import React, {Component} from 'react';
import moment from "moment/moment";
import {Col, Glyphicon, OverlayTrigger, Panel, Row, Tooltip} from 'react-bootstrap'
import PropTypes from 'prop-types'
import {Link} from "react-router";
import {FormattedRelative} from "react-intl";

class Sticky extends Component {

    iconMapping = {
        customer_message: {icon: 'user'},
        proposal_email: {icon: 'envelope'},
        notification_email: {icon: 'envelope'},
        acceptance: {icon: 'arrow-up', className: 'text-success'},
        unacceptance: {icon: 'arrow-down', className: 'text-danger'},
        acceptance_all: {icon: 'ok-circle', className: 'text-success'},
        pdf_download: {icon: 'save'}
    };

    render() {
        const {sticky, showActionType, withFollowUp, isProposalView} = this.props;
        if (sticky === null || sticky === undefined) {
            return null
        }

        return (
            <Row>
                <Col xs={12}>
                    <Panel bsStyle="default"
                           className={`sticky ${sticky.is_author ? 'pull-left' : 'pull-right'}`}>
                        <Row>
                            <Col xs={7}><span className='small'>
                                {this.props.onRead && !sticky.read ?
                                    <OverlayTrigger
                                        placement={'bottom'}
                                        overlay={
                                            <Tooltip id={`tooltip-visibility`}>
                                                Mark as read
                                            </Tooltip>
                                        }
                                    >
                                        <span><Glyphicon glyph="exclamation-sign" className="notification pointer"
                                                         onClick={() => this.props.onRead(sticky)}/>
                                            &nbsp;</span>
                                    </OverlayTrigger>
                                    : null}
                                {!sticky.complete && withFollowUp && sticky.after_follow_up ?
                                    <OverlayTrigger
                                        placement={'bottom'}
                                        overlay={
                                            <Tooltip id={`tooltip-followup`}>
                                                After follow up
                                            </Tooltip>
                                        }
                                    >
                                    <span><Glyphicon glyph="exclamation-sign" className="follow_up"/>
                                        &nbsp;</span>
                                    </OverlayTrigger>
                                    : null}
                                {moment(sticky.created_at).format('llll')}</span></Col>
                            <Col xs={5} className="text-right"><strong>{sticky.created_by}</strong>
                                {this.props.onNotify &&
                                    <span>&nbsp;
                                        <OverlayTrigger
                                            placement={'bottom'}
                                            overlay={
                                                <Tooltip id={`tooltip-new-email`}>
                                                    New email
                                                </Tooltip>
                                            }
                                        >
                                    <Link className="pointer" onClick={() => this.props.onNotify(sticky)}>
                                        <Glyphicon glyph="envelope"/>
                                    </Link>
                                    </OverlayTrigger>
                                </span>
                                }
                                {!isProposalView && this.props.onVisibilityChange &&
                                    <span>&nbsp;
                                        <OverlayTrigger
                                            placement={'bottom'}
                                            overlay={
                                                <Tooltip id={`tooltip-visibility`}>
                                                    <div>{sticky.hidden ? 'Show on' : 'Hide on'}</div>
                                                    <div>customer view</div>
                                                </Tooltip>
                                            }
                                        >
                                    <Link className="pointer" onClick={() => this.props.onVisibilityChange(sticky)}>
                                        <Glyphicon glyph={sticky.hidden ? 'eye-close' : 'eye-open'}/>
                                    </Link>
                                    </OverlayTrigger>
                                </span>
                                }
                                {this.props.onRemove &&
                                    <span>&nbsp;
                                        <OverlayTrigger
                                            placement={'bottom'}
                                            overlay={
                                                <Tooltip id={`tooltip-delete`}>
                                                    Delete
                                                </Tooltip>
                                            }
                                        >
                                    <Link className="pointer" onClick={() => this.props.onRemove(sticky)}>
                                        <Glyphicon glyph="remove"/>
                                    </Link>
                                    </OverlayTrigger>
                                </span>
                                }
                            </Col>
                        </Row>
                        <Row className="sticky-body">
                            <Col xs={12}>
                                {showActionType && this.iconMapping[sticky.action_type] &&
                                    <span className={this.iconMapping[sticky.action_type].className}>
                                    <Glyphicon className="bigger"
                                               glyph={this.iconMapping[sticky.action_type].icon}/>&nbsp;
                                </span>
                                }
                                {sticky.body}
                                {withFollowUp && sticky.follow_up_date &&
                                    <div><b>Follow up:</b> <FormattedRelative value={sticky.follow_up_date}/>
                                        {!sticky.complete && this.props.onComplete &&
                                            <OverlayTrigger
                                                placement={'bottom'}
                                                overlay={
                                                    <Tooltip id={`tooltip-completed`}>
                                                        Mark as completed
                                                    </Tooltip>
                                                }
                                            >
                                    <span> <Glyphicon className="pointer" glyph="ok"
                                                      onClick={() => this.props.onComplete(sticky)}/></span>
                                            </OverlayTrigger>}
                                    </div>}
                            </Col>
                        </Row>
                    </Panel>
                </Col>
            </Row>
        )
    }
}

Sticky.defaultProps = {
    showActionType: false
};

Sticky.propTypes = {
    sticky: PropTypes.object.isRequired,
    onRemove: PropTypes.func,
    onVisibilityChange: PropTypes.func,
    onNotify: PropTypes.func,
    onComplete: PropTypes.func,
    showActionType: PropTypes.bool,
    withFollowUp: PropTypes.bool,
    withRead: PropTypes.bool,
    onRead: PropTypes.func
};


export default Sticky;