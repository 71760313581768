import React from "react";
import DefaultReportView from "./DefaultReportView";

const RevenueReportsTab = ({getRevenueReport}) => {
    const reportOptions = [
        {value: 'revenue_by_service_type', label: 'Revenue by Service Type'},
        {value: 'revenue_by_service', label: 'Revenue by Service'},
        {value: 'revenue_by_service_by_customer', label: 'Revenue by Service by Customer'},
        {value: 'revenue_by_zip_by_service', label: 'Revenue by Zip by Service'},
        {value: 'revenue_by_zip_by_arborist', label: 'Revenue by Zip by Arborist'},
    ]

    return <DefaultReportView
        title="Revenue Reports"
        options={reportOptions}
        getRevenueReport={getRevenueReport}
    />
}

export default RevenueReportsTab;