import React from "react";
import {Col, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import '../NewCustomerInfoPage.scss'
import Dollars from "../../../../components/Dollars";

const CustomerSalesData = ({data}) => {
    const getPercent = (value, all) => (value / all * 100).toFixed(2)

    const statisticRow = (type, countClosed, countOpen, countDeclined, countBids, totalClosed, totalOpen, totalDeclined, totalBids) => {
        return countBids && countBids > 0 ? <Row className="font10">
                <Col xs={12}>
                    <Row className="text-center">
                        <Col xs={5} className="closed">{countClosed}</Col>
                        <Col xs={4} className="open">{countOpen}</Col>
                        <Col xs={3} className="declined">{countDeclined}</Col>
                    </Row>
                    <OverlayTrigger placement="top" overlay={<Tooltip
                        id="statistic_tooltip_tob">All Bids: {countBids - countDeclined}, total Bids:
                        ${totalBids - totalDeclined}</Tooltip>}>
                        <Row className="d-flex">
                            <div style={{width: `${getPercent(countClosed, countBids)}%`}}
                                 className="statistic-line closed"/>
                            <div style={{width: `${getPercent(countOpen, countBids)}%`}}
                                 className="statistic-line open"/>
                            <div style={{width: `${getPercent(countDeclined, countBids)}%`}}
                                 className="statistic-line declined"/>
                        </Row>
                    </OverlayTrigger>
                </Col>
                <Col xs={12}>
                    <Row className="text-center">
                        <Col xs={5} className="closed"><Dollars amount={totalClosed}/></Col>
                        <Col xs={4} className="open"><Dollars amount={totalOpen}/></Col>
                        <Col xs={3} className="declined"><Dollars amount={totalDeclined}/></Col>
                    </Row>
                    <Row className="d-flex">
                        <div style={{width: `${getPercent(totalClosed, totalBids)}%`}} className="statistic-line closed"/>
                        <div style={{width: `${getPercent(totalOpen, totalBids)}%`}} className="statistic-line open"/>
                        <div style={{width: `${getPercent(totalDeclined, totalBids)}%`}}
                             className="statistic-line declined"/>
                    </Row>
                </Col>
            </Row>
            :
            <p className={'no-record'}>No {type && type} proposal services</p>
    }

    return <div id="customer-sales-data">
        <Row className="customer-sales-data-row">
            <Col xs={2} style={{width: "50px"}}/>
            <Col xs={10} className="customer-sales-data-statistic">
                <Row>
                    <Col xs={5} className="text-center closed">Closed</Col>
                    <Col xs={4} className="text-center open">Open</Col>
                    <Col xs={3} className="text-center declined">Declined</Col>
                </Row>
            </Col>
        </Row>
        <Row className="customer-sales-data-row">
            <Col xs={2} className="customer-sales-data-description"
                 style={{borderRadius: "10px 0 0 0"}}><span>All</span></Col>
            <Col xs={10} className="customer-sales-data-statistic">
                {statisticRow(undefined, +data.closedCount, +data.openCount, +data.declinedCount, +data.bidsCount, +data.totalClosedServices, +data.totalOpenServices, +data.totalDeclinedServices, +data.total)}
            </Col>
        </Row>
        <Row className="customer-sales-data-row">
            <Col xs={2} className="customer-sales-data-description">Tree</Col>
            <Col xs={10} className="customer-sales-data-statistic">
                {statisticRow("Tree Care", +data.closedCountTreeCare, +data.openCountTreeCare, +data.declinedCountTreeCare, +data.bidsCountTreeCare, +data.totalClosedTreeServices, +data.totalOpenTreeServices, +data.totalDeclinedTreeServices, +data.totalTreeCare)}
            </Col>
        </Row>
        <Row className="customer-sales-data-row">
            <Col xs={2} className="customer-sales-data-description" style={{borderRadius: "0 0 0 10px"}}>PHC</Col>
            <Col xs={10} className="customer-sales-data-statistic">
                {statisticRow("PHC", +data.closedCountPhc, +data.openCountPhc, +data.declinedCountPhc, +data.bidsCountPhc, +data.totalClosedPhcServices, +data.totalOpenPhcServices, +data.totalDeclinedPhcServices, +data.totalPhc)}
            </Col>
        </Row>
    </div>
}

export default CustomerSalesData;