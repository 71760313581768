import {
    ADD_NEW_CLIENT,
    CLIENT_LIST_LOADED,
    DELETE_CLIENT,
    DELETE_ISSUE,
    DELETED_USER,
    EDIT_CLIENT_FILTER_TEXT,
    EDIT_USER_FILTER_TEXT,
    EDIT_USER_LIST_FILTER_TEXT,
    EDITING_CLIENT_ID,
    FETCH_CLIENT,
    FETCH_CUSTOMER_INFO,
    FETCH_EMPLOYEES,
    FETCH_ROLES,
    FETCH_USERS,
    IMPERSONATE_SUCCESS,
    SAVED_CLIENT_INFO,
    SAVED_USER_INFO,
    USER_ACTIVITIES_LOADED,
    USER_ISSUES_LOADED
} from './constants'

import {addAlert} from '../App/actions'
import axios from 'axios'
import {browserHistory} from 'react-router'
import {apiCall} from "../../common/apiActionHandler";

//READ (abriged index)
export function fetchClientsList(callback) {
    return function (dispatch, getState) {
        const store = getState()
        const config = {
            method: 'get',
            url: `/api/admin/clients_list`,
            headers: {Authorization: store.auth.token}
        }

        axios.request(config).then(response => {
            dispatch({type: CLIENT_LIST_LOADED, clientsList: response.data})
            callback && callback(response.data)
        })
    }
}

//DELETE
export const deleteClient = id => (dispatch, getState) => {
    if (id === 0) {
        //delete it locally assuming it will be deleted on the server
        dispatch({type: DELETE_CLIENT, id})
        return true
    }

    const state = getState()
    const config = {
        method: 'delete',
        url: `/api/clients/${id}`,
        headers: {Authorization: state.auth.token},
    }

    axios.request(config).then(response => {
        dispatch(
            // addAlert({ message: 'Deleted', mood: 'warning', dismissAfter: 1500 })
        )
    })
}

export const deleteUser = (userId, clientId) => async (dispatch, getState) => {

    const store = getState();
    const config = {
        method: "DELETE",
        url: `/api/admin/user_destroy?id=${userId}`,
        headers: {Authorization: store.auth.token},
    };

    try {
        const response = await axios.request(config);
        dispatch(
            addAlert({
                message: "User deleted",
                mood: "success",
                dismissAfter: 1000,
            })
        );
        dispatch(fetchUsers(clientId));
        dispatch({type: DELETED_USER});
    } catch (error) {
        dispatch(
            addAlert({
                message: "User not deleted because " +
                    error.toString(),
                mood: "danger",
                dismissAfter: 1500,
            })
        );

        throw error
    }
};

//CREATE/UPDATE
export const saveOrCreateClient = client => async (dispatch, getState) => {
    const state = getState()
    const id = client.id
    const config = {
        method: id === 0 ? 'post' : 'put',
        url: id === 0 ? '/api/admin/client_create' : `/api/admin/client_update?id=${id}`,
        headers: {Authorization: state.auth.token},
        data: {client},
    }

    try {
        const response = await axios.request(config);

        if (id === 0) {
            dispatch(deleteClient(0))
        }

        dispatch(
            addAlert({
                message: id === 0 ? 'Saved' : 'Updated',
                mood: 'success',
                dismissAfter: 1500,
            })
        )
        dispatch({type: SAVED_CLIENT_INFO, id: response.data.id});
        dispatch({type: FETCH_CLIENT, clients: response.data})
        dispatch(loadClientForEditing(response.data.id))
        dispatch(fetchClientsList())
        dispatch(fetchEmployees(response.data.id))

    } catch (error) {
        dispatch(
            addAlert({
                message: "Client not saved because " +
                    error.response.data ||
                    error.toString(),
                mood: "danger",
                dismissAfter: 2500,
            })
        )

        throw error
    }
}

export const clientFilterText = filterText => {
    return {type: EDIT_CLIENT_FILTER_TEXT, filterText}
}

export const clientUserFilterText = filterUserText => {
    return {type: EDIT_USER_FILTER_TEXT, filterUserText}
}

export const userListFilterText = filterUserListText => {
    return {type: EDIT_USER_LIST_FILTER_TEXT, filterUserListText}
}

export const impersonate = (id, coords, callback) => (dispatch, getState) => {
    const store = getState();
    const config = {
        method: 'post',
        url: `/api/admin/impersonate`,
        headers: {Authorization: store.auth.token},
        data: {
            id: id,
            coords: {lat: coords.latitude, lng: coords.longitude}
        },
    };

    axios.request(config).then(response => {
        dispatch(
            addAlert({message: `You are impersonating ${response.data.email}`})
        );
        dispatch({
            type: IMPERSONATE_SUCCESS,
            isAuthenticated: true,
            client: response.data.client,
            client_id: response.data.client_id,
            email: response.data.email,
            token: response.data.access_token,
            roles: response.data.roles,
            name: response.data.name,
            employee_id: response.data.employee_id,
            user_id: response.data.user_id,
            download_notifications: response.data.download_notifications,
            password: '',
        });
        let next_config = {
            url: `/api/users/${response.data.user_id}/refresh_activity`,
            method: 'PUT',
            headers: {
                Authorization: response.data.access_token
            }
        };
        axios.request(next_config);
        callback && callback()
        browserHistory.push('/');
    })
};

export function refreshToken(id) {
    return function (dispatch, getState) {
        const store = getState()
        const config = {
            method: 'put',
            url: `/api/admin/refresh_token`,
            headers: {Authorization: store.auth.token},
            data: {
                id: id,
            },
        }

        axios.request(config).then(response => {
            alert('Refreshed')
        })
    }
}

export const loadClientForEditing = (editingClientId, callback = null) => dispatch => {
    dispatch({type: EDITING_CLIENT_ID, editingClientId})

    switch (editingClientId) {
        case null:
            console.log('Not editing any client')
            return true
        case 0:
            console.log('This client id is 0, not going to fetch it!')
            return true
        default:
            dispatch(fetchCustomerInfo(editingClientId, callback))
    }
}

export const fetchUsers = clientId => async (dispatch, getState) => {
    const store = getState()
    const config = {
        url: `/api/users/`,
        headers: {Authorization: store.auth.token},
        params: {
            clientId: clientId
        }
    }

    try {
        const response = await axios.request(config)
        dispatch({type: FETCH_USERS, userInfo: response.data})
    } catch (error) {
        dispatch(
            addAlert({
                message: "User not found in your account because " +
                    error.toString(),
                mood: "danger",
                dismissAfter: 1500,
            })
        )

        throw error
    }
}

export const fetchEmployees = (clientId) => async (dispatch, getState) => {
    const store = getState()
    const config = {
        url: `/api/employees/`,
        headers: {Authorization: store.auth.token},
        params: {
            clientId: clientId
        }
    }

    try {
        const response = await axios.request(config)
        dispatch({type: FETCH_EMPLOYEES, employees: response.data})
    } catch (error) {
        dispatch(
            addAlert({
                message: "User not found in your account because " +
                    error.toString(),
                mood: "danger",
                dismissAfter: 1500,
            })
        )

        throw error
    }
}

export const fetchRoles = () => async (dispatch, getState) => {
    const store = getState()
    const config = {
        url: `/api/roles/`,
        headers: {Authorization: store.auth.token},
    }

    try {
        const response = await axios.request(config)
        dispatch({type: FETCH_ROLES, roles: response.data})
    } catch (error) {
        dispatch(
            addAlert({
                message: "User not found in your account because " +
                    error.toString(),
                mood: "danger",
                dismissAfter: 1500,
            })
        )

        throw error
    }
}

export const saveUserInfo = (user, clientId) => async (dispatch, getState) => {
    const store = getState()
    const userId = user.id;
    const method = userId ? "PUT" : "POST";
    const url = userId ? `/api/users/${userId}` : `/api/users/`;
    const config = {
        method,
        url,
        headers: {Authorization: store.auth.token},
        data: {user}
    }

    try {
        const response = await axios.request(config)
        dispatch({type: SAVED_USER_INFO, id: response.data.id});
        dispatch(
            addAlert({
                message: userId ? "User updated" : "User saved",
                mood: "success",
                dismissAfter: 1000,
            })
        )
        dispatch(fetchUsers(clientId))
    } catch (error) {
        dispatch(
            addAlert({
                message: "USER not saved because " +
                    error.response.data.errors.join(". ") ||
                    error.toString(),
                mood: "danger",
                dismissAfter: 2500,
            })
        )

        throw error
    }
}


export const fetchCustomerInfo = (clientId, callback) => async (dispatch, getState) => {
    const store = getState()
    const config = {
        url: `/api/admin/client?id=${+clientId}`,
        headers: {Authorization: store.auth.token},
    }

    try {
        const response = await axios.request(config)
        dispatch({type: FETCH_CLIENT, clients: response.data})
        dispatch({type: FETCH_CUSTOMER_INFO, customerInfo: response.data})
        callback && callback(response.data);
    } catch (error) {
        dispatch(
            addAlert({
                message: "Client not found in your account because " +
                    error.toString(),
                mood: "danger",
                dismissAfter: 1500,
            })
        )

        throw error
    }
}

export const fetchClientInfo = (clientId) => async (dispatch, getState) => {
    const store = getState()
    const config = {
        url: `/api/clients/${clientId}`,
        headers: {Authorization: store.auth.token},
    }

    try {
        const response = await axios.request(config)
        dispatch({type: FETCH_CUSTOMER_INFO, customerInfo: response.data})
    } catch (error) {
        dispatch(
            addAlert({
                message: "Client not found in your account because " +
                    error.toString(),
                mood: "danger",
                dismissAfter: 1500,
            })
        )

        throw error
    }
}

export const createNewClient = () => {
    return {type: ADD_NEW_CLIENT}
}

export function fetchUserActivities() {
    return function (dispatch, getState) {
        const store = getState();
        const config = {
            method: 'get',
            url: `/api/admin/activities`,
            headers: {Authorization: store.auth.token},
        };

        axios.request(config).then(response => {
            dispatch({type: USER_ACTIVITIES_LOADED, activities: response.data})
        })
    }
}

export function fetchUserIssues() {
    return function (dispatch, getState) {
        const store = getState();
        const config = {
            method: 'get',
            url: `/api/issues`,
            headers: {Authorization: store.auth.token},
        };

        axios.request(config).then(response => {
            dispatch({type: USER_ISSUES_LOADED, issues: response.data.content})
        })
    }
}

export const deleteIssue = (id) => async (dispatch, getState) => {
    const store = getState();
    const config = {
        method: "DELETE",
        url: `/api/issues/${id}`,
        headers: {Authorization: store.auth.token},
    };

    try {
        const response = await axios.request(config);
        dispatch(
            addAlert({
                message: "Issue deleted",
                mood: "success",
                dismissAfter: 1000,
            })
        );
        dispatch(fetchUserIssues());
        dispatch({type: DELETE_ISSUE});
    } catch (error) {
        dispatch(
            addAlert({
                message: "Issue not deleted because " +
                    error.toString(),
                mood: "danger",
                dismissAfter: 1500,
            })
        );

        throw error
    }
};

export const updatePayments = (params, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/admin/update_transaction_fee`,
        method: 'PUT',
        params: params
    };
    apiCall("Saving payments settings", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};

export const fetchStripeTransactions = (callback) => (dispatch, getState) => {
    const config = {
        url: `/api/stripe/get_transactions`,
        method: 'GET'
    };

    apiCall("Fetch stripe transactions", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const fetchClientPayments = (callback) => (dispatch, getState) => {
    const config = {
        url: `/api/payments/get_payments`,
        method: 'GET',
    };

    apiCall("Fetch transactions", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};

export const changeUserCapacity = (userCapacity, clientId, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/clients/change_user_capacity`,
        method: 'PUT',
        data: {
            client_id: clientId,
            user_capacity: userCapacity
        }
    };

    apiCall("Changing user capacity", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState);
};

export const fetchAllUsers = (callback) => (dispatch, getState) => {
    const config = {
        url: `/api/users/all_users`,
    };
    apiCall("Fetching all users", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState);
}
