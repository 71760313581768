import React, {useEffect, useRef, useState} from "react";
import "./OrganizationalChartPage.scss"
import {Tree, TreeNode} from "react-organizational-chart";
import {Button, Col, FormControl, Glyphicon, OverlayTrigger, Popover, Row, Tooltip} from "react-bootstrap";
import * as actions from "./OrganizationalChartPageApi"
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import EmployeeCard from "./EmployeeCard";
import MDSpinner from "react-md-spinner";
import {mapForSelect, select} from "../../common/commonHandlers";
import Select from "react-select";
import {SketchPicker} from "react-color";

const OrganizationalChartPage = ({actions, roles}) => {
    const [ready, setReady] = useState(false)
    const [organizationalStructure, setOrganizationalStructure] = useState(null)
    const [employees, setEmployees] = useState([])
    const [editMode, setEditMode] = useState(false)
    const [editedEmployee, setEditedEmployee] = useState(null)
    const [current, setCurrent] = useState({x: 0, y: 0, zoom: 1})
    const [startPoint, setStartPoint] = useState({})
    const [temporaryMove, setTemporaryMove] = useState({x: 0, y: 0})
    const [maxZoom, setMaxZoom] = useState(1)
    const [chartOptions, setChartOptions] = useState({
        backgroundColor: {r: 255, g: 255, b: 255, a: 1},
        typeCardColor: 'none',
        backgroundCardColor: {r: 255, g: 255, b: 255, a: 1},
        typeBorderColor: 'color',
        borderColor: {r: 0, g: 0, b: 0, a: 1},
        lineWidth: '2',
        lineColor: {r: 0, g: 0, b: 0, a: 1},
        lineBorderRadius: '10',
    })
    const [options, setOptions] = useState(false)
    const [colorPicker, setColorPicker] = useState({
        background: false,
        employeeBackground: false,
        border: false,
        chartLine: false
    })

    let refOptions = useRef(null)
    let refCPBackground = useRef(null)
    let refCPBackgroundB = useRef(null)
    let refCPEmployeeBackground = useRef(null)
    let refCPEmployeeBackgroundB = useRef(null)
    let refCPBorder = useRef(null)
    let refCPBorderB = useRef(null)
    let refCPChartLine = useRef(null)
    let refCPChartLineB = useRef(null)

    const employeeCardBackgroundOptions = [
        {value: 'none', label: 'Without background color'},
        {value: 'color', label: 'Selected color'},
        {value: 'user_color', label: 'User color'}
    ]

    const employeeCardBorderOptions = [
        {value: 'color', label: 'Selected color'},
        {value: 'user_color', label: 'User color'}
    ]

    const employeesOptions = (employeeCard) => mapForSelect(employees.filter(e => (!organizationalStructure?.map(o => o.person_id).includes(e.id) && e.disabled === false) || e.id === employeeCard.person_id))

    const c = document.getElementById('container');

    const calculateMaxZoom = () => {
        const tree = document.getElementsByClassName('tree')[0]
        const treeWidth = tree?.offsetWidth
        const windowWidth = window.innerWidth
        const initialZoom = windowWidth / treeWidth
        let finalZoom;
        if (initialZoom > 1) {
            finalZoom = 1
        } else {
            finalZoom = Math.trunc(initialZoom * 10) / 10;
        }
        return finalZoom
    }

    const backToStart = () => {
        const container = document.getElementById('container')
        const zoom = calculateMaxZoom()
        setMaxZoom(zoom ? zoom : 1)
        if (container) {
            setCurrent({x: 0, y: 0, zoom: zoom ? zoom : 1})
            setTemporaryMove({x: 0, y: 0})
            container.style.transform = `scale(${zoom ? zoom : 1}) translate(0px,0px)`;
        }
    }

    const getMaxMove = () => {
        const tree = document.getElementsByClassName('tree')[0]

        const treeWidth = tree.offsetWidth
        const treeHeight = tree.offsetHeight

        const windowWidth = window.innerWidth
        const windowHeight = window.innerHeight

        const maxMoveX = ((windowWidth - treeWidth * current.zoom) * 1.5) / current.zoom
        const maxMoveY = ((windowHeight - treeHeight * current.zoom) * 1.2) / current.zoom
        return {x: maxMoveX, y: maxMoveY}
    }

    const moveFunction = (event) => {
        if (!editedEmployee && !options && organizationalStructure) {
            let maxMove = getMaxMove()

            let wx = 0;
            let wy = 0;

            if (event.type === "mousemove") {
                wx = current.x + (event.clientX - startPoint.x);
                wy = current.y + (event.clientY - startPoint.y);
            } else if (event.type === "touchmove") {
                wx = current.x + (event.touches[0].clientX - startPoint.x);
                wy = current.y + (event.touches[0].clientY - startPoint.y);
            }

            if ((current.zoom <= maxZoom && maxMove.x >= 0) || maxMove.x >= 0) {
                temporaryMove.x = 0
            } else if (wx >= 0) {
                temporaryMove.x = -1
            } else if (temporaryMove.x <= maxMove.x) {
                temporaryMove.x = maxMove.x + 1
            } else if (wx <= 0 && wx > maxMove.x) {
                temporaryMove.x = wx;
            }

            if ((current.zoom === maxZoom && maxMove.y > 0) || maxMove.y > 0) {
                temporaryMove.y = 0
            } else if (wy >= 0) {
                temporaryMove.y = -1
            } else if (temporaryMove.y <= maxMove.y) {
                temporaryMove.y = maxMove.y + 1
            } else if (temporaryMove.y <= 0 && wy > maxMove.y) {
                temporaryMove.y = wy;
            }

            if (c) {
                c.style.transform = 'scale(' + current.zoom + ') translate(' + (temporaryMove.x) + 'px,' + (temporaryMove.y) + 'px)';
            }
        }
    }

    const zoomFunction = (e, zoomIn = false, zoomOut = false) => {
        if (!editedEmployee && !options && organizationalStructure) {
            let maxMove = getMaxMove()

            if ((e?.deltaY < 0 || zoomIn) && current.zoom < 1.9) {
                current.zoom += 0.1;
                if (current.zoom > 1) {
                    temporaryMove.x = temporaryMove.x - 30
                    temporaryMove.y = temporaryMove.y - 30
                } else {
                    temporaryMove.x = temporaryMove.x - 150
                    temporaryMove.y = temporaryMove.y - 120
                }
            } else if ((e?.deltaY > 0 || zoomOut) && current.zoom > maxZoom) {
                current.zoom -= 0.1;
                if (current.zoom === maxZoom) {
                    current.zoom = maxZoom
                } else if (current.zoom > 1) {
                    temporaryMove.x = temporaryMove.x + 30
                    temporaryMove.y = temporaryMove.y + 30
                } else {
                    temporaryMove.x = temporaryMove.x + 150
                    temporaryMove.y = temporaryMove.y + 120
                }
            }

            if (temporaryMove.x <= maxMove.x) {
                temporaryMove.x = maxMove.x + 1
            }
            if (temporaryMove.y <= maxMove.y) {
                temporaryMove.y = maxMove.y + 1
            }

            if (temporaryMove.x >= 50 || current.zoom <= maxZoom) {
                temporaryMove.x = 0
            }
            if (temporaryMove.y >= 0) {
                temporaryMove.y = -1
            }
            if (c) {
                c.style.transform = `scale(${current.zoom}) translate(${temporaryMove.x}px, ${temporaryMove.y}px)`;
            }
        }
    }

    const pressFunction = () => {
        window.addEventListener('mousemove', moveFunction)
    }

    const releaseFunction = () => {
        window.removeEventListener("mousemove", moveFunction)
    }

    const pressFunctionTouch = () => {
        window.addEventListener('touchmove', moveFunction)
    }

    const releaseFunctionTouch = () => {
        window.removeEventListener("touchmove", moveFunction)
    }

    useEffect(() => {
        if (!organizationalStructure) {
            setEditedEmployee(null)
            setOptions(false)
            setCurrent({x: 0, y: 0, zoom: 1})
            if (c) {
                c.style.transform = 'scale(1) translate(0px,0px)';
            }
        }
    }, [organizationalStructure])

    useEffect(() => {
        window.addEventListener('touchstart', pressFunctionTouch)
        return () => {
            window.removeEventListener('touchstart', pressFunctionTouch)
        }
    }, [pressFunction])

    useEffect(() => {
        window.addEventListener('touchend', releaseFunctionTouch)
        return () => {
            window.removeEventListener('touchend', releaseFunctionTouch)
        }
    }, [releaseFunction])

    useEffect(() => {
        window.addEventListener('mousedown', pressFunction)
        return () => {
            window.removeEventListener('mousedown', pressFunction)
        }
    }, [pressFunction])

    useEffect(() => {
        window.addEventListener('mouseup', releaseFunction)
        return () => {
            window.removeEventListener('mouseup', releaseFunction)
        }
    }, [releaseFunction])

    useEffect(() => {
        window.addEventListener('wheel', zoomFunction);
        return () => {
            window.removeEventListener('wheel', zoomFunction);
        }
    }, [zoomFunction])

    useEffect(() => {
        getOrganizationalData()
    }, [])

    useEffect(() => {
        backToStart()
    }, [ready, editMode])

    const getOrganizationalData = () => {
        actions.organizationalData(res => {
            setEmployees(res.employees)
            setOrganizationalStructure(res.organizational_chart)
            setReady(true)
            res.options && setChartOptions(res.options)
            setMaxZoom(calculateMaxZoom())
        })
    }

    const employeeStructure = (id) => {
        return {
            id: Math.max(...organizationalStructure.map(o => o.id)) + 1,
            person_id: null,
            duties: [],
            supervisor: id,
            show: true,
            showSubordinates: true
        }
    }

    const updateEmployee = employee => {
        const index = organizationalStructure.findIndex(e => e.id === employee.id)
        organizationalStructure.splice(index, 1, employee)
        saveOrganizationalData(organizationalStructure)
    }

    const removeEmployee = async (employee, type) => {
        if (type === "hard_delete") {
            deleteEmployee(employee)
            const data = organizationalStructure
            await setOrganizationalStructure([])
            saveOrganizationalData(data)
        }
        if (type === "soft_delete") {
            const newEmployee = {
                ...employee,
                person_id: null,
                duties: []
            }
            updateEmployee(newEmployee)
        }
    }

    const deleteEmployee = employee => {
        const index = organizationalStructure.findIndex(e => e.id === employee.id)
        organizationalStructure.splice(index, 1)
        return organizationalStructure.filter(e => e.supervisor === employee.id)?.map(employee =>
            ((() => {
                return deleteEmployee(employee)
            })()))
    }

    const saveOrganizationalData = (data) => {
        setOrganizationalStructure(data)
        actions.saveChart(data, chartOptions, () => getOrganizationalData())
    }

    const moveEmployee = async (employee, selects) => {
        const data = organizationalStructure
        await setOrganizationalStructure([])
        if (selects.type === "move" || selects.type === "demote") {
            const newSupervisor = data.find(e => e.person_id === selects.newSupervisor)
            const newOrganizationalStructure = data.map(e => {
                if (e.id === employee.id) {
                    return {...e, supervisor: newSupervisor ? newSupervisor.id : e.supervisor}
                } else {
                    return e
                }
            })
            saveOrganizationalData(newOrganizationalStructure)
        }
        if (selects.type === "promote") {
            const oldSupervisor = data.find(e => e.id === employee.supervisor)
            const newOrganizationalStructure = data.map(e => {
                if (e.id === employee.id) {
                    return {...e, supervisor: oldSupervisor ? oldSupervisor.supervisor : e.supervisor}
                } else {
                    return e
                }
            })
            saveOrganizationalData(newOrganizationalStructure)
        }
    }

    const handleExpandTree = employee => {
        const newOrganizationalStructure = organizationalStructure.map(e => {
            if (e.id === employee.id) {
                return {...employee, showSubordinates: !employee.showSubordinates}
            } else {
                return e
            }
        })
        setOrganizationalStructure(newOrganizationalStructure)
    }

    const handleShowEmployee = async (employee, value) => {
        changeShowEmployee(employee, value)
        const data = organizationalStructure
        await setOrganizationalStructure([])
        saveOrganizationalData(data)
    }

    const changeShowEmployee = (employee, value) => {
        const newEmployee = {...employee, show: value}
        const index = organizationalStructure.findIndex(e => e.id === employee.id)
        organizationalStructure.splice(index, 1, newEmployee)
        return organizationalStructure.filter(e => e.supervisor === employee.id)?.map(employee =>
            ((() => {
                return changeShowEmployee(employee, value)
            })()))
    }

    const employeesAsSubordinate = (employee, callback) => {
        const result = []
        const subordinates = organizationalStructure.filter(e => e.supervisor === employee.id)
        result.push(subordinates)
        callback(result)
        subordinates.map(employee =>
            ((() => {
                return employeesAsSubordinate(employee, res => result.push(res))
            })()))
        return result.flat(10)
    }

    const genderTree = (employeeCards, supervisor, supervisorId, supervisorShowSubordinates) => {
        const treeNode = (employeeCard, i) => {
            return (editMode || employeeCard.person_id) && <TreeNode
                key={i}
                label={
                    <EmployeeCard
                        employeeCard={employeeCard}
                        employee={employees.find(e => e.id === employeeCard.person_id)}
                        updateEmployee={updateEmployee}
                        editMode={editMode}
                        removeEmployee={removeEmployee}
                        haveSubordinate={organizationalStructure.filter(e => editMode ? e.supervisor === employeeCard.id : e.supervisor === employeeCard.id && e.person_id)?.length > 0}
                        employeesOptions={employeesOptions(employeeCard)}
                        moveEmployee={moveEmployee}
                        employeesInChart={employees.filter(e => organizationalStructure?.map(o => o.person_id).includes(e.id) && !employeesAsSubordinate(employeeCard, () => {
                        }).map(e => e.person_id).includes(e.id) && e.id !== employeeCard.person_id)}
                        employeesInChartForDemote={employees.filter(e => organizationalStructure?.filter(o => o.supervisor === employeeCard.supervisor).map(o => o.person_id).includes(e.id) && e.id !== employeeCard.person_id)}
                        handleExpandTree={handleExpandTree}
                        handleShowEmployee={handleShowEmployee}
                        editedEmployee={employeeCard.id === editedEmployee}
                        setEditedEmployee={setEditedEmployee}
                        typeCardColor={chartOptions.typeCardColor}
                        typeBorderColor={chartOptions.typeBorderColor}
                        backgroundColor={chartOptions.backgroundCardColor}
                        borderColor={chartOptions.borderColor}
                    />
                }
            >
                {(() => {
                    return genderTree(organizationalStructure.filter(e => editMode ? e.supervisor === employeeCard.id && employeeCard.showSubordinates === true : e.supervisor === employeeCard.id && e.person_id && employeeCard.showSubordinates === true && e.show === true), employeeCard.person_id, employeeCard.id, employeeCard.showSubordinates)
                })()}
            </TreeNode>
        }

        return editMode && supervisor && supervisorShowSubordinates ?
            <>
                {employeeCards?.map((employeeCard, i) => treeNode(employeeCard, i))}
                <TreeNode
                    label={
                        <div id="employee-card">
                            <div
                                id={`employee-card ${supervisorId}`}
                                className="full-employee-card"
                                style={{
                                    backgroundColor: chartOptions.typeCardColor !== 'color' ? '#fff' : `rgba(${chartOptions.backgroundCardColor.r}, ${chartOptions.backgroundCardColor.g}, ${chartOptions.backgroundCardColor.b}, ${chartOptions.backgroundCardColor.a})`,
                                    border: `1px solid rgba(${chartOptions.borderColor.r}, ${chartOptions.borderColor.g}, ${chartOptions.borderColor.b}, ${chartOptions.borderColor.a})`
                                }}>
                                <Glyphicon
                                    glyph='plus'
                                    className="add-new-employee"
                                    onClick={() => {
                                        const newOrganizationalStructure = organizationalStructure.concat(employeeStructure(supervisorId))
                                        setOrganizationalStructure(newOrganizationalStructure)
                                        saveOrganizationalData(newOrganizationalStructure)
                                    }}
                                />
                            </div>
                        </div>
                    }
                />
            </>
            :
            employeeCards?.map((employeeCard, i) => treeNode(employeeCard, i))
    }

    useEffect(() => {
        function handleClickOutside(event) {
            if ((refCPBackground?.current && !refCPBackground?.current?.contains(event.target) && refCPBackgroundB?.current && !refCPBackgroundB?.current?.contains(event.target)) ||
                (refCPEmployeeBackground?.current && !refCPEmployeeBackground?.current?.contains(event.target) && refCPEmployeeBackgroundB?.current && !refCPEmployeeBackgroundB?.current?.contains(event.target)) ||
                (refCPBorder?.current && !refCPBorder?.current?.contains(event.target) && refCPBorderB?.current && !refCPBorderB?.current?.contains(event.target)) ||
                (refCPChartLine?.current && !refCPChartLine?.current?.contains(event.target) && refCPChartLineB?.current && !refCPChartLineB?.current?.contains(event.target))) {
                setColorPicker({
                    background: false,
                    employeeBackground: false,
                    border: false,
                    chartLine: false
                })
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [refCPBackground, refCPEmployeeBackground, refCPBorder, refCPChartLine]);

    return (
        <div
            id="organizational-chart"
            onMouseDown={event => {
                setStartPoint({x: event.clientX, y: event.clientY})
                setCurrent({
                    x: temporaryMove?.x ? temporaryMove?.x : 0,
                    y: temporaryMove?.y ? temporaryMove?.y : 0,
                    zoom: current.zoom
                })
            }}
            onTouchStart={event => {
                setStartPoint({x: event.touches[0].clientX, y: event.touches[0].clientY})
                setCurrent({
                    x: temporaryMove?.x ? temporaryMove?.x : 0,
                    y: temporaryMove?.y ? temporaryMove?.y : 0,
                    zoom: current.zoom
                })
            }}
            style={{backgroundColor: `rgba(${chartOptions.backgroundColor.r}, ${chartOptions.backgroundColor.g}, ${chartOptions.backgroundColor.b}, ${chartOptions.backgroundColor.a})`}}
        >
            {ready && <div className="buttons-island-top">
                <OverlayTrigger placement="right"
                                overlay={<Tooltip id="tooltip">Click to show editing options</Tooltip>}>
                    {roles?.includes("admin") ?
                        <Button bsStyle="info" className={editMode ? "allow-editing" : "avoid-editing"}
                                onClick={() => setEditMode(!editMode)}>
                            Edit mode {editMode ? "🔓" : "🔒"}
                        </Button>
                        :
                        <p></p>}
                </OverlayTrigger>
                <Button bsStyle="info" onClick={() => backToStart()}>
                    Recenter
                </Button>
                <OverlayTrigger ref={r => (refOptions = r)} placement="right" trigger="click" overlay={
                    <Popover id="organizational-chart-popover" title="Organizational Chart Options">
                        <Row className="vertical-align">
                            <Col md={5} xs={5}>
                                Background color
                            </Col>
                            <Col md={7} xs={7} className="color-picker-wrapper">
                                {colorPicker.background &&
                                    <div className="color-picker" ref={refCPBackground}>
                                        <SketchPicker
                                            width={150}
                                            onChangeComplete={(color) => setChartOptions({
                                                ...chartOptions,
                                                backgroundColor: color.rgb
                                            })}
                                            color={chartOptions.backgroundColor}
                                        />
                                    </div>
                                }
                                <div ref={refCPBackgroundB}>
                                    <Button
                                        bsSize="xsmall"
                                        onClick={() => setColorPicker({
                                            ...colorPicker,
                                            background: !colorPicker.background
                                        })}
                                        className={`full-width ${colorPicker.background && 'mt-5'}`}
                                    >
                                        {`${colorPicker.background ? 'Hide' : 'Show'} color picker`}
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-20">
                            <Col md={5}>
                                Employee card background
                            </Col>
                            <Col md={7}>
                                <Select
                                    value={select(employeeCardBackgroundOptions, chartOptions.typeCardColor)}
                                    isClearable={false}
                                    options={employeeCardBackgroundOptions}
                                    onChange={type => setChartOptions({...chartOptions, typeCardColor: type.value})}
                                />
                            </Col>
                        </Row>
                        {chartOptions.typeCardColor === "color" && <Row className="mt10 vertical-align">
                            <Col md={5} xs={5}>
                                Background employee card color
                            </Col>
                            <Col md={7} xs={7} className="color-picker-wrapper">
                                {colorPicker.employeeBackground &&
                                    <div className="color-picker" ref={refCPEmployeeBackground}>
                                        <SketchPicker
                                            width={150}
                                            onChangeComplete={(color) => setChartOptions({
                                                ...chartOptions,
                                                backgroundCardColor: color.rgb
                                            })}
                                            color={chartOptions.backgroundCardColor}
                                        />
                                    </div>
                                }
                                <div ref={refCPEmployeeBackgroundB}>
                                    <Button
                                        bsSize="xsmall"
                                        onClick={() => setColorPicker({
                                            ...colorPicker,
                                            employeeBackground: !colorPicker.employeeBackground
                                        })}
                                        className={`full-width ${colorPicker.employeeBackground && 'mt-5'}`}
                                    >
                                        {`${colorPicker.employeeBackground ? 'Hide' : 'Show'} color picker`}
                                    </Button>
                                </div>
                            </Col>
                        </Row>}
                        <Row className="mt-20">
                            <Col md={5}>
                                Employee card border
                            </Col>
                            <Col md={7}>
                                <Select
                                    value={select(employeeCardBorderOptions, chartOptions.typeBorderColor)}
                                    isClearable={false}
                                    options={employeeCardBorderOptions}
                                    onChange={type => setChartOptions({...chartOptions, typeBorderColor: type.value})}
                                />
                            </Col>
                        </Row>
                        {chartOptions.typeBorderColor === "color" && <Row className="mt10 vertical-align">
                            <Col md={5} xs={5}>
                                Border color
                            </Col>
                            <Col md={7} xs={7} className="color-picker-wrapper">
                                {colorPicker.border &&
                                    <div className="color-picker" ref={refCPBorder}>
                                        <SketchPicker
                                            width={150}
                                            onChangeComplete={(color) => setChartOptions({
                                                ...chartOptions,
                                                borderColor: color.rgb
                                            })}
                                            color={chartOptions.borderColor}
                                        />
                                    </div>
                                }
                                <div ref={refCPBorderB}>
                                    <Button
                                        bsSize="xsmall"
                                        onClick={() => setColorPicker({...colorPicker, border: !colorPicker.border})}
                                        className={`full-width ${colorPicker.border && 'mt-5'}`}
                                    >
                                        {`${colorPicker.border ? 'Hide' : 'Show'} color picker`}
                                    </Button>
                                </div>
                            </Col>
                        </Row>}
                        <Row className="mt-20">
                            <Col md={5}>
                                Organizational chart line width
                            </Col>
                            <Col md={7}>
                                <FormControl type="number" value={chartOptions.lineWidth}
                                             onChange={e => {
                                                 if (e.target.value > 0) {
                                                     setChartOptions({...chartOptions, lineWidth: e.target.value})
                                                 }
                                             }}
                                             placeholder="Line width"
                                />
                            </Col>
                        </Row>
                        <Row className="mt-20 vertical-align">
                            <Col md={5} xs={5}>
                                Organizational chart line color
                            </Col>
                            <Col md={7} xs={7} className="color-picker-wrapper">
                                {colorPicker.chartLine &&
                                    <div className="color-picker" ref={refCPChartLine}>
                                        <SketchPicker
                                            width={150}
                                            onChangeComplete={(color) => setChartOptions({
                                                ...chartOptions,
                                                lineColor: color.rgb
                                            })}
                                            color={chartOptions.lineColor}
                                        />
                                    </div>
                                }
                                <div ref={refCPChartLineB}>
                                    <Button
                                        bsSize="xsmall"
                                        onClick={() => setColorPicker({
                                            ...colorPicker,
                                            chartLine: !colorPicker.chartLine
                                        })}
                                        className={`full-width ${colorPicker.chartLine && 'mt-5'}`}
                                    >
                                        {`${colorPicker.chartLine ? 'Hide' : 'Show'} color picker`}
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-20">
                            <Col md={5}>
                                Organizational chart line border radius
                            </Col>
                            <Col md={7}>
                                <FormControl type="number" value={chartOptions.lineBorderRadius}
                                             onChange={e => {
                                                 if (e.target.value > 0) {
                                                     setChartOptions({
                                                         ...chartOptions,
                                                         lineBorderRadius: e.target.value
                                                     })
                                                 }
                                             }}
                                             placeholder="Line width"
                                />
                            </Col>
                        </Row>
                        <div className="text-right mt10">
                            <Button
                                bsStyle="warning"
                                bsSize="xsmall"
                                className="mr-10"
                                onClick={() => {
                                    refOptions.handleHide()
                                    setOptions(!options)
                                }}>
                                Cancel
                            </Button>
                            <Button
                                bsStyle="success"
                                bsSize="xsmall"
                                onClick={() => {
                                    saveOrganizationalData(organizationalStructure)
                                    refOptions.handleHide()
                                    setOptions(!options)
                                }}
                            >
                                Save
                            </Button>
                        </div>
                    </Popover>
                }>
                    {organizationalStructure && roles?.includes("admin") && editMode ?
                        <Button bsStyle="info" onClick={() => setOptions(!options)}>
                            Options
                        </Button>
                        :
                        <p></p>
                    }
                </OverlayTrigger>
            </div>}
            {ready && <div className="buttons-island-bottom">
                <Glyphicon glyph="zoom-in" onClick={() => zoomFunction(undefined, true)}/>
                <Glyphicon glyph="zoom-out" onClick={() => zoomFunction(undefined, false, true)}/>
            </div>}
            {ready && (editedEmployee || options || organizationalStructure?.length === 0) &&
                <div className='block-move-function'>
                    <OverlayTrigger placement='left' overlay={<Tooltip id="tooltip">Zoom and Move functions have been
                        disabled!</Tooltip>}>
                        <Glyphicon glyph='ban-circle'/>
                    </OverlayTrigger>
                </div>}
            <div id="container">
                <div id="item">
                    {ready ?
                        !organizationalStructure ?
                            <div className="empty-chart">
                                {roles?.includes("admin") && editMode ?
                                    <OverlayTrigger placement="top"
                                                    overlay={<Tooltip id="tooltip">Create Organizational
                                                        Chart</Tooltip>}>
                                        <Glyphicon glyph='plus' className="pointer"
                                                   onClick={() => setOrganizationalStructure([{
                                                       id: 1,
                                                       supervisor: "leader",
                                                       person_id: null,
                                                       duties: [],
                                                       show: true,
                                                       showSubordinates: true
                                                   }])
                                                   }/>
                                    </OverlayTrigger>
                                    :
                                    <p className="empty-organizational-chart-info">Organizational chart was not created
                                        yet!</p>
                                }
                            </div>
                            :
                            <div className="tree">
                                {organizationalStructure.filter(e => e.supervisor === "leader").map(employeeCard => {
                                    return employeeCard.person_id || editMode ? <Tree
                                            lineWidth={`${chartOptions.lineWidth}px`}
                                            lineColor={`rgba(${chartOptions.lineColor.r}, ${chartOptions.lineColor.g}, ${chartOptions.lineColor.b}, ${chartOptions.lineColor.a})`}
                                            lineBorderRadius={`${chartOptions.lineBorderRadius}px`}
                                            label={
                                                <EmployeeCard
                                                    employeeCard={employeeCard}
                                                    employee={employees.find(e => e.id === employeeCard.person_id)}
                                                    updateEmployee={updateEmployee}
                                                    editMode={editMode}
                                                    removeEmployee={removeEmployee}
                                                    haveSubordinate={organizationalStructure.filter(e => editMode ? e.supervisor === employeeCard.id : e.supervisor === employeeCard.id && e.person_id)?.length > 0}
                                                    employeesOptions={employeesOptions(employeeCard)}
                                                    moveEmployee={moveEmployee}
                                                    employeesInChart={employees.filter(e => organizationalStructure?.map(o => o.person_id).includes(e.id) && !employeesAsSubordinate(employeeCard, () => {
                                                    }).map(e => e.person_id).includes(e.id) && e.id !== employeeCard.person_id)}
                                                    employeesInChartForDemote={employees.filter(e => organizationalStructure?.filter(o => o.supervisor === employeeCard.supervisor).map(o => o.person_id).includes(e.id) && e.id !== employeeCard.person_id)}
                                                    handleExpandTree={handleExpandTree}
                                                    handleShowEmployee={handleShowEmployee}
                                                    editedEmployee={employeeCard.id === editedEmployee}
                                                    setEditedEmployee={setEditedEmployee}
                                                    typeCardColor={chartOptions.typeCardColor}
                                                    typeBorderColor={chartOptions.typeBorderColor}
                                                    backgroundColor={chartOptions.backgroundCardColor}
                                                    borderColor={chartOptions.borderColor}
                                                />
                                            }
                                        >
                                            {genderTree(organizationalStructure.filter(e => editMode ? e.supervisor === employeeCard.id && employeeCard.showSubordinates === true : e.supervisor === employeeCard.id && e.person_id && employeeCard.showSubordinates === true && e.show === true), employeeCard.person_id, employeeCard.id, employeeCard.showSubordinates)}
                                        </Tree>
                                        :
                                        <p className="empty-organizational-chart-info">Organizational chart was not
                                            created yet!</p>
                                })}
                            </div>
                        :
                        <div className="empty-chart">
                            <MDSpinner size={50}/>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    roles: state.auth.roles
});

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(actions, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationalChartPage)