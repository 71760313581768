import {APPROVAL_SUCCESS, PENDING_APPROVALS_LOADED} from './constants'

//TODO: add this reducer to reducers

const initialState = {
    arboristApprovals: [],
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case PENDING_APPROVALS_LOADED:
            return {...state, arboristApprovals: action.arboristApprovals}
        case APPROVAL_SUCCESS:
            return {
                ...state,
                arboristApprovals: state.arboristApprovals.filter(a => a.proposal_id !== action.proposalId)
            }
        default:
            return state
    }
}

