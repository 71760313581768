export const [
    UPDATE_SERVICE_STATUS,
    UPDATE_SELECTED_SITE,
    SELECT_CUSTOMER,
    FETCH_SELECTED_SITEINFO,
] = [
    "UPDATE_SERVICE_STATUS",
    "UPDATE_SELECTED_SITE",
    "SELECT_CUSTOMER",
    "FETCH_SELECTED_SITEINFO",
]
