import {dataUpload} from "../../common/upload";
import {apiCall} from "../../common/apiActionHandler";
import axios from "axios";
import {addAlert} from "../App/actions";

export const uploadOrdinanceAndLawAttachment = (id, file_type, acceptedFiles, callback) => (dispatch, getState) => {
    dataUpload(`/api/ordinance_and_law_files?ordinance_and_law_id=${id}&file_type=${file_type}`, acceptedFiles, callback)(dispatch, getState);
};
export const deleteOrdinanceAndLawAttachment = (id, callback) => (dispatch, getState) => {
    const state = getState()
    const config = {
        method: 'delete',
        url: `/api/ordinance_and_law_files/${id}`,
        headers: {'Authorization': state.auth.token},
    }

    axios.request(config)
        .then(response => {
            dispatch(addAlert({message: 'Deleted', mood: 'info', dismissAfter: 1500}))
            callback()
        })
        .catch((error, response) => {
            dispatch(addAlert({
                message: 'Did not delete Attachment!',
                mood: 'warning',
                dismissAfter: 3500
            }))
        })
}

export const reorderAttachment = (newImageOrder, ordinance_and_law_id, file_type, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/ordinance_and_laws/update_doc_order`,
        method: 'PUT',
        data: {new_doc_order: newImageOrder, id: ordinance_and_law_id, file_type: file_type}
    };

    apiCall("Reordering attachments", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false);
};