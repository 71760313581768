import React from 'react';
import {Button, Modal} from "react-bootstrap";

const UpdateJobStatusModal = ({show, onHide, stopAllTimeTracks, services}) => {
    return <Modal show={show}
                  onHide={() => onHide()}
                  className={"heightAuto fontIOS"}>
        <Modal.Header closeButton><Modal.Title>Update Job Status</Modal.Title></Modal.Header>
        <Modal.Body>
            <div className='columnDirection rate-modal-body-padding'>
                <p>You will send completed job notification email:</p>
                {services.filter(s => s.proposal_service_status_name === 'Scheduled').length > 0 &&
                    <b>Not completed services:</b>}
                <ul>
                    {services.filter(s => s.proposal_service_status_name === 'Scheduled').map(s =>
                        <li>{s.proposal_service_status_name} {s.name}</li>)}
                </ul>

                {services.filter(s => s.proposal_service_status_name !== 'Scheduled').length > 0 &&
                    <b>Completed services:</b>}
                <ul>
                    {services.filter(s => s.proposal_service_status_name !== 'Scheduled').map(s =>
                        <li>{s.proposal_service_status_name} {s.name}</li>)}
                </ul>

                <p>Are you sure you want to continue?</p>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button type="button" onClick={() => onHide()}
                    className="btn btn-default"
                    data-dismiss="modal">No
            </Button>
            <Button className="btn btn-success" onClick={() => {
                stopAllTimeTracks()
                onHide()
            }}>
                Yes
            </Button>
        </Modal.Footer>
    </Modal>

};


export default UpdateJobStatusModal;