import React, {useEffect, useRef, useState} from "react";
import {Button, Col, Image, Row, Tab, Tabs} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import moment from "moment-timezone";
import {defaultDateFormat, reverseColor} from "./commonHandlers";
import {dateFormatter, dateNoTimeFormatter, dollarsFormatter} from "../components/BootStrapTableCellFormatters";
import TrucksFilterModal from "../containers/lists/TrucksFilterModal";
import tableFilter from "./tableFilter";
import documentIcon from "../images/document.png";
import QRCode from "qrcode.react";

const isMobile = window.screen.width <= 1024;

export const MasterDetailParent = ({
                                       handleChildTabSelect,
                                       setResource,
                                       resources,
                                       resources_all,
                                       filtersData,
                                       saveNewCsvDownload
                                   }) => {

    const [truckFilterModalShow, setTruckFilterModalShow] = useState(false)
    const [truckServicesFilterModalShow, setTruckServicesFilterModalShow] = useState(false)
    const [truckFinancialsFilterModalShow, setTruckFinancialsFilterModalShow] = useState(false)
    const [filteredTrucks, setFilteredTrucks] = useState([])
    const [filteredServices, setFilteredServices] = useState([])
    const [filteredFinancials, setFilteredFinancials] = useState([])
    const [services, setServices] = useState([])
    const [financials, setFinancials] = useState([])
    const [trucks, setTrucks] = useState([])
    const [filter, setFilter] = useState({})
    const [key, setKey] = useState(0)

    const tableFleetRef = useRef()
    const tableServicesRef = useRef()
    const tableFinancialsRef = useRef()

    useEffect(() => {
        setTrucks(resources)
        setFilteredTrucks(resources)
        setServices(resources.flatMap(r => r.truck_services))
        setFilteredServices(resources.flatMap(r => r.truck_services))
        setFinancials(resources.flatMap(r => r.truck_financial_attributes).filter(value => Object.keys(value).length !== 0))
        setFilteredFinancials(resources.flatMap(r => r.truck_financial_attributes).filter(value => Object.keys(value).length !== 0))
    }, [])

    const renderSizePerPageDropDown = ({currSizePerPage, sizePerPageList, changeSizePerPage}) => (
        <div className="btn-group dropup">
            <button className="btn btn-info dropdown-toggle" type="button" id="dropdownMenu2"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span>{currSizePerPage}</span>
                <span className="ml-6 caret"/>
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                {sizePerPageList.map(({text, value}) =>
                    <li className='pointer'>
                        <a
                            className='table-pagination dropdown-item'
                            onClick={() => changeSizePerPage(value)}
                        >{text}</a>
                    </li>
                )}
            </ul>
        </div>
    );
    const optionsTruck = {
        onRowClick: (e) => setResource(e),
        page: 1,  //which page you want to show as default
        sizePerPageList: [{
            text: '10', value: 10
        }, {
            text: '25', value: 25
        }, {
            text: '30', value: 30
        }, {
            text: '50', value: 50
        }],
        //you can change the dropdown list for size per page
        sizePerPage: 10,  //which size per page you want to locate as default
        paginationSize: 3,  //the pagination bar size.
        sizePerPageDropDown: renderSizePerPageDropDown,
    }
    const optionsService = {
        onRowClick: (e) => {
            setResource(trucks.find(t => t.id === e.truck_id))
            handleChildTabSelect(1)
        },
        page: 1,  //which page you want to show as default
        sizePerPageList: [{
            text: '10', value: 10
        }, {
            text: '25', value: 25
        }, {
            text: '30', value: 30
        }, {
            text: '50', value: 50
        }],
        //you can change the dropdown list for size per page
        sizePerPage: 10,  //which size per page you want to locate as default
        paginationSize: 3,  //the pagination bar size.
        sizePerPageDropDown: renderSizePerPageDropDown,
    }
    const optionsFinancial = {
        onRowClick: (e) => {
            setResource(trucks.find(t => t.id === e.truck_id))
            handleChildTabSelect(2)
        },
        page: 1,  //which page you want to show as default
        sizePerPageList: [{
            text: '10', value: 10
        }, {
            text: '25', value: 25
        }, {
            text: '30', value: 30
        }, {
            text: '50', value: 50
        }],
        //you can change the dropdown list for size per page
        sizePerPage: 10,  //which size per page you want to locate as default
        paginationSize: 3,  //the pagination bar size.
        sizePerPageDropDown: renderSizePerPageDropDown,
    }
    const handleFilterData = (filteredData, filterName) => {

        setFilter(filteredData)

        switch (filterName) {
            case 'Fleet':
                setFilteredTrucks(tableFilter(trucks, filteredData))
                break;
            case 'Services':
                setFilteredServices(tableFilter(services, filteredData))
                break;
            case 'Financials':
                setFilteredFinancials(tableFilter(financials, filteredData))
                break;
            default:
                break;
        }
    }
    const dollarSortFunc = (a, b, order, name) => {
        if (order === 'desc') {
            return (parseFloat(a[name]?.toString().replace(/[^0-9.-]/g, '')) || 0) - (parseFloat(b[name]?.toString().replace(/[^0-9.-]/g, '')) || 0);
        } else {
            return (parseFloat(b[name]?.toString().replace(/[^0-9.-]/g, '')) || 0) - (parseFloat(a[name]?.toString().replace(/[^0-9.-]/g, '')) || 0);
        }
    }
    const downloadQR = () => {
        const canvas = document.getElementById(`trucks-services-qr`);
        let url = canvas.toDataURL("image/png");
        let link = document.createElement("a");
        link.download = `trucks-services-qr.png`;
        link.href = url;
        link.click();
    };
    const handleTabSelect = (e) => {
        setKey(e)
    }

    const csvDollarFormatter = cell => {
        if (typeof (cell) === "string") {
            cell = cell.replace(',', '')
        }
        return parseFloat(cell && !isNaN(cell) ? cell : 0).toFixed(2)
    }

    return <Tabs activeKey={key}
                 id="controlled-tab-example"
                 mountOnEnter={true}
                 onSelect={handleTabSelect}
                 animation={false} id="noanim-tab-example">
        <Tab eventKey={0} title='Fleet'>
            <div>
                <Row>
                    <Row style={isMobile ? {width: "100%"} : {}}>
                        <Col md={3} mdOffset={9} className='align-right margin-top-20'>
                            <Button
                                className="pointer no-top-margin"
                                bsStyle="warning"
                                onClick={() => setTruckFilterModalShow(!truckFilterModalShow)}>
                                Filter {" "}<FontAwesomeIcon icon="search"
                                                             className="small-margin"/>
                            </Button>
                        </Col>
                    </Row>
                    <Col md={12}>
                        <BootstrapTable
                            ref={tableFleetRef}
                            data={filteredTrucks}
                            striped={true}
                            bordered={false}
                            hover={true}
                            exportCSV={true}
                            pagination={true}
                            search={true}
                            height={!isMobile ? '600px' : ''}
                            csvFileName={() => {
                                if (tableFleetRef?.current) {
                                    saveNewCsvDownload(tableFleetRef.current, `trucks-${moment()}.csv`, filter, "trucks")
                                }
                                return `trucks-${moment().format(defaultDateFormat)}.csv`
                            }}
                            options={optionsTruck}
                        >

                            <TableHeaderColumn
                                width={100}
                                dataField="name"
                                tdStyle={{whiteSpace: 'normal'}}
                                thStyle={{whiteSpace: 'normal'}}
                                dataSort={true}
                            >
                                Name
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width={100}
                                dataField="truck_year_id"
                                tdStyle={{whiteSpace: 'normal'}}
                                thStyle={{whiteSpace: 'normal'}}
                                dataSort={true}
                                columnClassName='menu-show-fixer'
                                dataFormat={(cell) => {
                                    return resources_all.truck_years.find(y => y.id === cell)?.label || ''
                                }}
                                csvFormat={(cell) => {
                                    return resources_all.truck_years.find(y => y.id === cell)?.label || ''
                                }}
                            >
                                Year
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width={100}
                                dataField="truck_make_id"
                                tdStyle={{whiteSpace: 'normal'}}
                                thStyle={{whiteSpace: 'normal'}}
                                dataSort={true}
                                columnClassName='menu-show-fixer'
                                dataFormat={(cell) => {
                                    return resources_all.truck_makes.find(m => m.id === cell)?.label || ''
                                }}
                                csvFormat={(cell) => {
                                    return resources_all.truck_makes.find(m => m.id === cell)?.label || ''
                                }}
                            >
                                Make
                            </TableHeaderColumn>

                            <TableHeaderColumn
                                width={100}
                                dataField="truck_model_id"
                                tdStyle={{whiteSpace: 'normal'}}
                                thStyle={{whiteSpace: 'normal'}}
                                dataSort={true}
                                columnClassName='menu-show-fixer'
                                dataFormat={(cell) => {
                                    return resources_all.truck_models.find(m => m.id === cell)?.label || ''
                                }}
                                csvFormat={(cell) => {
                                    return resources_all.truck_models.find(m => m.id === cell)?.label || ''
                                }}
                            >
                                Model
                            </TableHeaderColumn>

                            <TableHeaderColumn
                                width={100}
                                dataField="truck_type_id"
                                tdStyle={{whiteSpace: 'normal'}}
                                thStyle={{whiteSpace: 'normal'}}
                                dataSort={true}
                                columnClassName='menu-show-fixer'
                                dataFormat={(cell) => {
                                    return resources_all.truck_types.find(t => t.id === cell)?.label || ''
                                }}
                                csvFormat={(cell) => {
                                    return resources_all.truck_types.find(t => t.id === cell)?.label || ''
                                }}
                            >
                                Type
                            </TableHeaderColumn>

                            <TableHeaderColumn
                                width={100}
                                dataField="truck_class_id"
                                tdStyle={{whiteSpace: 'normal'}}
                                thStyle={{whiteSpace: 'normal'}}
                                dataSort={true}
                                columnClassName='menu-show-fixer'
                                dataFormat={(cell) => {
                                    return resources_all.truck_classes.find(c => c.id === cell)?.label || ''
                                }}
                                csvFormat={(cell) => {
                                    return resources_all.truck_classes.find(c => c.id === cell)?.label || ''
                                }}
                            >
                                Class
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width={100}
                                dataField="truck_unit_id"
                                tdStyle={{whiteSpace: 'normal'}}
                                thStyle={{whiteSpace: 'normal'}}
                                dataSort={true}
                                columnClassName='menu-show-fixer'
                                dataFormat={(cell) => {
                                    return resources_all.truck_units.find(u => u.id === cell)?.label || ''
                                }}
                                csvFormat={(cell) => {
                                    return resources_all.truck_units.find(u => u.id === cell)?.label || ''
                                }}
                            >
                                Unit
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width={100}
                                dataField="license_plate"
                                tdStyle={{whiteSpace: 'normal'}}
                                thStyle={{whiteSpace: 'normal'}}
                                dataSort={true}
                                columnClassName='menu-show-fixer'
                            >
                                License Plate
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width={100}
                                dataField="expiration"
                                tdStyle={{whiteSpace: 'normal'}}
                                thStyle={{whiteSpace: 'normal'}}
                                dataSort={true}
                                columnClassName='menu-show-fixer'
                                dataFormat={dateNoTimeFormatter}
                                csvFormat={dateNoTimeFormatter}
                            >
                                Expiration
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width={200}
                                dataField="vin"
                                tdStyle={{whiteSpace: 'normal'}}
                                thStyle={{whiteSpace: 'normal'}}
                                dataSort={true}
                                columnClassName='menu-show-fixer'
                            >
                                VIN
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                hidden
                                dataField="id"
                                isKey={true}
                            >
                            </TableHeaderColumn>
                        </BootstrapTable>
                        {truckFilterModalShow &&
                            <TrucksFilterModal isOpen={truckFilterModalShow}
                                               closeModal={() => setTruckFilterModalShow(!truckFilterModalShow)}
                                               modalBuilder={filtersData.trucks}
                                               data={resources_all}
                                               filterData={(filteredData) => handleFilterData(filteredData, 'Fleet')}
                                               initialFilter={filter}

                            />
                        }
                    </Col>
                </Row>
            </div>
        </Tab>
        <Tab eventKey={1} title='Services'>
            <div>
                <Row>
                    <Row style={isMobile ? {width: "100%"} : {}}>
                        <Col md={3} mdOffset={9} className='align-right margin-top-20'>
                            <Button
                                className="pointer no-top-margin"
                                bsStyle="warning"
                                onClick={() => setTruckServicesFilterModalShow(!truckServicesFilterModalShow)}>
                                Filter {" "}<FontAwesomeIcon icon="search"
                                                             className="small-margin"/>
                            </Button>
                        </Col>
                    </Row>
                    <Col md={9} lg={10}>
                        <BootstrapTable
                            ref={tableServicesRef}
                            data={filteredServices}
                            striped={true}
                            bordered={false}
                            hover={true}
                            exportCSV={true}
                            pagination={true}
                            search={true}
                            height={!isMobile ? '600px' : ''}
                            csvFileName={() => {
                                if (tableServicesRef?.current) {
                                    saveNewCsvDownload(tableServicesRef.current, `trucks-services-${moment()}.csv`, filter, "trucks-services")
                                }
                                return `trucks-services-${moment().format(defaultDateFormat)}.csv`
                            }}
                            options={optionsService}
                        >
                            <TableHeaderColumn
                                width={100}
                                dataField="truck_name"
                                tdStyle={{whiteSpace: 'normal'}}
                                thStyle={{whiteSpace: 'normal'}}
                                dataSort={true}
                            >
                                Truck
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width={100}
                                dataField="truck_service_status_id"
                                tdStyle={{whiteSpace: 'normal'}}
                                thStyle={{whiteSpace: 'normal'}}
                                dataSort={true}
                                dataFormat={(cell) => {
                                    let status = resources_all.truck_service_statuses.find(s => s.id === cell)
                                    return <span className='service-status' style={{
                                        backgroundColor: `${status?.color}`,
                                        color: `${reverseColor(status?.color)}`
                                    }}>{status?.label}</span> || ''
                                }}
                                csvFormat={(cell) => {
                                    return resources_all.truck_service_statuses.find(s => s.id === cell)?.label || ''
                                }}
                            >
                                Status
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width={100}
                                dataField="scheduled_date"
                                tdStyle={{whiteSpace: 'normal'}}
                                thStyle={{whiteSpace: 'normal'}}
                                dataSort={true}
                                dataFormat={dateNoTimeFormatter}
                                csvFormat={dateNoTimeFormatter}
                                columnClassName='menu-show-fixer'
                            >
                                Scheduled date
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width={100}
                                dataField="details"
                                tdStyle={{whiteSpace: 'normal'}}
                                thStyle={{whiteSpace: 'normal'}}
                                dataSort={true}
                                columnClassName='menu-show-fixer'
                            >
                                Details
                            </TableHeaderColumn>

                            <TableHeaderColumn
                                width={100}
                                dataField="completed_date"
                                tdStyle={{whiteSpace: 'normal'}}
                                thStyle={{whiteSpace: 'normal'}}
                                dataSort={true}
                                dataFormat={dateFormatter}
                                csvFormat={dateFormatter}
                                columnClassName='menu-show-fixer'
                            >
                                Completed date
                            </TableHeaderColumn>

                            <TableHeaderColumn
                                width={100}
                                dataField="cost"
                                tdStyle={{whiteSpace: 'normal'}}
                                thStyle={{whiteSpace: 'normal'}}
                                dataSort={true}
                                sortFunc={dollarSortFunc}
                                columnClassName='menu-show-fixer'
                                dataFormat={(cell) => dollarsFormatter(cell ? cell.replace(/[^0-9.-]/g, '') : cell)}
                                csvFormat={csvDollarFormatter}
                            >
                                Cost
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width={100}
                                dataField="reporter"
                                tdStyle={{whiteSpace: 'normal'}}
                                thStyle={{whiteSpace: 'normal'}}
                                dataSort={true}
                                columnClassName='menu-show-fixer'
                            >
                                Reporter
                            </TableHeaderColumn>

                            <TableHeaderColumn
                                width={100}
                                dataField="service_records"
                                tdStyle={{whiteSpace: 'normal'}}
                                thStyle={{whiteSpace: 'normal'}}
                                dataSort={true}
                                columnClassName='menu-show-fixer'
                                dataFormat={(cell) => {
                                    return <>{cell?.map(c => <a href={c.original} target='_blank'>
                                            <Image className='table-image'
                                                   src={(c.is_image && c?.mini) || documentIcon}/>
                                        </a>
                                    )}</>
                                }}
                                csvFormat={(cell) => {
                                    return cell?.map(c => c.original)
                                }}
                            >
                                Records
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                hidden
                                dataField="id"
                                isKey={true}
                            >
                            </TableHeaderColumn>
                        </BootstrapTable>
                        {truckServicesFilterModalShow &&
                            <TrucksFilterModal isOpen={truckServicesFilterModalShow}
                                               closeModal={() => setTruckServicesFilterModalShow(!truckServicesFilterModalShow)}
                                               modalBuilder={filtersData.services}
                                               data={resources_all}
                                               filterData={(filteredData) => handleFilterData(filteredData, 'Services')}
                                               initialFilter={filter}

                            />
                        }
                    </Col>
                    <Col md={3} lg={2}>
                        <QRCode id={`trucks-services-qr`}
                                className='d-block'
                                value={`https://${window.location.hostname}/trucks/new_service`}
                                size={180}
                        />
                        <a onClick={downloadQR}>Download QR</a>
                    </Col>
                </Row>
            </div>
        </Tab>
        <Tab eventKey={2} title='Financials'>
            <div>
                <Row>
                    <Row style={isMobile ? {width: "100%"} : {}}>
                        <Col md={3} mdOffset={9} className='align-right margin-top-20'>
                            <Button
                                className="pointer no-top-margin"
                                bsStyle="warning"
                                onClick={() => setTruckFinancialsFilterModalShow(!truckFinancialsFilterModalShow)}>
                                Filter {" "}<FontAwesomeIcon icon="search"
                                                             className="small-margin"/>
                            </Button>
                        </Col>
                    </Row>
                    <Col md={12}>
                        <BootstrapTable
                            ref={tableFinancialsRef}
                            data={filteredFinancials}
                            striped={true}
                            bordered={false}
                            hover={true}
                            exportCSV={true}
                            pagination={true}
                            search={true}
                            height={!isMobile ? '600px' : ''}
                            csvFileName={() => {
                                if (tableFinancialsRef?.current) {
                                    saveNewCsvDownload(tableFinancialsRef.current, `trucks-financials-${moment()}.csv`, filter, "trucks-financials")
                                }
                                return `trucks-financials-${moment().format(defaultDateFormat)}.csv`
                            }}
                            options={optionsFinancial}
                        >
                            <TableHeaderColumn
                                width={100}
                                dataField="truck_name"
                                tdStyle={{whiteSpace: 'normal'}}
                                thStyle={{whiteSpace: 'normal'}}
                                dataSort={true}
                            >
                                Truck
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width={100}
                                dataField="financial_lender_id"
                                tdStyle={{whiteSpace: 'normal'}}
                                thStyle={{whiteSpace: 'normal'}}
                                dataSort={true}
                                dataFormat={(cell) => {
                                    return resources_all.financial_lenders.find(l => l.id === cell)?.label || ''
                                }}
                                csvFormat={(cell) => {
                                    return resources_all.financial_lenders.find(l => l.id === cell)?.label || ''
                                }}
                            >
                                Lender
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width={100}
                                dataField="loan"
                                tdStyle={{whiteSpace: 'normal'}}
                                thStyle={{whiteSpace: 'normal'}}
                                dataSort={true}
                                columnClassName='menu-show-fixer'
                                csvFormat={cell => cell || ''}
                            >
                                Loan
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width={100}
                                dataField="original_amount"
                                tdStyle={{whiteSpace: 'normal'}}
                                thStyle={{whiteSpace: 'normal'}}
                                dataSort={true}
                                sortFunc={dollarSortFunc}
                                columnClassName='menu-show-fixer'
                                dataFormat={(cell) => dollarsFormatter(cell ? cell.replace(/[^0-9.-]/g, '') : cell)}
                                csvFormat={csvDollarFormatter}
                            >
                                Original Amount
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width={100}
                                dataField="down_payment"
                                tdStyle={{whiteSpace: 'normal'}}
                                thStyle={{whiteSpace: 'normal'}}
                                dataSort={true}
                                sortFunc={dollarSortFunc}
                                columnClassName='menu-show-fixer'
                                dataFormat={(cell) => dollarsFormatter(cell ? cell.replace(/[^0-9.-]/g, '') : cell)}
                                csvFormat={csvDollarFormatter}
                            >
                                Down Payment
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width={100}
                                dataField="origin_date"
                                tdStyle={{whiteSpace: 'normal'}}
                                thStyle={{whiteSpace: 'normal'}}
                                dataSort={true}
                                dataFormat={dateNoTimeFormatter}
                                csvFormat={dateNoTimeFormatter}
                                columnClassName='menu-show-fixer'
                            >
                                First Payment Date
                            </TableHeaderColumn>


                            <TableHeaderColumn
                                width={100}
                                dataField="term_amount"
                                tdStyle={{whiteSpace: 'normal'}}
                                thStyle={{whiteSpace: 'normal'}}
                                dataSort={true}
                                columnClassName='menu-show-fixer'
                                dataFormat={(cell, row) => {
                                    return `${row.term_amount ? row.term_amount : ''} ${row.term_amount ? 'month' : 'N/A'}`
                                }}
                                csvFormat={(cell, row) => {
                                    return `${row.term_amount ? row.term_amount : ''} ${row.term_amount ? 'month' : 'N/A'}`
                                }}
                            >
                                Term
                            </TableHeaderColumn>

                            <TableHeaderColumn
                                width={100}
                                dataField="interest_rate"
                                tdStyle={{whiteSpace: 'normal'}}
                                thStyle={{whiteSpace: 'normal'}}
                                dataSort={true}
                                columnClassName='menu-show-fixer'
                                csvFormat={cell => cell || ''}
                            >
                                Interest Rate
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width={100}
                                dataField="payment_amount"
                                tdStyle={{whiteSpace: 'normal'}}
                                thStyle={{whiteSpace: 'normal'}}
                                dataSort={true}
                                sortFunc={dollarSortFunc}
                                columnClassName='menu-show-fixer'
                                dataFormat={(cell) => dollarsFormatter(cell ? cell.replace(/[^0-9.-]/g, '') : cell)}
                                csvFormat={csvDollarFormatter}
                            >
                                Payment Amount
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width={100}
                                dataField="payment_date"
                                tdStyle={{whiteSpace: 'normal'}}
                                thStyle={{whiteSpace: 'normal'}}
                                dataSort={true}
                                dataFormat={dateNoTimeFormatter}
                                csvFormat={dateNoTimeFormatter}
                                columnClassName='menu-show-fixer'
                            >
                                Payment Date
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width={100}
                                dataField="balance"
                                tdStyle={{whiteSpace: 'normal'}}
                                thStyle={{whiteSpace: 'normal'}}
                                dataSort={true}
                                sortFunc={dollarSortFunc}
                                columnClassName='menu-show-fixer'
                                dataFormat={(cell) => dollarsFormatter(cell ? cell.replace(/[^0-9.-]/g, '') : cell)}
                                csvFormat={csvDollarFormatter}
                            >
                                Balance
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width={100}
                                dataField="value"
                                tdStyle={{whiteSpace: 'normal'}}
                                thStyle={{whiteSpace: 'normal'}}
                                dataSort={true}
                                sortFunc={dollarSortFunc}
                                columnClassName='menu-show-fixer'
                                dataFormat={(cell) => dollarsFormatter(cell ? cell.replace(/[^0-9.-]/g, '') : cell)}
                                csvFormat={csvDollarFormatter}
                            >
                                Value
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width={100}
                                dataField="equity"
                                tdStyle={{whiteSpace: 'normal'}}
                                thStyle={{whiteSpace: 'normal'}}
                                dataSort={true}
                                sortFunc={dollarSortFunc}
                                columnClassName='menu-show-fixer'
                                dataFormat={(cell) => dollarsFormatter(cell ? cell.replace(/[^0-9.-]/g, '') : cell)}
                                csvFormat={csvDollarFormatter}
                            >
                                Equity
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width={100}
                                dataField="notes"
                                tdStyle={{whiteSpace: 'normal'}}
                                thStyle={{whiteSpace: 'normal'}}
                                dataSort={true}
                                columnClassName='menu-show-fixer'
                                csvFormat={cell => cell || ''}
                            >
                                Notes
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                hidden
                                dataField="id"
                                isKey={true}
                            >
                            </TableHeaderColumn>
                        </BootstrapTable>
                        {truckFinancialsFilterModalShow &&
                            <TrucksFilterModal isOpen={truckFinancialsFilterModalShow}
                                               closeModal={() => setTruckFinancialsFilterModalShow(!truckFinancialsFilterModalShow)}
                                               modalBuilder={filtersData.financials}
                                               data={resources_all}
                                               filterData={(filteredData) => handleFilterData(filteredData, 'Financials')}
                                               initialFilter={filter}

                            />
                        }
                    </Col>
                </Row>
            </div>
        </Tab>
    </Tabs>
}