import {Button, Col, Glyphicon, Modal, Row} from "react-bootstrap";
import ResourceComponent from "../../../components/ResourceComponent";
import React from "react";
import TextareaAutosize from "react-textarea-autosize";

class RefundReasonModal extends ResourceComponent {
    constructor(props) {
        super(props);
        this.state = {
            resource: {}
        };
    }

    render() {
        const {resource} = this.state;
        const {show} = this.props;
        return (
            <Modal
                {...this.props}
                bsSize="normal"
                animation={true}
                backdrop={false}
                dialogClassName='modal-smaller'
                onHide={() => this.props.onHide()}
            >
                <Modal.Header closeButton>
                    <Modal.Title className='text-center'>Are you sure you want to request a refund?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={12}>
                            <div className='text-center'><strong className="small-margin">Specify the reason why you
                                want a refund</strong>
                            </div>
                            <TextareaAutosize
                                style={{width: '100%', marginTop: '25px'}}
                                name="reason"
                                value={resource.reason}
                                onChange={(e) => {
                                    let {resource} = this.state;
                                    resource.reason = e.target.value;
                                    this.setState({resource})
                                }}
                                minRows={3}
                                maxRows={10}
                                placeholder="Reason"
                            />
                        </Col>
                        <Col md={12}>
                            <Glyphicon glyph="exclamation-sign text-orange" className="notification"/>
                            <span className='text-orange text-small small-margin'>
                                The value to be returned is the value of the payment (or the entire invoice), customer fee will not be returned.
                            </span>
                        </Col>
                    </Row>
                </Modal.Body>

                <Modal.Footer>
                    <Row>
                        <Col md={6} xs={6} className="text-left">
                            <Button bsSize="small" onClick={() => this.props.onHide()}>No</Button>
                        </Col>
                        <Col md={6} xs={6} className="text-right">
                            <Button
                                bsStyle="success"
                                bsSize="small"
                                onClick={() => this.props.onSave(show, resource)}
                            >Yes
                            </Button>
                        </Col>
                    </Row>

                </Modal.Footer>
            </Modal>
        )
    }
}

export default RefundReasonModal;
