import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Button, Col, Grid, Row} from "react-bootstrap";
import moment from "moment";
import * as api from "./QuickbooksServicesApi";
import {select} from "../../../common/commonHandlers";
import ResourceComponent from "../../../components/ResourceComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLongArrowAltLeft, faLongArrowAltRight, faTimes, faTimesCircle} from '@fortawesome/free-solid-svg-icons'
import Select from "react-select";
import InlineEditable from "../../Scheduler/InlineEditable";
import './QuickbooksServices.css';
import MDSpinner from "react-md-spinner";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import SyncAllModal from "../SyncAllModal";
import * as progressActions from "../QuickbooksApi";
import ErrorInfoModal from "../ErrorInfoModal";
import ConfirmDisassociateModal from "../QuickbooksCustomers/ConfirmDisassociateModal";

const actions = {...api, ...progressActions};

class QuickbooksServices extends ResourceComponent {

    state = {
        filterVisible: true,
        resource: {
            from: moment().subtract(7, "days").utc().startOf('day'),
            to: moment().utc().endOf('day'),
            service_id: null,
            showSynchronized: false,
            qb_service: null,
            page: 1,
            per_page: 25
        },
        content: [],
        rowLoading: null,
        allSelected: null,
        selectedRecords: [],
        errorModal: {show: false, message: ''},
        showDisassociateModal: {
            show: false,
            th_id: null,
            index: null
        },
    };

    constructor(props, context) {
        super(props, context);
        this.serviceSearch = {
            searchOptions: () => [],
            search: () => {
            }
        };
        this.qbServiceSearch = {
            searchOptions: () => [],
            search: () => {
            }
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {eventKey, selectedKey, dateTo, dateFrom, showSynchronized, serviceFilterType} = this.props;
        const {resource} = this.state;
        if (prevProps.dateTo !== dateTo || prevProps.dateFrom !== dateFrom || prevProps.showSynchronized !== showSynchronized) {
            resource["from"] = dateFrom;
            resource["to"] = dateTo;
            resource["showSynchronized"] = showSynchronized;
            this.setState({resource: resource}, this.sync);
        }
        if (prevProps.eventKey !== eventKey || prevProps.selectedKey !== selectedKey) {
            if (eventKey === selectedKey) {
                this.serviceSearch = this.buildSearchComponent('service_id', this.props.actions.searchForService);
                this.qbServiceSearch = this.buildSearchComponent('qb_service', this.props.actions.searchForQbService);
                this.sync();
            }
        }
        if (prevProps.serviceFilterType !== serviceFilterType) {
            resource.page = 1
            this.setState({resource: resource})
        }
    }

    componentDidMount() {
        this.serviceSearch = this.buildSearchComponent('service_id', this.props.actions.searchForService);
        this.qbServiceSearch = this.buildSearchComponent('qb_service', this.props.actions.searchForQbService);
        if (window.location.href.indexOf("service_id") === -1) {
            this.sync();
        }
        this.props.onRef(this)
    }

    componentWillUnmount() {
        this.props.onRef(undefined)
    }

    sync = () => {
        const {resource} = this.state;
        const {serviceFilterType, serviceQbFilter, serviceThFilter, page} = this.props
        this.props.actions.syncServices({
            ...resource,
            serviceFilterType: serviceFilterType,
            serviceQbFilter: serviceQbFilter,
            serviceThFilter: serviceThFilter,
            page: page ? page : resource.page
        }, response => this.setState(response, () => {
            this.props.setIsNotLoading();
        }));
    }

    syncAllOnThisPage = () => {
        this.setState({syncAllModal: !this.state.syncAllModal})
    }

    syncQbService = (qbId, index) => {
        let {content} = this.state;
        this.props.actions.getQbService(qbId, (result) => {
            content[index].th = result.content[0].service;
            this.setState({
                content: content,
                rowLoading: null
            });
        })
    }

    syncThService = (thId, index) => {
        let {content} = this.state;
        this.props.actions.getService(thId, (result) => {
            content[index].th = result;
            this.props.actions.getQbService(result.quickbooks_id, (response) => {
                content[index].qb = response.content[0];
                this.setState({
                    content: content,
                    rowLoading: null
                });
            })
        })
    }

    assignService = (service, index) => {
        const {content} = this.state;
        service.isAssigned = true;
        let obj = service;
        if (content[index] && content[index]["th"]) {
            content[index]["th"] = obj
        } else {
            content[index] = {...content[index], th: obj}
        }
        this.setState({
            content: content
        });
    }

    assignQbService = (service, index) => {
        let {content} = this.state;
        service.isAssigned = true;
        content[index]["qb"] = service;
        this.setState({
            content: content
        });
    }

    handleSearchService = (resource, index, callback = null) => {
        if (resource[`service_id_${index}`]) {
            this.props.actions.getService(resource[`service_id_${index}`], (response) => {
                this.assignService(response, index)
                callback && callback(response)
            })
        }
    }

    handleSearchQbService = (resource, index) => {
        if (resource[`qb_service${index}`]) {
            this.props.actions.getQbService(resource[`qb_service${index}`], (response) => this.assignQbService(response.content[0], index))
        }
    }

    onSelectAll = (isSelected, rows) => {
        let selectedRecords = [];
        if (isSelected) {
            selectedRecords = rows.map(p => p.proposalId)
        }
        this.setState({selectedRecords})
    };

    onRowSelect = (row, isSelected) => {
        let selectedRecords = [...this.state.selectedRecords];
        const proposalId = parseInt(row.proposalId, 10);
        if (isSelected) {
            selectedRecords = selectedRecords.concat(proposalId);
            const uniqSelected = new Set(selectedRecords);
            selectedRecords = [...uniqSelected];
        } else {
            selectedRecords = selectedRecords.filter(s => s !== proposalId);
        }
        this.setState({selectedRecords});
    };

    searchService = (index) => {
        const serviceOptions = this.serviceSearch.searchOptions();
        const {resource, content} = this.state;
        return (
            <div>
                <Row>
                    <Col xs={12}>
                        <InlineEditable
                            style={{minWidth: "50%"}}
                            className="pointer"
                            fullWidth={true}
                            withSubmit={!content[index]["qb"]}
                            value={
                                content[index]["th"] ? this.serviceDataField(content[index]["th"], content[index]["qb"], index) :
                                    <h4>
                                        <b>Not Assigned</b>
                                    </h4>
                            }
                            form={(done) =>
                                <Select className="Select"
                                        classNamePrefix="select"
                                        value={select(serviceOptions, resource[`service_id_${index}`])}
                                        options={serviceOptions}
                                        placeholder="Service"
                                        isClearable
                                        onInputChange={this.serviceSearch.search}
                                        onChange={this.selectResourceAttr(`service_id_${index}`,
                                            () => {
                                                this.handleSearchService(resource, index)
                                            }
                                        )}
                                />
                            }
                        />
                    </Col>
                </Row>
            </div>
        );
    }

    searchQbService = (index) => {
        const serviceQbOptions = this.qbServiceSearch.searchOptions();
        const {resource, content} = this.state;
        return (
            <div>
                <Row>
                    <Col xs={12} className="hover-cell">
                        <InlineEditable
                            style={{minWidth: "50%"}}
                            className="pointer text-center"
                            fullWidth={true}
                            withSubmit={!content[index]["qb"]}
                            value={
                                content[index]["qb"] ? this.serviceDataField(content[index]["qb"], content[index]["th"], index, 'right') :
                                    <h4>
                                        <b>Not Assigned</b>
                                    </h4>
                            }
                            form={(done) =>
                                <Select className="Select"
                                        classNamePrefix="select"
                                        value={select(serviceQbOptions, resource[`qb_service${index}`])}
                                        options={serviceQbOptions}
                                        placeholder="Service"
                                        isClearable
                                        onInputChange={this.qbServiceSearch.search}
                                        onChange={this.selectResourceAttr(`qb_service${index}`,
                                            () => this.handleSearchQbService(resource, index)
                                        )}
                                />
                            }
                        />
                    </Col>
                </Row>
            </div>
        );
    }

    serviceDataField = (service, template, index, direction = null) => {
        const deleteable = service && template && service.quickbooks_id !== template.quickbooks_id
        return (
            <div className="d-flex">
                <div style={{flexGrow: 1}}>
                    <Row className="full-width">
                        <Col style={{textDecoration: "none"}} className="text-right" xs={5}>
                            Name:
                        </Col>
                        <Col xs={7} className="text-left" style={{
                            color: template && service.name?.trim() === template.name?.trim() ? "green" : "red"
                        }}>
                            <p>{service.name}</p>
                        </Col>
                    </Row>
                    <Row className="full-width">
                        <Col style={{textDecoration: "none"}} className="text-right" xs={5}>
                            Type:
                        </Col>
                        <Col xs={7} className="text-left" style={{
                            color: template && service.service_type_name && template.service_type_name && service.service_type_name.trim().toUpperCase() === template.service_type_name.trim().toUpperCase() ? "green" : "red"
                        }}>
                            <p>{service.service_type_name && service.service_type_name.toUpperCase()}</p>
                        </Col>
                    </Row>
                    <Row className="full-width">
                        <Col style={{textDecoration: "none"}} className="text-right" xs={5}>
                            Description:
                        </Col>
                        <Col xs={7} className="text-left"
                             style={{color: template && service.description?.trim() === template.description?.trim() ? "green" : "red"}}>
                            <p>{service.description}</p>
                        </Col>
                    </Row>
                </div>
                {direction === 'right' && deleteable && <div>
                    <FontAwesomeIcon
                        className="pointer github-picker"
                        icon={faTimesCircle}
                        size="1x"
                        data-toggle={'tooltip'}
                        title={'Delete match'}
                        onClick={() => {
                            const {content} = this.state
                            if (content[index]) {
                                content[index].qb = null;
                                this.setState(content);
                            }
                        }}/>
                </div>}
            </div>
        )
    }

    serviceFormatter = (cell, row, enumObject, index, direction = null) => {
        if (direction === 'right') {
            return (cell && !cell.isAssigned) ? this.serviceDataField(cell, row.th, index, direction) : this.searchQbService(index)
        } else if (direction === 'left') {
            return (cell && !cell.isAssigned) ? this.serviceDataField(cell, row.qb, index, direction) : this.searchService(index)
        }
    }

    choiceFormatter = (cell, row, enumObject, index) => {
        const {
            rowLoading
        } = this.state;
        const items = row;
        return (
            rowLoading !== null && rowLoading === index ?
                <div><MDSpinner/></div>
                :
                <div>
                    {items.qb &&
                        <div>
                            <FontAwesomeIcon
                                className={`pointer`}
                                icon={faLongArrowAltLeft}
                                size="2x"
                                onClick={() => {
                                    this.setState({rowLoading: index}, () => {
                                        this.props.actions.updateService(items, 'left',
                                            () => this.syncQbService(items.qb.quickbooks_id, index),
                                            (res) => {
                                                this.setState({rowLoading: false})
                                                if (res?.data?.message) {
                                                    const errorModal = {
                                                        show: true,
                                                        message: res?.data?.message
                                                    }
                                                    this.setState({errorModal})
                                                }
                                            }
                                        )
                                    })
                                }}/>
                        </div>
                    }
                    {items.th &&
                        <div>
                            <FontAwesomeIcon
                                className={`pointer`}
                                icon={faLongArrowAltRight}
                                size="2x"
                                data-toggle={(items.th && !items.qb) && 'tooltip'}
                                onClick={() => {
                                    ((items.th && !items.qb) || (items.th && items.qb)) &&
                                    this.setState({rowLoading: index}, () => {
                                        this.props.actions.updateService(items, 'right',
                                            () => this.syncThService(items.th.id, index),
                                            (res) => {
                                                this.setState({rowLoading: false})
                                                if (res?.data?.message) {
                                                    const errorModal = {
                                                        show: true,
                                                        message: res?.data?.message
                                                    }
                                                    this.setState({errorModal})
                                                }
                                            }
                                        )
                                    })
                                }}/>
                        </div>}
                    {items.qb && items.th && items.qb.quickbooks_id === items.th.quickbooks_id && <div>
                        <FontAwesomeIcon className="pointer"
                                         icon={faTimes}
                                         size="2x"
                                         data-toggle={'tooltip'}
                                         title={'Disassociate an account'}
                                         onClick={() => this.setState({
                                             showDisassociateModal: {
                                                 show: true,
                                                 th_id: items?.th?.id,
                                                 index: index
                                             }
                                         })}
                        />
                    </div>}
                </div>
        )
    }


    render() {
        const {
            syncAllModal, filterVisible, resource, content, rowLoading, total, errorModal, showDisassociateModal
        } = this.state;
        const {page, per_page} = this.state.resource;
        return (
            <Grid fluid>
                <Row>
                    <Col md={4}>
                        <Button
                            bsSize={'sm'}
                            bsStyle={'link'}
                            onClick={() => this.syncAllOnThisPage()}
                        >
                            Synchronize all services
                        </Button>
                    </Col>
                </Row>
                <Row>
                    {syncAllModal &&
                        <SyncAllModal
                            createImport={this.props.actions.createImport}
                            stopImport={this.props.actions.stopImport}
                            checkProgress={this.props.actions.checkProgress}
                            getTotalNumber={this.props.actions.getTotalNumber}
                            reload={this.sync}
                            synchronizeManyRecords={this.props.actions.synchronizeManyRecords}
                            show={syncAllModal}
                            onHide={() => this.syncAllOnThisPage()}
                            category={'services'}
                        />}
                    {showDisassociateModal?.show &&
                        <ConfirmDisassociateModal
                            type="service"
                            onHide={() => this.setState({
                                showDisassociateModal: {
                                    show: false,
                                    th_id: null,
                                    index: null
                                }
                            })}
                            onSave={() => {
                                this.props.actions.disassociateService(showDisassociateModal?.th_id, () => {
                                    const {content} = this.state
                                    if (content[showDisassociateModal?.index]) {
                                        content[showDisassociateModal?.index].qb = null;
                                        this.setState(content);
                                    }
                                })
                            }}
                        />
                    }
                    {errorModal?.show &&
                        <ErrorInfoModal
                            type="Service"
                            message={errorModal?.message}
                            onHide={() => {
                                const errorModal = {
                                    show: false,
                                    message: ''
                                }
                                this.setState({errorModal})
                            }}
                        />
                    }
                    <Col xs={12} className={`top25 no-padding`}>
                        <BootstrapTable
                            ref="table"
                            data={content}
                            striped={false} tab
                            bordered
                            hover={true}
                            pagination={true}
                            remote
                            fetchInfo={{dataTotalSize: total}}
                            className="wrapped"
                            trClassName={this.rowSynchronizedClass}
                            options={
                                {
                                    onPageChange: (page, per_page) => {
                                        let {resource} = this.state;
                                        resource = {...resource, page, per_page};
                                        this.setState({resource}, this.sync);
                                    },
                                    page,
                                    sizePerPageList: [{
                                        text: '10', value: 10
                                    }, {
                                        text: '25', value: 25
                                    },
                                        {
                                            text: '30', value: 30
                                        },
                                        {
                                            text: '50', value: 50
                                        },
                                        {
                                            text: '100', value: 100
                                        },
                                        {
                                            text: '200', value: 200
                                        },
                                        {
                                            text: 'All', value: total
                                        }],
                                    sizePerPage: per_page,
                                    sizePerPageDropDown: this.renderSizePerPageDropDown,
                                }
                            }
                            // selectRow={{
                            //     mode: "checkbox",
                            //     clickToSelect: false,
                            //     onSelect: this.onRowSelect,
                            //     onSelectAll: this.onSelectAll,
                            // }}
                            selectRow={{
                                mode: "hidden",
                                clickToSelect: false,
                                selected: this.state.allSelected ? content.map(x => x.id) : this.state.selectedRecords,
                                onSelect: (row, selected) => {
                                    let {selectedRecords, allSelected} = this.state;
                                    const index = selectedRecords.indexOf(row.id);
                                    if (index > -1) {
                                        allSelected = false
                                        selectedRecords.splice(index, 1);
                                    } else {
                                        selectedRecords.push(row.id);
                                        allSelected = selectedRecords.length === content.length
                                    }
                                    this.setState({selectedRecords, allSelected})
                                },
                                onSelectAll: () => {
                                    let {allSelected} = this.state;
                                    this.onSelectAll(!allSelected, content)
                                    this.setState({allSelected: !allSelected})
                                },
                            }}
                        >
                            <TableHeaderColumn
                                dataField="id"
                                hidden
                                width={"5%"}
                                isKey
                            />
                            <TableHeaderColumn
                                className='align-center'
                                columnClassName='align-center'
                                width={"45%"}
                                dataField="th"
                                dataFormat={(cell, row, enumObj, index) => this.serviceFormatter(cell, row, enumObj, index, 'left')}
                            >
                                TreeHub Services
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width={"10%"}
                                className='align-center'
                                columnClassName='align-center'
                                dataFormat={this.choiceFormatter}
                            >
                                Choice
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                className='align-center'
                                columnClassName='align-center'
                                dataField="qb"
                                dataFormat={(cell, row, enumObj, index) => this.serviceFormatter(cell, row, enumObj, index, 'right')}
                                width={"45%"}
                            >
                                QuickBooks Services
                            </TableHeaderColumn>
                        </BootstrapTable>
                    </Col>
                </Row>
            </Grid>
        );
    }
}

QuickbooksServices.propTypes = {
    //myProp: PropTypes.object.isRequired
};

QuickbooksServices.defaultProps = {
    //myProp: <defaultValue>
};

function mapStateToProps(state, ownProps) {
    return {
        clientId: state.auth.client_id
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(QuickbooksServices);
