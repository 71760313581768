import React, {useEffect, useState} from 'react'
import ProgressBar from "./ProgressBar";
import './GeneralProgress.scss'
import ProgressSection from "./ProgressSection";
import * as actions from './GeneralProgressApi'
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import Confetti from "react-dom-confetti";

const config = {
    angle: 360,
    spread: 360,
    startVelocity: 60,
    elementCount: 200,
    width: "15px",
    height: "15px",
    stagger: 5,
    duration: 5000
};

const GeneralProgress = ({setActiveTab, actions}) => {

    const [adminData, setAdminData] = useState([])
    const [customerData, setCustomerData] = useState([])
    const [settingsData, setSettingsData] = useState([])
    const [operationsData, setOperationsData] = useState([])
    const [totalPercent, setTotalPercent] = useState(null)
    const [categoriesPercent, setCategoriesPercent] = useState({})

    const loadProgress = () => actions.getProgress(res => {
        setAdminData(res.admin_progress)
        setCustomerData(res.customer_progress)
        setSettingsData(res.settings_progress)
        setOperationsData(res.operations_progress)
        setTotalPercent(res.total_percent_completed)
        setCategoriesPercent(res.categories_percent)
    })

    const confettiActive = totalPercent === 100;

    useEffect(() => {
        loadProgress()
    }, [])

    useEffect(() => {
        if (confettiActive) {
            document.body.style.overflowX = "hidden";
            setTimeout(() => {
                document.body.style.overflowX = "auto";
            }, config.duration)
        }
    }, [confettiActive]);

    return (
        <div className='general-progress-container'>
            <div className='confetti'>
                <Confetti active={confettiActive} config={config}/>
            </div>
            <ProgressBar progress={totalPercent}/>
            <div className='sections-container'>
                <ProgressSection title={'Admin'} data={adminData}
                                 setActiveTab={setActiveTab}
                                 percent={categoriesPercent.admin_percent}/>
                <ProgressSection title={'Customers'} data={customerData}
                                 percent={categoriesPercent.customer_percent}/>
                <ProgressSection title={'Settings'} data={settingsData}
                                 percent={categoriesPercent.settings_percent}/>
                <ProgressSection title={'Operations'} data={operationsData}
                                 percent={categoriesPercent.operations_percent}/>
            </div>
        </div>
    )

}

const mapDispatchToProps = dispatch => (
    {
        actions: bindActionCreators(actions, dispatch),
    }
)

export default connect(null, mapDispatchToProps)(GeneralProgress)