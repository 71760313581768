import React, {useEffect, useState} from "react";
import Dropzone from "react-dropzone";
import ReorderingDragAndDrop from "../../../../components/ReorderingDragAndDrop/ReorderingDragAndDrop";
import './FilesAnswer.scss'
import {boxesPerRowReorderingDNDDocuments} from "../../../CustomerPage/CustomerInfo/constants";
import {Col, ControlLabel, FormControl, Glyphicon, Label, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import ColorCheckbox from "../../../Scheduler/ColorCheckbox";
import DefaultAnswerFormatter from "../DefaultAnswerFormatter";
import * as actions from './FilesAnswerApi'
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

const FilesAnswer = ({
                         number,
                         question,
                         value,
                         id,
                         options,
                         setAnswerOptions,
                         preview,
                         disabled,
                         actions,
                         onChange
                     }) => {
    const [files, setFiles] = useState([])

    const uploadAttachment = (attachment) => {
        actions.uploadAttachment(attachment, id, newFile => {
            const newFiles = files.concat(newFile)
            setFiles(newFiles)
            onChange(newFiles.map(f => f.id))
        })
    };

    const deleteAttachment = (id) => {
        actions.deleteAttachment(id, () => {
            const updatedFiles = files.filter(file => file.id !== id)
            setFiles(updatedFiles);
            onChange(updatedFiles.map(f => f.id))
        })
    }

    const reorderAttachments = (orderedFiles) => {
        setFiles(orderedFiles)
        onChange(orderedFiles.map(f => f.id))
    };

    const fileOptions = () => {
        return <Row>
            <Col xs={12}>
                <Label bsStyle="info">Dropzone Placeholder</Label>
                <FormControl
                    type="text"
                    placeholder="Dropzone Placeholder"
                    onChange={e => setAnswerOptions({
                        ...options,
                        dropzonePlaceholder: e.target.value
                    })}
                    value={options.dropzonePlaceholder}
                />
            </Col>
            <Col xs={12}>
                <Label bsStyle="info">Attachment Placeholder</Label>
                <FormControl
                    type="text"
                    placeholder="Attachment Placeholder"
                    onChange={e => setAnswerOptions({
                        ...options,
                        attachmentPlaceholder: e.target.value
                    })}
                    value={options.attachmentPlaceholder}
                />
            </Col>
            <Col xs={12}>
                <ColorCheckbox value={options.required}
                               label="Answer will be required"
                               onChange={() => setAnswerOptions({
                                   ...options,
                                   required: !options.required
                               })}
                />
            </Col>
        </Row>
    }

    const fileAnswers = () => {
        return <Row>
            <Col xs={12}>
                <div id="dropzone-element">
                    <ControlLabel className="bold">
                        <span>{number}. {question}</span>
                        {options.required && <OverlayTrigger
                            placement='top'
                            overlay={<Tooltip id={`tooltip-refresh`}>Answer is required</Tooltip>}
                        >
                            <Glyphicon glyph='exclamation-sign' className="ml-6 font14"/>
                        </OverlayTrigger>}
                    </ControlLabel>
                    <Dropzone
                        onDrop={uploadAttachment}
                        disabled={disabled || preview}
                        className="dropzone-area"
                    >
                        <p className="font12">{options.dropzonePlaceholder}</p>
                    </Dropzone>
                    {files?.length > 0 ? <ReorderingDragAndDrop
                            files={files}
                            deleteAttachment={deleteAttachment}
                            reorderAttachments={reorderAttachments}
                            numberOfColumns={boxesPerRowReorderingDNDDocuments}
                        />
                        :
                        <p className='text-center'>{options.attachmentPlaceholder}</p>
                    }
                </div>
            </Col>
        </Row>
    }

    useEffect(() => {
        if (!disabled && !preview) {
            actions.getAnswerFiles(value, res => {
                setFiles(res)
            })
        }
    }, [])

    return preview ?
        <DefaultAnswerFormatter options={fileOptions()} preview={fileAnswers()}/>
        :
        fileAnswers()
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch),
});


export default connect(undefined, mapDispatchToProps)(FilesAnswer)