import React, {useState} from "react";
import {Button, Col, FormControl, Modal, Row} from "react-bootstrap";
import Dropzone from "react-dropzone";
import ReorderingDragAndDrop from "../../../../components/ReorderingDragAndDrop/ReorderingDragAndDrop";
import {boxesPerRowReorderingDNDDocuments} from "../../../CustomerPage/CustomerInfo/constants";

const isMobile = window.screen.width < 768;

const NewDocumentModal = ({
                              newResource,
                              onHide,
                              deleteClientDocument,
                              onDrop,
                              deleteClientFile,
                              saveClientDocument,
                              onReload
                          }) => {
    const [resource, setResource] = useState(newResource)

    const updateDocument = (e, attr) => {
        resource[attr] = e.target.value
        setResource({...resource})
    }

    const hideModal = () => {
        deleteClientDocument(resource?.id, null, () => onHide())
    }

    return <Modal
        show={true}
        animation={true}
        className={"heightAuto maxHeight90 fontIOS"}
        id='new-document-modal'
        backdrop='static'
        onHide={() => hideModal()}
    >
        <Modal.Header>
            <Modal.Title>Add new document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row>
                <Col md={6}>
                    <Row>
                        <Col md={12}>
                            <h4>Title:</h4>
                            <FormControl
                                name="title"
                                placeholder="Title"
                                onChange={e => updateDocument(e, 'title')}
                                value={resource?.title}
                            />
                        </Col>
                        <Col md={12}>
                            <h4>Note:</h4>
                            <FormControl
                                componentClass="textarea"
                                rows={5}
                                type="text"
                                name="note"
                                placeholder="Note"
                                value={resource?.note}
                                onChange={e => updateDocument(e, 'note')}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col md={6}>
                    <Row>
                        <Col xs={12} className="text-left"><h4>Documents:</h4></Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div className="new_document_dropzone">
                                <Dropzone
                                    onDrop={file => onDrop(file, res => {
                                        if (Array.isArray(resource?.document_files_ids)) {
                                            const newClientFiles = resource?.document_files_ids.push(res.id)
                                        } else {
                                            resource.document_files_ids = [res.id]
                                        }
                                        if (Array.isArray(resource?.files)) {
                                            const newFiles = resource?.files.push(res)
                                        } else {
                                            resource.files = [res]
                                        }
                                        saveClientDocument(resource, false, () => setResource({...resource}))
                                    })}
                                    className="dropzone pointer"
                                >
                                    <p>Drop a file here or click to select a file to upload.</p>
                                </Dropzone>
                                <ReorderingDragAndDrop
                                    files={resource?.files ? resource?.files?.sort((a, b) => a.order - b.order) : []}
                                    deleteAttachment={(id) => deleteClientFile(id, () => {
                                        resource.document_files_ids = resource?.document_files_ids.filter(cf => cf !== id)
                                        resource.files = resource?.files.filter(f => f.id !== id)

                                        saveClientDocument(resource, false, () => setResource({...resource}))
                                    })}
                                    reorderAttachments={(orderedFiles) => {
                                        resource.document_files_ids = orderedFiles.map((image) => image.id)
                                    }}
                                    numberOfColumns={boxesPerRowReorderingDNDDocuments}
                                />
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Modal.Body>
        <Modal.Footer>
            {isMobile ? <div className="d-flex pl-5 pr-5 sb">
                <div>
                    <Button bsSize="small" onClick={() => hideModal()}>Close</Button>
                </div>
                <div>
                    <Button
                        bsStyle="success"
                        bsSize="small"
                        onClick={() => saveClientDocument(resource, true, () => {
                            onReload()
                            onHide()
                        })}
                    >
                        Save
                    </Button>
                </div>
            </div> : <Row>
                <Col md={6} className="text-left">
                    <Button bsSize="small" onClick={() => hideModal()}>Close</Button>
                </Col>
                <Col md={6} className="text-right">
                    <Button
                        bsStyle="success"
                        bsSize="small"
                        onClick={() => saveClientDocument(resource, true, () => {
                            onReload()
                            onHide()
                        })}
                    >
                        Save
                    </Button>
                </Col>
            </Row>}
        </Modal.Footer>
    </Modal>
}

export default NewDocumentModal;