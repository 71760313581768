import React from 'react';
import {Col, FormControl, FormGroup, Row} from "react-bootstrap";
import Datetime from "react-datetime";
import {defaultDateFormat, defaultTimeFormat} from "../../../common/commonHandlers";
import ResourceComponent from "../../../components/ResourceComponent";
import moment from "moment";

class DeletedProposalsFilters extends ResourceComponent {

    handleChangeSendDateFrom = (e, date) => {
        let {resource} = this.props;
        if (e && moment(e).isValid()) {
            resource[date] = moment(e).startOf('day');
        } else {
            resource[date] = ""
        }
        this.setState(resource, this.search);
    };

    handleChangeSendDateTo = (e, date) => {
        let {resource} = this.props;
        if (e && moment(e).isValid()) {
            resource[date] = moment(e).endOf('day');
        } else {
            resource[date] = ""
        }
        this.setState(resource, this.search);
    };

    render() {
        const {resource, onChangeFilters} = this.props;
        const {created_from, created_to, arborist, proposal_no} = resource;

        return (
            <div>
                <Row className="mt-15">
                    <Col md={6}>
                        <FormGroup>
                            <Datetime
                                dateFormat={defaultDateFormat}
                                timeFormat={defaultTimeFormat}
                                value={created_from}
                                className="created_from"
                                inputProps={{placeholder: 'Created from'}}
                                closeOnSelect
                                onChange={e => this.handleChangeSendDateFrom(e, 'created_from')}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Datetime
                                dateFormat={defaultDateFormat}
                                timeFormat={defaultTimeFormat}
                                value={created_to}
                                className="created_to"
                                inputProps={{placeholder: 'Created to'}}
                                closeOnSelect
                                onChange={e => this.handleChangeSendDateTo(e, 'created_to')}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <FormControl
                                placeholder="Arborist"
                                name="arborist"
                                value={arborist}
                                onChange={(e) => onChangeFilters(e.target.name, e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <FormControl
                                placeholder="Proposal #"
                                name="proposal_no"
                                type="number"
                                value={proposal_no}
                                onChange={(e) => onChangeFilters(e.target.name, e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </div>
        );
    };
}

export default DeletedProposalsFilters;