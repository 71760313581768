import {
    CREATE_STICKY_SUCCESS,
    FETCH_STICKIES_SUCCESS,
    REPLY_STICKY_SUCCESS,
    UPDATE_NEW_STICKY_BODY,
    UPDATE_REPLY_STICKY_BODY
} from './constants'

//note stickyReplyingTo is the first sticky in that group. not the previous, timewise i.e. its only single threaded comments.
const initialState = {
    replyStickyBody: '',
    stickyReplyingTo: null,
    newStickyBody: '',
    stickies: [],
    proposalId: null,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_STICKIES_SUCCESS:
            return {...state, stickies: action.stickies, proposalId: action.proposalId}
        case UPDATE_NEW_STICKY_BODY:
        case CREATE_STICKY_SUCCESS:
            return {...state, newStickyBody: action.newStickyBody}
        case REPLY_STICKY_SUCCESS:
        case UPDATE_REPLY_STICKY_BODY:
            return {...state, replyStickyBody: action.replyStickyBody, stickyReplyingTo: action.stickyReplyingTo}
        default:
            return state
    }
}

export default reducer