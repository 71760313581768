import React from "react"
import {Col, Row} from "react-bootstrap"
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table"
import PropTypes from "prop-types";
import VersionHistoryDetails from "./VersionHistoryDetails";
import {dateFormatter} from "../../../../components/BootStrapTableCellFormatters";

const VersionHistoryTable = ({proposalVH, toggleView, forwardRef}) => {
    const options = {
        page: 1,
        sizePerPageList: [5, 10, 15, 25, 50],
        sizePerPage: 25,
        paginationSize: 2,
        onRowClick: (version) => toggleView(version)
    }

    return (
        <Row id='vh-bs-table'>
            <Col md={12}>
                <BootstrapTable
                    ref={forwardRef}
                    data={proposalVH}
                    striped={true}
                    bordered={false}
                    hover={true}
                    pagination={true}
                    options={options}
                >
                    <TableHeaderColumn
                        width='10%'
                        isKey
                        dataSort={true}
                        dataField="order_no"
                    >
                        #
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        width="20%"
                        tdStyle={{whiteSpace: 'normal'}}
                        dataSort={true}
                        columnClassName="menu-show-fixer"
                        dataField="date_time"
                        dataFormat={dateFormatter}
                    >
                        Date/Time
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        tdStyle={{whiteSpace: 'normal'}}
                        width="35%"
                        dataSort={true}
                        dataField="edited_by"
                    >
                        User
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        tdStyle={{whiteSpace: 'normal'}}
                        width="35%"
                        dataSort={true}
                        dataField="activity"
                    >
                        Activity
                    </TableHeaderColumn>
                </BootstrapTable>
            </Col>
        </Row>
    )

}

VersionHistoryDetails.propTypes = {
    proposalVH: PropTypes.array.isRequired,
    toggleView: PropTypes.func
}

export default VersionHistoryTable
