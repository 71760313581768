import React from 'react';
import {Glyphicon} from "react-bootstrap";

export const MediaComponent = ({
                                   messageId,
                                   imageId,
                                   imageSrcMini,
                                   imageSrcOriginal,
                                   removeMessageFile,
                                   is_author,
                                   onSelectImage,
                                   toggleImageModal,
                                   isCalendarView
                               }) => (
    <div style={{position: 'relative', margin: messageId ? '0 5px 0 0 ' : '5px 2px 3px 5px'}}>
        {is_author &&
            <a
                className="remove-attachement-icon"
                title="Delete this image"
                onClick={() => removeMessageFile(messageId, imageId, "images")}
            >
                                                        <span className="text-danger">
                                                          <Glyphicon glyph="remove-sign" className="pointer"/>
                                                        </span>
            </a>}
        <a href="#" onClick={e => {
            e.preventDefault()
            onSelectImage(imageSrcOriginal)
            toggleImageModal(true)
        }} id={`image_${imageId}`}>
            <img alt="" src={imageSrcMini} style={{width: 36, height: 36}}/>
        </a>
    </div>
)
