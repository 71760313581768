import {
    SET_BASE_URL,
    SET_CLIENT_APP_VERSION,
    SET_EQUIPMENT_INTERVAL,
    SET_EQUIPMENT_TIMEOUT,
    SET_NEW_CLIENT_APP_VERSION,
    SIGN_IN_USER_FAILED,
    SIGN_IN_USER_START,
    SIGN_IN_USER_SUCCESS,
    SIGN_OUT_USER,
    UPDATE_CLIENT,
    UPDATE_EMAIL,
    UPDATE_PASSWORD,
    UPDATE_ROLES,
} from './constants'

import {IMPERSONATE_SUCCESS} from '../AdminPage/constants'

const initialState = {
    client: "",
    client_id: "",
    email: "",
    password: "",
    isAuthenticated: false,
    token: "",
    name: "",
    baseUrl: "",
    roles: [],
    newMapView: true,
    newInvoicesView: true,
    isCustomerAuthenticated: false,
    download_notifications: false,
    clientAppVersion: null,
    newClientAppVersion: null,
    equipmentInterval: null,
    equipmentTimer: null
};

const clearEquipmentChecklist = (state) => {
    if (state.equipmentTimer) {
        clearTimeout(state.equipmentTimer);
    }
    if (state.equipmentInterval) {
        clearInterval(state.equipmentInterval)
    }
}

export function authReducer(state = initialState, action) {
    switch (action.type) {
        case SIGN_OUT_USER:
            clearEquipmentChecklist(state)
            return {
                ...initialState,
                clientAppVersion: state.clientAppVersion,
                newClientAppVersion: state.newClientAppVersion
            };
        case SIGN_IN_USER_SUCCESS:
            clearEquipmentChecklist(state)
        case IMPERSONATE_SUCCESS:
            clearEquipmentChecklist(state)
            return {
                ...state,
                isAuthenticated: action.isAuthenticated,
                client: action.client,
                client_id: action.client_id,
                roles: action.roles,
                email: action.email,
                password: action.password,
                token: action.token,
                employee_id: action.employee_id,
                user_id: action.user_id,
                name: action.name,
                isCustomerAuthenticated: action.isCustomerAuthenticated,
                download_notifications: action.download_notifications,
            }
        case SIGN_IN_USER_FAILED:
            return {...state, isAuthenticated: false}
        case UPDATE_CLIENT:
            return {...state, client: action.client}
        case UPDATE_EMAIL:
            return {...state, email: action.email}
        case UPDATE_PASSWORD:
            return {...state, password: action.password}
        case SET_BASE_URL:
            return {...state, baseUrl: action.baseUrl}
        case SET_CLIENT_APP_VERSION:
            return {...state, clientAppVersion: action.version};
        case SET_NEW_CLIENT_APP_VERSION:
            return {...state, newClientAppVersion: action.version};
        case SIGN_IN_USER_START:
        case SET_EQUIPMENT_INTERVAL:
            return {...state, equipmentInterval: action.equipmentInterval};
        case SET_EQUIPMENT_TIMEOUT:
            return {...state, equipmentTimer: action.equipmentTimer};
        case UPDATE_ROLES:
            return {...state, roles: action.roles}
        default:
            return state
    }
}
