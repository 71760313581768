import {Col, Modal, Row} from "react-bootstrap";
import React, {Component} from 'react';


class WorkOrdersFilterModal extends Component {

    render() {
        return (
            <Modal
                animation={false}
                show={this.props.show}
                onHide={this.props.onHide}
                backdrop={this.props.backdrop}
                className={"heightAuto maxHeight90 modalLeadsFilterBody fontIOS"}
                style={this.props.overflow}
            >
                <Modal.Header closeButton={!this.props.declinedModal}>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={this.props.modalBodyStyle}>
                    {this.props.children}
                </Modal.Body>

                {!this.props.hiddenFooter &&
                    <Modal.Footer>
                        <Row>
                            <Col md={6} className="text-left">
                                {this.props.infoContent}
                            </Col>
                            {!this.props.showAcceptBtn ?
                                <Col md={6}>
                                    <button type="button" onClick={this.props.closeButton} className="btn btn-default"
                                            data-dismiss="modal">Close
                                    </button>
                                    <button type="button" onClick={this.props.onHide} className="btn btn-success">Save
                                    </button>
                                </Col>
                                :
                                <Col md={6}>
                                    <button type="button" onClick={this.props.onHide} className="btn btn-success">OK
                                    </button>
                                </Col>
                            }
                        </Row>
                    </Modal.Footer>}
            </Modal>
        )
    }
}

export default WorkOrdersFilterModal;
