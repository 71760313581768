import {Modal} from "react-bootstrap";
import React, {Component} from 'react';

const isMobile = window.screen.width < 1024

class EmailEditorPreview extends Component {

    render() {
        return (
            <Modal
                animation={false}
                show={this.props.show}
                onHide={this.props.onHide}
                className={"heightAuto maxHeight90 modalLeadsFilterBody fontIOS"}
                style={this.props.overflow}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className={isMobile ? "preview_modal preview_modal_scroll" : "preview_modal"}>
                    {this.props.children}
                </Modal.Body>
            </Modal>
        )
    }
}

export default EmailEditorPreview;
