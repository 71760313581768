import {addTimeZoneHeader, apiCall} from "../../../common/apiActionHandler";
import axios from "axios/index";
import {mapPersonSearch} from "../../../common/commonHandlers";
import {FETCH_CUSTOMER_INFO} from "../constants";

export const loadUserProfile = (callback) => (dispatch, getState) => {
    const config = {
        url: `/api/user_profile`
    };

    apiCall("Loading user profile", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const saveUserProfile = (user_obj) => (dispatch, getState) => {
    const user = Object.assign({}, user_obj);
    delete user.recipients;

    const config = {
        url: `/api/user_profile`,
        method: 'PUT',
        data: {user}
    };

    apiCall("Saving user settings", config, async config => {
        await axios.request(config);
    }, dispatch, getState);
};

export const saveClientSettings = (clientSettings) => (dispatch, getState) => {
    let config = {
        url: `/api/clients/change_client_settings`,
        method: 'PUT',
        data: clientSettings
    };
    config = addTimeZoneHeader(config);
    apiCall("Saving client settings", config, async config => {
        const clientResponse = await axios.request(config);
        dispatch({type: FETCH_CUSTOMER_INFO, customerInfo: clientResponse.data})
    }, dispatch, getState);
};

export const getEmployees = (callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/search/all_employees`
    };

    apiCall("Getting employees", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const search = (q, customer_id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/search/search_for_guest`,
        params: {q, customer_id}
    };

    apiCall("Searching for guests", config, async config => {
        const response = await axios.request(config);
        callback && callback(mapPersonSearch(response.data));
    }, dispatch, getState, false);
};
