import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as MyActions from "./actions"
import {addAlert} from "../App/actions";
import {showDock} from "../../components/dock/actions";
import {Button, Col, Row} from "react-bootstrap";
import CustomerViewMenu from "./CustomerViewMenu";
import './CustomerViewPage.scss'

import CustomerViewTabs from "./CustomerViewTabs";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import CustomerMessages from "./CustomerEvents/CustomerMessages";
import CustomerAppointments from "./CustomerEvents/CustomerAppointments";
import CustomerRequests from "./CustomerEvents/CustomerRequests";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import NewRequestModal from "./NewRequestModal";
import NewMessageModal from "./NewMessageModal";

const Actions = {...MyActions, addAlert, showDock}

const isMobile = window.screen.width < 1024;

class CustomerViewMessages extends Component {
    state = {
        customerEvents: {},
        loading: true,
        request: {},
        showModal: false,
        showMessageModal: false,
        loadAllRecords: false,
        customerEvent: {
            data: [],
            type: ""
        }
    }

    getCustomerEvents = () => {
        this.props.actions.getCustomerEvents(
            this.props.params.token,
            this.props.site?.selected?.id,
            true,
            res => {
                this.setState({
                    customerEvents: res.customerEvents,
                    loading: false,
                    customerEvent: {
                        data: res.customerEvents.messages,
                        type: "messages"
                    }
                })
            })
    }

    componentWillMount = () => {
        if (this.props.params.token) {
            this.getCustomerEvents()
        } else {
            //should never get here because router should not mount this page and instead 404
            this.props.actions.addAlert({
                message: "Proposal not given. I can't go on!",
            })
        }
    }

    menuItems = [
        {label: 'Messages'},
        {label: 'Requests'},
        {label: 'Appointments'},
    ]

    setEvent = (type) => {
        const {customerEvents} = this.state
        const eventType = type.toLowerCase()
        const customerEvent = customerEvents[`${eventType}`]

        this.setState({
            customerEvent: {
                data: customerEvent,
                type: eventType
            }
        })
    }

    displayEvent = () => {
        const {customerEvent} = this.state
        const eventData = customerEvent.data
        const eventType = customerEvent.type

        if (eventType === "messages") {
            return <CustomerMessages messages={eventData} sendMessage={this.props.actions.addNote}
                                     reload={this.getCustomerEvents}/>
        } else if (eventType === "appointments") {
            return <CustomerAppointments appointments={eventData}/>
        } else if (eventType === "requests") {
            return <CustomerRequests requests={eventData}/>
        }
    }

    openModal = () => {
        this.setState({showModal: true, request: null})
    }

    closeModal = () => {
        this.setState({showModal: false})
    }
    closeMessageModal = () => {
        this.setState({showMessageModal: false})
    }

    render() {
        const {client, params, site} = this.props
        const {loading, customerEvent, request, showModal, showMessageModal} = this.state
        const clientLogo = client ? client.client_logo_selected : ''
        return (<>
                <div id="customerView" className={`${isMobile ? "customerView--Mob" : "customerView"}`}>
                    <Row className='customerView__header customerView__header--disable-grid'>
                        <Col md={2} className='company-logo'><img src={clientLogo}/></Col>
                        <Col md={8} className='align-center white-stripe'>
                            {!loading &&
                                <>
                                    <p className='title'>{this.props.site?.selected?.name}: {customerEvent.type}</p>
                                    <p className='subtitle'>{customerEvent.data.length < 1 ? `It looks like you don't have any ${customerEvent.type} yet` : ""}</p>
                                </>
                            }
                        </Col>
                        <Col md={2} className='right-side-header'>
                            <Button className='request-btn' onClick={this.openModal}
                                    disabled={site?.selected?.site_moved}>
                                <FontAwesomeIcon className='font14 mr-11' icon={faPlus}/>
                                <span>NEW REQUEST</span>
                            </Button>
                            <Button className='request-btn' onClick={() => this.setState({showMessageModal: true})}>
                                <FontAwesomeIcon className='font14 mr-11' icon={faPlus}/>
                                <span>NEW MESSAGE</span>
                            </Button>
                        </Col>
                    </Row>
                    {isMobile
                        ? <Col md={12}>
                            <CustomerViewMenu token={this.props.params.token}/>
                        </Col>
                        : <Row className="customerView__tabs">
                            <Col md={12}>
                                <CustomerViewTabs token={params.token}/>
                            </Col>
                        </Row>
                    }
                    <Row
                        className={`customerView__content ${isMobile ? "c-events--mobile" : ""}`}>
                        {!loading &&
                            <Col md={8} sm={10} xs={10} className='events'>
                                <Row>
                                    <Col md={3}
                                         className={`events__left-menu ${isMobile ? "align-left events__left-menu--mobile" : "align-right"}`}>
                                        {this.menuItems.map(item => (
                                            <p className={customerEvent.type.toLowerCase() === item.label.toLowerCase() ? "events__left-menu--selected" : "bla"}
                                               onClick={() => this.setEvent(item.label)}>
                                                {item.label}
                                            </p>
                                        ))}
                                    </Col>
                                    <Col md={9} className='items-container'>
                                        {this.displayEvent()}
                                    </Col>
                                </Row>
                            </Col>
                        }
                    </Row>
                </div>
                {showModal && <NewRequestModal showModal={showModal}
                                               closeModal={this.closeModal}
                                               token={this.props.params.token}
                                               request={request}
                                               reload={this.getCustomerEvents}
                />}
                {showMessageModal && <NewMessageModal showModal={showMessageModal}
                                                      token={this.props.params.token}
                                                      reload={this.getCustomerEvents}
                                                      closeModal={this.closeMessageModal}
                />
                }
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        client: state.client.customerInfo,
        site: state.customerView.site
    }
}


const mapDispatchToProps = dispatch => {
    return {actions: bindActionCreators(Actions, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerViewMessages)

