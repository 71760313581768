import React, {useEffect, useState} from "react";
import {
    Button,
    Checkbox,
    Col,
    ControlLabel,
    FormControl,
    FormGroup,
    Modal,
    OverlayTrigger,
    Row,
    Tooltip
} from "react-bootstrap";
import Select from "react-select";
import {select} from "../../../../common/commonHandlers";
import Dropzone from "react-dropzone";
import ReorderingDragAndDrop from "../../../../components/ReorderingDragAndDrop/ReorderingDragAndDrop";
import {boxesPerRowReorderingDND} from "../../../SiteEditorModal/constants";
import {debounce} from "throttle-debounce";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import InfoModal from "../../../../common/InfoModal";
import MovedSiteModal from "./MovedSiteModal";
import './CustomerSitesEditModal.scss'
import ConfirmationSaveModal from "./ConfirmationSaveModal";
import MDSpinner from "react-md-spinner";
import LeadListModal from "./LeadListModal";

const CustomerSitesEditModal = ({
                                    onSuccess,
                                    customerId,
                                    onSave,
                                    editedSite,
                                    onHide,
                                    onDelete,
                                    customerContacts,
                                    uploadSiteAttachment,
                                    deleteSiteAttachment,
                                    reorderSieAttachment,
                                    selectSiteAttachmentForPrint,
                                    getSites,
                                    onCustomerSearch,
                                    onCustomerContactSearch,
                                    roles,
                                    fetchStateAndCityForSite,
                                    getMovedProposals,
                                    fetchOrdinances,
                                    getProposalsForInactive,
                                    setCustomerSites,
                                    getLeadList,
                                    deleteLead
                                }) => {
    const [site, setSite] = useState(editedSite)
    const [siteBasicData, setSiteBasicData] = useState(editedSite)
    const [customerOptions, setCustomerOptions] = useState([])
    const [newCustomerContactsOptions, setNewCustomerContactsOptions] = useState([])
    const [newCustomerId, setNewCustomerId] = useState(customerId)
    const [showMovedSiteModal, setShowMovedSiteModal] = useState({show: false, movedSiteModalData: {}})
    const [ordinanceOptions, setOrdinanceOptions] = useState([])
    const [addMoreSiteContacts, setAddMoreSiteContacts] = useState(false)
    const [showInfoModal, setShowInfoModal] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [showConfirmationSaveModal, setShowConfirmationSaveModal] = useState({
        show: false,
        onSaveWith: null,
        onSave: null
    })
    const [loadingAttachments, setLoadingAttachments] = useState(false)
    const [showLeadListModal, setShowLeadListModal] = useState(false)

    const isSiteValid = () => {
        const isMovedSiteValid = () => {
            return newCustomerId && newCustomerId !== customerId ? site.moved_site_contact_id : true
        }

        return site &&
            isMovedSiteValid() &&
            site?.customer_contact_id &&
            site?.name?.trim().length > 0 &&
            site?.address?.trim().length > 0 &&
            site?.city?.trim().length > 0 &&
            site?.state?.trim().length > 0 &&
            site?.zip?.trim().length === 5 &&
            site?.country?.trim().length > 0 &&
            !site?.address?.includes(':') &&
            !site?.name?.includes(':')
    }


    const customerContactsOptions = customerContacts?.map(c => ({
        value: c.id,
        label: c.person_name,
    })) || []

    const customerSecondaryContactsOptions = customerContacts.filter(c => c.id !== site?.customer_contact_id && !site?.additional_customer_contact_ids?.includes(c.id))?.map(c => ({
        value: c.id,
        label: c.person_name,
    })) || []


    const isAdditionalContacts = site?.additional_customer_contact_ids?.length > 0 || addMoreSiteContacts

    let additionalContactOptions = []
    if (site?.customer_contact_id) {
        additionalContactOptions = customerContacts.filter(x => x.id !== site?.customer_contact_id && x.id !== site?.secondary_customer_contact_id).map(c => ({
            value: c.id,
            label: c.person_name,
        }))
    }

    const reloadSite = (callback) => {
        getSites(customerId, res => {
            const newSite = res.find(s => s.id === site.id)
            setSite({
                ...site,
                files: newSite.files,
                has_assets: newSite.has_assets,
                has_proposals: newSite.has_proposals,
                has_leads: newSite.has_leads
            })
            setCustomerSites(res)
            callback && callback()
        })
    }

    const saveSite = e => {
        e.preventDefault()
        setSubmitted(true)

        const save = (doUpdate = false, callback = null, errorCallback = null) => {
            if (site?.moved_site_contact_id) {
                getMovedProposals(site?.id, newCustomerId || customerId, res => {
                    setShowMovedSiteModal({show: true, movedSiteModalData: res})
                })
            } else {
                onSave(doUpdate ? {...site, updateSettings: true} : site, newCustomerId || customerId, () => {
                    callback && callback()
                    onSuccess()
                    onHide()
                }, () => {
                    errorCallback && errorCallback()
                })
            }
        }

        if (isSiteValid()) {
            if (site?.moved_site_contact_id) {
                getMovedProposals(site?.id, newCustomerId || customerId, res => {
                    setShowMovedSiteModal({show: true, movedSiteModalData: res})
                })
            } else {
                if (site?.id && ((siteBasicData.name?.trim() || "") !== (site.name?.trim() || "") ||
                    (siteBasicData.address?.trim() || "") !== (site.address?.trim() || "") ||
                    (siteBasicData.zip?.trim() || "") !== (site.zip?.trim() || "") ||
                    (siteBasicData.city?.trim() || "") !== (site.city?.trim() || "") ||
                    (siteBasicData.state?.trim() || "") !== (site.state?.trim() || "")
                )) {
                    setShowConfirmationSaveModal({
                        show: true,
                        onSaveWith: (callback, errorCallback) => save(true, callback, errorCallback),
                        onSave: () => save()
                    })
                } else {
                    save()
                }
            }
        }
    }

    const deleteSite = e => {
        e.preventDefault()
        onDelete(site.id, () => {
            onSuccess()
            onHide()
        })

    }

    const updateZip = (zip, site) => {
        fetchStateAndCityForSite(zip, (response) => {
            const zip = response.zipcode;
            const state = response.state ? response.state : site?.state;
            const city = response.city ? response.city : site?.city;
            fetchOrdinances(zip, (res) => {
                if (res.length < 1) {
                    setSite({...site, zip: zip, state: state, city: city, ordinance_id: null});
                } else {
                    setSite({...site, zip: zip, state: state, city: city});
                }
                setOrdinanceOptions(res)
            })
        })
    }

    const debounceUpdateZip = debounce(1000, false, (zip, site) => updateZip(zip, site))

    const searchCustomer = q => {
        if (q && q.length > 0) {
            debounce(1000, onCustomerSearch(q, res => setCustomerOptions(res)))
        }
    }

    const onCustomerChange = (e) => {
        if (e?.value) {
            onCustomerContactSearch(e.value, res => {
                const options = res.map(c => {
                    return {value: c.id, label: c.person_name}
                })
                setNewCustomerContactsOptions(options)
            })
        }
        setNewCustomerId(e?.value ? e?.value : customerId)
        setSite({...site, moved_site_contact_id: null})
    }
    const onOrdinanceChange = (e) => {
        setSite({...site, ordinance_id: e?.value})
    }

    const uploadAttachment = (attachment) => {
        uploadSiteAttachment(attachment, site.id, () => reloadSite())
    }

    const deleteAttachment = (id) => {
        setLoadingAttachments(true)
        deleteSiteAttachment(id, () => {
            reloadSite(() => setLoadingAttachments(false))
        }, () => setLoadingAttachments(false))
    }

    const reorderAttachments = (orderedFiles) => {
        const order = JSON.stringify(orderedFiles.map((image) => image.id))
        reorderSieAttachment(order, site.id, () => reloadSite())
    };

    const selectAttachment = (id) => {
        selectSiteAttachmentForPrint(id, () => reloadSite())
    }

    const selectElement = (label, value, options, placeholder, isMulti = false, isClearable = false, invalid = false) => {
        return <>
            <ControlLabel>{label}</ControlLabel>
            <Select className={`Select ${(submitted && invalid) ? 'invalid' : ''}`}
                    classNamePrefix="select"
                    name={value}
                    onChange={e => {
                        if (isMulti) {
                            setSite({...site, [value]: e.map(x => x.value)})
                        } else {
                            setSite({...site, [value]: e?.value})
                        }
                    }}
                    placeholder={placeholder}
                    isClearable={isClearable}
                    value={site && select(options, site[value])}
                    options={options}
                    isMulti={isMulti}
            />
        </>
    }

    const inputElement = (label, value, placeholder, disabled = false, smallInput = true, invalid, customClassName) => {
        return <>
            <FormGroup bsSize={smallInput ? 'small' : ''} className={customClassName && customClassName}>
                <ControlLabel>{label}</ControlLabel>
                <FormControl
                    placeholder={placeholder}
                    name={value}
                    type={'text'}
                    className={`${(submitted && invalid) ? 'invalid' : ''}`}
                    disabled={disabled}
                    onChange={e => {
                        setSite({...site, [value]: e.target.value})
                        if (value === 'zip' && e.target.value.length === 5) {
                            debounceUpdateZip(e.target.value, site)
                        }
                    }}
                    value={site ? site[value] : ""}
                />
            </FormGroup>
        </>
    }
    const textareaElement = (label, value, placeholder, disabled = false) => {
        return <>
            <FormGroup bsSize="small">
                <ControlLabel>{label}</ControlLabel>
                <FormControl
                    componentClass={'textarea'}
                    placeholder={placeholder}
                    name={value}
                    rows={5}
                    type={'textarea'}
                    disabled={disabled}
                    onChange={e => setSite({...site, [value]: e.target.value})}
                    value={site ? site[value] : ""}
                />
            </FormGroup>
        </>
    }

    const deleteButton = () => {
        return <Button
            className="mr-5"
            bsStyle="danger"
            bsSize="xs"
            onClick={deleteSite}
            disabled={!site?.id || site?.has_assets || site?.has_proposals || site?.has_leads}
        >
            Delete
        </Button>
    }

    useEffect(() => {
        if (!site?.id && !site?.customer_id) {
            setSite({...site, customer_id: customerId, country: 'United States'})
        }
        if (site) {
            fetchOrdinances(site.zip, (res) => {
                setOrdinanceOptions(res)
            })
        }
    }, [])

    return <Modal
        bsSize="large"
        show={true}
        onHide={onHide}
        backdrop="static"
        className="heightAuto fontIOS"
        id='customer-site-edit-modal'
    >
        <form onSubmit={saveSite}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {site?.id > 0 ? " Editing" : " New"} Site
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{maxHeight: "700px"}}>
                <Row className='mt10 align-text-end'>
                    <Col xs={isAdditionalContacts ? 6 : 5} sm={6}>
                        {selectElement("Primary Contact*", "customer_contact_id", customerContactsOptions, "Contact", false, false, !site?.customer_contact_id)}
                    </Col>
                    <Col xs={isAdditionalContacts ? 6 : 5}>
                        {selectElement("Secondary Contact", "secondary_customer_contact_id", customerSecondaryContactsOptions, "Second Contact", false, true)}
                    </Col>
                    {!isAdditionalContacts && <Col xs={1} className='no-padding'>
                        <Button
                            bsStyle="success"
                            onClick={() => setAddMoreSiteContacts(true)}
                        >
                            <FontAwesomeIcon icon="plus"/>
                        </Button>
                    </Col>}
                </Row>
                {isAdditionalContacts &&
                    <Row>
                        <Col xs={12}>
                            {selectElement("Additional Contacts", "additional_customer_contact_ids", additionalContactOptions, "Additional Contacts", true, true)}
                        </Col>
                    </Row>}
                <Row className='no-margin'>
                    <Col xs={12} className='mt-15 no-padding'>
                        {inputElement("Site Name*", "name", "Site Name", false, true, !(site?.name?.trim().length > 0 && !site?.name?.includes(':')), 'no-margin')}
                    </Col>
                    <Col xs={12} className='font10 pl-20'>
                        Name cannot contain characters: ":"
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col xs={12} md={8}>
                        {inputElement("Full Address", "full_address", "Full Address", true, false)}
                    </Col>
                    <Col xs={12} md={4}>
                        <ControlLabel>Ordinance</ControlLabel>
                        <Select
                            options={ordinanceOptions}
                            isClearable
                            name='ordinance_id'
                            onChange={onOrdinanceChange}
                            placeholder={ordinanceOptions.length ? "Select ordinance" : "No matching ordinances"}
                            value={site && select(ordinanceOptions, site.ordinance_id)}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={4}
                         xs={12}>{inputElement("Address*", "address", "Address", false, true, !(site?.address?.trim().length > 0 && !site?.address?.includes(':')))}</Col>
                    <Col md={2}
                         xs={3}>{inputElement("City*", "city", "City", false, true, !(site?.city?.trim().length > 0))}</Col>
                    <Col md={2}
                         xs={3}>{inputElement("State*", "state", "State", false, true, !(site?.state?.trim().length > 0))}</Col>
                    <Col md={2}
                         xs={3}>{inputElement("Zip*", "zip", "Zip", false, true, !(site?.zip?.trim().length === 5))}</Col>
                    <Col md={2}
                         xs={3}>{inputElement("Country*", "country", "Country", false, true, !(site?.country?.trim().length > 0))}</Col>
                </Row>
                {site?.id > 0 && (roles?.includes("admin") || roles?.includes("scheduler")) &&
                    <div className='site-moving-section'>
                        <Row>
                            <Col xs={12} md={4}>
                                <p>Move site to another customer:</p>
                            </Col>
                            <Col xs={12} md={8}>
                                <Select
                                    options={customerOptions}
                                    isClearable
                                    name='customer_id'
                                    onInputChange={searchCustomer}
                                    onChange={onCustomerChange}
                                    placeholder="Customer Name"
                                />
                            </Col>
                        </Row>
                        <Row className='mt10'>
                            <Col xs={12} md={4}>
                                <p>Select site contact:</p>
                            </Col>
                            <Col xs={12} md={8}>
                                <Select
                                    className={`Select ${(submitted && !site?.moved_site_contact_id) ? 'invalid' : ''}`}
                                    classNamePrefix="select"
                                    name="moved_site_contact_id"
                                    isDisabled={!newCustomerId || !customerId || newCustomerId === customerId}
                                    onChange={e => setSite({...site, moved_site_contact_id: e.value})}
                                    placeholder="Moved Site Contact"
                                    value={site && select(newCustomerContactsOptions, site.moved_site_contact_id)}
                                    options={newCustomerContactsOptions}
                                />
                            </Col>
                        </Row>
                    </div>
                }
                <Row>
                    <Col md={12}>{textareaElement("Site Note", "notes", "Site Note")}</Col>
                </Row>
                {site && site.id && <div className="site-moving-section">
                    <Row>
                        <Col xs={12} className="text-left">Attachments</Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div className="dropzone_with_thumbnails">
                                <Dropzone onDrop={uploadAttachment}
                                          className="dropzone pointer border_none">
                                    <p>Drop a file here or click to select a file to upload.</p>
                                </Dropzone>
                                {loadingAttachments ?
                                    <div className="vhCenter" style={{height: '135px'}}>
                                        <MDSpinner size={50} borderSize={2}/>
                                    </div>
                                    :
                                    site?.files?.length > 0 ? <ReorderingDragAndDrop
                                            files={site.files.sort((a, b) => a.order - b.order)}
                                            deleteAttachment={deleteAttachment}
                                            reorderAttachments={reorderAttachments}
                                            numberOfColumns={boxesPerRowReorderingDND}
                                            selectAttachment={selectAttachment}
                                        />
                                        :
                                        <p className='text-center'>Site has no photos!</p>

                                }
                            </div>
                        </Col>
                    </Row>
                </div>}
            </Modal.Body>
            <Modal.Footer>
                <Row className="vertical-align">
                    <Col className="text-left" xs={4}>
                        {site?.has_assets || site?.has_proposals || site?.has_leads ?
                            <OverlayTrigger
                                placement={'top'}
                                overlay={
                                    <Tooltip id='delete-site-tooltip'>
                                        <Row className="text-left">
                                            <Col xs={12}><b>Site cannot be removed because it is associated
                                                with:</b></Col>
                                            {site?.has_assets && <Col xs={12}>Assets</Col>}
                                            {site?.has_proposals && <Col xs={12}>Proposals</Col>}
                                            {site?.has_leads && <Col xs={12}>Leads</Col>}
                                        </Row>
                                    </Tooltip>
                                }
                            >
                            <span>
                                {deleteButton()}
                            </span>
                            </OverlayTrigger>
                            :
                            deleteButton()
                        }
                        <Button
                            className="mr-5"
                            bsSize="xs"
                            bsStyle="warning"
                            onClick={onHide}
                        >
                            Cancel
                        </Button>
                        {site?.has_leads && <Button
                            className="no-margin"
                            bsSize="xs"
                            bsStyle="danger"
                            onClick={() => setShowLeadListModal(true)}
                        >
                            Lead List
                        </Button>}
                    </Col>

                    <Col className="text-center vcenter" xs={4}>
                        <Row>
                            <Col md={4} xs={12}>
                                {site?.id && <Checkbox
                                    className="no-margin"
                                    name="inactive"
                                    onChange={() => {
                                        setSite({...site, inactive: !site.inactive})
                                        setShowInfoModal(true)
                                    }}
                                    checked={site.inactive}
                                >
                                    <span className="checkbox-label">Inactive</span>
                                </Checkbox>}
                            </Col>
                            <Col md={8} xs={12}>
                                Recent
                                Arborist: {site && site.last_site_arborist !== null ? site.last_site_arborist : "Sorry - not, sure"}
                            </Col>
                        </Row>
                    </Col>
                    <Col className="text-right" xs={4}>
                        <Button
                            bsSize="xs"
                            bsStyle="success"
                            type="submit">
                            Save Site
                        </Button>
                    </Col>
                </Row>
            </Modal.Footer>
        </form>
        {showInfoModal && site.inactive && <InfoModal
            isOpen={true}
            closeModal={() => setShowInfoModal(false)}
            title={'Warning'}
            getProposals={getProposalsForInactive}
            searchId={{site_id: site?.id}}
            text={<p>If you save a Site with the Inactive option checked, all Open Proposals assigned to that site will
                be marked as Declined.</p>}
        />}
        {showMovedSiteModal?.show &&
            <MovedSiteModal
                onSave={(res, callback, errorCallback) => {
                    onSave({...site, ...res}, newCustomerId, () => {
                        onSuccess()
                        onHide()
                        callback && callback()
                    }, () => errorCallback && errorCallback())
                }}
                onClose={() => setShowMovedSiteModal({show: false, movedSiteModalData: {}})}
                data={showMovedSiteModal?.movedSiteModalData}
            />
        }
        {showConfirmationSaveModal?.show && <ConfirmationSaveModal
            onHide={() => setShowConfirmationSaveModal({show: false, onSaveWith: null, onSave: null})}
            onSave={showConfirmationSaveModal?.onSave}
            onSaveWith={showConfirmationSaveModal?.onSaveWith}
            type="Site"
            message="Changed site data appearing in events. Do you want to update all events to the new site data?"
        />}
        {showLeadListModal &&
            <LeadListModal
                siteId={site.id}
                onHide={() => {
                    setShowLeadListModal(false)
                    reloadSite()
                }}
                getLeadList={getLeadList}
                deleteLead={deleteLead}
            />
        }
    </Modal>
}

export default CustomerSitesEditModal;