import React from 'react';
import {Button, Col, FormGroup, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import './TransferPlant.css';
import Select from "react-select";
import {select} from "../../../common/commonHandlers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle, faTrash} from '@fortawesome/free-solid-svg-icons'
import ResourceComponent from "../../../components/ResourceComponent";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as actions from "./RemoveCustomerApi";
import MDSpinner from "react-md-spinner";

class RemoveCustomer extends ResourceComponent {
    constructor(props) {
        super(props);
        this.customerSearch = {
            searchOptions: () => [],
            search: () => {
            }
        }
        this.state = {
            resource: {
                customer_id: null
            },
            loading: false
        }
    }

    componentDidMount() {
        this.customerSearch = this.buildSearchComponent('customer_id', this.props.actions.getCustomersSearch);
    }

    removeCustomer = (e, resource) => {
        e.preventDefault();
        this.setState({loading: true})
        const {clientId} = this.props;
        resource.client_id = clientId;
        this.props.actions.removeCustomer(resource, () => this.setState(() => ({
                resource: {...resource, customer_id: null}, loading: false, customer_id: []
            })),
            () => this.setState({loading: false})
        )
    };

    renderButtonContent = () => {
        const {loading} = this.state;
        if (loading) {
            return <div>
                <MDSpinner size={16} className="mr-9"/>
                <span>Removing Customer...</span>
            </div>
        }
        return <div className="vcenter">
            <FontAwesomeIcon icon={faTrash} className="mr-9 font16"/>
            <span>Remove Customer</span>
        </div>
    }

    render() {
        const {resource} = this.state;
        const {customer_id} = resource;
        let customerOptions = this.customerSearch.searchOptions();

        if (customerOptions && customerOptions.length > 0) {
            customerOptions = customerOptions.map(x => ({
                value: x.value,
                label: `${x.label} (id: ${x.value})`
            }))
        }

        const tooltip = (
            <Tooltip id="tooltip">
                <div className="text-left">
                    <strong>Remove Customer</strong><span> - option that allow removing customers with all dependencies like: leads, proposals, proposal_services, stickies, etc</span>
                </div>
            </Tooltip>
        );

        return (
            <div id="transfer_plant">
                <h4 className="transfer_plant_title">
                    <span className="mr-5">Remove Customer</span>
                    <OverlayTrigger placement="right" overlay={tooltip}>
                        <FontAwesomeIcon icon={faInfoCircle} className="mb2 ml-4"/>
                    </OverlayTrigger>
                </h4>
                <div className="transfer_plant_inputs">
                    <Row>
                        <Col md={10}>
                            <FormGroup>
                                <Select className="Select"
                                        classNamePrefix="select"
                                        onInputChange={this.customerSearch.search}
                                        value={select(customerOptions, customer_id)}
                                        filterOption={(option, inputValue) => {
                                            return option
                                        }}
                                        options={customerOptions}
                                        placeholder="Search customer to delete"
                                        onMenuClose
                                        onChange={this.selectResourceAttr('customer_id')}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={10} className='text-right'>
                            <Button className="transfer_btn removal_btn_width"
                                    disabled={!customer_id}
                                    onClick={e => this.removeCustomer(e, resource)}>
                                {this.renderButtonContent()}
                            </Button>
                        </Col>
                        <Col md={2}/>
                    </Row>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        clientsList: state.admin.clientsList,
        clientId: state.client.customerInfo.id,
    };
}

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(actions, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(RemoveCustomer);