import React, {Component} from 'react';
import {CardElement, Elements, injectStripe, StripeProvider,} from 'react-stripe-elements';
import './payment.css'
import Dollars from "../../../components/Dollars";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLock} from "@fortawesome/free-solid-svg-icons";

// You can customize your Elements to give it the look and feel of your site.
const createOptions = () => {
    return {
        style: {
            base: {
                width: '100%',
                fontSize: '16px',
                color: '#424770',
                fontFamily: 'Open Sans, sans-serif',
                letterSpacing: '0.025em',
                '::placeholder': {
                    color: '#aab7c4',
                },
            },
            invalid: {
                color: '#c23d4b',
            },
        }
    }
};

class _CardForm extends Component {
    state = {
        isLoading: true,
        isPaid: false,
        errorMessage: '',
        amount: null
    };

    componentDidMount() {
        this.setState({
            isLoading: false,
            isPaid: this.props.isPaid,
            amount: this.props.amount
        });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.amount !== this.props.amount) {
            this.setState({amount: nextProps.amount})
        }
    }

    handleChange = ({error}) => {
        if (error) {
            this.setState({errorMessage: error.message});
        }
    };

    handleSubmit = (evt) => {
        evt.preventDefault();
        if (this.props.stripe) {
            this.setState({
                isLoading: true
            });
            if (this.props.defaultPaymentMethod || this.props.payWithBalance) {
                this.props.handleResult(this.props.defaultPaymentMethod, (result) => {
                    this.setState({
                        isLoading: false,
                        errorMessage: result.error,
                        isPaid: !!result.status
                    });
                });
            } else {
                try {
                    this.props.stripe.createToken().then((result) => {
                        if (result.token) {
                            this.props.handleResult(result.token.id, (result) => {
                                this.setState({
                                    isLoading: false,
                                    errorMessage: result.error,
                                    isPaid: !!result.status
                                });
                            });
                        } else {
                            this.setState({
                                isLoading: false,
                            });
                        }
                    });
                } catch (e) {
                    console.error(e);
                    this.setState({
                        isLoading: false
                    });
                }
            }
        } else {
            console.log("Stripe.js hasn't loaded yet.");
        }
    };

    render() {
        const {isLoading, isPaid, errorMessage} = this.state;
        return (
            <div className="CardDemo" disabled={isLoading || isPaid}>
                {
                    isPaid ?
                        <div className="text-success lead font-weight-bold" role="alert">
                            This invoice has been paid
                        </div>
                        : ''
                }
                {
                    !isPaid && this.props.payWithStripe ?
                        <form onSubmit={this.handleSubmit.bind(this)}>
                            {!this.props.defaultPaymentMethod && !this.props.payWithBalance && <label>
                                Card details
                                <CardElement
                                    onChange={this.handleChange}
                                    {...createOptions()}
                                />
                            </label>}
                            <div className="error text-danger" role="alert">
                                <strong>{errorMessage}</strong>
                            </div>
                            {this.props.saveOnly ? <button>{!isLoading ? <div>Save Card</div> : "Loading..."}</button> :
                                <button disabled={this.state.amount < 0.5 || this.props.disabledPayButton}>
                                    {!isLoading ? <div><span>{`Pay `}</span><Dollars amount={this.state.amount}/>
                                    </div> : "Loading..."}
                                </button>}
                            {this.props.saveOnly &&
                                <div className='security'>
                                    <div>
                                        <FontAwesomeIcon className='font18 mr-15' icon={faLock}/> Security Assurance
                                    </div>
                                    <div className='security-text'>
                                        Your information is not stored by us, and is transferred over an encrypted
                                        connection
                                        to a certified payment processing server.
                                    </div>
                                </div>}
                        </form>
                        :
                        null
                }
            </div>
        );
    }
}

const CardForm = injectStripe(_CardForm);

export class PaymentElement extends Component {

    render() {
        return (
            <StripeProvider apiKey={this.props.apiKey}>
                <Elements>
                    <CardForm amount={this.props.amount}
                              isPaid={this.props.isPaid}
                              payWithStripe={this.props.payWithStripe}
                              saveOnly={this.props.saveOnly}
                              defaultPaymentMethod={this.props.defaultPaymentMethod}
                              payWithBalance={this.props.payWithBalance}
                              error={this.props.error}
                              disabledPayButton={this.props.disabledPayButton}
                              handleResult={(result, callback) => {
                                  this.props.handleResult(result,
                                      r => {
                                          callback(r);
                                      });
                              }}/>
                </Elements>
            </StripeProvider>
        );
    }
}
