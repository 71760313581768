import axios from "axios"
import {addAlert} from "../App/actions"

import {
    CLEAR_WO_DATA,
    FOUND_WO_TO_COMPLETE,
    SELECT_COMPLETE_PROPOSAL_SERVICE,
    UN_SELECT_COMPLETE_PROPOSAL_SERVICE,
    UPDATE_WONO_SEARCH_TEXT,
} from "./constants"
import {loadProposalAndPServicesInfo} from "../../actions/workOrders"
import {browserHistory} from "react-router"
import {apiCall} from "../../common/apiActionHandler";

export const updateWorkOrderNoSearchText = woNumberSearchText => {
    return {type: UPDATE_WONO_SEARCH_TEXT, woNumberSearchText}
}

export const clearWOData = () => (dispatch, getState) => {
    return dispatch({type: CLEAR_WO_DATA})
}

export const searchPsByWorkOrderNo = (callback) => async (dispatch, getState) => {
    //Loads a wo for the print page
    const store = getState()
    const q = store.changeServiceStatus.woNumberSearchText

    if (q === undefined || q === null || q.length < 1) {
        return dispatch(
            addAlert({
                message: "Please enter a work order number to search for.",
                mood: "warning",
                dismissAfter: 3000,
            })
        )
    }

    const config = {
        url: "/api/work_orders/find_proposal",
        params: {q: q},
        headers: {Authorization: store.auth.token},
    }

    return axios
        .request(config)
        .then(response => {
            if (response.data === null) {
                return dispatch(
                    addAlert({message: "Work Order Number was not found!"})
                )
            } else {
                browserHistory.push(`/complete_work_orders/${q}`)
                dispatch({type: FOUND_WO_TO_COMPLETE, workOrder: response.data})
                dispatch(loadProposalAndPServicesInfo(response.data.proposal_id, () => {
                    callback && callback()
                }))
            }
        })
        .catch(error => {
            if (error.response && error.response.status === 404) {
                dispatch(
                    addAlert({
                        message: `Work Order Number ${q} was not found.`,
                        mood: "warning",
                        dismissAfter: 3000,
                    })
                )
            } else {
                console.error(error)
            }
        })
}

export const selectPs = (e, psId) => {
    if (e.target.checked) {
        return {type: SELECT_COMPLETE_PROPOSAL_SERVICE, psId}
    } else {
        return {type: UN_SELECT_COMPLETE_PROPOSAL_SERVICE, psId}
    }
}

export const completeServices = (elegiblePsToComplete, norefresh = false, callback) => (dispatch, getState) => {
    const store = getState()

    const psToComplete = elegiblePsToComplete ? elegiblePsToComplete : store.changeServiceStatus.selectedPs
    if (psToComplete.length === 0) {
        return dispatch(
            addAlert({
                message: "Please select at least 1 Proposal Service to mark Completed!",
                mood: "warning",
                dismissAfter: 3000,
            })
        )
    }

    const config = {
        method: "put",
        url: "/api/proposal_services/complete",
        headers: {Authorization: store.auth.token},
        data: {
            proposal_services: psToComplete.join(",")
        },
    }

    return axios
        .request(config)
        .then(response => {
            if (response && response.status === 201) {
                dispatch(
                    addAlert({
                        message: "Proposal Services were marked Completed!",
                        mood: "success",
                        dismissAfter: 3000,
                    })
                )
                //refresh the page essentially
                !norefresh && dispatch(searchPsByWorkOrderNo())
                callback && callback()
            }
        })
        .catch(error => {
            dispatch(
                addAlert({
                    message: error.toString(),
                })
            )
        })
}

export const saveWorkOrderProposalService = (proposal_service, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/proposal_services/${proposal_service.id}/update_work_order_proposal_service`,
        data: {proposal_service},
        method: 'PUT'
    };
    apiCall("Saving work order proposal service", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};

export const loadConsumables = (proposal_service_id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/consumables/ps_consumables?proposal_service_id=` + proposal_service_id,
    };

    apiCall("Load consumables", config, async config => {
        const result = await axios.request(config);
        callback && callback({consumables: result.data.consumables});
    }, dispatch, getState, false);
};

export const sendConsumablesConsumption = (consumption, costs, selectedMeasure, proposal_service_id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/consumables/add_ps_consumption`,
        method: 'POST',
        data: {
            consumption,
            costs,
            selectedMeasure,
            proposal_service_id
        }
    };

    apiCall("Saving consumables consumption", config, async config => {
        const result = await axios.request(config);
        callback && callback(result.data);
    }, dispatch, getState, false);
};
export const updateCompletedDateInPs = (ps_ids, global_completed_date, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/proposal_services/update_completed_date_in_ps`,
        data: {ps_ids, global_completed_date},
        method: 'PUT'
    };
    apiCall("Updating completed proposal services", config, async config => {
        const response = await axios.request(config);
        dispatch(searchPsByWorkOrderNo(() => {
            callback && callback(response.data);
        }))
    }, dispatch, getState);
};