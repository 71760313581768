import {FETCH_SALES_ARBORISTS} from '../constants'
import axios from 'axios'

export const fetchSalesArborists = () => (dispatch, getState) => {

    const store = getState()
    const config = {
        method: 'get',
        url: `/api/sales_arborists`,
        headers: {'Authorization': store.auth.token},
    }

    axios.request(config)
        .then(response => {
            dispatch({type: FETCH_SALES_ARBORISTS, salesArboristsList: response.data});
        })
}