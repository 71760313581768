import {
    ADD_PS_TO_ACCEPT,
    PROPOSAL_ACCEPTANCE_SUCCESS,
    PROPOSAL_LOADED,
    REMOVE_PS_TO_ACCEPT,
    SELECT_ALL_PS_TO_ACCEPT,
    SELECT_NONE_PS_TO_ACCEPT,
    UPDATE_PROPOSAL_ACCEPTED_DATE,
    UPDATE_PROPOSAL_NO
} from './constants'

const initialState = {
    proposalNo: '',
    foundProposal: null,
    psToAccept: [],
    proposalAcceptedDate: null
}


function removeItemByValue(original, psId) {
    return original.filter(i => i !== psId)
}

function addToArrayUnique(original, psId) {
    const newArr = original.concat(psId)
    return [...new Set(newArr)]
}

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case UPDATE_PROPOSAL_NO:
            return {...state, proposalNo: action.proposalNo}
        case PROPOSAL_LOADED:
            return {...state, foundProposal: action.foundProposal}
        case ADD_PS_TO_ACCEPT:
            return {...state, psToAccept: addToArrayUnique(state.psToAccept, action.psId)}
        case REMOVE_PS_TO_ACCEPT:
            return {...state, psToAccept: removeItemByValue(state.psToAccept, action.psId)}
        case SELECT_ALL_PS_TO_ACCEPT:
        case SELECT_NONE_PS_TO_ACCEPT:
            return {...state, psToAccept: action.psToAccept}
        case UPDATE_PROPOSAL_ACCEPTED_DATE:
            return {...state, proposalAcceptedDate: action.proposalAcceptedDate}
        case PROPOSAL_ACCEPTANCE_SUCCESS:
            return {
                ...state, proposalNo: '',
                foundProposal: null,
                psToAccept: [],
                proposalAcceptedDate: null
            }
        default:
            return state
    }
}

export default reducer 