import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Col, Grid, Panel, Row, Table} from "react-bootstrap";
import * as api from "./QuickbooksCustomersApi";
import {select} from "../../../common/commonHandlers";
import ResourceComponent from "../../../components/ResourceComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLongArrowAltLeft, faLongArrowAltRight} from '@fortawesome/free-solid-svg-icons'
import Select from "react-select";
import InlineEditable from "../../Scheduler/InlineEditable";

const actions = {...api};

class QuickbooksCustomers extends ResourceComponent {

    state = {
        filterVisible: true,
        resource: {customer_id: null},
        content: []
    };

    constructor(props, context) {
        super(props, context);
        this.customerSearch = {
            searchOptions: () => [],
            search: () => {
            }
        };
    }

    componentDidMount() {
        this.customerSearch = this.buildSearchComponent('customer_id', this.props.actions.searchForCustomer);
        this.sync();
    }

    sync = () => {
        const {resource} = this.state;
        this.props.actions.syncAllCustomers(response => this.setState(response));
    }

    assignCustomer = (customer, index) => {
        const {content} = this.state;
        content[index]["th"] = {
            id: customer.id,
            first_name: customer.first_name,
            last_name: customer.last_name,
            address_1: customer.address_1,
            state: customer.state,
            zip: customer.zip,
            city: customer.city,
            email_address: customer.email_address,
            phone_number: customer.phone_number,
            isAssigned: true
        };
        this.setState({
            content: content
        });
    }

    searchUser = (index) => {
        const customerOptions = this.customerSearch.searchOptions();
        const {resource, content} = this.state;
        return (
            <div>
                <Row>
                    <Col xs={2}></Col>
                    <Col xs={8}>
                        <InlineEditable
                            style={{minWidth: "50%"}}
                            className="pointer"
                            value={
                                content[index]["th"] ? this.userDataField(content[index]["th"], content[index]["qb"]) :
                                    <h4>
                                        <b>Not Assigned</b>
                                    </h4>
                            }
                            form={(done) =>
                                <Select className="Select"
                                        classNamePrefix="select"
                                        value={select(customerOptions, resource.customer_id)}
                                        options={customerOptions}
                                        placeholder="Customer"
                                        isClearable
                                        onInputChange={this.customerSearch.search}
                                        onChange={this.selectResourceAttr('customer_id',
                                            () => this.props.actions.getCustomer(resource.customer_id, (response) => this.assignCustomer(response, index))
                                        )}
                                />
                            }
                        />
                    </Col>
                    <Col xs={2}></Col>
                </Row>
            </div>
        );
    }

    userDataField = (user, template) => {
        return (
            <div>
                <Row>
                    <Col style={{textDecoration: "none"}} className="text-right" xs={5}>
                        Name:
                    </Col>
                    <Col xs={7} className="text-left" style={{
                        color: template && user.first_name === template.first_name && user.last_name
                        === template.last_name ? "green" : "red"
                    }}>
                        <p>{user.first_name} {user.last_name}</p>
                    </Col>
                </Row>
                <Row>
                    <Col style={{textDecoration: "none"}} className="text-right" xs={5}>
                        Address:
                    </Col>
                    <Col xs={7} className="text-left" style={{
                        color: template && user.address_1 === template.address_1 && user.state
                        === template.state && user.zip === template.zip && user.city === template.city ? "green" : "red"
                    }}>
                        <p>{user.address_1} {user.state} {user.zip} {user.city}</p>
                    </Col>
                </Row>
                <Row>
                    <Col style={{textDecoration: "none"}} className="text-right" xs={5}>
                        Email:
                    </Col>
                    <Col xs={7} className="text-left"
                         style={{color: template && user.email_address === template.email_address ? "green" : "red"}}>
                        <p>{user.email_address}</p>
                    </Col>
                </Row>
                <Row>
                    <Col style={{textDecoration: "none"}} xs={5} className="text-right">
                        Phone number:
                    </Col>
                    <Col xs={7} className="text-left"
                         style={{color: template && user.phone_number === template.phone_number ? "green" : "red"}}>
                        <p>{user.phone_number}</p>
                    </Col>
                </Row>
            </div>
        )
    }

    render() {
        const {
            filterVisible, resource, content
        } = this.state;
        return (
            <Grid fluid>
                <Row>
                    <Col md={12}>
                    </Col>
                </Row>
                <Row className="vcenter headerBorder">
                    <Col md={6} sm={6} xs={6} className="vcenter">
                        <h2 className="no-top-margin small-margin">Synchronize customers with quickbooks</h2>
                    </Col>
                </Row>
                <Row>
                    <Panel
                        collapsible
                        expanded={filterVisible}
                    >
                    </Panel>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Table bordered>
                            <thead>
                            <tr>
                                <th className="text-center">TreeHub Customers</th>
                                <th className="text-center">Choice</th>
                                <th className="text-center">QuickBooks Customers</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                content.map((items, index) => {
                                    return (
                                        <tr style={{textAlign: "center"}}>
                                            <td>{(items.th && !items.th.isAssigned) ? this.userDataField(items.th, items.qb) : this.searchUser(index)}</td>
                                            <td style={{position: "relative"}} className="text-center">
                                                <div style={{
                                                    position: "absolute",
                                                    top: "50%",
                                                    left: "50%",
                                                    transform: "translate(-50%, -50%)"
                                                }}>
                                                    {items.qb &&
                                                        <div>
                                                            <FontAwesomeIcon className="pointer"
                                                                             icon={faLongArrowAltLeft}
                                                                             size="3x"
                                                                             onClick={() => this.props.actions.updateCustomer(items, 'left')}/>
                                                        </div>
                                                    }
                                                    <div>
                                                        <FontAwesomeIcon className="pointer" icon={faLongArrowAltRight}
                                                                         size="3x"
                                                                         onClick={() => this.props.actions.updateCustomer(items, 'right')}/>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>{items.qb ? this.userDataField(items.qb, items.th) : ""}</td>
                                        </tr>
                                    );
                                })
                            }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Grid>
        );
    }
}

QuickbooksCustomers.propTypes = {
    //myProp: PropTypes.object.isRequired
};

QuickbooksCustomers.defaultProps = {
    //myProp: <defaultValue>
};

function mapStateToProps(state, ownProps) {
    return {
        clientId: state.auth.client_id
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(QuickbooksCustomers);