import React, {useEffect, useState} from "react";
import {InfoWindow, Marker} from "react-google-maps";
import {Link} from "react-router";
import {Button, Col, Glyphicon, OverlayTrigger, Panel, Row, Tooltip} from "react-bootstrap";
import ColorCheckbox from "../Scheduler/ColorCheckbox";
import EventWindowContent from "./EventWindowContent";
import moment from "moment/moment";
import {getBasicMarker} from "../../utilities";

const MemoizedMarker = ({
                            item,
                            onInfoOpen,
                            onInfoClose,
                            eventType,
                            onAddToRoute,
                            markerOrderNumber,
                            removePsFromWo,
                            sites,
                            onInfoLinkClick,
                            showExclusive,
                            locationState,
                            scheduledServices,
                            checkedRoute,
                            index,
                            getColor,
                            hasScheduledEvent,
                            handleToggleRouteRemoveHelper,
                            resource,
                            activeMarkerData,
                            reload,
                            mixedMarker
                        }) => {
    const [isOpen, setIsOpen] = useState(null);

    useEffect(() => {
        const currentOpen = activeMarkerData && item?.some(i => i.site_id === activeMarkerData?.site_id)
        if (currentOpen) {
            setIsOpen(true)
        } else {
            setIsOpen(false)
        }

        if (currentOpen) {
            const idsDiffer = activeMarkerData.site_id !== item[0].site_id;
            const eventIdsDiffer = activeMarkerData.scheduler_event_id && item[0].scheduler_event_id && activeMarkerData.scheduler_event_id !== item[0].scheduler_event_id;
            if (idsDiffer || eventIdsDiffer) {
                setIsOpen(false);
            }
        }
    }, [item?.[0]?.id, activeMarkerData])

    const displayEventWindow = eventType && Object.values(sites).every((item) => item[0].id === null);
    const today = moment().toDate().toLocaleDateString()

    const getMarkerStyles = () => {
        if (isOpen) {
            return {
                icon: getBasicMarker({
                    selected: false,
                    color: '#f8e500',
                    label: mixedMarker ? "S" : markerOrderNumber && item?.filter(i => i.scheduler_event_id)?.length > 0 ? markerOrderNumber.toString() : ""
                }),
                zIndex: 10000
            }
        } else if (hasScheduledEvent) {
            let icon;
            if (resource.showTodaysRouteAllUsers) {
                icon = getBasicMarker({
                    selected: false,
                    color: getColor({person_id: item[0].person_id}),
                    label: mixedMarker ? "S" : markerOrderNumber && item?.filter(i => i.scheduler_event_id)?.length > 0 ? markerOrderNumber.toString() : ""
                });
            } else {
                icon = getBasicMarker({
                    selected: false,
                    color: getColor({person_id: resource.person_id}),
                    label: mixedMarker ? "S" : markerOrderNumber && item?.filter(i => i.scheduler_event_id)?.length > 0 ? markerOrderNumber.toString() : ""
                });
            }
            return {icon}
        } else {
            return {
                icon: getBasicMarker({
                    selected: false,
                    color: '#56C1FE',
                    label: mixedMarker ? "S" : markerOrderNumber && item?.filter(i => i.scheduler_event_id)?.length > 0 ? markerOrderNumber.toString() : ""
                })
            }
        }
    }

    const {icon, zIndex} = getMarkerStyles();
    const handleInfoClose = () => {
        setIsOpen(false)
        onInfoClose()
    };
    return item && item.length > 0 && item[0].latitude && (
        <Marker
            position={{
                lat: item[0].latitude,
                lng: item[0].longitude
            }}
            icon={icon}
            title={item[0].site_name}
            zIndex={(isOpen || item[0].scheduler_event_guest_id) ? (zIndex || 1000) + index : null}
            onClick={() => {
                onInfoOpen(() => setIsOpen(true))
            }}
        >
            {isOpen && !displayEventWindow &&
                <InfoWindow
                    onCloseClick={handleInfoClose}>
                    <div>
                        {Object.values(sites).map((item) => {
                            if (!!item[0].id) {
                                const types = [];
                                item.map(site => {
                                    if (site.service_type && !types.includes(site.service_type)) {
                                        types.push(site.service_type);
                                    }
                                });
                                const formattedTypes = types.map(type => {
                                    let typeObject = {};
                                    typeObject.fullName = type;
                                    if (type !== 'PHC') {
                                        typeObject.shortName = type.split(' ').map(s => s.charAt(0)).join('');
                                    } else {
                                        typeObject.shortName = type;
                                    }
                                    return typeObject;
                                });
                                formattedTypes.map(type => {
                                    let hours = 0;
                                    let total = 0;
                                    item.filter((thing, index, self) => self.findIndex(t => t.service_no === thing.service_no) === index).map(item => {
                                        hours += item.service_type === type.fullName ? +item.man_hours : 0;
                                        total += item.service_type === type.fullName ? +item.service_total : 0;
                                        type.hours = hours;
                                        type.total = total;
                                    })
                                });

                                const isStatusOverThenAccepted = (status) => {
                                    return status === "Scheduled" ||
                                        status === "Completed" ||
                                        status === "Invoiced" ||
                                        status === "Payment" ||
                                        status === "Paid"
                                }

                                return <div className="info-window">
                                    <h6 className={`text-blue`}>{item[0].customer_full_name} (
                                        <Link
                                            onClick={() => onInfoLinkClick(item[0].id)}>
                                            Proposal
                                            #{item[0].proposal_no}
                                        </Link>
                                        )
                                        <span className={`text-green`}>
                                                                                            {
                                                                                                formattedTypes.map(type => {
                                                                                                    return `  ${type.shortName}: ${(type.hours).toFixed(2)} hr  $${type.total.toFixed(2)}`
                                                                                                })
                                                                                            }
                                                                                        </span>
                                    </h6>
                                    <span><strong>{item[0].full_address}</strong></span>
                                    {item.sort((a, b) => a.service_no - b.service_no).filter((item, index, self) =>
                                        index === self.findIndex((t) => (
                                            t.proposal_service_id === item.proposal_service_id
                                        ))).map((ps, idx) => {
                                            const service_total = '$' + parseFloat(ps.service_total).toFixed(2);

                                            const label = <span
                                                className={`${(mixedMarker ? ps.date_from && moment(ps.date_from).toDate().toLocaleDateString() === today : scheduledServices.includes(ps.proposal_service_id)) && 'text-orange'} ${(showExclusive && ps.exclusive || locationState && locationState.ids.includes(ps.proposal_service_id)) && 'text-purple'}`}>{`#${ps.service_no} ${ps.name} ${ps.man_hours}hrs ${service_total} ${ps.work_order_no && `WO #${ps.work_order_no}`}`}</span>;
                                            return (
                                                <Row key={idx}>
                                                    <Col xs={12}>
                                                        <div
                                                            className={ps.is_bundle_service && 'bundle-services'}>
                                                            {!ps.is_bundle_service
                                                                ? <div className="vertical-align">
                                                                    <ColorCheckbox
                                                                        value={checkedRoute && checkedRoute.length > 0 && checkedRoute.includes(ps.proposal_service_id)}
                                                                        label={ps.schedulable ?
                                                                            <b>{label}</b> :
                                                                            <b className='text-gray'>{label}</b>}
                                                                        key={idx}
                                                                        onChange={() => handleToggleRouteRemoveHelper(ps)}/>
                                                                    {ps.work_order_id && !isStatusOverThenAccepted(ps.service_status_name) &&
                                                                        <OverlayTrigger placement="top"
                                                                                        overlay={<Tooltip
                                                                                            id="tooltip">Remove
                                                                                            Proposal Service from
                                                                                            Work Order</Tooltip>}>
                                                                                                                        <span
                                                                                                                            onClick={() => {
                                                                                                                                removePsFromWo(ps.proposal_service_id, () => {
                                                                                                                                    reload()
                                                                                                                                })
                                                                                                                            }}
                                                                                                                            className="text-danger ml-9 pointer">
                                                                                                                                <Glyphicon
                                                                                                                                    glyph="remove-sign"/>
                                                                                                                            </span>
                                                                        </OverlayTrigger>}
                                                                </div>
                                                                : <ColorCheckbox
                                                                    value={checkedRoute && checkedRoute.length > 0 && checkedRoute.includes(ps.proposal_service_id)}
                                                                    key={idx}
                                                                    onChange={() => handleToggleRouteRemoveHelper(ps)}/>
                                                            }
                                                            {ps.is_bundle_service &&
                                                                <Panel
                                                                    collapsible
                                                                    expanded={true}
                                                                    header={
                                                                        <span>
                                                                                                                        {label}
                                                                            {ps.work_order_id && !isStatusOverThenAccepted(ps.service_status_name) &&
                                                                                <OverlayTrigger placement="top"
                                                                                                overlay={<Tooltip
                                                                                                    id="tooltip">Remove
                                                                                                    Proposal Service
                                                                                                    from Work
                                                                                                    Order</Tooltip>}>
                                                                                                                        <span
                                                                                                                            onClick={() => {
                                                                                                                                removePsFromWo(ps.proposal_service_id, () => {
                                                                                                                                    reload()
                                                                                                                                })
                                                                                                                            }}
                                                                                                                            className="text-danger ml-9 pointer">
                                                                                                                                <Glyphicon
                                                                                                                                    glyph="remove-sign"/>
                                                                                                                            </span>
                                                                                </OverlayTrigger>}
                                                                                                                    </span>

                                                                    }
                                                                    className={'bundle-services__panel'}
                                                                >
                                                                    <div
                                                                        className='bundle-services__body'>
                                                                        {ps.bundle_services.sort((a, b) => a.service_number - b.service_number).map(bs => {
                                                                            return (
                                                                                <>
                                                                                    <div
                                                                                        className='bundle-services__status'>
                                                                                        <ColorCheckbox
                                                                                            value={checkedRoute && checkedRoute.length > 0 && checkedRoute.includes(ps.proposal_service_id)}
                                                                                            disabled={true}
                                                                                            key={idx}
                                                                                            label={bs.name}
                                                                                        />
                                                                                        {bs.wo_notes &&
                                                                                            <div
                                                                                                className='colorRed ml-10'
                                                                                                style={{whiteSpace: 'normal'}}>
                                                                                                <p>{bs.wo_notes}</p>
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                </Panel>
                                                            }
                                                        </div>
                                                        {['In Work Order', 'Accepted', 'Scheduled'].includes(ps.service_status_name) &&
                                                            <div
                                                                className='text-green'
                                                                style={{
                                                                    display: 'flex',
                                                                    justifyContent: 'space-between'
                                                                }}>
                                                                {ps.priorities && ps.priorities.length > 0 &&
                                                                    <span>
                                                                                                                        {ps.priorities.map(p => p.name).join(', ')}
                                                                                                                    </span>
                                                                }
                                                                {ps.equipments && ps.equipments.length > 0 &&
                                                                    <span
                                                                        className="text-green">{ps.equipments.map(p => p).join(', ')}
                                                                                                                    </span>
                                                                }
                                                            </div>
                                                        }
                                                        {ps.wo_notes &&
                                                            <div
                                                                className='colorRed ml-10'
                                                                style={{whiteSpace: 'normal'}}>
                                                                <p>{ps.wo_notes}</p>
                                                            </div>
                                                        }
                                                    </Col>
                                                </Row>
                                            )
                                        }
                                    )}
                                    <Col xs={12}
                                         className='save-work-order'>
                                        <Button
                                            className="pointer btn-smaller"
                                            bsStyle="success"
                                            disabled={item.filter(x => checkedRoute.includes(x.proposal_service_id)).length < 1}
                                            onClick={() => {
                                                onAddToRoute(item, () => onInfoOpen())
                                            }}>
                                            Add To Route
                                        </Button>
                                    </Col>
                                </div>
                            } else {
                                return <EventWindowContent site={item}/>
                            }
                        })
                        }

                    </div>
                </InfoWindow>}
            {isOpen && displayEventWindow &&
                <InfoWindow
                    onCloseClick={handleInfoClose}>
                    <div>
                        {Object.values(sites).map((item) => {
                            return <EventWindowContent site={item}/>
                        })}
                    </div>
                </InfoWindow>
            }
        </Marker>
    )
}
export default MemoizedMarker;