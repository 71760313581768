const initialState = {
    active: null,
    options: {},
    errors: [],
    errorIndex: 0
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case 'SHOW_EMAIL_DIALOG':
            return {...state, active: true, options: action.options};
        case 'HIDE_EMAIL_DIALOG':
            return {...initialState};
        case 'SET_EMAIL_ERRORS':
            return {...state, errorIndex: action.errors.length - 1, errors: action.errors}
        case 'APPEND_EMAIL_ERROR':
            return {
                ...state,
                errorIndex: [...state.errors, action.error].length - 1,
                errors: [...state.errors, action.error]
            }
        default:
            return state
    }
}

