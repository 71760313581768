import React from 'react';
import {Glyphicon} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faDownload,
    faFile,
    faFileArchive,
    faFileCsv,
    faFileExcel,
    faFilePowerpoint,
    faFileVideo,
    faFileWord
} from "@fortawesome/free-solid-svg-icons";
import './DocAttachment.css';
import PdfAttachment from "../pdf/PdfAttachment";

export const DocAttachment = ({
                                  file,
                                  deleteDoc,
                                  isCrewMessage,
                                  isReplyMode,
                                  allowRemove = true,
                                  disableRedirect = false,
                                  renderName = false,
                                  downloadButton = false,
                                  downloadResource
                              }) => {

    const removeTitle = "Delete this document"
    const downloadTitle = `Open file:  ${file.name}`

    const AttachmentName = (file) => {
        return <a href={file.original} target="_blank" title="Open in a new window">
        <span className="attachment_name" target="_blank">
            {file?.created_at}
            {file?.name?.substring(0, 20)}
            {file?.name?.length > 20 && '...'}
        </span>
        </a>
    }

    const renderIcon = (icon) => (
        <>
            <a title={downloadTitle}
               href={!disableRedirect && (file.url || file.original)}
               target="_blank"
            >
                <FontAwesomeIcon icon={icon}
                                 className={isCrewMessage ? "doc-attachment-crew-icon ml-10" : "doc-attachment-icon ml-10"}/>
                {allowRemove &&
                    <a className={isCrewMessage ? "doc-attachment-crew-remove-icon ml-10" : "doc-attachment-remove-icon ml-10"}
                       title={removeTitle} onClick={e => {
                        e.preventDefault()
                        deleteDoc(file.id)
                    }}>
                        <Glyphicon glyph="remove-sign" className="pointer text-danger"/>
                    </a>}
                {downloadButton && <a title={'Download'} className='doc-attachment-download-icon'
                                      onClick={() => downloadResource(file.original, file.name)}>
                    <FontAwesomeIcon icon={faDownload} className="font14 pointer"/>
                </a>}
            </a>
        </>
    )

    const renderPdfIcon = (file) => (

        <PdfAttachment key={file.id} pdf={file}
                       disableRedirect={true}
                       allowRemove={allowRemove}
                       deletePdf={() => deleteDoc(file.id)}/>
    )

    const renderFile = (name, type, file) => {
        if (name) {
            if (name.includes(".csv")) {
                return renderIcon(faFileCsv)
            } else if (name.includes(".xls")) {
                return renderIcon(faFileExcel)
            } else if (name.includes(".doc")) {
                return renderIcon(faFileWord)
            } else if (name.includes(".ppt") || name.includes(".key")) {
                return renderIcon(faFilePowerpoint)
            } else if (name.includes(".pdf")) {
                return renderPdfIcon(file)
            } else if (name.includes(".zip")) {
                return renderIcon(faFileArchive)
            } else if (name.includes(".webm") || type && type.includes("x-matroska")) {
                return renderIcon(faFileVideo)
            } else {
                return renderIcon(faFile)
            }
        }
    }

    return (
        <div
            key={file.id}
            className={file.name?.includes(".pdf") ? "doc-attachment-preview pdf-attachment-preview"
                : "doc-attachment-preview"}
            style={{margin: isReplyMode && isCrewMessage && '5px -5px 3px 5px'}}
        >
            {renderFile(file.name, file?.type, file)}
            {renderName && AttachmentName(file)}
        </div>
    );
}
