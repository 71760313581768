import React, {useEffect, useState} from 'react';
import {Col, FormControl, Modal, OverlayTrigger, Row, Tooltip, Well} from "react-bootstrap";
import moment from "moment";
import Dollars from "../../components/Dollars";
import {defaultDateFormat} from "../../common/commonHandlers";

const PONumberInvoiceCreationModal = ({
                                          term_types,
                                          defaultTerm,
                                          handleSelectService,
                                          handleSelectAllServices,
                                          proposalId,
                                          save,
                                          selected_proposal_services,
                                          all_proposal_services,
                                          proposal_services,
                                          show,
                                          onClose,
                                          onSaveShowTermModal
                                      }) => {

    const [purchaseOrder, setPurchaseOrder] = useState("")
    const [isSaveInProgress, setIsSaveInProgress] = useState(false)

    useEffect(() => {
        if (isSaveInProgress) {
            saveInvoice()
        }
    }, [isSaveInProgress])

    const saveInvoice = () => {
        let wos = [];
        let ps_update = [];
        let proposal = {};
        let amount = 0;
        proposal.id = proposalId;
        selected_proposal_services.map(ps => {
            let selectedPS = proposal_services.find(p => ps === p.proposal_service_id);
            amount += proposal_services.find(p => ps === p.proposal_service_id).price;
            selectedPS.work_order_id !== null && wos.push(selectedPS.work_order_id);
            ps_update.push(selectedPS.proposal_service_id)
        });
        proposal.proposal_services = ps_update;
        if (defaultTerm && term_types.length && term_types.find(tt => tt.default_term)?.id) {
            save(purchaseOrder);
        } else {
            onSaveShowTermModal(amount)
        }
    }
    return (
        <Modal
            id='invoice-create-with-po-number-modal'
            animation={true}
            show={show}
            className="heightAuto"
            bsSize="normal"
        >
            <Modal.Header>
                <Modal.Title>Please select services to create new invoice with entered PO number</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Well bsSize="small mt10">
                    <Row>
                        <Col md={2}>
                            <label>
                                <input
                                    disabled={all_proposal_services.filter(ps => ['Completed', 'In Work Order'].includes(ps.status) && !ps.invoice_id).length === 0}
                                    checked={
                                        selected_proposal_services.length ===
                                        all_proposal_services.filter(ps => ['Completed', 'In Work Order'].includes(ps.status) && !ps.invoice_id).length &&
                                        all_proposal_services.filter(ps => ['Completed', 'In Work Order'].includes(ps.status) && !ps.invoice_id).length > 0
                                    }
                                    type="checkbox"
                                    onChange={e => handleSelectAllServices(e, proposal_services)}
                                />
                                {' '}
                                #
                            </label>
                        </Col>
                        <Col
                            md={2}>
                            Service
                        </Col>
                        <Col md={1}>
                            Priority
                        </Col>
                        <Col md={2}>
                            Status
                        </Col>
                        <Col md={1}>
                            Est Hrs
                        </Col>
                        <Col md={1}>
                            $ Price
                        </Col>
                        <Col md={1}>
                            WO #
                        </Col>
                        <Col md={1}>
                            Invoice #
                        </Col>
                    </Row>
                </Well>
                <Well bsSize="small mt10">
                    {all_proposal_services.map((service, i) => {
                        const renderTooltip = service && (
                            <div>
                                {service.created_at &&
                                    <span>Draft {moment(service.created_at).format(defaultDateFormat)}<br/></span>}
                                {service.proposal_date &&
                                    <span>Proposed {moment(service.proposal_date).format(defaultDateFormat)}<br/></span>}
                                {service.accepted_date &&
                                    <span>Accepted {moment(service.accepted_date).format(defaultDateFormat)}<br/></span>}
                                {service.scheduled_date &&
                                    <span>Scheduled {moment(service.scheduled_date).format(defaultDateFormat)}<br/></span>}
                                {service.completed_at &&
                                    <span>Completed {moment(service.completed_at).format(defaultDateFormat)}<br/></span>}
                                {service.invoiced_at &&
                                    <span>Invoiced {moment(service.invoiced_at).format(defaultDateFormat)}<br/></span>}
                                {service.invoice && service.invoice.payments && service.invoice.payments.length > 0 &&
                                    <span>Payment {moment(service.invoice.payments[0].payment_date).format(defaultDateFormat)}<br/></span>}
                                {service.paid_at &&
                                    <span>Paid {moment(service.paid_at).format(defaultDateFormat)}<br/></span>}
                                {service.declined_date &&
                                    <span>Declined {moment(service.declined_date).format(defaultDateFormat)}</span>}
                            </div>
                        );
                        let statusDate = null;

                        if (service.proposalServiceStatus === 'Draft') {
                            statusDate = service.created_at && moment(service.created_at).format(defaultDateFormat);
                        } else {
                            switch (service.status) {
                                case 'Invoiced':
                                    statusDate = service.invoiced_at && moment(service.invoiced_at).format(defaultDateFormat);
                                    break;
                                case 'Proposed':
                                    statusDate = service.proposal_date && moment(service.proposal_date).format(defaultDateFormat);
                                    break;
                                case 'Accepted':
                                    statusDate = service.accepted_date && moment(service.accepted_date).format(defaultDateFormat);
                                    break;
                                case 'Scheduled':
                                    statusDate = service.scheduled_date && moment(service.scheduled_date).format(defaultDateFormat);
                                    break;
                                case 'Completed':
                                    statusDate = service.completed_at && moment(service.completed_at).format(defaultDateFormat);
                                    break;
                                case 'Paid':
                                    statusDate = service.paid_at && moment(service.paid_at).format(defaultDateFormat);
                                    break;
                                case 'Declined':
                                    statusDate = service.declined_date && moment(service.declined_date).format(defaultDateFormat);
                                    break;
                                case 'Payment':
                                    statusDate = service.invoice && service.invoice.payments && service.invoice.payments.length > 0 &&
                                        moment(service.invoice.payments[0].date).format(defaultDateFormat);
                                    break;
                                default:
                                    statusDate = null;
                            }
                        }

                        return <><Row>
                            <Col md={2}>
                                <label>
                                    <input
                                        type="checkbox"
                                        disabled={
                                            !['Completed'].includes(service.status) || service.invoice_id
                                        }
                                        onChange={e => handleSelectService(e, service.proposal_service_id)}
                                        checked={selected_proposal_services.includes(service.proposal_service_id)}
                                    />
                                    {' '}
                                    #{service.order_number_in_proposal}
                                </label>
                            </Col>
                            <Col md={2}>
                                {service.service_name}
                            </Col>
                            <Col xs={1}>
                                <strong>
                                    {service.priorities && service.priorities.length > 0 ? 'Priority: ' : null}
                                    {service.priorities && service.priorities.length > 0 &&
                                        service.priorities.map(p => <span
                                            key={p.id}>{p.name}{service.priorities.length > 1 ? ', ' : ' '}</span>)}
                                </strong>


                                {service.date_ranges.length
                                    ? service.date_ranges.map(dr => (
                                        <div key={dr.id}>
                                            Typical Timing:
                                            {" "}
                                            {moment(dr.from_date).format("MMMM Do")}
                                            {" "}
                                            -
                                            {" "}
                                            {moment(dr.to_date).format("MMMM Do")}
                                        </div>
                                    ))
                                    : null}
                            </Col>
                            <Col xs={2}>
                                <OverlayTrigger placement="top" overlay={<Tooltip>{renderTooltip}</Tooltip>}>
                                    <div>
                                        <strong>{service.status}</strong>{' '}
                                        {statusDate}
                                    </div>
                                </OverlayTrigger>
                            </Col>
                            <Col xs={1}>
                                {service.est_hours}
                            </Col>

                            <Col xs={1}>
                                <Dollars amount={service.price}/>
                            </Col>
                            <Col xs={1}>
                                {service.work_order_no &&
                                    <span className='small-margin'> #{service.work_order_no} </span>}
                            </Col>
                            <Col xs={1}>
                                {service.invoice_no && service.invoice_no}
                            </Col>
                        </Row>
                            {i !== (all_proposal_services.length - 1) && <hr/>}
                        </>
                    })}
                </Well>
            </Modal.Body>
            <Modal.Footer>
                <div className="space-between">
                    <div className="text-left">
                        <FormControl
                            className="mr-5"
                            maxLength={30}
                            name="purchaseOrder"
                            placeholder="Purchase order"
                            value={purchaseOrder}
                            onChange={(e) => {
                                setPurchaseOrder(e.target.value)
                            }}
                        />
                    </div>
                    <div className="text-right">
                        <button onClick={onClose} type="button" className="btn btn-default"
                                data-dismiss="modal">Cancel
                        </button>
                        <button
                            type="button"
                            className="btn btn-success"
                            disabled={isSaveInProgress || selected_proposal_services.length < 1}
                            onClick={() => {
                                setIsSaveInProgress(true)
                            }}>
                            Create invoice
                        </button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default PONumberInvoiceCreationModal