import {FETCH_SELECTED_SITEINFO, SELECT_CUSTOMER, UPDATE_SELECTED_SITE, UPDATE_SERVICE_STATUS,} from "./constants"

import {EDITING_ASSET_ID,} from "../../MapViewPage/constants"

import axios from "axios"
import {addAlert} from "../../App/actions"
import {browserHistory} from "react-router"
import {apiCall} from "../../../common/apiActionHandler";
import * as FileSaver from "file-saver";
import moment from "moment";
import {dataUpload} from "../../../common/upload";

export const updateServiceStatus = serviceStatus => {
    return {type: UPDATE_SERVICE_STATUS, serviceStatus}
}

export const updateSelectedSite = selectedSite => dispatch => {
    dispatch({type: UPDATE_SELECTED_SITE, selectedSite})
    dispatch(fetchSelectedSiteInfo())
}

export const updateSelectedCustomer = selectedCustomer => dispatch => {
    dispatch({type: SELECT_CUSTOMER, selectedCustomer})
    browserHistory.push(`/customer/invoices/${selectedCustomer.value}`)
}

export const dismissAssetEditorModal = () => ({
    type: EDITING_ASSET_ID,
    editingAssetHistoryId: null,
})

export const applyHistoryFilter = () => (dispatch, getState) => {
    const store = getState()
    dispatch(fetchCustomerById(store.customerProposals.customer.id))
}

export const fetchCustomerById = (customerId, callback) => async (dispatch, getState) => {
    const store = getState()

    const config = {
        url: `/api/customers/${customerId}/info`,
        headers: {Authorization: store.auth.token},
    }

    apiCall("Getting customers", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
}

export const fetchCustomerSites = (customerId, callback) => async (dispatch, getState) => {
    const store = getState()
    const config = {
        url: "/api/sites",
        headers: {Authorization: store.auth.token},
        params: {customer_id: customerId},
    }

    apiCall("Getting customer invoices", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);

}

export const fetchCustomerInvoices = ({
                                          selectedSite,
                                          selectedInvoiceType,
                                          invoiceFromDate,
                                          invoiceToDate
                                      }, customerId, callback) => async (dispatch, getState) => {
    const store = getState()
    const selectedSite = store.customerInvoices.selectedSite

    const config = {
        url: `/api/invoices/customer_invoices/`,
        headers: {Authorization: store.auth.token},
        params: {
            site_id: selectedSite,
            customer_id: customerId,
            invoice_type: selectedInvoiceType,
            invoice_from_date: invoiceFromDate,
            invoice_to_date: invoiceToDate,
        },
    }
    apiCall("Getting customer invoices", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, true);

}

const fetchSelectedSiteInfo = () => async (dispatch, getState) => {
    const store = getState()
    const selectedSite = store.customerProposals.selectedSite
    if (isNaN(parseInt(selectedSite, 10))) {
        return false
    }

    const config = {
        url: `/api/sites/${selectedSite}`,
        headers: {Authorization: store.auth.token},
    }

    try {
        const response = await axios.request(config)
        dispatch({type: FETCH_SELECTED_SITEINFO, selectedSiteInfo: response.data})
    } catch (exception) {
        dispatch(
            addAlert({
                message: "Unable to fetch customer site because " +
                    exception.toString(),
            })
        )
        throw exception
    }
}

export const searchForCustomer = (searchQuery, callback) => async (dispatch, getState) => {
    const store = getState()

    if (searchQuery.length < 2) {
        return false
    }

    const config = {
        method: "get",
        url: `/api/customers`,
        headers: {Authorization: store.auth.token},
        params: {
            q: searchQuery,
        },
    }

    apiCall("Searching customer", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
}

export const markInvoicesPaid = (invoiceIds, paidDate, callback) => async (dispatch, getState) => {
    const store = getState()

    const config = {
        method: "POST",
        url: `/api/invoices/mark_invoice_paid`,
        headers: {Authorization: store.auth.token},
        params: {
            invoice_ids: invoiceIds.join(','),
            paid_at: paidDate
        },
    }

    apiCall("Marking customer invoices as paid", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);

}

export const downloadCsv = (invoices, selectedInvoices) => async (dispatch, getState) => {
    const config = {
        url: `/api/invoices/csv`,
        method: "POST",
        data: {
            invoices,
            selectedInvoices
        }
    };

    apiCall("Downloading csv", config, async config => {
        const response = await axios.request(config);
        const blob = new Blob([response.data], {
            type: 'text/csv',
        });
        FileSaver.saveAs(blob, `customer_invoices-${moment().format()}.csv`);

        blob.name = `customer_invoices-${moment().format()}.csv`
        dataUpload(`/api/download_history`, blob, null, null, {
            params: JSON.stringify(invoices ? invoices : {}),
            source: "customer_invoices"
        }, false)(dispatch, getState)
    }, dispatch, getState);
};
