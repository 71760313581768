import React, {useState} from "react";
import {Col, Row} from 'react-bootstrap'
import {MediaComponent} from "../../../../Scheduler/CrewMessages/MediaComponent";
import {ImageLightBox} from "../../../../Scheduler/CrewMessages/ImageLightBox";
import TimelineElementWrapper from "./TimelineElementWrapper";

const TimelineCrewMessage = ({message, images, color, employeeName, employeeDisabled, employeePrimaryPhoto}) => {
    const [showMessage, setShowMessage] = useState(false)
    const [selectedImage, setSelectedImage] = useState(null)
    const [isImageModalOpened, setIsImageModalOpened] = useState(null)

    const toggleImageModal = (value) => {
        setIsImageModalOpened(value)

        if (value !== true) {
            setSelectedImage(null)
        }
    }

    const getTextWidth = (text, font) => {
        const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement("canvas"));
        const context = canvas.getContext("2d");
        context.font = font;
        const metrics = context.measureText(text);
        return metrics.width;
    }

    const textWidth = getTextWidth(message?.body?.replace(/<[^>]+>/g, ''), "15px Open Sans")
    const containerWidth = document.getElementById('message')?.clientWidth

    const isMultipleParagraph = () => {
        return message?.body?.split('<p>').length > 2
    }

    const isShortMessage = () => {
        return containerWidth > textWidth
    }

    return (
        message &&
        <TimelineElementWrapper
            color={color}
            employeeName={employeeName}
            employeeDisabled={employeeDisabled && employeeDisabled}
            createdAt={message.created_at}
            topElement={<span>WO <strong>#{message.work_order_no}</strong></span>}
            isShowDetail={showMessage}
            hideEvent={() => setShowMessage(false)}
            employeePrimaryPhoto={employeePrimaryPhoto}
        >
            <Row>
                <Col md={12} xs={12}>
                    {isMultipleParagraph() ?
                        <p onClick={() => setShowMessage(true)}
                           className={`${showMessage ? 'message-show' : 'message-hidden multiple_paragraph'} fontSize15 no-margin-bottom`}>
                            <div dangerouslySetInnerHTML={{__html: message.body}}/>
                        </p>
                        :
                        <p id="message"
                           onClick={() => isShortMessage() && images.length === 0 ? {} : setShowMessage(true)}
                           className={`${showMessage || (isShortMessage() && images.length === 0) ? 'message-show break-spaces' : 'message-hidden'} fontSize15 no-margin-bottom`}>
                            {message.body?.replace(/<[^>]+>/g, '').length > 1 ?
                                message.body?.replace(/<[^>]+>/g, '')
                                :
                                <i className="text-gray">(No message)</i>}
                        </p>
                    }
                    <div className='flex-wrap'>
                        {images.length > 0 && showMessage && images.map(i => (
                            <MediaComponent imageId={i.id}
                                            imageSrcMini={i.mini}
                                            imageSrcOriginal={i.original}
                                            onSelectImage={img => setSelectedImage(img)}
                                            toggleImageModal={toggleImageModal}
                            />
                        ))}
                    </div>
                </Col>
            </Row>
            <ImageLightBox images={[selectedImage]}
                           isImageModalOpened={isImageModalOpened}
                           toggleImageModal={toggleImageModal}
            />
        </TimelineElementWrapper>
    )
}

export default TimelineCrewMessage;