import React, {Component} from "react"
import {bindActionCreators} from "redux"
import {connect} from "react-redux"
import PropTypes from "prop-types"
import {addAlert} from "../App/actions"
import * as MyActions from "./actions"
import Helmet from "react-helmet"
import {Col, Grid, Label, Row, Table} from "react-bootstrap"
import Stickies from "../Stickies"
import Dollars from "../../components/Dollars"
import ThDate from "../../components/ThDate"
import {Link} from "react-router"
import * as api from "../ProposalServiceStatusPage/actions"
import PromiseUi from "../../components/PromiseUi"
import PlantName from "../../components/PlantName";

const Actions = {...MyActions, addAlert: addAlert, ...api}

class ProposalView extends Component {

    state = {
        proposal: null
    }

    componentDidMount() {
        this.props.location?.query?.isDeleted ? this.loadDeletedProposal(this.props.proposalId)
            : this.loadProposal(this.props.proposalId)

    }

    componentWillReceiveProps(nextProps) {
        this.setState({proposal: nextProps.proposal})
        if (this.props.proposalId !== nextProps.proposalId) {
            this.props.location?.query?.isDeleted ? this.loadDeletedProposal(nextProps.proposalId)
                : this.loadProposal(nextProps.proposalId)
        }
    }

    loadProposal = proposalId => {
        this.props.actions.fetchProposal(proposalId)
    }

    loadDeletedProposal = proposalId => {
        this.props.actions.fetchDeletedProposal(proposalId)
    }

    renderProposal(proposal) {
        if (proposal === null) {
            return <span>Loading Proposal...</span>
        }
        const setState = this.setState
        const {promises, actions} = this.props

        return (
            <div>

                <Row>
                    <Col xs={12} className="text-center">
                        <h1><p className="text-primary">{proposal.clientName}</p></h1>
                    </Col>
                </Row>

                <Row>
                    <Col xs={4}>
                        <h4>
                            {this.props.location?.query?.isDeleted ?
                                <>Deleted Proposal #{proposal.proposalNo}</>
                                : <>Proposal #
                                    <Link to={`/mapview/${proposal.id}`}>{proposal.proposalNo}</Link></>}
                        </h4>
                        <h4>PO # {proposal.purchaseOrder}</h4>
                    </Col>

                    <Col xs={4}>
                        <h4>
                            <p className="text-success">
                                Proposal total: <Dollars amount={proposal.proposalTotal}/>
                            </p>
                        </h4>
                    </Col>

                    <Col xs={4}>
                        Proposal Date: <ThDate dateString={proposal.proposalDate}/> <br/>
                        Accepted Date: <ThDate dateString={proposal.acceptedDate}/>
                    </Col>
                </Row>

                <Row>
                    <Col xs={4}>
                        <strong>{proposal.customerName}</strong><br/>
                        {proposal.customerAddress1}<br/>
                        {proposal.customerAddress2}<br/>
                        {proposal.customerCity}
                        ,
                        {" "}
                        {proposal.customerState}
                        {" "}
                        {" "}
                        {" "}
                        {proposal.customerZip}
                        {" "}
                        <br/>
                        {proposal.customerPhone}
                    </Col>

                    <Col xs={4}>
                        <Link to={`/proposal_lead/${proposal.lead_id}`} target='_blank'>LEAD</Link>
                        <br/>
                        <Link to={`/lead/${proposal.lead_id}/time`} target='_blank'>FIND A TIME</Link>
                    </Col>

                    <Col xs={4}>
                        Sales Arborist:{" "}
                        {proposal.salesArboristName}<br/>
                        Site:{" "}
                        <strong>
                            {proposal.siteName}<br/>
                            {proposal.siteFullAddress}
                        </strong>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Table striped hover>
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>Service Description</th>
                                <th>Plants</th>
                                <th>Priority</th>
                                <th>Hours</th>
                                <th>Rate</th>
                                <th>Price</th>
                                {promises ? <th>Promised Date</th> : null}

                            </tr>
                            </thead>
                            <tbody>
                            {proposal.proposalServices.map((ps) => {
                                return (
                                    <tr key={ps.id}>
                                        <td>{ps.serviceNo}</td>

                                        <td className="width50">
                                            <u>{ps.serviceName}</u>
                                            {" "}
                                            <Label bsStyle="info">{ps.serviceStatusName}</Label>
                                            {" "}
                                            <br/>
                                            {ps.notes}
                                        </td>

                                        <td>
                                            {ps.plants.map(function (plant) {
                                                return (
                                                    <span key={plant.assetNumber}>
                              #
                                                        {plant.assetNumber}
                                                        {" "}
                                                        {" "}
                                                        {" "}
                                                        <PlantName plant={plant.plantName}/>
                                                        {" "}
                                                        {plant.plantCount > 1
                                                            ? `[${plant.plantCount}]`
                                                            : ""}
                                                        {" "}
                                                        {" "}
                            </span>
                                                )
                                            })}
                                        </td>
                                        <td><p className="text-danger">{
                                            ps.priorities && ps.priorities.length > 0 &&
                                            ps.priorities.map(p => <span
                                                key={p.id}>{p.name}{ps.priorities.length > 1 ? ', ' : ' '}</span>)}
                                        </p></td>
                                        <td><p className="text-danger">{ps.manHours}</p></td>
                                        <td>
                                            <p className="text-danger">
                                                <Dollars amount={ps.aveSalaryRate}/>
                                            </p>
                                        </td>
                                        <td>
                                            <p className="text-danger">
                                                <Dollars amount={ps.serviceCost}/>
                                            </p>
                                        </td>
                                        {promises
                                            ? <td className="width20">
                                                <PromiseUi
                                                    promisedStart={ps.promised_start_at}
                                                    promisedEnd={ps.promised_end_at}
                                                    upsertPromise={(id, startDate, endDate) => {
                                                        actions.upsertPromise(id, startDate, endDate);
                                                        let proposalService = proposal.proposalServices.find(x => x.id === id);
                                                        proposalService.promised_start_at = startDate;
                                                        proposalService.promised_end_at = endDate;
                                                        this.setState({proposal})
                                                    }}
                                                    proposalServiceId={ps.id}
                                                    serviceNo={ps.serviceNo}
                                                />
                                            </td>
                                            : null}
                                    </tr>
                                )
                            })}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="text-right">
                        Total <Dollars amount={proposal.proposalTotal}/>
                    </Col>
                </Row>
            </div>
        )
    }

    render() {
        const {proposal} = this.state

        return (
            <Grid fluid>
                <Helmet title={`Proposal #${proposal ? proposal.proposalNo : ""}`}/>
                <Row>
                    <Col xs={!this.props.location?.query?.isDeleted ? 9 : 12} className="print-full-width">
                        {this.renderProposal(proposal)}
                    </Col>

                    {!this.props.location?.query?.isDeleted ?
                        <Col xs={3} className="print-full-width visibile-print-block">
                            {proposal
                                ? <Stickies proposalId={proposal.id}/>
                                : "Loading stickies..."}
                        </Col> : null
                    }
                </Row>
            </Grid>
        )
    }
}

/*prmiseds and upsertpromise is only required if you wnt to use the promise ui*/
ProposalView.propTypes = {
    requestedProposalId: PropTypes.any
        .isRequired /*by itself, its a string, from arborist approval its a number. wahtevs.*/,
    promises: PropTypes.bool,
}

const mapStateToProps = (state, props) => {
    return {
        requestedProposalId: (props.params && props.params.proposalId) ||
            props.proposalId /*this page can be loaded via itself or by using it as a componet in another page*/,
        proposal: state.proposal.fetchedProposal,
        upsertPromise: props.upsertPromise
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProposalView)
