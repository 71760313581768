import {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from "prop-types"

class Secured extends Component {
    constructor(props, context) {
        super(props, context);
    }

    hasRole = () => {
        if (!this.props.roles) {
            return this.props.userRoles !== undefined;
        }
        return this.props.userRoles && this.props.userRoles.length > 0 && this.props.roles.split(',').map(x => x.trim()).some(role =>
            this.props.userRoles.includes(role.replace('!', '')) !== role.includes('!'));
    };

    render() {
        return (
            this.hasRole() ? this.props.children : null
        );
    }
}

Secured.propTypes = {
    children: PropTypes.object.isRequired,
    roles: PropTypes.string
};


function mapStateToProps(state) {
    return {
        userRoles: state.auth.roles
    };
}

export default connect(mapStateToProps, undefined)(Secured);