import {Col, Modal, Row} from "react-bootstrap";
import Dollar from "../../components/Dollars";
import React, {Component} from "react";
import Button from "react-bootstrap/lib/Button";

const iPad = window.innerWidth >= 768 && window.innerHeight === 1024;
const isMobile = window.innerWidth <= 768;
const screen30 = window.innerWidth >= 1024 && window.innerHeight <= 700;

export default class WorkOrderDetailsModal extends Component {

    state = {
        work_order: null
    }

    componentDidMount() {
        this.props.loadWorkOrder(this.props.work_order_id, (work_order) => {
            this.setState({work_order: work_order.contextResource})
        })
    }

    render() {
        const {client, userRoles} = this.props

        const isCrewUser = userRoles?.every(r => r === 'crew' || r === 'technician')
        const isSubcontractorUser = userRoles?.every(r => r === 'subcontractor')

        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                className="heightAuto maxHeight90">
                <Modal.Header>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="mt-15 mb-15">
                    {this.state.work_order &&
                        <Row className={`no-margin ${!isMobile && 'minus-right10'}`}>
                            <Col md={12}>
                                <Row className='top5'>
                                    <Col xs={isMobile ? 12 : 4} className={iPad ? 'bottom10' : 'no-padding'}>
                                        <div style={{lineHeight: screen30 && '16px'}}>
                                    <span>
                                    Services:
                                    </span>
                                            <div className={`modalBox borderDiv ${!isMobile && 'scrollDiv'}`}
                                                 style={{
                                                     height: "unset",
                                                     lineHeight: screen30 && '16px'
                                                 }}
                                            >
                                                <ul>
                                                    {this.state.work_order.work_order_proposal_services && this.state.work_order.work_order_proposal_services.map((wops, i) =>
                                                        <li key={i}>#{wops.service_no} {wops.service_name} {wops.est_hours}hr {((!client?.hidden_prices && isCrewUser) || (client?.display_subcontractor_price && isSubcontractorUser) || userRoles?.every(r => r !== 'subcontractor' && r !== 'crew' && r !== 'technician')) &&
                                                            <Dollar
                                                                amount={isSubcontractorUser && client.price_percent_value ? wops.price * (client.price_percent_value / 100) : wops.price}/>}
                                                            {wops.bs_services && <ul>
                                                                {wops.bs_services.map((bs_s, i) => <li
                                                                    key={i}>{bs_s}</li>)}
                                                            </ul>}
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={isMobile ? 12 : 4}
                                         style={{marginTop: isMobile && 10, padding: isMobile && 0}}>
                                        <div style={{lineHeight: screen30 && '16px'}}>
                                    <span style={{lineHeight: screen30 && '16px'}}>
                                    Equipment:
                                    </span>
                                            <div className={`modalBox borderDiv ${!isMobile && 'scrollDiv'}`}
                                                 style={{
                                                     height: "unset",
                                                     lineHeight: screen30 && '16px'
                                                 }}
                                            >
                                                <div>
                                                    <ul>
                                                        {this.state.work_order.equipment && this.state.work_order.equipment.length > 0 && this.state.work_order.equipment.map((e, i) =>
                                                            <li key={i}>{e}</li>)}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={isMobile ? 12 : 4} className='no-padding'>
                                        <div className={isMobile && 'mt10 bottom10'}
                                             style={{lineHeight: screen30 && '16px'}}>
                                    <span>
                                    JSA:
                                    </span>
                                            <div className={`modalBox borderDiv ${!isMobile && 'scrollDiv'}`}
                                                 style={{
                                                     height: "unset",
                                                     lineHeight: screen30 && '16px'
                                                 }}
                                            >
                                                <ul>
                                                    {this.state.work_order && this.state.work_order.site_hazards.map((e, i) => {
                                                            return <li key={i}>{e.site_hazard}</li>
                                                        }
                                                    )}
                                                </ul>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button type="button" onClick={this.props.onHide}
                            className="btn btn-default">
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}