import React, {Component} from 'react';
import {Button, ButtonGroup, ButtonToolbar, Col, Modal, Row} from 'react-bootstrap'
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import Attachment from "../../../components/image/Attachment";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as issuesActions from "./IssueListApi"
import * as MyActions from '../actions'
import IssueModal from "../IssueModal";
import {dateFormatter} from "../../../components/BootStrapTableCellFormatters";
import Grid from "react-bootstrap/lib/Grid";
import './IssueList.scss'
import {DocAttachment} from "../../../components/docs/DocAttachment";

const actions = {...issuesActions, ...MyActions};
const isMobile = window.screen.width <= 1024;

class IssueList extends Component {
    state = {
        resource: {},
        viewIssue: false,
        selectedIssue: null,
        showIssueModal: false,
        issues: [],
        subTab: 'active',
        page: 1,
        per_page: 10,
        total: 0
    };

    componentDidMount() {
        const {subTab} = this.state;
        this.fetchIssues(subTab)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {subTab} = this.state
        if (prevState.subTab !== subTab) {
            this.setState({page: 1}, () => this.fetchIssues(subTab))
        }
    }

    onRowClick = (row) => {
        this.setState({viewIssue: true, resource: row});
    };

    handleClose = () => {
        this.setState({viewIssue: false, resource: null});
    };

    showIssueModal = (issue) => {
        if (issue) {
            this.setState({selectedIssue: issue}, () => this.setState({showIssueModal: true, viewIssue: false}));
        }
    };
    closeIssueModal = () => {
        this.setState({showIssueModal: false, selectedIssue: null});
    };
    updateIssueAttributes = (data, issue) => {
        const {selectedIssue, subTab} = this.state;
        let issueParams = {};
        issueParams.id = selectedIssue.id;
        issueParams.status = data.status ? data.status : 'reported';
        issueParams.comment = data.comment && data.comment;
        this.props.actions.updateIssue(issueParams, () => {
            this.fetchIssues(subTab);
            this.setState({showIssueModal: false, selectedIssue: null})
        })
    };
    withModalFormatter = (cell, row) => {
        return <span className={`text-orange`} style={{fontSize: "1.2rem"}}
                     onClick={() => this.showIssueModal(row)}>{cell}</span>
    };

    renderSizePerPageDropDown = ({currSizePerPage, sizePerPageList, changeSizePerPage}) => (
        <div className="btn-group dropup">
            <button className="btn btn-info dropdown-toggle" type="button" id="dropdownMenu2"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span>{currSizePerPage}</span>
                <span className="ml-6 caret"/>
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                {sizePerPageList.map(({text, value}) =>
                    <li className='pointer'>
                        <a
                            className='table-pagination dropdown-item'
                            onClick={() => changeSizePerPage(value)}
                        >{text}</a>
                    </li>
                )}
            </ul>
        </div>
    );

    fetchIssues = (type) => {
        const {page, per_page} = this.state
        const {fetchUserIssues} = this.props
        fetchUserIssues(type, {page, per_page}, issues => {
            this.setState({subTab: type, issues: issues.content, total: issues.total})
        })
    }

    render() {
        const {viewIssue, resource, issues, subTab, total, page, per_page} = this.state;
        const {deleteIssue} = this.props;

        return (
            <Grid fluid id="issue-list">
                <Row className='bottom15'>
                    <Col md={6} className="issue-list-header-wrapper">
                        <h4 className="issue-list-title">Issues</h4>
                        <h5 className="issue-list-count">{total} returned</h5>
                    </Col>
                    <Col md={6} className="justify-flex-end">
                        <ButtonToolbar>
                            <ButtonGroup bsSize="small">
                                <Button className={subTab === 'active' && 'issue-active-btn'}
                                        onClick={() => this.setState({subTab: 'active'})}>Active</Button>
                                <Button className={subTab === 'archived' && 'issue-active-btn'}
                                        onClick={() => this.setState({subTab: 'archived'})}>Archived</Button>
                            </ButtonGroup>
                        </ButtonToolbar>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className="remaining vertical-responsive-height"
                         style={{width: isMobile ? "90vw" : ""}}>
                        <BootstrapTable
                            ref="table"
                            data={issues}
                            striped={true}
                            bordered={false}
                            hover={true}
                            pagination={true}
                            responsive={true}
                            remote
                            fetchInfo={{dataTotalSize: total}}
                            className="wrapped word-wrap"
                            options={{
                                onRowClick: row => this.onRowClick(row),
                                onPageChange: (page, per_page) => {
                                    this.setState({page, per_page}, () => this.fetchIssues(subTab));
                                },
                                page,
                                sizePerPageList: [
                                    {
                                        text: '10', value: 10
                                    },
                                    {
                                        text: '25', value: 25
                                    },
                                    {
                                        text: '30', value: 30
                                    },
                                    {
                                        text: '50', value: 50
                                    },
                                    {
                                        text: '100', value: 100
                                    },
                                    {
                                        text: '200', value: 200
                                    },
                                    {
                                        text: 'All', value: issues.length
                                    }],
                                sizePerPageDropDown: this.renderSizePerPageDropDown,
                                sizePerPage: per_page
                            }}
                        >
                            <TableHeaderColumn
                                dataField="id"
                                hidden
                                width={isMobile ? "50px" : "5%"}
                                isKey
                            />
                            <TableHeaderColumn
                                dataField="client_name"
                                dataFormat={this.customerFormatter}
                                width={isMobile ? "100px" : "25%"}
                            >
                                Client
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="page_address"
                                width={isMobile ? "150px" : "25%"}
                            >
                                Page address
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="title"
                                width={isMobile ? "125px" : "15%"}
                            >
                                Title
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="description"
                                className={'word-wrap'}
                                width={isMobile ? "250px" : "35%"}
                            >
                                Description
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="person_full_name"
                                width={isMobile ? "150px" : "20%"}
                            >
                                Created By
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="created_at"
                                dataFormat={dateFormatter}
                                width={isMobile ? "100px" : "20%"}
                            >
                                Created At
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="updated_at"
                                dataFormat={dateFormatter}
                                width={isMobile ? "100px" : "20%"}
                            >
                                Updated At
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="status"
                                dataFormat={this.withModalFormatter}
                                width={isMobile ? "100px" : "15%"}
                            >
                                Status
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="comment"
                                dataFormat={this.withModalFormatter}
                                width={isMobile ? "250px" : "25%"}
                            >
                                Comment
                            </TableHeaderColumn>
                        </BootstrapTable>
                        {viewIssue &&
                            <Modal
                                bsSize="medium"
                                animation={false}
                                show={true}
                                onHide={this.handleClose}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title>Issue preview</Modal.Title>
                                </Modal.Header>
                                <Modal.Body className="top10">
                                    <Row style={{marginBottom: '15px'}}>
                                        <Col sm={2}>
                                            <b>Client</b>
                                        </Col>
                                        <Col sm={10}>
                                            <p className='break-word-issue'>{resource.client_name}</p>
                                        </Col>
                                    </Row>
                                    <Row style={{marginBottom: '15px'}}>
                                        <Col sm={2}>
                                            <b className='break-word-issue'>Page address</b>
                                        </Col>
                                        <Col sm={10}>
                                            <span>{resource.page_address}</span>
                                        </Col>
                                    </Row>
                                    <Row style={{marginBottom: '15px'}}>
                                        <Col sm={2}>
                                            <b>Status</b>
                                        </Col>
                                        <Col sm={10}>
                                            <p>{resource.status}</p>
                                        </Col>
                                    </Row>
                                    <Row style={{marginBottom: '15px'}}>
                                        <Col sm={2}>
                                            <b>Title</b>
                                        </Col>
                                        <Col sm={10}>
                                            <p className='break-word-issue'>{resource.title}</p>
                                        </Col>
                                    </Row>
                                    <Row style={{marginBottom: '15px'}}>
                                        <Col sm={2}>
                                            <b>Description</b>
                                        </Col>
                                        <Col sm={10}>
                                            <p className='break-word-issue'>{resource.description}</p>
                                        </Col>
                                    </Row>
                                    <Row style={{marginBottom: '15px'}}>
                                        <Col sm={2}>
                                            <b>Created By</b>
                                        </Col>
                                        <Col sm={10}>
                                            <p>{resource.person_full_name}</p>
                                        </Col>
                                    </Row>
                                    <Row style={{marginBottom: '15px'}}>
                                        <Col sm={2}>
                                            <b>Attachments</b>
                                        </Col>
                                        <Col sm={10} className='d-flex'>
                                            <Row>
                                                <Col sm={12} className='d-flex'>
                                                    {resource.images.map(i => <Attachment
                                                        isIssueModal={true}
                                                        key={i.id}
                                                        image={i}/>
                                                    )}
                                                </Col>
                                                <Col sm={12} className='mt10'>
                                                    {resource.files && resource.files.map(file => {
                                                            if (file.type.includes('video') || file.type.includes('x-matroska')) {
                                                                return <video
                                                                    src={file.url}
                                                                    controls
                                                                    className="issue-record-video mr-5"
                                                                />
                                                            } else {
                                                                return <DocAttachment
                                                                    key={file.id}
                                                                    file={file}
                                                                    allowRemove={false}
                                                                />
                                                            }

                                                        }
                                                    )}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    {resource.comment && <Row style={{marginBottom: '15px'}}>
                                        <Col sm={2}>
                                            <b>Comment</b>
                                        </Col>
                                        <Col sm={10}>
                                            <p className='break-word-issue'>{resource.comment}</p>
                                        </Col>
                                    </Row>}
                                </Modal.Body>
                                <Modal.Footer>
                                    <div className='issue-preview-footer-buttons'>
                                        <Button onClick={() => {
                                            deleteIssue(resource.id, () => {
                                                this.fetchIssues(subTab)
                                            });
                                            this.handleClose();
                                        }}>
                                            Delete
                                        </Button>
                                        <Button onClick={() => this.handleClose()}>
                                            Close
                                        </Button>
                                    </div>
                                </Modal.Footer>
                            </Modal>
                        }
                        {this.state.showIssueModal &&
                            <IssueModal show={this.state.showIssueModal} onHide={this.closeIssueModal}
                                        issue={this.state.selectedIssue} onSave={this.updateIssueAttributes}/>}
                    </Col>
                </Row>
            </Grid>
        )
    }
}

IssueList.defaultProps = {
    issueList: [
        {
            id: 1,
            user_name: "velobuff@gmail.com",
            page_address: "test.com",
            title: "Test",
        }
    ]
};

const mapStateToProps = (state) => ({
    state: state
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(IssueList);