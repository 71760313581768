import React, {Component} from 'react';
import RemoveProposal from './RemoveProposal';
import TransferPlant from './TransferPlant';
import RemoveCustomer from './RemoveCustomer';
import {Col, ListGroup, ListGroupItem, Row, Well} from "react-bootstrap";
import './ManageData.scss'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLeaf, faListAlt, faUser} from "@fortawesome/free-solid-svg-icons";

const smMobile = window.screen.width <= 450;

class ManageData extends Component {
    state = {
        data: [{
            value: 'plants',
            label: 'Plants',
            description: 'Allow transfer plants assets to another plant',
            color: '#36d6a6',
            icon: faLeaf
        }, {
            value: 'proposals',
            label: 'Proposals',
            color: '#e6bb00',
            icon: faListAlt
        },
            {
                value: 'customers',
                label: 'Customers',
                color: '#34abeb',
                icon: faUser
            }
        ],
        selected_data: 'plants',
        activeIndex: 0
    };

    selectedData = (e, index) => {
        e.preventDefault();
        this.setState({selected_data: e.target.name, activeIndex: index})
    };

    renderComponent = () => {
        const {selected_data} = this.state;
        if (selected_data === 'plants') {
            return <TransferPlant/>
        } else if (selected_data === 'proposals') {
            return <RemoveProposal/>
        } else if (selected_data === 'customers') {
            return <RemoveCustomer/>
        }
    };

    render() {
        const {data, activeIndex} = this.state;

        return (
            <div className="manage-data">
                <Row className="wrapper">
                    <Col md={3} className={`${smMobile && "full-width"} &&list_column`}>
                        <ListGroup className="list">
                            <div className="list_wrapper">
                                <h3 className="title">Data list</h3>
                                {data && data.map((item, index) => {
                                    const className = activeIndex === index ? 'list_item active_list_item' : 'list_item';
                                    return (
                                        <ListGroupItem key={index} className={className} name={item.value}
                                                       style={{background: item.color}}
                                                       onClick={e => this.selectedData(e, index)}>
                                            {item.label}
                                            <FontAwesomeIcon icon={item.icon} className="mr-9 font24"/>
                                        </ListGroupItem>
                                    )
                                })}
                            </div>
                        </ListGroup>
                    </Col>
                    <Col md={7} className="list_components">
                        <Well className="component_wrapper">
                            {this.renderComponent()}
                        </Well>
                    </Col>
                    <Col md={2}/>
                </Row>
            </div>
        )
    }
}

export default ManageData;