import {EDIT_FILTER_TEXT, EDITING_SERVICE_ID} from "./constants";

const initialState = {
    editingServiceId: null,
    filterText: ""
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case EDITING_SERVICE_ID:
            return {...state, editingServiceId: action.editingServiceId};
        case EDIT_FILTER_TEXT:
            return {...state, filterText: action.filterText};
        default:
            return state;
    }
}
