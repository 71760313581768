import React, {useEffect, useState} from 'react';
import {Button, Col, Row} from 'react-bootstrap'
import {BootstrapTable, SizePerPageDropDown, TableHeaderColumn} from "react-bootstrap-table";
import LeadsFilterModal from "../LeadsPage/LeadsFilterModal";
import ActivityListFilters from "./ActivityListFilters";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PageHeader from "../../components/PageTitle";
import './ActivityList.css'
import {dateFormatter} from "../../components/BootStrapTableCellFormatters";
import {DocAttachment} from "../../components/docs/DocAttachment";
import {getClientsSearch} from "./NewAdminPage/AdminPageApi"
import {bindActionCreators} from "redux";
import {connect} from "react-redux";


const actions = {
    getClientsSearch
}

const isMobile = window.screen.width <= 1024;

const ActivityList = ({onTruncate, onRefresh, actions}) => {
    const [page, setPage] = useState(1)
    const [per_page, setPer_page] = useState(25)
    const [activityList, setActivityList] = useState(null)
    const [total, setTotal] = useState(0)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [resource, setResource] = useState({})
    const [activityListFilterSelectedClients, setActivityListFilterSelectedClients] = useState([])
    const [clientOptions, setClientOptions] = useState([])

    useEffect(() => {
        loadActivities()
    }, [page, per_page])

    const loadActivities = () => {
        onRefresh({page, per_page, ...resource}, (response) => {
            setActivityList(response.activities)
            setTotal(response.count)
        })
    }

    const onToggleDropDown = (toggleDropDown) => toggleDropDown();

    const renderSizePerPageDropDown = (props) => {
        const {toggleDropDown} = props;

        return (
            <SizePerPageDropDown
                className='my-size-per-page'
                variation='dropup'
                onClick={() => onToggleDropDown(toggleDropDown)}
                currSizePerPage={per_page + ""}
            />
        );
    };

    const onChangeFilters = (key, value) => {
        setResource({...resource, [key]: value})
    };

    const toggleModal = (e) => {
        e.preventDefault();
        setIsModalOpen(!isModalOpen);
    };

    const indexN = (cell, row, enumObject, index) => <div>{(page * per_page - per_page) + index + 1}</div>;

    const attachmentsFormatter = (cell, row) => {
        return <div>
            {row.attachment && <DocAttachment key={row.id}
                                              file={row.attachment}
                                              renderName={true}
                                              allowRemove={false}/>}
        </div>;
    }


    return (
        <div id='activity-list'>
            <Row>
                <Col md={6} className="activity_list_header_wrapper flex-gap-10">
                    <PageHeader pageName="User Activities" pageDetail={`${total} records`}/>
                </Col>
                <Col md={6} className="justify-flex-end mt10 flex-gap-10 height30">
                    <Button bsStyle="default"
                            onClick={e => onTruncate()}
                            className='pull-right'
                            bsSize='xsmall'>Truncate
                        Older</Button>
                    <Button className="btn-success" bsSize='xsmall' onClick={() => {
                        setPage(1)
                        loadActivities()
                    }}>
                        Refresh
                    </Button>
                    <Button className="filter-button-small" onClick={e => toggleModal(e)}>
                        Filter {" "}<FontAwesomeIcon icon="search" className="small-margin"/>
                    </Button>
                </Col>
            </Row>
            <Col md={12} className="remaining vertical-responsive-height no-padding">
                {activityList ?
                    <BootstrapTable
                        data={activityList}
                        striped={true}
                        bordered={false}
                        hover={true}
                        pagination={true}
                        remote
                        fetchInfo={{dataTotalSize: total}}
                        options={{
                            onPageChange: (page, per_page) => {
                                page === 0 ? setPage(1) : setPage(page)
                                setPer_page(per_page)
                            },
                            page,
                            sizePerPageDropDown: renderSizePerPageDropDown,
                            sizePerPageList: [{
                                text: '10', value: 10
                            }, {
                                text: '25', value: 25
                            },
                                {
                                    text: '30', value: 30
                                },
                                {
                                    text: '50', value: 50
                                },
                                {
                                    text: '100', value: 100
                                },
                                {
                                    text: '200', value: 200
                                }],
                            sizePerPage: per_page,
                        }
                        }
                    >
                        <TableHeaderColumn hidden isKey dataField="id"/>
                        <TableHeaderColumn dataField="any"
                                           dataFormat={indexN}
                                           width={"3%"}
                        >
                            #
                        </TableHeaderColumn>
                        <TableHeaderColumn tdStyle={{whiteSpace: 'normal', wordWrap: 'break-word'}}
                                           dataField="user_email" width='30%'>
                            User
                        </TableHeaderColumn>
                        <TableHeaderColumn tdStyle={{whiteSpace: 'normal', wordWrap: 'break-word'}} dataField="activity"
                                           width='47%'>
                            Activity
                        </TableHeaderColumn>
                        <TableHeaderColumn tdStyle={{whiteSpace: 'normal', wordWrap: 'break-word'}}
                                           dataField="created_at" dataFormat={dateFormatter} width='10%'>
                            When
                        </TableHeaderColumn>
                        <TableHeaderColumn tdStyle={{whiteSpace: 'normal', wordWrap: 'break-word'}}
                                           dataField="attachments" width='10%' dataFormat={attachmentsFormatter}>
                            Attachments
                        </TableHeaderColumn>
                    </BootstrapTable> : null}
                {isModalOpen &&
                    <LeadsFilterModal
                        title="Activities Filter"
                        show={isModalOpen}
                        onHide={() => {
                            setIsModalOpen(false)
                            page === 1 ? loadActivities() : setPage(1)
                        }}
                        closeButton={() => setIsModalOpen(false)}
                    >
                        <ActivityListFilters
                            resource={resource}
                            onChangeFilters={onChangeFilters}
                            getClientsSearch={actions.getClientsSearch}
                            selectedClients={activityListFilterSelectedClients}
                            onChangeSelectedClients={e => setActivityListFilterSelectedClients(e)}
                            clientOptions={clientOptions}
                            onChangeClientOptions={e => setClientOptions(e)}
                        />
                    </LeadsFilterModal>
                }
            </Col>
        </div>
    )
}

const mapDispatchToProps = dispatch => (
    {
        actions: bindActionCreators(actions, dispatch),
    }
)

export default connect(undefined, mapDispatchToProps)(ActivityList)