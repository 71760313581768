import React from "react"
import PropTypes from "prop-types"
import ResourceComponent from "./ResourceComponent";
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './PromiseUi.css';
import DateRangeFilter from "./DateRangeFilter";

class PromiseUi extends ResourceComponent {

    render() {
        const {
            promisedStart,
            promisedEnd,
            customClassName,
            upsertPromise,
            proposalServiceId,
            serviceNo,
            startPlaceholder,
            endPlaceholder,
            index,
            timeDelay,
            anchorDirection
        } = this.props;

        return (
            <DateRangeFilter
                customClassName={customClassName}
                anchorDirection={anchorDirection}
                promisedStart={promisedStart}
                promisedEnd={promisedEnd}
                upsertPromise={upsertPromise}
                proposalServiceId={proposalServiceId}
                serviceNo={serviceNo}
                startPlaceholder={startPlaceholder}
                endPlaceholder={endPlaceholder}
                index={index}
                timeDelay={timeDelay}
            />
        )
    }
};

PromiseUi.propTypes = {
    proposalServiceId: PropTypes.number,
    promisedStart: PropTypes.string,
    promisedEnd: PropTypes.string,
    upsertPromise: PropTypes.func.isRequired,
    start_id: PropTypes.string,
    end_id: PropTypes.string,
    timeDelay: PropTypes.number
};
export default PromiseUi
