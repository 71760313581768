import {
    Button,
    Col,
    FormControl,
    FormGroup,
    Glyphicon,
    InputGroup,
    Modal,
    OverlayTrigger,
    Row,
    Tooltip,
} from 'react-bootstrap';
import React, {Component} from 'react';
import ColorCheckbox from '../../Scheduler/ColorCheckbox';
import {PaymentElement} from './StripeElements';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import {dateFormatter, dollarsFormatter} from '../../../components/BootStrapTableCellFormatters';
import Select, {Creatable} from 'react-select';
import {addAlert} from '../../App/actions';
import {showDock} from '../../../components/dock/actions';
import * as MyActions from './actions';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import './payment.css';
import RefundReasonModal from './RefundReasonModal';
import Plaid from './Plaid/Plaid';
import Dollars from '../../../components/Dollars';
import {creatableSelect} from '../../../common/commonHandlers';
import InputGroupAddon from 'react-bootstrap/lib/InputGroupAddon';
import {Link} from 'react-router';
import moment from "moment";

const Actions = {...MyActions, addAlert, showDock};
const isMobile = window.screen.width < 1024;


class PaymentModal extends Component {

    constructor(props) {
        super(props);
        this.paymentModalScrollable = React.createRef();
    }

    state = {
        invoice: null,
        stripeCredentials: {},
        paymentResult: '',
        payWithStripe: false,
        payWithBalance: false,
        payPartial: false,
        partialAmount: null,
        showRefundModal: null,
        achPaymentsVisible: false,
        acceptPartialValue: false,
        feeACH: null,
        feeStripe: null,
        default_payment_method: null,
        stripe_balance: 0,
        newCard: false,
        saveCard: false,
        last4: '',
    };

    calculateAmount = (amount, total) => {
        const {invoice} = this.state;
        const fee = invoice.customer_fee && invoice.customer_fee.includes('%') ? +invoice.customer_fee.slice(0, -1) * amount / 100 : (invoice.customer_fee && invoice.customer_fee.includes('$')) ? +invoice.customer_fee.substr(1) : null;
        const clientFee = invoice.client_fee && invoice.client_fee.includes('%') ? +invoice.client_fee.slice(0, -1) * amount / 100 : (invoice.client_fee && invoice.client_fee.includes('$')) ? +invoice.client_fee.substr(1) : null;
        const calculated = +amount + fee + clientFee;
        return calculated > total ? total : calculated;
    };

    dollarFormatPartialAmount = (e, callback) => {
        const value = (e.target.value && e.target.value !== '') ? parseFloat(e.target.value.toString().replace(/[^0-9.-]/g, '')).toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }) : e.target.value;
        this.setState({partialAmount: value}, callback);
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {payByACH} = this.props;
        if (prevState.payWithStripe !== this.state.payWithStripe && !this.state.payWithStripe) {
            this.props.changePaymentMethod('Paid');
        }
        if (prevProps.payByACH !== payByACH && !payByACH) {
            const localStorageData = ['invoice_token', 'link_token', 'plaid_customer'];
            localStorageData.map(data => localStorage.removeItem(data));
        }
    }

    buttonFormatter = (cell, row) => {
        return row.status === 'paid' ?
            this.state.invoice.refunds_enabled && row.charge_id ?
                <Button disabled={moment().diff(moment(row.payment_date), "d") > 90} bsStyle="success"
                        onClick={() => this.setState({showRefundModal: row.charge_id})}>
                    Request Refund
                </Button> : 'Paid'
            : row.status === 'refund_requested' ? 'Requested' : 'Refunded';
    };

    paymentTypeFormatter = (cell, row) => {
        return row.reference_no ? <div className='d-flex'>
            <span>{row.payment_type_name}</span>
            <div className='ml-2'>
                <OverlayTrigger
                    placement={'top'}
                    overlay={
                        <Tooltip id={`tooltip-reference_no`}>
                            <div>Reference No:</div>
                            <div>{row.reference_no}</div>
                        </Tooltip>
                    }
                >
                    <Glyphicon className='pointer' glyph='info-sign'/>
                </OverlayTrigger>
            </div>
        </div> : row.payment_type_name;
    };

    handleRequestRefund = (charge_id, data) => {
        this.props.actions.requestRefund(this.state.invoice.token, charge_id, data.reason, () => {
            this.reloadInvoice();
            this.setState({showRefundModal: null, payWithStripe: false});
        });
    };

    calculateWithStripeFee = (amount) => {
        const totalWithStripeFee = (parseFloat(amount) + 0.30) / (1 - 0.029);
        const fee = totalWithStripeFee - amount;
        return {
            fee: this.state.invoice.stripe_processing_fee ? fee : null,
            total: this.state.invoice.stripe_processing_fee ? totalWithStripeFee : amount,
        };
    };

    calculateWithStripeFeeConvert = (totalWithStripeFee) => {
        let amount = (totalWithStripeFee * (1 - 0.029)) - 0.30;
        const fee = totalWithStripeFee - amount;
        return {
            fee: this.state.invoice.stripe_processing_fee ? fee : null,
            total: this.state.invoice.stripe_processing_fee ? totalWithStripeFee : amount,
        };
    };

    calculateMaxBalanceAmount = () => {
        const {stripe_balance, invoice} = this.state;

        let newPartialTotal = (stripe_balance / (1 + (invoice.customer_fee && invoice.customer_fee.includes('%') ? parseFloat(invoice.customer_fee) / 100 : 0) + (invoice.client_fee && invoice.client_fee.includes('%') ? parseFloat(invoice.client_fee) / 100 : 0))) - (invoice.customer_fee && invoice.customer_fee.includes('$') ? +invoice.customer_fee.substr(1) : 0) - (invoice.client_fee && invoice.client_fee.includes('$') ? +invoice.client_fee.substr(1) : 0);
        let partialValue = (newPartialTotal - this.calculateWithStripeFeeConvert(newPartialTotal).fee).toFixed(2);

        let partialCustomerFee = invoice.customer_fee && (invoice.customer_fee.includes('%') ? partialValue * parseFloat(invoice.customer_fee) / 100 : invoice.customer_fee.includes('$') ? +invoice.customer_fee.substr(1) : null);
        let partialClientFee = invoice.client_fee && (invoice.client_fee.includes('%') ? partialValue * parseFloat(invoice.client_fee) / 100 : invoice.client_fee.includes('$') ? +invoice.client_fee.substr(1) : null);
        let partialTotal = partialValue && parseFloat(partialValue) + partialCustomerFee + partialClientFee;
        let price = partialClientFee + partialCustomerFee + this.calculateWithStripeFee(partialTotal).fee;

        return {
            partialValue: partialValue > 0 ? partialValue : 0,
            price: partialValue > 0 ? price : 0,
        };
    };

    calculateACHvalue = (amount) => {
        if (amount) {
            let basedAmount = parseFloat(amount);
            let totalACHAmount = basedAmount / (1 - 0.008);

            if (basedAmount > 625) {
                return 5;
            } else {
                return (totalACHAmount - basedAmount);
            }
        } else {
            return 0;
        }
    };

    closeRefundModal = () => {
        this.setState({showRefundModal: null});
    };

    handlePayment = (token, callback) => {
        const {invoice, partialAmount, payPartial, payWithBalance} = this.state;
        if (payPartial) {
            this.props.actions.charge(invoice.token, token, partialAmount, payWithBalance, this.state.newCard, this.state.saveCard, result => {
                this.setState({
                    paymentResult: result,
                    payWithStripe: false,
                    payPartial: false,
                }, () => this.props.changePaymentMethod('Paid'));
                if (result.status) this.reloadInvoice();
                callback(result);
            });
        } else if (!payPartial) {
            this.props.actions.charge(invoice.token, token, (invoice.invoice_total - invoice.paid_amount).toFixed(2), payWithBalance, this.state.newCard, this.state.saveCard, result => {
                this.setState({
                    paymentResult: result,
                    payWithStripe: false,
                    payPartial: false,
                }, () => this.props.changePaymentMethod('Paid'));
                if (result.status)
                    this.reloadInvoice();
                callback(result);
            });
        }
    };

    handleResult = (token, callback) => {
        this.props.actions.updatePaymentSettings('payByStripe', this.state.invoice.customer_id);
        if (this.state.saveCard) {
            this.props.actions.savePaymentMethod({
                    customer_id: this.state.invoice.customer_id,
                    card_token: token,
                    set_as_default: true,
                },
                () => {
                    this.props.actions.getDefaultPaymentMethod(this.state.invoice.token, res => {
                        this.handlePayment(res.default_source, callback);
                    });
                });
        } else {
            this.handlePayment(token, callback);
        }
    };


    reloadInvoice = () => {
        !this.props.invoiceList && this.setState({
            achPaymentsVisible: false,
        }, this.props.changePaymentMethod('Paid'));
        this.props.reload();
    };

    getDefaultPaymentMethod = () => {
        this.props.actions.getDefaultPaymentMethod(this.state.invoice.token, res => {
            this.setState({default_payment_method: res.default_source, last4: res.last4}, () => {
                if (this.state.invoice.customer_payment_settings) {
                    switch (this.state.invoice.customer_payment_settings) {
                        case 'payByACH':
                            this.onPayByACHCheckboxSelect(true);
                            break;
                        case 'payByStripe':
                            this.onPayByStripeCheckboxSelect(true);
                            break;
                        case 'payByManualPayment':
                            this.props.invoiceList && this.onPayByManualPaymentCheckboxSelect(true);
                            break;
                    }
                }
            });
        });
    };
    loadStripeCredentials = (invoiceId) => {
        this.props.actions.loadStripeCredentials(this.state.invoice.client_id, result => {
            this.setState({
                stripeCredentials: result,
            });
        });
        this.getDefaultPaymentMethod();
    };

    componentDidMount() {
        if (this.props.invoice) {
            this.setState({invoice: this.props.invoice}, () => this.loadStripeCredentials(this.props.invoice.client_id));
        }
        if (this.props.invoice?.proposal_token) {
            this.props.actions.loadWalletBalance(this.props.invoice.proposal_token, res => this.setState({stripe_balance: res / 100}));
        }
    }

    onSelectInvoice = (invoice) => {
        this.props.actions.fetchInvoice(invoice.value, result => {
            this.setState({
                invoice: result,
                payWithStripe: false,
            }, () => this.loadStripeCredentials(result.client_id));
        });
    };

    rowFormatter = (cell, row) => (
        <div>
            <Row>
                <Col xs={6}>Created at</Col>
                <Col xs={6}>{dateFormatter(row.created_at)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Amount</Col>
                <Col xs={6}>{dollarsFormatter(row.amount)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Fee</Col>
                <Col xs={6}>{dollarsFormatter(row.fee_amount)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Total</Col>
                <Col xs={6}>{dollarsFormatter(row.total)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Request refund</Col>
                <Col xs={6}>{this.buttonFormatter(cell, row)}</Col>
            </Row>
        </div>
    );

    renderMobileTable = (invoice) => (
        <BootstrapTable
            ref='table'
            data={invoice.payments}
            striped={true}
            bordered={false}
            hover={true}
            remote
            fetchInfo={{dataTotalSize: invoice.payments.length}}
            className='wrapped'
        >
            <TableHeaderColumn
                dataField='id'
                hidden
                isKey
            />
            <TableHeaderColumn
                dataFormat={(cell, row) => this.rowFormatter(cell, row)}
            >
            </TableHeaderColumn>
        </BootstrapTable>
    );

    setACHCharge = (acceptPartialValue, partialAmount) => {
        const {invoice} = this.state;
        if (invoice.proposal_services.filter(ps => ps.service_name === 'Convenience fee').length > 0) {
            invoice.proposal_services = invoice.proposal_services.filter(ps => ps.service_name !== 'Convenience fee');
        }

        if (acceptPartialValue) {
            let stripeCharge = {};
            stripeCharge.service_name = 'Convenience fee';
            stripeCharge.price = this.calculateACHvalue(partialAmount);
            this.setState({feeACH: stripeCharge.price});
            if (invoice.proposal_services.filter(ps => ps.service_name === 'Convenience fee').length === 0) {
                invoice.proposal_services.push(stripeCharge);
            }
            this.setState({invoice: invoice});
        }
    };

    onPayByACHCheckboxSelect = (e) => {
        const {
            invoice,
            achPaymentsVisible,
        } = this.state;
        const {
            changePaymentMethod,
        } = this.props;
        const balanceDue = invoice && invoice.invoice_total - invoice.paid_amount;
        if (invoice.proposal_services.filter(ps => ps.service_name === 'Convenience fee').length > 0) {
            invoice.proposal_services = invoice.proposal_services.filter(ps => ps.service_name !== 'Convenience fee');
        }

        if (e) {
            let stripeCharge = {};
            stripeCharge.service_name = 'Convenience fee';
            stripeCharge.price = this.calculateACHvalue(balanceDue);
            this.setState({feeACH: stripeCharge.price});
            if (invoice.proposal_services.filter(ps => ps.service_name === 'Convenience fee').length === 0) {
                invoice.proposal_services.push(stripeCharge);
            }
        } else {
            if (invoice.proposal_services.filter(ps => ps.service_name === 'Convenience fee').length > 0) {
                invoice.proposal_services = invoice.proposal_services.filter(ps => ps.service_name !== 'Convenience fee');
            }
        }
        this.setState({
            achPaymentsVisible: !achPaymentsVisible,
            invoice: invoice,
            payPartial: false,
            partialAmount: '',
        }, () => {
            changePaymentMethod('payByACH', !!e);
        });
    };

    onPayByStripeCheckboxSelect = (e) => {
        const {
            invoice,
            payWithStripe,
        } = this.state;
        const {
            invoiceList,
            changePaymentMethod,
        } = this.props;
        const customerFee = invoice && invoice.customer_fee && (invoice.customer_fee.includes('%') ? (invoice.invoice_total - invoice.paid_amount) * parseFloat(invoice.customer_fee) / 100 : invoice.customer_fee.includes('$') ? +invoice.customer_fee.substr(1) : null);
        const clientFee = invoice && invoice.client_fee && (invoice.client_fee.includes('%') ? (invoice.invoice_total - invoice.paid_amount) * parseFloat(invoice.client_fee) / 100 : invoice.client_fee.includes('$') ? +invoice.client_fee.substr(1) : null);
        const balanceDue = invoice && invoice.invoice_total - invoice.paid_amount;
        if (invoice.proposal_services.filter(ps => ps.service_name === 'Convenience fee').length > 0) {
            invoice.proposal_services = invoice.proposal_services.filter(ps => ps.service_name !== 'Convenience fee');
        }

        if (invoice.proposal_services.filter(ps => ps.service_name === 'Convenience fee').length > 0) {
            invoice.proposal_services = invoice.proposal_services.filter(ps => ps.service_name !== 'Convenience fee');
        }

        if (e) {
            let stripeCharge = {};
            stripeCharge.service_name = 'Convenience fee';
            stripeCharge.price = customerFee + clientFee + this.calculateWithStripeFee(balanceDue + customerFee + clientFee).fee;
            this.setState({feeStripe: stripeCharge.price});
            if (invoice.proposal_services.filter(ps => ps.service_name === 'Convenience fee').length === 0) {
                invoice.proposal_services.push(stripeCharge);
            }
        } else {
            if (invoice.proposal_services.filter(ps => ps.service_name === 'Convenience fee').length > 0) {
                invoice.proposal_services = invoice.proposal_services.filter(ps => ps.service_name !== 'Convenience fee');
            }
        }
        this.setState({
            payWithStripe: !payWithStripe,
            invoice: invoice,
            payWithBalance: false,
            payPartial: false,
            partialAmount: 0,
        }, () => changePaymentMethod('payByStripe', !!e));
    };

    onPayByManualPaymentCheckboxSelect = (e) => {
        const {invoice} = this.state;
        const {changePaymentMethod} = this.props;
        this.props.actions.getManualPaymentTypes((res) => {
            let manualPaymentTypes = res.map((pt) => {
                pt.label = pt.name;
                pt.value = pt.id;
                return pt;
            });
            this.setState({
                manualPaymentTypes,
                payPartial: false,
                partialAmount: '',
            });
            changePaymentMethod('payByManualPayment', !!e);
        });
    };

    doManualPayment = () => {
        this.props.actions.updatePaymentSettings('payByManualPayment', this.state.invoice.customer_id);
        const {invoice, payPartial, partialAmount, manualPaymentType, manualPaymentReferenceNo} = this.state;
        const balanceDue = invoice && invoice.invoice_total - invoice.paid_amount;
        this.props.actions.addPayment(invoice.token, payPartial ? partialAmount : balanceDue, null, manualPaymentType, manualPaymentReferenceNo, () => {
            this.props.onHide();
            this.reloadInvoice();
        });
    };

    addManualPaymentType = (name, callback) => {
        this.props.actions.addPaymentType(name, (res) => {
            callback && callback(res);
        });
    };

    render() {
        const {
            achPaymentsVisible,
            payWithStripe,
            invoice,
            stripeCredentials,
            paymentResult,
            stripe_balance,
            manualPaymentType,
            manualPaymentTypes,
            manualPaymentReferenceNo,
        } = this.state;
        const {
            show,
            invoicesList,
            changePaymentMethod,
            payByACH,
            payByStripe,
            payByManualPayment,
            newViewEnabled = false,
            invoiceList = false,
        } = this.props;

        let total = invoice && invoice.proposal_services.reduce((total, ps) => {
            return total + (ps.status !== 'Not Offered' ? ps.price : 0);
        }, 0);
        total = total && (total + total * invoice.tax);

        const customerFee = invoice && invoice.customer_fee && (invoice.customer_fee.includes('%') ? (invoice.invoice_total - invoice.paid_amount) * parseFloat(invoice.customer_fee) / 100 : invoice.customer_fee.includes('$') ? +invoice.customer_fee.substr(1) : null);
        const clientFee = invoice && invoice.client_fee && (invoice.client_fee.includes('%') ? (invoice.invoice_total - invoice.paid_amount) * parseFloat(invoice.client_fee) / 100 : invoice.client_fee.includes('$') ? +invoice.client_fee.substr(1) : null);
        const balanceDue = invoice && invoice.invoice_total - invoice.paid_amount;
        let amount;
        let convenienceFee;
        if (invoice) {
            amount = (this.state.payPartial && (this.state.partialAmount === '' || +this.state.partialAmount === 0))
                ?
                0
                : (this.state.payPartial && this.state.partialAmount !== '' && this.state.partialAmount > 0)
                    ?
                    this.calculateWithStripeFee(this.calculateAmount(this.state.partialAmount, total - invoice.paid_amount)).total.toFixed(2)
                    :
                    (invoice.invoice_total - invoice.paid_amount + (customerFee + clientFee + this.calculateWithStripeFee(invoice.invoice_total - invoice.paid_amount + (customerFee + clientFee)).fee)).toFixed(2);
        }
        return (
            <Modal
                {...this.props}
                bsSize='normal'
                animation={true}
                backdrop={false}
                onHide={() => this.props.onHide()}
                id='customer-payment-modal'
            >
                <Modal.Header closeButton>
                    <Col xs={11} className='no-padding ml-4'>
                        {!this.props.invoice ? <Select
                            isClearable={false}
                            options={invoicesList}
                            onChange={this.onSelectInvoice}
                            placeholder='Select Invoice No'
                        /> : <>
                            {this.props.invoiceList &&
                                <>
                                    <div>
                                        <h4><b>Customer: </b><span>{this.props.invoice.customer_full_name}</span></h4>
                                    </div>
                                    <div>
                                        <h5><b>Invoice No: </b><span>#{this.props.invoice.number}</span></h5>
                                    </div>
                                </>
                            }
                        </>}
                    </Col>
                </Modal.Header>
                {invoice &&
                    <Modal.Body>
                        {stripeCredentials.stripe_publishable_key && stripeCredentials.stripe_enabled && invoice.enable_payments &&
                            <Row style={{marginBottom: payByManualPayment ? 250 : 0}}>
                                {stripeCredentials.stripe_user_id && <>
                                    <div className='paddingLeft'>
                                        {!invoice.paid_at && invoice.enable_ach_payments ?

                                            <h4 className='text-primary'>
                                                <ColorCheckbox
                                                    label={`ACH Payments: Processing fee ${this.state.feeACH ? '$' + this.state.feeACH.toFixed(2) : '$' + this.calculateACHvalue(balanceDue).toFixed(2)}`}
                                                    className='vertical-align'
                                                    value={payByACH}
                                                    onChange={e => {
                                                        this.onPayByACHCheckboxSelect(e);
                                                    }}
                                                />
                                            </h4> : null
                                        }
                                        <div>{payByACH && !invoice.paid_at ?
                                            <Plaid total={invoice.invoice_total - invoice.paid_amount}
                                                   invoiceToken={invoice.token}
                                                   invoiceNo={invoice.number}
                                                   setACHCharge={this.setACHCharge}
                                                   reloadInvoice={this.reloadInvoice}
                                                   handleResult={() => this.props.actions.updatePaymentSettings('payByACH', this.state.invoice.customer_id)}/>
                                            : null}</div>
                                    </div>
                                    {!invoice.paid_at && <Col xs={12} className='text-center'>
                                        <h4 className='text-primary'>
                                            <ColorCheckbox id='payByStripeCheckbox'
                                                           value={payByStripe}
                                                           label={`Pay with credit card: Processing fee $${this.state.feeStripe || this.state.feeStripe === 0 ? this.state.feeStripe.toFixed(2) : (customerFee + clientFee + this.calculateWithStripeFee(balanceDue + customerFee + clientFee).fee).toFixed(2)}`}
                                                           className='vertical-align payByStripeCheckbox'
                                                           onChange={e => {
                                                               this.onPayByStripeCheckboxSelect(e);
                                                           }}
                                            />
                                        </h4>
                                        {(payByStripe && this.state.default_payment_method) &&
                                            <p>Payments are processed using the default
                                                card. {!this.props.invoiceList && <>You can manage your
                                                    payment methods <Link
                                                        to={`/customer_view/${this.state.invoice.proposal_token}/wallet`}>here</Link>.</>}
                                            </p>}
                                    </Col>}

                                    {!invoice.paid_at ?
                                        <Col xs={12} className='text-center'>
                                            <div className='flex-column-start'>
                                                {payByStripe && newViewEnabled && stripe_balance > 0 &&
                                                    <ColorCheckbox value={this.state.payWithBalance}
                                                                   label='Use balance credits'
                                                                   className='vertical-align'
                                                                   onChange={e => {
                                                                       const {partialAmount, payPartial} = this.state;

                                                                       let stripeCharge = invoice.proposal_services.filter(ps => ps.service_name === 'Convenience fee')[0];
                                                                       let partialValue = undefined;

                                                                       if ((+partialAmount + +stripeCharge.price) > stripe_balance) {
                                                                           const newMaxValue = this.calculateMaxBalanceAmount();
                                                                           partialValue = newMaxValue.partialValue;
                                                                           stripeCharge.price = newMaxValue.price;
                                                                       } else if (payPartial && +partialAmount > 0) {
                                                                           let partialCustomerFee = invoice.customer_fee && (invoice.customer_fee.includes('%') ? partialAmount * parseFloat(invoice.customer_fee) / 100 : invoice.customer_fee.includes('$') ? +invoice.customer_fee.substr(1) : null);
                                                                           let partialClientFee = invoice.client_fee && (invoice.client_fee.includes('%') ? partialAmount * parseFloat(invoice.client_fee) / 100 : invoice.client_fee.includes('$') ? +invoice.client_fee.substr(1) : null);
                                                                           let partialTotal = partialAmount && parseFloat(partialAmount) + partialCustomerFee + partialClientFee;
                                                                           stripeCharge.price = partialClientFee + partialCustomerFee + this.calculateWithStripeFee(partialTotal).fee;
                                                                       } else if (payPartial && +partialAmount === 0) {
                                                                           stripeCharge.price = 0;
                                                                       } else {
                                                                           stripeCharge.price = customerFee + clientFee + this.calculateWithStripeFee(balanceDue + customerFee + clientFee).fee;
                                                                       }

                                                                       this.setState({
                                                                           feeStripe: stripeCharge.price,
                                                                           partialAmount: partialValue || partialAmount,
                                                                           payWithBalance: !this.state.payWithBalance,
                                                                       });
                                                                   }}
                                                    />}
                                                {payByStripe && newViewEnabled && this.state.payWithBalance &&
                                                    <p className='ml-30 mb2'>Your balance: <b><Dollars
                                                        amount={stripe_balance}/></b></p>}
                                                {payByStripe && newViewEnabled && this.state.payWithBalance && !this.state.payPartial && stripe_balance >= 0.5 && stripe_balance < amount &&
                                                    <p className='error-message ml-30'>Your balance is too low to pay
                                                        the
                                                        full price. You can pay partially.</p>}
                                                {payByStripe && newViewEnabled && this.state.payWithBalance && stripe_balance < 0.5 &&
                                                    <p className='error-message ml-30'>Your balance is too low. Please
                                                        top
                                                        up your balance or pay by credit card.</p>}
                                            </div>
                                            {payByStripe && <div className='stripe-options'>
                                                {(payByStripe) &&
                                                    <div className='options-container'>
                                                        <div className='d-flex justify-content-between card-options'>
                                                            {this.state.default_payment_method &&
                                                                <ColorCheckbox value={this.state.newCard}
                                                                               label='Use a different card'
                                                                               className='vertical-align'
                                                                               onChange={e => {
                                                                                   this.setState({
                                                                                       newCard: !this.state.newCard,
                                                                                   });
                                                                               }}/>}
                                                            {(!this.state.default_payment_method || this.state.newCard) &&
                                                                <ColorCheckbox value={this.state.saveCard}
                                                                               label='Save card for later use'
                                                                               className='vertical-align save-card'
                                                                               onChange={e => {
                                                                                   this.setState({
                                                                                       saveCard: !this.state.saveCard,
                                                                                   });
                                                                               }}/>}
                                                        </div>
                                                        <ColorCheckbox value={this.state.payPartial}
                                                                       label='Pay partial'
                                                                       className='vertical-align'
                                                                       onChange={e => {
                                                                           let stripeCharge = {};
                                                                           stripeCharge.service_name = 'Convenience fee';
                                                                           if (!e) {
                                                                               stripeCharge.price = customerFee + clientFee + this.calculateWithStripeFee(balanceDue + customerFee + clientFee).fee;
                                                                           }
                                                                           this.setState({
                                                                               payPartial: !this.state.payPartial,
                                                                               feeStripe: stripeCharge?.price || 0,
                                                                               partialAmount: 0,
                                                                           });
                                                                       }}
                                                        />
                                                    </div>}
                                                {this.state.payPartial && <InputGroup className='ml-6'>
                                                    <InputGroupAddon>$</InputGroupAddon>
                                                    <FormControl
                                                        name='partialAmount'
                                                        type='text'
                                                        onChange={(e) => {
                                                            let value = parseFloat(e.target.value.toString()
                                                                .replace(/[^0-9.]/g, '')
                                                                .replace(',', ''));
                                                            let stripeCharge = invoice.proposal_services.filter(ps => ps.service_name === 'Convenience fee')[0];
                                                            let partialValue;
                                                            if (this.state.payWithBalance) {
                                                                partialValue = value > stripe_balance ? stripe_balance : value;
                                                            } else {
                                                                partialValue = value > invoice.invoice_total - invoice.paid_amount ? invoice.invoice_total - invoice.paid_amount : value;
                                                            }

                                                            if (partialValue && partialValue > 0) {
                                                                let partialCustomerFee = invoice.customer_fee && (invoice.customer_fee.includes('%') ? partialValue * parseFloat(invoice.customer_fee) / 100 : invoice.customer_fee.includes('$') ? +invoice.customer_fee.substr(1) : null);
                                                                let partialClientFee = invoice.client_fee && (invoice.client_fee.includes('%') ? partialValue * parseFloat(invoice.client_fee) / 100 : invoice.client_fee.includes('$') ? +invoice.client_fee.substr(1) : null);
                                                                let partialTotal = partialValue && parseFloat(partialValue) + partialCustomerFee + partialClientFee;
                                                                stripeCharge.price = partialClientFee + partialCustomerFee + this.calculateWithStripeFee(partialTotal).fee;
                                                            } else {
                                                                stripeCharge.price = 0;
                                                            }

                                                            if (this.state.payWithBalance) {
                                                                if ((+partialValue + stripeCharge.price) > stripe_balance) {
                                                                    const newMaxValue = this.calculateMaxBalanceAmount();
                                                                    partialValue = newMaxValue.partialValue;
                                                                    stripeCharge.price = newMaxValue.price;
                                                                }
                                                            }

                                                            const match_value = partialValue ? partialValue.toString().match(/^(\d+\.\d{0,2})/) : partialValue;
                                                            this.setState({
                                                                feeStripe: stripeCharge.price,
                                                                partialAmount: (match_value ? match_value[1] : partialValue).toString(),
                                                                invoice: invoice,
                                                            }, () => this.calculateAmount(this.state.partialAmount, total - invoice.paid_amount));
                                                        }}
                                                        onBlur={(e) => this.dollarFormatPartialAmount(e,
                                                            () => {
                                                                this.setState({
                                                                    partialAmount: parseFloat(this.state.partialAmount?.toString()
                                                                        .replace(/[^0-9.]/g, '')
                                                                        .replace(',', '')),
                                                                });
                                                            })}
                                                        value={this.state.partialAmount?.toLocaleString('en-US', {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2,
                                                        })}
                                                    />
                                                </InputGroup>}
                                            </div>}
                                            {(!this.state.newCard && this.state.default_payment_method && payByStripe) &&
                                                <>
                                                    <h5>Pay with card: </h5>
                                                    <h4>**** **** **** {this.state.last4}</h4>
                                                </>
                                            }
                                            {(invoice.paid || payByStripe) &&
                                                <PaymentElement apiKey={stripeCredentials.stripe_publishable_key}
                                                                handleResult={this.handleResult} token={invoice.token}
                                                                customerName={invoice.customer_full_name}
                                                                error={paymentResult.error ? paymentResult.error : ''}
                                                                isPaid={balanceDue === 0}
                                                                payWithStripe={payByStripe}
                                                                amount={amount}
                                                                defaultPaymentMethod={this.state.newCard && this.state.default_payment_method ? null : this.state.default_payment_method}
                                                                payWithBalance={this.state.payWithBalance}
                                                                disabledPayButton={stripe_balance && stripe_balance < amount && !this.state.payPartial && this.state.payWithBalance}
                                                />}
                                        </Col>
                                        :
                                        <div className='text-success lead font-weight-bold flex-column-center mt-5'
                                             role='alert'>
                                            <span>This invoice has been paid</span>
                                            <h2 className='glyphicon glyphicon-ok-sign'/>
                                        </div>
                                    }
                                    {paymentResult.error ?
                                        <div className='error text-danger text-center mb-10' role='alert'>
                                            <strong>{paymentResult.error}</strong>
                                        </div> : ''}
                                </>}

                                {!invoice.paid_at && invoiceList && <Col xs={12} className='text-center'>
                                    <h4 className='text-primary'>
                                        <ColorCheckbox value={payByManualPayment}
                                                       label={`Manual payment`}
                                                       className='vertical-align'
                                                       onChange={e => {
                                                           this.onPayByManualPaymentCheckboxSelect(e);
                                                       }}
                                        />
                                    </h4>
                                </Col>}


                                {payByManualPayment && !invoice.paid_at &&
                                    <Col xs={12} className='bottom5 vertical-align'>
                                        <ColorCheckbox value={this.state.payPartial}
                                                       label='Pay partial'
                                                       className='vertical-align'
                                                       onChange={e => {
                                                           this.setState({
                                                               payPartial: !this.state.payPartial,
                                                               partialAmount: null,
                                                           });
                                                       }}
                                        />
                                        {this.state.payPartial && <FormGroup className='vertical-align ml-4 bottom0'>
                                            <InputGroup>
                                                <InputGroupAddon>
                                                    $
                                                </InputGroupAddon>
                                                <FormControl
                                                    name='partialAmount'
                                                    type='text'
                                                    onChange={(e) => {
                                                        let value = parseFloat(e.target.value.toString()
                                                            .replace(/[^0-9.]/g, '')
                                                            .replace(',', ''));
                                                        let partialValue = value > invoice.invoice_total - invoice.paid_amount ? invoice.invoice_total - invoice.paid_amount : value;
                                                        this.setState({
                                                            partialAmount: partialValue.toString(),
                                                        });
                                                    }}
                                                    onBlur={(e) => this.dollarFormatPartialAmount(e,
                                                        () => {
                                                            this.setState({
                                                                partialAmount: parseFloat(this.state.partialAmount?.toString()
                                                                    .replace(/[^0-9.]/g, '')
                                                                    .replace(',', '')),
                                                            });
                                                        })}
                                                    value={this.state.partialAmount?.toLocaleString('en-US', {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    })}
                                                />
                                            </InputGroup>
                                        </FormGroup>}
                                    </Col>
                                }

                                {invoiceList && payByManualPayment && !invoice.paid_at ?
                                    <Col xs={12} className='padding4'>
                                        <Col xs={4}>
                                            <Creatable
                                                isClearable
                                                options={manualPaymentTypes}
                                                value={creatableSelect(manualPaymentTypes, manualPaymentType)}
                                                onChange={e => {
                                                    if (e?.label && !manualPaymentTypes.find(o => o.label === e.label)) {
                                                        this.addManualPaymentType(e.label, (res) => {
                                                            this.setState({manualPaymentType: res.id}, () => {
                                                                this.props.actions.getManualPaymentTypes((res) => {
                                                                    let manualPaymentTypes = res.map((pt) => {
                                                                        pt.label = pt.name;
                                                                        pt.value = pt.id;
                                                                        return pt;
                                                                    });
                                                                    this.setState({
                                                                        manualPaymentTypes,
                                                                    });
                                                                });
                                                            });
                                                        });
                                                    } else {
                                                        this.setState({manualPaymentType: e?.value});
                                                    }
                                                }}/>
                                        </Col>
                                        <Col xs={3}>
                                            <FormControl placeholder='Reference no.'
                                                         value={manualPaymentReferenceNo}
                                                         onChange={(e) => this.setState({manualPaymentReferenceNo: e.target.value})}>
                                            </FormControl>
                                        </Col>
                                        <Col xs={5}>
                                            <Button
                                                disabled={this.state.payPartial && !this.state.partialAmount}
                                                onClick={this.doManualPayment}>
                                                Pay
                                                ${((this.state.payPartial && this.state.partialAmount) || !this.state.payPartial) ?
                                                (parseFloat(this.state.payPartial ? this.state.partialAmount : balanceDue)?.toLocaleString('en-US', {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                })) : ''}
                                            </Button>
                                        </Col>
                                    </Col>
                                    : null}

                                {invoice.payments && invoice.payments.length > 0 && !isMobile ?
                                    <Col xs={12}>
                                        <h4 className='text-center'><strong>Payments</strong></h4>
                                        <BootstrapTable
                                            ref='table'
                                            data={invoice.payments}
                                            striped={true}
                                            bordered={false}
                                            hover={true}
                                            remote
                                            fetchInfo={{dataTotalSize: invoice.payments.length}}
                                            className='wrapped'
                                        >
                                            <TableHeaderColumn
                                                dataField='id'
                                                hidden
                                                width={'5%'}
                                                isKey
                                            />
                                            <TableHeaderColumn
                                                dataField='created_at'
                                                dataFormat={dateFormatter}
                                                width={'20%'}
                                            >
                                                Created At
                                            </TableHeaderColumn>
                                            <TableHeaderColumn
                                                dataField='amount'
                                                dataFormat={dollarsFormatter}
                                                width={'15%'}
                                            >
                                                Amount
                                            </TableHeaderColumn>
                                            <TableHeaderColumn
                                                dataField='fee_amount'
                                                dataFormat={dollarsFormatter}
                                                width={'15%'}
                                            >
                                                Fee
                                            </TableHeaderColumn>
                                            <TableHeaderColumn
                                                dataField='total'
                                                dataFormat={dollarsFormatter}
                                                width={'15%'}
                                            >
                                                Total
                                            </TableHeaderColumn>
                                            <TableHeaderColumn
                                                dataField='payment_type_name'
                                                width={'15%'}
                                                dataFormat={this.paymentTypeFormatter}
                                            >
                                                Payment Type
                                            </TableHeaderColumn>
                                            <TableHeaderColumn
                                                dataFormat={this.buttonFormatter}
                                            >
                                                Request refund
                                            </TableHeaderColumn>
                                        </BootstrapTable>
                                    </Col>
                                    : invoice.payments && invoice.payments.length > 0 && isMobile ?
                                        <Col xs={12}>
                                            {this.renderMobileTable(invoice)}
                                        </Col>
                                        : null
                                }
                            </Row>
                        }
                        <RefundReasonModal show={this.state.showRefundModal} onHide={this.closeRefundModal}
                                           onSave={this.handleRequestRefund}/>
                    </Modal.Body>}
                <Modal.Footer>
                    <Row>
                        <Col md={12} className='text-right'>
                            <Button
                                bsSize='small'
                                onClick={() => this.props.onHide()}
                            >Close
                            </Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        client: state.client.customerInfo,
        site: state.customerView.site,
    };
};

const mapDispatchToProps = dispatch => {
    return {actions: bindActionCreators(Actions, dispatch)};
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentModal);

