import React, {Component} from 'react';
import PropTypes from "prop-types"
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table"

import {
    dateFormatter,
    dollarsFormatter,
    serviceNameFormatter,
    workOrderNoFormatter
} from "../../../components/BootStrapTableCellFormatters";

class ProposalServicesList extends Component {
    constructor(props) {
        super(props)
        this._onRowSelect = this._onRowSelect.bind(this)
        this._onSelectAll = this._onSelectAll.bind(this)
    }

    state = {
        selectedServiceType: 'open',
        selectedService: [],
    }

    _onSelectAll = (isSelected, rows) => {
        let selected = []

        if (isSelected) {
            selected = rows.map(ps => ps.id)
        }
        this.props.selectServices(selected)
        // this.setState({ selected })
    }

    _onRowSelect = (row, isSelected, e) => {
        let selected = [...this.props.selectedPs]
        const psNumber = parseInt(row.id, 10)

        if (isSelected) {
            selected = selected.concat(psNumber) // prob concatted a dup #
            const uniqSelecteds = new Set(selected) //uniqu them
            selected = [...uniqSelecteds] //transform uniqs back into an array
        } else {
            selected = selected.filter(s => s !== psNumber)
        }
        this.props.selectServices(selected)
    }

    render() {
        const {
            selectedProposalServices,
            proposalServicesInfo,
            showFilterRows,
        } = this.props

        return (
            <BootstrapTable
                data={proposalServicesInfo}
                striped={true}
                bordered={false}
                hover={true}
                search={false}
                selectRow={{
                    mode: "checkbox",
                    clickToSelect: false,
                    onSelect: this._onRowSelect,
                    onSelectAll: this._onSelectAll,
                    selected: selectedProposalServices
                }}
            >
                <TableHeaderColumn hidden isKey dataField="id">
                    Proposal Service Id
                </TableHeaderColumn>
                <TableHeaderColumn hidden dataField="serviceNo">
                    Service NO
                </TableHeaderColumn>
                <TableHeaderColumn
                    filter={showFilterRows ? {type: "TextFilter", delay: 500} : null}
                    dataSort={true}
                    dataFormat={serviceNameFormatter}
                    dataField="plants"
                >
                    Service
                </TableHeaderColumn>
                <TableHeaderColumn
                    filter={showFilterRows ? {type: "TextFilter", delay: 500} : null}
                    dataSort={true}
                    columnClassName="menu-show-fixer"
                    dataFormat={workOrderNoFormatter}
                    dataField="workOrderNo"
                >
                    WO #
                </TableHeaderColumn>
                <TableHeaderColumn
                    filter={showFilterRows ? {type: "DateFilter"} : null}
                    dataSort={true}
                    filterFormatted={false}
                    dataFormat={dateFormatter}
                    dataField="completed_at"
                >
                    Completed Date
                </TableHeaderColumn>
                <TableHeaderColumn
                    filter={showFilterRows ? {type: "TextFilter", delay: 500} : null}
                    dataSort={true}
                    dataFormat={dollarsFormatter}
                    filterFormatted={false}
                    dataField="serviceCost"
                >
                    Cost
                </TableHeaderColumn>
            </BootstrapTable>
        );
    }
}

ProposalServicesList.propTypes = {
    proposalServicesInfo: PropTypes.array.isRequired,
    selectedProposalServices: PropTypes.array.isRequired,
};

export default ProposalServicesList
