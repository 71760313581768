import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from "prop-types"
import {authenticateCustomer, requestCredentials, updateEmail, updatePassword} from '../containers/LoginPage/actions'
import {bindActionCreators} from "redux";
import {Button, Col, ControlLabel, FormControl, FormGroup, Glyphicon, Grid, Row, Well} from "react-bootstrap";
import ReCAPTCHA from 'react-google-recaptcha';
import './SecuredCustomer.scss'
import config from '../config/environment'
import Helmet from "react-helmet";
import PageTitle from "../components/PageTitle";
import Logo from "../containers/HomePage/HomePageLogo.png";

const icon = ['star']

const actions = {
    authenticateCustomer,
    updateEmail,
    updatePassword,
    requestCredentials,
};

const captchaEnabled = config.captchaEnabled === 'true';

class SecuredCustomer extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            message: ''
        };
    }

    render() {
        return (
            this.props.isCustomerAuthenticated ? this.props.children :
                <Grid fluid>
                    <Helmet title="Login"/>
                    <PageTitle pageLogo={Logo} pageName="TreeHub" pageDetail="login"/>
                    <Col xs={12} mdOffset={3} md={6}>
                        <div className='login-center'><Well>
                            <h4>Please Login</h4>
                            <p>&nbsp;</p>
                            <form onSubmit={this.onAuthenticate}>
                                <FormGroup>
                                    <ControlLabel>Login</ControlLabel>
                                    <FormControl
                                        type="text"
                                        className='font16'
                                        placeholder="Login"
                                        onChange={this.props.actions.updateEmail}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <ControlLabel>Password</ControlLabel>
                                    <FormControl
                                        type="password"
                                        className='font16'
                                        placeholder="Password"
                                        onChange={this.props.actions.updatePassword}
                                    />
                                </FormGroup>
                                <p>&nbsp;</p>
                                <Row>
                                    <Col xs={4}>
                                        <Button bsStyle="info"
                                                onClick={() => this.props.actions.authenticateCustomer(this.props.token)}>
                                            <Glyphicon glyph={icon[icon.length - 1]}/> Login
                                        </Button>
                                    </Col>
                                    <Col xs={8}>
                                        <div className='d-flex justify-flex-end flex-column-end'>
                                            {(!this.state.message && !this.state.showCaptcha) &&
                                                <p className='pointer text-right text-danger'
                                                   onClick={() => captchaEnabled ? this.setState({
                                                       showCaptcha: true,
                                                       is_customer: true
                                                   }) : this.props.actions.requestCredentials(this.props.token, null, res => {
                                                       this.setState({showCaptcha: false, message: res.message})
                                                   })}>
                                                    Request credentials
                                                </p>
                                            }
                                            {captchaEnabled && this.state.showCaptcha && <ReCAPTCHA
                                                size='normal'
                                                sitekey={config.captchaSiteKey}
                                                onChange={captcha => {
                                                    this.props.actions.requestCredentials(this.props.token, captcha, res => {
                                                        this.setState({showCaptcha: false, message: res.message})
                                                    })
                                                }}
                                            />}
                                            {this.state.message &&
                                                <p className='text-danger mt10 align-right'>{this.state.message}</p>}
                                        </div>
                                    </Col>
                                </Row>
                            </form>
                        </Well></div>
                    </Col>
                </Grid>

        );
    }
}

SecuredCustomer.propTypes = {
    children: PropTypes.object.isRequired,
    isCustomerAuthenticated: PropTypes.bool
};

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)

    }
}

export default connect(null, mapDispatchToProps)(SecuredCustomer);