import React from 'react';
import PropTypes from "prop-types"
import ResourceComponent from "../../components/ResourceComponent";
import {Creatable} from 'react-select';
import Dropzone from "react-dropzone";
import {Button} from "react-bootstrap";
import Attachment from "../../components/image/Attachment";
import createApiService from "../../common/clientActionsBuilder";
import {bindActionCreators} from "redux";
import {select, stringifySelect} from "../../common/commonHandlers";
import {connect} from "react-redux";

const api = createApiService('hazards', 'hazard', 'Hazard');

class SiteHazardForm extends ResourceComponent {

    constructor(props, context) {
        super(props, context);
        this.state = {
            resource: props.resource
        };
    }


    confirmDelete = resource => {
        const choice = window.confirm('Are you sure?');

        if (choice) {
            this.props.removeImages(this.props.newImages);
            this.props.setNewImages([]);
            this.props.delete(resource);
        }
    };
    doSave = () => {
        const {resource} = this.state;
        this.props.save(resource);
    };

    setNewImages = (images) => {
        this.props.setNewImages(images);
    }


    render() {
        const {hazards} = this.props;
        const {resource} = this.state;
        const hazardsOptions = stringifySelect(hazards);
        return (
            <tr>
                <td>
                    <Creatable options={hazardsOptions}
                               value={select(hazardsOptions, resource.hazard_id && resource.hazard_id.toString())}
                               onChange={e => {
                                   this.selectResourceAttr('hazard_id')(e);

                                   if (isNaN(e.value)) {
                                       this.props.actions.save({name: e.label}, result => {
                                           e.value = result.id;
                                       });
                                   }
                               }}/>
                </td>
                <td>
                    {resource.images.map(i => <Attachment key={i.id} image={i} deleteImage={imageId => {
                        resource.images = resource.images.filter(i => i.id !== imageId);
                        this.setState({resource});

                    }}/>)}
                    {resource.site_id &&
                        <Dropzone
                            onDrop={files => this.props.uploadAttachment(resource.site_id, files, data => {
                                resource.images.push(data);
                                this.setNewImages([...this.props.newImages, data.id])
                                this.setState({resource});
                            })}
                            className="dropzone pointer small">
                            <p>Drop a file here or click to select a file to upload.</p>
                        </Dropzone>
                    }
                </td>
                <td>
                    <Button
                        bsSize="xsmall"
                        bsStyle="success"
                        className="full-width top5"
                        onClick={() => {
                            this.props.cancel()
                            this.doSave()
                        }}
                    >
                        Save
                    </Button>
                    <Button
                        bsSize="xsmall"
                        bsStyle="warning"
                        className="full-width top5"
                        onClick={() => {
                            const filteredImages = this.state.resource.images.filter(i => !this.props.newImages.includes(i.id));
                            this.props.removeImages(this.props.newImages);
                            this.setState({
                                resource: {
                                    ...this.state.resource,
                                    images: filteredImages
                                }
                            }, (r) => {
                                this.props.reloadData(r)
                                this.props.cancel()
                            });
                            this.props.setNewImages([]);


                        }}
                    >
                        Cancel
                    </Button>
                    <p>&nbsp;</p>
                    {resource.id &&
                        <Button
                            bsSize="xsmall"
                            bsStyle="warning"
                            className="full-width"
                            onClick={() => this.confirmDelete(resource)}
                        >
                            Delete
                        </Button>
                    }
                </td>
            </tr>
        );
    }
}

SiteHazardForm.propTypes = {
    resource: PropTypes.object.isRequired,
    newImages: PropTypes.array.isRequired,
    setNewImages: PropTypes.func.isRequired,
    removeImages: PropTypes.func.isRequired,
    save: PropTypes.func.isRequired,
    cancel: PropTypes.func.isRequired,
    delete: PropTypes.func.isRequired,
    uploadAttachment: PropTypes.func.isRequired,
    hazards: PropTypes.array
};

SiteHazardForm.defaultProps = {
    //myProp: <defaultValue>
};


function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(api, dispatch)
    };
}

export default connect(undefined, mapDispatchToProps)(SiteHazardForm);