export const [
    FETCH_INVOICES,
    UPDATE_INVOICED_START,
    UPDATE_INVOICED_END,
    UPDATE_STATUS,
    UPDATE_INVOICES,
    NEW_INVOICE,
] = [
    "FETCH_INVOICES",
    "UPDATE_INVOICED_START",
    "UPDATE_INVOICED_END",
    "UPDATE_STATUS",
    "UPDATE_INVOICES",
    "NEW_INVOICE",
]
