import React from 'react';
import {bindActionCreators} from 'redux';
import {
    Button,
    ButtonToolbar,
    Checkbox,
    Col,
    DropdownButton,
    Grid,
    MenuItem,
    Nav,
    NavDropdown,
    OverlayTrigger,
    Row,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip
} from "react-bootstrap";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import * as api from "./InvoicesApi";
import ResourceComponent from "../../components/ResourceComponent";
import {showEmailDialog} from "../../components/email/actions"
import EmailDialog from "../../components/email/EmailDialog";
import {Link} from "react-router";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    defaultDateFormat,
    defaultDateTimeFormat,
    defaultDateTimeFormatComma,
    defaultTimeFormat,
    saveNewCsvDownload,
    select,
} from "../../common/commonHandlers";
import Datetime from "react-datetime";
import {debounce} from "throttle-debounce";
import "./Invoices.scss";
import {dateFormatter, dollarsFormatter} from "../../components/BootStrapTableCellFormatters";
import {LinkContainer} from "react-router-bootstrap";
import moment from "moment";
import {connect} from 'react-redux';
import ExcelJS from "exceljs";
import {saveAs} from "file-saver";
import {faCog} from "@fortawesome/free-solid-svg-icons";
import _ from 'lodash'
import PaymentModal from "../CustomerViewInvoice/Payment/PaymentModal";
import {fetchInvoice} from "../CustomerViewInvoice/actions";
import {exportToXLSX} from "./InvoicesWorksheetExport";
import InvoicesTable from "./InvoicesTable";
import InvoicesFilterModal from "./InvoicesFilterModal";

const actions = {...api, showEmailDialog, saveNewCsvDownload, fetchInvoice};
const isMobile = window.screen.width <= 1024;
const smMobile = window.screen.width <= 450;

class Invoices extends ResourceComponent {
    state = {
        total: 0,
        selectedRows: [],
        invoices: [],
        InvoiceFilterModalShow: false,
        loaded: false,
        resource: {
            sort: 'desc',
            sort_by: 'aging',
            page: 1,
            per_page: 50,
            paid: false,
            from: null,
            to: null,
            date_filter: 'invoiced_at',
            label: 'all',
            inactive: false
        },
        employees: [],
        filterVisible: true,
        dateFilterOptions: ['Today', '1 - 30', '31 - 60', '61 - 90', '91+', 'all'],
        columnsSettings: {
            "Customer": true,
            "Site": true,
            "Arborist": true,
            "Title": true,
            "Invoice notes": true,
            "Proposal #": true,
            "WO #": true,
            "Invoice #": true,
            "Status Dates": true,
            "Sent": true,
            "Aging": true,
            "Balance": true,
            "Cost": true,
            "Pay": true
        },
        columnsSettingsSelectedAll: true,
        numericFilterChanged: false,
        showPaymentPopUp: false
    };

    tableRef = React.createRef()

    constructor(props, context) {
        super(props, context);
        this.delayedSearch = debounce(500, this.search);
        this.customerSearch = {
            searchOptions: () => [], search: () => {
            }
        };
        this.siteSearch = {
            searchOptions: () => [], search: () => {
            }
        };
        this.arboristSearch = {
            searchOptions: () => [], search: () => {
            }
        };
        this.proposalTitleSearch = {
            searchOptions: () => [], search: () => {
            }
        };
        this.proposalNoSearch = {
            searchOptions: () => [], search: () => {
            }
        };
        this.workOrderSearch = {
            searchOptions: () => [], search: () => {
            }
        };
        this.invoiceSearch = {
            searchOptions: () => [], search: () => {
            }
        };
    }

    search = () => {
        const {resource} = this.state;
        this.setState({loaded: false}, () => this.props.actions.loadInvoices(resource, results => {
            this.setState({invoices: results.content, total: results.total, loaded: true});
        }))
    }

    onSelectAll = (isSelected, rows) => {
        let selectedRows = [];

        if (isSelected) {
            selectedRows = rows.map(p => p.id)
        }
        this.setState({selectedRows})
    };

    onRowSelect = (row, isSelected) => {
        let selectedRows = [...this.state.selectedRows];
        const id = parseInt(row.id, 10);
        if (isSelected) {
            selectedRows = selectedRows.concat(id);
            const uniqSelected = new Set(selectedRows);
            selectedRows = [...uniqSelected];
        } else {
            selectedRows = selectedRows.filter(s => s !== id);
        }
        this.setState({selectedRows});
    };

    updatePaidDate() {
        const {selectedRows, paid_date} = this.state;
        this.props.actions.updatePaidDate(selectedRows, paid_date, () => {
            this.search();
        })
    }

    handleSelectDateFilterType = e => {
        let {resource} = this.state;
        resource.date_filter = e;
        this.setState({resource}, this.search);
    };
    renderRadioButton = (param) => {
        const {resource} = this.state;
        return <div className="form-check abc-radio" onClick={() => this.handleSelectPayment(param.value)}>
            <input className="form-check-input"
                   type="radio"
                   checked={resource.paid === param.value}
            />
            <label className="form-check-label">
                {param.label}
            </label>
        </div>
    };

    customerFormatter = (cell, row) => (
        <NavDropdown
            title={row.customer_name}
        >
            <LinkContainer to={`/customer/info/${row.customer_id}`}>
                <MenuItem bsSize="small" eventKey={2.6}>Info</MenuItem>
            </LinkContainer>
            <LinkContainer to={`/customer/proposals/${row.customer_id}`}>
                <MenuItem bsSize="small" eventKey={2.6}>Proposals</MenuItem>
            </LinkContainer>
            <LinkContainer to={`/customer/sites/${row.customer_id}`}>
                <MenuItem bsSize="small" eventKey={2.6}>Sites</MenuItem>
            </LinkContainer>
            <LinkContainer to={`/customer/work_orders/${row.customer_id}`}>
                <MenuItem bsSize="small" eventKey={2.6}>Work Orders</MenuItem>
            </LinkContainer>
            <LinkContainer to={`/customer/invoices/${row.customer_id}`}>
                <MenuItem bsSize="small" eventKey={2.6}>Invoices</MenuItem>
            </LinkContainer>
            <LinkContainer to={`/customer/work_history/${row.customer_id}`}>
                <MenuItem bsSize="small" eventKey={2.6}>Work History</MenuItem>
            </LinkContainer>
        </NavDropdown>
    )

    invoiceFormatter = (cell, row) => {
        return <Nav>
            <NavDropdown
                title={row.number}
                className="menu-show-fixer no-caret-in-dropdown nmargin"
            >
                <li className="pointer">
                    <Link to={`/proposals/${row.proposal_id}/invoices`}>Invoice</Link>
                </li>
                <li className="pointer">
                    <Link onClick={() => this.email(row)}>Email</Link>
                </li>
            </NavDropdown>
        </Nav>
    };

    invoiceNotesFormatter = (cell, row) => {
        let invoice_notes = ""
        let compact = false
        if (row.invoice_notes.length > 80) {
            invoice_notes = row.invoice_notes.substring(0, 80) + "..."
            compact = true
        }

        return <div>{compact ?
            <div>
                <OverlayTrigger
                    placement={'bottom'}
                    overlay={
                        <Tooltip id={`tooltip-edit-description`}>
                            {row.invoice_notes.split(';').map((invoice_note) => {
                                return <div>{invoice_note}</div>
                            })}
                        </Tooltip>
                    }
                >
                    <div className="word-break">
                        {invoice_notes.split(';').map((invoice_note) => {
                            return <div>{invoice_note}</div>
                        })}
                    </div>
                </OverlayTrigger>
            </div> :
            <div>
                {row.invoice_notes.split(';').map((invoice_note) => {
                    return <div className="word-break">{invoice_note}</div>
                })}
            </div>
        }</div>
    }

    componentDidMount() {
        this.customerSearch = this.buildSearchComponent('customer_id', this.props.actions.searchForCustomer);
        this.siteSearch = this.buildSearchComponentWithParams('site_id', this.props.actions.searchForSite);
        this.arboristSearch = this.buildSearchComponentWithParams('arborist_id', this.props.actions.searchForArborist);
        this.proposalTitleSearch = this.buildSearchComponentWithParams('proposal_id', this.props.actions.searchForProposalTitle);
        this.proposalNoSearch = this.buildSearchComponentWithParams('proposal_no', this.props.actions.searchForProposalNo);
        this.workOrderSearch = this.buildSearchComponentWithParams('work_order_id', this.props.actions.searchForWorkOrder);
        this.invoiceSearch = this.buildSearchComponentWithParams('invoice_id', this.props.actions.searchForInvoice);
        this.props.actions.load(result => this.setState(result, () => {
            if (result.user?.invoices_list_settings?.columns) {
                this.setState({columnsSettings: result.user.invoices_list_settings.columns}, () => {
                    this.setAllColumnsSelect()
                })
            } else {
                this.setAllColumnsSelect()
            }
            this.search()
        }));
    }

    email = row => {
        const {email} = this.props;

        let recipientEmail;
        const {contact_email, contact_email2, secondContactEmail, secondContactEmail2} = row
        const primarySiteContactEmails = (contact_email && contact_email2) ? `${contact_email}, ${contact_email2}` : contact_email
        const secondarySiteContactEmails = (secondContactEmail && secondContactEmail2) ? `${secondContactEmail}, ${secondContactEmail2}` : secondContactEmail

        recipientEmail = `${primarySiteContactEmails || ""}`
        if (secondarySiteContactEmails) recipientEmail += `, ${secondarySiteContactEmails}`
        let billingEmail = row.email_address
        this.props.actions.showEmailDialog(
            {
                emailType: row.paid_at ? 'invoice paid' : 'invoice',
                referenceId: row.id,
                recipient: billingEmail,
                cc_recipients: row.site_email_addresses,
                defaultEmail: email,
                dataPdf: row,
                client: this.props.client,
                sendInBackground: true
            });
    };

    proposalFormatter = (cell, row) => (
        <Nav>
            <NavDropdown
                eventKey={2}
                title={row.proposal_no}
                id={`proposal-menu`}
                className="menu-show-fixer proposal-menu-padding-reset"
            >
                <LinkContainer to={`/mapview/${row.proposal_id}`}>
                    <MenuItem bsSize="small">Edit Proposal</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/proposal_service_status/${row.proposal_id}`}>
                    <MenuItem bsSize="small">Proposal Statuses</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/print_proposal/${row.proposal_id}`}>
                    <MenuItem bsSize="small">Print Proposal</MenuItem>
                </LinkContainer>
            </NavDropdown>
        </Nav>
    );

    woFormatter = (cell, row) => {
        let wo_number;
        if (row.wo_numbers && row.wo_numbers.includes(",")) {
            wo_number = row.wo_numbers.split(",").join("\n")
        } else {
            wo_number = row.wo_numbers
        }
        return (
            <Nav>
                <NavDropdown
                    title={wo_number}
                    className="menu-show-fixer no-caret-in-dropdown nmargin max_width_70"
                >
                    <li className="pointer">
                        <Link to={`/work_orders/${row.proposal_id}`}>Work Order</Link>
                    </li>
                    <li className="pointer">
                        <Link to={`/complete_work_orders/${row.wo_numbers}`}>Complete Work Order</Link>
                    </li>
                    <li className="pointer">
                        <Link to={`/print_work_orders/${row.wo_numbers}`}>Print Work Order</Link>
                    </li>
                </NavDropdown>
            </Nav>
        )
    }

    siteFormatter = (cell, row) => (
        <div className="columnDirection">
            <span className="bold">{row.site_name}</span>
            <span>{row.site_full_address}</span>
        </div>
    );

    _statusDateFormatter = (cell, row) => {
        const currentDate = moment(moment(new Date()), defaultDateTimeFormatComma);
        const paidDate = moment(moment(row.paid_at), defaultDateTimeFormatComma);
        const dueDate = moment(moment(row.due_date), defaultDateTimeFormatComma);
        const tooltip = row.payments && row.payments.length >= 1 && row.payments.map((p, index) => (<div>
            <span
                key={index}>{moment(p.payment_date).format(defaultDateTimeFormatComma)}{' '}Payment #{p.id}{' '}Amount: {dollarsFormatter(p.amount)}
            </span>
        </div>));
        return (
            <div style={{display: 'grid'}}>
                <div>
                    <small style={{color: 'black'}}>
                        <span>{row.invoiced_at ?
                            `Invoiced: ${row && moment(row.invoiced_at).format(defaultDateTimeFormatComma)}`
                            : null}
                        </span>
                        {row.viewed && <span>{' '}(Viewed)</span>}
                    </small>
                </div>
                <div>
                    <small>
                        <span>{row.paid_at ?
                            (<div
                                className="text-success">{`Paid: ${row && moment(row.paid_at).format(defaultDateTimeFormatComma)} (${dueDate.diff(paidDate) > 0 ? `Early` : `Late`} ${Math.abs(Math.round((dueDate.diff(paidDate, 'minutes') / 1440)))})`}{row.payments && row.payments.length === 1 && `Payment #${row.payments[0].id}`}</div>)
                            : null}
                        </span>
                        <span>{!row.paid_at && dueDate.diff(currentDate) > 0 ?
                            (<div
                                className="text-warning">{`Open: ${row && moment(row.due_date).format(defaultDateTimeFormatComma)} (Due ${Math.abs(Math.round(dueDate.diff(currentDate, 'minutes') / 1440))})`}
                            </div>)
                            : null}
                        </span>
                        <span>{!row.paid_at && dueDate.diff(currentDate) < 0 ?
                            (<div
                                className="text-danger">{`Overdue: ${row && moment(row.due_date).format(defaultDateTimeFormatComma)} (Late ${Math.abs(Math.round(dueDate.diff(currentDate, 'minutes') / 1440))})`}
                            </div>)
                            : null}
                        </span>
                        {
                            row.last_email_send && <span
                                className={'text-blue font-weight-normal'}>Last send: {moment(row.last_email_send).format(defaultDateTimeFormatComma)}</span>
                        }
                    </small>
                </div>
                <div>
                    {!row.paid_at && row.payments && row.payments.length >= 1 &&
                        <OverlayTrigger placement="top"
                                        overlay={<Tooltip>{tooltip}</Tooltip>}>
                            <small>
                            <span
                                className={'text-purple'}>Payment:{' '}{moment(row.payments[0].created_at).format(defaultDateTimeFormatComma)}</span>
                            </small>
                        </OverlayTrigger>}
                </div>
            </div>
        )
    }


    handleSelectPayment(param) {
        let {resource} = this.state;
        resource.paid = param;
        this.setState({resource}, this.search);
    };

    proposalNoFormatterXLSX = (proposal_no, proposal_id) => {
        const primaryUrl = new URL(window.location.href).origin

        return {formula: `=HYPERLINK("${primaryUrl}/mapview/${proposal_id}","${proposal_no}")`};
    }

    woFormatterXLSX = (wo_numbers, proposal_id) => {
        const primaryUrl = new URL(window.location.href).origin

        let wo_number;
        if (wo_numbers && wo_numbers.includes(",")) {
            wo_number = wo_numbers.split(",").join("\n")
        } else {
            wo_number = wo_numbers
        }
        return {formula: `=HYPERLINK("${primaryUrl}/work_orders/${proposal_id}","${wo_number}")`};
    }

    invoiceNoFormatterXLSX = (invoice_no, proposal_id) => {
        const primaryUrl = new URL(window.location.href).origin

        return {formula: `=HYPERLINK("${primaryUrl}/proposals/${proposal_id}/invoices","${invoice_no}")`};
    }

    dateFormatterXLSX = (date) => {
        return moment(date).format(defaultDateTimeFormat);
    }

    siteFormatterXLSX = (site_name, site_full_address) => {
        site_full_address = site_full_address.split(/, (.*)/s)
        return {
            'richText': [
                {'font': {'bold': true, 'size': 8, 'name': 'Calibri'}, 'text': `${site_name}\n`},
                {'font': {'size': 8, 'name': 'Calibri'}, 'text': `${site_full_address[0]}\n${site_full_address[1]}`}
            ]
        }
    };

    autoColumnWidth = (column) => {
        const lengths = column.values.map(v => v.toString().length);
        const maxLength = Math.max(...lengths.filter(v => typeof v === 'number')) * 0.727; //by default it works for font 11, we use font 8 so we need to convert result
        return maxLength;
    }

    exportToXLSX = async () => {

        const data = _.cloneDeep(this.onExportToXLSX())
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Invoices")

        let rows = [];

        data.forEach((item) => {
            rows.push([item.customer_name,
                this.siteFormatterXLSX(item.site_name, item.site_full_address),
                item.arborist,
                item.proposal_title,
                item.invoice_notes,
                this.proposalNoFormatterXLSX(item.proposal_no, item.proposal_id),
                this.woFormatterXLSX(item.wo_numbers, item.proposal_id),
                this.invoiceNoFormatterXLSX(item.number, item.proposal_id),
                dateFormatter(item.invoiced_at), //format with moment
                item.invoice_sent_count,
                dateFormatter(item.due_date), //format with moment
                item.aging,
                parseFloat(item.balance),
                parseFloat(item.cost)
            ])
        })

        worksheet.addTable({
            name: 'Invoices',
            ref: 'A1',
            headerRow: true,
            style: {
                theme: 'TableStyleMedium9',
                showRowStripes: true,
            },
            columns: [
                {name: 'Customer', filterButton: true},
                {name: 'Site', filterButton: true},
                {name: 'Arborist', filterButton: true},
                {name: 'Title', filterButton: true},
                {name: 'Invoice notes', filterButton: false},
                {name: 'Proposal', filterButton: true},
                {name: 'Work Order', filterButton: true},
                {name: 'Invoice', filterButton: true},
                {name: 'Invoice Date', filterButton: true},
                {name: 'Sent', filterButton: true},
                {name: 'Due Date', filterButton: true},
                {name: 'Aging', filterButton: true,},
                {name: 'Balance', filterButton: true,},
                {name: 'Cost', filterButton: true},
            ],
            rows: rows,
        });

        worksheet.eachRow({includeEmpty: true}, (row, rowNumber) => {
            if (rowNumber > 1) {
                row.eachCell((cell, colNumber) => {
                    if ([3, 6, 8, 9, 10, 11, 12, 13, 14].includes(colNumber)) {
                        cell.style = {font: {name: 'Calibri', size: 8}}
                    }
                })
                row.getCell(1).style = {alignment: {wrapText: true}, font: {name: 'Calibri', size: 8}}
                row.getCell(2).style = {alignment: {wrapText: true}, font: {name: 'Calibri', size: 8}}
                row.getCell(4).style = {alignment: {wrapText: true}, font: {name: 'Calibri', size: 8}}
                row.getCell(5).style = {alignment: {wrapText: true, vertical: "top"}, font: {name: 'Calibri', size: 8}}
                row.getCell(7).style = {alignment: {wrapText: true}, font: {name: 'Calibri', size: 8}}
                row.getCell(13).numFmt = '[$$-409]#,##0.00;-[$$-409]#,##0.00'
                row.getCell(14).numFmt = '[$$-409]#,##0.00;-[$$-409]#,##0.00'
                row.height = 50
            }
        })

        worksheet.views = [
            {state: 'frozen', xSplit: 0, ySplit: 1, activeCell: 'A1'}
        ];

        worksheet.addRow(['', '', '', '', '', '', '', '', '', '', '',
            'Total',
            {formula: `SUM(M1:M${worksheet.rowCount})`, date1904: false},
            ''
        ])

        worksheet.getCell(`L${worksheet.rowCount}`).style = {font: {name: 'Calibri', size: 8}}
        worksheet.getCell(`M${worksheet.rowCount}`).style = {font: {name: 'Calibri', size: 8}}
        worksheet.getCell(`M${worksheet.rowCount}`).numFmt = '[$$-409]#,##0.00;-[$$-409]#,##0.00'

        worksheet.columns.forEach((column) => {
            let autoWidthColumns = [3, 9, 11, 12]
            let colNumber = column._number
            if (autoWidthColumns.includes(colNumber)) {
                column.width = this.autoColumnWidth(column) + 0.62;
            } else if (colNumber === 1 || colNumber === 4) {
                column.width = 20 + 0.62
            } else if (colNumber === 2) {
                column.width = 18 + 0.62
            } else if (colNumber === 5) {
                column.width = 50 + 0.62;
            } else if (colNumber === 13 || colNumber === 14) {
                column.width = 10 + 0.62;
            }
        })

        const excelBuffer = await workbook.xlsx.writeBuffer();

        const csvFileName = `invoices-${moment().format(defaultDateFormat)}.csv`
        if (this.tableRef?.current) {
            this.props.actions.saveNewCsvDownload(this.tableRef.current, csvFileName, this.state.resource, "invoices")
        }

        const dataBlob = new Blob([excelBuffer], {type: 'application/octet-stream'});
        saveAs(dataBlob, `invoices-${moment().format(defaultDateFormat)}.xlsx`);
    }

    createCustomToolBar = () => {
        const {resource, columnsSettings, selectedRows, dateFilterOptions, loaded} = this.state;
        const paymentOptions = [
            {value: null, label: 'All'},
            {value: true, label: 'Paid'},
            {value: false, label: 'Unpaid'}
        ];
        return (
            <Row id="invoices-toolbar" className={isMobile ? "no-margin" : "no-margin d-flex margin-inline-15"}>
                <div className={isMobile ? 'bottom15' : "InvoicePanelLR export-email-buttons"}>
                    {isMobile ? null : <Button bsStyle="success" disabled={!loaded || selectedRows.length === 0}
                                               onClick={this.doExportToXLSX}>Export to XLSX</Button>}
                    <Button bsStyle="warning"
                            className={isMobile ? null : "marginLeft10"}
                            onClick={() => this.setState({email: true})}
                            disabled={selectedRows.length === 0}>Email Selected</Button>
                </div>
                <div className={isMobile ? 'textLeft bottom10' : 'vcenter radio-buttons'}>
                    {paymentOptions.map(option => this.renderRadioButton(option))}
                </div>
                <div className={isMobile ? "bottom15" : "InvoicePanelLR label-buttons"}>
                    <ButtonToolbar>
                        <ToggleButtonGroup type="radio"
                                           name="options"
                                           value={resource.label}
                                           onChange={this.selectCheckboxAttr('label', this.search)}
                        >
                            {
                                dateFilterOptions.map((d, i) => {
                                    return <ToggleButton
                                        key={i}
                                        value={d}
                                        className={isMobile ? "padding10LR" : null}
                                    >
                                        {d}
                                    </ToggleButton>
                                })
                            }
                        </ToggleButtonGroup>
                    </ButtonToolbar>
                </div>
                <div className={isMobile ? "bottom15" : "textCenter due-date-paid-at-buttons"}>
                    <ToggleButtonGroup
                        type="radio"
                        name="options"
                        value={resource.date_filter}
                        onChange={this.handleSelectDateFilterType}
                    >
                        <ToggleButton value={'due_date'}>Due Date</ToggleButton>
                        <ToggleButton value={'paid_at'}>Paid at</ToggleButton>
                    </ToggleButtonGroup>
                </div>
                <div className={isMobile ? 'textLeft' : 'justify-flex-end update-button-and-input'}>
                    <Button className="update-paid-button" bsStyle="warning" onClick={() => this.updatePaidDate()}
                            disabled={selectedRows.length === 0}>Update paid date</Button>
                    <Datetime
                        dateFormat={defaultDateFormat}
                        timeFormat={defaultTimeFormat}
                        value={resource.paid_date}
                        className="marginLeft10"
                        inputProps={{placeholder: 'Paid date'}}
                        onChange={(e) => this.setState({paid_date: e})}
                    />
                </div>
            </Row>
        );
    }

    rowFormatter = (cell, row) => {
        return <div>
            <Row>
                <Col xs={6}>Customer</Col>
                <Col xs={6}>{this.customerFormatter(cell, row)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Site</Col>
                <Col xs={6}>{this.siteFormatter(cell, row)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Arborist</Col>
                <Col xs={6}>{row.arborist}</Col>
            </Row>
            <Row>
                <Col xs={6}>Title</Col>
                <Col xs={6}>{row.proposal_title}</Col>
            </Row>
            <Row>
                <Col xs={6}>Invoice notes</Col>
                <Col xs={6}>{this.invoiceNotesFormatter(cell, row)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Proposal #</Col>
                <Col xs={6}>{this.proposalFormatter(cell, row)}</Col>
            </Row>
            <Row>
                <Col xs={6}>WO #</Col>
                <Col xs={6} className='link-no-padding'>{this.woFormatter(cell, row)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Invoice #</Col>
                <Col xs={6} className='link-no-padding'>{this.invoiceFormatter(cell, row)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Status Dates</Col>
                <Col xs={6}>{this._statusDateFormatter(cell, row)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Sent</Col>
                <Col xs={6}>{row.invoice_sent_count}</Col>
            </Row>
            <Row>
                <Col xs={6}>Aging</Col>
                <Col xs={6}>{row.aging}</Col>
            </Row>
            <Row>
                <Col xs={6}>Balance</Col>
                <Col xs={6}>{dollarsFormatter(row.balance)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Cost</Col>
                <Col xs={6}>{dollarsFormatter(row.cost)}</Col>
            </Row>
        </div>
    };

    dateChangeCallback = () => {
        this.setState(state => {
            state.resource.label = 'all';
            return state
        });
        this.search();
    };

    rowStyleFormat = (cell, row) => {
        const {invoices} = this.state;
        return {
            background: invoices.map((invoice, i) => invoice.inactive === true ? i : -1).filter(index => index !== -1).includes(row) ? '#EBEBE4' : null
        }
    };

    onExportToXLSX = () => {
        const {invoices} = this.state
        const selectedRows = this.tableRef.current.state.selectedRowKeys;
        return invoices.filter(i => {
            if (selectedRows.indexOf(i.id) > -1) {
                return i;
            }
        });
    }

    onColumnsSettingsChange = (column) => {
        let {columnsSettings} = this.state
        columnsSettings[column] = !columnsSettings[column]
        this.setState({columnsSettings}, this.setAllColumnsSelect)
        this.props.actions.updateInvoicesListSettings(columnsSettings)
    }

    setAllColumnsSelect = () => {
        let {columnsSettings} = this.state
        let check = true
        Object.keys(columnsSettings).forEach(column => {
            if (!columnsSettings[column]) {
                check = false
            }
        })
        this.setState({columnsSettingsSelectedAll: check})
    }

    onSelectAllColumns = () => {
        let {columnsSettings, columnsSettingsSelectedAll} = this.state
        Object.keys(columnsSettings).forEach(column => columnsSettings[column] = !columnsSettingsSelectedAll)
        columnsSettingsSelectedAll = !columnsSettingsSelectedAll;
        this.props.actions.updateInvoicesListSettings(columnsSettings, (res) => {
            this.setState({columnsSettings, columnsSettingsSelectedAll})
        })
    }

    renderSettingsDropdown = () => {
        const {columnsSettings, columnsSettingsSelectedAll} = this.state
        return (
            <div className="settings-dropdown-menu">
                <DropdownButton title={<>Settings<FontAwesomeIcon icon={faCog} className="small-margin fontSize14"/></>}
                                className="mr-9 settings-dropdown-menu">
                    <Checkbox className="ml-9" checked={columnsSettingsSelectedAll} onChange={() => {
                        this.onSelectAllColumns()
                    }}>Select All</Checkbox>
                    <Checkbox className="ml-9" checked={columnsSettings["Customer"]} onChange={() => {
                        this.onColumnsSettingsChange("Customer")
                    }}>Customer</Checkbox>
                    <Checkbox className="ml-9" checked={columnsSettings["Site"]} onChange={() => {
                        this.onColumnsSettingsChange("Site")
                    }}>Site</Checkbox>
                    <Checkbox className="ml-9" checked={columnsSettings["Arborist"]} onChange={() => {
                        this.onColumnsSettingsChange("Arborist")
                    }}>Arborist</Checkbox>
                    <Checkbox className="ml-9" checked={columnsSettings["Title"]} onChange={() => {
                        this.onColumnsSettingsChange("Title")
                    }}>Title</Checkbox>
                    <Checkbox className="ml-9" checked={columnsSettings["Invoice notes"]} onChange={() => {
                        this.onColumnsSettingsChange("Invoice notes")
                    }}>Invoice notes</Checkbox>
                    <Checkbox className="ml-9" checked={columnsSettings["Proposal #"]} onChange={() => {
                        this.onColumnsSettingsChange("Proposal #")
                    }}>Proposal #</Checkbox>
                    <Checkbox className="ml-9" checked={columnsSettings["WO #"]} onChange={() => {
                        this.onColumnsSettingsChange("WO #")
                    }}>WO #</Checkbox>
                    <Checkbox className="ml-9" checked={columnsSettings["Invoice #"]} onChange={() => {
                        this.onColumnsSettingsChange("Invoice #")
                    }}>Invoice #</Checkbox>
                    <Checkbox className="ml-9" checked={columnsSettings["Status Dates"]} onChange={() => {
                        this.onColumnsSettingsChange("Status Dates")
                    }}>Status Dates</Checkbox>
                    <Checkbox className="ml-9" checked={columnsSettings["Sent"]} onChange={() => {
                        this.onColumnsSettingsChange("Sent")
                    }}>Sent</Checkbox>
                    <Checkbox className="ml-9" checked={columnsSettings["Aging"]} onChange={() => {
                        this.onColumnsSettingsChange("Aging")
                    }}>Aging</Checkbox>
                    <Checkbox className="ml-9" checked={columnsSettings["Balance"]} onChange={() => {
                        this.onColumnsSettingsChange("Balance")
                    }}>Balance</Checkbox>
                    <Checkbox className="ml-9" checked={columnsSettings["Cost"]} onChange={() => {
                        this.onColumnsSettingsChange("Cost")
                    }}>Cost</Checkbox>
                    <Checkbox className="ml-9 bottom3" checked={columnsSettings["Pay"]} onChange={() => {
                        this.onColumnsSettingsChange("Pay")
                    }}>Pay</Checkbox>
                </DropdownButton>
            </div>
        )
    }

    clearFilters = () => {
        const {resource} = this.state
        this.setState({
            resource: {
                ...resource,
                customer_id: null,
                site_id: null,
                arborist_id: null,
                proposal_id: null,
                proposal_no: null,
                work_order_no: null,
                work_order_id: null,
                invoice_no: null,
                invoice_id: null,
                page: 1,
                from: null,
                to: null,
                invoice_sent_count_from: null,
                invoice_sent_count_to: null,
                aging_from: null,
                aging_to: null,
                balance_from: null,
                balance_to: null,
                cost_from: null,
                cost_to: null,
                inactive: false
            }
        }, this.delayedSearch)
    }

    onFilterChange = () => {
        const {resource} = this.state
        if (resource.page !== 1) {
            this.setState({resource: {...resource, page: 1}}, this.delayedSearch)
        } else {
            this.delayedSearch()
        }
    }

    onNumericFilterChange = () => {
        const {numericFilterChanged} = this.state
        if (!numericFilterChanged) {
            this.setState({numericFilterChanged: true})
        }
    }

    reloadInvoicesPaymentModal = () => {
        this.props.actions.load(result => this.setState(result, this.search));
        this.setState({
            paymentModalInvoice: null,
            showPaymentPopUp: false
        })
    };

    doExportToXLSX = async () => {
        const {resource, columnsSettings} = this.state
        const invoices = _.cloneDeep(this.onExportToXLSX())
        await exportToXLSX(invoices, resource, columnsSettings)
        const csvFileName = `invoices-${moment().format(defaultDateFormat)}.csv`
        if (this.tableRef?.current) {
            this.props.actions.saveNewCsvDownload(this.tableRef.current, csvFileName, this.state.resource, "invoices")
        }
    }

    onPayButtonClick = (invoiceToken) => {
        this.props.actions.fetchInvoice(invoiceToken, result => {
            this.setState({
                paymentModalInvoice: result,
                showPaymentPopUp: true
            })
        }, null, true)
    }

    updatePaging = (page, per_page) => {
        let {resource} = this.state
        resource = {...resource, page, per_page};
        this.setState({resource}, this.delayedSearch);
    }

    updateSorting = (sort_by, sort) => {
        let {resource} = this.state;
        resource = {...resource, sort_by, sort};
        this.setState({resource}, this.delayedSearch);
    }

    updateInvoiceCustomerPaymentSettings = (customer_payment_settings, callback) => {
        let {invoices, paymentModalInvoice} = this.state
        invoices.map((i) => {
            if (i.id === paymentModalInvoice.id) {
                i.customer_payment_settings = customer_payment_settings
            }
            return i
        })
        this.setState({invoices}, callback)
    }

    changePaymentMethod = (key, value) => {
        if ((key === "payByACH" || key === "payByStripe" || key === "payByManualPayment") && value) {
            this.setState({paymentModalPaymentMethod: key})
        } else {
            this.setState({paymentModalPaymentMethod: null})
        }
    }

    render() {
        const {
            invoices, total, selectedRows, email, filterVisible,
            resource, dateFilterOptions, employees, InvoiceFilterModalShow,
            loaded, columnsSettings, showPaymentPopUp, paymentModalInvoice,
            paymentModalPaymentMethod
        } = this.state;
        const {sort, sort_by, page, per_page} = this.state.resource;
        const customerOptions = this.customerSearch.searchOptions();
        const selectedRowsEmail = invoices.filter(d => selectedRows?.includes(d.id)).map(d => {
            return {name: d.customer_name, email: d.email_address}
        })
        const siteOptions = this.siteSearch.searchOptions();
        const arboristOptions = this.arboristSearch.searchOptions();
        const proposalTitleOptions = this.proposalTitleSearch.searchOptions();
        const proposalNoOptions = this.proposalNoSearch.searchOptions();
        const workOrderOptions = this.workOrderSearch.searchOptions();
        const invoiceOptions = this.invoiceSearch.searchOptions();
        const selectedRowsPdf = invoices.filter(d => selectedRows.includes(d.id)).map(d => {
            return {dataPdf: d};
        });
        return (
            <Grid fluid id='invoices'>
                <Row>
                    <Col md={12}>
                    </Col>
                </Row>
                <Row className="vcenter">
                    <Col md={4} className="vcenter">
                        <h2 className="no-top-margin mr-9">Invoices</h2>
                        <h5 className={smMobile ? "text-primary nmt-10" : "text-primary"}>{total} returned</h5>
                    </Col>
                    <Col md={8} className="justify-flex-end">
                        {this.renderSettingsDropdown()}
                        <Button
                            className="pointer no-top-margin"
                            bsStyle="warning"
                            onClick={() => this.setState({
                                InvoiceFilterModalShow: !this.state.InvoiceFilterModalShow
                            })}>
                            Filter {" "}<FontAwesomeIcon icon="search" className="small-margin"/>
                        </Button>
                    </Col>
                </Row>
                <hr className="no-margin"/>
                <Row>
                    {InvoiceFilterModalShow && <InvoicesFilterModal
                        resource={resource}
                        show={InvoiceFilterModalShow}
                        onHide={() => this.setState({InvoiceFilterModalShow: !this.state.InvoiceFilterModalShow}, () => {
                            this.onFilterChange()
                        })
                        }
                        closeButton={() => this.setState({InvoiceFilterModalShow: !this.state.InvoiceFilterModalShow})}
                        clearFiltersButton={this.clearFilters}
                        onDateTimeChange={(e, name) => {
                            this.state.resource[name] = (e && e !== '') ? e : null;
                            this.setState({resource: this.state.resource}, this.dateChangeCallback)
                        }}
                        onFocusDateTimeTo={() => {
                            if (resource.to === null) {
                                resource.to = moment().endOf('day')
                                this.setState(resource)
                            }
                        }}
                        onClickRadioButton={(name) => this.handleSelectDateFilterType(name)}
                        selectOptions={{
                            customerOptions,
                            siteOptions,
                            arboristOptions,
                            proposalTitleOptions,
                            proposalNoOptions,
                            workOrderOptions,
                            invoiceOptions
                        }}
                        search={{
                            customerSearch: this.customerSearch.search,
                            siteSearch: this.siteSearch.search,
                            arboristSearch: this.arboristSearch.search,
                            proposalTitleSearch: this.proposalTitleSearch.search,
                            proposalNoSearch: this.proposalNoSearch.search,
                            workOrderSearch: this.workOrderSearch.search,
                            invoiceSearch: this.invoiceSearch.search
                        }}
                        selectResourceAttr={(name) => this.selectResourceAttr(name)}
                        selectCheckboxAttr={(e, name) => {
                            this.state.resource[name] = e;
                            this.setState({resource: this.state.resource}, () => this.setState(resource))
                        }}
                        updateResourceAttr={(e) => this.updateResourceAttr(e, this.onNumericFilterChange)}
                        onInputBlur={(e, name) => this.dollarFormatAttr(e, this.reformatDollars(name))}
                        dollarInputValue={(name) => this.numericInputValueFormatterDollar(name)}
                    />}
                    {showPaymentPopUp && <PaymentModal
                        changePaymentMethod={this.changePaymentMethod}
                        show={showPaymentPopUp}
                        reload={this.reloadInvoicesPaymentModal}
                        invoice={paymentModalInvoice}
                        onHide={() => this.setState({showPaymentPopUp: false, paymentModalPaymentMethod: null})}
                        invoiceList={true}
                        payByACH={paymentModalPaymentMethod === "payByACH"}
                        payByStripe={paymentModalPaymentMethod === "payByStripe"}
                        payByManualPayment={paymentModalPaymentMethod === "payByManualPayment"}
                        paymentMethod={paymentModalPaymentMethod}
                        newViewEnabled={true}
                    />}
                </Row>
                <Row className="top10">
                    <InvoicesTable
                        renderPaginationPanel={this.renderPaginationPanel}
                        createCustomToolBar={this.createCustomToolBar}
                        sizePerPageDropDown={this.renderSizePerPageDropDown}
                        tableRef={this.tableRef}
                        total={total}
                        onRowSelect={this.onRowSelect}
                        onSelectAll={this.onSelectAll}
                        resource={resource}
                        updatePaging={this.updatePaging}
                        updateSorting={this.updateSorting}
                        columnsSettings={columnsSettings}
                        invoices={invoices}
                        loaded={loaded}
                        selectedRows={selectedRows}
                        doExportToXLSX={this.doExportToXLSX}
                        onPayButtonClick={this.onPayButtonClick}
                        email={this.email}
                    />
                </Row>
                {
                    email && <EmailDialog defaultEmail={this.props.email}
                                          sendEmail={() => this.search()}
                                          isBulk={true}
                                          referenceId={selectedRows}
                                          templateMode
                                          emailType="bulk_invoiced"
                                          confirmMessage={true}
                                          selectedRecords={selectedRowsEmail}
                                          dataPdf={selectedRowsPdf}
                                          hideEmailDialog={() => {
                                              this.setState({email: null});
                                          }}
                                          sendInBackground={true}
                    />
                }
            </Grid>
        );
    }
}

Invoices.propTypes = {
    //myProp: PropTypes.object.isRequired
};

Invoices.defaultProps = {
    //myProp: <defaultValue>
};

function mapStateToProps(state, ownProps) {
    return {
        email: state.auth.email,
        client: state.client.customerInfo,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Invoices);