import axios from "axios/index";
import {apiCall} from "../../common/apiActionHandler";
import {mapForSelect} from "../../common/commonHandlers";
import * as FileSaver from "file-saver";
import moment from "moment";
import {dataUpload} from "../../common/upload";
import {addAlert} from "../App/actions";

export const sendBulkEmail = (recipients, email, selectedRows, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/emails/send_bulk_email`,
        method: 'POST',
        data: {email: {...email, recipients}, selected_rows: selectedRows}
    };

    apiCall("Email sending", config, async config => {
        const response = await axios.request(config);
        if (!(response.data.length > 0 && response.data[0].error)) {
            dispatch(
                addAlert({
                    message: `Email sending succeed`,
                    mood: "success",
                    dismissAfter: 1500,
                })
            );
        }
        callback(response.data);
    }, dispatch, getState, false);
};

export const loadProposalData = (proposalId, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/proposals/${proposalId}`
    };

    apiCall("Loading proposal", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const declineProposals = (proposalIds, isBacklog = false, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/sales_dashboard/decline`,
        params: {
            isBacklog: isBacklog,
            ps_ids: proposalIds.join(',')
        }
    };

    apiCall("Declining proposals", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};

export const downloadCsv = (params) => async (dispatch, getState) => {
    const config = {
        url: `/api/sales_dashboard/csv`,
        params
    };

    apiCall("Downloading csv", config, async config => {
        const response = await axios.request(config);
        const blob = new Blob([response.data], {
            type: 'text/csv',
        });
        FileSaver.saveAs(blob, `sales-${moment().format()}.csv`);

        blob.name = `sales-${moment().format()}.csv`
        dataUpload(`/api/download_history`, blob, null, null, {
            params: JSON.stringify(params ? params : {}),
            source: "new_sales_dashboard"
        }, false)(dispatch, getState)
    }, dispatch, getState);
};

export const downloadCsvForExcel = (params, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/sales_dashboard/csv`,
        params
    };

    apiCall("Downloading csv", config, async config => {
        const response = await axios.request(config);
        const blob = new Blob([response.data], {
            type: 'text/csv',
        });
        blob.name = `sales-${moment().format()}.csv`
        dataUpload(`/api/download_history`, blob, null, null, {
            params: JSON.stringify(params ? params : {}),
            source: "new_sales_dashboard"
        }, false)(dispatch, getState)
        callback && callback(response.data)
    }, dispatch, getState);
};

export const fetchData = (resource, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/sales_dashboard`,
        method: 'GET',
        params: resource
    };


    apiCall("Fetch sales data", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const fetchAcceptedTotals = (resource, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/sales_dashboard/accepted_totals`,
        method: 'GET',
        params: resource
    };

    apiCall("Fetch sales data", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const fetchTableData = (resource, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/sales_dashboard/data`,
        method: 'GET',
        params: resource
    };

    apiCall("Fetch sales data", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const load = callback => (dispatch, getState) => {
    const config = [
        {url: `/api/sales_cross_view/service_types`},
        {url: `/api/new_services?bundle_service=false`},
        {url: `/api/customer_types`},
        {url: `/api/reasons`},
        {url: `/api/priorities/search`},
        {url: `/api/equipments/search`},
        {url: '/api/plants'},
        {url: '/api/customers'},
    ];

    apiCall("Loading data", config, async config => {
        let actions = config.map(c => axios.request(c));
        const all = await axios.all(actions);
        callback({
            service_types: mapForSelect(all[0].data),
            services: mapForSelect(all[1].data),
            customer_types: mapForSelect(all[2].data),
            reasons: mapForSelect(all[3].data),
            priorities: mapForSelect(all[4].data),
            equipments: mapForSelect(all[5].data),
            plants: mapForSelect(all[6].data),
            customers: mapForSelect(all[7].data),
        });
    }, dispatch, getState, false);
};

export const getEmployees = callback => (dispatch, getState) => {
    const config = {
        url: `/api/employees/`
    };

    apiCall("Fetch employees", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const getAssignees = callback => (dispatch, getState) => {
    const config = {
        url: '/api/sales_dashboard/assignees'
    };

    apiCall("Fetch assignees", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const fetchStateAndCityForCustomer = (zipcode, callback) => async (dispatch, getState) => {
    const config = {
        url: "/api/customers/zipcode_search",
        params: {
            zipcode: zipcode
        }
    };
    apiCall("Fetching state and city", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false);
}

export const getCustomers = (filter, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/new_customers`,
        params: filter
    };

    apiCall("Getting customers", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const getCustomersSearch = (searchText, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/new_customers/search_customers/`,
        params: {q: searchText}
    };

    apiCall("Getting customers", config, async config => {
        const response = await axios.request(config);
        callback(mapForSelect(response.data));
    }, dispatch, getState, false);
};

export const getZipcodeSearch = (searchText, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/sites/search_zip_code/`,
        params: {q: searchText}
    };

    apiCall("Getting zipcode", config, async config => {
        const response = await axios.request(config);
        callback(mapForSelect(response.data));
    }, dispatch, getState, false);
};

export const getCitySearch = (searchText, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/sites/city_search/`,
        params: {q: searchText}
    };

    apiCall("Getting city", config, async config => {
        const response = await axios.request(config);
        callback(mapForSelect(response.data));
    }, dispatch, getState, false);
};

export const searchForProposal = (searchText, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/new_proposals/search`,
        params: {q: searchText}
    };

    apiCall("Searching for proposals", config, async config => {
        const response = await axios.request(config);
        callback(mapForSelect(response.data));
    }, dispatch, getState, false);
};

export const getDashboardSettings = (callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/users/get_dashboard_settings`
    };

    apiCall("Getting dashboard settings", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const saveDashboardSettings = (resource, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/users/update_dashboard_settings`,
        method: 'PUT',
        data: {resource: resource}
    };
    apiCall("Saving dashboard settings", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false);
};

export const sendBulkNotification = (email, recipients, emailType, emailTemplateId, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/twilio/send_notification`,
        method: 'POST',
        data: {email: email, recipients: recipients, email_type: emailType, email_template_id: emailTemplateId}
    };

    apiCall("Notification sent", config, async config => {
        const response = await axios.request(config);
        callback && callback(response);
    }, dispatch, getState);
};