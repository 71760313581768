import React, {useEffect, useState} from 'react'
import {Button, Col, ControlLabel, FormControl, FormGroup, Grid} from "react-bootstrap";
import {bindActionCreators} from "redux";
import * as actions from './SubscriptionSettingsApi'
import {connect} from "react-redux";
import PageHeader from "../../../components/PageTitle";
import Select from "react-select";
import {select} from "../../../common/commonHandlers";
import MDSpinner from "react-md-spinner";


const SubscriptionSettings = ({actions, name}) => {
    const [pricesList, setPricesList] = useState([])
    const [oldApplicationPrice, setOldApplicationPrice] = useState('')
    const [applicationPrice, setApplicationPrice] = useState('')
    const [maxUserCount, setMaxUserCount] = useState(50)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [firstUserPrice, setFirstUserPrice] = useState(0)
    const [userPrice, setUserPrice] = useState(0)
    const [newSubscriptionsOnly, setNewSubscriptionsOnly] = useState(true)
    const [isNewPrice, setIsNewPrice] = useState(false)

    const loadSettings = () => {
        actions.getPricesList(res => {
            setPricesList(res)
        })
        actions.getStripeSettings(res => {
            setOldApplicationPrice(res.application_price)
            setApplicationPrice(res.application_price)
            setMaxUserCount(res.max_user_count)
        })
        setIsNewPrice(false)
    }
    useEffect(() => {
        loadSettings()
    }, [])

    return (<>
        <PageHeader pageName="Subscription Settings" pageDetail={`Treehub`}/>
        <Grid fluid className="top25">
            <Col md={3}>
                {!isNewPrice && <>
                    <FormGroup>
                        <ControlLabel>Subscription price:</ControlLabel>
                        <Select className="Select"
                                classNamePrefix="select"
                                value={select(pricesList, applicationPrice)}
                                options={pricesList}
                                placeholder="Select price"
                                onChange={(e) => setApplicationPrice(e.value)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Button className='btn-success' onClick={() => setIsNewPrice(true)}>Add new</Button>
                    </FormGroup>
                </>}
                {isNewPrice && <><FormGroup>
                    <ControlLabel>First user price:</ControlLabel>
                    <FormControl
                        type="number"
                        value={firstUserPrice}
                        placeholder="First user price"
                        onChange={(e) => setFirstUserPrice(e.target.value)}
                    />
                </FormGroup>
                    <FormGroup>
                        <ControlLabel>User price:</ControlLabel>
                        <FormControl
                            type="number"
                            value={userPrice}
                            placeholder="User price"
                            onChange={(e) => setUserPrice(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Button onClick={() => setIsNewPrice(false)}>Cancel</Button>
                    </FormGroup>
                </>}
                <FormGroup>
                    <ControlLabel>Max user count:</ControlLabel>
                    <FormControl
                        type="number"
                        value={maxUserCount}
                        placeholder="Max user count"
                        onChange={(e) => setMaxUserCount(e.target.value)}
                    />
                </FormGroup>
                <FormGroup>
                    <ControlLabel>Update:</ControlLabel>
                    <p>
                        <Button className={newSubscriptionsOnly ? 'btn-success' : 'btn-danger'}
                                onClick={() => setNewSubscriptionsOnly(!newSubscriptionsOnly)}>{newSubscriptionsOnly ? 'Only new subscriptions' : 'All subscriptions'}</Button>
                    </p>
                </FormGroup>
                {!newSubscriptionsOnly && <FormGroup>
                    <p className='text-info'>
                        Change subscription price will update subscriptions for all
                        clients. This may take a while.
                    </p>
                </FormGroup>}
                <FormGroup>
                    <p className='text-danger'>
                        {error}
                    </p>
                </FormGroup>
                <FormGroup>
                    <Button className="top-margin" disabled={loading}
                            onClick={() => {
                                actions.updateStripeSettings(applicationPrice, maxUserCount, firstUserPrice, userPrice, isNewPrice, res => {
                                    if (res) {
                                        if (res.updated_status === 'finished' && (oldApplicationPrice !== applicationPrice || isNewPrice)) {
                                            setLoading(true)
                                            actions.updateSubscriptionPrice(applicationPrice, newSubscriptionsOnly, res.new_price_id, res => {
                                                if (res) {
                                                    if (res.status === 'finished') {
                                                        loadSettings()
                                                        setLoading(false)
                                                        setError('')
                                                    } else if (res.status === 'failed') {
                                                        setLoading(false)
                                                        setError('Something went wrong, try again.')
                                                    }
                                                }
                                            })
                                        } else if (res.status === 'failed') {
                                            setLoading(false)
                                            setError('Something went wrong, try again.')
                                        }
                                    }
                                })

                            }}>{loading ? <><MDSpinner/> Loading</> : <>Save</>}</Button>
                </FormGroup>
            </Col>
        </Grid>
    </>);
};

const mapStateToProps = state => ({
    name: state.auth.name,
    client: state.client.customerInfo,
})

const mapDispatchToProps = dispatch => ({actions: bindActionCreators(actions, dispatch),})

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionSettings)