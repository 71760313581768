import {
    DELETED_USER,
    FETCH_CUSTOMER_INFO,
    FETCH_EMPLOYEES,
    FETCH_IMAGES_INFO,
    FETCH_ROLES,
    FETCH_STATE_AND_CITY,
    FETCH_USERS,
    SAVED_CLIENT_INFO,
    SAVED_USER_INFO,
} from "./constants"

import axios from "axios"
import {addAlert} from "../App/actions"
import {apiCall} from "../../common/apiActionHandler";
import {handleUpload} from "../../common/upload";

export {addAlert}


export const fetchStateAndCity = zipcode => async (dispatch, getState) => {
    const store = getState()
    const config = {
        url: "/api/customers/zipcode_search",
        headers: {Authorization: store.auth.token},
        params: {
            zipcode: zipcode,
        },
    }

    try {
        const response = await axios.request(config)
        dispatch({type: FETCH_STATE_AND_CITY, location: response.data})
    } catch (error) {
        dispatch(
            addAlert({
                message: "Could not fetch your state and city from zipcode because " + error.toString(),
                mood: "danger",
                dismissAfter: 1500,
            })
        )
        throw error
    }
}

export const fetchCustomerInfo = (clientId) => async (dispatch, getState) => {
    const store = getState()
    const config = {
        url: `/api/clients/${clientId}`,
        headers: {Authorization: store.auth.token},
    }

    try {
        const response = await axios.request(config)
        dispatch({type: FETCH_CUSTOMER_INFO, customerInfo: response.data})
    } catch (error) {
        dispatch(
            addAlert({
                message: "Client not found in your account because " +
                    error.toString(),
                mood: "danger",
                dismissAfter: 1500,
            })
        )

        throw error
    }
}

export const saveClientInfo = client => async (dispatch, getState) => {
    const store = getState()
    const config = {
        method: "PUT",
        url: `/api/clients/${client.id}`,
        headers: {Authorization: store.auth.token},
        data: {client}
    }

    try {
        const response = await axios.request(config)
        dispatch({type: SAVED_CLIENT_INFO})
    } catch (error) {
        dispatch(
            addAlert({
                message: "Client not saved because " +
                    error.toString(),
                mood: "danger",
                dismissAfter: 1500,
            })
        )

        throw error
    }
}

export const validateUser = user => (dispatch) => {
    if (!user.person_id) {
        dispatch(
            addAlert({
                message: "Employee missing",
                mood: "danger",
                dismissAfter: 1000,
            })
        );
        return false;
    }
    return true;
};

export const deleteUser = userId => async (dispatch, getState) => {

    const store = getState();
    const config = {
        method: "DELETE",
        url: `/api/users/${userId}`,
        headers: {Authorization: store.auth.token},
    };

    try {
        const response = await axios.request(config);
        dispatch(
            addAlert({
                message: "User deleted",
                mood: "success",
                dismissAfter: 1000,
            })
        );
        dispatch({type: DELETED_USER});
        const clientId = store.client.customerInfo.id;
        dispatch(fetchUsers(clientId));
    } catch (error) {
        dispatch(
            addAlert({
                message: "User not deleted because " +
                    error.toString(),
                mood: "danger",
                dismissAfter: 1500,
            })
        );

        throw error
    }
};

export const saveUserInfo = user => async (dispatch, getState) => {
    const store = getState()
    const clientId = store.client.customerInfo.id
    const userId = user.id;
    const method = userId ? "PUT" : "POST";
    const url = userId ? `/api/users/${userId}` : `/api/users/`;
    const config = {
        method,
        url,
        headers: {Authorization: store.auth.token},
        data: {user}
    }

    try {
        const response = await axios.request(config)
        dispatch({type: SAVED_USER_INFO, id: response.data.id});
        dispatch(fetchUsers(clientId))
        dispatch(
            addAlert({
                message: userId ? "User updated" : "User saved",
                mood: "success",
                dismissAfter: 1000,
            })
        )
    } catch (error) {
        dispatch(
            addAlert({
                message: "USER not saved because " +
                    error.response.data.errors.join(". ") ||
                    error.toString(),
                mood: "danger",
                dismissAfter: 2500,
            })
        )

        throw error
    }
}

export const fetchUsers = clientId => async (dispatch, getState) => {
    const store = getState()
    const config = {
        url: `/api/users/`,
        headers: {Authorization: store.auth.token},
        params: {
            clientId: clientId
        }
    }

    try {
        const response = await axios.request(config)
        dispatch({type: FETCH_USERS, userInfo: response.data})
    } catch (error) {
        dispatch(
            addAlert({
                message: "User not found in your account because " +
                    error.toString(),
                mood: "danger",
                dismissAfter: 1500,
            })
        )

        throw error
    }
}

export const fetchRoles = () => async (dispatch, getState) => {
    const store = getState()
    const config = {
        url: `/api/roles/`,
        headers: {Authorization: store.auth.token},
    }

    try {
        const response = await axios.request(config)
        dispatch({type: FETCH_ROLES, roles: response.data})
    } catch (error) {
        dispatch(
            addAlert({
                message: "User not found in your account because " +
                    error.toString(),
                mood: "danger",
                dismissAfter: 1500,
            })
        )

        throw error
    }
}

export const fetchEmployees = (clientId) => async (dispatch, getState) => {
    const store = getState()
    const config = {
        url: `/api/employees/`,
        headers: {Authorization: store.auth.token},
        params: {
            clientId: clientId
        }
    }

    try {
        const response = await axios.request(config)
        dispatch({type: FETCH_EMPLOYEES, employees: response.data})
    } catch (error) {
        dispatch(
            addAlert({
                message: "User not found in your account because " +
                    error.toString(),
                mood: "danger",
                dismissAfter: 1500,
            })
        )

        throw error
    }
}

export const uploadClientImage = (acceptedFiles) => (dispatch, getState) => {
    const store = getState()
    const id = store.client.customerInfo.id
    handleUpload(`/api/images/upload?client_id=${id}`, acceptedFiles, () => dispatch(fetchClientImageInfo(id)))(dispatch, getState);
};
export const uploadClientImageLogo = (acceptedFiles) => (dispatch, getState) => {
    const store = getState()
    const id = store.client.customerInfo.id
    handleUpload(`/api/images/upload?client_id=${id}`, acceptedFiles, () => dispatch(fetchClientImageInfo(id)), null, "client_logo", true)(dispatch, getState);
};
export const fetchClientImageInfo = (clientId) => (dispatch, getState) => {
    const store = getState()
    const config = {
        method: "get",
        url: "/api/images",
        headers: {Authorization: store.auth.token},
        params: {
            client_id: clientId,
        },
    }

    return axios.request(config).then(response => {
        dispatch({type: FETCH_IMAGES_INFO, imagesInfo: response.data})
    })
}

export const deleteClientImage = id => (dispatch, getState) => {
    const store = getState()

    const config = {
        method: "DELETE",
        url: `/api/images/${id}`,
        headers: {Authorization: store.auth.token},
    }

    return axios.request(config).then(response => {
        dispatch(fetchClientImageInfo(store.client.customerInfo.id))
        dispatch(
            addAlert({
                message: "Header Deleted!",
                mood: "success",
                dismissAfter: 1500,
            })
        )
    })
}

export const changeClientHeader = id => (dispatch, getState) => {
    const store = getState()

    const config = {
        method: "PUT",
        url: `/api/images/change_header?id=${id}`,
        headers: {Authorization: store.auth.token},
    }

    return axios.request(config).then(response => {
        dispatch(fetchClientImageInfo(store.client.customerInfo.id))
        dispatch(
            addAlert({
                message: "Header Updated!",
                mood: "success",
                dismissAfter: 1500,
            })
        )
    })
}
export const changeClientLogo = id => (dispatch, getState) => {
    const store = getState()

    const config = {
        method: "PUT",
        url: `/api/images/change_logo?id=${id}`,
        headers: {Authorization: store.auth.token},
    }

    return axios.request(config).then(response => {
        dispatch(fetchClientImageInfo(store.client.customerInfo.id))
        dispatch(
            addAlert({
                message: "Logo Updated!",
                mood: "success",
                dismissAfter: 1500,
            })
        )
    })
}

export const validateProposalNumber = (number, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/new_proposals/validate_number`,
        params: {number: number}
    };

    apiCall("Validating proposal number", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};

export const validateWoNumber = (number, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/work_orders/validate_number`,
        params: {number: number}
    };

    apiCall("Validating wo number", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};

export const validateInvoiceNumber = (number, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/new_invoices/validate_number`,
        params: {number: number}
    };

    apiCall("Validating invoice number", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};
