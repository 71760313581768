import {Modal, Row} from "react-bootstrap";
import React, {Component} from 'react';
import './PastDueInvoices.scss';
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import {dateFormatter, dollarsFormatter} from "../../../components/BootStrapTableCellFormatters";
import {Link} from "react-router";
import Col from "react-bootstrap/lib/Col";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons'

const isMobile = window.screen.width <= 1024;

class PastDueInvoicesModal extends Component {

    rowFormatterMobile = (row) => {
        return <>
            <Row>
                <Col xs={6}>Invoice #</Col>
                <Col xs={6}>
                    <Link target='_blank' to={`/proposals/${row.proposal_id}/invoices`}>#{row.proposal_id}</Link>
                </Col>
            </Row>
            <Row>
                <Col xs={6}>Due Date</Col>
                <Col xs={6}>{dateFormatter(row.due_date)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Days Past Due</Col>
                <Col xs={6}>{row.days_past_due_date}</Col>
            </Row>
            <Row>
                <Col xs={6}>Total</Col>
                <Col xs={6}>{dollarsFormatter(row.payment_amount)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Balance</Col>
                <Col xs={6}>{dollarsFormatter(row.balance)}</Col>
            </Row>
        </>
    };

    render() {
        const {pastDueInvoices} = this.props
        return (
            <Modal
                animation={false}
                show={this.props.show}
                onHide={this.props.onHide}
                id="past-due-invoices-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Past Due Invoices</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isMobile ?
                        <>{pastDueInvoices?.map((row, idx) =>
                            <>
                                <Col xs={12} className={idx === 0 && "mt10"}>{this.rowFormatterMobile(row)}</Col>
                                {idx !== pastDueInvoices?.length - 1 && <Col xs={12}>
                                    <hr/>
                                </Col>}
                            </>
                        )}</> :
                        <BootstrapTable
                            data={pastDueInvoices}
                            striped={true}
                            bordered={false}
                            hover={true}
                            remote
                        >
                            <TableHeaderColumn hidden isKey dataField="any"/>
                            <TableHeaderColumn
                                dataField="number"
                                width='19%'
                                dataFormat={(cell, row) => {
                                    return <Link target='_blank'
                                                 to={`/proposals/${row.proposal_id}/invoices`}>#{cell}</Link>
                                }}
                            >
                                Invoice #
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="due_date"
                                width='25%'
                                dataFormat={dateFormatter}
                            >
                                Due Date
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="days_past_due_date" width='16%'>
                                Days Past Due
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="payment_amount"
                                width='20%'
                                dataFormat={dollarsFormatter}
                            >
                                Total
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="balance"
                                width='20%'
                                dataFormat={dollarsFormatter}
                            >
                                Balance
                            </TableHeaderColumn>
                        </BootstrapTable>
                    }
                </Modal.Body>

                <Modal.Footer>
                    <Col md={9} className="text-left">
                        <div className="vcenter">
                            <FontAwesomeIcon icon={faInfoCircle} className="mr-9 font20"/>
                            <span className="font10">If there is no due date, then the invoice is counted as overdue when it is unpaid and the time from the invoice date is more than 45 days.</span>
                        </div>
                    </Col>
                    <Col md={3}>
                        <button type="button" onClick={this.props.onHide} className="btn btn-default"
                                data-dismiss="modal">Cancel
                        </button>
                        <button type="button" onClick={this.props.onSubmit} className="btn btn-danger">Submit</button>
                    </Col>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default PastDueInvoicesModal;