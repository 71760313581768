import axios from 'axios'
import {addAlert} from '../App/actions'
import {PROPOSALS_FETCHED} from './constants'


export function fetchProposals() {
    return function (dispatch, getState) {
        const store = getState()

        const config = {
            method: 'get',
            headers: {'Authorization': store.auth.token},
            url: '/api/proposals/'
        }

        axios.request(config)
            .then(response => {
                dispatch({type: PROPOSALS_FETCHED, fetchedProposals: response.data})
                // dispatch(addAlert({message: "Proposals loaded", dismissAfter: 500, mood: 'success'}))
            })
            .catch(error => {
                if (error.response && error.response.status === 404) {
                    dispatch(addAlert({message: "Proposals for this Client were not found"}))
                }
            })

    }

}
