export const [
    UPDATE_WONO_SEARCH_TEXT,
    FOUND_WO_TO_COMPLETE,
    CLEAR_WO_DATA,
    SELECT_COMPLETE_PROPOSAL_SERVICE,
    UN_SELECT_COMPLETE_PROPOSAL_SERVICE,
] = [
    "UPDATE_WONO_SEARCH_TEXT",
    "FOUND_WO_TO_COMPLETE",
    "CLEAR_WO_DATA",
    "SELECT_COMPLETE_PROPOSAL_SERVICE",
    "UN_SELECT_COMPLETE_PROPOSAL_SERVICE",
]
