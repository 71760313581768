import {FETCH_PRINT_PROPOSAL, SET_SELECTED_SITE} from './constants'
import axios from 'axios'
import {addAlert} from "../App/actions"
import * as FileSaver from "file-saver";
import {addAuthorizationHeader, addTimeZoneHeader, apiCall} from "../../common/apiActionHandler";
import {dataUpload} from "../../common/upload";

export const fetchMap = (url, token, callback) => {
    const config = {
        method: 'get',
        url,
        headers: {'Authorization': token},
        responseType: "blob"
    };

    axios.request(config)
        .then(response => {
            const reader = new window.FileReader();
            reader.readAsDataURL(response.data);
            reader.onload = function () {
                const imageDataUrl = reader.result;
                callback(imageDataUrl);

            }
        })
};

export const fetchProposal = (proposalToken, callback, forPdf = null) => async (dispatch, getState) => {
    const config = {
        url: `/api/restricted/${proposalToken}/proposal${forPdf ? '?for_pdf=true' : ''}`,
    };

    apiCall("Fetching customer proposal", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
        dispatch({type: FETCH_PRINT_PROPOSAL, proposal: response.data})
    }, dispatch, getState, false);
};
export const loadInvoices = (proposalToken, loadAllRecords, site, callback) => async (dispatch, getState) => {
    const store = getState()
    let login = ""
    if (store.auth.token.includes("login")) {
        login = store.auth.token.split(':')[4]
    }
    const config = {
        url: `/api/restricted/${proposalToken}/customer_invoices`,
        params: {loadAllRecords, site, login}
    };

    apiCall("Load customer invoices", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, null, false);
};
export const loadProposals = (proposalToken, loadAllRecords, site, callback) => async (dispatch, getState) => {
    const store = getState()
    let login = ""
    if (store.auth.token.includes("login")) {
        login = store.auth.token.split(':')[4]
    }
    const config = {
        url: `/api/restricted/${proposalToken}/customer_proposals`,
        params: {loadAllRecords, site, login}
    };
    apiCall("Load customer proposals", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, null, false);
};

export const loadSites = (proposalToken, callback) => async (dispatch, getState) => {
    const store = getState()
    let login = ""
    if (store.auth.token.includes("login")) {
        login = store.auth.token.split(':')[4]
    }
    const config = {
        url: `/api/restricted/${proposalToken}/customer_sites`,
        params: {login}
    };

    apiCall("Load customer sites", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, null, false);
};

export const loadReferrals = (proposalToken, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/restricted/${proposalToken}/customer_referrals`,
    };

    apiCall("Load customer sites", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, null, false);
};

export const getCustomerEvents = (proposalToken, site, loadAllRecords, callback) => async (dispatch, getState) => {
    const store = getState()
    let login = ""
    if (store.auth.token.includes("login")) {
        login = store.auth.token.split(':')[4]
    }
    const config = [
        {
            url: `/api/restricted/${proposalToken}/customer_requests`,
            params: {loadAllRecords, site, login}
        },
        {
            url: `/api/restricted/${proposalToken}/customer_messages`,
            params: {site, login}
        },
        {
            url: `/api/restricted/${proposalToken}/customer_appointments`,
            params: {loadAllRecords, site, login}
        }
    ]

    apiCall("Load requests, messages and appointments", config, async config => {
        let actions = config.map(c => axios.request(c));
        const all = await axios.all(actions);
        callback({
                customerEvents: {
                    requests: all[0].data.customer_requests,
                    messages: all[1].data.customer_messages,
                    appointments: all[2].data.customer_appointments
                }
            }
        );
    }, dispatch, null, false);
};

export const loadCustomerReasons = (proposalToken, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/restricted/${proposalToken}/customer_reasons`,
    };

    apiCall("Load customer sites", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, null, false);
};

export const loadRequests = (proposalToken, loadAllRecords, site, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/restricted/${proposalToken}/customer_requests`,
        params: {loadAllRecords, site}
    };

    apiCall("Load customer sites", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, null, false);
};

export const loadContacts = (proposalToken, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/restricted/${proposalToken}/customer_contacts`
    };

    apiCall("Load customer sites", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, null, false);
};
export const loadMessages = (proposalToken, site, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/restricted/${proposalToken}/customer_messages`,
        params: {site}
    };

    apiCall("Load customer messages", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, null, false);
};

export const geocodeAddress = async (address, token) => {
    const config = {
        url: "/api/restricted/geocode",
        headers: {Authorization: token},
        params: {
            address,
        },
    };
    try {
        const response = await axios.request(config);
        const addresses = response.data && response.data.results

        let site_addresses;
        if (addresses && addresses.length > 0) {
            const keys = ["USA", "United States"]
            const countryAddress = addresses.filter(r => r.formatted_address && (r.formatted_address.includes(keys[0]) || r.formatted_address.includes(keys[1])))

            if (countryAddress && countryAddress.length > 0) {
                site_addresses = countryAddress
            } else {
                site_addresses = response.data.results
            }
        } else {
            site_addresses = response.data.results
        }
        return site_addresses
    } catch (error) {
        throw error
    }
};

export const saveRequest = (token, resources, isAddingNewSite, callback) => async (dispatch, getState) => {

    let full_address = ''
    let coords = ''
    let latitude = ''
    let longitude = ''

    resources = {
        ...resources,
        isAddingNewSite: isAddingNewSite
    }

    const isValid = validateForm(resources)
    if (!isValid) {
        dispatch(
            addAlert({
                message: "Some required fields are missing. Please check the form and try again.",
                mood: "danger",
                dismissAfter: 3500,
            })
        )
        return false
    }

    if (resources.address && resources.city && resources.state && resources.zip) {
        full_address = `${resources.address}, ${resources.city}, ${resources.state}, ${resources.zip}`
        coords = await geocodeAddress(full_address, getState().auth.token)

        if (!coords.length) {
            dispatch(
                addAlert({
                    message: "One of address parameters is invalid",
                    mood: "danger",
                    dismissAfter: 3500,
                })
            )
            return false
        } else {
            latitude = coords[0].geometry.location.lat
            longitude = coords[0].geometry.location.lng
        }

        resources = {
            ...resources,
            latitude: latitude,
            longitude: longitude,
            full_address: full_address
        }

    }


    const config = {
        url: `/api/restricted/${token}/save_request`,
        method: 'POST',
        params: {
            token: token,
            resources: resources
        }
    };

    await apiCall("Create request", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, null, false);
};

export const updateRequest = (token, resources, request_id, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/restricted/${token}/update_request/`,
        method: 'PUT',
        params: {
            token: token,
            resources: resources,
            request_id: request_id
        }
    };

    apiCall("Create customer payment methods", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, null, false);
};


export const fetchStateAndCityForCustomer = (zipcode, callback) => async (dispatch, getState) => {
    const config = {
        url: "/api/customers/zipcode_search",
        params: {
            zipcode: zipcode
        }
    };
    apiCall("Fetching state and city", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState);
}


const validateForm = site => {

    let requiredFields = []

    if (site.isAddingNewSite) {
        requiredFields = [
            "contact_id",
            "sitename",
            "address",
            "city",
            "state",
            "zip"
        ]
    } else {
        requiredFields = [
            "site_id"
        ]
    }


    const invalidFields = requiredFields.some(prop => {
        if (
            site[prop] === null ||
            site[prop] === undefined ||
            site[prop].toString().trim().length < 1
        ) {
            return true
        } else {
            return false
        }
    })

    if (invalidFields) {
        return false
    }

    return true
}


export const loadAppointments = (proposalToken, loadAllRecords, site, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/restricted/${proposalToken}/customer_appointments`,
        params: {loadAllRecords, site}
    };

    apiCall("Load customer appointments", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, null, false);
};

export const loadPaymentMethods = (token, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/stripe/get_payment_methods`,
        method: 'GET',
        params: {token: token}
    };

    apiCall("Load customer payment methods", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, null, false);
};
export const savePaymentMethod = (token, card_token, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/stripe/save_payment_method`,
        method: 'POST',
        params: {token: token, card_token: card_token}
    };

    apiCall("Create customer payment methods", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, null, false);
};
export const updateDefaultPaymentMethod = (token, card_id, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/stripe/update_default_payment_method`,
        method: 'PUT',
        params: {token: token, card_id: card_id}
    };

    apiCall("Delete customer payment methods", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, null, false);
};
export const deletePaymentMethod = (token, card_id, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/stripe/delete_payment_method`,
        method: 'DELETE',
        params: {token: token, card_id: card_id}
    };

    apiCall("Delete customer payment methods", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, null, false);
};
export const loadStripeCredentials = (token, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/stripe/customer_stripe_credentials`,
        method: 'GET',
        params: {token: token}
    };

    apiCall(null, config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};
export const loadWalletBalance = (token, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/stripe/get_wallet_balance`,
        method: 'GET',
        params: {token: token}
    };

    apiCall("Load customer payment methods", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, null, false);
};
export const prepayServices = (token, amount, card_token, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/stripe/prepaid_services_charge`,
        method: 'POST',
        params: {token: token, amount: amount, card_token: card_token}
    };

    apiCall(null, config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const acceptTerms = (proposalToken, callback) => async dispatch => {
    const config = {
        url: `/api/restricted/${proposalToken}/accept_terms`,
        method: 'PUT'
    };
    apiCall("Accepting terms and conditions", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch);
};

export const submit = (proposalToken, accepted, declined, signature, callback) => async dispatch => {
    const config = {
        url: `/api/restricted/${proposalToken}/submit`,
        method: 'PUT',
        data: {
            accepted: accepted.join(","),
            declined: declined.join(","),
            signature: signature
        },
    };
    apiCall("Updating proposal", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch);
};


export const addNote = (proposalToken, note, callback) => async dispatch => {
    const config = {
        url: `/api/restricted/${proposalToken}/add_note`,
        data: {
            note
        },
        method: 'PUT'
    };
    apiCall("Sending the message", config, async config => {
        const response = await axios.request(config);
        dispatch(getConversation(proposalToken, list => callback && callback(response.data, list)));
    }, dispatch);
};

export const addMessage = (token, message, callback) => async dispatch => {
    const config = {
        url: `/api/restricted/${token}/add_message`,
        data: {
            message
        },
        method: 'PUT'
    };
    apiCall("Sending the message", config, async config => {
        const response = await axios.request(config);
        dispatch(getConversation(token, list => callback && callback(response.data, list)));
    }, dispatch);
};


export const downloadPdf = (proposal, callback, errorCallback) => async (dispatch, getState) => {
    let config = {
        url: `/api/restricted/${proposal.token}/pdf`,
        method: 'GET',
        responseType: 'blob'
    };
    config = addAuthorizationHeader(config, getState);
    config = addTimeZoneHeader(config, getState);
    try {
        const response = await axios.request(config);
        const blob = new Blob([response.data], {
            type: 'application/pdf',
        });
        const fileName = `${proposal.download_name}-Proposal #${proposal.proposal_no}.pdf`
        blob.name = fileName
        dataUpload(`/api/download_history`, blob, null, null, {
            params: JSON.stringify(proposal ? proposal : {}),
            source: "proposal pdf"
        }, false)(dispatch, getState)
        FileSaver.saveAs(blob, fileName);
        callback && callback()
    } catch (exception) {
        dispatch(
            addAlert({
                message: "Unable to download pdf" + exception.toString(),
            })
        );
        errorCallback && errorCallback()
        throw exception
    }
};

export const downloadWOPdf = (wo_token, work_order_no, callback, errorCallback) => async (dispatch, getState) => {
    let config = {
        url: `/api/restricted/${wo_token}/wo_pdf`,
        method: 'GET',
        responseType: 'blob'
    };
    config = addAuthorizationHeader(config, getState);
    config = addTimeZoneHeader(config, getState);
    try {
        const response = await axios.request(config);
        const blob = new Blob([response.data], {
            type: 'application/pdf',
        });
        const fileName = `WO #${work_order_no}.pdf`
        blob.name = fileName
        dataUpload(`/api/download_history`, blob, null, null, {
            params: JSON.stringify({wo_token, work_order_no}),
            source: "work order pdf"
        }, false)(dispatch, getState)
        FileSaver.saveAs(blob, fileName);
        callback && callback()
    } catch (exception) {
        dispatch(
            addAlert({
                message: "Unable to download pdf" + exception.toString(),
            })
        );
        errorCallback && errorCallback()
        throw exception
    }
};

export const getConversation = (proposalToken, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/restricted/${proposalToken}/conversation`,
        method: 'GET'
    };

    apiCall("Getting conversation", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};


export const readUserMessages = (token, stickiesIds, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/restricted/${token}/read_user_message`,
        data: {stickiesIds},
        method: 'PUT'
    };
    apiCall("Mark all user messages as read", config, async config => {
        const response = await axios.request(config);
        callback()
    }, dispatch, getState, false);
};

export const setSelectedSite = (id, token, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/restricted/${token}/selected_site`,
        params: {id}
    };
    apiCall("Error from sites", config, async config => {
        const response = await axios.request(config);
        dispatch({type: SET_SELECTED_SITE, site: response.data})
        callback && callback(response.data)
    }, dispatch, getState, false);

}