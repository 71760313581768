import axios from "axios"
import {apiCall} from "../../common/apiActionHandler";
import {mapForSelect} from "../../common/commonHandlers";

export const findWorkOrders = (filter, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/work_orders/find_work_order`,
        params: filter
    };

    apiCall("Getting work orders", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const load = callback => (dispatch, getState) => {
    const config = [
        {url: `/api/reasons`},
        {url: `/api/new_services?bundle_service=false`},
        {url: `/api/priorities/search`},
        {url: `/api/equipments/search`},
        {url: `/api/sales_cross_view/service_types`}
    ];

    apiCall("Loading data", config, async config => {
        let actions = config.map(c => axios.request(c));
        const all = await axios.all(actions);
        callback({
            reasons: mapForSelect(all[0].data),
            serviceTypes: mapForSelect(all[4].data),
            services: mapForSelect(all[1].data),
            priorities: mapForSelect(all[2].data),
            equipments: mapForSelect(all[3].data)
        });
    }, dispatch, getState, false);
};
