import React, {PureComponent} from 'react';
import PropTypes from "prop-types"
import {GoogleMap, InfoWindow, Marker} from 'react-google-maps'
import {GoogleMapLoader} from "../../components/GoogleMapLoader";
import {Link} from "react-router";
import {getBasicAssetMarker, getBasicMarker} from "../../utilities";

const {DrawingManager} = require("react-google-maps/lib/components/drawing/DrawingManager");
const google = window.google

class Map extends PureComponent {
    static propTypes = {
        asset: PropTypes.object,
        siteAssets: PropTypes.array,
        editable: PropTypes.bool,
        onMarkerMouseOver: PropTypes.func.isRequired,
        onMarkerMouseOut: PropTypes.func.isRequired,
        onMarkerClick: PropTypes.func.isRequired,
        onMarkerDragEnd: PropTypes.func.isRequired,
        onMapDragEnd: PropTypes.func.isRequired,
        onMarkerAdd: PropTypes.func.isRequired,
    }

    state = {
        distance: undefined, position: undefined,
        polyLine: undefined, selectedAsset: null, drawingMode: null, duplicates: []
    };
    getZIndex = asset => {
        if (asset.__highlighted === true || asset.__locked === true) {
            //we injected this property to modify state on the next rerender - its slow. maybe we shoudl instead use setState?
            return this.props.siteAssets.length + 10
        } else {
            return this.props.siteAssets.findIndex(a => a.id === asset.id) + 1
        }

    }

    toggleInfoWindow = () => {
        this.state.polyLine.setMap(null)
        this.setState({distance: undefined})
    }

    onPolyLineComplete = polyLine => {
        let path = polyLine.getPath()
        let totalDistance = google.maps.geometry.spherical.computeLength(path)
        totalDistance *= 3.28084

        this.setState({
            distance: totalDistance.toFixed(2).toString() + ' ft.',
            position: {
                lat: path.getArray()[0].lat(),
                lng: path.getArray()[0].lng(),
            },
            polyLine: polyLine,
        })
    }

    finishDuplication = (callback) => {
        const duplicates = this.state.duplicates;
        const selectedAsset = this.state.selectedAsset;
        this.setState({duplicates: [], selectedAsset: null, editable: false, drawingMode: null, mode: null}, () => {
            duplicates.forEach(x => {
                google.maps.event.clearInstanceListeners(x);
                x.setMap(null);
            });
            callback && callback(duplicates, selectedAsset);
        })
    };

    render() {
        const {
            site,
            siteAssets,
            onMarkerClick,
            onMarkerDragEnd,
            onMapDragEnd,
            onMarkerAdd,
            onMarkersAdd
        } = this.props;
        let {selectedAsset, drawingMode, editable, mode, duplicates} = this.state;
        editable = editable || this.props.editable;
        if (site === undefined) {
            return <span>Map appears when a Site is selected</span>
        }

        return (
            <GoogleMapLoader
                containerElement={
                    <div
                        style={{
                            height: '100%',
                        }}
                    />
                }
                googleMapElement={
                    <GoogleMap
                        ref={it => (window._googleMapComponent = it)}
                        defaultZoom={site.default_zoom}
                        mapTypeId={site.map_type || 'hybrid'}
                        tilt={0}
                        defaultCenter={{lat: site.latitude, lng: site.longitude}}
                        onDragend={onMapDragEnd}
                    >
                        {editable &&
                            <DrawingManager
                                drawingMode={drawingMode}
                                defaultOptions={{
                                    drawingControl: true,
                                    drawingControlOptions: {
                                        position: google.maps.ControlPosition.TOP_CENTER,
                                        drawingModes: [
                                            'marker',
                                            google.maps.drawing.OverlayType.POLYLINE,
                                        ],
                                    },
                                    polylineOptions: {strokeColor: '#ff0000'},
                                }}
                                onMarkercomplete={marker => {
                                    if (mode === 'duplication') {
                                        duplicates.push(marker);
                                    } else {
                                        onMarkerAdd(marker);
                                    }
                                }}
                                onPolylinecomplete={this.onPolyLineComplete}
                            />
                        }

                        {this.state.distance
                            ? <InfoWindow
                                onCloseclick={this.toggleInfoWindow}
                                position={this.state.position}
                            >
                                {this.state.distance}
                            </InfoWindow>
                            : null}

                        {siteAssets.map(ass => (
                            <Marker
                                key={ass.id}
                                position={{lat: ass.latitude, lng: ass.longitude}}
                                icon={getBasicAssetMarker({
                                    asset: ass,
                                    label: ass.label
                                })}
                                zIndex={this.getZIndex(ass)}
                                title={
                                    ass.plant_name +
                                    (ass.plant_count > 1 ? ' [' + ass.plant_count + ']' : '')
                                }
                                draggable={editable}
                                onClick={() => this.setState({selectedAsset: ass.id})}
                                onDragend={e => onMarkerDragEnd(e, ass.id)}
                            >
                                {selectedAsset === ass.id && <InfoWindow className="info-window"
                                                                         onCloseclick={() => this.setState({selectedAsset: null})}>
                                    <div>
                                        {mode === 'duplication' &&
                                            <div>Creating duplicates of: <br/>
                                                <b>{ass.plant_name}</b><br/>
                                                <Link className="small-padding pointer" style={{color: '#1a664f'}}
                                                      onClick={() => {
                                                          this.finishDuplication((duplicates, selectedAsset) => {
                                                              onMarkersAdd(selectedAsset, duplicates);
                                                          });
                                                      }}>
                                                    Save
                                                </Link>
                                                <Link className="small-padding pointer" style={{color: 'red'}}
                                                      onClick={() => {
                                                          this.finishDuplication();
                                                      }}>
                                                    Cancel
                                                </Link>
                                            </div>}
                                        {mode !== 'duplication' &&
                                            <div>
                                                <b>#{ass.label}{" "}{ass.plant_name}</b><br/>
                                                <Link className="small-padding pointer" style={{color: 'red'}}
                                                      onClick={() => {
                                                          this.setState({selectedAsset: null});
                                                          onMarkerClick(ass.id);
                                                      }}>
                                                    <i className="fa fa-pencil bigger"/>
                                                </Link>
                                                <Link className="small-padding pointer" style={{color: 'red'}}
                                                      onClick={() => {
                                                          this.setState({
                                                              editable: true,
                                                              drawingMode: 'marker',
                                                              mode: 'duplication'
                                                          })
                                                      }
                                                      }
                                                >
                                                    Duplicate
                                                </Link>
                                            </div>}
                                    </div>
                                </InfoWindow>}
                            </Marker>
                        ))}

                        {siteAssets.length === 0 &&
                            <Marker
                                key={1}
                                position={{lat: site.latitude, lng: site.longitude}}
                                icon={getBasicMarker({selected: false, color: "#ff6479", label: "x"})}
                                title={site.name}
                            />}

                    </GoogleMap>
                }
            />
        )
    }
}

export default Map
