import {
    ADD_NEW_PRIORITY,
    DELETE_PRIORITY,
    EDIT_PRIORITY_FILTER_TEXT,
    EDITING_PRIORITY_ID,
    FETCH_PRIORITIES_LIST,
    FETCH_PRIORITY,
    UPDATE_PRIORITY_ATTR,
} from '../constants'
import {deleteKeyFromObject, insertKeyIntoObject, updateObjectAttribute,} from '../utilities'

const initialState = {
    prioritiesList: [],
    priorities: {},
    prioritiesListLastUpdated: 0,
    editingPriorityId: null,
    filterText: '',
}
const newPriority = () => {
    return {
        id: 0,
        name: 'New Priority',
        description: 'Description of it',
    }
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_PRIORITIES_LIST:
            return {
                ...state,
                prioritiesList: action.prioritiesList,
                prioritiesListLastUpdated: action.prioritiesListLastUpdated,
            }
        case FETCH_PRIORITY:
            return {
                ...state,
                priorities: insertKeyIntoObject(
                    state.priorities,
                    'priorities',
                    action.priorities
                ),
            }
        case UPDATE_PRIORITY_ATTR:
            return {
                ...state,
                priorities: updateObjectAttribute(
                    state.priorities,
                    'priorities',
                    action.id,
                    action.attr,
                    action.val
                ),
            }
        case ADD_NEW_PRIORITY:
            return {
                ...state,
                priorities: insertKeyIntoObject(
                    state.priorities,
                    'priorities',
                    newPriority()
                ),
            }
        case DELETE_PRIORITY:
            return {
                ...state,
                priorities: deleteKeyFromObject(
                    state.priorities,
                    'priorities',
                    action.id
                ),
            }
        case EDITING_PRIORITY_ID:
            return {...state, editingPriorityId: action.editingPriorityId}
        case EDIT_PRIORITY_FILTER_TEXT:
            return {...state, filterText: action.filterText}
        default:
            return state
    }
}
