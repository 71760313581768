import React from "react"
import PropTypes from "prop-types"

const PageTitle = ({
                       pageName = "New Page",
                       pageDetail = "page description",
                       pageLogo = ''
                   }) => <h3>{pageLogo ? <img src={pageLogo} alt='Logo' height={40}/> : pageName}
    <small>{pageDetail}</small></h3>

PageTitle.propTypes = {
    pageName: PropTypes.string.isRequired,
    pageDetail: PropTypes.string.isRequired,
}

export default PageTitle
