import React from 'react'
import {Circle, GoogleMap, InfoWindow, Marker, Polygon, Polyline, Rectangle} from "react-google-maps";
import ResourceComponent from "../../components/ResourceComponent";
import {checkRectangleCoordinates, colors, defaultMapParams} from "../../common/commonHandlers";
import {getLabel} from "../MapViewPage/IconProvider";
import {GoogleMapLoader} from "../../components/GoogleMapLoader";
import PropTypes from "prop-types"
import {getBasicAssetMarker} from "../../utilities";
import {Col, Row} from "react-bootstrap";
import PlantName from "../../components/PlantName";

class AssetsMap extends ResourceComponent {

    state = {
        selectedAsset: null,
        assets: []
    }

    setShapeOpacity = (ass) => {
        const polygon = ass.polygons.find(p => p.chosen)

        if (polygon.type_polygon === 'polyLine') {
            return 0.75
        } else {
            return 0.5
        }
    }

    markerPolygon = (ass) => {
        const polygon = ass.polygons.find(p => p.chosen)
        const points = polygon.polygon_points
        let averageLat = (polygon.type_polygon !== 'circle' && polygon.type_polygon !== 'polyLine')
            ? points.reduce((previousValue, currentValue, i) => {
            if (currentValue.lat) {
                if (i === 1) {
                    return previousValue.lat + currentValue.lat
                }
                return previousValue + currentValue.lat
            } else {
                if (i === 1) {
                    return previousValue.latitude + currentValue.latitude
                }
                return previousValue + currentValue.latitude
            }
        }) / points.length : ass.lat
        let averageLng = (polygon.type_polygon !== 'circle' && polygon.type_polygon !== 'polyLine')
            ? points.reduce((previousValue, currentValue, i) => {
            if (currentValue.lng) {
                if (i === 1) {
                    return previousValue.lng + currentValue.lng
                }
                return previousValue + currentValue.lng
            } else {
                if (i === 1) {
                    return previousValue.longitude + currentValue.longitude
                }
                return previousValue + currentValue.longitude
            }
        }) / points.length : ass.lng
        if (polygon.type_polygon === 'polyLine') {
            const index = Math.round(points.length / 2) - 1
            if (points.length % 2 !== 0) {
                averageLat = points[index].lat ? points[index].lat : points[index].latitude
                averageLng = points[index].lng ? points[index].lng : points[index].longitude
            } else {
                if (points[0].lat) {
                    averageLat = (points[index].lat + points[index + 1].lat) / 2
                    averageLng = (points[index].lng + points[index + 1].lng) / 2
                } else {
                    averageLat = (points[index].latitude + points[index + 1].latitude) / 2
                    averageLng = (points[index].longitude + points[index + 1].longitude) / 2
                }
            }

        }
        return (
            <Marker
                position={{lat: averageLat, lng: averageLng}}
                icon={getLabel(ass.asset_label)}
                strokeColor={"#ffffff"}
                zIndex={1000}
            >
                {this.infoWindow(ass, averageLat, averageLng)}
            </Marker>
        )
    }

    onMarkerClick = (ass) => {
        this.setState({selectedAsset: ass.asset_number});
    };

    infoWindow = (ass, lat, lng) => {

        if (this.state.selectedAsset === ass.asset_number) {
            return (
                <InfoWindow
                    key={this.state.selectedAsset}
                    position={{lat: lat ? lat : ass.latitude, lng: lng ? lng : ass.longitude}}
                    onCloseclick={() => this.setState({selectedAsset: null})}>
                    <div className="info-window no_white-space">
                        <div>
                            <Row>
                                {this.props.proposal?.print_options?.assetPhotosCustomerView && ass.photos.length > 0 &&
                                    <Col md={4}>
                                        {ass.photos.filter(p => p.cwo_and_proposal_display === true).sort((a, b) => a.order - b.order).slice(0, 1).map(i =>
                                            <span className="text-success pointer"
                                                  onClick={() => this.showPhotoPopUp(ass)}><img width={50}
                                                                                                alt="tree"
                                                                                                src={i.url}/></span>)}
                                    </Col>}
                                <Col md={8}>
                                    <b>#{ass.asset_label}{" "}<PlantName plant={ass.name}/></b><br/>
                                    {ass.dbh ? `DBH: ${ass.dbh}"` : ""}
                                    {ass.dbh ? <br/> : null}
                                </Col>
                            </Row>
                        </div>
                    </div>
                </InfoWindow>
            )
        }
    }

    render() {
        const {assets, center} = this.props;
        let mapParams = []
        assets.forEach(asset => {
            let param = {};
            param.longitude = asset.longitude;
            param.latitude = asset.latitude;
            mapParams.push(param);
        });
        return assets && (
            <>
                <GoogleMapLoader
                    containerElement={
                        <div
                            id="sitessMap"
                        />
                    }
                    googleMapElement={
                        <GoogleMap
                            ref={it => this.refGoogleMap(it, mapParams.filter(param => param.latitude))}
                            {...defaultMapParams(mapParams.filter(param => param.latitude), center)}
                        >
                            {assets && assets.map((ass, i) => {
                                const polygon = ass.polygons.find(p => p.chosen)

                                if (polygon && polygon.type_polygon !== 'marker') {
                                    const color = polygon.color ? colors[polygon.color] : colors[parseInt(ass.plant_color)]
                                    const highlighted = colors[51]
                                    const opacity = this.setShapeOpacity(ass)

                                    if (polygon.type_polygon === 'circle') {
                                        return (<><Circle
                                            onClick={() => {
                                                this.onMarkerClick(ass)
                                            }}
                                            defaultOpacity={1}
                                            defaultCenter={{
                                                lat: polygon.polygon_points[0].lat,
                                                lng: polygon.polygon_points[0].lng
                                            }}
                                            editable={false}
                                            label={ass.asset_label}
                                            options={{
                                                fillOpacity: opacity,
                                                strokeColor: color,
                                                fillColor: color
                                            }}
                                            radius={polygon.radius}
                                            key={ass.number}
                                        />
                                            {this.markerPolygon(ass)}
                                        </>)
                                    } else if (polygon.type_polygon === 'polyLine') {
                                        return (<><Polyline
                                            onClick={() => {
                                                this.onMarkerClick(ass)
                                            }}
                                            path={polygon.polygon_points}
                                            key={ass.number}
                                            label={ass.asset_label}
                                            options={{
                                                strokeOpacity: opacity,
                                                strokeColor: color,
                                                fillColor: color
                                            }}
                                            editable={false}
                                        />
                                            {this.markerPolygon(ass)}
                                        </>)
                                    } else if (polygon.type_polygon === 'rectangle' && checkRectangleCoordinates(polygon)) {
                                        return (<><Rectangle
                                            onClick={() => {
                                                this.onMarkerClick(ass)
                                            }}
                                            bounds={{
                                                north: polygon.polygon_points[0].lat,
                                                south: polygon.polygon_points[3].lat,
                                                east: polygon.polygon_points[1].lng,
                                                west: polygon.polygon_points[0].lng
                                            }}
                                            key={ass.number}
                                            label={ass.asset_label}
                                            options={{
                                                fillOpacity: opacity,
                                                strokeColor: color,
                                                fillColor: color
                                            }}
                                            editable={false}
                                        />
                                            {this.markerPolygon(ass)}
                                        </>)
                                    } else {
                                        return (<>
                                            <Polygon
                                                onClick={() => {
                                                    this.onMarkerClick(ass)
                                                }}
                                                path={polygon.polygon_points}
                                                key={ass.number}
                                                label={ass.asset_label}
                                                options={{
                                                    fillOpacity: opacity,
                                                    strokeColor: color,
                                                    fillColor: color
                                                }}
                                                editable={false}
                                            />
                                            {this.markerPolygon(ass)}
                                        </>)
                                    }
                                } else {
                                    return (
                                        <Marker
                                            key={ass.number}
                                            position={{lat: ass.latitude, lng: ass.longitude}}
                                            zIndex={null}
                                            icon={getBasicAssetMarker({
                                                asset: ass,
                                                highlighted: this.state.selectedAsset && this.state.selectedAsset === ass.asset_number,
                                                label: ass.asset_label
                                            })}
                                        >
                                            {this.infoWindow(ass)}
                                        </Marker>

                                    )
                                }
                            })}
                        </GoogleMap>
                    }
                />
            </>)
    }
}

AssetsMap.propTypes = {
    assets: PropTypes.array.isRequired,
}

export default AssetsMap;
