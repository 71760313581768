import {FETCH_SEARCH_RESULTS} from './constants'


const initialState = {
    searchResults: []
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_SEARCH_RESULTS:
            return {...state, searchResults: action.searchResults}
        default:
            return state
    }
}

export default reducer