import axios from "axios/index";
import {apiCall} from "../../../common/apiActionHandler";
import {mapForSelect} from "../../../common/commonHandlers";

export const getAuthUrl = (id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/clients/${id}/authorization_url`,
        method: 'GET'
    };
    apiCall("Google Calendar Authorization", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const fetchColors = (token, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/embedded_form/form_colors`,
        method: 'GET',
        params: {
            token: token
        }
    };
    apiCall("Loading embedded colors", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);

};

export const syncCalendar = (id) => (dispatch, getState) => {
    const config = {
        url: `/api/clients/${id}/sync_calendar`,
        method: 'POST'
    };
    apiCall("Google Calendar Sync", config, async config => {
        await axios.request(config);
    }, dispatch, getState);
};

export const updateClient = (client, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/clients/${client.id}`,
        method: 'PUT',
        data: client
    };
    apiCall("Updating client", config, async config => {
        await axios.request(config);
        callback && callback();
    }, dispatch, getState);
};


export const createTestEvent = (id) => (dispatch, getState) => {
    const config = {
        url: `/api/clients/${id}/create_test_event`,
        method: 'POST'
    };
    apiCall("Google Calendar test", config, async config => {
        await axios.request(config);
    }, dispatch, getState);
};

export const deleteToken = (id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/clients/${id}/delete_gcal_token`,
        method: 'DELETE'
    };
    apiCall("Google Calendar Deletion", config, async config => {
        await axios.request(config);
        callback();
    }, dispatch, getState);
};

export const getStripeCredentials = (authorizationCode) => (dispatch, getState) => {
    const config = {
        url: `/api/stripe/stripe_callback`,
        method: 'POST',
        data: {
            code: authorizationCode
        }
    };
    apiCall("Getting stripe credentials", config, async config => {
        await axios.request(config);
    }, dispatch, getState);
};

export const loadStripeCredentials = (client_id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/stripe/stripe_credentials`,
        method: 'GET',
        params: {
            client_id: client_id
        }
    };
    apiCall("Loading stripe credentials", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};

export const disconnectStripe = (callback) => (dispatch, getState) => {
    const config = {
        url: `/api/stripe/disconnect_stripe`,
        method: 'POST'
    };
    apiCall("Disconnecting stripe", config, async config => {
        await axios.request(config);
        callback();
    }, dispatch, getState);
};

export const connectWithQuickbooks = (callback) => (dispatch, getState) => {
    const config = {
        url: `/api/quickbooks/connect`,
        method: 'GET'
    };
    apiCall("Connecting with quickbooks", config, async config => {
        const response = await axios.request(config);
        callback(response);
    }, dispatch, getState, false);
};

export const getQuickbooksCredentials = (quickbooksCredentials, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/quickbooks/quickbooks_callback`,
        method: 'POST',
        data: quickbooksCredentials
    };
    apiCall("Getting quickbooks credentials", config, async config => {
        await axios.request(config);
        callback && callback();
    }, dispatch, getState);
};

export const loadQuickbooksCredentials = (client_id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/quickbooks/quickbooks_credentials`,
        method: 'GET',
        params: {
            client_id: client_id
        }
    };
    apiCall("Loading quickbooks credentials", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};

export const disconnectQuickbooks = (callback) => (dispatch, getState) => {
    const config = {
        url: `/api/quickbooks/revoke_access`,
        method: 'POST'
    };
    apiCall("Disconnecting quickbooks", config, async config => {
        await axios.request(config);
        callback();
    }, dispatch, getState);
};

export const syncQuickbooks = (callback) => (dispatch, getState) => {
    const config = {
        url: `/api/quickbooks/sync_all`,
        method: 'PUT'
    };
    apiCall("Quickbooks Sync", config, async config => {
        await axios.request(config);
        callback && callback();
    }, dispatch, getState);
};

export const getQbAccount = (service_id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/quickbooks/get_account`,
        params: {id: service_id}
    };

    apiCall("Searching for quickbooks account", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};


export const saveQbSettings = (income_account_id, auto_qb_sync) => (dispatch, getState) => {
    const config = {
        url: `/api/quickbooks/save_qb_settings`,
        params: {income_account_id: income_account_id, auto_qb_sync: auto_qb_sync},
        method: 'PUT'
    };

    apiCall("Quickbooks settings saved", config, async config => {
        const response = await axios.request(config);
    }, dispatch, getState, true);
};

export const searchForQbAccount = (searchText, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/quickbooks/search_for_account`,
        params: {search_text: searchText}
    };

    apiCall("Searching for quickbooks accounts", config, async config => {
        const response = await axios.request(config);
        callback(mapForSelect(response.data));
    }, dispatch, getState, false);
};

export const getSyncCalendar = (id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/clients/${id}/get_sync_calendar`,
    };
    apiCall("Google Calendar Sync", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data)
    }, dispatch, getState);
};
