import React, {useState} from "react";
import {Button, Col, Row} from "react-bootstrap";
import DateRangeFilter from "../../../components/DateRangeFilter";
import moment from "moment";
import {defaultDateFormat, select} from "../../../common/commonHandlers";
import Select from "react-select";

const DefaultReportView = ({title, options, getRevenueReport, children}) => {
    const [from, setFrom] = useState(null)
    const [to, setTo] = useState(null)
    const [reportType, setReportType] = useState("")

    const onChangeReportType = selected => setReportType(selected.value)

    const handleRangeDateChange = (start, end) => {
        setFrom(start)
        setTo(end)
    }

    return <Col xs={12}>
        <h1>{title}</h1>
        <p><b>You can customize reports by selecting date range and report type. If no date range is selected, a
            lifetime report will be generated by default.</b></p>
        <Row>
            <Col md={6} xs={12} className='mb-15'>
                <DateRangeFilter
                    customClassName="customer-DateRangeFilter"
                    promisedStart={from ? moment(from) : null}
                    promisedEnd={to ? moment(to) : null}
                    upsertPromise={(id, start, end) => handleRangeDateChange(start, end)}
                    serviceNo={null}
                    index={1}
                    timeDelay={1}
                    format={defaultDateFormat}
                />
            </Col>
            <Col md={3} xs={6}>
                <Select className="Select" classNamePrefix="select"
                        placeholder="Report type"
                        simpleValue={true}
                        value={select(options, reportType)}
                        onChange={onChangeReportType}
                        options={options}
                />
            </Col>
            <Col md={3} xs={6}>
                <Button disabled={!reportType} onClick={() => getRevenueReport({from, to, report_type: reportType})}>
                    Generate Report
                </Button>
            </Col>
            {children}
        </Row>
    </Col>
}

export default DefaultReportView;