import React, {useState} from 'react';
import classNames from "classnames";
import moment from "moment";
import {LinkContainer} from "react-router-bootstrap";
import {Button, Col, Row} from "react-bootstrap";
import TextareaAutosize from "react-textarea-autosize";

const CustomerMessages = ({messages, sendMessage, reload}) => {
    const iPadPro = (window.screen.widthwindow == 1024 && window.screen.height == 1366);
    const isMobile = (window.screen.width <= 1024 || iPadPro);
    const [replyToken, setReplyToken] = useState('')
    const [replyText, setReplyText] = useState('')

    return (
        <>
            {messages.map((proposalMessages, i) => {
                return (
                    <>
                        <div key={i}
                             className={classNames('messages-container', {'few-messages': proposalMessages.messages.length <= 1})}>
                            <div className='message-header'>
                                <p>
                                    Proposal:
                                    <LinkContainer to={`/customer_view/${proposalMessages.proposal_token}`}>
                                        <span> {proposalMessages.proposal_no} </span>
                                    </LinkContainer>
                                </p>
                                <span onClick={() => setReplyToken(proposalMessages.proposal_token)}>Reply</span>
                            </div>
                            <div className='message-wrapper'>
                                <div className='message-container'>
                                    {(replyToken === proposalMessages.proposal_token) && <Row>
                                        <Col xs={8}>
                                            <TextareaAutosize
                                                name={`reply_area`}
                                                value={replyText}
                                                minRows={3}
                                                maxRows={10}
                                                placeholder="Your note"
                                                className="sticky-textarea align-bottom"
                                                onChange={(e) => setReplyText(e.target.value)}
                                            />
                                        </Col>
                                        <Col xs={4}>
                                            <Button type="button" bsStyle="success"
                                                    onClick={() => sendMessage(replyToken, replyText, () => {
                                                        setReplyText('')
                                                        reload()
                                                    })}
                                                    className="align-bottom sticky-button">Submit</Button>
                                        </Col>
                                    </Row>}
                                    {proposalMessages.messages.map(message => {
                                        return (
                                            <div
                                                className={classNames('message', {'is-author': message.is_author}, {'message--phone': isMobile})}>
                                                <div className='date'>
                                                    <p>{moment(message.created_at).format('llll')}</p>
                                                </div>
                                                <div className='sender'>
                                                    <p>{message.created_by}</p>
                                                </div>
                                                <div className='message-body'>
                                                    <p>{message.body}</p>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </>
                )
            })}
        </>
    );
};

export default CustomerMessages;