import React from 'react'
import PropTypes from "prop-types"

const LabelWithText = ({label, text}) => {
    return <>
        <span className="font-weight-bold">{label}: </span>{text}<br/>
    </>
}

LabelWithText.propTypes = {
    dateString: PropTypes.string,
}

export default LabelWithText
