import {FETCH_REFERALLS_LIST,} from '../constants'

import axios from 'axios'

// TODO: deprecated
export const fetchReferallsList = () => (dispatch, getState) => {
    const store = getState();
    const config = {
        url: '/api/referrals?disabled=false',
        headers: {Authorization: store.auth.token},
    };

    return axios.request(config).then(response => {
        dispatch({
            type: FETCH_REFERALLS_LIST,
            referallsList: response.data,
            referallsListLastUpdated: Date.now(),
        })
    })
};
