import {Col, Modal, Row} from "react-bootstrap";
import React, {Component} from "react";
import Button from "react-bootstrap/lib/Button";

export default class TaskDetailsModal extends Component {

    constructor(props) {
        super(props);
        this.dateFromFormatter = this.props.formatters.dateFromFormatter
        this.nameFormatter = this.props.formatters.nameFormatter
        this.customerFormatter = this.props.formatters.customerFormatter
        this.siteFormatter = this.props.formatters.siteFormatter
        this.leadFormatter = this.props.formatters.leadFormatter
        this.proposalFormatter = this.props.formatters.proposalFormatter
        this.workOrderFormatter = this.props.formatters.workOrderFormatter
        this.statusCheckboxFormatter = this.props.formatters.statusCheckboxFormatter
        this.statusFormatterMobile = this.props.formatters.statusFormatterMobile
        this.state = {
            event: {}
        }
    }

    componentDidMount() {
        this.setState({event: this.props.event})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.event !== this.props.event) {
            this.setState({event: this.props.event})
        }
    }

    render() {
        const {event} = this.state
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                className="heightAuto maxHeight90">
                <Modal.Header>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="mt-15 mb-15">
                    <div>
                        <Row>
                            <Col xs={6} md={6}>#</Col>
                            <Col xs={6} md={6}>{event.task_number}</Col>
                        </Row>
                        <Row>
                            <Col xs={6} md={6}>Task Date</Col>
                            <Col xs={6} md={6}>{this.dateFromFormatter(event.date_from)}</Col>
                        </Row>
                        <Row>
                            <Col xs={6} md={6}>Name</Col>
                            <Col xs={6} md={6}>{this.nameFormatter(null, event, true)}</Col>
                        </Row>
                        <Row>
                            <Col xs={6} md={6}>Type</Col>
                            <Col xs={6} md={6}>{event.scheduler_event_type_name}</Col>
                        </Row>
                        <Row>
                            <Col xs={6} md={6}>Assignee</Col>
                            <Col xs={6} md={6}>{event.person_name}</Col>
                        </Row>
                        <Row>
                            <Col xs={6} md={6}>Customer</Col>
                            <Col xs={6} md={6}>{this.customerFormatter(null, event, true)}</Col>
                        </Row>
                        <Row>
                            <Col xs={6} md={6}>Location</Col>
                            <Col xs={6} md={6}>{this.siteFormatter(null, event)}</Col>
                        </Row>
                        <Row>
                            <Col xs={6} md={6}>Lead #</Col>
                            <Col xs={6} md={6}>{this.leadFormatter(null, event)}</Col>
                        </Row>
                        <Row>
                            <Col xs={6} md={6}>Proposal #</Col>
                            <Col xs={6} md={6}>{this.proposalFormatter(null, event)}</Col>
                        </Row>
                        <Row>
                            <Col xs={6} md={6}>WO #</Col>
                            <Col xs={6} md={6}>{this.workOrderFormatter(null, event)}</Col>
                        </Row>
                        <Row>
                            <Col xs={6} md={6}>Invoice #</Col>
                            <Col xs={6} md={6}>{event.invoice_no}</Col>
                        </Row>
                        <Row>
                            <Col xs={6} md={6}>Hours</Col>
                            <Col xs={6} md={6}>{event.service_hours}</Col>
                        </Row>
                        <Row>
                            <Col xs={6} md={6}>Status</Col>
                            <Col xs={6} md={6}>{this.statusFormatterMobile(null, event)}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>{this.statusCheckboxFormatter(null, event)}</Col>
                        </Row>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="button" onClick={this.props.onHide}
                            className="btn btn-default">
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}