import React, {Component} from "react";
import {cancelablePromise, delay} from "../utilities";

const stopTriggeringClickOnDoubleClick = (WrappedComponent) => {
    class HOC extends Component {
        componentWillUnmount() {
            this.clearPendingPromises();
        }

        pendingPromises = [];

        appendPendingPromise = promise =>
            (this.pendingPromises = [...this.pendingPromises, promise]);

        removePendingPromise = promise =>
            (this.pendingPromises = this.pendingPromises.filter(p => p !== promise));

        clearPendingPromises = () => this.pendingPromises.map(p => p.cancel());

        handleClick = (callback) => {
            const waitForClick = cancelablePromise(delay(200));
            this.appendPendingPromise(waitForClick);
            return waitForClick.promise
                .then(() => {
                    this.removePendingPromise(waitForClick);
                    callback && callback()
                })
                .catch(errorInfo => {
                    this.removePendingPromise(waitForClick);
                    if (!errorInfo.isCanceled) {
                        throw errorInfo.error;
                    }
                });

        };

        handleDoubleClick = (callback) => {
            this.clearPendingPromises();
            callback && callback()
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    onClick={this.handleClick}
                    onDoubleClick={this.handleDoubleClick}
                />
            )
        }
    }

    return HOC
}

export default stopTriggeringClickOnDoubleClick