import axios from "axios/index";
import {apiCall} from "../../common/apiActionHandler";
import {mapForSelect, optionsFor} from "../../common/commonHandlers";
import * as FileSaver from "file-saver";
import moment from "moment";
import {dataUpload} from "../../common/upload";

export const getLeads = (filter, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/leads`,
        params: filter
    };

    apiCall("Getting leads", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const searchForCustomer = (searchText, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/customers/name_zip_city_search`,
        params: {q: searchText}
    };

    apiCall("Searching for customers", config, async config => {
        const response = await axios.request(config);
        callback(mapForSelect(response.data));
    }, dispatch, getState, false);
};

export const load = callback => (dispatch, getState) => {
    const config = [
        {url: `/api/search/all_employees`},
        {url: `/api/referrals`, params: {disabled: false}},
        {url: `/api/customer_types`},
        {url: '/api/service_types'},
        {url: '/api/services/info'},
        {url: '/api/asset_types'},
        {url: `/api/search/all_assets`},
        {url: `/api/reasons`, params: {disabled: false}},
    ];

    apiCall("Loading data", config, async config => {
        let actions = config.map(c => axios.request(c));
        const all = await axios.all(actions);
        callback({
            employees: all[0].data,
            referrals: mapForSelect(all[1].data),
            customerTypes: mapForSelect(all[2].data),
            serviceTypes: mapForSelect(all[3].data),
            services: mapForSelect(all[4].data),
            assetTypes: mapForSelect(all[5].data),
            assets: all[6].data.map((val) => ({label: val.name, value: val.id})),
            reasons: mapForSelect(all[7].data),
        });
    }, dispatch, getState, false);
};


export const remove = (id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/leads/${id}`,
        method: 'DELETE'
    };

    apiCall("Deleting lead", config, async config => {
        await axios.request(config);
        callback();
    }, dispatch, getState);
};

export const searchForCity = (searchText, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/sites/city_search`,
        params: {q: searchText}
    };

    apiCall("Searching for city", config, async config => {
        const response = await axios.request(config);
        callback(optionsFor(...response.data.map(x => x.city)));
    }, dispatch, getState, false);
};

export const searchForAsset = (searchText, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/plants/plant_search`,
        params: {q: searchText}
    };

    apiCall("Searching for assets", config, async config => {
        const response = await axios.request(config);
        callback(mapForSelect(response.data));
    }, dispatch, getState, false);
};

export const searchForZip = (searchText, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/sites/zip_search`,
        params: {q: searchText}
    };

    apiCall("Searching for zip", config, async config => {
        const response = await axios.request(config);
        callback(optionsFor(...response.data.map(x => x.zip)));
    }, dispatch, getState, false);
};

export const downloadCsv = (params) => async (dispatch, getState) => {
    const config = {
        url: `/api/leads/csv`,
        params
    };

    apiCall("Downloading csv", config, async config => {
        const response = await axios.request(config);
        const blob = new Blob([response.data], {
            type: 'text/csv',
        });
        FileSaver.saveAs(blob, `leads-${moment().format()}.csv`);

        blob.name = `leads-${moment().format()}.csv`
        dataUpload(`/api/download_history`, blob, null, null, {
            params: JSON.stringify(params ? params : {}),
            source: "leads"
        }, false)(dispatch, getState)
    }, dispatch, getState);
};

export const loadCustomerInfo = (customerId, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api//customers/${customerId}`,
        method: 'GET'
    };

    apiCall("Loading customer", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const showDuplicates = (value, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/customers/search_duplicates`,
        params: {...value}
    };

    apiCall("Show Duplicates", config, async config => {
        const result = await axios.request(config);
        callback(result.data);
    }, dispatch, getState, false);
};
export const mergeCustomer = (customer_id, merging_customer_id, callback) => (dispatch, getState) => {
    const config = {
        method: 'POST',
        url: `/api/customers/merge_customer`,
        data: {customer_id, merging_customer_id}
    };

    apiCall("Show Duplicates", config, async config => {
        const result = await axios.request(config);
        callback(result.data);
    }, dispatch, getState, false);
};

export const deleteLead = (lead_id, callback) => (dispatch, getState) => {
    const config = {
        method: 'DELETE',
        url: `/api/leads/${lead_id}`,
    };

    apiCall("Lead Deleted", config, async config => {
        const result = await axios.request(config);
        callback(result.data);
    }, dispatch, getState, false);
};
export const setAsUnique = (lead_id, callback) => (dispatch, getState) => {
    const config = {
        method: 'PUT',
        url: `/api/leads/${lead_id}/set_as_unique`,
    };

    apiCall("Lead Updated", config, async config => {
        const result = await axios.request(config);
        callback(result.data);
    }, dispatch, getState, false);
};