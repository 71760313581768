export const [
    UPDATE_PROPOSAL_NO_SEARCH_TEXT,
    STATUS_FETCH_PROPOSAL_INFO,
    STATUS_FETCH_PS_INFO,
    FETCH_PROPOSAL_SERVICE_STATUSES,
    SELECT_DESTINATION_STATUS_CODE,
    STATUS_SELECT_PROPOSAL_SERVICE,
    STATUS_UN_SELECT_PROPOSAL_SERVICE,
    STATUS_UPDATE_NEW_PROPOSAL_ACCEPTED_DATE,
    UPDATE_PROPOSAL_DATE,
] = [
    'UPDATE_PROPOSAL_NO_SEARCH_TEXT',
    'STATUS_FETCH_PROPOSAL_INFO',
    'STATUS_FETCH_PS_INFO',
    'FETCH_PROPOSAL_SERVICE_STATUSES',
    'SELECT_DESTINATION_STATUS_CODE',
    'STATUS_SELECT_PROPOSAL_SERVICE',
    'STATUS_UN_SELECT_PROPOSAL_SERVICE',
    'STATUS_UPDATE_NEW_PROPOSAL_ACCEPTED_DATE',
    'UPDATE_PROPOSAL_DATE',
]
