import React from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {Button, Col, Row, Well} from 'react-bootstrap'
import './Conversation.css'
import TextareaAutosize from "react-textarea-autosize";
import Sticky from "./contents/Sticky";
import ResourceComponent from "../../components/ResourceComponent";
import {bindActionCreators} from "redux";


class Conversation extends ResourceComponent {
    state = {resource: {note: ''}};

    submitNewStickyForm = (e) => {
        e.preventDefault();
        this.props.save(this.state.resource.note, () => {
            this.setState({resource: {note: ''}});
        });
    };

    render() {
        const {stickies, children} = this.props;

        return (
            <div className="conversation-wrapper">
                <section>
                    {children}
                    <p>&nbsp;</p>
                    {
                        stickies.map(sticky =>
                            <Well key={sticky.id}>
                                <Sticky sticky={sticky}/>


                                {sticky.replies.map(reply => <Sticky sticky={reply} key={reply.id}/>)}
                            </Well>)
                    }
                </section>
                <footer className="conversation-footer">
                    <form onSubmit={this.submitNewStickyForm}>
                        <Row>
                            <Col xs={8}>
                                <TextareaAutosize
                                    name="note"
                                    value={this.state.resource.note}
                                    minRows={3}
                                    maxRows={10}
                                    placeholder="Your note"
                                    className="conversation-textarea align-bottom"
                                    onChange={this.updateResourceAttr}
                                />
                            </Col>
                            <Col xs={4}>
                                <Button type="submit" bsStyle="success"
                                        className="align-bottom conversation-button">Submit</Button>
                            </Col>
                        </Row>
                    </form>
                </footer>
            </div>
        );
    }
}

Conversation.propTypes = {
    children: PropTypes.object,
    stickies: PropTypes.array.isRequired,
    save: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({}, dispatch)
    };
}

export default connect(undefined, mapDispatchToProps)(Conversation)
