import {Button, Col, ControlLabel, FormControl, Glyphicon, Label, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import ColorCheckbox from "../../../Scheduler/ColorCheckbox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import DefaultAnswerFormatter from "../DefaultAnswerFormatter";
import './CheckboxAnswer.scss'

const CheckboxAnswer = ({number, question, value, onChange, options, setAnswerOptions, preview, disabled}) => {
    const checkboxOptions = () => {
        return <Row>
            <Col xs={12}>
                <ColorCheckbox value={options.multiSelect}
                               label="Enable multi options"
                               onChange={() => setAnswerOptions({
                                   ...options,
                                   multiSelect: !options.multiSelect
                               })}
                />
            </Col>
            <Col xs={12}>
                <ColorCheckbox value={options.answersHorizontally}
                               label="Answers horizontally"
                               onChange={() => setAnswerOptions({
                                   ...options,
                                   answersHorizontally: !options.answersHorizontally,
                               })}
                />
            </Col>
            <Col xs={12}>
                <ColorCheckbox value={options.required}
                               label="Answer will be required"
                               onChange={() => setAnswerOptions({
                                   ...options,
                                   required: !options.required
                               })}
                />
            </Col>
            {options.checkboxOptions.map(option => {
                const label = `Option ${option.id}`
                return <Col xs={12}>
                    <Row className="mt10">
                        <Col xs={12}><Label bsStyle="info">{label}</Label></Col>
                        <Col xs={12} className="flex-center">
                            <FormControl
                                type="text"
                                placeholder={label}
                                onChange={(e) => {
                                    const newOptions = options.checkboxOptions.map(o => {
                                        if (o.id === option.id) {
                                            o.label = e.target.value
                                            return o
                                        } else {
                                            return o
                                        }
                                    })
                                    setAnswerOptions({
                                        ...options,
                                        checkboxOptions: newOptions,
                                    })
                                }}
                                value={option.label}
                            />
                            <FontAwesomeIcon
                                icon="trash-alt"
                                className="trash-icon"
                                onClick={() => {
                                    let newOptions = options.checkboxOptions.filter(o => o.id !== option.id)
                                    newOptions = newOptions.map((o, i) => {
                                        o.id = i + 1
                                        return o
                                    })
                                    setAnswerOptions({
                                        ...options,
                                        checkboxOptions: newOptions,
                                    })
                                }}
                            />
                        </Col>
                    </Row>
                </Col>
            })}
            <Col xs={12}>
                <Button
                    className="pointer mt-15 mb-15 full-width"
                    bsSize="small"
                    bsStyle="success"
                    onClick={() => {
                        if (options.checkboxOptions?.every(q => q.label && q.label.length > 0)) {
                            setAnswerOptions({
                                ...options,
                                checkboxOptions: options.checkboxOptions.concat({
                                    id: options.checkboxOptions.length + 1,
                                    label: ''
                                }),
                            })
                        }
                    }}
                >
                    Add next option {" "}<FontAwesomeIcon icon="plus" className="small-margin"/>
                </Button>
            </Col>
        </Row>
    }

    const checkboxAnswers = () => {
        return <div id="checkbox-answer-type">
            <ControlLabel className="bold">
                <span>{number}. {question}</span>
                {options.required && <OverlayTrigger
                    placement='top'
                    overlay={<Tooltip id={`tooltip-refresh`}>Answer is required</Tooltip>}
                >
                    <Glyphicon glyph='exclamation-sign' className="ml-6 font14"/>
                </OverlayTrigger>}
            </ControlLabel>
            <div
                className={`checkbox-options-wrapper ${options.answersHorizontally && 'horizontally-wrapper'} ${!options.answersHorizontally && 'vertically-wrapper'}`}>
                {options.checkboxOptions.map((o, i) => {
                    return <div
                        className={`${options.answersHorizontally ? 'horizontally' : ''} ${!options.answersHorizontally ? 'vertically' : ''}`}
                        style={{width: options.answersHorizontally ? `${(1 / options.checkboxOptions.length) * 100}%` : 'auto'}}
                    >
                        <input
                            id={`${i}-${o.label}`}
                            type="checkbox"
                            checked={value.includes(o.id)}
                            disabled={disabled}
                            onClick={(e) => {
                                if (options.multiSelect) {
                                    if (e.target.checked) {
                                        value = value.concat(o.id)
                                    } else {
                                        value = value.filter(v => v !== o.id)
                                    }
                                } else {
                                    if (e.target.checked) {
                                        value = [o.id]
                                    } else {
                                        value = []
                                    }
                                }
                                onChange(value)
                            }}
                        />
                        <label htmlFor={`${i}-${o.label}`}>
                            {o.label}
                        </label>
                    </div>
                })}
            </div>
        </div>
    }
    return preview ?
        <DefaultAnswerFormatter options={checkboxOptions()} preview={checkboxAnswers()}/>
        :
        checkboxAnswers()
}

export default CheckboxAnswer;