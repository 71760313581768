import {FETCH_PRINT_PROPOSAL, FETCH_PROPOSAL_SITE_MAPS, SET_SELECTED_SITE} from './constants'

const initialState = {
    proposal: {},
    customerSiteMaps: null,
    site: {
        loaded: false,
        arborist: {},
        selected: {}
    }
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_PRINT_PROPOSAL:
            return {...state, proposal: action.proposal}
        case FETCH_PROPOSAL_SITE_MAPS:
            return {...state, customerSiteMaps: action.siteMaps}
        case SET_SELECTED_SITE:
            return {...state, site: {...action.site, loaded: true}}
        default:
            return state
    }
}
