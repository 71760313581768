import axios from "axios/index";
import {apiCall} from "../../common/apiActionHandler";
import {mapForSelect} from "../../common/commonHandlers";
import * as FileSaver from "file-saver";
import moment from "moment/moment";
import {dataUpload} from "../../common/upload";
import {addAlert} from "../App/actions";

export const load = callback => (dispatch, getState) => {
    const config = [
        {url: `/api/search/all_employees`},
        {url: `/api/customer_types`},
        {url: `/api/scheduler_event_types`, params: {tasks_only: true}},
        {url: `/api/user_profile`}
    ];

    apiCall("Loading data", config, async config => {
        let actions = config.map(c => axios.request(c));
        const all = await axios.all(actions);
        callback({
            employees: all[0].data,
            customerTypes: mapForSelect(all[1].data),
            types: mapForSelect(all[2].data),
            user: all[3].data
        });
    }, dispatch, getState, false);
};

export const loadInvoices = (params, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/new_invoices`,
        params
    };
    apiCall("Loading events", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const getPastDueInvoices = (params, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/new_invoices/past_due`,
        params
    };
    apiCall("Loading events", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const searchForCustomer = (searchText, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/customers/name_zip_city_search`,
        params: {q: searchText}
    };

    apiCall("Searching for customers", config, async config => {
        const response = await axios.request(config);
        callback(mapForSelect(response.data));
    }, dispatch, getState, false);
};


export const updatePaidDate = (invoiceIds, paidDate, callback) => async (dispatch, getState) => {
    const store = getState();

    const config = {
        method: "POST",
        url: `/api/invoices/mark_invoice_paid`,
        headers: {Authorization: store.auth.token},
        params: {
            invoice_ids: invoiceIds.join(','),
            paid_at: paidDate
        },
    };

    apiCall("Updating paid dates", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);

};

export const sendBulkEmail = (recipients, email, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/emails/send_bulk_email`,
        method: 'POST',
        data: {email: {...email, recipients}}
    };

    apiCall("Email sending", config, async config => {
        const response = await axios.request(config);
        if (!(response.data.length > 0 && response.data[0].error)) {
            dispatch(
                addAlert({
                    message: `Email sending succeed`,
                    mood: "success",
                    dismissAfter: 1500,
                })
            );
        }
        callback(response.data);
    }, dispatch, getState, false);
};

export const sendBulkNotification = (email, recipients, emailType, emailTemplateId, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/twilio/send_notification`,
        method: 'POST',
        data: {email: email, recipients: recipients, email_type: emailType, email_template_id: emailTemplateId}
    };

    apiCall("Notification sent", config, async config => {
        const response = await axios.request(config);
        callback && callback(response);
    }, dispatch, getState);
};

export const downloadCsv = (params) => async (dispatch, getState) => {
    const config = {
        url: `/api/new_invoices/csv`,
        params
    };

    apiCall("Downloading csv", config, async config => {
        const response = await axios.request(config);
        const blob = new Blob([response.data], {
            type: 'text/csv',
        });
        FileSaver.saveAs(blob, `invoices-${moment().format()}.csv`);

        blob.name = `invoices-${moment().format()}.csv`
        dataUpload(`/api/download_history`, blob, null, null, {
            params: JSON.stringify(params ? params : {}),
            source: "invoices"
        }, false)(dispatch, getState)
    }, dispatch, getState);
};

export const updateInvoicesListSettings = (columns_settings, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/users/update_invoices_list_settings`,
        data: {
            columns_settings
        },
        method: 'PUT'
    };

    apiCall("Update tasks settings", config, async config => {
        const result = await axios.request(config);
        callback && callback(result.data);
    }, dispatch, getState, false);
};

export const searchForSite = (searchText, resource, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/new_invoices/filter_data`,
        params: {
            q: searchText,
            filter: "site",
            ...resource
        }
    };

    apiCall("Searching for sites", config, async config => {
        const response = await axios.request(config);
        callback(mapForSelect(response.data));
    }, dispatch, getState, false);
};

export const searchForArborist = (searchText, resource, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/new_invoices/filter_data`,
        params: {
            q: searchText,
            filter: "arborist",
            ...resource
        }
    };

    apiCall("Searching for arborists", config, async config => {
        const response = await axios.request(config);
        callback(mapForSelect(response.data));
    }, dispatch, getState, false);
};

export const searchForProposalTitle = (searchText, resource, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/new_invoices/filter_data`,
        params: {
            q: searchText,
            filter: "proposal_title",
            ...resource
        }
    };

    apiCall("Searching for proposal titles", config, async config => {
        const response = await axios.request(config);
        callback(mapForSelect(response.data));
    }, dispatch, getState, false);
};

export const searchForProposalNo = (searchText, resource, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/new_invoices/filter_data`,
        params: {
            q: searchText,
            filter: "proposal_no",
            ...resource
        }
    };

    apiCall("Searching for proposal no", config, async config => {
        const response = await axios.request(config);
        callback(mapForSelect(response.data));
    }, dispatch, getState, false);
};

export const searchForWorkOrder = (searchText, resource, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/new_invoices/filter_data`,
        params: {
            q: searchText,
            filter: "work_order_id",
            ...resource
        }
    };

    apiCall("Searching for workorder no", config, async config => {
        const response = await axios.request(config);
        callback(mapForSelect(response.data));
    }, dispatch, getState, false);
};

export const searchForInvoice = (searchText, resource, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/new_invoices/filter_data`,
        params: {
            q: searchText,
            filter: "invoice_id",
            ...resource
        }
    };

    apiCall("Searching for invoice no", config, async config => {
        const response = await axios.request(config);
        callback(mapForSelect(response.data));
    }, dispatch, getState, false);
};