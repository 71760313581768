import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {
    Button,
    Checkbox,
    Col,
    DropdownButton,
    FormControl,
    FormGroup,
    Glyphicon,
    Grid,
    MenuItem,
    NavDropdown,
    OverlayTrigger,
    Row,
    Tooltip,
} from "react-bootstrap";
import {GoogleMap, Marker} from "react-google-maps";
import {GoogleMapLoader} from "../../components/GoogleMapLoader";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import {
    defaultDateFormat,
    defaultMapParams,
    defaultTimeFormat,
    saveNewCsvDownload,
    select
} from "../../common/commonHandlers";
import ResourceComponent from "../../components/ResourceComponent";
import {debounce} from "throttle-debounce";
import Datetime from "react-datetime";
import Select from "react-select";
import {Link} from "react-router";
import ColorCheckbox from "../Scheduler/ColorCheckbox";
import {LinkContainer} from "react-router-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import moment from "moment/moment";
import SplitPane from "react-split-pane";
import './Tasks.scss';
import LeadsFilterModal from "../LeadsPage/LeadsFilterModal";
import EditDescriptionModal from "./EditDescriptionModal";
import WorkOrderDetailsModal from "./WorkOrderDetailsModal";
import TaskDetailsModal from "./TaskDetailsModal";
import SchedulerEventDialog from "../Scheduler/SchedulerEventDialog";
import DragAndDropTable from "../../components/DragAndDropTable/DragAndDropTable";
import classNames from 'classnames';

import * as api from "./TasksApi";
import {loadEvent, loadWorkOrder, updateEvent} from "../Scheduler/SchedulerEventDialogApi"
import {showEmailDialog} from "../../components/email/actions"
import {showTaskDialog} from "../AppWrapper/contents/actions";
import {faCog, faExpand} from "@fortawesome/free-solid-svg-icons";
import {getBasicMarker} from "../../utilities";

const actions = {...api, showEmailDialog, showTaskDialog, loadEvent, updateEvent, loadWorkOrder, saveNewCsvDownload};

const isMobile = window.screen.width <= 1024;
const smMobile = window.screen.width <= 450;

const primaryUrl = new URL(window.location.href).origin

const iOSdetect = () => {
    return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

const isAppleMobile = iOSdetect()

class Tasks extends ResourceComponent {
    state = {
        filterVisible: false,
        employees: [],
        total: 0,
        selectedRows: [],
        center: true,
        CustomersFilterModalShow: false,
        editDescriptionModal: {
            show: false,
            event_id: -1
        },
        workOrderDetailsModal: {
            show: false,
            work_order_id: -1,
            client_id: -1
        },
        taskDetailsModal: {
            show: false,
            event: {}
        },
        schedulerEvents: [],
        resource: {
            completed: false,
            person_id: null,
            reason_id: null,
            sort: 'ASC',
            sort_by: 'date_from',
            page: 1,
            per_page: 25,
            label: 'Today'
        },
        statuses: [],
        types: [],
        reasons: [],
        showModal: false,
        scheduler_event_id: null,
        user: null,
        dateFilterOptions: [
            {value: 'All', label: 'All'},
            {value: 'Today', label: 'Today'},
            {value: 'Tomorrow', label: 'Tomorrow'},
            {value: 'This Week', label: 'This Week'},
            {value: 'Rest Of Week', label: 'Rest Of Week'},
            {value: 'Next Week', label: 'Next Week'},
            {value: 'Yesterday', label: 'Yesterday'},
            {value: 'This Month', label: 'This Month'},
            {value: "Next Month", label: 'Next Month'}
        ],
        showMap: false,
        columnsSettings: {
            "#": true,
            "Task date": true,
            "Task Name": true,
            "Type": true,
            "Assignee": true,
            "Customer": true,
            "Location": true,
            "Lead #": true,
            "Proposal #": true,
            "WO #": true,
            "Invoice #": true,
            "Hours": true,
            "Status": true,
            "Confirmed": true,
            "On Site": true,
            "Lifetime spend": true,
            "Close rate": true,
            "Site contacts": true
        },
        columnsSettingsSelectedAll: true
    };

    constructor(props, context) {
        super(props, context);
        this.delayedSearch = debounce(500, this.search);
        this.customerSearch = {
            searchOptions: () => [],
            search: () => {
            }
        }
    }

    mapContainerRef = React.createRef()
    tableRef = React.createRef()

    componentDidMount() {
        this.load();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.showTaskDialog === false && this.props.showTaskDialog === true) {
            this.search();
        }
        return true;
    }

    isCrewRoleOnly = () => {
        const {userRoles} = this.props;
        const adminRoles = ["sales_arborist", "scheduler", "it_engineering", "admin"]
        const isCrewRole = userRoles && userRoles.map(ur => adminRoles.includes(ur)).every(r => r === false)
        return isCrewRole
    }

    isTechnicianRole = () => {
        const {userRoles} = this.props;
        const isTechnicianRole = userRoles && userRoles.includes("technician")
        return isTechnicianRole
    }

    load() {
        const {resource, employees} = this.state;
        let newResource = resource;
        const customerId = this.props.location.query.customer_id
        if (customerId) {
            const filter = {person_id: null, customer_id: customerId, label: 'All'}
            newResource = {...resource, ...filter}
        }
        const {userRoles} = this.props;
        this.customerSearch = this.buildSearchComponent('customer_id', this.props.actions.searchForCustomer);

        let hasSchedulerRole;
        if (userRoles && userRoles.length > 0) {
            hasSchedulerRole = userRoles.some(r => r === "scheduler")
        }

        this.props.actions.load(result => {
            result.reasons = result.reasons.map(reason => ({label: reason.name, value: reason.id}))
            this.setState(result, this.delayedSearch);
            if (newResource === resource) {
                newResource.person_id = userRoles.includes("sales_arborist") ? this.state.user.person_id : null;
            }
            this.setState({
                ...result,
                resource: newResource,
                employees: result.employees.filter(e => e.disabled !== true),
                showMap: result.user.show_map_on_tasks_page
            });

            if (result.user?.tasks_settings?.columns) {
                this.setState({columnsSettings: result.user.tasks_settings.columns}, () => {
                    this.setAllColumnsSelect()
                })
            } else {
                this.setAllColumnsSelect()
            }
        });
    }

    search = () => {
        const {resource} = this.state;
        this.props.actions.loadEvents(resource, results => {
            let index = 0;
            if (results.content) results.content = results.content.map(row => {
                index += 1;
                return {...row, array_id: resource.per_page * (resource.page - 1) + index}
            });
            let schedulerEvents = results.content;
            let uniqueSites = [];
            schedulerEvents.map(event => {
                uniqueSites.push(event.site_id);
                const singularSiteCount = uniqueSites.filter(s => s === event.site_id).length;
                if (singularSiteCount > 1) {
                    event.site_longitude += singularSiteCount * 0.000015;
                }
            });
            this.setState({schedulerEvents: schedulerEvents, total: results.total, loaded: false});
        });
    };

    onSelectAll = (isSelected, rows) => {
        let selectedRows = [];

        if (isSelected) {
            selectedRows = rows.map(p => p.id)
        }
        this.setState({selectedRows})
    };

    onRowSelect = (row, isSelected) => {
        let selectedRows = [...this.state.selectedRows];
        const id = parseInt(row.id, 10);

        if (isSelected) {
            selectedRows = selectedRows.concat(id);
            const uniqSelected = new Set(selectedRows);
            selectedRows = [...uniqSelected];
        } else {
            selectedRows = selectedRows.filter(s => s !== id);
        }
        this.setState({selectedRows});
    };

    onShowMapChange = () => {
        this.props.actions.updateShowMapOnTasksPage(this.props.user.user_id, !this.state.showMap, (res) => {
            this.setState({showMap: res.show_map_on_tasks_page})
        })

    }

    nameFormatter = (cell, row, isModal = false) => {
        if (this.isCrewRoleOnly()) {
            return <>{row.name}</>
        }
        return <div>
            {isMobile ? <>
                    <Link
                        className="pointer"
                        onClick={() => this.setState(
                            {showModal: true, scheduler_event_id: row.id})}>
                        {row.name}
                    </Link>
                    <div className="hstart top5">
                        <a bsSize="small" className="pointer"
                           onClick={() => this.setState({
                               editDescriptionModal: {
                                   show: true,
                                   event_id: row.id
                               }
                           })}><Glyphicon glyph='edit'/><span className="ml-4 pointer">Edit description</span></a>
                    </div>
                </> :
                <>
                    <div className={isModal ? 'width-fit-content' : ''}>
                        <Link
                            className="pointer mr-3"
                            onClick={() => this.setState(
                                {showModal: true, scheduler_event_id: row.id})}>
                            {row.name}
                        </Link>
                    </div>
                    <a bsSize="small"
                       onClick={() => this.setState({
                           editDescriptionModal: {
                               show: true,
                               event_id: row.id
                           }
                       })}><Glyphicon className="pointer" glyph='edit'/>Edit description</a>
                </>
            }
        </div>;
    };

    toggleComplete = (id, e) => {
        this.props.actions.markAsCompleted({id: id}, e, (data) => {
            if (this.state.taskDetailsModal.show) {
                const {event} = this.state.taskDetailsModal
                this.setState({
                    taskDetailsModal:
                        {show: true, event: {...event, completed: data.completed}}
                })
            }
            this.search()
        });
    };

    toggleConfirmed = (id, e) => {
        this.props.actions.markAsConfirmed({id: id}, e, (data) => {
            if (this.state.taskDetailsModal.show) {
                this.setState({
                    taskDetailsModal:
                        {show: true, event: {...this.state.taskDetailsModal.event, confirmed: data.confirmed}}
                })
            }
            this.search()
        });

    };

    toggleOnSite = (id) => {
        this.props.actions.markAsOnSite({id: id}, (data) => {
            if (this.state.taskDetailsModal.show) {
                this.setState({
                    taskDetailsModal:
                        {show: true, event: {...this.state.taskDetailsModal.event, on_site: data.on_site}}
                })
            }
            this.search()
        });
    }

    statusFormatterMobile = (cell, row) => {
        return <div>
            <div>{row.completed ? 'Complete' : 'To Do'}</div>
        </div>;
    };

    statusFormatter = (cell, row) => {
        return <div>
            <div>{row.completed ? 'Complete' : 'To Do'}</div>
            <div className="d-flex space-between">
                <ColorCheckbox value={row.confirmed}
                               label="Confirm"
                               className="checkbox-smaller webkit-fix-checkbox"
                               onChange={(e) => this.toggleConfirmed(row.id, e)}
                />
            </div>
            <div>
                <ColorCheckbox value={row.completed}
                               label="Complete"
                               className="checkbox-smaller webkit-fix-checkbox"
                               onChange={(e) => this.toggleComplete(row.id, e)}
                />
            </div>
            <div>
                <ColorCheckbox value={row.on_site}
                               label="On Site"
                               className="checkbox-smaller webkit-fix-checkbox"
                               onChange={() => this.toggleOnSite(row.id)}
                />
            </div>
        </div>;
    };

    statusCheckboxFormatter = (cell, row) => {
        return <div>
            <div className="status-checkbox-row space-between">
                <ColorCheckbox value={row.confirmed}
                               label="Confirm Event"
                               className="checkbox-smaller"
                               onChange={(e) => this.toggleConfirmed(row.id, e)}
                /><ColorCheckbox value={row.completed}
                                 label="Complete Event"
                                 className="checkbox-smaller"
                                 onChange={(e) => this.toggleComplete(row.id, e)}
            />
                <ColorCheckbox value={row.on_site}
                               label="On Site"
                               className="checkbox-smaller"
                               onChange={() => this.toggleOnSite(row.id)}
                />
            </div>
        </div>
    };

    leadFormatter = (cell, row) => {
        return row.scheduler_event_resource_id
    };

    workOrderFormatter = (cell, row) => {
        if (row.work_order_no) {
            return <div className="d-flex">
                <div className={isMobile ? "mr-5" : "mr-3"}>{row.work_order_no}</div>
                <OverlayTrigger
                    placement={'bottom'}
                    overlay={
                        <Tooltip id={`tooltip-edit-description`}>
                            View Services/Equipment/JSA
                        </Tooltip>
                    }
                >
                    <a bsSize="small"
                       onClick={() => this.setState({
                           workOrderDetailsModal: {
                               show: true,
                               work_order_id: row.work_order_id,
                               client_id: row.client_id
                           }
                       })}><Glyphicon className="pointer" glyph='info-sign'/></a>
                </OverlayTrigger>
            </div>
        } else {
            return ''
        }
    };

    proposalFormatter = (cell, row) => {
        if (row.proposal_no) {
            return row.proposal_no
        } else {
            if (row.customer_id && row.scheduler_event_resource_id) {
                return <a
                    href={primaryUrl + `/#/mapview?customer_id=${row.customer_id}&lead_id=${row.scheduler_event_resource_id}`}
                    target="_blank">Add</a>
            } else return ''
        }
    }

    customerFormatter = (cell, row, isModal = false) => {
        if (this.isCrewRoleOnly()) {
            return <Row><Col xs={12}>{row.customer_name}</Col>
                <Col md={isModal ? 2 : 6} xs={2}
                     className={(isMobile || isModal) ? 'hstart top5' : `hcenter hright top5`}>
                    {row.customer_phone ?
                        <a bsSize="small"
                           href={`tel:${row.customer_phone}`}><Glyphicon glyph='phone'/></a> : ''
                    }
                </Col>
                {this.isTechnicianRole() ?
                    <Col md={isModal ? 2 : 6} xs={2}
                         className={(isMobile || isModal) ? 'hstart top5' : `hcenter hstart top5`}>
                        {row.customer_email ?
                            <a bsSize="small"
                               href={`mailto:${row.customer_email}`}><Glyphicon glyph='envelope'/></a> : ''
                        }
                    </Col> : null}
            </Row>
        }
        return <Row><Col xs={12}><NavDropdown
            title={row.customer_name}
            noCaret
        >
            <LinkContainer to={`/customer/info/${row.customer_id}`}>
                <MenuItem bsSize="small" eventKey={2.6}>Info</MenuItem>
            </LinkContainer>
            {row?.site_id ?
                <>
                    <LinkContainer to={`/customer/proposals/${row.customer_id}`}>
                        <MenuItem bsSize="small" eventKey={2.6}>Proposals</MenuItem>
                    </LinkContainer>
                    <LinkContainer to={`/customer/sites/${row.customer_id}`}>
                        <MenuItem bsSize="small" eventKey={2.6}>Sites</MenuItem>
                    </LinkContainer>
                    <LinkContainer to={`/customer/work_orders/${row.customer_id}`}>
                        <MenuItem bsSize="small" eventKey={2.6}>Work Orders</MenuItem>
                    </LinkContainer>
                    <LinkContainer to={`/customer/invoices/${row.customer_id}`}>
                        <MenuItem bsSize="small" eventKey={2.6}>Invoices</MenuItem>
                    </LinkContainer>
                    <LinkContainer to={`/customer/work_history/${row.customer_id}`}>
                        <MenuItem bsSize="small" eventKey={2.6}>Work History</MenuItem>
                    </LinkContainer>
                </>
                :
                <>
                    <MenuItem bsSize="small" eventKey={2.6} disabled={true}>Proposals</MenuItem>
                    <MenuItem bsSize="small" eventKey={2.6} disabled={true}>Sites</MenuItem>
                    <MenuItem bsSize="small" eventKey={2.6} disabled={true}>Work Orders</MenuItem>
                    <MenuItem bsSize="small" eventKey={2.6} disabled={true}>Invoices</MenuItem>
                    <MenuItem bsSize="small" eventKey={2.6} disabled={true}>Work History</MenuItem>
                </>
            }
        </NavDropdown>
        </Col>
            <Col md={isModal ? 2 : 6} xs={2} className={(isMobile || isModal) ? 'hstart top5' : `hcenter hright top5`}>
                {row.customer_phone ?
                    <a bsSize="small"
                       href={`tel:${row.customer_phone}`}><Glyphicon glyph='phone'/></a> : ''
                }
            </Col>
            <Col md={isModal ? 2 : 6} xs={2} className={(isMobile || isModal) ? 'hstart top5' : `hcenter hstart top5`}>
                {row.customer_email ?
                    <a bsSize="small"
                       href={`mailto:${row.customer_email}`}><Glyphicon glyph='envelope'/></a> : ''
                }
            </Col>
        </Row>
    };

    dateFromFormatter = (cell, row) => {
        if (row?.all_day) {
            return `All Day (${moment(cell).format(defaultDateFormat)})`
        } else {
            return cell ? <div>
                <div>{moment(cell).format(defaultDateFormat)}</div>
                <div>{moment(cell).format(defaultTimeFormat)}</div>
            </div> : 'N/A';
        }
    }

    siteFormatter = (cell, row) => {
        return <div>
            {row.site_name && <>
                <b>{row.site_name}</b>
                <br/>
            </>}
            <a href={`https://maps.apple.com/place?&ll=${row.site_latitude},${row.site_longitude}/`}
               target="_blank">
                {row.site_full_address}
            </a>
        </div>
    };

    rowFormatterMobile = (cell, row) => {
        const {columnsSettings} = this.state
        return <div>
            {columnsSettings["#"] && <Row>
                <Col xs={6}>#</Col>
                <Col xs={6}>{row.array_id}</Col>
            </Row>}
            {columnsSettings["Task date"] && <Row>
                <Col xs={6}>Task Date</Col>
                <Col xs={6}>{this.dateFromFormatter(row.date_from, row)}</Col>
            </Row>}
            {columnsSettings["Task Name"] && <Row>
                <Col xs={6}>Name</Col>
                <Col xs={6}>{this.nameFormatter(cell, row)}</Col>
            </Row>}
            {columnsSettings["Type"] && <Row>
                <Col xs={6}>Type</Col>
                <Col xs={6}>{row.scheduler_event_type_name}</Col>
            </Row>}
            {columnsSettings["Assignee"] && <Row>
                <Col xs={6}>Assignee</Col>
                <Col xs={6}>{row.person_name}</Col>
            </Row>}
            {columnsSettings["Customer"] && <Row>
                <Col xs={6}>Customer</Col>
                <Col xs={6}>{this.customerFormatter(cell, row)}</Col>
            </Row>}
            {columnsSettings["Location"] && <Row>
                <Col xs={6}>Location</Col>
                <Col xs={6}>{this.siteFormatter(cell, row)}</Col>
            </Row>}
            {columnsSettings["Lead #"] && <Row>
                <Col xs={6}>Lead #</Col>
                <Col xs={6}>{this.leadFormatter(cell, row)}</Col>
            </Row>}
            {columnsSettings["Proposal #"] && <Row>
                <Col xs={6}>Proposal #</Col>
                <Col xs={6}>{this.proposalFormatter(cell, row)}</Col>
            </Row>}
            {columnsSettings["WO #"] && <Row>
                <Col xs={6}>WO #</Col>
                <Col xs={6}>{this.workOrderFormatter(cell, row)}</Col>
            </Row>}
            {columnsSettings["Invoice #"] && <Row>
                <Col xs={6}>Invoice #</Col>
                <Col xs={6}>{row.invoice_no}</Col>
            </Row>}
            {columnsSettings["Hours"] && <Row>
                <Col xs={6}>Hours</Col>
                <Col xs={6}>{row.service_hours}</Col>
            </Row>}
            {columnsSettings["Status"] && <><Row>
                <Col xs={6}>Status</Col>
                <Col xs={6}>{this.statusFormatterMobile(cell, row)}
                </Col>
            </Row>
                <Row>
                    <Col xs={12}>{this.statusCheckboxFormatter(cell, row)}</Col>
                </Row></>}
        </div>
    };

    handleDateFilterChange = e => {
        let {resource} = this.state;
        resource.from = null;
        resource.to = null;
        resource.created_from = null;
        resource.created_to = null;
        resource.label = e.value;
        this.setState(resource, this.search)
    };

    csvCustomerFormatter = (cell, row) => {
        return row.customer_name
    }

    csvSiteFormatter = (cell, row) => {
        if (row.site_name !== undefined) {
            return row.site_name, row.site_latitude, row.site_longitude, row.site_full_address
        } else {
            return ''
        }
    }

    csvLeadFormatter = (cell, row) => {
        if (row.scheduler_event_resource_id !== null) {
            return row.scheduler_event_resource_id
        } else {
            return ''
        }
    }

    csvProposalFormatter = (cell, row) => {
        if (row.work_order_no !== null) {
            return row.work_order_no
        } else {
            return ''
        }
    }

    csvWorkOrderFormatter = (cell, row) => {
        if (row.work_order_no !== null) {
            return row.work_order_no
        } else {
            return ''
        }
    }

    csvInvoiceFormatter = (cell, row) => {
        if (row.invoice_no !== null) {
            return row.invoice_no
        } else {
            return ''
        }
    }

    csvServiceHoursFormatter = (cell, row) => {
        if (row.service_hours !== null) {
            return row.service_hours
        } else {
            return ''
        }
    }

    csvDateFormatter = (cell, row) => {
        if (row.date_from !== undefined) {
            if (row?.all_day) {
                return `All Day (${moment(row.date_from).format(defaultDateFormat)})`
            } else {
                return [moment(row.date_from).format(defaultDateFormat), moment(row.date_from).format(defaultTimeFormat)]
            }
        } else {
            return ''
        }
    }

    csvStatusFormatterCompleted = (cell, row) => {
        return row.completed ? "Complete" : "To Do"
    }

    csvStatusFormatterConfirmed = (cell, row) => {
        return row.confirmed ? "Yes" : "No"
    }

    csvStatusFormatterOnSite = (cell, row) => {
        return row.on_site ? "Yes" : "No"
    }

    csvSiteContactFormatter = (cell, row) => {
        let site_contacts = []
        row.site_contacts.map(sc => {
            site_contacts.push(sc.site_contact)
        })
        return site_contacts.length > 0 ? site_contacts.join(", ") : ""
    }

    csvSiteContactEmailFormatter = (cell, row) => {
        let emails = []
        row.site_contacts.map(sc => {
            emails.push(sc.site_contact_email)
        })
        return emails.length > 0 ? emails.join(", ") : ""
    }

    csvSiteContactPhoneFormatter = (cell, row) => {
        let phones = []
        row.site_contacts.map(sc => {
            phones.push(sc.site_contact_phone)
        })
        return phones.length > 0 ? phones.join(", ") : ""
    }

    csvLifetimeSpendFormatter = (cell, row) => {
        return row.lifetime_spend ? "$" + row.lifetime_spend : ""
    }

    csvCloseRateFormatter = (cell, row) => {
        return row.close_rate ? row.close_rate + '%' : ""
    }

    columnsMobile = [{
        dataFormat: this.rowFormatterMobile
    }]

    columns = [{
        dataField: 'array_id',
        text: '#',
        width: '5%',
        sortable: true
    }, {
        dataField: 'date_from',
        text: 'Task date',
        width: '10%',
        sortable: true,
        dataFormat: this.dateFromFormatter
    }, {
        dataField: 'name',
        text: 'Task Name',
        width: '10%',
        sortable: true,
        dataFormat: this.nameFormatter
    }, {
        dataField: 'scheduler_event_type_name',
        text: 'Type',
        width: '7%',
        sortable: true
    }, {
        dataField: 'person_name',
        text: 'Assignee',
        width: '10%',
        sortable: true
    },
        {
            dataField: 'customer_name',
            text: 'Customer',
            width: '10%',
            sortable: true,
            dataFormat: this.customerFormatter
        }, {
            dataField: 'site_location',
            text: 'Location',
            width: '10%',
            sortable: true,
            dataFormat: this.siteFormatter
        }, {
            dataField: 'scheduler_event_resource_id',
            text: 'Lead #',
            width: '6%',
            sortable: true,
            dataFormat: this.leadFormatter
        }, {
            dataField: 'proposal_no',
            text: 'Proposal #',
            width: '6%',
            sortable: true,
            dataFormat: this.proposalFormatter
        }, {
            dataField: 'work_order_no',
            text: 'WO #',
            width: '6%',
            sortable: true,
            dataFormat: this.workOrderFormatter
        }, {
            dataField: 'invoice_no',
            text: 'Invoice #',
            width: '6%',
            sortable: true
        }, {
            dataField: 'service_hours',
            text: 'Hours',
            width: '6%',
            sortable: true
        }, {
            dataField: 'completed',
            text: 'Status',
            width: '11%',
            sortable: true,
            dataFormat: this.statusFormatter
        }];

    onToggleFullScreen = () => {
        const {fullScreen} = this.props
        this.props.actions.toggleFullScreen(fullScreen === false)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.fullScreen !== this.props.fullScreen && !this.props.fullScreen && isAppleMobile) {
            this.mapContainerRef.current.scrollIntoView()
        }
    }

    formattersTaskModal = {
        dateFromFormatter: this.dateFromFormatter,
        nameFormatter: this.nameFormatter,
        customerFormatter: this.customerFormatter,
        siteFormatter: this.siteFormatter,
        leadFormatter: this.leadFormatter,
        proposalFormatter: this.proposalFormatter,
        workOrderFormatter: this.workOrderFormatter,
        statusCheckboxFormatter: this.statusCheckboxFormatter,
        statusFormatterMobile: this.statusFormatterMobile
    }

    onColumnsSettingsChange = (column) => {
        let {columnsSettings} = this.state
        columnsSettings[column] = !columnsSettings[column]
        this.props.actions.updateTasksSettings(columnsSettings, (res) => {
            this.setState({columnsSettings}, this.setAllColumnsSelect)
        })
    }

    setAllColumnsSelect = () => {
        let {columnsSettings} = this.state
        let check = true
        Object.keys(columnsSettings).forEach(column => {
            if (!columnsSettings[column]) {
                check = false
            }
        })
        this.setState({columnsSettingsSelectedAll: check})
    }

    onSelectAllColumns = () => {
        let {columnsSettings, columnsSettingsSelectedAll} = this.state
        Object.keys(columnsSettings).forEach(column => columnsSettings[column] = !columnsSettingsSelectedAll)
        columnsSettingsSelectedAll = !columnsSettingsSelectedAll;
        this.props.actions.updateTasksSettings(columnsSettings, (res) => {
            this.setState({columnsSettings, columnsSettingsSelectedAll})
        })
    }

    renderSettingsDropdown = () => {
        const {columnsSettings, columnsSettingsSelectedAll} = this.state
        return (
            <div className="settings-dropdown-menu">
                <DropdownButton title={<>Settings<FontAwesomeIcon icon={faCog} className="small-margin fontSize14"/></>}
                                className="mr-9 settings-dropdown-menu">
                    <Checkbox className="ml-9" checked={columnsSettingsSelectedAll} onChange={() => {
                        this.onSelectAllColumns()
                    }}>Select All</Checkbox>
                    <Checkbox className="ml-9" checked={columnsSettings["#"]} onChange={() => {
                        this.onColumnsSettingsChange("#")
                    }}>#</Checkbox>
                    <Checkbox className="ml-9" checked={columnsSettings["Task date"]} onChange={() => {
                        this.onColumnsSettingsChange("Task date")
                    }}>Task date</Checkbox>
                    <Checkbox className="ml-9" checked={columnsSettings["Task Name"]} onChange={() => {
                        this.onColumnsSettingsChange("Task Name")
                    }}>Task Name</Checkbox>
                    <Checkbox className="ml-9" checked={columnsSettings["Type"]} onChange={() => {
                        this.onColumnsSettingsChange("Type")
                    }}>Type</Checkbox>
                    <Checkbox className="ml-9" checked={columnsSettings["Assignee"]} onChange={() => {
                        this.onColumnsSettingsChange("Assignee")
                    }}>Assignee</Checkbox>
                    <Checkbox className="ml-9" checked={columnsSettings["Customer"]} onChange={() => {
                        this.onColumnsSettingsChange("Customer")
                    }}>Customer</Checkbox>
                    <Checkbox className="ml-9" checked={columnsSettings["Location"]} onChange={() => {
                        this.onColumnsSettingsChange("Location")
                    }}>Location</Checkbox>
                    <Checkbox className="ml-9" checked={columnsSettings["Lead #"]} onChange={() => {
                        this.onColumnsSettingsChange("Lead #")
                    }}>Lead #</Checkbox>
                    <Checkbox className="ml-9" checked={columnsSettings["Proposal #"]} onChange={() => {
                        this.onColumnsSettingsChange("Proposal #")
                    }}>Proposal #</Checkbox>
                    <Checkbox className="ml-9" checked={columnsSettings["WO #"]} onChange={() => {
                        this.onColumnsSettingsChange("WO #")
                    }}>WO #</Checkbox>
                    <Checkbox className="ml-9" checked={columnsSettings["Invoice #"]} onChange={() => {
                        this.onColumnsSettingsChange("Invoice #")
                    }}>Invoice #</Checkbox>
                    <Checkbox className="ml-9" checked={columnsSettings["Hours"]} onChange={() => {
                        this.onColumnsSettingsChange("Hours")
                    }}>Hours</Checkbox>
                    <Checkbox className="ml-9 bottom3" checked={columnsSettings["Status"]} onChange={() => {
                        this.onColumnsSettingsChange("Status")
                    }}>Status</Checkbox>
                    <p className="ml-7 bottom0">CSV export only</p>
                    <hr className="mt0 bottom0"/>
                    <Checkbox className="ml-9" checked={columnsSettings["Confirmed"]} onChange={() => {
                        this.onColumnsSettingsChange("Confirmed")
                    }}>Confirmed</Checkbox>
                    <Checkbox className="ml-9" checked={columnsSettings["On Site"]} onChange={() => {
                        this.onColumnsSettingsChange("On Site")
                    }}>On Site</Checkbox>
                    <Checkbox className="ml-9" checked={columnsSettings["Lifetime spend"]} onChange={() => {
                        this.onColumnsSettingsChange("Lifetime spend")
                    }}>Lifetime spend</Checkbox>
                    <Checkbox className="ml-9" checked={columnsSettings["Close rate"]} onChange={() => {
                        this.onColumnsSettingsChange("Close rate")
                    }}>Close rate</Checkbox>
                    <Checkbox className="ml-9" checked={columnsSettings["Site contacts"]} onChange={() => {
                        this.onColumnsSettingsChange("Site contacts")
                    }}>Site contacts</Checkbox>

                </DropdownButton>
            </div>
        )
    }

    render() {
        const {
            employees, center, total, schedulerEvents, filterVisible, reasons,
            resource, types, showModal, scheduler_event_id, dateFilterOptions,
            CustomersFilterModalShow, showMap, columnsSettings
        } = this.state;

        const {client, userRoles} = this.props;
        const {page, per_page, sort, sort_by} = resource;
        const customerOptions = this.customerSearch.searchOptions();
        const mapParams = schedulerEvents.map(event => {
            let param = {};
            param.latitude = event.site_latitude;
            param.longitude = event.site_longitude;
            return param;
        });
        const statusOptions = [
            {value: true, label: 'Complete'},
            {value: false, label: 'To Do'}
        ];
        if (center) {
            this.setState({center: false});
        }

        return (
            <Grid fluid id='tasks'>
                {!this.props.fullScreen && <>
                    <Row>
                        <Col md={12}>
                        </Col>
                    </Row>
                    <Row className="vcenter">
                        <Col md={4} className="vcenter full-width">
                            <h2 className="no-top-margin mr-9">Tasks</h2>
                            <h5 className={smMobile ? "text-primary nmt-10" : "text-primary"}>{total} returned</h5>
                        </Col>
                        <Col md={8} className="hright">
                            <div className="material-switch mt10 mr-11">
                                <input id="showMapToggle" name="showMapToggle" type="checkbox"
                                       checked={showMap}
                                       onChange={this.onShowMapChange}/>
                                <label htmlFor="showMapToggle" className="label-default"></label>
                                <span className="ml-10">Show Map</span>
                            </div>
                            {this.renderSettingsDropdown()}
                            <Button
                                className="pointer no-top-margin"
                                bsStyle="warning"
                                onClick={() => this.setState({
                                    CustomersFilterModalShow: !this.state.CustomersFilterModalShow,
                                    oldResource: {...resource}
                                })}>
                                Filter {" "}<FontAwesomeIcon icon="search" className="small-margin"/>
                            </Button>
                        </Col>
                    </Row>
                    <hr className={'mb-10-mt-0'}/>
                </>}
                {this.state.editDescriptionModal.show && <EditDescriptionModal
                    event_id={this.state.editDescriptionModal.event_id}
                    loadEvent={this.props.actions.loadEvent}
                    show={this.state.editDescriptionModal.show}
                    onHide={() => {
                        this.setState({editDescriptionModal: {show: false}})
                    }}
                    onSave={this.props.actions.updateEvent}
                    title={'Edit description'}/>}
                {this.state.workOrderDetailsModal.show && <WorkOrderDetailsModal
                    work_order_id={this.state.workOrderDetailsModal.work_order_id}
                    loadWorkOrder={this.props.actions.loadWorkOrder}
                    show={this.state.workOrderDetailsModal.show}
                    onHide={() => {
                        this.setState({workOrderDetailsModal: {show: false}})
                    }}
                    client={this.props.client}
                    userRoles={userRoles}
                    title={'Work Order Details'}
                />}
                {this.state.taskDetailsModal.show && <TaskDetailsModal
                    event={this.state.taskDetailsModal.event}
                    show={this.state.taskDetailsModal.show}
                    formatters={this.formattersTaskModal}
                    onHide={() => {
                        this.setState({taskDetailsModal: {show: false}})
                    }}
                    title={'Task Details'}
                />}
                {this.state.showModal && <SchedulerEventDialog
                    handleClose={() => {
                        this.setState({showModal: false})
                    }}
                    event={{id: this.state.scheduler_event_id}}
                />}
                <LeadsFilterModal
                    title="Tasks Filter"
                    show={CustomersFilterModalShow}
                    onHide={() => {
                        this.setState({
                            CustomersFilterModalShow: !this.state.CustomersFilterModalShow,
                        }, this.search)
                    }}
                    closeButton={() => this.setState({
                        CustomersFilterModalShow: !this.state.CustomersFilterModalShow,
                        resource: this.state.oldResource
                    })}
                >
                    <Row className={'modalLeadsFilter'}>
                        {!this.isCrewRoleOnly() ?
                            <Col md={12}>
                                <FormGroup>
                                    <Select className="Select top15 bottom15"
                                            classNamePrefix="select"
                                            value={select(employees, resource.person_id)}
                                            options={employees}
                                            isClearable
                                            isMulti
                                            onChange={this.selectResourceAttr('person_id')}
                                            placeholder="Assignee"
                                    />
                                </FormGroup>
                            </Col> : null}
                        <Col md={12}>
                            <FormGroup>
                                <Select className={"Select bottom15 " + `${!this.isCrewRoleOnly() ? "" : "top15"}`}
                                        classNamePrefix="select"
                                        value={select(customerOptions, resource.customer_id)}
                                        options={customerOptions}
                                        placeholder="Customer"
                                        isClearable
                                        isMulti
                                        onInputChange={this.customerSearch.search}
                                        onChange={this.selectResourceAttr('customer_id')}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className={'modalLeadsFilter'}>
                        <hr className={'marginBottom10'}/>
                        <Col md={6} className="text-left">
                            <FormGroup>
                                <Select className="Select bottom15"
                                        classNamePrefix="select"
                                        value={select(statusOptions, resource.completed)}
                                        options={statusOptions}
                                        placeholder="Status"
                                        isClearable
                                        onChange={this.selectResourceAttr('completed')}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Select className="Select bottom15"
                                        classNamePrefix="select"
                                        options={dateFilterOptions}
                                        value={select(dateFilterOptions, resource.label)}
                                        placeholder="Date"
                                        onChange={e => this.handleDateFilterChange(e)}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Select className="Select bottom15"
                                        classNamePrefix="select"
                                        options={types}
                                        value={select(types, resource.scheduler_event_type_id)}
                                        placeholder="Type"
                                        isMulti
                                        onChange={this.selectResourceAttr('scheduler_event_type_id')}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Select className="Select bottom15"
                                        classNamePrefix="select"
                                        options={reasons}
                                        value={select(reasons, resource.reason_id)}
                                        placeholder="Reason"
                                        isClearable
                                        isMulti
                                        onChange={this.selectResourceAttr('reason_id')}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <hr className={'marginBottom10'}/>
                    <Row className={'modalLeadsFilter'}>
                        <Col md={6}>
                            <FormGroup>
                                <Datetime
                                    dateFormat={defaultDateFormat}
                                    timeFormat={defaultTimeFormat}
                                    utc={true}
                                    value={resource.from}
                                    className="bottom15"
                                    inputProps={{placeholder: 'Task From'}}
                                    onChange={this.dateResourceAttr('from')}/>
                            </FormGroup>
                        </Col>
                        {/*<Col md={2}><span className='text-default'><Glyphicon glyph='minus'/></span></Col>*/}
                        <Col md={6}>
                            <FormGroup>
                                <Datetime
                                    dateFormat={defaultDateFormat}
                                    timeFormat={defaultTimeFormat}
                                    utc={true}
                                    onFocus={() => {
                                        if (resource.to === null) {
                                            resource.to = moment().endOf('day')
                                            this.setState(resource)
                                        }
                                    }}
                                    value={resource.to}
                                    className="bottom15"
                                    inputProps={{placeholder: 'Task To'}}
                                    onChange={this.dateResourceAttr('to')}/>
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Datetime
                                    dateFormat={defaultDateFormat}
                                    timeFormat={defaultTimeFormat}
                                    value={resource.created_from}
                                    className="bottom15"
                                    inputProps={{placeholder: 'Created From'}}
                                    onChange={this.dateResourceAttr('created_from')}/>
                            </FormGroup>
                        </Col>
                        {/*<span className='text-default'><Glyphicon glyph='minus'/></span>*/}
                        <Col md={6}>
                            <FormGroup>
                                <Datetime
                                    dateFormat={defaultDateFormat}
                                    timeFormat={defaultTimeFormat}
                                    onFocus={() => {
                                        if (resource.created_to === null) {
                                            resource.created_to = moment().endOf('day')
                                            this.setState(resource)
                                        }
                                    }}
                                    value={resource.created_to}
                                    className="bottom15"
                                    inputProps={{placeholder: 'Created To'}}
                                    onChange={this.dateResourceAttr('created_to')}/>
                            </FormGroup>
                        </Col>
                    </Row>
                    <hr className="mb-10-mt-0"/>
                    <Row className={'modalLeadsFilter'}>
                        <Col md={12}>
                            <ColorCheckbox value={resource.on_site}
                                           label="On Site"
                                           onChange={this.selectCheckboxAttr('on_site')}
                            />
                        </Col>
                        <Col md={12} className="vcenter text-left marginBottom8">
                            <ColorCheckbox value={resource.confirmed}
                                           label="Confirmed"
                                           onChange={this.selectCheckboxAttr('confirmed')}
                            />
                        </Col>
                    </Row>
                </LeadsFilterModal>
                {isMobile ?
                    <Row style={{margin: '0 0 10px 0'}}>
                        {!this.props.fullScreen && <>
                            <Row className="vertical-align pb-10">
                                <Col xs={4} md={6}/>
                                <Col xs={8} md={6} className="pl-20 full-width">
                                    <FormControl
                                        name="q"
                                        placeholder="Search..."
                                        value={resource.q}
                                        onChange={e => this.updateResourceAttr(e, this.delayedSearch)}
                                    />
                                </Col>
                            </Row>
                            <Col className="remaining vertical-responsive-max-height height-fit-content bottom10">
                                <BootstrapTable
                                    exportCSV={true}
                                    ref={this.tableRef}
                                    data={schedulerEvents}
                                    striped={true}
                                    bordered={false}
                                    hover={true}
                                    pagination={true}
                                    remote
                                    fetchInfo={{dataTotalSize: total}}
                                    className="wrapped"
                                    csvFileName={() => {
                                        const fileName = `tasks-${moment().format()}.csv`
                                        if (this.tableRef?.current) {
                                            this.props.actions.saveNewCsvDownload(this.tableRef.current, fileName, this.state.resource, "tasks")
                                        }
                                        return fileName
                                    }}
                                    options={
                                        {
                                            onPageChange: (page, per_page) => {
                                                let {resource} = this.state;
                                                resource = {...resource, page, per_page};
                                                this.setState({resource}, this.delayedSearch);
                                            },
                                            page,
                                            sizePerPageList: [{
                                                text: '10', value: 10
                                            }, {
                                                text: '25', value: 25
                                            },
                                                {
                                                    text: '30', value: 30
                                                },
                                                {
                                                    text: '50', value: 50
                                                },
                                                {
                                                    text: '100', value: 100
                                                },
                                                {
                                                    text: '200', value: 200
                                                },
                                                {
                                                    text: 'All', value: total
                                                }],
                                            sizePerPage: per_page,
                                            sizePerPageDropDown: this.renderSizePerPageDropDown,
                                            onRowClick: row => {
                                                this.selectMarkerNoScroll('schedulerEvents')(row);
                                            },
                                            noDataText: ' ',
                                            sortName: sort_by,
                                            sortOrder: sort,
                                            onSortChange: (sort_by, sort) => {
                                                let {resource} = this.state;
                                                resource = {...resource, sort_by, sort};
                                                this.setState({resource}, this.delayedSearch);
                                            }
                                        }
                                    }
                                    selectRow={{
                                        mode: "hidden",
                                        clickToSelect: false,
                                        onSelect: this.onRowSelect,
                                        onSelectAll: this.onSelectAll,
                                    }}
                                >
                                    <TableHeaderColumn
                                        dataField="id"
                                        hidden
                                        width={"5%"}
                                        isKey
                                    />
                                    <TableHeaderColumn
                                        hidden
                                        dataField="array_id"
                                        width={"5%"}
                                        dataSort
                                    >
                                        #
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        export={columnsSettings["Task date"]}
                                        hidden
                                        dataField="date_from"
                                        dataSort
                                        dataFormat={this.dateFromFormatter}
                                        csvFormat={this.csvDateFormatter}
                                        csvHeader="Task date"
                                        width={"10%"}
                                    >
                                        Task date
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        export={columnsSettings["Task Name"]}
                                        hidden
                                        dataField="name"
                                        csvHeader="Task Name"
                                        dataSort
                                        dataFormat={this.nameFormatter}
                                        csvFormat={cell => cell ? cell : ''}
                                        width={"10%"}
                                    >
                                        Task Name
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        export={columnsSettings["Type"]}
                                        hidden
                                        dataField="scheduler_event_type_name"
                                        csvHeader="Type"
                                        dataSort
                                        width={"7%"}
                                    >
                                        Type
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        export={columnsSettings["Assignee"]}
                                        hidden
                                        dataField="person_name"
                                        csvHeader="Assignee"
                                        dataSort
                                        width={"10%"}
                                    >
                                        Assignee
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        export={columnsSettings["Customer"]}
                                        hidden
                                        dataField="customer_name"
                                        dataFormat={this.customerFormatter}
                                        csvFormat={this.csvCustomerFormatter}
                                        csvHeader="Customer"
                                        dataSort
                                        width={"10%"}
                                    >
                                        Customer
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        export={columnsSettings["Location"]}
                                        hidden
                                        dataField="site_location"
                                        dataFormat={this.siteFormatter}
                                        csvFormat={this.csvSiteFormatter}
                                        csvHeader="Location"
                                        dataSort
                                        width={"10%"}
                                    >
                                        Location
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        export={columnsSettings["Lead #"]}
                                        hidden
                                        dataField="scheduler_event_resource_id"
                                        dataFormat={this.leadFormatter}
                                        csvFormat={this.csvLeadFormatter}
                                        csvHeader="Lead #"
                                        dataSort
                                        width={"7%"}
                                    >
                                        Lead #
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        export={columnsSettings["Proposal #"]}
                                        hidden
                                        dataField="proposal_no"
                                        csvHeader="Proposal #"
                                        csvFormat={this.csvProposalFormatter}
                                        dataSort
                                        width={"7%"}
                                    >
                                        Proposal #
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        export={columnsSettings["WO #"]}
                                        hidden
                                        dataField="work_order_no"
                                        csvHeader="WO #"
                                        csvFormat={this.csvWorkOrderFormatter}
                                        dataSort
                                        width={"7%"}
                                    >
                                        WO #
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        export={columnsSettings["Invoice #"]}
                                        hidden
                                        dataField="invoice_no"
                                        csvHeader="Invoice #"
                                        csvFormat={this.csvInvoiceFormatter}
                                        dataSort
                                        width={"7%"}
                                    >
                                        Invoice #
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        export={columnsSettings["Hours"]}
                                        hidden
                                        dataField="service_hours"
                                        csvHeader="Hours"
                                        csvFormat={this.csvServiceHoursFormatter}
                                        dataSort
                                        width={"7%"}
                                    >
                                        Hours
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        export={columnsSettings["Status"]}
                                        hidden
                                        dataField="completed"
                                        dataSort
                                        dataFormat={this.statusFormatter}
                                        csvFormat={this.csvStatusFormatterCompleted}
                                        csvHeader="Status"
                                        width={"10%"}
                                    >
                                        Status
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        export={columnsSettings["Confirmed"]}
                                        hidden
                                        dataField="confirmed"
                                        dataSort
                                        dataFormat={this.statusFormatter}
                                        csvFormat={this.csvStatusFormatterConfirmed}
                                        csvHeader="Confirmed"
                                        width={"10%"}
                                    >
                                        Confirmed
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        export={columnsSettings["On Site"]}
                                        hidden
                                        dataField="on_site"
                                        dataSort
                                        dataFormat={this.statusFormatter}
                                        csvFormat={this.csvStatusFormatterOnSite}
                                        csvHeader="On Site"
                                        width={"10%"}
                                    >
                                        On Site
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        export={columnsSettings["Lifetime spend"]}
                                        hidden
                                        dataField="lifetime_spend"
                                        dataSort
                                        csvFormat={this.csvLifetimeSpendFormatter}
                                        csvHeader={"Lifetime spend"}
                                        width={"10%"}
                                    >
                                        Lifetime spend
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        export={columnsSettings["Close rate"]}
                                        hidden
                                        dataField="close_rate"
                                        dataSort
                                        csvFormat={this.csvCloseRateFormatter}
                                        csvHeader={"Close rate"}
                                        width={"10%"}
                                    >
                                        Close rate
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        export={columnsSettings["Site contacts"]}
                                        hidden
                                        dataField="site_contacts"
                                        dataSort
                                        csvFormat={this.csvSiteContactFormatter}
                                        csvHeader={"Site contacts"}
                                        width={"10%"}
                                    >
                                        Site contacts
                                    </TableHeaderColumn>
                                </BootstrapTable>
                                <DragAndDropTable
                                    updateResource={(res) => {
                                        res.forEach((item, index) => {
                                            item.array_id = (resource.per_page * (resource.page - 1)) + index + 1
                                        })
                                        this.setState({schedulerEvents: res})
                                    }}
                                    data={schedulerEvents}
                                    columns={this.columnsMobile}
                                    trClassName={this.selectedMarkerClass}
                                    onRowClick={row => {
                                        this.selectMarkerNoScroll('schedulerEvents')(row)
                                    }}
                                />
                            </Col>
                        </>}
                        <div style={{"visibility": showMap ? "visible" : "hidden"}} ref={this.mapContainerRef}
                             className="bottom15 no-margin">
                            <GoogleMapLoader
                                containerElement={
                                    <div
                                        id="customersMap"
                                        className={this.props.fullScreen && isMobile ? "customersMap full-map" : "customersMap remaining vertical-responsive-height"}
                                    />
                                }
                                googleMapElement={
                                    <>{showMap ? <GoogleMap
                                        options={{
                                            fullscreenControl: !isAppleMobile
                                        }}
                                        ref={it => this.refGoogleMap(it, mapParams.filter(param => param.latitude))}
                                        {...defaultMapParams(mapParams, center, null, client)}
                                    >

                                        {isAppleMobile && <Button bsStyle={'light'}
                                                                  className={`${this.props.fullScreen && !isMobile ? 'mr-20' : 'mr-20'} btn-full-screen`}
                                                                  onClick={() => {
                                                                      this.onToggleFullScreen()
                                                                  }}>
                                            <FontAwesomeIcon size={8} icon={faExpand}/></Button>}
                                        {schedulerEvents.map((event, index) => {
                                            const employee = select(employees, event.person_id);
                                            const employeeColor = (employee ? employee.color : '#3a87ad');
                                            return event.site_latitude && (
                                                <Marker
                                                    key={event.id}
                                                    position={{
                                                        lat: event.site_latitude,
                                                        lng: event.site_longitude
                                                    }}
                                                    icon={getBasicMarker({
                                                        selected: event.selected,
                                                        color: employeeColor,
                                                        label: (index + 1).toString()
                                                    })}
                                                    title={event.customer_name}
                                                    onClick={() => {
                                                        const page = this.tableRef.current.getPageByRowKey(event.id);
                                                        this.setState({
                                                            page,
                                                            taskDetailsModal: {
                                                                show: true,
                                                                event: {
                                                                    ...event,
                                                                    task_number: (index + 1).toString()
                                                                }
                                                            }
                                                        });
                                                        this.selectMarker('schedulerEvents')(event);
                                                    }}
                                                    zIndex={event.selected ? 1000 : index + 1}
                                                />
                                            )
                                        })}
                                    </GoogleMap> : null}</>
                                }
                            />
                        </div>
                    </Row>
                    :
                    <div>
                        <SplitPane split="vertical" size={showMap ? "50%" : "98%"} defaultSize={showMap ? "50%" : "98%"}
                                   minSize={500}>
                            <div className="table-wrapper">
                                <Row>
                                    <Col md={6}/>
                                    <Col md={6} className="vcenter">
                                        <FormControl
                                            name="q"
                                            placeholder="Search..."
                                            value={resource.q}
                                            onChange={e => this.updateResourceAttr(e, this.delayedSearch)}
                                        />
                                    </Col>
                                </Row>
                                <BootstrapTable
                                    exportCSV={true}
                                    ref={this.tableRef}
                                    data={schedulerEvents}
                                    striped={true}
                                    bordered={false}
                                    hover={true}
                                    pagination={true}
                                    remote
                                    fetchInfo={{dataTotalSize: total}}
                                    className="wrapped"
                                    csvFileName={() => {
                                        const fileName = `tasks-${moment().format()}.csv`
                                        if (this.tableRef?.current) {
                                            this.props.actions.saveNewCsvDownload(this.tableRef.current, fileName, this.state.resource, "tasks")
                                        }
                                        return fileName
                                    }}
                                    options={
                                        {
                                            onPageChange: (page, per_page) => {
                                                let {resource} = this.state;
                                                resource = {...resource, page, per_page};
                                                this.setState({resource}, this.delayedSearch);
                                            },
                                            page,
                                            sizePerPageList: [{
                                                text: '10', value: 10
                                            }, {
                                                text: '25', value: 25
                                            },
                                                {
                                                    text: '30', value: 30
                                                },
                                                {
                                                    text: '50', value: 50
                                                },
                                                {
                                                    text: '100', value: 100
                                                },
                                                {
                                                    text: '200', value: 200
                                                },
                                                {
                                                    text: 'All', value: total
                                                }],
                                            sizePerPage: per_page,
                                            sizePerPageDropDown: this.renderSizePerPageDropDown,
                                            onRowClick: row => {
                                                this.selectMarkerNoScroll('schedulerEvents')(row);
                                            },
                                            noDataText: ' ',
                                            sortName: sort_by,
                                            sortOrder: sort,
                                            onSortChange: (sort_by, sort) => {
                                                let {resource} = this.state;
                                                resource = {...resource, sort_by, sort};
                                                this.setState({resource}, this.delayedSearch);
                                            }
                                        }
                                    }
                                    selectRow={{
                                        mode: "hidden",
                                        clickToSelect: false,
                                        onSelect: this.onRowSelect,
                                        onSelectAll: this.onSelectAll,
                                    }}
                                >
                                    <TableHeaderColumn
                                        dataField="id"
                                        hidden
                                        width={"5%"}
                                        isKey
                                    />
                                    <TableHeaderColumn
                                        hidden
                                        dataField="array_id"
                                        width={"5%"}
                                        dataSort
                                    >
                                        #
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        export={columnsSettings["Task date"]}
                                        hidden
                                        dataField="date_from"
                                        dataSort
                                        dataFormat={this.dateFromFormatter}
                                        csvFormat={this.csvDateFormatter}
                                        csvHeader="Task date"
                                        width={"10%"}
                                    >
                                        Task date
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        export={columnsSettings["Task Name"]}
                                        hidden
                                        dataField="name"
                                        csvHeader="Task Name"
                                        dataSort
                                        dataFormat={this.nameFormatter}
                                        width={"10%"}
                                        csvFormat={cell => cell ? cell : ''}
                                    >
                                        Task Name
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        export={columnsSettings["Type"]}
                                        hidden
                                        dataField="scheduler_event_type_name"
                                        csvHeader="Type"
                                        dataSort
                                        width={"7%"}
                                    >
                                        Type
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        export={columnsSettings["Assignee"]}
                                        hidden
                                        dataField="person_name"
                                        csvHeader="Assignee"
                                        dataSort
                                        width={"10%"}
                                    >
                                        Assignee
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        export={columnsSettings["Customer"]}
                                        hidden
                                        dataField="customer_name"
                                        dataFormat={this.customerFormatter}
                                        csvFormat={this.csvCustomerFormatter}
                                        csvHeader="Customer"
                                        dataSort
                                        width={"10%"}
                                    >
                                        Customer
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        export={columnsSettings["Location"]}
                                        hidden
                                        dataField="site_location"
                                        dataFormat={this.siteFormatter}
                                        csvFormat={this.csvSiteFormatter}
                                        csvHeader="Location"
                                        dataSort
                                        width={"10%"}
                                    >
                                        Location
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        export={columnsSettings["Lead #"]}
                                        hidden
                                        dataField="scheduler_event_resource_id"
                                        dataFormat={this.leadFormatter}
                                        csvFormat={this.csvLeadFormatter}
                                        csvHeader="Lead #"
                                        dataSort
                                        width={"7%"}
                                    >
                                        Lead #
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        export={columnsSettings["Proposal #"]}
                                        hidden
                                        dataField="proposal_no"
                                        csvHeader="Proposal #"
                                        csvFormat={this.csvProposalFormatter}
                                        dataSort
                                        width={"7%"}
                                    >
                                        Proposal #
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        export={columnsSettings["WO #"]}
                                        hidden
                                        dataField="work_order_no"
                                        csvHeader="WO #"
                                        csvFormat={this.csvWorkOrderFormatter}
                                        dataSort
                                        width={"7%"}
                                    >
                                        WO #
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        export={columnsSettings["Invoice #"]}
                                        hidden
                                        dataField="invoice_no"
                                        csvHeader="Invoice #"
                                        csvFormat={this.csvInvoiceFormatter}
                                        dataSort
                                        width={"7%"}
                                    >
                                        Invoice #
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        export={columnsSettings["Hours"]}
                                        hidden
                                        dataField="service_hours"
                                        csvHeader="Hours"
                                        csvFormat={this.csvServiceHoursFormatter}
                                        dataSort
                                        width={"7%"}
                                    >
                                        Hours
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        export={columnsSettings["Status"]}
                                        hidden
                                        dataField="completed"
                                        dataSort
                                        dataFormat={this.statusFormatter}
                                        csvFormat={this.csvStatusFormatterCompleted}
                                        csvHeader="Status"
                                        width={"10%"}
                                    >
                                        Status
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        export={columnsSettings["Confirmed"]}
                                        hidden
                                        dataField="confirmed"
                                        dataSort
                                        dataFormat={this.statusFormatter}
                                        csvFormat={this.csvStatusFormatterConfirmed}
                                        csvHeader="Confirmed"
                                        width={"10%"}
                                    >
                                        Confirmed
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        export={columnsSettings["On Site"]}
                                        hidden
                                        dataField="on_site"
                                        dataSort
                                        dataFormat={this.statusFormatter}
                                        csvFormat={this.csvStatusFormatterOnSite}
                                        csvHeader="On Site"
                                        width={"10%"}
                                    >
                                        On Site
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        export={columnsSettings["Lifetime spend"]}
                                        hidden
                                        dataField="lifetime_spend"
                                        dataSort
                                        csvFormat={this.csvLifetimeSpendFormatter}
                                        csvHeader={"Lifetime spend"}
                                        width={"10%"}
                                    >
                                        Lifetime spend
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        export={columnsSettings["Close rate"]}
                                        hidden
                                        dataField="close_rate"
                                        dataSort
                                        csvFormat={this.csvCloseRateFormatter}
                                        csvHeader={"Close rate"}
                                        width={"10%"}
                                    >
                                        Close rate
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        export={columnsSettings["Site contacts"]}
                                        hidden
                                        dataField="site_contacts"
                                        dataSort
                                        csvFormat={this.csvSiteContactFormatter}
                                        csvHeader={"Site contacts"}
                                        width={"10%"}
                                    >
                                        Site contacts
                                    </TableHeaderColumn>
                                </BootstrapTable>
                                <div className={classNames('dnd-container', {'min-width-150': showMap})}>
                                    <DragAndDropTable
                                        updateResource={res => {
                                            res.forEach((item, index) => {
                                                item.array_id = (resource.per_page * (resource.page - 1)) + index + 1
                                            })
                                            this.setState({schedulerEvents: res})
                                        }}
                                        data={schedulerEvents}
                                        columns={this.columns}
                                        visibleColumns={columnsSettings}
                                        trClassName={this.selectedMarkerClass}
                                        onRowClick={row => {
                                            this.selectMarkerNoScroll('schedulerEvents')(row)
                                        }}
                                    />
                                </div>
                            </div>
                            <div style={{"visibility": showMap ? "visible" : "hidden"}}>
                                <GoogleMapLoader
                                    containerElement={
                                        <div
                                            id="tasksMap"
                                            className="remaining vertical-responsive-height"
                                        />
                                    }
                                    googleMapElement={
                                        <GoogleMap
                                            ref={it => this.refGoogleMap(it, mapParams.filter(param => param.latitude))}
                                            {...defaultMapParams(mapParams, center, null, client)}
                                        >
                                            {schedulerEvents.map((event, index) => {
                                                const employee = select(employees, event.person_id);
                                                const employeeColor = (employee ? employee.color : '#3a87ad');
                                                return event.site_latitude && (
                                                    <Marker
                                                        key={event.id}
                                                        position={{
                                                            lat: event.site_latitude,
                                                            lng: event.site_longitude
                                                        }}
                                                        icon={getBasicMarker({
                                                            selected: event.selected,
                                                            color: employeeColor,
                                                            label: (index + 1).toString()
                                                        })}
                                                        title={event.customer_name}
                                                        onClick={() => {
                                                            const page = this.tableRef.current.getPageByRowKey(event.id);
                                                            this.setState({
                                                                page,
                                                                taskDetailsModal: {
                                                                    show: true,
                                                                    event: {
                                                                        ...event,
                                                                        task_number: (index + 1).toString()
                                                                    }
                                                                }
                                                            });
                                                            this.selectMarker('schedulerEvents')(event);
                                                        }}
                                                        zIndex={event.selected ? 1000 : index + 1}
                                                    />
                                                )
                                            })}
                                        </GoogleMap>
                                    }
                                />
                            </div>
                        </SplitPane>
                    </div>}
            </Grid>
        );
    }
}

Tasks.propTypes = {
    //myProp: PropTypes.object.isRequired
};

Tasks.defaultProps = {
    //myProp: <defaultValue>
};

function mapStateToProps(state, ownProps) {
    return {
        showTaskDialog: state.userStatus.showTaskDialog,
        userRoles: state.auth.roles,
        client: state.client.customerInfo,
        fullScreen: state.mapView.fullScreen,
        user: state.auth
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Tasks)
