import React, {Component} from "react"
import {bindActionCreators} from "redux"
import {connect} from "react-redux"
import Helmet from "react-helmet"
import {addAlert} from "../App/actions"
import {fetchSalesArborists} from "../../actions/salesArborists"
import * as MyActions from "./actions"
import {Link} from "react-router"
import Dollars from "../../components/Dollars"
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table"
import Select from 'react-select'
import {
    ButtonGroup,
    Col,
    FormGroup,
    Glyphicon,
    Grid,
    MenuItem,
    Nav,
    NavDropdown,
    OverlayTrigger,
    Popover,
    Row,
} from "react-bootstrap"
import {LinkContainer} from "react-router-bootstrap"
import moment from "moment-timezone"
import {defaultDateFormatShort, mapForSelect, select} from "../../common/commonHandlers";

const Actions = {...MyActions, addAlert, fetchSalesArborists}

class SalesFunnel extends Component {
    state = {
        acceptedProposals: "accepted",
        proposedProposals: "proposed",
        woProposals: "in_wo",
    }

    componentWillMount() {
        this.props.actions.fetchSalesArborists()
    }

    selectSalesArborist = (e) => {
        this.setState({
            salesArboristId: e === null ? null : e.value
        })
    }

    render() {
        const {
            myProposals,
            salesArborists,
        } = this.props

        const {
            salesArboristId,
        } = this.state
        const salesArboristsOptions = mapForSelect(salesArborists);
        return (
            <Grid fluid>
                <Helmet title="Sales Funnel"/>
                <form>
                    <Row>
                        <Col md={3}>
                            <Select className="Select" classNamePrefix="select"
                                    name="sales-arborist-select"
                                    value={select(salesArboristsOptions, salesArboristId)}
                                    options={salesArboristsOptions}
                                    onChange={this.selectSalesArborist}
                                    placeholder="All Arborists"
                            />
                        </Col>
                        <Col md={9} className="text-left">
                            <ButtonGroup>
                                <button
                                    type="button"
                                    className="btn btn-warning"
                                    onClick={this.fetchProposedProposals}
                                >
                                    Proposed
                                    {" "}
                                    <span className="badge badge-light">
                  {myProposals
                      ? <Dollars
                          amount={parseFloat(
                              myProposals.reduce((sum, p) => sum + p.proposalTotal, 0).toFixed(2),
                              10
                          )}
                      />
                      : null}
                </span>
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={this.fetchAcceptedProposals}
                                >
                                    Accepted
                                    {" "}
                                    <span className="badge badge-light">
                  {myProposals ?
                      <Dollars
                          amount={parseFloat(myProposals.filter((openP) => openP.acceptedDate !== null).reduce((sum, p) => sum + p.acceptedTotal, 0).toFixed(2),
                              10
                          )}
                      />
                      : null}
                </span>
                                </button>
                                <button type="button" className="btn btn-primary">
                                    Promised
                                    <span className="badge badge-light"></span>
                                </button>
                                <button type="button" className="btn btn-primary" onClick={this.fetchWoProposals}>
                                    In Work Order
                                    {" "}
                                    <span className="badge badge-light">
                  {myProposals ?
                      <Dollars
                          amount={parseFloat(myProposals.filter((openP) => openP.acceptedDate !== null).reduce((sum, p) => sum + p.woTotal, 0).toFixed(2),
                              10
                          )}
                      />
                      : null}
                </span>
                                </button>
                                <button type="button" className="btn btn-primary">
                                    Completed
                                    <span className="badge badge-light"></span>
                                </button>
                                <button type="button" className="btn btn-primary">
                                    Invoiced
                                    <span className="badge badge-light"></span>
                                </button>
                                <button type="button" className="btn btn-primary">
                                    Paid
                                    <span className="badge badge-light"></span>
                                </button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <FormGroup bsSize="sm">
                                <Col md={2}>
                                    {}
                                </Col>
                            </FormGroup>
                        </Col>

                    </Row>
                </form>
                <p>&nbsp;</p>

                <Row>
                    <Col md={12}>
                        {myProposals ? this._renderTable(myProposals) : null}
                    </Col>
                </Row>

                <Row>
                    <Col md={12}/>
                </Row>

            </Grid>
        )
    }

    fetchMyProposals = e => {
        e.preventDefault()
        this.props.actions.fetchMyProposals(this.state.salesArboristId)
    }


    fetchAcceptedProposals = e => {
        e.preventDefault()
        this.props.actions.fetchAcceptedProposals(this.state.acceptedProposals)
    }

    fetchProposedProposals = e => {
        e.preventDefault()
        this.props.actions.fetchProposedProposals(this.state.proposedProposals)
    }

    fetchWoProposals = e => {
        e.preventDefault()
        this.props.actions.fetchWoProposals(this.state.woProposals)
    }

    _dateFormatter = cell => cell && moment(cell).format(defaultDateFormatShort)

    _dollarFormatter = cell => <Dollars amount={cell}/>

    totalSwitcher = (cell, row, fetchProposedProposals) => (
        this.props.actions.fetchProposedProposals() ? this.proposalTotal : this.acceptedTotal
    )
    _renderTable = data => {
        const {showFilters} = this.state
        const options = {
            page: 1,  //which page you want to show as default
            sizePerPageList: [5, 25, 75, 100], //you can change the dropdown list for size per page
            sizePerPage: 25,  //which size per page you want to locate as default
            paginationSize: 3,  //the pagination bar size.
        }

        const toggleColumnFilters = () => {
            this.setState({
                showFilters: !this.state.showFilters
            })
        }
        return (
            <BootstrapTable
                data={data}
                striped={true}
                bordered={false}
                hover={true}
                exportCSV={true}
                search={true}
                pagination={true}
                csvFileName={`Proposals-${moment().format()}.csv`}
                options={options}
            >
                <TableHeaderColumn
                    width="20%"
                    dataField="name"
                    tdStyle={{whiteSpace: 'normal'}}
                    thStyle={{whiteSpace: 'normal'}}
                    dataFormat={this._nameFormatter}
                    dataSort={true}
                    columnClassName='menu-show-fixer'
                >
                    Customer
                </TableHeaderColumn>

                <TableHeaderColumn
                    width="10%"
                    dataField="arborist"
                    dataSort={true}
                >
                    Arborist
                </TableHeaderColumn>

                <TableHeaderColumn
                    width="10%"
                    dataField="proposalNo"
                    dataFormat={this._proposalLinkFormatter}
                    isKey={true}
                    dataSort={true}
                    columnClassName='menu-show-fixer'
                >
                    Proposal No
                </TableHeaderColumn>

                <TableHeaderColumn
                    width="10%"
                    dataField="proposedDate"
                    dataFormat={this._stackDateFormater}
                    dataSort={true}
                >
                    Dates
                </TableHeaderColumn>

                <TableHeaderColumn
                    width="10%"
                    dataField="proposalTotal"
                    dataFormat={this._dollarFormatter}
                    dataSort={true}>
                    Proposed
                </TableHeaderColumn>

                <TableHeaderColumn
                    width="10%"
                    dataField="acceptedTotal"
                    dataFormat={this._dollarFormatter}
                    dataSort={true}>
                    Accepted
                </TableHeaderColumn>

                <TableHeaderColumn
                    width="10%"
                    dataField="woTotal"
                    dataFormat={this._dollarFormatter}
                    dataSort={true}>
                    In WO
                </TableHeaderColumn>

                <TableHeaderColumn
                    width="10%"
                    dataField="woTotal"
                    dataFormat={this._dollarFormatter}
                    dataSort={true}>
                    Completed
                </TableHeaderColumn>

            </BootstrapTable>
        )
    }
    _optCustomer = e => {
        const customerId = e.target.value
        const optedOut = e.target.checked
        this.props.actions.optCustomer(customerId, optedOut)
    }

    _linkFormatter = (cell, row) => (
        <Nav>
            <NavDropdown
                eventKey={2}
                title={`${row.name}`}
                id={`proposal-menu`}
                className="menu-show-fixer proposal-menu-padding-reset"
            >
                <LinkContainer to={`/customer/info/${row.id}`}>
                    <MenuItem bsSize="small">Info</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/customer/proposals/${row.id}`}>
                    <MenuItem bsSize="small">Proposals</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/customer/sites/${row.id}`}>
                    <MenuItem bsSize="small">Sites</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/customer/work_orders/${row.id}`}>
                    <MenuItem bsSize="small">Work Orders</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/customer/invoices/${row.id}`}>
                    <MenuItem bsSize="small">Invoices</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/customer/work_history/${row.id}`}>
                    <MenuItem bsSize="small">Work History</MenuItem>
                </LinkContainer>
            </NavDropdown>
        </Nav>
    )

    _nameFormatter(cell, row) {
        // get name by row.name
        // get description by row.siteAddress
        return (
            <div style={{display: 'grid'}}>
                <div>
                    <strong>
                        {row.name}
                    </strong>
                </div>
                <div>
                    <small>
                        <strong>Site: </strong>
                        {row.siteAddress}
                    </small>
                </div>
            </div>
        );
    }

    _stackDateFormater(cell, row) {
        const popoverRight = (
            <Popover id="popover-positioned-top" title="Status Dates">
                <div style={{display: 'grid'}}>
                    <div>
                        <strong>
                            <small>
                                {row.proposedDate ?
                                    `Proposed: ${row && moment(row.proposedDate).format(defaultDateFormatShort)}`
                                    : null}
                            </small>
                        </strong>
                    </div>
                    <div>
                        <strong>
                            <small>
                                {row.acceptedDate ?
                                    `Accepted: ${row && moment(row.acceptedDate).format(defaultDateFormatShort)}`
                                    : null}
                            </small>
                        </strong>
                    </div>
                </div>
            </Popover>
        );
        return (
            <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={popoverRight}>
                <h6
                    className="text-small text-info text-center"
                >
                    {`${row && moment(row.proposedDate).format(defaultDateFormatShort)}`}
                </h6>
            </OverlayTrigger>
        );
    }

    _proposalLinkFormatter = (cell, row) => (
        <Nav>
            <NavDropdown
                eventKey={2}
                title={cell}
                id={`proposal-menu`}
                className="menu-show-fixer proposal-menu-padding-reset"
            >
                <LinkContainer to={`/mapview/${row.proposalId}`}>
                    <MenuItem bsSize="small">Edit Proposal</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/proposal_service_status/${row.proposalId}`}>
                    <MenuItem bsSize="small">Proposal Statuses</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/print_proposal/${row.proposalId}`}>
                    <MenuItem bsSize="small">Print Proposal</MenuItem>
                </LinkContainer>
            </NavDropdown>
        </Nav>
    )

    _acceptFormatter = (cell, row) => (
        <Link to={`/proposal_service_status/${row.proposalId}`}>
            <span className='text-success'><Glyphicon glyph='ok'/></span>
        </Link>
    )

    _blankFormatter = (cell, row) => (
        <Link to={`/proposal_service_status/${row.proposalId}`}>
            <span className='text-success'></span>
        </Link>
    )
}

function mapStateToProps(state) {
    return {
        proposalProposedStart: state.myProposals.proposalProposedStart,
        proposalProposedEnd: state.myProposals.proposalProposedEnd,
        status: state.myProposals.status,
        myProposals: state.myProposals.myProposals,
        filterRenewalsBy: state.myProposals.filterRenewalsBy,
        salesArborists: state.salesArborists.salesArboristsList,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Actions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SalesFunnel)
