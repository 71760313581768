import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import createApiService from "../../../common/clientActionsBuilder";
import {Button, Col, ControlLabel, Form, FormGroup, Glyphicon, Grid, Row} from "react-bootstrap";
import ResourceComponent from "../../../components/ResourceComponent";
import * as serviceConfigurationApi from "./ServiceConfigurationApi";
import {browserHistory} from "react-router";
import ColorCheckbox from "../../Scheduler/ColorCheckbox";
import {select} from "../../../common/commonHandlers";
import Select from "react-select";

const api = createApiService('clients', 'client', 'Client');
const actions = {...api, ...serviceConfigurationApi};

class QuickbooksTab extends ResourceComponent {


    constructor(props, context) {
        super(props, context);
        this.state = {
            resource: {},
            credentials: {},
            quickbooksCredentials: {}
        };
        this.qbAccountSearch = {
            searchOptions: () => [],
            search: () => {
            }
        };
    }

    componentDidMount() {
        this.reload();
        this.qbAccountSearch = this.buildSearchComponent('qb_account', this.props.actions.searchForQbAccount);

    }

    reload = () => {
        this.props.actions.get(this.props.id, resource => this.setState({resource}));
        this.props.actions.loadQuickbooksCredentials(this.props.clientId, result => {
            this.setState({quickbooksCredentials: result})
            this.qbAccountSearch.search(result.income_account)
        });
    };

    render() {
        const {resource, credentials, quickbooksCredentials} = this.state;
        const accountOptions = this.qbAccountSearch.searchOptions();
        return (
            <Grid fluid className="top25">
                <Form horizontal onSubmit={e => {
                    e.preventDefault();
                    this.props.actions.saveQbSettings(resource.income_account_id, resource.auto_qb_sync)
                }}>
                    <Row className="top50">
                        <Col md={9}>
                            <FormGroup>
                                <Col mdOffset={4}>
                                    <Col md={8}>
                                        <h4>Quickbooks</h4>
                                    </Col>
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={9}>
                            <FormGroup>
                                <Col componentClass={ControlLabel} md={4}>
                                    Connect with Quickbooks
                                </Col>
                                <Col md={6}>
                                    {quickbooksCredentials.quickbooks_realm_id ?
                                        <a className="connect-button disconnect"
                                           onClick={() => this.props.actions.disconnectQuickbooks(() => this.reload())}>
                                            <span>Disconnect</span>
                                        </a>
                                        :
                                        <a className="connect-button quickbooks"
                                           onClick={() => this.props.actions.connectWithQuickbooks((result) => {
                                               window.location.replace(result.data.url)
                                           })}><span><img src="quickbooks.png"/> Connect</span></a>
                                    }
                                    {quickbooksCredentials.quickbooks_realm_id &&
                                        <a className="connect-button sync ml-9"
                                           onClick={() => browserHistory.push(`/quickbooks`)}><span>Sync</span></a>}
                                    <div className='mt-15'>
                                        <Glyphicon glyph="info-sign text-info" className="notification"/>
                                        <span className='text-info text-small small-margin'>
                                We allow importing following quickbooks custom fields: Sales Rep, Proposal Number.
                                If you want them to be imported in your invoices please add them manually in your quickbooks online account through quickbooks admin.
                            </span>
                                    </div>
                                    <div className='mt-15'>
                                        <Glyphicon glyph="info-sign text-info" className="notification"/>
                                        <span className='text-info text-small small-margin'>
                                For services synchronization you have to go to your quickbooks account and enable classes.
                            </span>
                                    </div>
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={9}>
                            <Row>
                                <Col componentClass={ControlLabel} md={4}>
                                    Allow automatic synchronization
                                </Col>
                                <Col md={6}>
                                    <FormGroup bsSize="small">
                                        <ColorCheckbox value={resource.auto_qb_sync}
                                                       label='Allow automatic sync on for customers and services'
                                                       onChange={this.selectCheckboxAttr('auto_qb_sync')}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={9}>
                            <FormGroup>
                                <Col componentClass={ControlLabel} md={4}>
                                    <span>Pick an income account</span>
                                </Col>
                                <Col md={6}>
                                    <FormGroup bsSize="small">
                                        <Select className="Select"
                                                classNamePrefix="select"
                                                value={select(accountOptions, resource.income_account_id)}
                                                options={accountOptions}
                                                placeholder="Account"
                                                isClearable
                                                onInputChange={this.qbAccountSearch.search}
                                                onChange={this.selectResourceAttr('income_account_id')}
                                        />
                                    </FormGroup>
                                    <div className='mt-15'>
                                        <Glyphicon glyph="info-sign text-info" className="notification"/>
                                        <span className='text-info text-small small-margin'>
                                            For services synchronization you have to pick an income account.
                                        </span>
                                    </div>
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col mdOffset={3} md={1}>
                            <Button
                                block
                                type="submit"
                                bsStyle="success"
                            >
                                Save
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Grid>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        state: state,
        clientId: state.auth.client_id
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(QuickbooksTab);
