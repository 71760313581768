import {FETCH_CHECKLIST_MODAL, FETCH_EQUIPMENT_LIST} from '../constants'

const initialState = {
    equipmentList: [],
    equipment: {},
    equipmentListLastUpdated: 0,
    showEquipmentChecklistModal: false
};

export function equipmentReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_EQUIPMENT_LIST:
            return {
                ...state,
                equipmentList: action.equipmentList,
                equipmentListLastUpdated: action.equipmentListLastUpdated,
            };
        case FETCH_CHECKLIST_MODAL:
            return {
                ...state,
                showEquipmentChecklistModal: action.status
            }
        default:
            return state
    }
}
