import React, {useCallback, useEffect, useState} from 'react';
import {usePlaidLink} from 'react-plaid-link';
import axios from "axios";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight, faCheckCircle, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router"
import plaidImg from './plaid.png'
import './OAuthPage.css'

const OAuthPage = () => {
    const [connected, setConnected] = useState(false)
    const [invoiceToken, setInvoiceToken] = useState(null)
    const [error, setError] = useState('')

    const linkToken = localStorage.getItem('link_token');

    const onSuccess = useCallback((public_token, metadata) => {
        const url = '/api/stripe/plaid_create';
        axios.post(url, {
            public_token: public_token,
            account_id: metadata.account_id
        }).then(response => {
            if (response.status === 200) {
                localStorage.setItem('plaid_customer', response.data.customer)
                setConnected(true)
            }
        })
    }, []);

    const onExit = (err, metadata) => {
        setError(metadata.status)
    };

    const config = {
        token: linkToken,
        receivedRedirectUri: window.location.href,
        onSuccess,
        onExit,
    };

    const {open, ready} = usePlaidLink(config);

    useEffect(() => {
        if (ready) {
            setInvoiceToken(localStorage.getItem('invoice_token'))
            open();
        }
    }, [ready, open]);

    const clearStorage = () => {
        const localStorageData = ["invoice_token", "link_token", "plaid_customer"]
        localStorageData.map(data => localStorage.removeItem(data))
    }

    const icon = error ? faTimesCircle : faCheckCircle
    const text = error ? 'Back to TreeHub' : 'Continue Payment in TreeHub'

    if (connected || error) {
        return (
            <div className='oauth_page_wrapper'>
                <img src={plaidImg} className='oauth_page_plaid_logo'/>
                <div className='vertical-align bottom5'>
                    <FontAwesomeIcon icon={icon}
                                     className={`${error ? 'oauth_page_error_icon' : 'accept'} font20 mr-5`}/>
                    <h4>{`Accounts have ${error && 'not'} been connected`}</h4>
                </div>
                <Link onClick={() => error && clearStorage()} to={`/customer_view/invoice/${invoiceToken}`}
                      className='vertical-align'>
                    <span className='mr-5'>{text}</span>
                    <FontAwesomeIcon icon={faArrowRight}/>
                </Link>
            </div>)
    } else {
        return null
    }
};

export default OAuthPage
