import {
    ADD_PS_TO_ACCEPT,
    PROPOSAL_ACCEPTANCE_SUCCESS,
    PROPOSAL_LOADED,
    REMOVE_PS_TO_ACCEPT,
    SELECT_ALL_PS_TO_ACCEPT,
    SELECT_NONE_PS_TO_ACCEPT,
    UPDATE_PROPOSAL_ACCEPTED_DATE,
    UPDATE_PROPOSAL_NO
} from './constants'

import moment from 'moment'
import axios from 'axios'
import {addAlert} from '../App/actions'
import {browserHistory} from 'react-router'
import {defaultDateFormat} from "../../common/commonHandlers";

export function findProposal() {
    return function (dispatch, getState) {
        const store = getState()

        const config = {
            method: 'get',
            headers: {'Authorization': store.auth.token},
            url: '/api/proposals?q=' + store.acceptProposals.proposalNo
        }

        axios.request(config)
            .then(response => {
                dispatch({type: PROPOSAL_LOADED, foundProposal: response.data})
                const proposalAcceptedDate = (response.data.acceptedDate === null) ? moment() : moment(response.data.acceptedDate)
                dispatch(updateProposalAcceptedDate(proposalAcceptedDate.format(defaultDateFormat)))
                // dispatch(addAlert({message: "Proposal found", dismissAfter: 1500, mood: 'success'}))
            })
            .catch(error => {
                if (error.response && error.response.status === 404) {
                    dispatch(addAlert({message: "Proposal was not found"}))
                }
            })
    }
}

export function updateProposalAcceptedDate(val) {
    return {type: UPDATE_PROPOSAL_ACCEPTED_DATE, proposalAcceptedDate: val}
}

export function updateProposalNo(val) {
    console.log("update proposal no")
    return {type: UPDATE_PROPOSAL_NO, proposalNo: val}
}

export function addPsToAccept(psId) {
    return {type: ADD_PS_TO_ACCEPT, psId}
}

export function removePsToAccept(psId) {
    return {type: REMOVE_PS_TO_ACCEPT, psId}
}

export function selectAllPsToAccept() {
    //this totally doesn't need to be a thunk but we want access to the store
    //alternatively we could have the reducer do the work since it has access
    //to the proposal. not sure which way is best.
    return function (dispatch, getState) {
        const store = getState()
        const psToAccept = store.acceptProposals.foundProposal.proposalServices.map(ps => ps.id.toString())
        dispatch({type: SELECT_ALL_PS_TO_ACCEPT, psToAccept})
    }
}

export function selectNonePsToAccept() {
    return {type: SELECT_NONE_PS_TO_ACCEPT, psToAccept: []}
}

export function acceptProposalServices() {
    return function (dispatch, getState) {
        const store = getState()

        if (store.acceptProposals.psToAccept.length < 1 || !Array.isArray(store.acceptProposals.psToAccept)) {
            dispatch(addAlert({
                message: 'Please check at least 1 Proposal Service to accept',
                mood: 'warning',
                dismissAfter: 3500
            }))
            return false
        }

        //the calendar is pretty good about coming up with any valid date no matter what you do but just in case.
        //TODO: note that Rails wants the date as MM/DD/YYYY format so we do "L" in moment. it would be nice to give it a full timestamp when we get off MCX
        try {
            const tryDate = moment(store.acceptProposals.proposalAcceptedDate).isValid()
            if (!tryDate) {
                dispatch(addAlert({
                    message: 'The Accepted Date does not look like a date. Please re-set the Accepted Date.',
                    mood: 'warning',
                    dismissAfter: 3500
                }))
                return false
            }
        } catch (e) {
            dispatch(addAlert({
                message: 'The Accepted Date does not look like a date. Please select the Accepted Date again.',
                mood: 'warning',
                dismissAfter: 3500
            }))
            return false
        }

        const config = {
            method: 'post',
            headers: {'Authorization': store.auth.token},
            url: '/api/proposal_services/accept',
            data: {
                proposal_services_to_accept: store.acceptProposals.psToAccept.join(','),
                new_proposal_accepted_date: store.acceptProposals.proposalAcceptedDate
            }
        }

        axios.request(config)
            .then(response => {
                const proposalId = store.acceptProposals.foundProposal.id
                dispatch({type: PROPOSAL_ACCEPTANCE_SUCCESS})
                dispatch(addAlert({message: "Proposal Services Accepted.", mood: 'success', dismissAfter: 1500}))
                browserHistory.push(`/proposals/${proposalId}`)

            })
            .catch(error => {
                if (error.response) {
                    console.log("ohshit", error)
                    dispatch(addAlert({message: "Proposal Services were not Accepted." + error.response.data.message}))
                }
            })

    }
}
