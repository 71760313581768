import React from "react";
import {Button, Col, Modal, Row} from "react-bootstrap";

const ConfirmDeletionModal = ({onHide, onDelete, isDocument}) => {
    return <Modal
        show={true}
        animation={true}
        className={"heightAuto maxHeight90 fontIOS"}
        id='confirm-deleted-modal'
        onHide={() => onHide()}
    >
        <Modal.Header>
            <Modal.Title>Confirm {isDocument ? 'deletion' : 'saving'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <h4 className='text-danger'>Are you sure you want
                to {isDocument ? 'permanently delete the document' : 'delete this file'}?</h4>
            {isDocument ?
                <h5>The document will be permanently deleted. Once removed, you will not be able to undo it!</h5>
                :
                <h5>If you continue, it will be permanently deleted!</h5>
            }
        </Modal.Body>
        <Modal.Footer>
            <Row>
                <Col md={6} className="text-left">
                    <Button
                        bsStyle="success"
                        bsSize="small"
                        onClick={() => onHide()}
                    >
                        Close
                    </Button>
                </Col>
                <Col md={6} className="text-right">
                    <Button
                        bsStyle="danger"
                        bsSize="small"
                        onClick={() => onDelete()}
                    >
                        {isDocument ? 'Delete' : 'Continue'}
                    </Button>
                </Col>
            </Row>
        </Modal.Footer>
    </Modal>
}

export default ConfirmDeletionModal;