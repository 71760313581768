import {dataUpload} from "../../../../common/upload";
import {apiCall} from "../../../../common/apiActionHandler";
import axios from "axios";
import * as FileSaver from "file-saver";

export const importClients = (acceptedFiles, callback) => (dispatch, getState) => {
    dataUpload('/api/imports/import_clients', acceptedFiles, callback, resp => `${resp.body.imported} customers imported, ${resp.body.skipped} skipped`)(dispatch, getState);
};

export const importSites = (acceptedFiles, callback) => (dispatch, getState) => {
    dataUpload('/api/imports/import_sites', acceptedFiles, callback, resp => `${resp.body.imported} sites imported, ${resp.body.skipped} skipped`)(dispatch, getState);
};

export const importPlants = (acceptedFiles, callback) => (dispatch, getState) => {
    dataUpload('/api/imports/import_plants', acceptedFiles, callback, resp => `${resp.body.imported} plants imported, ${resp.body.skipped} skipped`)(dispatch, getState);
};

export const importAssets = (acceptedFiles, callback) => (dispatch, getState) => {
    dataUpload('/api/imports/import_assets', acceptedFiles, callback, resp => `${resp.body.imported} assets imported, ${resp.body.skipped} skipped`)(dispatch, getState);
};

export const importServices = (acceptedFiles, callback) => (dispatch, getState) => {
    dataUpload('/api/imports/import_services', acceptedFiles, callback, resp => `${resp.body.imported} services imported, ${resp.body.skipped} skipped`)(dispatch, getState);
};

export const importProposals = (acceptedFiles, callback) => (dispatch, getState) => {
    dataUpload('/api/imports/import_proposals', acceptedFiles, callback, resp => `${resp.body.imported} proposals imported, ${resp.body.skipped} skipped`)(dispatch, getState);
};

export const importProposalServices = (acceptedFiles, callback) => (dispatch, getState) => {
    dataUpload('/api/imports/import_proposal_services', acceptedFiles, callback, resp => `${resp.body.imported} proposals imported, ${resp.body.skipped} skipped`)(dispatch, getState);
};

export const downloadCsv = (template, data_name) => async (dispatch, getState) => {
    const config = {
        url: `/api/imports/csv`,
        params: {template: template}
    };

    apiCall("Downloading CSV template", config, async config => {
        const response = await axios.request(config);
        const blob = new Blob([response.data], {
            type: 'text/csv',
        });
        FileSaver.saveAs(blob, `${data_name}.csv`);

        blob.name = `${data_name}.csv`
        dataUpload(`/api/download_history`, blob, null, null, {
            params: JSON.stringify(template ? template : {}),
            source: "imports"
        }, false)(dispatch, getState)
    }, dispatch, getState);
};

export const getUserImportSettings = (callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/users/get_import_settings`
    };

    apiCall("Getting user import settings", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const updateUserImportSettings = (settings, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/users/update_import_settings`,
        method: 'PUT',
        data: {settings}
    };

    apiCall("Updating import settings", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState);
}
