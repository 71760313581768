import {PROPOSAL_FETCHED} from './constants'

const initialState = {
    fetchedProposal: null,
}

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case PROPOSAL_FETCHED:
            return {...state, fetchedProposal: action.fetchedProposal}
        default:
            return state
    }
}

export default reducer