import React from 'react';
import ReactDOM from 'react-dom';
import App from './containers/App';
import {client} from 'scrumship-client';

client.log('EPEXOf8');

ReactDOM.render(
    <App/>,
    document.getElementById('root')
);
