import React, {useEffect, useState} from 'react';
import {Button, Col, ProgressBar, Row} from "react-bootstrap";
import * as licencesApi from './LicencesApi'
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import moment from "moment";
import './licences.scss'
import Dollars from "../../../components/Dollars";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import {dollarsFormatter, downloadLinkFormatter} from "../../../components/BootStrapTableCellFormatters";
import {defaultDateFormat} from "../../../common/commonHandlers";

const actions = {...licencesApi}

const Licences = (props) => {
    const [stripeCredentials, setStripeCredentials] = useState(null)
    const [activeUsers, setActiveUsers] = useState(0)
    const [stripeSubscription, setStripeSubscription] = useState(null)
    const [trialUsersLimit, setTrialUsersLimit] = useState('100')
    const [invoices, setInvoices] = useState([])
    const [subscription, setSubscription] = useState()
    const [autoRenew, setAutoRenew] = useState(true)
    const [page, setPage] = useState(1)
    const [per_page, setPer_page] = useState(5)
    const [firstUserPrice, setFirstUserPrice] = useState(0)
    const [userPrice, setUserPrice] = useState(0)
    const [permanentSubscription, setPermanentSubscription] = useState(false)


    const loadSubscription = () => {
        props.actions.getClientSubscriptionInfo(props.client, res => {
            setSubscription(res.stripe_subscription)
            setAutoRenew(res.stripe_subscription.auto_renew)
            if (res.stripe_subscription.subscription_id) {
                props.actions.getClientPrice(res.stripe_subscription.stripe_price, res => {
                    setFirstUserPrice(res.first)
                    setUserPrice(res.next)
                })
            }
        })
    }
    const loadInvoices = () => {
        props.actions.getInvoices(props.client, res => {
            setInvoices(res)
        })
    }
    const loadCredentials = () => {
        props.actions.loadStripeCredentials(props.client, res => {
            setStripeCredentials(res)
            setTrialUsersLimit(res.stripe_subscription.trial_users_limit)
            if (!res.permanent_subscription) {
                loadSubscription()
            }
        });
    }

    const loadActiveUsersCount = () => {
        props.actions.getActiveUsersCount(props.client, res => {
            setActiveUsers(res.active_users_count)
        });
    }

    const changeTrialPermission = permission => {
        props.actions.changeTrialPermission(props.client, permission, () => {
            loadCredentials()
        });
    }

    const createTrialSubscription = () => {
        props.actions.createTrialSubscription(props.client, () => {
            loadCredentials()
        });
    }

    const changeTrialUsersLimit = () => {
        props.actions.changeTrialUsersLimit(props.client, trialUsersLimit, () => loadCredentials());
    }

    const changePermanentSubscription = () => {
        props.actions.changePermanentSubscription(props.client, () => loadCredentials())
    }
    const changeAutoRenew = () => {
        props.actions.updateSubscription(props.client, subscription, !autoRenew, () => {
            loadSubscription()
            loadCredentials()
        })
    }

    const renderNoLicenceScreen = () =>
        <>
            <div className="licences-section">
                <Row>
                    {stripeCredentials?.permanent_subscription ? <p>Selected client have permanent subscription.</p> :
                        <p>Selected client don't have any subscription.</p>}
                </Row>
            </div>
        </>

    const renderLicenceScreen = () =>
        <Col xs={12} className="licences-section">
            <Row>
                <p><b>Users:</b> {activeUsers}/{stripeCredentials?.stripe_subscription.user_count}</p>
                <ProgressBar now={(activeUsers / stripeCredentials?.stripe_subscription.user_count) * 100}/>
            </Row>
            <Row>
                <p><b>Monthly fee:</b> <Dollars
                    amount={(stripeCredentials?.stripe_subscription.user_count - 1) * userPrice + firstUserPrice}/></p>
                <p><b>Created:</b> {moment.unix(stripeSubscription.created).format(defaultDateFormat)}</p>
                <p><b>Start date:</b> {moment.unix(stripeSubscription.current_period_start).format(defaultDateFormat)}
                </p>
                <p>
                    <b>{stripeCredentials?.stripe_subscription?.auto_renew ? 'Next payment date:' : 'Expire date:'}</b> {moment.unix(stripeSubscription.current_period_end).format(defaultDateFormat)}
                </p>
            </Row>
        </Col>

    const renderSizePerPageDropDown = (props) => {
        return (
            <div className="btn-group dropup">
                <button className="btn btn-info dropdown-toggle" type="button" id="dropdownMenu2"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span>{props.currSizePerPage}</span>
                    <span className="ml-6 caret"></span>
                </button>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                    {[5, 10, 25, 50].map(item =>
                        <li>
                            <a
                                className='table-pagination dropdown-item'
                                onClick={() => props.changeSizePerPage(item)}
                            >{item}</a>
                        </li>
                    )}
                </ul>
            </div>
        );
    }
    const renderInvoicesSection = () => <div className="licences-section">
        <Row>
            <b>Invoices</b>
            <BootstrapTable
                data={invoices}
                striped={true}
                bordered={false}
                hover={true}
                options={{
                    onPageChange: (page, per_page) => {
                        setPage(page)
                        setPer_page(per_page)
                    },
                    sizePerPageDropDown: renderSizePerPageDropDown,
                    page: page,
                    sizePerPage: per_page,
                }
                }
                pagination
            >
                <TableHeaderColumn hidden isKey dataField="id">
                    Id
                </TableHeaderColumn>
                <TableHeaderColumn dataField="full_date" width='50%'>
                    Name
                </TableHeaderColumn>
                <TableHeaderColumn dataField="amount" width='20%' dataFormat={dollarsFormatter}>
                    Amount
                </TableHeaderColumn>
                <TableHeaderColumn dataField="invoice_pdf" dataFormat={downloadLinkFormatter}
                                   width='30%' dataAlign='center' headerAlign='center'>
                    Download
                </TableHeaderColumn>
            </BootstrapTable>
        </Row>
    </div>

    const renderAutoRenew = () =>
        <div className="licences-section">
            <Row>
                <div><b>Auto renew:</b> {subscription?.auto_renew ? 'Enabled' : 'Disabled'}</div>
                <Button className="licences-button top-margin" onClick={() => changeAutoRenew()}>Toggle
                    auto renew</Button>
            </Row>
        </div>

    const renderGeneralSettings = () =>
        <>
            <div className="licences-section">
                <Row>
                    <div><b>Permanent
                        subscription:</b> {stripeCredentials?.permanent_subscription ? 'Enabled' : 'Disabled'}</div>
                    <Button className="licences-button top-margin" onClick={() => changePermanentSubscription()}>Toggle
                        permanent subscription</Button>
                </Row>
            </div>
        </>

    useEffect(() => {
        loadCredentials()
        loadActiveUsersCount()
        loadInvoices()
    }, [props.client])

    useEffect(() => {
        stripeCredentials?.stripe_subscription.subscription_id ?
            props.actions.getSubscription(stripeCredentials.stripe_subscription.subscription_id, res => setStripeSubscription(res.stripe_subscription)) :
            setStripeSubscription(null)
    }, [props.client, stripeCredentials])

    return (
        <div className="licences">
            <div className="licences-section">
                <Row><h4>Subscription:</h4></Row>
            </div>
            {stripeCredentials?.stripe_subscription.subscription_id && stripeSubscription ? <>{renderLicenceScreen()}
                <hr></hr>
                {renderInvoicesSection()}
                <hr></hr>
                {renderAutoRenew()}</> : renderNoLicenceScreen()}
            <hr></hr>
            {renderGeneralSettings()}
        </div>
    );
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

export default connect(null, mapDispatchToProps)(Licences);

