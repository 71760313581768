import {addAuthorizationHeader, addTimeZoneHeader, apiCall} from "../../common/apiActionHandler";
import axios from "axios/index";
import {defaultDateFormat, mapPersonSearch} from "../../common/commonHandlers";
import {addAlert} from "../App/actions";
import * as FileSaver from "file-saver";
import moment from "moment";
import {dataUpload} from "../../common/upload";
import {FETCH_CHECKLIST_MODAL} from "../../constants";

export const load = (filter, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/crew_work_orders`,
        params: filter
    };

    apiCall("Getting crew work orders", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const loadContextData = (userRole, callback) => (dispatch, getState) => {
    const config = [
        {url: `/api/search/all_employees?user_role=${userRole}`},
        {url: `/api/crew_messages_user?type=unread`},
        {url: `/api/search/all_employees`},
    ];

    apiCall("Loading data", config, async config => {
        let actions = config.map(c => axios.request(c));
        const all = await axios.all(actions);
        const data = {
            employees: all[0].data,
            crewMessages: all[1].data,
            all_employees: all[2].data
        };
        callback(data);
    }, dispatch, getState, false);
};

export const getCrewMessages = (type, personId, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/crew_messages_user`,
        params: {type, author: personId}
    };

    apiCall("Getting user crew messages", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false);
};

export const markAsRead = (crew_message_user_id, mark_all_as_read, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/crew_messages/mark_as_read`,
        method: "PUT",
        data: {
            crew_message_user_id: crew_message_user_id,
            mark_all_as_read
        }
    };

    apiCall("Mark message as read", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState);
};

export const getSchedulerEvent = (work_order_id, person_id, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/scheduler_events/get_event_by_wo?work_order_id=${work_order_id}&person_id=${person_id}`,
    };

    apiCall("Getting event", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const downloadRoutePdf = (filter, route, callback, errorCallback) => async (dispatch, getState) => {
    let config = {
        url: `/api/restricted/route_pdf`,
        method: 'GET',
        responseType: 'blob',
        params: filter
    }
    config = addAuthorizationHeader(config, getState);
    config = addTimeZoneHeader(config, getState);
    try {
        const response = await axios.request(config);
        const blob = new Blob([response.data], {
            type: 'application/pdf',
        });
        const fileName = `${moment(route[0].date_from).utc().format(defaultDateFormat)}-Route ${route[0].person_full_name}.pdf`
        blob.name = fileName
        dataUpload(`/api/download_history`, blob, null, null, {
            params: JSON.stringify(filter ? filter : {}),
            source: "route pdf"
        }, false)(dispatch, getState)
        FileSaver.saveAs(blob, fileName);
        callback && callback()
    } catch (exception) {
        dispatch(
            addAlert({
                message: "Unable to download pdf" + exception.toString(),
            })
        );
        errorCallback && errorCallback()
        throw exception
    }
}

export const getCrew = (scheduler_events_ids, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/scheduler_events/get_crew`,
        method: 'POST',
        data: {scheduler_events_ids}
    };

    apiCall("Getting event", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};
export const updateJobsCrew = (scheduler_events_resource_ids, person_ids, selected_employee_person_id, stop_time, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/scheduler_events/update_jobs_crew`,
        method: 'POST',
        data: {scheduler_events_resource_ids, person_ids, stop_time, selected_employee_person_id}
    };

    apiCall("Saving crew", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};
export const search = (q, customer_id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/search/search_for_guest`,
        params: {q, customer_id}
    };
    apiCall("Searching for guests", config, async config => {
        const response = await axios.request(config);
        callback(mapPersonSearch(response.data));
    }, dispatch, getState, false);
};
export const startTimeTracks = (scheduler_event_id, start_time, person_ids, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/crew_work_orders/start_time_tracks`,
        method: 'POST',
        data: {
            scheduler_event_id: scheduler_event_id,
            start_time: start_time,
            person_ids: person_ids
        }
    };

    apiCall("Start time", config, async config => {
        const result = await axios.request(config);
        callback && callback(result.data);
    }, dispatch, getState, false);
};
export const updateJobStatus = (scheduler_event_id, stop_time, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/crew_work_orders/stop_time_tracks`,
        method: 'POST',
        data: {
            scheduler_event_id: scheduler_event_id,
            stop_time: stop_time,
        }
    };

    apiCall("Stop time", config, async config => {
        const result = await axios.request(config);
        callback && callback(result.data);
    }, dispatch, getState, false);
};
export const getJobServices = (job_id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/crew_work_orders/get_job_services`,
        method: 'get',
        params: {job_id: job_id}
    };

    apiCall("Get job services", config, async config => {
        const result = await axios.request(config);
        callback && callback(result.data);
    }, dispatch, getState, false);
};

export const fetchChecklistModal = (status) => (dispatch, getState) => {
    const store = getState()
    const config = {
        url: '/api/equipments',
        headers: {'Authorization': store.auth.token},
    };

    return axios.request(config)
        .then(response => {
            dispatch({type: FETCH_CHECKLIST_MODAL, status: status});
        });
};