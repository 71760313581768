export const [
    GET_CUSTOMER_BY_ID,
    FETCH_PROPOSALS,
    UPDATE_SERVICE_STATUS,
    FETCH_CUSTOMER_SITES,
    UPDATE_SELECTED_SITE,
    UPDATE_CUSTOMER_SEARCH,
    SELECT_CUSTOMER,
    FETCH_SEARCHED_CUSTOMERS,
    FETCH_SITES_ASSETS,
    FETCH_SELECTED_SITEINFO,
    FETCH_CUSTOMER_SITE_MAPS,
    CREATE_CUSTOMER_SITE_MAP,
    UPDATE_CUSTOMER_SITE_MAP,
    DELETE_CUSTOMER_SITE_MAP,
    FETCH_CUSTOMER_INFO,
    FETCH_BILLING_CONTACT,
    FETCH_SITES,
    FETCH_CONTACTS,
] = [
    "GET_CUSTOMER_BY_ID",
    "FETCH_PROPOSALS",
    "UPDATE_SERVICE_STATUS",
    "FETCH_CUSTOMER_SITES",
    "UPDATE_SELECTED_SITE",
    "UPDATE_CUSTOMER_SEARCH",
    "SELECT_CUSTOMER",
    "FETCH_SEARCHED_CUSTOMERS",
    "FETCH_SITES_ASSETS",
    "FETCH_SELECTED_SITEINFO",
    "FETCH_CUSTOMER_SITE_MAPS",
    "CREATE_CUSTOMER_SITE_MAP",
    "UPDATE_CUSTOMER_SITE_MAP",
    "DELETE_CUSTOMER_SITE_MAP",
    "FETCH_CUSTOMER_INFO",
    "FETCH_BILLING_CONTACT",
    "FETCH_SITES",
    "FETCH_CONTACTS",
]
