import moment from "moment-timezone"
import {
    DELETED_USER,
    FETCH_CUSTOMER_INFO,
    FETCH_EMPLOYEES,
    FETCH_IMAGES_INFO,
    FETCH_ROLES,
    FETCH_STATE_AND_CITY,
    FETCH_USERS,
    SAVED_USER_INFO,
} from "./constants"

const initialState = {
    customerInfo: null,
    userInfo: null,
    roles: [],
    employees: [],
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CUSTOMER_INFO:
            return {
                ...state,
                customerInfo: action.customerInfo,
            }
        case FETCH_IMAGES_INFO:
            return {...state, imagesInfo: action.imagesInfo}
        case FETCH_USERS:
            return {
                ...state,
                userInfo: action.userInfo,
                fetchDate: moment.now()
            }
        case FETCH_ROLES:
            return {
                ...state,
                roles: action.roles,
            }
        case FETCH_EMPLOYEES:
            return {
                ...state,
                employees: action.employees,
            }
        case FETCH_STATE_AND_CITY:
            return {
                ...state,
                customerInfo: {...state.customerInfo, state: action.location.state, city: action.location.city}
            }
        case SAVED_USER_INFO:
            return {...state, newUserId: action.id}
        case DELETED_USER:
            return {...state, deleteDate: moment.now()}
        default:
            return state
    }
}

export default reducer
