import React, {useState} from "react";
import {Button, Col, Modal, Row} from "react-bootstrap";
import './MatchedCustomersModal.scss'
import MDSpinner from "react-md-spinner";

const MatchedCustomersModal = ({onHide, matchedCustomers, onSync, reload, syncMany = true}) => {
    const [selectedQBCustomer, setSelectedQBCustomer] = useState([])
    const [loading, setLoading] = useState(false)
    const [successTable, setSuccessTable] = useState([])
    const [errorsSitesTable, setErrorsSitesTable] = useState([])
    const [errorsMessagesTable, setErrorsMessagesTable] = useState([])

    return (
        <Modal
            id="matched-modal"
            show={true}
            onHide={onHide}
            backdrop="static"
            bsSize="large"
            className={"heightAuto maxHeight90 fontIOS"}
        >
            <Modal.Header closeButton={!loading}>
                <Modal.Title>
                    Matched customers
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{maxHeight: '500px'}}>
                <Row>
                    <Col xs={6}><h4 className="text-center">TreeHub Customer</h4></Col>
                    <Col xs={6}><h4 className="text-center">Quickbook Customers</h4></Col>
                </Row>
                {matchedCustomers && matchedCustomers.length > 0 && matchedCustomers.map((customer, index) => {
                    return <Row>
                        {index !== 0 && <hr/>}
                        <Col xs={12} style={{position: "relative"}}>
                            <Row>
                                <Col xs={6}>
                                    <Row>
                                        <Col xs={12} className="customer">
                                            <Row>
                                                <Col xs={12} className="d-flex">
                                                    <span className="pr-5 fontSize10 align-text-end">ID:</span>
                                                    <b className="align-text-end">{customer.th?.id}</b>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} className="d-flex">
                                                    <span
                                                        className="pr-5 fontSize10 align-text-end">Company name:</span>
                                                    <b className="align-text-end">{customer.th?.company_name}</b>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} className="d-flex">
                                                    <span className="pr-5 fontSize10 align-text-end">Name:</span>
                                                    <b className="align-text-end">{customer.th?.first_name} {customer.th?.last_name}</b>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} className="d-flex">
                                                    <span className="pr-5 fontSize10 align-text-end">Address:</span>
                                                    <b className="align-text-end">{customer.th?.address_1} {customer.th?.city}, {customer.th?.state} {customer.th?.zip}</b>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={6}>
                                    <Row className="mb-15">
                                        {customer.qbs && customer.qbs.length > 0 && customer.qbs.map((qb, i) => {
                                            return <Col xs={12}
                                                        className={`customer quickbooks-customer ${selectedQBCustomer.filter(c => c.index === index)[0]?.qbIndex === i ? "selected" : ""}`}
                                                        onClick={() => {
                                                            if (!loading) {
                                                                const actualAnswer = selectedQBCustomer.filter(c => c.index === index)
                                                                if (actualAnswer && actualAnswer.length > 0 && actualAnswer[0].qbIndex === i) {
                                                                    const newSelectedQBCustomer = selectedQBCustomer.filter(c => c.index !== index)
                                                                    setSelectedQBCustomer(newSelectedQBCustomer)
                                                                } else {
                                                                    const newSelectedQBCustomer = selectedQBCustomer.filter(c => c.index !== index)
                                                                    setSelectedQBCustomer(newSelectedQBCustomer.concat({
                                                                        index: index,
                                                                        qbIndex: i
                                                                    }))
                                                                }
                                                            }
                                                        }}>
                                                <Row>
                                                    <Col xs={12} className="d-flex">
                                                        <span className="pr-5 fontSize10 align-text-end">ID:</span>
                                                        <b className="align-text-end">{qb.quickbooks_id}</b>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} className="d-flex">
                                                        <span
                                                            className="pr-5 fontSize10 align-text-end">Company name:</span>
                                                        <b className="align-text-end">{qb.company_name}</b>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} className="d-flex">
                                                        <span className="pr-5 fontSize10 align-text-end">Name:</span>
                                                        <b className="align-text-end">{qb.first_name} {qb.last_name}</b>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} className="d-flex">
                                                        <span className="pr-5 fontSize10 align-text-end">Address:</span>
                                                        <b className="align-text-end">{qb.address_1} {qb.city}, {qb.state} {qb.zip}</b>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        })}
                                    </Row>
                                </Col>
                            </Row>
                            {loading && selectedQBCustomer.some(s => s.index === index) &&
                                <Row className="result-panel loading-panel">
                                    <MDSpinner size={30}/>
                                </Row>
                            }
                            {successTable.some(s => s.index === index) &&
                                <Row className="result-panel success-panel">
                                    Success
                                </Row>
                            }
                            {errorsMessagesTable.some(s => s.index === index) &&
                                <Row className="result-panel error-panel">
                                    {errorsMessagesTable.filter(e => e.index === index).map(message => {
                                        return <Col xs={12}>{message.result}</Col>
                                    })}
                                </Row>
                            }
                            {errorsSitesTable.some(s => s.index === index) &&
                                <Row className="result-panel error-panel">
                                    {errorsSitesTable.filter(e => e.index === index).map(site => {
                                        return <>
                                            <Col xs={12}>{site.result?.message}</Col>
                                            <Col xs={10} xsOffset={2}>
                                                {site.result?.error_sites?.map(e => {
                                                    return <Row className="font14">
                                                        <Col xs={5}>{e.site_name}</Col>
                                                        <Col xs={7}>{e.error_message}</Col>
                                                    </Row>
                                                })}
                                            </Col>
                                        </>
                                    })}
                                </Row>
                            }
                        </Col>
                    </Row>
                })}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    bsStyle="warning"
                    disabled={loading}
                    onClick={() => onHide()}
                >
                    Close
                </Button>
                <Button
                    bsStyle="success"
                    onClick={() => {
                        if (selectedQBCustomer && selectedQBCustomer.length > 0) {
                            const resultCustomers = []

                            selectedQBCustomer.map(qb => {
                                resultCustomers.push({
                                    index: qb.index,
                                    customer: {
                                        th: matchedCustomers[qb.index].th,
                                        qb: matchedCustomers[qb.index].qbs[qb.qbIndex]
                                    }
                                })
                            })
                            setLoading(true)
                            onSync(resultCustomers, 'right', (res) => {
                                setErrorsMessagesTable(errorsMessagesTable.concat(res.errors_messages))
                                setErrorsSitesTable(errorsSitesTable.concat(res.errors_sites))
                                setSuccessTable(successTable.concat(res.success))
                                setSelectedQBCustomer([])
                                setLoading(false)
                                if (syncMany) {
                                    reload && reload()
                                } else {
                                    reload && reload(resultCustomers[0]?.customer?.qb)
                                }
                            }, () => {
                                setLoading(false)
                            })
                        }
                    }}
                    disabled={!(selectedQBCustomer && selectedQBCustomer.length > 0) || loading}
                >
                    {loading ? <span><MDSpinner size={15}/> Processing...</span> : 'Sync'}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default MatchedCustomersModal;