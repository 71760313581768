import React, {useEffect, useState} from "react";
import {Button, Col, Modal, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router";
import MDSpinner from "react-md-spinner";

const LeadListModal = ({siteId, onHide, getLeadList, deleteLead}) => {
    const [leads, setLeads] = useState([])
    const [loading, setLoading] = useState(false)

    const reload = () => {
        setLoading(true)
        getLeadList(siteId, (res) => {
            setLeads(res)
            setLoading(false)
        }, () => setLoading(false))
    }

    const removeLead = (id) => {
        setLoading(true)
        deleteLead(id, () => {
            reload()
            setLoading(false)
        }, () => setLoading(false))
    }

    useEffect(() => {
        reload()
    }, [])

    return <Modal
        show={true}
        onHide={onHide}
        id="lead-list-modal"
        className="heightAuto fontIOS"
    >
        <Modal.Header closeButton>
            <Modal.Title>Lead List</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{minHeight: '30px', maxHeight: '400px'}}>
            {loading ?
                <Row className="vhCenter full-height pb-26 padding-top-15 bottom10">
                    <MDSpinner size={70} borderSize={2}/>
                </Row>
                :
                leads.map(l => {
                    return <Row className="top10 bottom10 vhCenter padding-top-bottom-4"
                                style={{backgroundColor: '#ededed'}}>
                        <Col xs={10}>
                            <Row>
                                <Col xs={6}>
                                    <span className="mr-7">Lead:</span>
                                    <Link to={`/lead/${l.id}`} target='_blank'>
                                        #{l.id}
                                    </Link>
                                </Col>
                                <Col xs={6}>
                                    <span className="mr-7">Proposal:</span>
                                    {l.proposal_id ?
                                        <Link to={`/mapview/${l.proposal_id}`} target='_blank'>
                                            #{l.proposal_no}
                                        </Link>
                                        :
                                        'N/A'
                                    }
                                </Col>
                                <Col xs={12}>
                                    <span className="mr-7">Arborist:</span>
                                    <b>{l.first_name} {l.last_name}</b>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={2} className="text-right">
                            {l.proposal_id ?
                                <OverlayTrigger
                                    placement="left"
                                    overlay={<Tooltip id="tooltip">Lead cannot be deleted, it contains a
                                        proposal</Tooltip>}
                                >
                                    <FontAwesomeIcon
                                        className="mr-9 arrow-disabled"
                                        size='2x'
                                        icon={faTrash}
                                    />
                                </OverlayTrigger>
                                :
                                <OverlayTrigger
                                    placement="left"
                                    overlay={<Tooltip id="tooltip">Delete Lead</Tooltip>}
                                >
                                    <FontAwesomeIcon
                                        className="pointer mr-9 text-success"
                                        size='2x'
                                        icon={faTrash}
                                        onClick={() => removeLead(l.id)}
                                    />
                                </OverlayTrigger>
                            }
                        </Col>
                    </Row>
                })
            }
        </Modal.Body>
        <Modal.Footer>
            <Button
                bsStyle="info"
                className="button-right"
                onClick={onHide}
            >
                Cancel
            </Button>
        </Modal.Footer>
    </Modal>
}

export default LeadListModal;