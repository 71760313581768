import {Col, ControlLabel, FormControl, FormGroup, Modal, Row} from "react-bootstrap";
import React, {Component} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import MDSpinner from "react-md-spinner";

class NewFilterModal extends Component {

    state = {
        name: ""
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.name !== this.state.name) {
            this.props.setFilterName(this.state.name)
        } else if (this.props.show && this.props.selectedFilter && (this.state.name !== this.props.searchValue)) {
            this.setState({name: this.props.searchValue})
        }
    }

    render() {
        const {
            show,
            onHide,
            onSave,
            overflow,
            disabled,
            infoContent,
            closeButton,
            title,
            isDeletedFilter,
            userSearchFilters,
            selectedFilter,
            filterToDeleteName,
            isProcessing
        } = this.props;
        const {name} = this.state;

        return (
            <Modal
                animation={false}
                show={show}
                onHide={onHide}
                className={"heightAuto maxHeight90 modalLeadsFilterBody fontIOS"}
                style={overflow}
            >
                <Modal.Header>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!isDeletedFilter ?
                        <Row className="padding20">
                            <Col>
                                <FormGroup className="bottom0">
                                    <ControlLabel>
                                        {selectedFilter ? "Change Filter Name" : "Name Your Filter"}
                                    </ControlLabel>
                                    <FormControl
                                        autoFocus
                                        type="text"
                                        placeholder="Filter name"
                                        value={name}
                                        onChange={e => this.setState({name: e.target.value})}
                                    />
                                </FormGroup>
                                {userSearchFilters.some(x => x.name === name) && !selectedFilter &&
                                    <p className="colorRed font11 mt-3">Filter with the same name already
                                        exists!</p>}
                            </Col>
                        </Row>
                        :
                        <div className="rate-modal-body-padding d-flex">
                            <FontAwesomeIcon icon={faExclamationTriangle}
                                             className="small-margin font20 alert-font-color mt-3"/>
                            <p className="ml-10 line_height17_5 mt-3 font14">
                                {`Are you sure, you want to delete "${filterToDeleteName}" filter ?`}
                            </p>
                        </div>
                    }
                </Modal.Body>

                <Modal.Footer>
                    <Row>
                        <Col md={6} className="text-left">
                            {infoContent}
                        </Col>
                        <Col md={6}>
                            <button type="button" onClick={closeButton} className="btn btn-default"
                                    data-dismiss="modal">Cancel
                            </button>
                            <button type="button" onClick={onSave} className="btn btn-success"
                                    disabled={disabled}>{isProcessing ? <MDSpinner size={14}/> : "Save"}
                            </button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default NewFilterModal;
