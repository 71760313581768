import React from 'react';
import {Col, FormControl, FormGroup, Row} from "react-bootstrap";
import Datetime from "react-datetime";
import {defaultDateFormat, defaultTimeFormat, select} from "../../common/commonHandlers";
import ResourceComponent from "../../components/ResourceComponent";
import moment from "moment";
import Select from "react-select";

class ActivityListFilters extends ResourceComponent {

    handleChangeSendDateFrom = (e, date) => {
        let {resource} = this.props;
        if (e && moment(e).isValid()) {
            resource[date] = moment(e).startOf('day');
        } else {
            resource[date] = ""
        }
        this.setState(resource, this.search);
    };

    handleChangeSendDateTo = (e, date) => {
        let {resource} = this.props;
        if (e && moment(e).isValid()) {
            resource[date] = moment(e).endOf('day');
        } else {
            resource[date] = ""
        }
        this.setState(resource, this.search);
    };

    getClients = e => {
        if (e.length > 0) {
            this.props.getClientsSearch(e, res => this.props.onChangeClientOptions(res))
        }
    }

    selectClientFilter = (e) => {
        this.props.onChangeSelectedClients(e)
        this.props.onChangeFilters('client_ids', [...e.map(x => x.value)])
    }

    render() {
        const {resource, onChangeFilters, clientOptions, selectedClients} = this.props;
        const {created_from, created_to, user, activity, client_ids} = resource;

        return (
            <div>
                <Row className="mt-15">
                    <Col md={6}>
                        <FormGroup>
                            <Datetime
                                dateFormat={defaultDateFormat}
                                timeFormat={defaultTimeFormat}
                                value={created_from}
                                className="created_from"
                                inputProps={{placeholder: 'Created from'}}
                                closeOnSelect
                                onChange={e => this.handleChangeSendDateFrom(e, 'created_from')}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Datetime
                                dateFormat={defaultDateFormat}
                                timeFormat={defaultTimeFormat}
                                value={created_to}
                                className="created_to"
                                inputProps={{placeholder: 'Created to'}}
                                closeOnSelect
                                onChange={e => this.handleChangeSendDateTo(e, 'created_to')}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <FormControl
                                placeholder="User"
                                name="user"
                                value={user}
                                onChange={(e) => onChangeFilters(e.target.name, e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <FormControl
                                placeholder="Activity"
                                name="activity"
                                value={activity}
                                onChange={(e) => onChangeFilters(e.target.name, e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Select className="Select bottom15 top15"
                                classNamePrefix="select"
                                value={select([...selectedClients, ...clientOptions], client_ids)}
                                options={clientOptions}
                                placeholder="Client"
                                isMulti
                                isClearable
                                onInputChange={e => this.getClients(e)}
                                onChange={e => this.selectClientFilter(e)}
                        />
                    </Col>
                </Row>
            </div>
        );
    };
}

export default ActivityListFilters;