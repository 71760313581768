import React, {Component} from 'react';
import {Modal} from "react-bootstrap";
import Button from "react-bootstrap/lib/Button";
import {Editor} from "react-draft-wysiwyg";
import {ContentState, convertToRaw, EditorState} from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import {updateExternalLinks} from "../../common/commonHandlers";


export default class EditDescriptionModal extends Component {
    constructor(props) {
        super(props);
        const editorState = EditorState.createEmpty();
        this.state = {
            editorState: editorState
        };
    }

    onEditorStateChange = (editorState) => {
        this.state.description = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        this.setState({editorState});
    };

    componentDidMount() {
        this.props.loadEvent(this.props.event_id, (event) => {
            if (event.description) {
                const contentBlock = htmlToDraft(updateExternalLinks(null, event.description));
                if (contentBlock) {
                    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                    const editorState = EditorState.createWithContent(contentState);
                    this.setState({
                        description: event.description,
                        editorState: editorState
                    });
                }
            }

        })
    }

    render() {
        return (
            <Modal
                show={this.props.show}
                className="heightAuto maxHeight90">
                <Modal.Header>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="mt-15 mb-15">
                    <Editor
                        editorState={this.state.editorState}
                        onEditorStateChange={this.onEditorStateChange}
                        toolbarHidden
                        showOpenOptionOnHover={false}
                        toolbar={{
                            link: {
                                showOpenOptionOnHover: true,
                            }
                        }}
                        spellCheck="true"
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button type="button" onClick={this.props.onHide}
                            className="btn btn-default">
                        Cancel
                    </Button>
                    <Button type="button"
                            onClick={() => this.props.onSave({
                                id: this.props.event_id,
                                description: updateExternalLinks("prod", this.state.description)
                            }, this.props.onHide)}
                            className="btn btn-success">
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}