import {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import PropTypes from "prop-types"
import * as actions from './actions'
import {geolocated} from "react-geolocated";
import {browserHistory} from "react-router";
import {ROLES} from "../../../constants";

class Poller extends Component {
    constructor(props, context) {
        super(props, context);
    }

    componentDidMount() {
        this.getUserStatus()
        this.props.actions.getClientInfo(this.props.auth.client_id, (response) => {
            this.timer = setInterval(this.getUserStatus, +this.props.interval * 1000);
            if (response.geolocation_interval && !(response.client_current_user_optional_geolocation || response.optional_geolocation)) {
                this.getGeolocation()
                this.geoTimer = setInterval(this.getGeolocation, +response.geolocation_interval * 1000);
            }
            this.subscriptionTimeout = setTimeout(this.checkSubscriptionValid, 10000)
            this.subscriptionTimer = setInterval(this.checkSubscriptionValid, +this.props.subscriptionInterval * 1000)
            this.locationTimeout = setTimeout(this.checkLocalizationAccess, 10000)
            this.locationTimer = setInterval(this.checkLocalizationAccess, +this.props.locationInterval * 1000)
        });
    }

    componentWillUnmount() {
        clearInterval(this.timer);
        clearInterval(this.geoTimer);
        clearInterval(this.locationTimer)
        clearInterval(this.subscriptionTimer)
        clearTimeout(this.subscriptionTimeout);
        clearTimeout(this.locationTimeout);
    }

    render() {
        return null;
    }

    getUserStatus = () => {
        const {user_id} = this.props.auth;
        this.props.actions.getUserStatus(user_id);
    };

    getGeolocation = (callback) => {
        const {isGeolocationAvailable, isGeolocationEnabled, coords} = this.props;
        const {user_id, client_id} = this.props.auth;
        this.props.actions.getGeolocation(user_id, client_id, isGeolocationAvailable && isGeolocationEnabled && coords && coords, callback);
    };

    checkLocalizationAccess = () => {
        const url = window.location.href
        if (!this.props.perm.optionalGeolocation) {
            if ('geolocation' in navigator)
                navigator.geolocation.getCurrentPosition(
                    () => {
                        return null
                    },
                    () => {
                        return !url.includes('/login') && browserHistory.push(`/login`)
                    }
                )
            else
                !url.includes('login') && browserHistory.push('/login');
        }
    }

    checkSubscriptionValid = () => {
        const url = window.location.href;
        const urlSwitched = ['/login', '/subscription_renew', '/subscription_expired'].some(u => url.includes(u))
        if (!urlSwitched && !this.props.userStatus.subscription_active) {
            if (this.props.auth.roles.includes(ROLES.admin)) {
                browserHistory.push('/subscription_renew')
            } else {
                browserHistory.push('/subscription_expired')
            }
        }
    }
}

Poller.propTypes = {
    interval: PropTypes.number.isRequired
};
Poller.defaultProps = {
    interval: 300,
    locationInterval: 300,
    subscriptionInterval: 3600
};

function mapStateToProps(state) {
    return {
        auth: state.auth,
        app: state.app,
        userStatus: state.userStatus.userStatus,
        path: state.routing.locationBeforeTransitions.pathname,
        perm: state.perm
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

export default geolocated({
    positionOptions: {
        enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
})(connect(mapStateToProps, mapDispatchToProps)(Poller));
