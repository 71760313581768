import React, {useEffect, useState} from "react";
import {GridContextProvider, GridDropZone, GridItem, swap} from "react-grid-dnd";
import PropTypes from "prop-types";
import AttachmentThumbnail from "./AttachmentThumbnail/AttachmentThumbnail";
import './ReorderingDragAndDrop.css'

const ReorderingDragAndDrop = ({
                                   files,
                                   deleteAttachment,
                                   reorderAttachments,
                                   numberOfColumns,
                                   selectAttachment,
                                   downloadButton
                               }) => {
    const [items, setItems] = useState([])
    const [boxesPerRow, setBoxesPerRow] = useState(4)

    const isDesktopOrLaptop = (window.innerWidth > numberOfColumns.desktop.minWidth)

    const isLaptop = (window.innerWidth > numberOfColumns.laptop.minWidth
        && window.innerWidth <= numberOfColumns.desktop.minWidth)

    const isTablet = (window.innerWidth > numberOfColumns.tablet.minWidth
        && window.innerWidth <= numberOfColumns.laptop.minWidth)

    const isBigMobile = (window.innerWidth > numberOfColumns.bigMobile.minWidth
        && window.innerWidth <= numberOfColumns.tablet.minWidth)

    const isMobile = (window.innerWidth > numberOfColumns.mobile.minWidth
        && window.innerWidth <= numberOfColumns.bigMobile.minWidth)

    const isSmallMobile = (window.innerWidth <= numberOfColumns.mobile.minWidth)

    useEffect(() => {
        if (isDesktopOrLaptop) {
            setBoxesPerRow(numberOfColumns.desktop.columns)
        } else if (isLaptop) {
            setBoxesPerRow(numberOfColumns.laptop.columns)
        } else if (isTablet) {
            setBoxesPerRow(numberOfColumns.tablet.columns)
        } else if (isBigMobile) {
            setBoxesPerRow(numberOfColumns.bigMobile.columns)
        } else if (isMobile) {
            setBoxesPerRow(numberOfColumns.mobile.columns)
        } else if (isSmallMobile) {
            setBoxesPerRow(numberOfColumns.smallMobile.columns)
        }
    }, [isDesktopOrLaptop, isLaptop, isTablet, isBigMobile, isMobile, isSmallMobile])


    useEffect(() => {
        setItems(files)
    }, [files])

    function onChange(sourceId, sourceIndex, targetIndex) {
        const nextState = swap(items, sourceIndex, targetIndex);
        const orderedFiles = nextState.map((file, index) => {
            file.order = index
            return file
        })
        setItems(orderedFiles)
        reorderAttachments(orderedFiles)
    }

    return <GridContextProvider onChange={onChange}>
        <GridDropZone
            id="items"
            boxesPerRow={boxesPerRow}
            rowHeight={125}
            className='grid_drop_zone'
        >
            {items.map(file => (
                <GridItem key={file.id}>
                    <AttachmentThumbnail className="attachment-thumbnail" file={file} downloadButton={downloadButton}
                                         deleteAttachment={deleteAttachment} selectAttachment={selectAttachment}/>
                </GridItem>
            ))}
        </GridDropZone>
    </GridContextProvider>
}

ReorderingDragAndDrop.propTypes = {
    files: PropTypes.object.isRequired,
    deleteAttachment: PropTypes.func.isRequired,
    reorderAttachments: PropTypes.func.isRequired,
    numberOfColumns: PropTypes.object.isRequired,
    selectAttachment: PropTypes.func
};


export default ReorderingDragAndDrop;