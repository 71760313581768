import React from 'react';
import PropTypes from "prop-types"
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {
    dashboardStatuses,
    defaultDateFormat,
    defaultDateTimeFormat,
    fitBounds,
    select
} from "../../common/commonHandlers";
import {showEmailDialog} from "../../components/email/actions"
import * as api from '.././SalesDashboard/SalesDashboardApi';
import BottomLeftPanel from "./BottomLeftPanel";
import BottomRightPanel from "./BottomRightPanel";
import BottomRightPanelMobile from './BottomRightPanelMobile';
import BottomLeftPanelMobile from './BottomLeftPanelMobile';
import {Button, Checkbox, Col, Glyphicon, NavDropdown, Row} from "react-bootstrap";
import EmailDialog from "../../components/email/EmailDialog";
import ResourceComponent from "../../components/ResourceComponent";
import {debounce} from "throttle-debounce";
import "./Dashboard.css";
import Map from "./Map";
import moment from "moment/moment";
import Dollars from "../../components/Dollars";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import LeadsFilterModal from '../LeadsPage/LeadsFilterModal';
import ReactDock from "react-dock";
import Select from "react-select";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";

const actions = {...api, showEmailDialog};

const isMobile = window.screen.width <= 1024;
const mdMobile = window.screen.width <= 768;
const smMobile = window.screen.width <= 450;
const xsMobile = window.screen.width <= 350;

class Dashboard extends ResourceComponent {

    constructor(props, context) {
        super(props, context);
        this.state = {
            left_panel: {
                show: false
            },
            right_panel: {
                show: false
            },
            report: null,
            acceptedTotals: null,
            data: [],
            selected: 0,
            selectedMarker: null,
            selectedFilter: 0,
            selectedStatus: 'draft',
            selectedRecords: [],
            backlogEmailRecipients: [],
            allSelected: false,
            selectedRows: [],
            selectedRow: 0,
            sales_arborist: null,
            includeScheduled: false,
            panel: null,
            panelWidth: 0,
            map: null,
            resource: {
                date_name: 'all',
                from: moment().clone().startOf('month').format(defaultDateFormat),
                to: moment().clone().endOf('month').format(defaultDateFormat),
                service_type_id: null,
                page: 1,
                per_page: 25,
                sort: 'asc',
                sort_by: 'created_at',
                selectedFilter: 'total',
                selectedStatus: 'draft',
                inBounds: true,
                acceptedStatus: true,
                workOrderStatus: true,
                scheduledStatus: true,
                acceptedTabWOStatus: false,
                acceptedTabScheduledStatus: false,
                acceptedTabCompletedStatus: false,
                acceptedTabInvoicedStatus: false,
                acceptedTabPaidStatus: false,
                promisedStatus: false,
                isBacklog: false,
                sent_from: null,
                sent_to: null,
                onlyProposed: true,
                ps_completed_statuses: ['COMPLETED']
            },
            services: [],
            customer_types: [],
            reasons: [],
            priorities: [],
            equipments: [],
            plants: [],
            mapParams: [],
            selectedProposal: 0,
            selected_string: "",
            navbarHeight: 0,
            minimumClusterSize: 1,
            rightPanelData: [],
            service_types: [],
            employees: [],
            filterModal: false,
            isDeclinedModalOpened: false,
            showDock: false,
            columnOptions: {
                draft: ['Customer', 'Services', 'Price', 'Proposal #', 'Age', 'Created', 'Arborist'],
                proposed: ['Customer', 'Services', 'Sent Date', '#Sent', 'Due on', 'Completed', 'Price', 'Proposal #', 'Age', 'Created', 'Arborist'],
                backlog: ['Customer', 'Service', 'Service Type', 'Service $', 'MHRS', 'Status', 'Priority', 'Proposal #', 'Age', 'Accepted', 'Promised', 'Scheduled on', 'Arborist', 'Site'],
                completed: ['Customer', 'Services', 'Price', 'Proposal #', 'Age', 'Created', 'Completed at', 'Arborist'],
                invoiced: ['Customer', 'Services', 'Price', 'Proposal #', 'Age', 'Created', 'Invoiced at', 'WOs', 'Invoices', 'Arborist'],
                paid: ['Customer', 'Services', 'Price', 'Proposal #', 'Age', 'Created', 'Paid Dates', 'Total Paid', 'WOs', 'Invoices', 'Arborist'],
            },
            visibleColumns: {
                draft: [],
                proposed: [],
                backlog: [],
                completed: [],
                invoiced: [],
                paid: [],
            },
            dashboard_settings: {
                draft: [],
                proposed: [],
                backlog: [],
                completed: [],
                invoiced: [],
                paid: [],
            }
        };
        this.delayedSearch = debounce(1250, this.fetchTableData);
        this.delayedUpdateZip = debounce(1000, this.updateZip);
        this.delayedCitySearch = debounce(1000, this.fetchTableData);
        this.delayedCustomerSearch = debounce(500, this.search);
        this.delayedProposalSearch = debounce(500, this.search);

        this.proposalSearch = {
            searchOptions: () => [],
            search: () => {
            }
        }
        this.customerSearch = {
            searchOptions: () => [],
            search: () => {
            }
        }
        this.zipSearch = {
            searchOptions: () => [],
            search: () => {
            }
        }
        this.citySearch = {
            searchOptions: () => [],
            search: () => {
            }
        }
    }

    componentDidMount() {
        const {selected, visibleColumns} = this.state;
        this.props.actions.load(result => {
            this.setState(result)
        });
        this.fetchData();
        this.props.actions.getEmployees(employees => this.setState({employees: employees}, this.getEmployeesWithRole));
        this.delayedInBounds = debounce(2000, this.inBounds);
        this.customerSearch = this.buildSearchComponent('customer_id', this.props.actions.getCustomersSearch);
        this.zipSearch = this.buildSearchComponent('zip', this.props.actions.getZipcodeSearch);
        this.citySearch = this.buildSearchComponent('city', this.props.actions.getCitySearch);
        this.proposalSearch = this.buildSearchComponent('proposal_id', this.props.actions.searchForProposal);
        this.props.actions.getDashboardSettings(settings => {
            this.setState(() => ({visibleColumns: settings.columns}))
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const elements = document.getElementsByClassName("navbar navbar-default");
        const {visibleColumns, selected} = this.state;

        if (prevState.selected !== selected) {
            this.props.actions.getDashboardSettings(settings => {
                const tab = dashboardStatuses()[selected].value
                this.setState(() => ({visibleColumns: {...visibleColumns, [tab]: settings.columns[tab]}}))
            })
        }
        if (this.state.perPage !== prevState.perPage) {
            this.checkMarkers();
        }
        if (this.state.data !== prevState.data) {
            this.setState({
                rightPanelData: this.state.data
            });
        }
        if (elements[0].offsetHeight !== this.state.navbarHeight) {
            this.setState({
                navbarHeight: elements[0].offsetHeight
            });
        }
    }

    search = () => {
        let {resource} = this.state;
        resource.sort_by = 'created_at';
        this.props.actions.getCustomers({...resource}, results => {
            this.setState(() => ({resource: {...resource}, customers: results.content}), this.fetchTableData);
        });
    }

    handleCheckbox = e => {
        this.setState({
            [e.target.name]: {
                ...this.state[e.target.name],
                "show": e.target.checked
            }
        })
    }

    handleData = (name, data, resource) => {
        !resource ?
            this.setState({
                [name]: data
            }) : this.state.resource[name] = data;
        this.setState({resource: this.state.resource})

    }

    checkMarkers = () => {
        let {resource, data, perPage} = this.state;
        const new_data = data.filter(d => d.longitude <= resource.min_lng && d.longitude >= resource.max_lng &&
            d.latitude <= resource.min_lat && d.latitude >= resource.max_lat);
        if (new_data.length <= perPage) {
            this.setState({minimumClusterSize: perPage + 1, rightPanelData: new_data});
        } else {
            this.setState({minimumClusterSize: 1, rightPanelData: new_data});
        }
    }


    inBounds = () => {
        let {resource} = this.state;
        const map = this.state.map;
        if (resource.inBounds && map && map.getBounds()) {
            const bounds = map.getBounds()
            resource.min_lng = bounds && bounds.getNorthEast().lng();
            resource.max_lng = bounds && bounds.getSouthWest().lng();
            resource.min_lat = bounds && bounds.getNorthEast().lat();
            resource.max_lat = bounds && bounds.getSouthWest().lat();
            this.setState({resource}, this.checkMarkers);
        }
    };

    fetchData = () => {
        const {dashboardStatuses, durations} = this.props;
        const {selected, resource, map, selectedRecords} = this.state;

        const date_name = durations.find(duration => (duration.value === resource.date_name) || (duration.label === resource.date_name)).value

        this.props.actions.fetchData({
            ...resource,
            status: dashboardStatuses[selected].value,
            date_name: select(durations, date_name).label,
        }, report => {
            this.setState({report})
        });
        this.fetchTableData(false)

    };

    setEmailRecipients = (selectedRecords, backlogEmailRecipients) => {
        this.setState({
            email: true,
            selectedRecords,
            backlogEmailRecipients
        })
    }

    toggleDeclinedModal = (callback) => this.setState({isDeclinedModalOpened: !this.state.isDeclinedModalOpened}, callback)

    fetchTableData = (recalculateAmount = true) => {
        const {selected, resource, map, report} = this.state;
        const {dashboardStatuses, durations} = this.props;


        this.props.actions.fetchTableData({
                ...resource,
                status: dashboardStatuses[selected].value,
                date_name: select(durations, resource.date_name).label
            },
            data => {
                if (recalculateAmount && report) {
                    this.props.actions.fetchData({
                        ...resource,
                        status: resource.selectedStatus ? resource.selectedStatus : dashboardStatuses[selected].value,
                        date_name: select(durations, resource.date_name).label,
                        table_report: true
                    }, result => {
                        report[resource.selectedStatus] = result.report
                        this.setState({report})
                    });
                }
                this.setState({
                    selectedFilter: 0,
                    data,
                    selectedRow: 0,
                    selectedRecords: [],
                    allSelected: false
                }, () => {
                });

                const mapParams = data.map(item => {
                    return {
                        longitude: item.longitude,
                        latitude: item.latitude
                    }
                });
                fitBounds(map, mapParams);
                this.setState({mapParams: mapParams});
            })
    }

    showSelectedMarkers = () => {
        const {dashboardStatuses, durations} = this.props;
        const {data, selectedRecords, selected, resource, map} = this.state;
        const filtered_data = selectedRecords.length === 0 ? data : data.filter(row =>
            selectedRecords.indexOf(resource.isBacklog ? row.id : row.proposalId) !== -1)

        const mapParams = filtered_data.map(item => {
            return {
                longitude: item.longitude,
                latitude: item.latitude
            }
        });
        if (mapParams) {
            fitBounds(map, mapParams);
            this.setState({mapParams: mapParams});
        }
    };

    handleChangeCreatedFrom = (e, date) => {
        let {resource} = this.state;
        if (resource[date] === null) {
            resource[date] = moment(e);
        } else {
            resource[date] = moment(e);
        }
        this.setState(resource, this.fetchData);
    };

    handleChangeCreatedTo = (e, date) => {
        let {resource} = this.state;
        if (resource[date] === null) {
            resource[date] = moment(e);
        } else {
            resource[date] = moment(e);
        }
        this.setState(resource, this.fetchData);
    };


    selectStatus = (index, i) => {
        let {resource} = this.state;
        resource.sort = 'asc';
        resource.sort_by = 'created_at';
        resource.selectedFilter = 'total';
        resource.page = 1;
        resource.selectedStatus = this.props.dashboardStatuses[index].value
        resource.isBacklog = this.props.dashboardStatuses[index].label === 'Backlog'
        this.setState({
            selected: index,
            resource: resource,
            allSelected: false,
            selectedRecords: [],
            left_panel: {show: true},
            right_panel: {show: true}
        }, () => this.fetchTableData(false))
    };


    selectFilter = (filter) => {
        const {selected, acceptedTotals, resource, sales_arborist, map} = this.state;
        resource.selectedFilter = filter;
        this.setState(resource, this.fetchData);
    };

    email = proposalId => {
        const {email} = this.props;
        this.props.actions.loadProposalData(proposalId, proposal => {
            let recipientEmail
            const {contactEmail, contactEmail2, secondContactEmail, secondContactEmail2} = proposal
            const primarySiteContactEmails = (contactEmail && contactEmail2) ? `${contactEmail}, ${contactEmail2}` : contactEmail
            const secondarySiteContactEmails = (secondContactEmail && secondContactEmail2) ? `${secondContactEmail}, ${secondContactEmail2}` : secondContactEmail

            recipientEmail = `${primarySiteContactEmails || ""}`
            if (secondarySiteContactEmails) recipientEmail += `, ${secondarySiteContactEmails}`

            this.props.actions.showEmailDialog(
                {
                    emailType: "proposal",
                    referenceId: proposalId,
                    recipient: recipientEmail,
                    defaultEmail: email
                });
        });
    };

    onSelectAll = (isSelected, rows) => {
        let selectedRecords = [];

        if (isSelected) {
            selectedRecords = rows.map(p => p.proposalId)
        }
        this.setState({selectedRecords})
    };

    onRowSelect = (row, isSelected) => {
        let selectedRecords = [...this.state.selectedRecords];
        const proposalId = parseInt(row.proposalId, 10);
        if (isSelected) {
            selectedRecords = selectedRecords.concat(proposalId);
            const uniqSelected = new Set(selectedRecords);
            selectedRecords = [...uniqSelected];
        } else {
            selectedRecords = selectedRecords.filter(s => s !== proposalId);
        }
        this.setState({selectedRecords});
    };


    rowClickHandler = (row) => {
        this.selectMarkerOneToMany('data', undefined, undefined, undefined, true)(row);
        fitBounds(this.state.map, [{latitude: row.latitude, longitude: row.longitude}]);
    }

    declineSelected = () => {
        const {selectedRecords, selected} = this.state;
        const isBacklog = this.props.dashboardStatuses[selected].label === 'Backlog'
        this.props.actions.declineProposals(selectedRecords, isBacklog, () => this.fetchData());
    };

    onChangePromisedStatus = (key, value) => {
        this.setState(() => ({
            resource: {
                ...this.state.resource,
                promised_from: null,
                promised_to: null,
                [key]: value
            }
        }), () => this.selectCheckboxAttr(`${key}`, this.fetchData()))
    };

    onChangeResource = (value) => {
        this.setState(() => ({resource: value}), () => this.fetchTableData(false))
    };

    getEmployeesWithRole = () => {
        const {employees} = this.state;
        if (employees && employees.length > 0) {
            const employees_helper = [];
            employees && employees.filter(employee => employee.hidden_arborist !== true).map(c => {
                employees_helper.push({label: c.name, value: c.id});
                this.setState({employees: employees_helper});
            });
        }
    };

    onChangeState = (key, value) => this.setState({[key]: value});

    createCSV = (status) => {
        const {resource, allSelected, selectedRecords, data, visibleColumns, selected, columnOptions} = this.state;
        const {durations} = this.props;
        const proposalIds = data && data.map(x => x.proposalId);
        const tab = dashboardStatuses()[selected].value
        this.props.actions.downloadCsv({
            ...resource,
            date_name: select(durations, resource.date_name).label,
            status: status && status.value,
            selected: allSelected ? 'all' : selectedRecords,
            selected_columns: visibleColumns[tab],
            visible_columns: columnOptions[tab],
            proposalIds
        })
    };

    onSearch = (e) => {
        const {resource} = this.state;
        this.setState(({
            resource: {
                ...resource,
                q: e.target.value
            }
        }), this.delayedSearch)
    };

    getDate = (date) => {
        if (date && date.length > 0) {
            let datearray = date.split("/");
            let parsed = datearray[1] + '/' + datearray[0] + '/' + datearray[2];
            return parsed;
        } else {
            return null;
        }
    }


    handleRangeDateChange = (start, end, dateStart, dateEnd, fetchReport = false) => {
        let {resource} = this.state;
        resource[dateStart] = this.getDate(start);
        resource[dateEnd] = this.getDate(end);
        this.setState(resource, () => fetchReport && this.fetchData());
    }

    sentFormatter = (cell, row) => cell ? <span>{cell}</span> : 'N/A';
    lastSentFormatter = (cell, row) => cell ? <span>{moment(cell).format(defaultDateTimeFormat)}</span> : 'N/A';
    sentCompleteFormatter = (cell, row) => cell ? `${cell}` : 'false';
    statusDateFilterFormatter = (selectedStatus) => {
        if (selectedStatus === 'In Draft') {
            return 'Created at date'
        }
        if (selectedStatus === 'Sent') {
            return 'Sent at date'
        }
        if (selectedStatus === 'Backlog') {
            return 'Accepted at date'
        }
        if (selectedStatus === 'Completed') {
            return 'Completed at date'
        }
        if (selectedStatus === 'Invoiced') {
            return 'Invoiced at date'
        }
        if (selectedStatus === 'Paid') {
            return 'Paid at date'
        }
    }

    saveReportSettings = () => {
        let {dashboard_settings} = this.state;
        this.props.actions.saveDashboardSettings(dashboard_settings)
    };

    handleColumnOptionsChange = e => {
        let {visibleColumns, dashboard_settings, selected} = this.state;
        const tab = dashboardStatuses()[selected].value
        visibleColumns[tab] = e.map(x => x.value)
        dashboard_settings = visibleColumns
        this.setState({
            visibleColumns: visibleColumns,
            dashboard_settings: dashboard_settings
        }, this.saveReportSettings);
    };

    render() {
        const {dashboardStatuses, users, theme, durations} = this.props;
        const {
            report,
            filterModal,
            selectedRecords,
            allSelected,
            service_types,
            resource,
            minimumClusterSize,
            navbarHeight,
            backlogEmailRecipients,
            perPage,
            mapParams,
            selectedProposal,
            selected_string,
            selected,
            selectedRows,
            left_panel,
            email,
            sales_arborist,
            right_panel,
            top_panel,
            acceptedTotals,
            data,
            selectedFilter,
            selectedRow,
            map,
            employees,
            isDeclinedModalOpened,
            showDock,
            columnOptions,
            visibleColumns
        } = this.state;
        const darkThemes = ["cyborg", "darkly", "slate", "solar", "superhero"];
        const className = darkThemes.includes(theme) ? "" : "light";
        const customerOptions = this.customerSearch.searchOptions();
        const zipOptions = this.zipSearch.searchOptions();
        const cityOptions = this.citySearch.searchOptions();
        const proposalOptions = this.proposalSearch.searchOptions();
        const selectedStatus = dashboardStatuses[selected].label;

        const customerInputSearch = {
            customerOptions: customerOptions,
            customerSearch: this.delayedCustomerSearch,
            onInputChange: this.customerSearch.search
        }
        const proposalInputSearch = {
            proposalOptions: proposalOptions,
            proposalSearch: this.delayedproposalSearch,
            onInputChange: this.proposalSearch.search
        }
        const zipInputSearch = {
            zipOptions: zipOptions,
            zipSearch: this.delayedUpdateZip,
            onInputChange: this.zipSearch.search
        }

        const cityInputSearch = {
            cityOptions: cityOptions,
            citySearch: this.delayedCitySearch,
            onInputChange: this.citySearch.search
        }

        const recipients = resource.isBacklog ? backlogEmailRecipients : selectedRecords
        const recipientsEmail = data.filter(d => recipients?.includes(d.proposalId)).map(d => {
            return {name: d.customer_name, email: d.email_address}
        })

        const statusColor = dashboardStatuses.find(d => d.label === selectedStatus).color
        const tab = dashboardStatuses[selected].value

        let columnOptionsSelect = columnOptions[tab].map(filter => {
            let result = {};
            result.value = filter;
            result.label = filter;
            return result;
        });


        let tabColumns = visibleColumns[tab]

        if (visibleColumns && !tabColumns) {
            tabColumns = []
        }

        const selectedServices = {
            status: tab,
            records: selectedRecords
        }

        return (
            <div id="dashboard_map">
                <ReactDock position='right'
                           isVisible={showDock}
                           dimMode="none"
                           defaultSize={window.innerWidth > 990 ? 0.24 : window.innerWidth > 600 ? 0.6 : 1}
                           dockStyle={{overflow: 'hidden'}}
                           zIndex={1031}
                >
                    <div className="close-dock">
                        <h4 className="margin10 pull-left">Dashboard settings</h4>
                        <div className="pull-right small-margin">
                            <Glyphicon glyph='remove' className="pointer margin10"
                                       onClick={() => this.setState({showDock: null})}/>
                        </div>
                    </div>
                    <div className="margin10 top40">
                        <Row className="no-margin space-between-end">
                            <Col md={8} className='no-left-padding'>
                                <h5 className={isMobile ? 'top25' : 'top50'}>Select columns to display for <span
                                    className='bold'
                                    style={{color: `${statusColor}`}}>{selectedStatus}</span> tab
                                </h5>
                            </Col>
                            <Col md={4} className='text-right no-right-padding'>
                                <Button disabled={tabColumns.length === columnOptions[tab].length}
                                        className={`recipient-btn ${isMobile ? 'top25' : 'top50'}`} onClick={() => {
                                    let {dashboard_settings} = this.state;
                                    visibleColumns[tab] = columnOptions[tab]
                                    dashboard_settings = visibleColumns

                                    this.setState({
                                        visibleColumns: visibleColumns,
                                        dashboard_settings: dashboard_settings
                                    }, this.saveReportSettings)
                                }}>
                                    <span>Select All</span>
                                </Button>
                            </Col>
                        </Row>
                        <Select className="Select"
                                classNamePrefix="small-select"
                                value={select(columnOptionsSelect, visibleColumns[tab])}
                                options={columnOptionsSelect}
                                isClearable={false}
                                isMulti
                                placeholder="Columns"
                                onChange={this.handleColumnOptionsChange}
                        />
                    </div>
                </ReactDock>
                {report &&
                    <div ref={(c) => this._parent = c} className={className} style={{
                        overflow: isMobile ? null : "hidden",
                        position: isMobile ? null : 'fixed',
                        top: isMobile ? null : `${navbarHeight}px`,
                        height: isMobile ? null : `calc(100vh - ${navbarHeight}px)`,
                        width: isMobile ? null : "100vw"
                    }}>
                        <div ref={(c) => this._panel = c} className="dashboard-statuses">
                            {report && smMobile ?
                                <div className="btn-group btn-breadcrumb">
                                    <Row className={xsMobile ? "mt-10" : "margin-2"}>
                                        {dashboardStatuses.map((status, i) => {
                                                return (
                                                    <Col xs={3} className="no-padding bottom5">
                                                        <a onClick={() => this.selectStatus(i)} key={i}
                                                           className={`btn btn-default btn_statuses ${(selected === 0 && i === 0) || i + 1 === selected ? 'beforeSelected' : ''} ${i === selected ? 'selected' : ''}`}>
                                                            <div>{report[status.value].count} {status.label}</div>
                                                            <div style={{color: status.color}}><Dollars
                                                                amount={report[status.value].amount}/></div>
                                                        </a>
                                                    </Col>
                                                )
                                            }
                                        )}
                                    </Row>
                                </div>
                                : report && !smMobile ?
                                    <div style={{marginLeft: "0px"}} className="btn-group btn-breadcrumb">
                                        {dashboardStatuses.map((status, i) => {
                                                return <a onClick={() => this.selectStatus(i)} key={i}
                                                          className={`btn btn-default top-nav-btn ${(selected === 0 && i === 0) || i + 1 === selected ? 'beforeSelected' : ''} ${i === selected ? 'selected' : ''}`}>
                                                    <div>{report[status.value].count} {status.label}</div>
                                                    <div style={{color: status.color}}><Dollars
                                                        amount={report[status.value].amount}/></div>
                                                </a>
                                            }
                                        )}
                                    </div> : null}
                        </div>
                        {isMobile &&
                            <div>
                                <Row className="no-left-margin mr-15 text-right">
                                    <Col>
                                        <Button
                                            className="pointer no-top-margin"
                                            bsStyle="warning"
                                            onClick={() => this.setState({filterModal: !filterModal})}>
                                            Filter {" "}<FontAwesomeIcon icon="search" className="small-margin"/>
                                        </Button>
                                    </Col>
                                </Row>
                                <Row className="ml-15 mr-15 tableHeight" style={{position: 'relative'}}>
                                    <BottomRightPanelMobile
                                        data={data}
                                        selectedFilter={selectedFilter}
                                        acceptedTotals={acceptedTotals}
                                        report={report}
                                        dashboardStatuses={dashboardStatuses}
                                        selectedStatus={selectedStatus}
                                        selected={selected}
                                        resource={resource}
                                        isDeclinedModalOpened={isDeclinedModalOpened}
                                        allSelected={allSelected}
                                        selectedRecords={selectedRecords}
                                        handleData={this.handleData}
                                        setEmailRecipients={this.setEmailRecipients}
                                        onChangeResource={this.onChangeResource}
                                        onSelectAll={this.onSelectAll}
                                        toggleDeclinedModal={this.toggleDeclinedModal}
                                        onSearch={this.onSearch}
                                        filter={acceptedTotals && acceptedTotals[selectedFilter]}
                                        reloadReport={this.fetchData}
                                        reloadTableData={this.fetchTableData}
                                        createCSV={this.createCSV}
                                        onChangeState={this.onChangeState}
                                        sentFormatter={this.sentFormatter}
                                        lastSentFormatter={this.lastSentFormatter}
                                        sentCompleteFormatter={this.sentCompleteFormatter}
                                        showSelectedMarkers={this.showSelectedMarkers}
                                        declineSelected={this.declineSelected}
                                        tabColumns={tabColumns}
                                        onEmail={this.email}
                                        hiddenTable={tabColumns.length === 0}
                                    />
                                </Row>
                            </div>
                        }
                        {isMobile && <LeadsFilterModal
                            title="Dashboard Filter"
                            show={filterModal}
                            onHide={() => this.setState({filterModal: !filterModal}, () => this.fetchTableData())}
                            closeButton={() => this.setState({filterModal: !filterModal})}
                        >
                            <BottomLeftPanelMobile
                                resource={resource}
                                service_types={service_types}
                                durations={durations}
                                employees={employees}
                                selectedStatus={selectedStatus}
                                selected={selected}
                                filters={acceptedTotals}
                                customerInputSearch={customerInputSearch}
                                zipInputSearch={zipInputSearch}
                                proposalInputSearch={proposalInputSearch}
                                cityInputSearch={cityInputSearch}
                                dashboardState={this.state}
                                reloadTableData={this.fetchTableData}
                                reloadReport={this.fetchData}
                                selectResourceAttr={this.selectResourceAttr}
                                selectCheckboxAttr={this.selectCheckboxAttr}
                                onChangePromisedStatus={this.onChangePromisedStatus}
                                statusDateFilterFormatter={this.statusDateFilterFormatter}
                                handleData={this.handleData}
                                handleRangeDateChange={this.handleRangeDateChange}
                            />
                        </LeadsFilterModal>}
                        <div style={{position: "relative", padding: isMobile && '10px'}}>
                            {!isMobile &&
                                (
                                    <div style={{
                                        position: "absolute",
                                        display: "inline-block",
                                        zIndex: "1",
                                        right: "30px",
                                        top: "30px"
                                    }}>
                                        <NavDropdown pullRight
                                                     title={<div style={{color: "#fcfcfc", fontSize: "30px"}}><Glyphicon
                                                         glyph="cog"/>{false &&
                                                         <span className="pad-left">Settings</span>}
                                                     </div>}
                                                     noCaret
                                        >
                                            <Checkbox style={{marginLeft: "5px"}} name="left_panel"
                                                      onClick={this.handleCheckbox}
                                                      checked={left_panel.show}>Filter</Checkbox>
                                            <Checkbox style={{marginLeft: "5px"}} name="right_panel"
                                                      onClick={this.handleCheckbox}
                                                      checked={right_panel.show}>Proposals</Checkbox>
                                        </NavDropdown>
                                    </div>
                                )
                            }
                            <Map
                                selectedString={selected_string}
                                mapData={mapParams}
                                data={data}
                                map={map}
                                handleMap={map => this.setState({map})}
                                fitBounds={fitBounds}
                                closeInfoWindow={() => this.setState({selectedProposal: 0})}
                                selectedMarkers={selectedRecords}
                                delayedInBounds={this.delayedInBounds}
                                handleData={this.handleData}
                                refGoogleMap={this.refGoogleMap}
                                selectedProposal={selectedProposal}
                                selectMarker={this.selectMarkerOneToMany}
                                navbarHeight={navbarHeight}
                                minimumClusterSize={minimumClusterSize}
                                allSelected={allSelected}
                                client={this.props.client}
                            />
                            {this._parent && !isMobile &&
                                <BottomLeftPanel
                                    durations={this.props.durations}
                                    parentRef={this._parent}
                                    handleData={this.handleData}
                                    users={users}
                                    selected={selected}
                                    selectedStatus={selectedStatus}
                                    selectedDuration={resource && resource.date_name}
                                    selectResourceAttr={this.selectResourceAttr}
                                    selectCheckboxAttr={this.selectCheckboxAttr}
                                    sales_arborist={sales_arborist}
                                    selectedRow={selectedRow}
                                    reloadTableData={this.fetchTableData}
                                    reloadReport={this.fetchData}
                                    selectFilter={this.selectFilter}
                                    filters={acceptedTotals}
                                    info={this.state.left_panel}
                                    otherPanels={[right_panel]}
                                    navbarHeight={navbarHeight}
                                    selectedFilter={selectedFilter}
                                    promisedStatus={resource.promisedStatus}
                                    service_types={service_types}
                                    employees={employees}
                                    resource={resource}
                                    dashboardState={this.state}
                                    customerInputSearch={customerInputSearch}
                                    zipInputSearch={zipInputSearch}
                                    cityInputSearch={cityInputSearch}
                                    proposalInputSearch={proposalInputSearch}
                                    handleRangeDateChange={this.handleRangeDateChange}
                                    statusDateFilterFormatter={this.statusDateFilterFormatter}
                                />
                            }
                            {this._parent && this._parent.offsetHeight > 100 && this._parent.offsetWidth > 100 && !isMobile &&
                                <BottomRightPanel
                                    report={this.state.report}
                                    map={map}
                                    fitBounds={fitBounds}
                                    setEmailRecipients={this.setEmailRecipients}
                                    selectedString={selected_string}
                                    selectedProposal={selectedProposal}
                                    selectedStatus={selectedStatus}
                                    parentRef={this._parent}
                                    backlogEmailRecipients={backlogEmailRecipients}
                                    selectMarker={this.selectRecord}
                                    reloadTableData={this.fetchTableData}
                                    isDeclinedModalOpened={isDeclinedModalOpened}
                                    reloadReport={this.fetchData}
                                    toggleDeclinedModal={this.toggleDeclinedModal}
                                    selectResourceAttr={this.selectResourceAttr}
                                    selectedMarkerClass={this.selectedMarkerClassWithChangeTextColor}
                                    declineSelected={this.declineSelected}
                                    handleData={this.handleData}
                                    selectedRows={selectedRows}
                                    onSelectAll={this.onSelectAll}
                                    onRowSelect={this.onRowSelect}
                                    onRowClick={this.rowClickHandler}
                                    dashboardStatuses={dashboardStatuses}
                                    selected={selected}
                                    acceptedTotals={acceptedTotals}
                                    selectedFilter={resource && resource.selectedFilter}
                                    filter={acceptedTotals && acceptedTotals[selectedFilter]}
                                    data={this.state.rightPanelData}
                                    otherPanels={[left_panel]}
                                    info={this.state.right_panel}
                                    navbarHeight={navbarHeight}
                                    resource={resource}
                                    selectedRecords={selectedRecords}
                                    allSelected={allSelected}
                                    onChangeState={this.onChangeState}
                                    onChangeResource={this.onChangeResource}
                                    createCSV={this.createCSV}
                                    onSearch={this.onSearch}
                                    sentFormatter={this.sentFormatter}
                                    lastSentFormatter={this.lastSentFormatter}
                                    sentCompleteFormatter={this.sentCompleteFormatter}
                                    showSelectedMarkers={this.showSelectedMarkers}
                                    tabColumns={tabColumns}
                                    onEmail={this.email}
                                    hiddenTable={tabColumns.length === 0}
                                />
                            }
                            {
                                email && <EmailDialog defaultEmail={this.props.email}
                                                      bulkEmailData={{recipients, selectedRows: selectedServices}}
                                                      isBulk={true}
                                                      sendBulkNotification={(email, callback) => this.props.actions.sendNotification(email, recipients, "bulk", (res) => {
                                                          callback && callback(res.data)
                                                      })}
                                                      templateMode
                                                      emailType="bulk"
                                                      confirmMessage={true}
                                                      selectedRecords={recipientsEmail}
                                                      hideEmailDialog={() => {
                                                          this.setState({email: null});
                                                      }}
                                                      sendInBackground={true}
                                />
                            }
                        </div>
                    </div>
                }
                <LeadsFilterModal
                    title="Decline Proposal"
                    show={isDeclinedModalOpened}
                    backdrop="static"
                    declinedModal={true}
                    onHide={() => this.toggleDeclinedModal(() => this.declineSelected())}
                    closeButton={() => this.toggleDeclinedModal()}
                >
                    <div className="rate-modal-body-padding d-flex">
                        <FontAwesomeIcon icon={faExclamationTriangle}
                                         className={isMobile ? "font20 alert-font-color mt-3" : "small-margin font20 alert-font-color mt-3"}/>
                        <div className='columnDirection'>
                            <p className={isMobile ? "ml-10 font12 line_height17_5 bold" : "ml-10 line_height17_5 bold"}>
                                Number of records selected: {selectedRecords && selectedRecords.length}
                            </p>
                            <p className={isMobile ? "ml-10 font12 line_height17_5" : "ml-10 line_height17_5"}>
                                Yes. Move all selected proposals and services to "DECLINED" status
                            </p>
                        </div>
                    </div>
                </LeadsFilterModal>
            </div>
        );
    }
}

Dashboard.propTypes = {
    dashboardStatuses: PropTypes.object.isRequired,
    durations: PropTypes.array.isRequired,
    users: PropTypes.array.isRequired
};

Dashboard.defaultProps = {
    dashboardStatuses: dashboardStatuses(),
    durations: [
        {value: 'all', label: 'All'},
        {value: 'today', label: 'Today'},
        {value: 'yesterday', label: 'Yesterday'},
        {value: 'current_week', label: 'Current Week'},
        {value: 'last_week', label: 'Last Week'},
        {value: 'current_month', label: 'Current Month'},
        {value: 'last_month', label: 'Last Month'},
        {value: 'current_year', label: 'Current Year'},
        {value: 'last_year', label: 'Last Year'},
        {value: 'custom', label: 'Custom'}
    ]
};

function mapStateToProps(state, ownProps) {
    return {
        users: state.users.employees,
        email: state.auth.email,
        theme: state.theme.theme,
        client: state.client.customerInfo
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
