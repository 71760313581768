import React, {Component} from 'react';
import PropTypes from "prop-types"
import {Glyphicon} from 'react-bootstrap'
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table"

import {
    dateFormatter,
    dollarsFormatter,
    proposalNoFormatter,
    workOrderNoFormatter
} from "../../../components/BootStrapTableCellFormatters";

class InvoiceServicesList extends Component {
    state = {
        selectedServiceType: 'open',
        selectedService: [],
    };

    constructor(props) {
        super(props);
        this.serviceFormatter = this.serviceFormatter.bind(this);
        this.removeServiceFromRow = this.removeServiceFromRow.bind(this);
    }

    removeServiceFromRow(row) {
        const action_confirmed = window.confirm('Do you REALLY want to delete this Service from the Invoice?');

        if (action_confirmed) {
            this.props.removePsFromInvoice(row);
        }
    }

    serviceFormatter = (list) => {
        return (cell, row) => (
            <label>
                <p>
        <span className="text-danger">
            {(!this.props.disableRemovingSingleRecord || (list && list.length > 1)) ?
                <Glyphicon glyph="remove-sign" onClick={() => this.removeServiceFromRow(row)}/> : ''}
        </span>
                    #{row.serviceNo} {row.serviceName}<br/>{row.plants.map(p => {
                    return <p>{p.label}</p>
                })}</p>
            </label>
        );
    };

    render() {
        const {
            proposalServicesInfo
        } = this.props;

        return (
            <BootstrapTable
                data={proposalServicesInfo}
                striped={true}
                bordered={false}
                hover={true}
                search={false}
            >
                <TableHeaderColumn
                    isKey
                    hidden
                    dataField="id"
                    dataSort={false}
                >
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataSort={true}
                    dataField="plants"
                    dataFormat={this.serviceFormatter(proposalServicesInfo)}
                >
                    Service
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataSort={true}
                    columnClassName="menu-show-fixer"
                    dataFormat={proposalNoFormatter}
                    dataField="proposalNo"
                >
                    Proposal #
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataSort={true}
                    columnClassName="menu-show-fixer"
                    dataFormat={workOrderNoFormatter}
                    dataField="workOrderNo"
                >
                    WO #
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataSort={true}
                    filterFormatted={false}
                    dataFormat={dateFormatter}
                    dataField="completed_at"
                >
                    Completed Date
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataSort={true}
                    dataFormat={dollarsFormatter}
                    filterFormatted={false}
                    dataField="serviceCost"
                >
                    Cost
                </TableHeaderColumn>
            </BootstrapTable>
        )
    }
}

InvoiceServicesList.propTypes = {
    proposalServicesInfo: PropTypes.array.isRequired,
    removePsFromInvoice: PropTypes.func.isRequired,
    disableRemovingSingleRecord: PropTypes.bool.isRequired
};

export default InvoiceServicesList
