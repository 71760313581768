import React from "react";
import {
    Col,
    ControlLabel,
    FormControl,
    FormGroup,
    Glyphicon,
    Label,
    OverlayTrigger,
    Row,
    Tooltip
} from "react-bootstrap";
import TextareaAutosize from "react-textarea-autosize";
import ColorCheckbox from "../../../Scheduler/ColorCheckbox";
import DefaultAnswerFormatter from "../DefaultAnswerFormatter";

const TextAnswer = ({number, question, value, onChange, options, setAnswerOptions, preview, disabled}) => {
    const textOptions = () => {
        return <Row>
            <Col xs={12}>
                <Label bsStyle="info">Placeholder</Label>
                <FormControl
                    type="text"
                    placeholder="Placeholder"
                    onChange={e => setAnswerOptions({
                        ...options,
                        placeholder: e.target.value
                    })}
                    value={options.placeholder}
                />
            </Col>
            <Col xs={12}>
                <ColorCheckbox value={options.singleRow}
                               label="Single row answer"
                               onChange={() => setAnswerOptions({
                                   ...options,
                                   singleRow: !options.singleRow,
                                   textBox: options.singleRow,
                               })}
                />
            </Col>
            <Col xs={12}>
                <ColorCheckbox value={options.textBox}
                               label="Text box answer"
                               onChange={() => setAnswerOptions({
                                   ...options,
                                   singleRow: options.textBox,
                                   textBox: !options.textBox,
                               })}
                />
            </Col>
            <Col xs={12}>
                <ColorCheckbox value={options.required}
                               label="Answer will be required"
                               onChange={() => setAnswerOptions({
                                   ...options,
                                   required: !options.required
                               })}
                />
            </Col>
            {options.textBox && <Col xs={12}>
                <Row className="flex-center">
                    <Col xs={5}>Min number of rows</Col>
                    <Col xs={7}>
                        <FormControl
                            type="number"
                            placeholder="Min"
                            onChange={(e) => {
                                let value = +e.target?.value
                                if (value || value === 0) {
                                    value = value > 0 ? value.toFixed(0) : 0
                                    setAnswerOptions({
                                        ...options,
                                        minRowNumber: value,
                                    })
                                }
                            }}
                            value={options.minRowNumber}
                        />
                    </Col>
                </Row>
            </Col>}
            {options.textBox && <Col xs={12}>
                <Row className="flex-center">
                    <Col xs={5}>Max number of rows</Col>
                    <Col xs={7}>
                        <FormControl
                            type="number"
                            placeholder="Max"
                            onChange={(e) => {
                                let value = +e.target?.value
                                if (value || value === 0) {
                                    value = value > 0 ? value.toFixed(0) : 0
                                    setAnswerOptions({
                                        ...options,
                                        maxRowNumber: value,
                                    })
                                }
                            }}
                            value={options.maxRowNumber}
                        />
                    </Col>
                </Row>
            </Col>}
        </Row>
    }

    const textAnswers = () => {
        return <Row>
            <Col xs={12}>
                {options.singleRow ?
                    <FormGroup>
                        <ControlLabel className="bold">
                            <span>{number}. {question}</span>
                            {options.required && <OverlayTrigger
                                placement='top'
                                overlay={<Tooltip id={`tooltip-refresh`}>Answer is required</Tooltip>}
                            >
                                <Glyphicon glyph='exclamation-sign' className="ml-6 font14"/>
                            </OverlayTrigger>}
                        </ControlLabel>
                        <FormControl
                            type="text"
                            placeholder={options.placeholder}
                            onChange={e => onChange(e.target.value)}
                            value={value}
                            disabled={disabled}
                        />
                    </FormGroup>
                    :
                    <FormGroup>
                        <ControlLabel className="bold">
                            <span>{number}. {question}</span>
                            {options.required && <OverlayTrigger
                                placement='top'
                                overlay={<Tooltip id={`tooltip-refresh`}>Answer is required</Tooltip>}
                            >
                                <Glyphicon glyph='exclamation-sign' className="ml-6 font14"/>
                            </OverlayTrigger>}
                        </ControlLabel>
                        <TextareaAutosize
                            style={{width: '100%', resize: 'none'}}
                            name="body"
                            value={value}
                            disabled={disabled}
                            onChange={e => onChange(e.target.value)}
                            minRows={options.minRowNumber}
                            maxRows={options.maxRowNumber}
                            placeholder={options.placeholder}
                        />
                    </FormGroup>
                }
            </Col>
        </Row>
    }

    return preview ?
        <DefaultAnswerFormatter options={textOptions()} preview={textAnswers()}/>
        :
        textAnswers()

}

export default TextAnswer;