import {apiCall} from "../../../common/apiActionHandler";
import axios from "axios";
import {mapForSelect} from "../../../common/commonHandlers";
import {addAlert} from "../../App/actions";

export const getCustomer = (customer_id, callback, errorCallback) => (dispatch, getState) => {
    const config = {
        url: `/api/customers/${customer_id}`
    };

    apiCall("Searching for customer", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false, errorCallback);
};

export const getQbCustomer = (customer_id, callback, errorCallback) => (dispatch, getState) => {
    const config = {
        url: `/api/quickbooks/get_customer`,
        params: {id: customer_id}
    };

    apiCall("Searching for quickbooks customer", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, true, errorCallback);
};

export const searchForCustomer = (searchText, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/customers/name_zip_city_search`,
        params: {q: searchText}
    };

    apiCall("Searching for customers", config, async config => {
        const response = await axios.request(config);
        callback(mapForSelect(response.data));
    }, dispatch, getState, false);
};

export const searchForQbCustomer = (searchText, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/quickbooks/search_for_customer`,
        params: {search_text: searchText}
    };

    apiCall("Searching for quickbooks customers", config, async config => {
        const response = await axios.request(config);
        callback(mapForSelect(response.data));
    }, dispatch, getState, false);
};

export const syncCustomers = (filter, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/quickbooks/sync_customers`,
        params: filter
    };

    apiCall("Synchronizing customers", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};

export const syncCustomersSite = (params, callback, errorCallback) => async (dispatch, getState) => {
    const config = {
        url: `/api/quickbooks/update_customer_site`,
        method: 'PUT',
        params: params
    };

    apiCall("Synchronizing customer site", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, true, errorCallback);
};

export const removeSiteQBId = (site_id, callback, errorCallback) => async (dispatch, getState) => {
    const config = {
        url: `/api/quickbooks/remove_site_quickbooks_id`,
        method: 'PUT',
        params: {site_id}
    };

    apiCall("Remove site Quickbooks ID", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, true, errorCallback);
};

export const syncAllCustomers = (callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/quickbooks/sync_all_customers`
    };

    apiCall("Synchronizing customers", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};

export const updateCustomer = (items, direction, callback, errorCallback) => async (dispatch, getState) => {
    const config = {
        url: `/api/quickbooks/update_customer`,
        method: 'PUT',
        data: {data: {...items}, direction: direction}
    };

    apiCall("Updating customer", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false, errorCallback);
};

export const updateThParam = (data, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/quickbooks/update_th_param`,
        method: 'PUT',
        data: data
    };

    apiCall("Updating customer", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState);
};

export const updateQbParam = (data, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/quickbooks/update_qb_param`,
        method: 'PUT',
        data: data
    };

    apiCall("Updating customer", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState);
};

export const synchronizeManyCustomers = (customers, direction, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/quickbooks/sync_many_customers`,
        method: 'PUT',
        data: {customers, direction}
    };

    apiCall("Updating customers", config, async config => {
        const response = await axios.request(config);
        callback && callback()
        addAlert({
            message: response.data.message,
            mood: "success",
            dismissAfter: 3500,
        })
    }, dispatch, getState);
};

export const disassociateAccount = (id, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/quickbooks/disassociate_account`,
        method: 'PUT',
        params: {id}
    };

    apiCall("Disassociate account", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState);
};

export const refreshCustomer = (id, is_th_customer, callback, errorCallback) => async (dispatch, getState) => {
    const config = {
        url: `/api/quickbooks/refresh_customer`,
        method: 'POST',
        data: {id, is_th_customer}
    };

    apiCall("Refresh customer", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, true, errorCallback);
};