import {applyMiddleware, compose, createStore} from "redux"
import {createStateSyncMiddleware, initStateWithPrevTab} from 'redux-state-sync';
import thunk from "redux-thunk"
import {autoRehydrate} from "redux-persist"
import rootReducer from "./reducers"

//TODO: Consider removing devtools extension when building for production to keep prying eyes away
//http://2dv607.oskarklintrot.se/exclude-modules-when-building-for-production/

const config = {
    predicate: action => {
        const whiteListActions = ['SET_CLIENT_APP_VERSION', 'SET_NEW_CLIENT_APP_VERSION', 'SIGN_OUT_USER'];
        if (whiteListActions.includes(action.type)) {
            return true;
        }
    },
};

//TODO: pass in initial state to hydrate the app
export default function configureStore(initialState) {
    const store = createStore(
        rootReducer,
        initialState,
        compose(
            autoRehydrate(),
            applyMiddleware(thunk, createStateSyncMiddleware(config)),
            window.devToolsExtension ? window.devToolsExtension() : f => f
        )
    )

    //we're doing this in the main app page so that we can control keeping auth for new tabs/windows
    // const persistConfig = {whitelist: ['auth']}
    // persistStore(store, persistConfig, () => { console.info('rehydration from Store complete')})
    //Reducers aren't modules so they don't reload. As a hack, we reload them manually.
    //See https://blog.tighten.co/react-101-using-redux
    // or one of wesbos videos where he talks about it https://www.youtube.com/playlist?list=PLu8EoSxDXHP5uyzEWxdlr9WQTJJIzr6jy
    if (module.hot) {
        module.hot.accept("./reducers", () => {
            const nextRootReducer = require("./reducers").default
            store.replaceReducer(nextRootReducer)
        })
    }

    initStateWithPrevTab(store);

    return store
}
