import React from "react"
import {bindActionCreators} from "redux"
import {connect} from "react-redux"
import Helmet from "react-helmet"
import * as MyActions from "./ActiveCustomersApi"
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table"
import {Button, Col, ControlLabel, FormGroup, Glyphicon, Grid, Row,} from "react-bootstrap"
import moment from "moment/moment"
import ResourceComponent from "../../components/ResourceComponent";
import Datetime from "react-datetime";
import {defaultDateFormat, defaultDateFormatShort, saveNewCsvDownload} from "../../common/commonHandlers";

const Actions = {...MyActions, saveNewCsvDownload}

class ActiveCustomers extends ResourceComponent {

    state = {
        resource: {proposalProposedStart: null, proposalProposedEnd: null},
        activeCustomers: []
    }

    tableRef = React.createRef()

    render() {
        const {activeCustomers} = this.state
        const {proposalProposedStart, proposalProposedEnd} = this.state.resource;

        return (
            <Grid fluid>
                <Helmet title="Active Customers"/>
                <form onSubmit={this.fetchActiveCustomers}>
                    <Row>
                        <Col md={12}>
                            <h3 className="text-primary" style={{marginBottom: "0px", marginTop: "0px"}}>
                                {`Active Customers ${activeCustomers ? `${activeCustomers.length} found.` : "Pick some dates!"} `}
                            </h3>
                            <FormGroup bsSize="sm">
                                <ControlLabel>
                                    {" "}&nbsp;
                                </ControlLabel>
                                <br/>
                                <Datetime
                                    dateFormat={defaultDateFormat}
                                    timeFormat={null}
                                    value={proposalProposedStart ? moment(proposalProposedStart) : ""}
                                    className="text-center"
                                    inputProps={{placeholder: 'Start Date'}}
                                    onChange={this.dateResourceAttr('proposalProposedStart')}/>
                                {" "}
                                <span className='text-default'><Glyphicon glyph='minus'/></span>
                                {" "}
                                <Datetime
                                    dateFormat={defaultDateFormat}
                                    timeFormat={null}
                                    value={proposalProposedEnd ? moment(proposalProposedEnd) : ""}
                                    className="text-center"
                                    inputProps={{placeholder: 'End Date'}}
                                    onChange={this.dateResourceAttr('proposalProposedEnd')}/>
                                {' '}
                                <Button bsSize="small" type="submit" bsStyle="success">
                                    Find Customers
                                </Button>
                            </FormGroup>
                        </Col>

                    </Row>
                </form>
                <p>&nbsp;</p>

                <Row>
                    <Col md={12}>
                        {activeCustomers ? this._renderTable(activeCustomers) : null}
                    </Col>
                </Row>

                <Row>
                    <Col md={12}/>
                </Row>

            </Grid>
        )
    }

    fetchActiveCustomers = e => {
        e.preventDefault()
        const {resource} = this.state;
        this.props.actions.fetchActiveCustomers(resource, result => {
            this.setState({activeCustomers: result})
        })
    }

    _dateFormatter = cell => cell && moment(cell).format(defaultDateFormatShort)

    _renderTable = data => {
        const options = {
            page: 1,  //which page you want to show as default
            sizePerPageList: [5, 25, 50, 75, 100], //you can change the dropdown list for size per page
            sizePerPage: 50,  //which size per page you want to locate as default
            paginationSize: 3,  //the pagination bar size.
        }
        return (
            <BootstrapTable
                ref={this.tableRef}
                data={data}
                striped={true}
                bordered={false}
                hover={true}
                exportCSV={true}
                pagination={true}
                search={true}
                csvFileName={() => {
                    const fileName = `Active-Customers-${moment().format()}.csv`
                    if (this.tableRef?.current) {
                        this.props.actions.saveNewCsvDownload(this.tableRef.current, fileName, this.state.resource, "active customers")
                    }
                    return fileName
                }}
            >

                <TableHeaderColumn
                    dataField="customer_id"
                    tdStyle={{whiteSpace: 'normal'}}
                    thStyle={{whiteSpace: 'normal'}}
                    dataSort={true}
                >
                    Customer Id
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="customer_name"
                    tdStyle={{whiteSpace: 'normal'}}
                    thStyle={{whiteSpace: 'normal'}}
                    dataSort={true}
                    columnClassName='menu-show-fixer'
                >
                    Customer Name
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="street"
                    tdStyle={{whiteSpace: 'normal'}}
                    thStyle={{whiteSpace: 'normal'}}
                    dataSort={true}
                    columnClassName='menu-show-fixer'
                >
                    Street
                </TableHeaderColumn>

                <TableHeaderColumn
                    dataField="city"
                    tdStyle={{whiteSpace: 'normal'}}
                    thStyle={{whiteSpace: 'normal'}}
                    dataSort={true}
                    columnClassName='menu-show-fixer'
                >
                    City
                </TableHeaderColumn>

                <TableHeaderColumn
                    dataField="zip"
                    tdStyle={{whiteSpace: 'normal'}}
                    thStyle={{whiteSpace: 'normal'}}
                    dataSort={true}
                    columnClassName='menu-show-fixer'
                >
                    Zip
                </TableHeaderColumn>

                <TableHeaderColumn
                    dataField="email"
                    tdStyle={{wordWrap: 'break-word', wordBreak: 'break-word', whiteSpace: 'pre-wrap'}}
                    thStyle={{whiteSpace: 'normal'}}
                    dataSort={true}
                    columnClassName='menu-show-fixer'
                >
                    Email
                </TableHeaderColumn>

                <TableHeaderColumn
                    dataField="created"
                    csvHeader="Customer Since"
                    tdStyle={{whiteSpace: 'normal'}}
                    thStyle={{whiteSpace: 'normal'}}
                    dataFormat={this._dateFormatter}
                    csvFormat={this._dateFormatter}
                    dataSort={true}
                    columnClassName='menu-show-fixer'
                >
                    Customer Since
                </TableHeaderColumn>

                <TableHeaderColumn
                    dataField="customer_rep"
                    csvHeader="Customer Arborist"
                    tdStyle={{whiteSpace: 'normal'}}
                    thStyle={{whiteSpace: 'normal'}}
                    dataSort={true}
                    columnClassName='menu-show-fixer'
                >
                    Customer Arborist
                </TableHeaderColumn>

                <TableHeaderColumn
                    dataField="last_proposal_rep"
                    csvHeader="Last Arborist"
                    tdStyle={{whiteSpace: 'normal'}}
                    thStyle={{whiteSpace: 'normal'}}
                    dataSort={true}
                    columnClassName='menu-show-fixer'
                >
                    Last Arborist
                </TableHeaderColumn>

                <TableHeaderColumn
                    hidden
                    export
                    dataField="customer_id"
                    isKey={true}
                >
                </TableHeaderColumn>

            </BootstrapTable>
        )
    }

}

function mapStateToProps(state) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Actions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ActiveCustomers)
