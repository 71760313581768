import React, {Component} from "react"
import {Badge, Button, Col, Grid, MenuItem, Nav, NavDropdown, OverlayTrigger, Row, Tooltip} from "react-bootstrap"
import {Link} from "react-router"
import {LinkContainer} from "react-router-bootstrap"
import * as Actions from "./CustomerInvoicesApi"
import {bindActionCreators} from "redux"
import {connect} from "react-redux"

import Select from "react-select"
import moment from "moment"
//demo map
import Dollars from "../../../components/Dollars"
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table"
import CustomerHeader from "../../../components/CustomerHeader"

import {dateFormatter, dollarsFormatter} from "../../../components/BootStrapTableCellFormatters";
import {defaultDateFormat, defaultDateFormatShort, mapForSelect, select} from "../../../common/commonHandlers";
import Datetime from "react-datetime";
import './CustomerInvoices.scss'

const isMobile = window.screen.width < 768;

class CustomerInvoices extends Component {
    state = {
        selectedServiceType: 'open',
        selectedInvoices: [],
        isFiltered: false,
        showFilters: false,
        invoiceFromDate: moment().startOf('month').format('YYYY-MM-DD'),
        invoiceToDate: moment().endOf('month').format('YYYY-MM-DD'),
        invoicePaidDate: moment().format('YYYY-MM-DD')
    }

    componentDidMount = () => {
        this.props.actions.fetchCustomerById(this.props.params.id, (customer) => {
            this.setState({
                customer, foundCustomers: [
                    {id: customer.id, name: customer.name}
                ]
            })
        })
        this.props.actions.fetchCustomerSites(this.props.params.id, (customerSites) => {
            this.setState({customerSites})
        })
    }

    componentWillReceiveProps = nextProps => {
        if (
            this.props.params.id &&
            nextProps.params.id &&
            this.props.params.id !== nextProps.params.id
        ) {
            this.props.actions.fetchCustomerById(nextProps.params.id, (customer) => {
                this.setState({
                    customer, foundCustomers: [
                        {id: customer.id, name: customer.name}]
                })
            })
            this.props.actions.fetchCustomerSites(this.props.params.id, (customerSites) => {
                this.setState({customerSites})
            })
        }
    }
    updateCustomerSearch = customerSearch => {
        this.props.actions.searchForCustomer(customerSearch, (foundCustomers) => {
            this.setState({foundCustomers})
        })
    }


    editInvoiceFormatter = (cell, row) => {
        const {params} = this.props
        return (
            <Nav>
                <LinkContainer to={`/proposals/${row.proposal_id}/invoices`}>
                    <MenuItem>{row.invoice_no}</MenuItem>
                </LinkContainer>
            </Nav>
        )

    }


    render() {
        const {
            customer,
            customerInvoices,
            customerSites,
            foundCustomers,
        } = this.state

        const customerId = this.props.params.id ? this.props.params.id : null

        if (customerId === null) {
            return (<span>I need a customerid parameter. I can't go on</span>)
        }


        return (
            (!customer && customer === undefined) ? <span>Loading...</span> :
                <Grid fluid className="customer-invoices">
                    <Row>
                        <Col md={12}>
                            <CustomerHeader
                                customerId={parseInt(customerId, 10)}
                                customerList={foundCustomers && foundCustomers.map(customer => ({
                                    value: customer.id,
                                    label: customer.name,
                                }))}
                                onUpdateCustomerSearch={this.updateCustomerSearch}
                                onSelectCustomer={this.props.actions.updateSelectedCustomer}
                                title="Customer Invoices"
                                salesArborist={customer.sales_arborist}
                                haveSites={customerSites?.length > 0}
                            />

                            {this._renderBillingAddress(customer)}
                            <br/>
                            {customerSites &&
                                this._renderFilterBar()}
                            <br/>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>

                        </Col>
                        <Col md={6}>

                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            {this._renderInvoicesTable(customerInvoices)}
                        </Col>
                    </Row>
                </Grid>
        )
    }

    toggleColumnFilters = () => {
        this.setState({
            showFilters: !this.state.showFilters
        })
    }

    filterCustomerInvoices = () => {
        const {selectedSite, params} = this.props
        const {
            invoiceFromDate,
            invoiceToDate,
            selectedInvoiceType,
        } = this.state

        this.setState({
            isFiltered: true,
        })
        // send filter parms

        this.props.actions.fetchCustomerInvoices(
            {selectedSite, selectedInvoiceType, invoiceFromDate, invoiceToDate},
            params.id, (result) => {
                this.setState({customerInvoices: result})
            }
        )
    }

    markInvoicesPaid = () => {
        const {
            selectedInvoices,
            invoicePaidDate
        } = this.state

        // call actions
        this.props.actions.markInvoicesPaid(selectedInvoices, invoicePaidDate, () => {
            let {customerInvoices} = this.state
            let changedInvoices = []
            if (selectedInvoices) {

                selectedInvoices.forEach(invoiceId => {
                    let index = customerInvoices.findIndex(invoice => invoice.id === invoiceId)
                    if (index > -1) {
                        customerInvoices[index] = {...customerInvoices[index], paid_at: invoicePaidDate}
                    }
                })
            }
            this.setState({
                customerInvoices: [
                    ...customerInvoices,
                    ...changedInvoices,
                ]
            })
        })
    }

    _onSelectAll = (isSelected, rows) => {
        let selectedInvoices = []

        if (isSelected) {
            selectedInvoices = rows.map(i => i.id)
        }
        this.setState({selectedInvoices})
    }

    _onRowSelect = (row, isSelected, e) => {
        let selectedInvoices = [...this.state.selectedInvoices]
        const invoiceId = parseInt(row.id, 10)

        if (selectedInvoices) {
            if (isSelected) {
                selectedInvoices = selectedInvoices.concat(invoiceId) // prob concatted a dup #
            } else {
                selectedInvoices = selectedInvoices.filter(i => i !== invoiceId)
            }
            const uniqSelecteds = new Set(selectedInvoices) //uniqu them
            selectedInvoices = [...uniqSelecteds] //transform uniqs back into an array
        } else {
            selectedInvoices = selectedInvoices.filter(s => s !== invoiceId)
        }
        this.setState({selectedInvoices})
    }

    _onSingleSelect = (name, selected) => {
        this.setState({
            [name]: selected && selected.value
                ? selected.value
                : ["promisedStart", "promisedEnd"].includes(name) ? "" : null,
        })

        if (name === "selectedInvoiceType") {
            //if we change the service type then zero out the selected services too
            this.setState({selectedInvoice: []})
        }
    }

    _onMultiSelect = (name, args) => {
        this.setState({
            [name]: args === "" ? [] : args.split(",").map(a => parseInt(a, 10)),
        })

        if (name === "selectedInvoice") {
            //if we change the Service, ensure nothings selected for service type
            this.setState({selectedInvoiceType: null})
        }
    }
    _onSuccess = () => {
        this.props.actions.fetchCustomerAssets()
    }

    _cancelModal = siteId => {
        console.log("cancelling modal with site ", siteId)
        //this.props.actions.dismissAssetEditorModal()
        this.props.actions.doneEditingAsset(siteId) //dismiss the modal, reload mapview assets for site which the asset editor modal depends on to show correct data
        this.props.actions.fetchCustomerAssets() //refresh them in case
    }

    _renderBillingAddress = customer => (
        <div>
            <strong>Billing Address:</strong>
            {" "}
            {customer.address_1},
            {customer.address_2}{" "}
            {customer.city}, {customer.state}{" "}
            {customer.zip}
        </div>
    )

    _applyHistoryFilter = () => {
        this.props.actions.applyHistoryFilter()
    }

    _renderFilterBar = () => {
        const {
            selectedSite,
            invoiceTypes,
        } = this.props

        const {
            customerSites,
            invoiceFromDate,
            invoiceToDate,
            isFiltered,
            invoicePaidDate,
            selectedInvoices,
        } = this.state;

        const selectedInvoiceType = this.state.selectedInvoiceType
        const promisedStart = ''
        const customerId = this.props.params.id
        const customerSitesOptions = mapForSelect(customerSites);
        const invoiceTypesOptions = mapForSelect(invoiceTypes);
        return (
            <Row>
                <Col md={2} xs={12}>
                    <Select className="Select" classNamePrefix="select"
                            name="select_site"
                            value={select(customerSitesOptions, selectedSite)}
                            options={customerSitesOptions}
                            onChange={this._updateSelectedSite}
                            placeholder="All Sites"
                    />
                </Col>
                <Col md={1} xs={12}>
                    <Select className="Select" classNamePrefix="select"
                            placeholder="All Invoices"
                            value={select(invoiceTypesOptions, selectedInvoiceType)}
                            onChange={selected => this._onSingleSelect("selectedInvoiceType", selected)}
                            options={invoiceTypesOptions}
                    />
                </Col>
                <Col md={3} xs={12} className="text-center">
                    <div className={`small-right-padding`}>
                        <Datetime
                            dateFormat={defaultDateFormat}
                            isClearable
                            timeFormat={null}
                            value={moment(invoiceFromDate)}
                            className="half small-margin"
                            inputProps={{placeholder: 'Invoiced from'}}
                            onChange={m =>
                                this._onSingleSelect("invoiceFromDate", {
                                    value: moment(m).isValid() ? moment(m) : null,
                                })}/>
                        <Datetime
                            dateFormat={defaultDateFormat}
                            isClearable
                            timeFormat={null}
                            value={moment(invoiceToDate)}
                            className="half small-margin"
                            inputProps={{placeholder: 'Invoiced to'}}
                            onChange={m =>
                                this._onSingleSelect("invoiceToDate", {
                                    value: moment(m).isValid() ? moment(m) : null,
                                })}/>
                    </div>
                </Col>
                <Col md={1} xs={12} className="text-center">
                    <Button bsStyle="success" onClick={this.filterCustomerInvoices}>Filter</Button>
                    {isFiltered &&
                        <Button bsStyle="warning" onClick={() => this.toggleColumnFilters()}>Filter Rows</Button>
                    }
                </Col>
                <Col md={2} xs={12} className="text-center">
                    {/* <Button bsStyle="info">Print Selected</Button> */}
                    <Button bsStyle="warning" onClick={() => this.markInvoicesPaid()}
                            disabled={selectedInvoices.length == 0}>Update Paid Date</Button>
                </Col>
                <Col md={2} xs={12} className="text-center">
                    <Datetime
                        dateFormat={defaultDateFormat}
                        isClearable
                        timeFormat={null}
                        value={moment(invoicePaidDate).isValid() ? moment(invoicePaidDate) : null}
                        className="small-margin"
                        inputProps={{placeholder: 'Paid Date'}}
                        onChange={m =>
                            this._onSingleSelect("invoicePaidDate", {
                                value: moment(m).isValid() ? moment(m).format() : null,
                            })}/>
                </Col>
                <Col md={1} xs={12}>
                    <LinkContainer to={`/customer/manage_invoices/${customerId}`}>
                        <Button bsStyle="primary">New Invoice</Button>
                    </LinkContainer>
                </Col>
            </Row>
        )
    }

    _updateSelectedSite = selected => {
        this.props.actions.updateSelectedSite(
            selected === null ? null : selected.value
        )
    }

    dataFormat = (field, row) => {
        const start = row.promised_date_start
        const end = row.promised_date_end

        if (!end || !start) return field

        if ((row.status === 'In Work Order') || (row.status === 'Accepted') || (row.status === 'Scheduled')) {
            return (
                <div>
                    <div>{field}</div>
                    <div className="text-warning">
                        {moment(start).format(defaultDateFormat)} - {moment(end).format(defaultDateFormat)}
                    </div>
                </div>
            )
        } else {
            return field
        }
    }

    _customerWorkOrderLink = (cell, row) => (
        <Row>
            {row.workOrders[0].workOrderNo}
        </Row>
    )

    renderTooltip = (data) => {
        if (data && data.length > 0) {
            return (
                <Tooltip id="tooltip">
                    {data && data.map((service, i) => (
                        <div className="d-flex text-left bottom5">
                            <span className="mr-5">#{i + 1}</span>
                            <div className="full-width mr-30">
                                <span className="mr-5">{service.name}</span>
                                <span>({service.services_amount})</span>
                            </div>
                            <span className="notification"><Dollars amount={service.service_total}/></span>
                        </div>
                    ))}
                </Tooltip>
            );
        }
    }

    eventsFormatter = (cell, row) => (
        <OverlayTrigger placement="left" overlay={this.renderTooltip(row.services)}>
            <Badge className="proposalBadge">
                <div>
                    {row.number_of_services}
                </div>
            </Badge>
        </OverlayTrigger>
    );

    _renderInvoicesTable = data => {
        const {showFilters, customerInvoices, selectedInvoices} = this.state
        const options = {
            page: 1,  //which page you want to show as default
            sizePerPageList: [5, 10, 25], //you can change the dropdown list for size per page
            sizePerPage: 5,  //which size per page you want to locate as default
            paginationSize: 3,  //the pagination bar size.
        }

        return (
            <div>
                <Button bsStyle="success"
                        onClick={() => this.props.actions.downloadCsv(customerInvoices, selectedInvoices)}>
                    Export to CSV</Button>
                <BootstrapTable

                    data={data}
                    striped={true}
                    bordered={false}
                    hover={true}
                    // exportCSV={true}
                    pagination={true}
                    search={showFilters}
                    csvFileName={`customer-invoices-${moment().format()}.csv`}
                    selectRow={{
                        mode: "checkbox",
                        clickToSelect: false,
                        onSelect: this._onRowSelect,
                        onSelectAll: this._onSelectAll,
                    }}
                    options={options}
                >
                    <TableHeaderColumn hidden isKey dataField="id">
                        Invoice Id
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        filter={showFilters ? {type: "DateFilter"} : null}
                        dataSort={true}
                        columnClassName="menu-show-fixer wrapped_column"
                        dataFormat={dateFormatter}
                        dataField="invoiced_at"
                        width={isMobile ? '140px' : null}
                    >
                        Invoiced Date
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        filter={showFilters ? {type: "TextFilter", delay: 500} : null}
                        dataSort={true}
                        dataFormat={this.editInvoiceFormatter}
                        dataField="invoice_no"
                        width={isMobile ? '100px' : null}
                    >
                        Invoice #
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataSort={true}
                        filter={showFilters ? {type: "TextFilter", delay: 500} : null}
                        columnClassName="menu-show-fixer wrapped_column"
                        dataField="site_address"
                        width={isMobile ? '120px' : null}
                    >
                        Site Address
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataSort={true}
                        filter={showFilters ? {type: "TextFilter", delay: 500} : null}
                        columnClassName="menu-show-fixer"
                        dataField="arborist"
                        width={isMobile ? '100px' : null}
                    >
                        Arborist
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        filter={showFilters ? {type: "TextFilter", delay: 500} : null}
                        dataSort={true}
                        dataFormat={this._proposalLinkFormatter}
                        columnClassName="menu-show-fixer"
                        dataField="proposal_no"
                        width={isMobile ? '120px' : null}
                    >
                        Proposal #
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        filter={showFilters ? {type: "TextFilter", delay: 500} : null}
                        dataSort={true}
                        dataFormat={this._woLinkFormatter}
                        columnClassName="menu-show-fixer"
                        dataField="work_order_no"
                        width={isMobile ? '100px' : null}
                    >
                        WO #
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        filter={showFilters ? {type: "TextFilter", delay: 500} : null}
                        dataSort={true}
                        dataFormat={dollarsFormatter}
                        filterFormatted={false}
                        dataField="total"
                        width={isMobile ? '100px' : null}
                    >
                        Cost
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        filter={showFilters ? {type: "DateFilter"} : null}
                        dataSort={true}
                        filterFormatted={false}
                        columnClassName="menu-show-fixer wrapped_column"
                        dataFormat={dateFormatter}
                        dataField="paid_at"
                        width={isMobile ? '100px' : null}
                    >
                        Paid Date
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        filter={showFilters ? {type: "TextFilter", delay: 500} : null}
                        dataSort={true}
                        dataField="aging"
                        width={isMobile ? '100px' : null}
                    >
                        Aging
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        filter={showFilters ? {type: "TextFilter", delay: 500} : null}
                        dataSort={true}
                        dataFormat={(cell, row) => this.eventsFormatter(cell, row)}
                        dataField="services"
                        width={isMobile ? '100px' : null}
                    >
                        Services
                    </TableHeaderColumn>
                </BootstrapTable>
            </div>
        )
    }


    _dollarFormatter = cell => <Dollars amount={cell}/>

    _dateFormatter = cell => cell && moment(cell).format(defaultDateFormatShort)

    _proposalLinkFormatter = (cell, row) => (
        <Nav>
            <NavDropdown
                title={cell || "..."}
                id={`proposal-menu-${row.id}`}
                className="no-caret-in-dropdown">
                <LinkContainer to={`/mapview/${row.proposal_id}`}>
                    <MenuItem>Edit Proposal</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/proposal_service_status/${row.proposal_id}`}>
                    <MenuItem>Proposal Service Statuses</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/print_proposal/${row.proposal_id}`}>
                    <MenuItem>Print Proposal</MenuItem>
                </LinkContainer>
            </NavDropdown>
        </Nav>
    )

    _woLinkFormatter = (cell, row) => (
        <Nav>
            <NavDropdown
                title={cell || "..."}
                id={`proposal-menu-${row.id}`}
                className="no-caret-in-dropdown">
                <LinkContainer to={`/work_orders/${row.proposal_id}`}>
                    <MenuItem>Manage Work Orders</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/complete_work_orders/${row.work_order_no}`}>
                    <MenuItem>Complete Work Orders</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/print_work_orders/${row.work_order_no}`}>
                    <MenuItem>Print Work Orders</MenuItem>
                </LinkContainer>
            </NavDropdown>
        </Nav>
    )

    _stateFormatter = (cell, row) => {
        if (cell === "In Work Order") {
            return (
                <div>
                    <div>{cell}</div>
                    <Link
                        to={`/work_orders/${row.proposal_id}`}

                        className="wo-link"
                    >
                        #{row.work_order_no}
                    </Link>
                </div>
            )
        } else {
            return (<div>{cell}</div>)
        }
    }
}

const mapStateToProps = state => {
    return {
        customer: state.customerInvoices.customer,
        customerInvoices: state.customerInvoices
            .customerInvoices,
        customerSites: state.customerInvoices.customerSites,
        selectedSite: state.customerInvoices.selectedSite,
        foundCustomers: state.customerInvoices.foundCustomers,
        sitesAssets: state.customerInvoices.sitesAssets,
        selectedSiteInfo: state.customerInvoices.selectedSiteInfo &&
            state.customerInvoices.selectedSiteInfo[
                `site_${state.customerInvoices.selectedSite}`
                ],
        invoiceTypes: state.customerInvoices.invoiceTypesList,
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomerInvoices)
