import axios from "axios/index";
import {apiCall} from "../../common/apiActionHandler";
import {mapForSelect} from "../../common/commonHandlers";
import {TOGGLE_FULL_SCREEN} from "../MapViewPage/constants";

export const load = callback => (dispatch, getState) => {
    const config = [
        {url: `/api/search/all_employees`},
        {url: `/api/customer_types`},
        {url: `/api/scheduler_event_types`},
        {url: `/api/user_profile`},
        {url: `/api/reasons`}
    ];

    apiCall("Loading data", config, async config => {
        let actions = config.map(c => axios.request(c));
        const all = await axios.all(actions);
        callback({
            employees: all[0].data,
            customerTypes: mapForSelect(all[1].data),
            types: mapForSelect(all[2].data),
            user: all[3].data,
            reasons: all[4].data
        });
    }, dispatch, getState, false);
};

export const loadEvents = (params, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/scheduler_events_search`,
        params
    };
    apiCall("Loading events", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const searchForCustomer = (searchText, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/customers/name_zip_city_search`,
        params: {q: searchText}
    };

    apiCall("Searching for customers", config, async config => {
        const response = await axios.request(config);
        callback(mapForSelect(response.data));
    }, dispatch, getState, false);
};

export const markAsCompleted = (resource, value, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/scheduler_events/${resource.id}/toggle_completed`,
        method: 'PUT'
    };
    apiCall(value ? "Changed to completed" : "Changed not to completed", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};


export const markAsConfirmed = (resource, value, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/scheduler_events/${resource.id}/toggle_confirmed`,
        method: 'PUT'
    };
    apiCall(value ? "Changed to confirmed" : "Changed not to confirmed", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};

export const markAsOnSite = (resource, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/scheduler_events/${resource.id}/toggle_on_site`,
        method: 'PUT'
    };
    apiCall("Saving on site", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};

export const toggleFullScreen = (isFullScreen) => (dispatch) => {
    dispatch({
        type: TOGGLE_FULL_SCREEN,
        fullScreen: isFullScreen
    })
}

export const updateShowMapOnTasksPage = (userId, value, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/users/${userId}/show_map_on_tasks_page`,
        data: {
            show_map_on_tasks_page: value
        },
        method: 'PUT'
    };

    apiCall("Update show map option", config, async config => {
        const result = await axios.request(config);
        callback(result.data);
    }, dispatch, getState, false);
};

export const updateTasksSettings = (columns_settings, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/users/update_tasks_settings`,
        data: {
            columns_settings
        },
        method: 'PUT'
    };

    apiCall("Update tasks settings", config, async config => {
        const result = await axios.request(config);
        callback(result.data);
    }, dispatch, getState, false);
};
