import React, {useEffect, useState} from 'react';
import {faClipboardList, faCreditCard, faListAlt, faMapMarkedAlt} from "@fortawesome/free-solid-svg-icons";
import {Glyphicon, Tab, Tabs} from "react-bootstrap";
import './CustomerViewTabs.scss'
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {logout} from '../LoginPage/actions'
import * as Actions from './actions'
import {browserHistory} from "react-router";

const actions = {...Actions, logout};

const isMobile = window.screen.width < 1024
const CustomerViewTabs = ({token, site, actions, isCustomerAuthenticated}) => {

    const link = window.location.href.split('/').pop()
    const [key, setKey] = useState(`/customer_view/${token}/${link}`)

    useEffect(() => {
        if (site.loaded) return
        actions.setSelectedSite(null, token)
    }, [])

    const menuItems = [
        {label: 'Sites', icon: faClipboardList},
        {label: 'Proposals', icon: faListAlt},
        {label: 'Invoices', icon: faMapMarkedAlt},
        {label: 'Messages', icon: faCreditCard}
    ]

    const handleTabSelect = (key) => {
        setKey(key)
        browserHistory.push(key)
    }

    return (
        <>
            {!isMobile && site.loaded &&
                <Tabs activeKey={key}
                      mountOnEnter={true}
                      animation={false}
                      onSelect={handleTabSelect}
                      id='customer-view-tabs'
                >
                    {menuItems.map(({label, icon}, i) => {
                        if (label) {
                            return (
                                <Tab eventKey={`/customer_view/${token}/${label.replace(/ /g, "_").toLowerCase()}`}
                                     title={label}>
                                </Tab>
                            )
                        }
                    })}
                    {isCustomerAuthenticated && <Tab title={<>Logout <Glyphicon glyph='log-out'/></>}
                                                     eventKey={() => actions.logout('/customer_view/logout')}/>}
                </Tabs>
            }
        </>
    );
};


const mapStateToProps = state => {
    return {
        site: state.customerView.site,
        proposal: state.customerView.proposal,
        isCustomerAuthenticated: state.auth.isCustomerAuthenticated
    }
}

const mapDispatchToProps = dispatch => {
    return {actions: bindActionCreators(actions, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerViewTabs);