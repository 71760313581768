import {GoogleMap, InfoWindow, Marker} from "react-google-maps";
import {GoogleMapLoader} from "../../components/GoogleMapLoader";
import React, {PureComponent} from "react";
import {defaultDateTimeFormat, defaultMapParams, fitBounds} from "../../common/commonHandlers";
import {Col, Row} from "react-bootstrap";
import {Link} from "react-router";
import moment from "moment-timezone";
import MarkerClusterer from "react-google-maps/lib/components/addons/MarkerClusterer";
import {getBasicMarker} from "../../utilities";

const isMobile = window.screen.width <= 1024;

class Map extends PureComponent {
    state = {
        center: true,
        proposal: [],
        resource: {
            inBounds: false
        },
        isMapLoaded: false
    };

    constructor(props, context) {
        super(props, context);
    }

    componentDidMount() {
        this.reloadMapParams()
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.data && this.props.data.length > 0 && !this.state.isMapLoaded || this.props.data && this.props.data.length !== nextProps.data.length) {
            this.reloadMapParams()
        }
    }

    reloadMapParams = (callback) => {
        const {
            data,
            client,
            map,
            mapData
        } = this.props;
        let isMapLoaded = false
        let coordinates = [];
        const {center} = this.state;
        const mapParams = defaultMapParams(mapData, center, null, client);
        if (client && data && data.length === 0) {
            mapParams.center = {lat: client.organization_latitude, lng: client.organization_longitude};
            mapParams.zoom = 18;
        } else {
            mapData.forEach(asset => {
                asset.lat = asset.latitude
                asset.lng = asset.longitude
                coordinates.push({latitude: asset.lat, longitude: asset.lng});
            })
            fitBounds(this.map, coordinates, () => {
                isMapLoaded = true
            });
        }
        this.setState({mapParams, isMapLoaded}, callback);

    };

    getGoogleClusterInlineSvg = (color) => {
        const encoded = window.btoa('<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-100 -100 200 200"><defs><g id="a" transform="rotate(45)"><path d="M0 47A47 47 0 0 0 47 0L62 0A62 62 0 0 1 0 62Z" fill-opacity="0.7"/><path d="M0 67A67 67 0 0 0 67 0L81 0A81 81 0 0 1 0 81Z" fill-opacity="0.5"/><path d="M0 86A86 86 0 0 0 86 0L100 0A100 100 0 0 1 0 100Z" fill-opacity="0.3"/></g></defs><g fill="' + color + '"><circle r="42"/><use xlink:href="#a"/><g transform="rotate(120)"><use xlink:href="#a"/></g><g transform="rotate(240)"><use xlink:href="#a"/></g></g></svg>');

        return ('data:image/svg+xml;base64,' + encoded);
    };

    render() {
        const {
            selectMarker,
            closeInfoWindow,
            handleSelectMarker,
            map,
            selectedMarkers,
            selectedProposal,
            data,
            handleMap,
            refGoogleMap,
            mapData,
            handleData,
            navbarHeight,
            minimumClusterSize,
            allSelected,
            client
        } = this.props;
        const {center, isMapLoaded, mapParams} = this.state;
        const defaultMapOptions = {
            disableDefaultUI: true,
        };

        if (center) {
            this.setState({center: false});
        }

        const cluster_styles = [
            {
                width: 40,
                height: 40,
                url: this.getGoogleClusterInlineSvg('rgb(61, 183, 255)'),
                textColor: 'black',
                textSize: 12
            }
        ];


        return (
            <GoogleMapLoader
                containerElement={
                    <div
                        {...this.props}
                        style={{
                            height: `calc(100vh - ${navbarHeight}px)`,
                        }}
                    />
                }
                googleMapElement={
                    <GoogleMap
                        ref={it => {
                            this.map = it
                            refGoogleMap(it, mapData.filter(param => param.latitude), (res) => {
                                handleMap(it)
                            })
                        }}
                        options={defaultMapOptions}
                        onIdle={() => {
                            this.props.delayedInBounds()
                        }}
                        {...mapParams}
                    >
                        {this.map && this.map.getBounds() &&
                            <MarkerClusterer
                                averageCenter={true}
                                maxZoom={18}
                                minimumClusterSize={minimumClusterSize}
                                styles={cluster_styles}
                            >
                                {
                                    data.map((item, index) => {
                                        return (
                                            <Marker
                                                key={index}
                                                position={{lat: item.latitude, lng: item.longitude}}
                                                icon={getBasicMarker({
                                                    selected: false,
                                                    color: selectedMarkers && selectedMarkers.includes(item.proposalId) || item.selected ? '#f8e500' : item.color,
                                                    label: (data.indexOf(item) + 1).toString()
                                                })}
                                                onClick={() => {
                                                    handleData("selectedProposal", item.proposalId);
                                                    selectMarker('data')(item);
                                                }}
                                                zIndex={item.selected ? 10000 : null}
                                            >
                                                {selectedProposal === item.proposalId &&
                                                    <InfoWindow
                                                        onCloseclick={closeInfoWindow}
                                                    >
                                                        <div
                                                            style={{color: "black", lineHeight: "18px"}}>
                                                            <Row style={{margin: "0 20px 0 20px"}}>
                                                                <Col xs={12} style={{
                                                                    paddingRight: "0px",
                                                                    textAlign: "center",
                                                                    paddingLeft: "0px",
                                                                    color: "rgb(61, 183, 255)"
                                                                }}><strong>{item.site}</strong>
                                                                </Col>
                                                            </Row>
                                                            <div>
                                                                {
                                                                    data.filter(e => e.site === item.site).map((p, idx) => {
                                                                        let events = [];
                                                                        if (p.events && p.events.length > 0) events = events.concat(p.events);
                                                                        return (
                                                                            <div key={idx}>
                                                                                <hr style={{margin: "2px 0px 2px 0px"}}/>
                                                                                <Row style={{
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    margin: "0 20px 0 20px"
                                                                                }}>
                                                                                    <Col style={{
                                                                                        paddingRight: "0px",
                                                                                        paddingLeft: "0px",
                                                                                        fontSize: "10px"
                                                                                    }} className="text-right" xs={4}>
                                                                                        Proposal:
                                                                                    </Col>
                                                                                    <Col xs={8}>
                                                                                        <a href={`/mapview/${p.proposalId}`}
                                                                                           target="_blank"><Link><strong>#{p.proposalNo}</strong></Link></a>
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row style={{
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    margin: "0 20px 0 20px"
                                                                                }}>
                                                                                    <Col style={{
                                                                                        paddingRight: "0px",
                                                                                        paddingLeft: "0px",
                                                                                        fontSize: "10px"
                                                                                    }} className="text-right"
                                                                                         xs={4}>Arborist: </Col>
                                                                                    <Col
                                                                                        style={{color: "rgb(121, 196, 79)"}}
                                                                                        xs={8}><strong>{p.arborist}</strong></Col>
                                                                                </Row>
                                                                                {p.lead && p.lead.notes &&
                                                                                    <Row style={{
                                                                                        display: "flex",
                                                                                        alignItems: "center",
                                                                                        margin: "0 20px 0 20px"
                                                                                    }}>
                                                                                        <Col style={{
                                                                                            paddingRight: "0px",
                                                                                            paddingLeft: "0px",
                                                                                            fontSize: "10px"
                                                                                        }} className="text-right"
                                                                                             xs={4}>Lead: </Col>
                                                                                        <Col
                                                                                            xs={8}><strong>{p.lead.notes}</strong></Col>
                                                                                    </Row>
                                                                                }
                                                                                {p.work_orders_no.length > 0 &&
                                                                                    <Row style={{
                                                                                        display: "flex",
                                                                                        alignItems: "center",
                                                                                        margin: "0 20px 0 20px"
                                                                                    }}>
                                                                                        <Col style={{
                                                                                            paddingRight: "0px",
                                                                                            paddingLeft: "0px",
                                                                                            fontSize: "10px"
                                                                                        }} className="text-right"
                                                                                             xs={4}>Work
                                                                                            Orders: </Col>
                                                                                        <Col xs={8}>
                                                                                            {p.work_orders_no.map((work, index) => {
                                                                                                if (work.events.length > 0) {
                                                                                                    events = events.concat(work.events);
                                                                                                }
                                                                                                return (
                                                                                                    <a href={`/work_orders/${p.proposalId}`}
                                                                                                       target="_blank"><Link><strong>{index > 0 && index < p.work_orders_no.length ? ", " : ""}#{work.number}</strong></Link></a>
                                                                                                );
                                                                                            })
                                                                                            }
                                                                                        </Col>
                                                                                    </Row>
                                                                                }
                                                                                {p.invoices_no.length > 0 &&
                                                                                    <Row style={{
                                                                                        display: "flex",
                                                                                        alignItems: "center",
                                                                                        margin: "0 20px 0 20px"
                                                                                    }}>
                                                                                        <Col style={{
                                                                                            paddingRight: "0px",
                                                                                            paddingLeft: "0px",
                                                                                            fontSize: "10px"
                                                                                        }} className="text-right"
                                                                                             xs={4}>Invoices: </Col>
                                                                                        <Col xs={8}>
                                                                                            {p.invoices_no.map((inv, index) => {
                                                                                                return (
                                                                                                    index > 0 && index < p.invoices_no.length ?
                                                                                                        <a href={`/proposals/${p.proposalId}/invoices`}
                                                                                                           target="_blank"><Link><strong>, {inv.number}</strong></Link></a> :
                                                                                                        <a href={`/proposals/${p.proposalId}/invoices`}
                                                                                                           target="_blank"><Link><strong>{inv.number}</strong></Link></a>
                                                                                                );
                                                                                            })
                                                                                            }
                                                                                        </Col>
                                                                                    </Row>
                                                                                }
                                                                                {events.length > 0 &&
                                                                                    events.map((event, index) => {
                                                                                        return (
                                                                                            <Row style={{
                                                                                                display: "flex",
                                                                                                alignItems: "center",
                                                                                                margin: "0 20px 0 20px"
                                                                                            }}>
                                                                                                <Col style={{
                                                                                                    paddingRight: "0px",
                                                                                                    paddingLeft: "0px",
                                                                                                    fontSize: "10px"
                                                                                                }}
                                                                                                     className="text-right"
                                                                                                     xs={4}>{index === 0 ? "Scheduler Events: " : ""}</Col>
                                                                                                <Col xs={8}>
                                                                                                    <strong
                                                                                                        style={{whiteSpace: "nowrap"}}>{moment(event.date_from).format(defaultDateTimeFormat)}<span
                                                                                                        style={{fontSize: "10px"}}> ({event.name})</span></strong>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        );
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        );
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </InfoWindow>
                                                }
                                            </Marker>
                                        )
                                    })
                                }
                            </MarkerClusterer>}
                    </GoogleMap>
                }
            />
        );
    }
}

export default Map;
