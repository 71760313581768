import React from 'react';
import {Button, Col, Modal, Row} from "react-bootstrap";

export const OrdinanceAndLawsModal = ({show, onConfirm, onHide, onRemind, ordinanceLawsNames, id}) => {

    return (
        <Modal
            animation={false}
            show={show}
            onHide={onHide}
            backdrop="static"
            className="heightAuto"
        >
            <Modal.Header closeButton>
                {id ?
                    <Modal.Title> Ordinance confirmation</Modal.Title> :
                    <Modal.Title>Ordinance reminder</Modal.Title>
                }
            </Modal.Header>
            {id ?
                <Modal.Body className="margin10">Is all data up to date? Confirm or click the “Remind me later” button
                    if you want to be
                    reminded again in 3 days.</Modal.Body> :
                <Modal.Body className="margin10">
                    The deadline for updating the following Ordinances is approaching:
                    {ordinanceLawsNames?.map((ol) =>
                        <Row className="margin10">• {ol}</Row>)
                    }
                    Please recheck them and confirm by clicking Save.
                </Modal.Body>
            }
            <Modal.Footer>
                <Row>
                    {id ?
                        <Col md={12}>
                            <Button type="button" onClick={onRemind}
                                    data-dismiss="modal">REMIND ME LATER
                            </Button>
                            <Button type="button" onClick={onConfirm} bsStyle="success">CONFIRM</Button>
                        </Col> :
                        <Col md={12} className=''>
                            <Button type="button" onClick={onHide}
                                    data-dismiss="modal">CLOSE
                            </Button>
                        </Col>}
                </Row>
            </Modal.Footer>}
        </Modal>
    )
}
