import React, {Component} from 'react';
import {FormGroup, Modal} from "react-bootstrap";
import Select from "react-select";
import * as actions from './actions'
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import TextareaAutosize from "react-textarea-autosize";

class NewMessageModal extends Component {

    state = {
        proposals: [],
        proposal: null,
        message: ''
    }


    getProposals = () => {
        this.props.actions.loadProposals(this.props.token, true, this.props.site?.selected?.id, res => {
            this.setState({proposals: res.customer_proposals, loading: false})
        })
    }

    componentDidMount() {
        if (this.props.token && this.props.showModal) {
            this.getProposals()
        } else {
            //should never get here because router should not mount this page and instead 404
            this.props.actions.addAlert({
                message: "Proposal not given. I can't go on!",
            })
        }
    }

    sendMessage = () => {
        if (this.state.proposal) {
            this.props.actions.addNote(this.state.proposal.token, this.state.message, () => {
                this.props.reload()
                this.props.closeModal()
            })
        }
    }


    mapForSelect(data, propName) {
        return data.map(x => {
            return {value: x.token, label: x[`${propName}`]}
        });
    }

    handleSelect = (e) => {
        this.setState({proposal: this.state.proposals.find(p => p.token === e.value)})
    }


    render() {
        const {showModal, closeModal, request} = this.props
        const {message, proposal, proposals} = this.state

        const proposalOptions = this.mapForSelect(proposals, 'proposal_no');

        return (
            <Modal
                id='request-modal'
                animation={true}
                show={showModal}
                className={"heightAuto"}
                bsSize="normal"
            >
                <Modal.Header>
                    <Modal.Title>New Message</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormGroup>
                        <b>Proposal*</b>
                        <Select className="Select"
                                classNamePrefix="select"
                                options={proposalOptions}
                                onChange={this.handleSelect}
                                placeholder={'Proposal'}
                                isClearable
                        />
                    </FormGroup>
                    <FormGroup>
                        <b>Message</b>
                        <TextareaAutosize
                            name={`reply_area`}
                            value={message}
                            minRows={3}
                            maxRows={10}
                            placeholder="Your note"
                            className="sticky-textarea align-bottom"
                            onChange={(e) => this.setState({message: e.target.value})}
                        />
                    </FormGroup>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={closeModal} type="button" className="btn btn-default"
                            data-dismiss="modal">Close
                    </button>
                    <button
                        type="button"
                        className="btn btn-success"
                        onClick={() => this.sendMessage()}>
                        Save
                    </button>
                </Modal.Footer>
            </Modal>
        )
            ;
    }
}

const mapStateToProps = state => {
    return {
        client: state.client.customerInfo,
        proposal: state.customerView.proposal,
        site: state.customerView.site
    }
}


const mapDispatchToProps = dispatch => {
    return {actions: bindActionCreators(actions, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(NewMessageModal)
