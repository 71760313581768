import {ADD_ALERT, CLEAR_ALERT, CLEAR_ALL_ALERTS, IS_PROCESSING,} from './constants'

const initialState = {
    alertMessages: [],
    isProcessing: 0,
}

const removeItemById = (myArray, id) => myArray.filter(i => i.id !== id)

const change = (count, isProcessing) => {
    count = isProcessing ? count + 1 : count - 1
    count = count < 0 ? 0 : count
    return count
}

export function appReducer(state = initialState, action) {
    switch (action.type) {

        case ADD_ALERT:
            return {...state, alertMessages: state.alertMessages.concat(action.alertMessage)}
        case CLEAR_ALERT:
            return {...state, alertMessages: removeItemById(state.alertMessages, action.id)}
        case CLEAR_ALL_ALERTS:
            return {...state, alertMessages: []}
        case IS_PROCESSING:
            return {...state, isProcessing: change(state.isProcessing, action.isProcessing)}
        default:
            return state
    }
}
