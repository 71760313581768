import React from 'react';
import PropTypes from "prop-types"
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Stickies from "../Stickies/Stickies";
import {Checkbox, Col, ControlLabel, FormGroup, Grid, Row} from "react-bootstrap";
import {getUserStatus} from "../AppWrapper/contents/actions"
import * as notificationsActions from "./NotificationsApi"
import Select from "react-select"
import Secured from "../../common/Secured";
import Notifications from "./Notifications";
import {showEmailDialog} from "../../components/email/actions"

const actions = {...notificationsActions, getUserStatus, showEmailDialog};

class FollowUps extends Notifications {
    searchRef = React.createRef();
    state = {
        stickies: [],
        showDisabledUsers: false,
        users: [],
        searchResult: null,
        searchResults: [],
        isLoading: false,
        unreadOnly: false
    };

    getReachedFollowUpsCount = stickies => {
        let afterFollowUp = stickies?.length > 0 && stickies.filter(s => !s.complete).length;
        let result = Math.min(afterFollowUp, this.props.count);
        result += result === this.props.count ? '+' : '';
        return result;
    };

    componentWillMount() {
        if (this.props && this.props.searchResult) {
            this.setState({searchResult: this.props.searchResult})
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {showDisabledUsers} = this.state;

        if (prevState.showDisabledUsers !== showDisabledUsers) {
            this.props.actions.saveShowDisabledUsers(showDisabledUsers, ({show_disabled_users}) => {
                this.props.actions.getActiveEmployees(show_disabled_users, employees => {
                    this.setState({employees})
                })
            })
        }

        if (prevProps.reloadDate !== this.props.reloadDate) {
            this.loadStickies()
        }
    }

    render() {
        const {
            stickies,
            searchResult,
            searchResults,
            employee,
            employee_arborist,
            showDisabledUsers,
            isLoading
        } = this.state;
        const {type, isProposalView, proposalId, proposal, auth} = this.props;
        const {user_id} = this.props.auth;

        const employees = this.setActiveEmployees(auth?.employee_id)

        return (
            <Stickies stickies={stickies}
                      proposalId={proposalId}
                      proposal={proposal}
                      isProposalView={isProposalView}
                      replyButtonLabel="Add follow up"
                      save={
                          (sticky, data, callback) => {
                              data.follow_up_date && data.follow_up_date !== '' && this.props.actions.addNote(sticky, data, () => {
                                  this.loadStickies();
                                  this.props.actions.getUserStatus(user_id);
                                  callback();
                              });
                          }}
                      remove={!isProposalView ? sticky => this.handleRemove(sticky, user_id) : null}
                      hide={sticky => this.props.actions.hideNote(sticky, () => {
                          this.loadStickies();
                          this.props.actions.getUserStatus(user_id);
                      })}
                      read={sticky => this.props.actions.readNote(sticky, () => {
                          this.loadStickies();
                          this.props.actions.getUserStatus(user_id);
                      })}
                      notify={sticky => {
                          this.setState({sticky}, this.showEmailDialog);
                      }}
                      complete={sticky => this.props.actions.complete(sticky, () => {
                          this.loadStickies();
                          this.props.actions.getUserStatus(user_id);
                      })}
                      withFollowUp
                      type={type}
                      stickable={searchResult}
                      searchResult={searchResult}
            >
                {!isProposalView && <div>
                    <Grid fluid>
                        <Row>
                            <Col md={12}>
                                <p className="margin10">
                                    {`You have ${this.getReachedFollowUpsCount(stickies)} follow ups reached`}
                                </p>
                            </Col>
                            <Secured roles="admin">
                                <Col md={12}>
                                    <form className="form-inline">
                                        <FormGroup>
                                            <ControlLabel>Created by</ControlLabel>
                                            <Select className="Select" classNamePrefix="select"
                                                    value={employee}
                                                    options={employees}
                                                    isClearable
                                                    onChange={this.handleEmployeeSelect}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <ControlLabel>Arborist</ControlLabel>
                                            <Select className="Select" classNamePrefix="select"
                                                    value={employee_arborist}
                                                    options={employees}
                                                    isClearable
                                                    onChange={this.handleEmployeeArboristSelect}
                                            />
                                        </FormGroup>
                                    </form>
                                </Col>
                            </Secured>
                            <Col md={12}>
                                <form className="form-inline">
                                    <FormGroup>
                                        <ControlLabel>Search</ControlLabel>
                                        <Select className="Select" classNamePrefix="select"
                                                ref={this.searchRef}
                                                value={searchResult}
                                                options={searchResults}
                                                key={searchResults ? searchResults.length : 0}
                                                openMenuOnFocus={searchResults?.length > 0 && !searchResult}
                                                isClearable
                                                isDisabled={isLoading}
                                                isLoading={isLoading}
                                                onInputChange={e => this.handleSearchInputChange(e, {
                                                    type: 'follow ups',
                                                    employee_id: employee?.value,
                                                    arborist_id: employee_arborist?.value
                                                })}
                                                onChange={this.handleSearchResultSelect}
                                        />
                                    </FormGroup>
                                </form>
                            </Col>
                            <form className="form-inline">
                                <Col md={3}/>
                                <Col md={9}>
                                    <FormGroup className='d-flex'>
                                    <span className="vertical-align">
                                        <ControlLabel className='full-width'>Disabled users</ControlLabel>
                                        <Checkbox
                                            name="showDisabledUsers"
                                            className='no-right-padding no-left-padding'
                                            onChange={e => {
                                                this.setState({showDisabledUsers: e.target.checked})
                                            }}
                                            checked={showDisabledUsers}
                                        />
                                        </span>
                                    </FormGroup>
                                </Col>
                            </form>
                        </Row>
                    </Grid>
                </div>}
            </Stickies>
        );
    }
}

FollowUps.propTypes = {
    count: PropTypes.number.isRequired,
    type: PropTypes.string,
    users: PropTypes.array
};

FollowUps.defaultProps = {
    count: 10,
    type: 'messages'
};


function mapStateToProps(state) {
    return {
        auth: state.auth,
        userStatus: state.userStatus.userStatus,
        docks: state.docks,
        users: state.users.users,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FollowUps);
