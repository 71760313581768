import React from "react";
import {Button, Modal} from "react-bootstrap";

const InactiveModal = ({onHide, onSave, inactiveCustomer, inactiveSite}) => {
    return <Modal show={true}
                  onHide={onHide}
                  static
                  className={"heightAuto fontIOS"}>
        <Modal.Header closeButton>
            <Modal.Title>
                Inactive
                {inactiveCustomer ? " Customer" : ""}
                {inactiveCustomer && inactiveSite ? " and" : ""}
                {inactiveSite ? " Site" : ""}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <h4 className="text-center">
                If you continue, the
                {inactiveCustomer ? " customer " : ""}
                {inactiveCustomer && inactiveSite ? "and" : ""}
                {inactiveSite ? " site " : ""}
                will be reactivated
            </h4>
        </Modal.Body>
        <Modal.Footer>
            <Button
                bsStyle='warning'
                bsSize="small"
                onClick={onHide}
            >
                Cancel
            </Button>
            <Button
                bsStyle='success'
                bsSize="small"
                onClick={(e) => {
                    onSave(e);
                    onHide()
                }}
            >
                Continue
            </Button>

        </Modal.Footer>
    </Modal>
}

export default InactiveModal;