import React from "react"
import {bindActionCreators} from "redux";
import * as usersActions from "./UsersListApi";
import {connect} from "react-redux";
import {
    Button,
    Checkbox,
    Col,
    ControlLabel,
    FormControl,
    FormGroup,
    Modal,
    Nav,
    NavItem,
    OverlayTrigger,
    Row,
    Tooltip
} from "react-bootstrap";
import SplitPane from "react-split-pane";
import Select from "react-select";
import {
    defaultMapParams,
    isEmailValid,
    reverseColor,
    rolesDescriptions,
    select
} from "../../../../common/commonHandlers";
import Secured from "../../../../common/Secured";
import {dateFormatter} from "../../../../components/BootStrapTableCellFormatters";
import {GoogleMap, Marker} from "react-google-maps";
import {GoogleMapLoader} from "../../../../components/GoogleMapLoader";
import ResourceComponent from "../../../../components/ResourceComponent";
import Dropzone from "react-dropzone";
import Attachment from "../../../../components/image/Attachment";
import personPlaceholder from './person-placeholder.png';
import './UsersList.css';
import DevicesModal from "./DevicesModal";
import {logout} from "../../../LoginPage/actions"
import ActivitiesModal from "./ActivitiesModal";
import {getColorCircleIcon} from "../../../MapViewPage/IconProvider";
import {debounce} from "throttle-debounce";
import Dollars from "../../../../components/Dollars";

const actions = {...usersActions, logout};

class UsersList extends ResourceComponent {

    constructor(props) {
        super(props);

        this.state = {
            employees: [],
            selectAll: true,
            showDisabled: false,
            resource: {},
            center: true,
            showActivitiesModal: false,
            activeUsers: 0,
            availableSubscription: 0,
            permanent_subscription: false,
            showSubscriptionModal: false,
            subscription: {},
            previousUserState: true,
            userPrice: 0,
            maxUserCount: 0,
            error: '',
            employeesForSelect: [],
            emailError: null,
        }
    }

    componentDidMount() {
        this.props.actions.load(result => {
            this.setState(result);
            this.reload();
        });
        this.props.actions.getStripeSettings(res => {
            this.setState({maxUserCount: res.max_user_count})
        })
        this.props.actions.loadStripeCredentials(res => {
            this.setState({permanent_subscription: res.permanent_subscription});
            this.setState({subscription: res.stripe_subscription});

            !res.permanent_subscription && this.props.actions.getClientPrice(res.stripe_subscription.stripe_price, price => {
                this.setState({userPrice: price.next})
            })

        });
        this.timer = setInterval(this.getLocations, +this.props.interval * 1000);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    getLocations = () => {
        const {resource} = this.state;
        this.props.actions.loadUsers(resource, result => {
            this.setState({users: result});
            this.setState({users: result});
        });
    }
    deleteUserSubscriptionUpdate = () => {
        if (this.state.permanent_subscription) {
            this.reload(this.state.user.id)
        } else {
            let newSub = this.state.subscription
            newSub = {...newSub, user_count: newSub.user_count - 1}
            this.props.actions.updateSubscription(newSub, res => {
                if (res) {
                    this.reload(this.state.user.id)
                }
            })
        }

    }

    reload = () => {
        const {user, resource, selectAll} = this.state;
        this.props.actions.getActiveUsersCount(res => {
            this.setState({activeUsers: res.active_users_count});
        });
        this.props.actions.loadStripeCredentials(res => {
            this.setState({availableSubscription: res.stripe_subscription.user_count});
            this.setState({subscription: res.stripe_subscription});
        });
        this.props.actions.loadUsers(resource, result => {
            this.setState({users: result, loaded: false});
            selectAll ? this.handleSelectAll(true)
                : user ? this.setState({
                        loaded: false,
                        user: result.find(u => u.id === user.id),
                        previousUserState: result.find(u => u.id === user.id)?.disabled
                    }, this.loadDevices)
                    : this.setState({loaded: false})
        });
    };

    reloadUser = (user_id) => {
        const {user, selectAll} = this.state;
        if (!user_id) {
            user_id = this.state.user.id
        }
        this.props.actions.loadUser(user?.id || user_id, result => {
            let users = this.state.users.map(obj => {
                if (obj.id === (user?.id || user_id)) {
                    return result;
                }
                return obj;
            })
            this.setState({users: users, loaded: false});
            selectAll ? this.handleSelectAll(true)
                : user ? this.setState({
                        loaded: false,
                        user: result,
                        previousUserState: result?.disabled
                    }, this.loadDevices)
                    : this.setState({loaded: false})
        });
    };

    handleUserSelect = (e) => {
        e.password_confirmation = '';
        e.password = '';
        e.disabled = false
        this.props.actions.loadNotSelectedEmployee(res => {
            const {user} = this.state
            this.setState({
                employeesForSelect: user?.id ? res.concat({
                    label: `${user?.first_name} ${user?.last_name}`,
                    value: user?.person_id
                }) : res
            })
        })
        this.setState(
            {
                user: Object.assign({}, e),
                selectAll: false,
                loaded: false
            }, () => {
                if (e.id === null) {
                    this.setState({activities: []})
                } else {
                    this.reloadUser(e.id)
                }
            }
        )
    };

    _updateUserAttr = e => {
        this.state.user[e.target.name] = e.target.value;
        this.setState({user: this.state.user});
    };

    _handleSelectedRole = roleId => e => {
        if (e.target.checked) {
            this.state.user.roles.push(roleId)
        } else {
            this.state.user.roles = this.state.user.roles.filter(r => r !== roleId)
        }
        this.setState({user: this.state.user});
    };

    _updateSelectedEmployee = selected => {
        this.state.user.person_id = selected.value;
        this.setState({user: this.state.user})
    };

    validateEmail = value => {
        const {emailError} = this.state;
        const isValid = !!value && !!value.trim().length && isEmailValid(value);
        const errorChanged = !!isValid !== !emailError;
        if (errorChanged) {
            this.setState({emailError: isValid ? null : "Invalid email address"})
        }
    }

    _saveUser = async e => {
        e.preventDefault();

        if (this.state.maxUserCount <= this.state.activeUsers && !this.state.permanent_subscription) {
            this.setState({error: 'Max number of user is used.'})
        } else if (!this.state.emailError) {
            if (this.state.user.id) {
                if (this.state.previousUserState && !this.state.user.disabled && this.state.activeUsers >= this.state.availableSubscription && !this.state.permanent_subscription) {
                    this.showSubscriptionModal()
                } else {
                    if (this.state.user.disabled) {
                        this.props.actions.saveUserInfo(this.state.user, res => {
                            if (res) {
                                let newSub = this.state.subscription
                                newSub = {...newSub, user_count: newSub.user_count - 1}
                                this.props.actions.updateSubscription(newSub, res => {
                                    if (res) {
                                        this.reload()
                                    }
                                })
                            }
                        })
                    } else {
                        this.props.actions.saveUserInfo(this.state.user, (res) => {
                            if (this.props.auth?.user_id === this.state.user.id) {
                                const newRoles = this.props.roles.filter(r => res.roles.includes(r.id)).map(r => r.role_name)
                                this.props.actions.updateRolesInState(newRoles)
                            }
                            this.reloadUser()
                        })
                    }
                }
            } else {
                if (!this.state.user.disabled && this.state.activeUsers >= this.state.availableSubscription && !this.state.permanent_subscription) {
                    this.showSubscriptionModal()
                } else {
                    this.props.actions.saveUserInfo(this.state.user, async e => {
                        this.props.actions.loadUsers(this.state.resource, result => {
                            this.setState({
                                users: result,
                                loaded: false,
                                employeesForSelect: this.state.employeesForSelect.concat(result.filter(r => r.id === e.id).map(u => {
                                    return {label: `${u?.first_name} ${u?.last_name}`, value: u?.person_id}
                                })[0])
                            })
                        });
                        this.reloadUser(e.id)
                    })
                }
            }
        }
    };

    _checkUserAttr = e => {
        this.state.user[e.target.name] = e.target.checked;
        this.setState({user: this.state.user});
    };

    handleSelectAll = e => {
        const {resource} = this.state;
        this.setState({selectAll: e === true ? e : e.target.checked}, () => {
            if (this.state.selectAll) {
                const elements = document.querySelectorAll('.nav-stacked li a');
                elements.forEach((e, i) => {
                    const employee = select(this.state.employees, +elements[i].id.split('_')[1]);
                    const backgroundColor = employee ? employee.color : '#3a87ad';
                    elements[i].style.backgroundColor = backgroundColor;
                    elements[i].style.color = reverseColor(backgroundColor)
                });
                this.setState({user: null, loaded: false})
            } else {
                const elements = document.querySelectorAll('.nav-stacked li a');
                elements.forEach((e, i) => {
                    elements[i].style.backgroundColor = 'transparent';
                    elements[i].style.color = '#d9230f';
                    elements[i].style.border = 'none'
                });
                this.setState({user: null, loaded: false}, this.reload);
            }
        })
    };

    handleShowDisabled = e => {
        this.setState({showDisabled: e.target.checked})
        if (this.state.selectAll) {
            this.handleSelectAll(true)
        }
    }

    int2ip = (ipInt) => {
        return ((ipInt >>> 24) + '.' + (ipInt >> 16 & 255) + '.' + (ipInt >> 8 & 255) + '.' + (ipInt & 255));
    };

    getUserColor = id => {
        const employee = select(this.state.employees, id);
        const backgroundColor = employee ? employee.color : '#3a87ad';
        const elements = document.querySelectorAll('.nav-stacked li a');
        elements.forEach((e, i) => {
            elements[i].style.backgroundColor = 'transparent';
            elements[i].style.border = 'none'
            elements[i].style.color = '#d9230f'
        });
        const element = document.getElementById(`person_${id}`);
        element.style.backgroundImage = 'none';
        element.style.backgroundColor = backgroundColor;
        element.style.color = reverseColor(backgroundColor)
    };

    loadDevices = () => {
        const {user} = this.state;
        if (user && user.id) {
            this.props.actions.loadDevices(user.id, (result) => {
                this.setState({
                    devices: result
                })
            })
        }
    };

    showSubscriptionModal = () => {
        this.setState({showSubscriptionModal: true});
    };
    closeSubscriptionModal = () => {
        this.setState({showSubscriptionModal: false});
    };
    showModal = () => {
        this.setState({showModal: true});
    };

    showActivitiesModal = () => {
        this.setState({showActivitiesModal: true});
    };

    closeModal = () => {
        this.setState({showModal: false}, this.loadDevices);
    };

    closeActivitiesModal = () => {
        this.setState({showActivitiesModal: false});
    };

    deleteDevice = (device) => {
        this.props.actions.deleteDevice(device, () => {
            if (navigator.userAgent === device.device) {
                this.props.actions.logout();
            }
            this.loadDevices();
            this.reloadUser();
        });
    };

    renderUserForm = () => {
        const {employees, roles, client} = this.props;
        const {user, selectAll, resource, users, devices, center, employeesForSelect, emailError} = this.state;
        const employeesOptions = employeesForSelect
        return (<form onSubmit={e => {
            this._saveUser(e)
        }}>
            <Row>
                {!selectAll && user && <div>
                    <Col xs={12}>
                        <Select className="Select" classNamePrefix="select"
                                placeholder="Employee"
                                simpleValue={true}
                                value={select(employeesOptions, user.person_id)}
                                onChange={this._updateSelectedEmployee}
                                options={employeesOptions}
                        />
                    </Col>
                    <hr/>
                    <Col xs={12}>
                        <FormGroup>
                            <ControlLabel>
                                Image
                            </ControlLabel>
                            <Dropzone
                                onDrop={file => this.props.actions.uploadImages(file, user.id, img => {
                                    img.primary = false
                                    user.images.push(img);
                                    if (this.props.actions.validateUser(this.state.user)) {
                                        this.props.actions.saveUserInfo(this.state.user, this.reloadUser)
                                    }
                                })}
                                className="dropzone pointer small">
                                <p>Drop a file here or click to select a file to upload.</p>
                            </Dropzone>
                            <Col md={12} style={{gap: 10, display: "flex", flexWrap: "wrap"}}>
                                {!user.images || user.images.length === 0 ? "No photos found. Please upload some." : ""}
                                {user.images && user.images.map(i => <Attachment key={i.id}
                                                                                 reloadData={this.reloadUser}
                                                                                 image={i}
                                                                                 rotateImage={this.props.actions.rotateImage}
                                                                                 allowRemove={true}
                                                                                 setAsMain={() =>
                                                                                     this.props.actions.setAsPrimary(i.id, () => {
                                                                                         const oldPrimary = user.images.find(image => image.primary)
                                                                                         if (oldPrimary) oldPrimary.primary = false
                                                                                         user.images.find(image => image.id === i.id).primary = true
                                                                                         users.find(u => u.id === user.id).images = user.images
                                                                                         this.setState({users, user})
                                                                                     })}
                                                                                 deleteImage={imageId => {
                                                                                     const index = user.images.findIndex(image => image.id === imageId)
                                                                                     user.images.splice(index, 1)
                                                                                     this.setState({user})
                                                                                 }}/>)}
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col xs={12}>
                        <FormGroup>
                            <ControlLabel>
                                Email <span style={{color: "#d9230f"}}>*</span>
                            </ControlLabel>
                            <FormControl
                                name="email"
                                placeholder="email"
                                className={!!emailError ? "input-invalid" : null}
                                onChange={(e) => {
                                    this.validateEmail(e.target.value)
                                    this._updateUserAttr(e)
                                }}
                                value={user.email}
                            />
                            {!!emailError && <div className='error-message'>{emailError}</div>}
                        </FormGroup>
                    </Col>
                    <Col xs={12}>
                        <Row>
                            <Col xs={6}>
                                <Row>
                                    <Col xs={12}>
                                        <FormGroup>
                                            <ControlLabel>
                                                New Password
                                            </ControlLabel>
                                            <FormControl
                                                name="password"
                                                type="password"
                                                placeholder="password"
                                                value={user.password}
                                                onChange={this._updateUserAttr}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12}>
                                        <FormGroup>
                                            <ControlLabel>
                                                Password Confirmation
                                            </ControlLabel>
                                            <FormControl
                                                name="password_confirmation"
                                                type="password"
                                                placeholder="password confirmation"
                                                value={user.password_confirmation}
                                                onChange={this._updateUserAttr}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12}>
                                                    <span className='pointer text-blue'
                                                          onClick={this.showModal}>Devices: {devices && devices.length}</span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={6}>
                                Roles
                                {user && roles && roles.map(
                                    r => {
                                        const checkbox = (
                                            <FormGroup key={r.id}>
                                                <Checkbox
                                                    name="role_name"
                                                    onChange={this._handleSelectedRole(r.id)}
                                                    inline
                                                    checked={user.roles.includes(r.id)}
                                                >
                                                    {" "}
                                                    <OverlayTrigger placement="top" overlay={<Tooltip>
                                                        <div>{rolesDescriptions[r.role_name]}</div>
                                                    </Tooltip>}>
                                                        <div>
                                                            {r.role_name}
                                                        </div>
                                                    </OverlayTrigger>

                                                </Checkbox></FormGroup>);
                                        return r.role_name === 'it_engineering' ? (
                                            <Secured
                                                roles="it_engineering">{checkbox}</Secured>) : checkbox;
                                    }
                                )}
                            </Col>
                        </Row>
                    </Col>
                </div>}
                {user && <div>
                    <Col xs={12}>
                        {user.last_sign_in_at && <div className='text-center'><strong>Last
                            login: </strong>{dateFormatter(user.last_sign_in_at)}</div>}
                        {user.current_sign_in_ip && <div className='text-center'><strong>IP
                            address: </strong>{this.int2ip(user.current_sign_in_ip.addr)}</div>}
                    </Col>
                    <Col xs={12} className='top15'>
                        <Row>
                            <Col md={4} xs={4}>
                                <Button
                                    onClick={() => this.props.actions.deleteUser(user.id, this.deleteUserSubscriptionUpdate)}>
                                    Delete User
                                </Button>
                            </Col>
                            <Col md={4} xs={4} className="vcenter hcenter">
                                <FormGroup>
                                    {user.id && <Checkbox
                                        name="disabled"
                                        onChange={this._checkUserAttr}
                                        inline
                                        checked={user.disabled}
                                    >
                                        disabled
                                    </Checkbox>}
                                </FormGroup>
                            </Col>
                            <Col md={4} xs={4}>
                                <Button bsStyle="info" className="pull-right" type="submit">
                                    Save User
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} className='top15 text-danger text-center'>
                        {this.state.error}
                    </Col>
                </div>}
            </Row>
        </form>)
    }

    renderMap = () => {
        const {user, selectAll, users, center} = this.state;
        const {isUsersTabActive} = this.props;
        let mapParams = [];
        let usersOnMap = []
        if (user) {
            user.coords && user.coords.length > 0 && user.coords.map(coord => {
                let param = {};
                param.longitude = coord.longitude;
                param.latitude = coord.latitude;
                mapParams.push(param);
            });
            usersOnMap.push(user);
        } else if (selectAll && users) {
            users.map(user => {
                user.coords && user.coords.length > 0 && user.coords.map(coord => {
                    let param = {};
                    param.longitude = coord.longitude;
                    param.latitude = coord.latitude;
                    mapParams.push(param);
                });
            });
            usersOnMap = users;
        }
        if (center) {
            this.setState({center: false});
        }
        if (isUsersTabActive || window.location.href.includes("tab=2")) {
            return (<div data-debug={"map_user_list"}>
                <GoogleMapLoader
                    containerElement={
                        <div
                            id="usersMap"
                            className="remaining vertical-responsive-height"
                        />
                    }
                    googleMapElement={
                        <GoogleMap
                            ref={it => this.refGoogleMap(it, mapParams.filter(param => param.latitude))}
                            {...defaultMapParams(mapParams.filter(param => param.latitude), center)}
                        >
                            {usersOnMap.map(param => {
                                const employee = select(this.state.employees, param.person_id);
                                const employeeColor = (employee ? employee.color : '#3a87ad');
                                const google = window.google;
                                return param.coords && param.coords.length > 0 && param.coords.map((coord, index) => {
                                    return coord.latitude && (
                                        <Marker
                                            key={coord.id}
                                            position={{
                                                lat: coord.latitude,
                                                lng: coord.longitude
                                            }}
                                            icon={getColorCircleIcon(employeeColor, (index + 1).toString())}
                                            title={param.first_name + ' ' + param.last_name}
                                            zIndex={param.selected ? 1000 : 1}
                                        />
                                    )
                                })
                            })}
                        </GoogleMap>
                    }
                />
            </div>)
        }
    }

    changeUserListFilterText = debounce(500, false, email => this.setState(() => ({
        resource: {...this.state.resource, email}
    }), this.reload))

    renderUsersButtons = () => {
        const {selectAll, showDisabled} = this.state;

        return <Row className='vcenter'>
            <Col xs={4}>
                <Button onClick={() => this.handleUserSelect({id: null, email: '', roles: []})}
                        bsStyle="info">
                    New User
                </Button>
            </Col>
            <Col xs={4} className="no-padding">
                <Checkbox
                    name="Select All"
                    onChange={this.handleSelectAll}
                    inline
                    checked={selectAll}
                >
                    Select All
                </Checkbox>
                <Checkbox
                    name="Show disabled"
                    onChange={this.handleShowDisabled}
                    checked={showDisabled}
                >
                    Show disabled
                </Checkbox>
            </Col>
            <Col xs={4}>
                <Button onClick={this.showActivitiesModal}
                        bsStyle="info">
                    Activities
                </Button>
            </Col>
        </Row>
    }

    renderLicenseInfo = () => {
        return this.state.permanent_subscription ? <></> : <Col md={12}>
            <div className="top25">
                <strong>License:</strong> {this.state.activeUsers}/{this.state.availableSubscription} available
            </div>
        </Col>
    }

    renderUserListWithFilter = () => {
        const isMobile = window.screen.width < 768;
        const {user, showDisabled, users} = this.state;
        return <>
            <div className="top25">
                <FormGroup>
                    <ControlLabel>Filter</ControlLabel>
                    <FormControl
                        type="text"
                        placeholder="Filter employees"
                        onChange={e => this.changeUserListFilterText(e.target.value)}
                    />
                </FormGroup>
            </div>
            <div style={isMobile ? {height: 300, overflow: "scroll"} : {}}>
                <Nav
                    bsStyle="pills"
                    stacked
                    onSelect={this.handleUserSelect}
                >
                    {users && users.map(u => {
                        if (!u.disabled || showDisabled) {
                            return (<NavItem key={u.id} eventKey={u} id={`person_${u.person_id}`}
                                             active={user && user.id === u.id}
                                             onClick={() => this.getUserColor(u.person_id)}>
                                    <div>
                                        {(u.images.length > 0 && u.images.find(i => i.primary)) ?
                                            <Attachment key={u.images.find(i => i.primary).id}
                                                        image={u.images.find(i => i.primary)}/> :
                                            <div className='attachment-preview'>
                                                <img className='img-thumbnail' src={personPlaceholder}/>
                                            </div>}
                                    </div>
                                    <span
                                        className={`small-margin top10}`}>{u.email}</span>
                                </NavItem>
                            )
                        }
                    })}
                </Nav>
            </div>
        </>
    }

    render() {
        const {user, selectAll, showDisabled, resource, users, center} = this.state;
        const isMobile = window.screen.width < 768;

        return <div id='users-list'>
            {isMobile ? <Col xs={12}>
                <Row style={{marginTop: "10px"}}>
                    <Col xs={12}>
                        {this.renderUsersButtons()}
                    </Col>
                </Row>
                <Row>
                    {this.renderLicenseInfo()}
                </Row>
                <Row>
                    <Col xs={12}>
                        {this.renderUserListWithFilter()}
                    </Col>
                </Row>
                {selectAll ? <Col xs={12}> {this.renderMap()} </Col> :
                    <Col xs={12}>
                        <Row>
                            {this.renderUserForm()}
                        </Row>
                        <Row>
                            {this.renderMap()}
                        </Row>
                    </Col>}
            </Col> : <SplitPane split="vertical" minSize={250} defaultSize={350} allowResize={!isMobile}>
                <Row>
                    <Col md={12}>
                        {this.renderUsersButtons()}
                    </Col>
                    {this.renderLicenseInfo()}
                    <Col xs={12}>
                        {this.renderUserListWithFilter()}
                    </Col>
                </Row>
                {selectAll ? this.renderMap() :
                    <SplitPane split="vertical" defaultSize={350} minSize={250}>
                        {this.renderUserForm()}
                        {this.renderMap()}
                    </SplitPane>}
            </SplitPane>}
            <DevicesModal show={this.state.showModal} onHide={this.closeModal}
                          devices={this.state.devices} onDelete={this.deleteDevice}/>
            <ActivitiesModal show={this.state.showActivitiesModal} onHide={this.closeActivitiesModal}
                             user={this.state.user ? this.state.user.id : null} selectAll={this.state.selectAll}/>
            <Modal
                animation={false}
                show={this.state.showSubscriptionModal}
                onHide={this.closeSubscriptionModal}
                className={"heightAuto maxHeight90 modalLeadsFilterBody fontIOS"}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update subscription</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure to increase the number of subscriptions? There will be an additional cost of <Dollars
                    amount={this.state.userPrice}/> per
                    month.
                </Modal.Body>
                <Modal.Footer>
                    <Row>
                        <Col md={6} className="text-left">
                        </Col>
                        <Col md={6}>
                            <button type="button" onClick={this.closeSubscriptionModal} className="btn btn-default"
                                    data-dismiss="modal">Cancel
                            </button>
                            <button type="button"
                                    onClick={() => {
                                        let newSub = this.state.subscription
                                        newSub = {...newSub, user_count: newSub.user_count + 1}
                                        this.props.actions.updateSubscription(newSub, res => {
                                            if (res) {
                                                this.props.actions.saveUserInfo(this.state.user, this.reload)
                                                this.closeSubscriptionModal()
                                            }
                                        })
                                    }}
                                    className="btn btn-success">Update
                            </button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        </div>
    }
}

UsersList.propTypes = {
    //myProp: PropTypes.object.isRequired
};

UsersList.defaultProps = {
    //myProp: <defaultValue>
};

function mapStateToProps(state, ownProps) {
    return {
        client: state.client.customerInfo,
        users: state.client.userInfo,
        roles: state.client.roles,
        employees: state.client.employees,
        fetchDate: state.client.fetchDate,
        newUserId: state.client.newUserId,
        deleteDate: state.client.deleteDate,
        imageList: state.client.imagesInfo,
        interval: state.client.customerInfo.geolocation_interval
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(UsersList);
