import axios from "axios"
import {fetchProposalServiceStatuses} from "../../ProposalServiceStatusPage/actions"
import {doneEditingAsset, fetchDbhs, onMarkerClick, updateAssetAttribute} from "../../MapViewPage/actions"
import {apiCall} from "../../../common/apiActionHandler";
import {mapForSelect} from "../../../common/commonHandlers";
import {FETCH_SITES_ASSETS} from "./constants";
import {FETCHED_SITE_ASSETS} from "../../MapViewPage/constants";

export {onMarkerClick}
export {doneEditingAsset}
export {updateAssetAttribute}


export const fetchProposals = (resource, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/proposals/customer_proposals/`,
        params: {
            customer_id: resource.customer_id,
            page: resource.page,
            per_page: resource.per_page,
            site_id: resource.site_id,
            q: resource.q
        }
    };

    apiCall("Searching for proposals", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false)
};

export const fetchCustomerSites = (customerId, callback) => (dispatch, getState) => {
    const config = {
        url: "/api/sites/all_customer_sites",
        params: {customer_id: customerId},
    };

    apiCall("Searching for customer sites", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false)
};

export const fetchCustomerAssets = (siteIds, callback) => (dispatch, getState) => {
    const config = {
        method: "get",
        url: `/api/assets`,
        params: {site_id: siteIds}
    };

    apiCall("Searching for customer assets", config, async config => {
        const response = await axios.request(config);
        dispatch({type: FETCH_SITES_ASSETS, sitesAssets: response.data})
        dispatch({type: FETCHED_SITE_ASSETS, siteAssets: response.data})
        dispatch(fetchDbhs())
        callback && callback(response.data);
    }, dispatch, getState, false)
};

export const fetchCustomerById = (customerId, callback) => (dispatch, getState) => {
    const config = {
        method: "get",
        url: `/api/customers/${customerId}/info`,
        params: {id: customerId}
    };

    apiCall("Searching for customer", config, async config => {
        const response = await axios.request(config);
        dispatch(fetchProposalServiceStatuses("all"));
        callback && callback(response.data);
    }, dispatch, getState, false);
};

export const searchForCustomer = (searchText, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/customers/name_zip_city_search`,
        params: {q: searchText}
    };

    apiCall("Searching for customers", config, async config => {
        const response = await axios.request(config);
        callback(mapForSelect(response.data));
    }, dispatch, getState, false);
};

export const fetchSelectedSiteInfo = (selectedSite, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/sites/`,
        params: {id: selectedSite}
    };

    apiCall("Searching for selected site info", config, async config => {
        const response = await axios.request(config);
        callback(mapForSelect(response.data));
    }, dispatch, getState, false);
};
