import {apiCall} from "../../../common/apiActionHandler";
import axios from "axios";
import {mapForSelect} from "../../../common/commonHandlers";

export const getInvoice = (invoice_id, callback, errorCallback) => (dispatch, getState) => {
    const config = {
        url: `/api/new_invoices/${invoice_id}`
    };

    apiCall("Searching for invoice", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false, errorCallback);
};

export const getQbInvoice = (invoice_id, callback, errorCallback) => (dispatch, getState) => {
    const config = {
        url: `/api/quickbooks/get_invoice`,
        params: {id: invoice_id}
    };

    apiCall("Searching for quickbooks invoice", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false, errorCallback);
};

export const getQbInvoiceNyNumber = (invoice_id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/quickbooks/get_invoice_by_number`,
        params: {id: invoice_id}
    };

    apiCall("Searching for quickbooks invoice", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const searchForInvoice = (searchText, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/new_invoices/search`,
        params: {q: searchText}
    };

    apiCall("Searching for invoices", config, async config => {
        const response = await axios.request(config);
        callback(mapForSelect(response.data));
    }, dispatch, getState, false);
};

export const searchForQbInvoice = (searchText, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/quickbooks/search_for_invoice`,
        params: {search_text: searchText}
    };

    apiCall("Searching for quickbooks invoices", config, async config => {
        const response = await axios.request(config);
        callback(mapForSelect(response.data));
    }, dispatch, getState, false);
};

export const syncInvoices = (filter, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/quickbooks/sync_invoices`,
        params: filter
    };

    apiCall("Synchronizing invoices", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};

export const syncAllInvoices = (callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/quickbooks/sync_all_invoices`
    };

    apiCall("Synchronizing invoices", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};

export const updateInvoice = (items, direction, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/quickbooks/update_invoice`,
        method: 'PUT',
        data: {data: {...items}, direction: direction}
    };

    apiCall("Updating invoice", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false, callback);
};

