import {
    CREATE_CUSTOMER_SITE_MAP,
    DELETE_CUSTOMER_SITE_MAP,
    FETCH_CUSTOMER_SITE_MAPS,
    UPDATE_CUSTOMER_SITE_MAP,
} from "../constants"

import axios from "axios"
import {addAlert} from "../containers/App/actions"

export const fetchSiteMaps = siteId => async (dispatch, getState) => {
    const store = getState()
    const config = {
        url: "/api/site_maps/",
        headers: {Authorization: store.auth.token},
        params: {
            site_id: siteId,
        },
    }

    try {
        const response = await axios.request(config)
        dispatch({type: FETCH_CUSTOMER_SITE_MAPS, siteMaps: response.data, siteId: siteId})
    } catch (error) {
        dispatch(
            addAlert({
                message: "Could not fetch your siteMaps " + error.toString(),
                mood: "danger",
                dismissAfter: 1500,
            })
        )
        throw error
    }
}

export const deleteSiteMap = (siteId, siteMapId) => async (dispatch, getState) => {
    const store = getState()
    const config = {
        url: `/api/site_maps/${siteMapId}`,
        headers: {Authorization: store.auth.token},
        method: "DELETE",
    }

    try {
        const response = await axios.request(config)
        dispatch({type: DELETE_CUSTOMER_SITE_MAP, siteMap: response.data, siteId: siteId})
        dispatch(
            addAlert({
                message: "Deleted your siteMap ",
                mood: "success",
                dismissAfter: 1500,
            })
        )
        return true
    } catch (error) {
        dispatch(
            addAlert({
                message: "Could not delete your siteMap " + error.toString(),
                mood: "danger",
                dismissAfter: 1500,
            })
        )
        throw error
    }
}

export const updateSiteMap = (siteId, siteMap) => async (dispatch, getState) => {
    const store = getState()
    const isNew = siteMap.id === 0 ? true : false

    const config = {
        method: isNew ? "POST" : "PUT",
        url: isNew ? "/api/site_maps" : `/api/site_maps/${siteMap.id}`,
        headers: {Authorization: store.auth.token},
        data: {site_map: {...siteMap, id: undefined, __dirty: undefined}},
    }

    try {
        const response = await axios.request(config)
        if (isNew) {
            dispatch(
                addAlert({
                    message: `${isNew ? "Created" : "Edited"} site!`,
                    mood: "success",
                    dismissAfter: 1500,
                })
            )
        }

        // update reducer state
        if (isNew) {
            dispatch({type: CREATE_CUSTOMER_SITE_MAP, siteMap: response.data, siteId: siteId})
        } else {
            dispatch({type: UPDATE_CUSTOMER_SITE_MAP, siteMap: response.data, siteId: siteId})
        }

        return true
    } catch (error) {
        dispatch(
            addAlert({
                message: `Could not ${isNew ? "CREATE" : "EDIT"} the site ${error.toString()}`,
                mood: "danger",
                dismissAfter: 1500,
            })
        )
        throw error
    }
}

export const saveSiteMap = (siteId, siteMapId) => async (dispatch, getState) => {
    const store = getState()
    let site = store.customerProposals.selectedSiteInfo &&
        store.customerProposals.selectedSiteInfo[
            `site_${store.customerProposals.selectedSite}`
            ]

    let siteMap = {
        site_id: site.id,
        latitude: site.latitude,
        longitude: site.longitude,
        default_zoom: site.default_zoom,
        map_type: 'hybrid',
        id: 0,
    }

    if (siteMapId) {
        siteMap = store.customerSiteMaps[siteMapId]
    }

    const isNew = siteMap.id === 0 ? true : false

    const config = {
        method: isNew ? "POST" : "PUT",
        url: isNew ? "/api/site_maps" : `/api/site_maps/${siteMap.id}`,
        headers: {Authorization: store.auth.token},
        data: {site_map: {...siteMap, id: undefined, __dirty: undefined}},
    }

    try {
        const response = await axios.request(config)
        dispatch(
            addAlert({
                message: `${isNew ? "Created" : "Edited"} site!`,
                mood: "success",
                dismissAfter: 1500,
            })
        )

        if (isNew) {
            dispatch({type: CREATE_CUSTOMER_SITE_MAP, siteMap: response.data, siteId: siteId})
        } else {
            dispatch({type: UPDATE_CUSTOMER_SITE_MAP, siteMap: response.data, siteId: siteId})
        }
        return true
    } catch (error) {
        dispatch(
            addAlert({
                message: `Could not ${isNew ? "CREATE" : "EDIT"} the site ${error.toString()}`,
                mood: "danger",
                dismissAfter: 1500,
            })
        )
        throw error
    }
}

export const toggleIncludeSiteMap = (siteId, mapNum) => async (dispatch, getState) => {
    const store = getState()
    const target = store.siteMaps.siteMaps[siteId].find((siteMap) => siteMap.id == mapNum)
    const siteMap = {
        ...target,
        id: mapNum,
        include_when_print: !target.include_when_print,
    }

    const config = {
        method: "PUT",
        url: `/api/site_maps/${siteMap.id}`,
        headers: {Authorization: store.auth.token},
        data: {site_map: {...siteMap, id: undefined, __dirty: undefined}},
    }

    try {
        const response = await axios.request(config)
        dispatch({type: UPDATE_CUSTOMER_SITE_MAP, siteMap: response.data, siteId: siteId})
        return true
    } catch (error) {
        dispatch(
            addAlert({
                message: `Could not toggle the sitemap setting ${error.toString()}`,
                mood: "danger",
                dismissAfter: 1500,
            })
        )
        throw error
    }
}
