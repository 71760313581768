import React, {useEffect, useState} from "react";
import {Button, Col, Row} from "react-bootstrap";
import PageHeader from "../../components/PageTitle";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import AnswerTableFormatter from "./EquipmentQuestionsFormatters/AnswerTableFormatter";
import moment from "moment-timezone";
import {defaultDateTimeFormat} from "../../common/commonHandlers";
import {Link} from "react-router";
import EquipmentFilterModal from "./EquipmentFilterModal";

const isMobile = window.screen.width <= 1024;
const isMobileSm = window.screen.width <= 768;

const EquipmentChecklistHistory = React.memo(({
                                                  eventKey,
                                                  id,
                                                  employees,
                                                  getEquipmentAnswers,
                                                  findProposals,
                                                  findWorkOrders
                                              }) => {
    const [expandedRows, setExpandedRows] = useState([])
    const [proposalsSelectedOptions, setProposalsSelectedOptions] = useState([])
    const [workOrderSelectedOptions, setWorkOrderSelectedOptions] = useState([])
    const [equipmentFilterModal, setEquipmentFilterModal] = useState(false)
    const [equipmentAnswers, setEquipmentAnswers] = useState([])
    const [total, setTotal] = useState(0)
    const [filters, setFilters] = useState({
        crewLeaders: [],
        completedBy: [],
        dateFrom: null,
        dateTo: null,
        proposals: [],
        workOrders: [],
        showFullAnswers: false,
        showIncompleteAnswers: false,
    })
    const [tableParams, setTableParams] = useState({
        page: 1,
        per_page: 10,
        sort_by: 'created_at',
        sort: 'desc',
    })

    const reload = () => {
        getEquipmentAnswers(id, {...filters, ...tableParams}, res => {
            setEquipmentAnswers(res.grouped_answers)
            setTotal(res.total)
        })
    }

    const equipmentAnswersFormatter = (cell, row) => {
        const isRowCurrentlyExpanded = expandedRows?.includes(cell.id);

        return isRowCurrentlyExpanded ? isMobileSm ?
            <Row>
                {cell.answers && cell.answers.map((a, i) => {
                    return <Col xs={12}>
                        <Row>
                            <Col xs={12}><AnswerTableFormatter content={a.content} answer={a.answer}
                                                               number={i + 1}/></Col>
                            <Col xs={4} className="bold">Answered by:</Col>
                            <Col xs={8}>{a.answered_full_name}</Col>
                            <Col xs={4} className="bold">Answer at:</Col>
                            <Col
                                xs={8}>{a.answer_date ? moment(a.answer_date).format(defaultDateTimeFormat) : 'No date'}</Col>
                        </Row>
                        <hr/>
                    </Col>
                })}
            </Row>
            :
            <Row>
                <Col xs={6} className="text-center bold">Answer:</Col>
                <Col xs={3} className="text-center bold">Answered by:</Col>
                <Col xs={3} className="text-center bold">Answer at:</Col>
                {cell.answers && cell.answers.map((a, i) => {
                    return <Col xs={12}>
                        <Row className="vcenter mb-15">
                            <Col xs={6}>
                                <AnswerTableFormatter content={a.content} answer={a.answer} number={i + 1}/>
                            </Col>
                            <Col xs={3} className="text-center">{a.answered_full_name}</Col>
                            <Col xs={3}
                                 className="text-center">{a.answer_date ? moment(a.answer_date).format(defaultDateTimeFormat) : 'No date'}</Col>
                        </Row>
                    </Col>
                })}
            </Row> : null
    }

    const renderSizePerPageDropDown = ({currSizePerPage, sizePerPageList, changeSizePerPage}) => (
        <div className="btn-group dropup" style={{margin: "18px 0"}}>
            <button className="btn btn-info dropdown-toggle" type="button" id="dropdownMenu2"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span>{currSizePerPage}</span>
                <span className="ml-6 caret"/>
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                {sizePerPageList.map(({text, value}) =>
                    <li className='pointer'>
                        <a
                            className='table-pagination dropdown-item'
                            onClick={() => changeSizePerPage(value)}
                        >{text}</a>
                    </li>
                )}
            </ul>
        </div>
    );

    const workOrderFormatter = (cell) => {
        const onClick = e => e.stopPropagation();
        return (
            <span onClick={onClick}>
                <Link target="_blank"
                      to={`/work_orders/${cell.id}`}>{cell.number}</Link>
            </span>
        );
    }

    const proposalFormatter = (cell) => {
        const onClick = e => e.stopPropagation();
        return (
            <span onClick={onClick}>
                <Link target="_blank" to={`/mapview/${cell.id}`}>{cell.number}</Link>
            </span>
        );
    }

    const createdAtFormatter = (cell) => cell ? moment(cell).format(defaultDateTimeFormat) : 'N/A'

    const rowFormatterMobile = (cell, row) => {
        return <div>
            <Row>
                <Col xs={6}>Work Order</Col>
                <Col xs={6}>{workOrderFormatter(row.work_order)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Proposal</Col>
                <Col xs={6}>{proposalFormatter(row.proposal)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Created At</Col>
                <Col xs={6}>{createdAtFormatter(row.created_at)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Completed by</Col>
                <Col xs={6}>{row.completed_full_name}</Col>
            </Row>
            <Row>
                <Col xs={6}>Crew Leader</Col>
                <Col xs={6}>{row.crew_leader_full_name}</Col>
            </Row>
        </div>
    };

    useEffect(() => {
        reload()
    }, [filters, tableParams])

    useEffect(() => {
        reload()
    }, [eventKey])

    return <>
        <Row className="mt10">
            <Col md={6}>
                <PageHeader pageName="Work Orders" pageDetail={`${total} records`}/>
            </Col>
            <Col md={6} className="text-right">
                <Button
                    className="pointer no-top-margin"
                    bsStyle="warning"
                    onClick={() => setEquipmentFilterModal(true)}
                >
                    Filter {" "}<FontAwesomeIcon icon="search" className="small-margin"/>
                </Button>
            </Col>
        </Row>
        <Row>
            <Col xs={12}>
                {isMobileSm ?
                    <BootstrapTable
                        striped={true}
                        bordered={false}
                        hover={true}
                        data={equipmentAnswers}
                        height={'auto'}
                        tableStyle={{height: '500px', overflowY: 'auto', overflowX: 'hidden'}}
                        pagination={true}
                        expandableRow={(cell) => cell.answers.length > 0}
                        expandComponent={equipmentAnswersFormatter}
                        fetchInfo={{dataTotalSize: total}}
                        options={{
                            onPageChange: (page, per_page) => setTableParams({...tableParams, page, per_page}),
                            page: tableParams.page,
                            expandRowBgColor: 'rgb(255,254,238)',
                            onRowClick: row => {
                                const isRowCurrentlyExpanded = expandedRows?.includes(row.id);

                                if (isRowCurrentlyExpanded) {
                                    setExpandedRows(expandedRows?.filter(id => id !== row.id))
                                } else {
                                    setExpandedRows([...expandedRows, row.id])
                                }
                            },
                            sizePerPage: tableParams.per_page,
                            paginationSize: 3,
                            sizePerPageDropDown: renderSizePerPageDropDown,
                            sizePerPageList: [
                                {text: '10', value: 10},
                                {text: '25', value: 25},
                                {text: '30', value: 30},
                                {text: '50', value: 50},
                                {text: '100', value: 100},
                                {text: '200', value: 200}],
                        }}
                    >
                        <TableHeaderColumn
                            isKey
                            dataField="id"
                            width={"10%"}
                            hidden
                        >
                            ID
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="id"
                            dataFormat={rowFormatterMobile}
                        />
                    </BootstrapTable>
                    :
                    <BootstrapTable
                        striped={true}
                        bordered={false}
                        hover={true}
                        data={equipmentAnswers}
                        height={'auto'}
                        tableStyle={{height: !isMobile ? '500px' : '', overflowY: 'auto', overflowX: 'hidden'}}
                        pagination={true}
                        expandableRow={(cell) => cell.answers.length > 0}
                        expandComponent={equipmentAnswersFormatter}
                        fetchInfo={{dataTotalSize: total}}
                        options={{
                            onPageChange: (page, per_page) => setTableParams({...tableParams, page, per_page}),
                            page: tableParams.page,
                            expandRowBgColor: 'rgb(255,254,238)',
                            onRowClick: row => {
                                const isRowCurrentlyExpanded = expandedRows?.includes(row.id);

                                if (isRowCurrentlyExpanded) {
                                    setExpandedRows(expandedRows?.filter(id => id !== row.id))
                                } else {
                                    setExpandedRows([...expandedRows, row.id])
                                }
                            },
                            sizePerPage: tableParams.per_page,
                            paginationSize: 3,
                            sizePerPageDropDown: renderSizePerPageDropDown,
                            sizePerPageList: [
                                {text: '10', value: 10},
                                {text: '25', value: 25},
                                {text: '30', value: 30},
                                {text: '50', value: 50},
                                {text: '100', value: 100},
                                {text: '200', value: 200}],
                            sortName: tableParams.sort_by,
                            sortOrder: tableParams.sort,
                            onSortChange: (sort_by, sort) => setTableParams({...tableParams, sort_by, sort})
                        }}
                    >
                        <TableHeaderColumn
                            isKey
                            dataField="id"
                            width={"10%"}
                            hidden
                        >
                            ID
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="work_order"
                            width={"15%"}
                            dataSort={true}
                            dataFormat={workOrderFormatter}
                        >
                            Work Order
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="proposal"
                            width={"15%"}
                            dataSort={true}
                            dataFormat={proposalFormatter}
                        >
                            Proposal
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="created_at"
                            width={"20%"}
                            dataSort={true}
                            dataFormat={createdAtFormatter}
                        >
                            Created At
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="completed_full_name"
                            width={"25%"}
                            dataSort={true}
                        >
                            Completed by
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="crew_leader_full_name"
                            width={"25%"}
                            dataSort={true}
                        >
                            Crew Leader
                        </TableHeaderColumn>
                    </BootstrapTable>
                }
            </Col>
        </Row>
        {equipmentFilterModal && <EquipmentFilterModal
            onHide={() => setEquipmentFilterModal(false)}
            onSave={(res) => {
                setFilters(res.filters)
                setProposalsSelectedOptions(res.proposalsSelectedOptions)
                setWorkOrderSelectedOptions(res.workOrderSelectedOptions)
                setEquipmentFilterModal(false)
            }}
            employees={employees}
            workOrderSelected={workOrderSelectedOptions}
            proposalsSelected={proposalsSelectedOptions}
            filters={filters}
            findWorkOrders={findWorkOrders}
            findProposals={findProposals}
        />}
    </>
}, (prevProps, nextProps) => {
    return prevProps.eventKey === nextProps.eventKey
})

export default EquipmentChecklistHistory;