import {apiCall} from "../../common/apiActionHandler";
import axios from "axios";
import {mapForSelect} from "../../common/commonHandlers";
import {dataUpload} from "../../common/upload";
import {addAlert} from "../App/actions";


export const loadConsumables = callback => (dispatch, getState) => {
    const config = [{url: '/api/plants'}, {url: 'api/factors'}, {url: 'api/services'}, {url: '/api/active_ingredient'}];

    apiCall("Loading data", config, async config => {
        let actions = config.map(c => axios.request(c));
        const all = await axios.all(actions);
        callback({
            plants: mapForSelect(all[0].data),
            factors: mapForSelect(all[1].data),
            services: mapForSelect(all[2].data),
            active_ingredients: all[3].data.map(x => ({value: x.id, label: `${x.name} [${x.percentage}%]`}))
        });
    }, dispatch, getState, false);
};

export const checkForDuplicateName = (resource, callback) => async (dispatch, getState) => {
    const method = "POST";
    const url = `/api/consumables/check_for_duplicate_name`;
    const config = {
        method,
        url,
        data: {
            id: resource?.id,
            name: resource?.name
        },
    };

    apiCall(`Search for duplicates`, config, async config => {
        const response = await axios.request(config);
        callback && callback(response);
    }, dispatch, getState, false);

};

export const uploadConsumablesAttachment = (id, acceptedFiles, callback) => (dispatch, getState) => {
    dataUpload(`/api/consumable_files?consumable_id=${id}`, acceptedFiles, callback)(dispatch, getState);
};

export const deleteConsumablesAttachment = (id, consumable_id, callback) => (dispatch, getState) => {
    const state = getState()
    const config = {
        method: 'delete',
        url: `/api/consumable_files/${id}`,
        params: {consumable_id: consumable_id},
        headers: {'Authorization': state.auth.token},
    }
    axios.request(config)
        .then(response => {
            dispatch(addAlert({message: 'Deleted', mood: 'info', dismissAfter: 1500}))
            callback()
        })
        .catch((error, response) => {
            dispatch(addAlert({
                message: 'Did not delete Attachment!',
                mood: 'warning',
                dismissAfter: 3500
            }))
        })
}

export const fetchFilterData = (id, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/consumables/filter_data/`,
        params: {id: id}
    }

    apiCall("Fetch filter data", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false);
}

export const getFilteredData = (id, callback, params) => async (dispatch, getState) => {
    const config = {
        url: `/api/consumables/${id}/`,
        params
    }

    apiCall("Filtered data", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false);
}

export const createActiveIngredient = (data, callback) => (dispatch, getState) => {
    const config = {
        url: '/api/active_ingredient',
        data: data,
        method: "POST"
    };

    apiCall("Creating active ingredient", config, async config => {
        const response = await axios.request(config);
        callback({
            id: response.data.id,
            value: response.data.id,
            label: `${response.data.name} [${response.data.percentage}%]`
        });
    }, dispatch, getState, true);
}