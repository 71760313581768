import React, {Component} from 'react';
import PropTypes from "prop-types"
import {Button, Col, FormControl, FormGroup, Panel, Row} from 'react-bootstrap'

export default class GoToProposal extends Component {
    handleSearch(e) {
        e.preventDefault();// interrupt enter key
        this.props.onGoToProposal()
    }

    render() {
        const {updateProposalSearchText, searchStatusMessage, searchProposalNo} = this.props
        return (

            <Panel bsStyle='default' className="text-center">

                <h3 className="text-success">Go To Proposal</h3>
                <br/>
                <Row>
                    <Col xs={6} xsOffset={3}>
                        <form onSubmit={e => this.handleSearch(e)}>
                            <FormGroup>
                                <FormControl type="text" className='input-sm' value={searchProposalNo}
                                             onChange={e => updateProposalSearchText(e.target.value)}
                                             placeholder="Proposal Number"/>
                            </FormGroup>
                            <p>{searchStatusMessage}{' '}</p>
                            <p>{' '}</p>
                            <Button type="submit" onClick={e => this.handleSearch(e)} bsStyle="success"
                                    bsSize="small">Search</Button>
                        </form>
                    </Col>
                </Row>

            </Panel>


        )
    }
}

GoToProposal.propTypes = {
    updateProposalSearchText: PropTypes.func.isRequired,
    searchStatusMessage: PropTypes.string,
    onGoToProposal: PropTypes.func.isRequired,
    searchProposalNo: PropTypes.string
}
