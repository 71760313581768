import React, {Component} from "react"
import {bindActionCreators} from "redux"
import {connect} from "react-redux"
import * as MyActions from "./actions"
import {addAlert} from "../App/actions"
import ProposalPage from "../ProposalPage"
import {Col, Grid, Row} from "react-bootstrap"
import PageHeader from "../../components/PageTitle"
import ApprovalList from "./ApprovalList"
import Helmet from "react-helmet"

const Actions = {...MyActions, addAlert}

class PendingApprovalsPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            proposalId: undefined,
            page: 1,
            per_page: 30,
            lastApproved: null
        }
    }

    componentWillMount() {
        //consider checking the data to see if its stale enough
        //  this.props.actions.fetchArboristApprovals()
        const {page, per_page} = this.state
        this.props.actions.fetchPendingApprovals(page, per_page)
    }

    reviewProposal = proposalId => {
        console.log("setting proposal id to ", proposalId)
        this.setState({proposalId})
    }
    onPaginationChange = (page, per_page) => {
        this.setState({page: page, per_page: per_page})
    }

    setProposalToApproved = proposalId => {
        const {page, per_page} = this.state

        this.props.actions.approveProposal(proposalId)
        this.setState({proposalId: undefined, lastApproved: proposalId}) // null it out so the proposaal view stops showing it
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {page, per_page, lastApproved} = this.state
        const {arboristApprovals} = this.props
        let shouldReload = arboristApprovals.data.some(aa => aa.proposal_id === lastApproved)
        if (prevState.page !== page || prevState.per_page !== per_page || shouldReload) {
            this.props.actions.fetchPendingApprovals(page, per_page)
        }
    }

    render() {
        const {name, arboristApprovals} = this.props
        return (
            <Grid fluid>
                <Helmet title="Proposals Pending Approval"/>
                <PageHeader
                    pageName="Proposals Pending Approval"
                    pageDetail={`- ${name}'s Proposals awaiting review and Approval`}
                />

                <Row>
                    <Col xs={12}>
                        <ApprovalList
                            arboristApprovals={arboristApprovals}
                            reviewProposal={this.reviewProposal.bind(this)}
                            onApproveProposal={this.setProposalToApproved.bind(this)}
                            onPaginationChange={this.onPaginationChange.bind(this)}
                        />
                    </Col>

                </Row>

                <Row>
                    {this.state.proposalId
                        ? <ProposalPage
                            promises={true}
                            upsertPromise={this.props.actions.upsertPromise}
                            proposalId={this.state.proposalId}
                        />
                        : null}
                </Row>
            </Grid>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        name: state.auth.name,
        arboristApprovals: state.pendingApprovals.arboristApprovals,
    }
}

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(Actions, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(
    PendingApprovalsPage
)
