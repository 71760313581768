import React, {useState} from 'react'
import {Button, ButtonGroup, Col, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle, faTrash, faVideo} from "@fortawesome/free-solid-svg-icons";
import * as recorderActions from './actions';
import {bindActionCreators} from "redux";
import RateModal from "../../MapViewPage/new/RateModal";
import {connect} from 'react-redux'


const isMobile = window.screen.width <= 1024;
const actions = {...recorderActions}


const IssueRecorder = ({recorder, removeFile, actions}) => {
    const [active, setActive] = useState(-1)
    const [showModal, setShowModal] = useState(false)
    const {startRecording, startRecordingCurrentTab} = actions
    const {isRecording, videos} = recorder

    const isSafariBrowser = () => {
        const vendor = navigator.vendor
        const agent = navigator.userAgent
        return vendor?.indexOf('Apple') > -1 && agent?.indexOf('CriOS') === -1 && agent?.indexOf('FxiOS') === -1
    }

    const recordVideo = () => {
        if (isSafariBrowser()) setShowModal(true)
        else startRecording()
    }

    const recordVideoCurrentTab = () => {
        if (isSafariBrowser()) setShowModal(true)
        else startRecordingCurrentTab()
    }

    const renderTooltip = (text) => (
        <Tooltip id="tooltip">
            <span>{text}</span>
        </Tooltip>
    );

    const renderOverlay = (text, icon, onClick) => (
        <OverlayTrigger placement='top' overlay={renderTooltip(text)}>
            <Button className='media-tools-btn' onClick={onClick}>
                <FontAwesomeIcon icon={icon} className="font16"/>
            </Button>
        </OverlayTrigger>
    );

    const renderRecorderButton = (type, func) => (
        <Button className='issue-record-btn' onClick={func} disabled={isRecording}>
            <FontAwesomeIcon icon={faVideo} className="mr-9 font16"/>
            <span>{`Record ${type}`}</span>
        </Button>
    )

    const renderRecorderButtonCurrentTab = (type, func) => (
        <Button className='issue-record-btn' onClick={func} disabled={isRecording}>
            <FontAwesomeIcon icon={faVideo} className="mr-9 font16"/>
            <span>{`Record current tab`}</span>
        </Button>
    )

    const renderBrowserAlert = () => {
        const browsers = ['Google Chrome', 'Firefox', 'Opera', 'Edge (new)']
        return (
            <RateModal
                title="Screen recording"
                show={showModal}
                onHide={() => setShowModal(false)}
                acceptBtn
            >
                <div className="rate-modal-body-padding d-flex">
                    <FontAwesomeIcon icon={faExclamationTriangle}
                                     className={isMobile ? "font20 alert-font-color mt-3" : "small-margin font20 alert-font-color mt-3"}/>
                    <p className={isMobile ? "ml-10 font12 line_height17_5" : "ml-10 line_height17_5"}>
                        Screen recording is supported by below browsers:
                        <ul className='recording-alert-modal no-padding'>
                            {browsers.map(browser => <li className='recording-alert-modal-item'>{browser}</li>)}
                        </ul>
                    </p>
                </div>
            </RateModal>
        )
    }

    const removeDescription = 'Remove file'

    return (
        <>
            {renderBrowserAlert()}
            <Row className='d-flex no-left-margin mt-20 bottom15'>
                <Col>
                    <ButtonGroup bsSize="small" style={{marginRight: "2px"}}>
                        {renderRecorderButtonCurrentTab('Video', recordVideoCurrentTab)}
                    </ButtonGroup>
                    <ButtonGroup bsSize="small">
                        {renderRecorderButton('Video', recordVideo)}
                    </ButtonGroup>
                </Col>
            </Row>
            <div className='media-wrapper'>
                <Row className="no-margin">
                    {videos?.length > 0 && videos.map(({id, url}, i) => {
                        const activityClass = active === i ? `active-tools` : `inactive-tools`
                        const classNames = ["issue-record-media-tools", activityClass].join(" ")

                        return (
                            <Col
                                md={4}
                                key={id}
                                className='issue-record-media-wrapper no-padding mr-15 bottom15'
                                onMouseEnter={() => setActive(i)}
                                onMouseLeave={() => setActive(-1)}
                            >
                                <video
                                    src={url}
                                    controls
                                    className="issue-record-media mr-5"
                                />
                                <div className={classNames}>
                                    {renderOverlay(removeDescription, faTrash, () => removeFile(id))}
                                </div>
                            </Col>
                        )
                    })}
                </Row>
            </div>
        </>
    )
}

const mapStateToProps = state => ({
    name: state.auth.name,
    recorder: state.recorder
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(IssueRecorder)