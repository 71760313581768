export const [
    FETCH_PRINT_PROPOSAL,
    FETCH_PROPOSAL_SITE_MAPS,
    FETCH_PRINT_INVOICE,
    SET_SELECTED_SITE
] = [
    'FETCH_PRINT_PROPOSAL',
    'FETCH_PROPOSAL_SITE_MAPS',
    'FETCH_PRINT_INVOICE',
    'SET_SELECTED_SITE'
]
