import React from 'react'
import {GoogleMap, Marker} from "react-google-maps";
import {defaultMapParams} from "../../../common/commonHandlers";
import {getColorCircleIcon} from "../../MapViewPage/IconProvider";
import {GoogleMapLoader} from "../../../components/GoogleMapLoader";
import ResourceComponent from "../../../components/ResourceComponent";

class UsersMap extends ResourceComponent {

    state = {
        users: [],
        loaded: false,
        state: true
    }

    componentDidUpdate(prevProps, prevState) {
        const {users, selectedUserId} = this.props
        if (users !== prevProps.users || selectedUserId !== prevProps.selectedUserId) {
            const updatedUsers = selectedUserId ? users.filter(u => u.id === selectedUserId) : users;
            this.setState({
                users: updatedUsers,
                loaded: false
            })
        }
    }

    render() {
        const {users, center} = this.state;
        if (center) {
            this.setState({center: false});
        }
        let mapParams = []
        users.forEach(user => {
            user.coords && user.coords.length > 0 && user.coords.forEach(coord => {
                let param = {};
                param.longitude = coord.longitude;
                param.latitude = coord.latitude;
                mapParams.push(param);
            });
        });
        return users && (<div>
            <GoogleMapLoader
                containerElement={
                    <div
                        id="usersMap"
                        className="remaining vertical-responsive-height"
                    />
                }
                googleMapElement={
                    <GoogleMap
                        ref={it => this.refGoogleMap(it, mapParams.filter(param => param.latitude))}
                        {...defaultMapParams(mapParams.filter(param => param.latitude), center)}
                    >
                        {users && users.map((param, i) => {
                            return param.coords && param.coords.length > 0 && param.coords.map((coord, index) => {
                                return coord.latitude && (<Marker
                                    key={index}
                                    position={{
                                        lat: coord.latitude,
                                        lng: coord.longitude
                                    }}
                                    icon={getColorCircleIcon(param.color || '#3a87ad', (i + index + 1).toString())}
                                    title={param.first_name + ' ' + param.last_name}
                                    zIndex={param.selected ? 1000 : 1}
                                />)
                            })

                        })
                        }
                    </GoogleMap>
                }
            />
        </div>)

    }

}

export default UsersMap;
