import axios from "axios"
import {apiCall} from "../../common/apiActionHandler";
import {dataUpload, handleUpload} from "../../common/upload";

export const getHoliday = (params, tab, holiday_id, callback, errorCallback) => (dispatch, getState) => {
    const config = {
        url: `/api/holidays/get_holidays`,
        params: {
            ...params,
            tab,
            holiday_id
        }
    };
    apiCall("Getting holidays", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false, errorCallback);
};

export const saveHolidayTabs = (tabs, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/holidays/update_holiday_tab`,
        data: {tabs},
        method: 'PUT'
    };

    apiCall("Save Holiday Tabs", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState);
};

export const fetchHolidayImages = (id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/images?holiday_id=${id}`
    };
    apiCall("Getting holidays", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const removeHolidayImage = (id, callback) => (dispatch, getState) => {
    const config = {
        method: "DELETE",
        url: `/api/images/${id}`
    };
    apiCall("Holiday image has been removed", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};

export const rotateImage = (id, direction, callback) => (dispatch, getState) => {
    const store = getState()

    const config = {
        method: "POST",
        url: `/api/images/rotate`,
        headers: {Authorization: store.auth.token},
        params: {id, direction},
    }

    apiCall("Image rotation", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
}

export const uploadImages = (acceptedFiles, id, callback) => (dispatch, getState) => {
    handleUpload(`/api/images/upload?holiday_id=${id}`, acceptedFiles, callback)(dispatch, getState);
};

export const uploadFiles = (acceptedFiles, id, callback) => (dispatch, getState) => {
    dataUpload(`/api/holiday_files/upload?holiday_id=${id}`, acceptedFiles, callback)(dispatch, getState);
};

export const fetchHolidayFiles = (holiday_id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/holiday_files`,
        params: {holiday_id}
    };
    apiCall("Getting holidays files", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const removeHolidayFile = (id, callback) => (dispatch, getState) => {
    const config = {
        method: "DELETE",
        url: `/api/holiday_files/${id}`
    };
    apiCall("File has been removed", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};


