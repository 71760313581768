import axios from 'axios'
import {apiCall} from "../../common/apiActionHandler";

export const fetchProposalServicesInfo = (token, proposalId) => {

    const config = {
        url: `/api/proposal_services/info`,
        headers: {'Authorization': token},
        params: {
            proposal_id: proposalId,
        },
    }
    return axios.request(config)
}

export const load = callback => (dispatch, getState) => {
    const config = [
        {url: `/api/reasons`}
    ];

    apiCall("Loading data", config, async config => {
        let actions = config.map(c => axios.request(c));
        const all = await axios.all(actions);
        callback({
            reasons: all[0].data
        });
    }, dispatch, getState, false);
};

export const fetchProposalInfo = (token, proposalId) => {
    const config = {
        url: `/api/proposals/${proposalId}/info`,
        headers: {'Authorization': token},
    }
    return axios.request(config)
}

export const updateWoNote = (id, note, token) => {
    //WO ui allows the user to update teh note while they're working on the WO
    console.log("about to call api to update wonote")
    const config = {
        method: 'put',
        url: `/api/proposal_services/${id}`,
        headers: {'Authorization': token},
        data: {
            proposal_service: {wo_notes: note}
        }
    }
    return axios.request(config)
}

export const addToWo = (wo, selectedPs, token) => {

    console.log("about to call api to add a ps to (maybe new) wo")

    const config = {
        method: wo === '-' ? 'post' : 'put',
        url: wo === '-' ? '/api/work_orders/' : `/api/work_orders/${wo}/add_ps_to_work_order`,
        headers: {'Authorization': token},
        data: {
            psid: selectedPs.join(',')
        }
    }
    return axios.request(config)
}

export const removePsFromWo = (psid, token) => {
    console.log("about to call api to delete a ps from wo")
    const config = {
        method: 'delete',
        url: `/api/work_orders/remove_ps_from_work_order`,
        headers: {'Authorization': token},
        params: {psid}
    }
    return axios.request(config)
}


