import {Button, Col, ControlLabel, FormControl, Glyphicon, Label, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import ColorCheckbox from "../../../Scheduler/ColorCheckbox";
import DefaultAnswerFormatter from "../DefaultAnswerFormatter";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {select} from "../../../../common/commonHandlers";
import Select from "react-select";
import './SwitchAnswers.scss'

const SwitchAnswer = ({number, question, value, onChange, options, setAnswerOptions, preview, disabled, id}) => {
    const switchOptions = () => {
        const labelPositionOptions = [
            {value: 'top', label: 'Top'},
            {value: 'right', label: 'Right'},
            {value: 'bottom', label: 'Bottom'},
        ]

        return <Row>
            <Col xs={12}>
                <Label bsStyle="info">Label Position</Label>
                <Select className="Select" classNamePrefix="select"
                        value={select(labelPositionOptions, options.labelPosition)}
                        options={labelPositionOptions}
                        onChange={e => setAnswerOptions({
                            ...options,
                            labelPosition: e ? e.value : null,
                        })}
                        placeholder="Label Position"
                />
            </Col>
            <Col xs={12}>
                <ColorCheckbox value={options.answersHorizontally}
                               label="Answers horizontally"
                               onChange={() => setAnswerOptions({
                                   ...options,
                                   answersHorizontally: !options.answersHorizontally,
                               })}
                />
            </Col>
            <Col xs={12}>
                <ColorCheckbox value={options.required}
                               label="Answer will be required"
                               onChange={() => setAnswerOptions({
                                   ...options,
                                   required: !options.required
                               })}
                />
            </Col>
            {options.switchOptions.map(option => {
                const label = `Option ${option.id}`
                return <Col xs={12}>
                    <Row className="mt10">
                        <Col xs={12}><Label bsStyle="info">{label}</Label></Col>
                        <Col xs={12} className="flex-center">
                            <FormControl
                                type="text"
                                placeholder={label}
                                onChange={(e) => {
                                    const newOptions = options.switchOptions.map(o => {
                                        if (o.id === option.id) {
                                            o.label = e.target.value
                                            return o
                                        } else {
                                            return o
                                        }
                                    })
                                    setAnswerOptions({
                                        ...options,
                                        switchOptions: newOptions,
                                    })
                                }}
                                value={option.label}
                            />
                            <FontAwesomeIcon
                                icon="trash-alt"
                                className="trash-icon"
                                onClick={() => {
                                    let newOptions = options.switchOptions.filter(o => o.id !== option.id)
                                    newOptions = newOptions.map((o, i) => {
                                        o.id = i + 1
                                        return o
                                    })
                                    setAnswerOptions({
                                        ...options,
                                        switchOptions: newOptions,
                                    })
                                }}
                            />
                        </Col>
                    </Row>
                </Col>
            })}
            <Col xs={12}>
                <Button
                    className="pointer mt-15 mb-15 full-width"
                    bsSize="small"
                    bsStyle="success"
                    onClick={() => {
                        if (options.switchOptions?.every(q => q.label && q.label.length > 0)) {
                            setAnswerOptions({
                                ...options,
                                switchOptions: options.switchOptions.concat({
                                    id: options.switchOptions.length + 1,
                                    label: ''
                                }),
                            })
                        }
                    }}
                >
                    Add next option {" "}<FontAwesomeIcon icon="plus" className="small-margin"/>
                </Button>
            </Col>
        </Row>
    }

    const switchAnswers = () => {
        return <div id="switch-answer-type">
            <ControlLabel className="bold">
                <span>{number}. {question}</span>
                {options.required && <OverlayTrigger
                    placement='top'
                    overlay={<Tooltip id={`tooltip-refresh`}>Answer is required</Tooltip>}
                >
                    <Glyphicon glyph='exclamation-sign' className="ml-6 font14"/>
                </OverlayTrigger>}
            </ControlLabel>
            <div
                className={`switch-options-wrapper ${options.answersHorizontally ? 'horizontally-wrapper' : ''} ${!options.answersHorizontally ? 'vertically-wrapper' : ''}`}>
                {options.switchOptions.map((o, i) => {
                    const switchId = `material-switch-${id}-${disabled}-${o.label}`
                    return <div className={`material-switch mt10 mr-11 ${options.labelPosition}-position`}
                                style={{width: `${options.switchOptions?.length > 0 && options.answersHorizontally ? (1 / options.switchOptions.length) * 100 : 100}%`}}>
                        <input id={switchId} type="checkbox"
                               checked={value.includes(o.id)}
                               disabled={disabled}
                               onChange={(e) => {
                                   if (e.target.checked) {
                                       value = value.concat(o.id)
                                   } else {
                                       value = value.filter(v => v !== o.id)
                                   }
                                   onChange(value)
                               }}
                        />
                        <label htmlFor={switchId} className="label-default"></label>
                        <span className="ml-10 description">{o.label}</span>
                    </div>
                })}
            </div>
        </div>
    }
    return preview ?
        <DefaultAnswerFormatter options={switchOptions()} preview={switchAnswers()}/>
        :
        switchAnswers()
}

export default SwitchAnswer;