import React, {useEffect, useState} from "react";
import {Button, Col, ControlLabel, FormControl, Modal, Row} from "react-bootstrap";
import DateRangeFilter from "../../components/DateRangeFilter";
import moment from "moment";
import './LogsModal.scss'
import Select from "react-select";
import {defaultDateFormat, defaultDateTimeFormat, select} from "../../common/commonHandlers";
import MDSpinner from "react-md-spinner";

const LogsModal = ({onHide, selectedTab, getUsers, getQuickbooksLogs}) => {
    const [filters, setFilters] = useState({
        selected_tab: selectedTab,
        date_from: null,
        date_to: null,
        users: [],
        searched_phrase: '',
        max_logs_count: 300
    })
    const [logs, setLogs] = useState([])
    const [formattedLogs, setFormattedLogs] = useState([])
    const [userOptions, setUserOptions] = useState([])
    const [firstOpen, setFirstOpen] = useState(true)
    const [total, setTotal] = useState(0)
    const [loading, setLoading] = useState(false)

    const getLogs = () => {
        setLoading(true)
        getQuickbooksLogs({
            ...filters,
            date_from: getDate(filters.date_from),
            date_to: getDate(filters.date_to)
        }, (res) => {
            const formattedLogs = res.logs.map(l => {
                return {
                    ...l,
                    activity: markSearchPhrase(l.activity)
                }
            })
            setLogs(formattedLogs)
            setFormattedLogs(formattedLogs)
            setTotal(res.total)
            setLoading(false)
        })
    }

    const getDate = (date) => {
        if (date && date.length > 0) {
            let datearray = date.split("/");
            return datearray[1] + '/' + datearray[0] + '/' + datearray[2];
        } else {
            return null;
        }
    }

    const handleRangeDateChange = (start, end) => {
        const from = start && moment(getDate(start)).format(defaultDateFormat);
        const to = end && moment(getDate(end)).format(defaultDateFormat);
        setFilters({
            ...filters,
            date_from: from && from,
            date_to: to && to
        })
    }

    const markSearchPhrase = (result) => {
        if (filters.searched_phrase && filters.searched_phrase.length > 0) {
            const reg = new RegExp(filters.searched_phrase, 'gi');
            const final_str = result.replace(reg, function (str) {
                return '<b class="mst">' + str + '</b>'
            });
            return final_str
        } else {
            return result
        }
    }

    const markSearchPhraseInResult = (result) => {
        if (filters.searched_phrase_in_results && filters.searched_phrase_in_results.length > 0) {
            const regResult = new RegExp(filters.searched_phrase_in_results, 'gi');
            const final_str = result.replace(regResult, function (str) {
                return '<b class="mstir">' + str + '</b>'
            });
            return final_str
        } else {
            return result
        }
    }

    useEffect(() => {
        const formattedLogs = logs?.map(l => {
            return {
                ...l,
                activity: markSearchPhraseInResult(l.activity)
            }
        })
        setFormattedLogs(formattedLogs)
    }, [filters.searched_phrase_in_results])

    useEffect(() => {
        if (!firstOpen) {
            getLogs()
        }
    }, [filters.users, filters.max_logs_count])

    useEffect(() => {
        if (!firstOpen) {
            let getLogsTimer = setTimeout(() => getLogs(), 1000);
            return () => {
                clearTimeout(getLogsTimer);
            };
        }
    }, [filters.date_from, filters.date_to, filters.searched_phrase])

    useEffect(() => {
        getUsers(res => {
            setFirstOpen(false)
            setUserOptions(res.map(u => {
                return {value: u.id, label: `${u.first_name} ${u.last_name}`}
            }))

        })
        getLogs()
    }, [])

    return (
        <Modal
            id="logs-modal"
            show={true}
            onHide={onHide}
            backdrop="static"
            bsSize="large"
            className={"heightAuto maxHeight90 fontIOS"}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Logs
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col xs={12}>
                        <ControlLabel>Dates</ControlLabel>
                        <DateRangeFilter
                            promisedStart={filters.date_from}
                            promisedEnd={filters.date_to}
                            upsertPromise={(id, start, end) => handleRangeDateChange(start, end, 'date_from', 'date_to')}
                            proposalServiceId={null}
                            serviceNo={null}
                            index={selectedTab.includes('Service') ? 1 : 2}
                            timeDelay={1}
                        />
                    </Col>
                    <Col xs={12}>
                        <Select className="Select bottom15 top15"
                                classNamePrefix="select"
                                value={select(userOptions, filters.users)}
                                options={userOptions}
                                placeholder="Users"
                                isMulti
                                isClearable
                                onChange={e => {
                                    setFilters({
                                        ...filters,
                                        users: e.map(u => u.value)
                                    })
                                }}
                        />
                    </Col>
                    <Col xs={12}>
                        <ControlLabel>Search in all logs</ControlLabel>
                        <FormControl
                            type="text"
                            placeholder="Searched phrase"
                            value={filters.searched_phrase}
                            onChange={e => {
                                setFilters({
                                    ...filters,
                                    searched_phrase: e.target.value
                                })
                            }}
                        />
                    </Col>
                    <Col xs={12}>
                        <ControlLabel>Search in result logs</ControlLabel>
                        <FormControl
                            type="text"
                            placeholder="Searched phrase"
                            value={filters.searched_phrase_in_results}
                            onChange={e => {
                                setFilters({
                                    ...filters,
                                    searched_phrase_in_results: e.target.value
                                })
                            }}
                        />
                    </Col>
                </Row>
                <hr/>
                {loading ?
                    <Row className="text-center"><MDSpinner size={40}/></Row>
                    :
                    <Row className="logs-list">
                        {formattedLogs && formattedLogs.length > 0 ?
                            formattedLogs.map(l => {
                                return <Col xs={12} className="log-row">
                                    <div className="text-gray font10">
                                        {moment(l.created_at).format(defaultDateTimeFormat)}{' '}
                                        {l.person_name}
                                    </div>
                                    <div style={{color: l.activity.includes("Error:") && 'red'}}>
                                        <div dangerouslySetInnerHTML={{__html: l.activity}}/>
                                    </div>
                                </Col>
                            })
                            :
                            <p className='no-data-text'>No logs to display</p>
                        }
                        {total > logs.length && <p onClick={() => {
                            setFilters({
                                ...filters,
                                max_logs_count: filters.max_logs_count * 2
                            })
                        }} className="load-more-button">Load more</p>}
                    </Row>
                }
            </Modal.Body>
            <Modal.Footer>
                <div className="footer">
                    <span>
                        <span className="text-gray mr-5">Total found:</span>
                        <span className="font16 bold">{total}</span>
                        <span className="text-gray mr-5 ml-20">Loaded:</span>
                        <span className="font16 bold">{logs.length}</span>
                    </span>
                    <Button
                        bsStyle="success"
                        onClick={() => onHide()}
                    >
                        Close
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default LogsModal;