import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Dropzone from "react-dropzone";
import * as actions from "./ImportsApi";
import {Button, Checkbox, Col, ControlLabel, FormGroup, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import {faDownload, faInfoCircle, faListAlt} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import LeadsFilterModal from "../../../LeadsPage/LeadsFilterModal";
import Select from "react-select";
import * as descriptions from './descriptions.json'
import './Imports.css'
import {select} from "../../../../common/commonHandlers";

class Imports extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            clientImportData: ['customerID', 'companyName', 'firstName', 'lastName', 'phoneNumber', 'emailAddress', 'street', 'city', 'state', 'zip', 'clientType'],
            clientImportDataOptionalFields: ['customerID', 'companyName', 'emailAddress', 'clientType'],
            siteImportData: ['siteID', 'customerID', 'siteName', 'street', 'city', 'state', 'zip'],
            siteImportDataOptionalFields: ['siteID'],
            plantImportData: ['plantID', 'plantName', 'genus', 'variety', 'order_name', 'ave_height', 'ave_width', 'ave_dbh', 'description'],
            plantImportDataOptionalFields: ['plantID', 'genus', 'variety', 'order_name', 'ave_height', 'ave_width', 'ave_dbh', 'description'],
            assetImportData: ['treeID', 'siteID', 'commonName', 'dbh', 'lat', 'lng', 'tstamp_created', 'height', 'width', 'stem_count', 'note'],
            assetImportDataOptionalFields: ['treeID', 'dbh', 'lat', 'lng', 'tstamp_created', 'height', 'width', 'stem_count', 'note'],
            serviceImportData: ['serviceID', 'service_name', 'description', 'service_type_name', 'estimated_total_time', 'number_of_employees', 'rate'],
            serviceImportDataOptionalFields: ['serviceID', 'description', 'estimated_total_time', 'number_of_employees', 'rate'],
            proposalImportData: ['reportID', 'name', 'salesUser', 'siteID', 'notes', 'siteName', 'tstamp_created', 'tstamp_paid', 'job_start', 'job_end', 'termType'],
            proposalImportDataOptionalFields: ['reportID', 'notes', 'tstamp_paid', 'job_start', 'job_end', 'termType'],
            proposalServiceImportData: ['reportItemID', 'reportID', 'treeID', 'treatmentType', 'price', 'tstamp_completed'],
            proposalServiceImportDataOptionalFields: ['reportItemID', 'tstamp_completed'],
            openInfoModal: false,
            selectedInstruction: 'customers',
            isAutoGenerateNumber: {
                customers: false,
                sites: false,
                plants: false,
                assets: false,
                services: false,
                proposals: false,
                proposal_services: false
            }
        }
    }

    componentDidMount() {
        const {getUserImportSettings} = this.props.actions
        getUserImportSettings(({settings}) => {
            this.setState({isAutoGenerateNumber: settings})
        })
    }

    handleSearchInputChange = (e, instructionsData) => {
        const selectedInstruction = instructionsData.find(data => data.value === e.value)?.value
        this.setState({selectedInstruction})
    }

    updateResourceAttr = (e) => {
        const {isAutoGenerateNumber} = this.state
        const {updateUserImportSettings} = this.props.actions

        isAutoGenerateNumber[e.target.name] = e.target.checked
        this.setState({isAutoGenerateNumber}, () => {
            updateUserImportSettings(isAutoGenerateNumber)
        })
    };

    renderTooltip = (importData, optionalFields) => (
        <Tooltip id="tooltip">
            <div className="text-left">
                <strong>Required data format:</strong>
                <ol className="mt-5">
                    {importData && importData.map(data => (
                        <li className="transfer_tooltip_li">
                            {data &&
                            optionalFields.includes(data) ? `${data} (optional)` : data}
                        </li>
                    ))}
                </ol>
            </div>
        </Tooltip>
    );

    renderTemplateTooltip = (template_name) => (
        <Tooltip id="tooltip">
            <p>Download example {template_name} import data template</p>
        </Tooltip>
    );

    renderModalHeader = () => (
        <div className='vertical-align'>
            <FontAwesomeIcon icon={faListAlt} className="font18 mr-10"/>
            <span>Import instructions</span>
        </div>
    )

    render() {
        const {
            clientImportData,
            siteImportData,
            plantImportData,
            assetImportData,
            serviceImportData,
            proposalImportData,
            proposalServiceImportData,
            clientImportDataOptionalFields,
            siteImportDataOptionalFields,
            plantImportDataOptionalFields,
            assetImportDataOptionalFields,
            serviceImportDataOptionalFields,
            proposalImportDataOptionalFields,
            proposalServiceImportDataOptionalFields,
            openInfoModal,
            selectedInstruction,
            isAutoGenerateNumber
        } = this.state;

        const instructionsData = [
            {label: 'Customers', value: 'customers'},
            {label: 'Sites', value: 'sites'},
            {label: 'Proposals', value: 'proposals'},
            {label: 'Plants', value: 'plants'},
            {label: 'Assets', value: 'assets'},
            {label: 'Services', value: 'services'},
            {label: 'Proposal Services', value: 'proposal_services'},
        ]

        const description = descriptions.default

        return (
            <div id="imports" className="top25">
                <Row className='no-margin'>
                    <LeadsFilterModal
                        title={this.renderModalHeader()}
                        show={openInfoModal}
                        onHide={() => this.setState({openInfoModal: false})}
                        closeButton={() => this.setState({openInfoModal: false})}
                        showAcceptBtn
                        backdrop='static'
                    >
                        <div className='import-modal-container'>
                            <Row className='no-margin'>
                                <Col md={12}>
                                    <FormGroup>
                                        <Select className="Select" classNamePrefix="select"
                                                placeholder="Select Data"
                                                value={select(instructionsData, selectedInstruction)}
                                                options={instructionsData}
                                                onChange={e => this.handleSearchInputChange(e, instructionsData)}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className='no-margin'>
                                <Col className='mt-15 bottom15 import-instructions-container'>
                                    <h5 className='bold bottom25'>{description[selectedInstruction].label}:</h5>
                                    <p className='bottom25'>Apply these tips to successfully import your data:</p>
                                    <ol className="import-instructions-list">
                                        {description[selectedInstruction].values.map(value => (
                                            <li className='bottom10'>{value}</li>
                                        ))}
                                    </ol>
                                </Col>
                            </Row>
                        </div>
                    </LeadsFilterModal>
                    <Button style={{padding: '2px 6px'}} onClick={() => this.setState({openInfoModal: true})}>
                        <FontAwesomeIcon icon={faInfoCircle} className="mr-5 font12"/>
                        <span>Import Instructions</span>
                    </Button>
                </Row>
                <hr/>
                <Row>
                    <Col md={3}>
                        <div className="imports-data-container">
                            <ControlLabel>
                                Customers Import
                            </ControlLabel>
                            <div className="imports-data-tools">
                                <OverlayTrigger placement="bottom"
                                                overlay={this.renderTooltip(clientImportData, clientImportDataOptionalFields)}>
                                    <FontAwesomeIcon icon={faInfoCircle} className="marginLeft10 font16"/>
                                </OverlayTrigger>
                                <OverlayTrigger placement="bottom" overlay={this.renderTemplateTooltip("customers")}>
                                    <button
                                        className="ml-2 removedBtnStyles"
                                        onClick={() => this.props.actions.downloadCsv(clientImportData, "customers_template")}>
                                        <FontAwesomeIcon icon={faDownload} className="font14 pointer"/>
                                    </button>
                                </OverlayTrigger>
                                <div className='vertical-align ml-6'>
                                    <Checkbox
                                        name="customers"
                                        onChange={this.updateResourceAttr}
                                        checked={isAutoGenerateNumber['customers']}
                                    />
                                    <span>Auto generate data number</span>
                                </div>
                            </div>
                        </div>
                        <FormGroup>
                            <Dropzone onDrop={f => this.props.actions.importClients(f)
                            }
                                      className="dropzone pointer">
                                <p>Drop a file here or click to select a file to upload.</p>
                            </Dropzone>
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <FormGroup>
                            <div className="imports-data-container">
                                <ControlLabel>
                                    Sites import
                                </ControlLabel>
                                <div className="imports-data-tools">
                                    <OverlayTrigger placement="bottom"
                                                    overlay={this.renderTooltip(siteImportData, siteImportDataOptionalFields)}>
                                        <FontAwesomeIcon icon={faInfoCircle} className="marginLeft10 font16"/>
                                    </OverlayTrigger>
                                    <OverlayTrigger placement="bottom" overlay={this.renderTemplateTooltip("sites")}>
                                        <button
                                            className="ml-2 removedBtnStyles"
                                            onClick={() => this.props.actions.downloadCsv(siteImportData, "sites_template")}>
                                            <FontAwesomeIcon icon={faDownload} className="font14 pointer"/>
                                        </button>
                                    </OverlayTrigger>
                                    <div className='vertical-align ml-6'>
                                        <Checkbox
                                            name="sites"
                                            onChange={this.updateResourceAttr}
                                            checked={isAutoGenerateNumber['sites']}
                                        />
                                        <span>Auto generate data number</span>
                                    </div>
                                </div>
                            </div>
                            <Dropzone onDrop={f => {
                                this.props.actions.importSites(f)
                            }}
                                      className="dropzone pointer">
                                <p>Drop a file here or click to select a file to upload.</p>
                            </Dropzone>
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <FormGroup>
                            <div className="imports-data-container">
                                <ControlLabel>
                                    Proposals import
                                </ControlLabel>
                                <div className="imports-data-tools">
                                    <OverlayTrigger placement="bottom"
                                                    overlay={this.renderTooltip(proposalImportData, proposalImportDataOptionalFields)}>
                                        <FontAwesomeIcon icon={faInfoCircle} className="marginLeft10 font16"/>
                                    </OverlayTrigger>
                                    <OverlayTrigger placement="bottom"
                                                    overlay={this.renderTemplateTooltip("proposals")}>
                                        <button
                                            className="ml-2 removedBtnStyles"
                                            onClick={() => this.props.actions.downloadCsv(proposalImportData, "proposals_template")}>
                                            <FontAwesomeIcon icon={faDownload} className="font14 pointer"/>
                                        </button>
                                    </OverlayTrigger>
                                    <div className='vertical-align ml-6'>
                                        <Checkbox
                                            name="proposals"
                                            onChange={this.updateResourceAttr}
                                            checked={isAutoGenerateNumber['proposals']}
                                        />
                                        <span>Auto generate data number</span>
                                    </div>
                                </div>
                            </div>
                            <Dropzone onDrop={f => {
                                this.props.actions.importProposals(f)
                            }}
                                      className="dropzone pointer">
                                <p>Drop a file here or click to select a file to upload.</p>
                            </Dropzone>
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <FormGroup>
                            <div className="imports-data-container">
                                <ControlLabel>
                                    Plants import
                                </ControlLabel>
                                <div className="imports-data-tools">
                                    <OverlayTrigger placement="bottom"
                                                    overlay={this.renderTooltip(plantImportData, plantImportDataOptionalFields)}>
                                        <FontAwesomeIcon icon={faInfoCircle} className="marginLeft10 font16"/>
                                    </OverlayTrigger>
                                    <OverlayTrigger placement="bottom" overlay={this.renderTemplateTooltip("plants")}>
                                        <button
                                            className="ml-2 removedBtnStyles"
                                            onClick={() => this.props.actions.downloadCsv(plantImportData, "plants_template")}>
                                            <FontAwesomeIcon icon={faDownload} className="font14 pointer"/>
                                        </button>
                                    </OverlayTrigger>
                                    <div className='vertical-align ml-6'>
                                        <Checkbox
                                            name="plants"
                                            onChange={this.updateResourceAttr}
                                            checked={isAutoGenerateNumber['plants']}
                                        />
                                        <span>Auto generate data number</span>
                                    </div>
                                </div>
                            </div>
                            <Dropzone onDrop={f => {
                                this.props.actions.importPlants(f)
                            }}
                                      className="dropzone pointer">
                                <p>Drop a file here or click to select a file to upload.</p>
                            </Dropzone>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={3}>
                        <FormGroup>
                            <div className="imports-data-container">
                                <ControlLabel>
                                    Assets import
                                </ControlLabel>
                                <div className="imports-data-tools">
                                    <OverlayTrigger placement="bottom"
                                                    overlay={this.renderTooltip(assetImportData, assetImportDataOptionalFields)}>
                                        <FontAwesomeIcon icon={faInfoCircle} className="marginLeft10 font16"/>
                                    </OverlayTrigger>
                                    <OverlayTrigger placement="bottom" overlay={this.renderTemplateTooltip("assets")}>
                                        <button
                                            className="ml-2 removedBtnStyles"
                                            onClick={() => this.props.actions.downloadCsv(assetImportData, "assets_template")}>
                                            <FontAwesomeIcon icon={faDownload} className="font14 pointer"/>
                                        </button>
                                    </OverlayTrigger>
                                    <div className='vertical-align ml-6'>
                                        <Checkbox
                                            name="assets"
                                            onChange={this.updateResourceAttr}
                                            checked={isAutoGenerateNumber['assets']}
                                        />
                                        <span>Auto generate data number</span>
                                    </div>
                                </div>
                            </div>
                            <Dropzone onDrop={f => {
                                this.props.actions.importAssets(f)
                            }}
                                      className="dropzone pointer">
                                <p>Drop a file here or click to select a file to upload.</p>
                            </Dropzone>
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <FormGroup>
                            <div className="imports-data-container">
                                <ControlLabel>
                                    Services import
                                </ControlLabel>
                                <div className="imports-data-tools">
                                    <OverlayTrigger placement="bottom"
                                                    overlay={this.renderTooltip(serviceImportData, serviceImportDataOptionalFields)}>
                                        <FontAwesomeIcon icon={faInfoCircle} className="marginLeft10 font16"/>
                                    </OverlayTrigger>
                                    <OverlayTrigger placement="bottom" overlay={this.renderTemplateTooltip("services")}>
                                        <button
                                            className="ml-2 removedBtnStyles"
                                            onClick={() => this.props.actions.downloadCsv(serviceImportData, "services_template")}>
                                            <FontAwesomeIcon icon={faDownload} className="font14 pointer"/>
                                        </button>
                                    </OverlayTrigger>
                                    <div className='vertical-align ml-6'>
                                        <Checkbox
                                            name="services"
                                            onChange={this.updateResourceAttr}
                                            checked={isAutoGenerateNumber['services']}
                                        />
                                        <span>Auto generate data number</span>
                                    </div>
                                </div>
                            </div>
                            <Dropzone onDrop={f => {
                                this.props.actions.importServices(f)
                            }}
                                      className="dropzone pointer">
                                <p>Drop a file here or click to select a file to upload.</p>
                            </Dropzone>
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <FormGroup>
                            <div className="imports-data-container">
                                <ControlLabel>
                                    Proposal Services import
                                </ControlLabel>
                                <div className="imports-data-tools">
                                    <OverlayTrigger placement="bottom"
                                                    overlay={this.renderTooltip(proposalServiceImportData, proposalServiceImportDataOptionalFields)}>
                                        <FontAwesomeIcon icon={faInfoCircle} className="marginLeft10 font16"/>
                                    </OverlayTrigger>
                                    <OverlayTrigger placement="bottom"
                                                    overlay={this.renderTemplateTooltip("proposal services")}>
                                        <button
                                            className="ml-2 removedBtnStyles"
                                            onClick={() => this.props.actions.downloadCsv(proposalServiceImportData, "proposals_services_template")}>
                                            <FontAwesomeIcon icon={faDownload} className="font14 pointer"/>
                                        </button>
                                    </OverlayTrigger>
                                    <div className='vertical-align ml-6'>
                                        <Checkbox
                                            name="proposal_services"
                                            onChange={this.updateResourceAttr}
                                            checked={isAutoGenerateNumber['proposal_services']}
                                        />
                                        <span>Auto generate data number</span>
                                    </div>
                                </div>
                            </div>
                            <Dropzone onDrop={f => {
                                this.props.actions.importProposalServices(f)
                            }}
                                      className="dropzone pointer">
                                <p>Drop a file here or click to select a file to upload.</p>
                            </Dropzone>
                        </FormGroup>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
})

export default connect(null, mapDispatchToProps)(Imports);