import moment from "moment";
import summer from "../Summer.png";
import tool from "../Tool.png";
import expiration from "../Expiration.png";
import {defaultDateFormat, reverseColor} from "../../../common/commonHandlers";
import birthday_cake from "../birthday_cake.png";
import React, {useState} from "react";
import * as classnames from 'classnames';
import './DayHeader.scss';
import {Glyphicon} from "react-bootstrap";
import {Link} from "react-router";

const DayHeader = ({arg, employees, holidays, birthdays, startDates, truckServices}) => {
    const todays = h => h.recurring ? (moment(h.date).format('MM-DD') === moment(arg.date).format('MM-DD') && moment(h.date).format(defaultDateFormat) <= moment(arg.date).format(defaultDateFormat)) : moment(h.date).format('YYYY-MM-DD') === moment(arg.date).format('YYYY-MM-DD')
    const isMonth = arg.view.type === 'dayGridMonth'
    const [showAll, setShowAll] = useState(false)

    const todayHolidaysCount = holidays.filter(todays).length
    const todayStartDatesCount = startDates.filter(todays).length
    const todayBirthdaysCount = birthdays.filter(todays).length
    const todayServicesCount = truckServices.filter(todays).length
    const totalEventsCount = todayHolidaysCount + todayStartDatesCount + todayBirthdaysCount + todayServicesCount
    return <div className={classnames('day-header', {"month-view": isMonth})}>
        {arg.dayNumberText && <span className='day-number-text'>{arg.dayNumberText}</span>}
        {arg.text && <span className='day-text'>{arg.text}</span>}
        <div className='events-container'>
            {showAll && <Glyphicon glyph='remove' className="pointer close-btn"
                                   onClick={() => setShowAll(false)}/>}

            {(!showAll && totalEventsCount > 2) ? <div className='pointer' onClick={() => setShowAll(true)}>
                {holidays.filter(todays).slice(0, 1).map(h =>
                    <Link to={{
                        pathname: '/holidays',
                        search: `?holiday_id=${h.id}`
                    }} target="_blank">
                        <div className='header-inner holiday'>
                            <img src={summer}/>
                            <strong> Holiday: </strong>
                            {h.name}
                        </div>
                    </Link>
                )}
                {todayHolidaysCount < 1 && startDates.filter(todays).slice(0, 1).map(e => {
                        const employee = employees.find(u => u.value === e.person_id);
                        e.color = employee && employee.color ? employee.color : '#3a87ad';
                        e.textColor = employee && employee.color ? reverseColor(employee.color) : reverseColor('#3a87ad')

                        return (
                            <div className='employee-date-header' style={{
                                background: e.color,
                                color: e.textColor
                            }}>
                                <strong> Start date: </strong>
                                {e.name}
                            </div>
                        )
                    }
                )}
                {todayHolidaysCount < 1 && todayStartDatesCount < 1 && birthdays.filter(todays).slice(0, 1).map(b => {
                    const employee = employees.find(u => u.value === b.person_id);
                    b.color = employee ? employee.color : '#3a87ad';
                    b.textColor = employee && employee.color ? reverseColor(employee.color) : reverseColor('#3a87ad')
                    return <div className='header-inner' style={{
                        background: b.color,
                        color: b.textColor
                    }}>
                        <img src={birthday_cake}/>
                        <strong> Birthday: </strong>
                        {b.name}
                    </div>
                })}
                {todayHolidaysCount < 1 && todayStartDatesCount < 1 && todayBirthdaysCount < 1 && truckServices.filter(todays).slice(0, 1).map(ts => {
                    return <Link to={{
                        pathname: '/trucks',
                        search: ts.type === 'expiration' ? `?truck_id=${ts.id}` : `?service_id=${ts.id}`
                    }} target="_blank">
                        <div className='header-inner' style={{
                            background: `${ts.color}`,
                            color: reverseColor(`${ts.color}`)
                        }}>
                            <img src={ts.type === 'expiration' ? expiration : tool}/>
                            <strong> {ts.type === 'expiration' ? 'Expiration: ' : 'Service: '}</strong>
                            {ts.name}
                        </div>
                    </Link>

                })}
                +{totalEventsCount - 1} more</div> : <>
                {holidays.filter(todays).map(h =>
                    <Link to={{
                        pathname: '/holidays',
                        search: `?holiday_id=${h.id}`
                    }} target="_blank">
                        <div className='header-inner holiday'>
                            <img src={summer}/>
                            <strong> Holiday: </strong>
                            {h.name}
                        </div>
                    </Link>
                )}
                {startDates.filter(todays).map(e => {
                        const employee = employees.find(u => u.value === e.person_id);
                        e.color = employee && employee.color ? employee.color : '#3a87ad';
                        e.textColor = employee && employee.color ? reverseColor(employee.color) : reverseColor('#3a87ad')

                        return (
                            <div className='employee-date-header' style={{
                                background: e.color,
                                color: e.textColor
                            }}>
                                <strong> Start date: </strong>
                                {e.name}
                            </div>
                        )
                    }
                )}
                {birthdays.filter(todays).map(b => {
                    const employee = employees.find(u => u.value === b.person_id);
                    b.color = employee ? employee.color : '#3a87ad';
                    b.textColor = employee && employee.color ? reverseColor(employee.color) : reverseColor('#3a87ad')
                    return <div className='header-inner' style={{
                        background: b.color,
                        color: b.textColor
                    }}>
                        <img src={birthday_cake}/>
                        <strong> Birthday: </strong>
                        {b.name}
                    </div>
                })}
                {truckServices.filter(todays).map(ts => {
                    return <Link to={{
                        pathname: '/trucks',
                        search: ts.type === 'expiration' ? `?truck_id=${ts.id}` : `?service_id=${ts.id}`
                    }} target="_blank">
                        <div className='header-inner' style={{
                            background: `${ts.color}`,
                            color: reverseColor(`${ts.color}`)
                        }}>
                            <img src={ts.type === 'expiration' ? expiration : tool}/>
                            <strong> {ts.type === 'expiration' ? 'Expiration: ' : 'Service: '}</strong>
                            {ts.name}
                        </div>
                    </Link>
                })}
            </>}
        </div>
    </div>
}

export default DayHeader;
