import React from 'react'
import LeadsFilterModal from "../LeadsPage/LeadsFilterModal";
import {Col, FormGroup, Row} from "react-bootstrap";
import PromiseUi from "../../components/PromiseUi";
import Select from "react-select";
import {select} from "../../common/commonHandlers";
import ColorCheckbox from "../Scheduler/ColorCheckbox";

const isMobile = window.screen.width <= 1024;

export const SalesDashboardTabFilter = ({params}) => {

    const status = params.values.resource.selectedStatus
    const statuses = params.values.dashboardStatuses

    return (
        <LeadsFilterModal
            title={status ? `${statuses.find(s => s.value === status)?.label} Table Filter` : 'In Draft Table Filter'}
            show={params.values.isBacklogTableOpened}
            onHide={params.func.setFilterModalParams}
            closeButton={params.func.closeModal}
            modalBodyStyle={{maxHeight: "600px", overflow: "auto"}}
        >
            <span>
                {["backlog", "completed", "invoiced", "paid"].indexOf(status) > -1 &&
                    <Row className="top10 no-margin">
                        <Col md={6}
                             className={isMobile ? "mt-15 bottom15" : "mt-15 bottom5"}>
                            <span className="font11">Promised date</span>
                            <PromiseUi
                                promisedStart={params.values.resource.promisedFrom}
                                promisedEnd={params.values.resource.promisedTo}
                                upsertPromise={(id, start, end) => params.func.handleRangeDateChange(start, end, 'promisedFrom', 'promisedTo')}
                                proposalServiceId={null}
                                serviceNo={null}
                                startPlaceholder="P From"
                                endPlaceholder="P To"
                                timeDelay={300}
                                index={1}
                            />
                        </Col>
                        <Col md={6} className={isMobile ? "mt-15 bottom15" : "mt-15 bottom5"}>
                            <div className="ml-7">
                                <span className="font11">Scheduled date</span>
                                <PromiseUi
                                    promisedStart={params.values.resource.scheduledFrom}
                                    promisedEnd={params.values.resource.scheduledTo}
                                    upsertPromise={(id, start, end) => params.func.handleRangeDateChange(start, end, 'scheduledFrom', 'scheduledTo')}
                                    proposalServiceId={null}
                                    serviceNo={null}
                                    startPlaceholder="S From"
                                    endPlaceholder="S To"
                                    timeDelay={300}
                                    index={2}
                                    orientation='vertical'
                                    anchorDirection='right'
                                />
                            </div>
                        </Col>
                    </Row>}
                {(status === "backlog" && params.values.backlogProposedFilter || ["proposed", "completed", "invoiced", "paid"].indexOf(status) > -1) &&
                    <><Row className={'modalLeadsFilter'}>
                        <Col className='ml-15 mr-15 bottom15'>
                            <span className="font11">Sent date</span>
                            <PromiseUi
                                promisedStart={params.values.resource.sent_from}
                                promisedEnd={params.values.resource.sent_to}
                                upsertPromise={(id, start, end) => params.func.handleRangeDateChange(start, end, 'sent_from', 'sent_to')}
                                proposalServiceId={null}
                                serviceNo={null}
                                startPlaceholder="Sent From"
                                endPlaceholder="Sent To"
                                timeDelay={300}
                                index={status === 'proposed' ? 1 : 3}
                            />
                        </Col>
                    </Row>
                        <hr className={'bottom10 mt10 mr-15 ml-15'}/>
                    </>}
                <Row className="bottom10 no-margin">

                         <Col md={["backlog", "completed"].indexOf(status) > -1 ? 6 : 12}
                              className={isMobile ? "mt5 bottom15" : "mt5 bottom5"}>
                            <Select className="Select" classNamePrefix="select"
                                    value={select(params.values.services, params.values.resource.service)}
                                    options={params.values.services}
                                    isClearable
                                    isMulti
                                    onChange={params.func.selectResourceAttr('service')}
                                    placeholder="Service"
                            />
                        </Col>
                    {["backlog", "completed"].indexOf(status) > -1 &&
                        <Col md={6}
                             className={isMobile ? "mt5 bottom15" : "mt5 bottom5"}>
                            <Select className="Select" classNamePrefix="select"
                                    value={select(params.values.statuses, params.values.resource.selectedStatus === 'backlog' ? params.values.resource.ps_status : params.values.resource.ps_completed_statuses)}
                                    options={params.values.statuses}
                                    isMulti
                                    isClearable
                                    onChange={params.func.selectResourceAttr(params.values.resource.selectedStatus === 'backlog' ? 'ps_status' : 'ps_completed_statuses')}
                                    placeholder="Status"
                            />
                        </Col>}
                    </Row>
                    <Row className="bottom10 no-margin">
                        <Col md={6} className="mt5 bottom15">
                            <Select className="Select" classNamePrefix="select"
                                    value={select(params.values.reasons, params.values.resource.reason)}
                                    options={params.values.reasons}
                                    isClearable
                                    isMulti
                                    onChange={params.func.selectResourceAttr('reason')}
                                    placeholder="Reason"
                            />
                        </Col>
                        <Col md={6} className="mt5 bottom15">
                            <Select className="Select" classNamePrefix="select"
                                    value={select(params.values.equipments, params.values.resource.equipment)}
                                    options={params.values.equipments}
                                    isClearable
                                    isMulti
                                    onChange={params.func.selectResourceAttr('equipment')}
                                    placeholder="Equipment"
                            />
                        </Col>
                    </Row>
                    <hr className={'mb-10-mt-0 mr-15 ml-15'}/>
                    <Row className="bottom10 no-margin">
                        <Col md={6} className={isMobile ? "mt5 bottom15" : "mt5 bottom5"}>
                            <FormGroup className="bottom0">
                                <Select className="Select" classNamePrefix="select"
                                        value={select(params.values.cityOptions, params.values.resource.city)}
                                        options={params.values.cityOptions}
                                        isClearable
                                        isMulti
                                        onInputChange={params.func.citySearch}
                                        onChange={params.func.selectResourceAttr('city')}
                                        placeholder="City"
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6} className={isMobile ? "mt5 bottom15" : "mt5 bottom5"}>
                            <FormGroup className="bottom0">
                                <Select className="Select" classNamePrefix="select"
                                        value={select(params.values.zipOptions, params.values.resource.zip)}
                                        options={params.values.zipOptions}
                                        isClearable
                                        isMulti
                                        onInputChange={params.func.zipSearch}
                                        onChange={params.func.selectResourceAttr('zip')}
                                        placeholder="Zip"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="bottom10 no-margin">
                        <Col md={6} className="mt5 bottom15">
                            <Select className="Select" classNamePrefix="select"
                                    value={select(params.values.priorities, params.values.resource.priority)}
                                    options={params.values.priorities}
                                    isMulti
                                    isClearable
                                    onChange={params.func.selectResourceAttr('priority')}
                                    placeholder="Priority"
                            />
                        </Col>
                        <Col md={6} className="mt5 bottom15">
                            <Select className="Select" classNamePrefix="select"
                                    value={select(params.values.plants, params.values.resource.plant)}
                                    options={params.values.plants}
                                    isMulti
                                    isClearable
                                    onChange={params.func.selectResourceAttr('plant')}
                                    placeholder="Plants"
                            />
                        </Col>
                    </Row>
                    <hr className={'mb-10-mt-0 mr-15 ml-15'}/>
                    <Row className="no-margin">
                       <Col md={6} className={isMobile ? "mt5 bottom15" : "mt5 bottom5"}>
                            <Select className="Select" classNamePrefix="select"
                                    value={select(params.values.customerOptions, params.values.resource.customer_id)}
                                    options={params.values.customerOptions}
                                    isClearable
                                    isMulti
                                    onInputChange={params.func.customerSearch}
                                    onChange={params.func.selectResourceAttr('customer_id')}
                                    placeholder="Customer"
                            />
                        </Col>
                        <Col md={6} className={isMobile ? "mt5 bottom15" : "mt5 bottom5"}>
                            <Select className="Select" classNamePrefix="select"
                                    value={select(params.values.customer_types, params.values.resource.customer_type)}
                                    options={params.values.customer_types}
                                    isClearable
                                    isMulti
                                    onChange={params.func.selectResourceAttr('customer_type')}
                                    placeholder="Customer type"
                            />
                        </Col>
                    </Row>
                                    <Row className="no-margin">
                        <Col md={6} className={isMobile ? "mt5 bottom15" : "mt5 bottom5"}>
                            <Select className="Select" classNamePrefix="select"
                                    value={select(params.values.employees, params.values.resource.arborist_id)}
                                    options={params.values.employees}
                                    isClearable
                                    onChange={params.func.selectResourceAttr('arborist_id')}
                                    placeholder="Arborist"
                            />
                        </Col>
                                        <Col md={6} className={isMobile ? "mt5 bottom15" : "mt5 bottom5"}>
                            <Select className="Select" classNamePrefix="select"
                                    value={select(params.values.proposalOptions, params.values.resource.proposal_id)}
                                    options={params.values.proposalOptions}
                                    isClearable
                                    isMulti
                                    onInputChange={params.func.proposalSearch}
                                    onChange={params.func.selectResourceAttr('proposal_id')}
                                    placeholder="Proposal #"
                            />
                        </Col>
                                        {status === 'backlog' &&
                                            <Col md={6} className={isMobile ? "mt5 bottom15" : "mt5 bottom5"}>
                                                <Select className="Select" classNamePrefix="select"
                                                        isMulti
                                                        value={select(params.values.assignees, params.values.resource.assignee_id)}
                                                        options={params.values.assignees}
                                                        isClearable
                                                        onChange={params.func.selectResourceAttr('assignee_id')}
                                                        placeholder="Assignee"
                                                />
                                            </Col>
                                        }
                                    </Row>
                                     <hr className={'marginBottom10 top10'}/>
                    <Row className="no-margin">
                        <Col md={6} className={isMobile ? "mt5 bottom15" : "mt5 bottom5"}>
                            <Select
                                className="Select"
                                classNamePrefix="select"
                                value={select(params.values.services, params.values.resource.ex_service)}
                                options={params.values.services}
                                isClearable
                                isMulti
                                onChange={params.func.selectResourceAttr('ex_service')}
                                placeholder="Select Excluded Services"
                            />
                        </Col>
                        <Col md={6} className={isMobile ? "mt5 bottom15" : "mt5 bottom5"}>
                            <Select
                                className="Select"
                                classNamePrefix="select"
                                value={select(params.values.priorities, params.values.resource.ex_priority)}
                                options={params.values.priorities}
                                isClearable
                                isMulti
                                onChange={params.func.selectResourceAttr('ex_priority')}
                                placeholder="Select Excluded Priority"
                            />
                        </Col>
                    </Row>
                <Row className="no-margin">
                        <Col md={6} className={isMobile ? "mt5 bottom15" : "mt5 bottom5"}>
                            <Select
                                className="Select"
                                classNamePrefix="select"
                                value={select(params.values.service_types, params.values.resource.service_type)}
                                options={params.values.service_types}
                                isClearable
                                isMulti
                                onChange={params.func.selectResourceAttr('service_type')}
                                placeholder="Select Service Type"
                            />
                        </Col>
                        <Col md={6} className={isMobile ? "mt5 bottom15" : "mt5 bottom5"}>
                            <Select
                                className="Select"
                                classNamePrefix="select"
                                value={select(params.values.service_types, params.values.resource.ex_service_type)}
                                options={params.values.service_types}
                                isClearable
                                isMulti
                                onChange={params.func.selectResourceAttr('ex_service_type')}
                                placeholder="Select Excluded Service Type"
                            />
                        </Col>
                    </Row>
                        <Row className="no-margin">
                        <Col md={6} className={isMobile ? "mt5 bottom15" : "mt5 bottom5"}>
                            <Select className="Select" classNamePrefix="select"
                                    value={select(params.values.equipments, params.values.resource.ex_equipment)}
                                    options={params.values.equipments}
                                    isClearable
                                    isMulti
                                    onChange={params.func.selectResourceAttr('ex_equipment')}
                                    placeholder="Select Excluded Equipment"
                            />
                        </Col>
                        <Col md={6} className={isMobile ? "mt5 bottom15" : "mt5 bottom5"}>
                            <Select className="Select" classNamePrefix="select"
                                    value={select(params.values.customer_types, params.values.resource.ex_customer_type)}
                                    options={params.values.customer_types}
                                    isClearable
                                    isMulti
                                    onChange={params.func.selectResourceAttr('ex_customer_type')}
                                    placeholder="Select Excluded Customer Type"
                            />
                        </Col>
                    </Row>
                <Row className="no-margin">
                    <Col md={12} className={isMobile ? "mt5 bottom15" : "mt5 bottom5"}>
                            <Select className="Select" classNamePrefix="select"
                                    value={select(params.values.customers, params.values.resource.ex_customers)}
                                    options={params.values.customers}
                                    isClearable
                                    isMulti
                                    onChange={params.func.selectResourceAttr('ex_customers')}
                                    placeholder="Select Excluded Customer"
                            />
                        </Col>
                </Row>
                {params.values.isBacklog && <Row className="no-margin">
                    <Col md={12} className={isMobile ? "mt5 bottom15" : "mt5 bottom5"}>
                        <hr className={'marginBottom10 top10'}/>
                        <ColorCheckbox value={params.values.excludeScheduled}
                                       label='Exclude Scheduled'
                                       onChange={params.func.selectCheckboxAttr('excludeScheduled')}
                        />
                    </Col>
                </Row>}
            </span>
        </LeadsFilterModal>
    )
}
