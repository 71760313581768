import {
    ADD_INVOICE_PROPOSAL_SERVICES,
    CHANGED_INVOICED_DATE,
    DELETED_INVOICE,
    FETCH_CUSTOMER_INVOICES,
    FETCH_CUSTOMER_SITES,
    FETCH_INVOICE_PS_INFO,
    FETCH_PROPOSAL_INVOICES,
    FETCH_PROPOSALS,
    FETCH_PS_INFO,
    FETCH_SEARCHED_CUSTOMERS,
    GET_CUSTOMER_BY_ID,
    INVOICE_MARK_PAID,
    NEW_INVOICE,
    POSSIBLE_INVOICE_NUMBER,
    REMOVE_INVOICE_PROPOSAL_SERVICE,
    SELECT_CUSTOMER,
    SELECT_INVOICE,
    SELECT_PROPOSAL,
    UPDATE_CUSTOMER_SEARCH,
    UPDATE_SELECTED_SITE,
    UPDATE_SERVICE_STATUS,
    VERIFY_INVOICE_NUMBER,
} from "./constants"

const initialState = {
    customer: null,
    serviceStatus: null,
    customerSites: null,
    selectedSite: null,
    selectedSiteInfo: null,
    selectedProposal: null,
    customerSearch: null,
    foundCustomers: null,
    proposals: null,
    selectedCustomer: null,
    customerInvoices: [],
    proposalServices: [],
    invoiceProposalServices: [],
    invoice: null,
    invoiceNoVerified: false,
    possibleInvoiceNo: null,
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SELECT_CUSTOMER:
            return {
                ...state,
                selectedCustomer: action.selectedCustomer,
                customerSites: null,
                selectedSite: null,
                selectedSiteInfo: null,
                customerInvoices: [],
                proposals: null,
                proposalServices: [],
                selectedProposalServices: [],
            }
        case ADD_INVOICE_PROPOSAL_SERVICES:
            let invoiceKeys = action.proposalServices.map(ps => ps.id)
            let updatedProposalServices = state.proposalServices.filter(ps => !invoiceKeys.includes(ps.id))
            return {
                ...state,
                invoiceProposalServices: [
                    ...state.invoiceProposalServices,
                    ...action.proposalServices
                ],
                proposalServices: updatedProposalServices
            }
        case REMOVE_INVOICE_PROPOSAL_SERVICE:
            return {
                ...state,
                invoiceProposalServices: state.invoiceProposalServices.filter(ps => ps.id != action.ps.id),
                proposalServices: [...state.proposalServices, action.ps]
            }
        case UPDATE_CUSTOMER_SEARCH:
            return {
                ...state,
                customerSearch: action.customerSearch,
            }
        case FETCH_SEARCHED_CUSTOMERS:
            return {...state, foundCustomers: action.foundCustomers}
        case FETCH_PROPOSALS:
            return {...state, proposals: action.customerProposals}
        case FETCH_PS_INFO:
            return {...state, proposalServices: action.proposalServices}
        case FETCH_INVOICE_PS_INFO:
            return {...state, invoiceProposalServices: action.invoiceProposalServices}
        case UPDATE_SELECTED_SITE:
            return {
                ...state, selectedSite: action.selectedSite,
                invoice: null,
                invoiceProposalServices: [],
                selectedProposal: null,
                proposals: []
            };
        case FETCH_CUSTOMER_SITES:
            return {...state, customerSites: action.customerSites}
        case UPDATE_SERVICE_STATUS:
            return {...state, serviceStatus: action.serviceStatus}
        case FETCH_CUSTOMER_INVOICES:
        case FETCH_PROPOSAL_INVOICES:
            return {
                ...state,
                customerInvoices:
                    [
                        ...action.customerInvoices
                    ]
            };
        case SELECT_PROPOSAL:
            return {
                ...state,
                selectedProposal: action.selectedProposal,
                invoice: action.invoice,
                invoiceProposalServices: action.invoiceProposalServices ? action.invoiceProposalServices : []
            };
        case SELECT_INVOICE:
            if (action.invoice) {
                return {
                    ...state,
                    invoice: state.customerInvoices.find(i => i.id === action.invoice.value)
                }
            } else {
                return {
                    ...state,
                    invoiceProposalServices: [],
                    invoice: null,
                }
            }
        case INVOICE_MARK_PAID:
            let customerInvoices = state.customerInvoices
            let changedInvoices = []
            if (action.payload.invoice_ids) {

                action.payload.invoice_ids.forEach(invoiceId => {
                    let index = customerInvoices.findIndex(invoice => invoice.id === invoiceId)
                    if (index > -1) {
                        customerInvoices[index] = {...customerInvoices[index], paid_at: action.payload.paid_date}
                    }
                })
            }
            return {
                ...state,
                customerInvoices: [
                    ...customerInvoices,
                    ...changedInvoices,
                ]
            }
        case CHANGED_INVOICED_DATE:
            let customerInvoice = state.customerInvoices
            let index = customerInvoice.findIndex(invoice => invoice.id === action.payload.invoice_id)
            if (index > -1) {
                customerInvoice[index] = {...customerInvoice[index], invoiced_at: action.payload.invoiced_at}
            }
            return {
                ...state
            }
        case NEW_INVOICE:
            return {
                ...state,
                invoice: action.payload.invoice,
                // invoiceProposalServices: action.payload.proposalServices,
            }
        case DELETED_INVOICE:
            return {
                ...state,
                invoice: null,
                invoiceProposalServices: [],
            }
        case GET_CUSTOMER_BY_ID:
            /*we can load the page via customer id or search for customer in the page. simulate a search so the ui is happy*/
            return {
                ...state,
                customer: action.customer,
                foundCustomers: [
                    {id: action.customer.id, name: action.customer.name},
                ],
                sitesAssets: null,
            }
        case POSSIBLE_INVOICE_NUMBER:
            return {
                ...state,
                possibleInvoiceNo: action.payload.maximum + 1
            }
        case VERIFY_INVOICE_NUMBER:
            return {
                ...state,
                invoiceNoVerified: action.payload.verified,
                possibleInvoiceNo: action.payload.invoiceNo
            }
        default:
            return state
    }
}
