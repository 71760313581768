import {
    FETCH_CUSTOMER_SITES,
    FETCH_CUSTOMER_WORK_ORDERS,
    FETCH_SEARCHED_CUSTOMERS,
    FETCH_SELECTED_SITEINFO,
    FETCH_SITES_ASSETS,
    GET_CUSTOMER_BY_ID,
    SELECT_CUSTOMER,
    UPDATE_CUSTOMER_SEARCH,
    UPDATE_SELECTED_SITE,
    UPDATE_SERVICE_STATUS,
} from "./constants"

import {EDITING_ASSET_ID, FETCHED_SITE_ASSETS,} from "../../MapViewPage/constants"

import axios from "axios"
import {addAlert} from "../../App/actions"
import {fetchProposalServiceStatuses,} from "../../ProposalServiceStatusPage/actions"
import {browserHistory} from "react-router"
import {doneEditingAsset, fetchDbhs, onMarkerClick, updateAssetAttribute,} from "../../MapViewPage/actions"

export const updateServiceStatus = serviceStatus => {
    return {type: UPDATE_SERVICE_STATUS, serviceStatus}
}

export {onMarkerClick}
export {doneEditingAsset}
export {updateAssetAttribute}

export const updateSelectedSite = selectedSite => dispatch => {
    dispatch({type: UPDATE_SELECTED_SITE, selectedSite})
    dispatch(fetchSelectedSiteInfo())
    dispatch(fetchCustomerAssets())
}

export const updateCustomerSearch = customerSearch => dispatch => {
    dispatch({type: UPDATE_CUSTOMER_SEARCH, customerSearch})
    dispatch(searchForCustomer())
}

export const updateSelectedCustomer = selectedCustomer => dispatch => {
    dispatch({type: SELECT_CUSTOMER, selectedCustomer})
    browserHistory.push(`/customer/work_orders/${selectedCustomer.value}`) //will cause page to kick off api calls
}

export const dismissAssetEditorModal = () => ({
    type: EDITING_ASSET_ID,
    editingAssetHistoryId: null,
})

export const applyHistoryFilter = () => (dispatch, getState) => {
    const store = getState()
    dispatch(fetchCustomerById(store.customerProposals.customer.id))
}

export const fetchCustomerAssets = () => async (dispatch, getState) => {
    const store = getState()
    const selectedSite = store.customerWorkOrders.selectedSite

    let siteIds = []
    if (selectedSite === null) {
        siteIds = store.customerWorkOrders.customerSites.map(site => site.id)
    } else {
        siteIds = [selectedSite]
    }

    if (siteIds.length === 0) {
        console.info(
            "There are no Site Ids to fetch Assets for. This customer probably doesn't have a Site. Sending API request anyway."
        )
    }

    const config = {
        method: "get",
        url: `/api/assets`,
        headers: {Authorization: store.auth.token},
        params: {
            site_id: siteIds,
        },
    }

    try {
        const response = await axios.request(config)
        dispatch({type: FETCH_SITES_ASSETS, sitesAssets: response.data})
        dispatch({type: FETCHED_SITE_ASSETS, siteAssets: response.data}) //mapview which is what asset editor depends on :(
        dispatch(fetchDbhs()) //asset editor depends on this too
        dispatch(fetchCustomerWorkOrders(siteIds))
    } catch (exception) {
        dispatch(
            addAlert({
                message: "Unable to fetch the customer because " + exception.response?.data?.error,
            })
        )
        throw exception
    }
}

export const fetchCustomerById = customerId => async (dispatch, getState) => {
    const store = getState()

    const config = {
        url: `/api/customers/${customerId}/info`,
        headers: {Authorization: store.auth.token},
    }

    try {
        const response = await axios.request(config)

        dispatch({type: GET_CUSTOMER_BY_ID, customer: response.data})
        // dispatch(fetchProposals(customerId))
        dispatch(fetchProposalServiceStatuses("all"))
        const _ = await dispatch(fetchCustomerSites())
        dispatch(fetchCustomerAssets())
    } catch (exception) {
        dispatch(
            addAlert({
                message: "Unable to fetch the customer because " + exception.toString(),
            })
        )
        throw exception
    }
}

const fetchCustomerSites = (siteIds) => async (dispatch, getState) => {
    const store = getState()
    const customerId = store.customerWorkOrders.customer.id
    const config = {
        url: "/api/sites",
        headers: {Authorization: store.auth.token},
        params: {customer_id: customerId},
    }

    try {
        const response = await axios.request(config)
        // dispatch(fetchCustomerWorkOrders(siteIds))
        dispatch({type: FETCH_CUSTOMER_SITES, customerSites: response.data})
    } catch (exception) {
        dispatch(
            addAlert({
                message: "Unable to fetch customer sites because " +
                    exception.toString(),
            })
        )
        throw exception
    }
}

const fetchCustomerWorkOrders = (siteIds) => async (dispatch, getState) => {
    const store = getState()
    const selectedSite = store.customerWorkOrders.selectedSite

    const config = {
        url: `/api/work_orders/customer_work_orders/`,
        headers: {Authorization: store.auth.token},
        params: {
            site_id: siteIds,
        },
    }

    try {
        const response = await axios.request(config)
        dispatch({type: FETCH_CUSTOMER_WORK_ORDERS, customerWorkOrders: response.data})
    } catch (exception) {
        dispatch(
            addAlert({
                message: "Unable to fetch proposals because " +
                    exception.toString(),
            })
        )
        throw exception
    }
}

const fetchSelectedSiteInfo = () => async (dispatch, getState) => {
    const store = getState()
    const selectedSite = store.customerProposals.selectedSite
    if (isNaN(parseInt(selectedSite, 10))) {
        return false
    }

    const config = {
        url: `/api/sites/${selectedSite}`,
        headers: {Authorization: store.auth.token},
    }

    try {
        const response = await axios.request(config)
        dispatch({type: FETCH_SELECTED_SITEINFO, selectedSiteInfo: response.data})
    } catch (exception) {
        dispatch(
            addAlert({
                message: "Unable to fetch customer site because " +
                    exception.toString(),
            })
        )
        throw exception
    }
}

export const searchForCustomer = () => async (dispatch, getState) => {
    const store = getState()
    const searchQuery = store.customerProposals.customerSearch

    if (searchQuery.length < 2) {
        return false
    }

    const config = {
        method: "get",
        url: `/api/customers`,
        headers: {Authorization: store.auth.token},
        params: {
            q: searchQuery,
        },
    }
    try {
        const response = await axios.request(config)
        dispatch({
            type: FETCH_SEARCHED_CUSTOMERS,
            foundCustomers: response.data,
        })
    } catch (exception) {
        dispatch(
            addAlert({
                message: "Unable to search for customers because " +
                    exception.toString(),
            })
        )
        throw exception
    }
}
