import React, {useEffect, useState} from "react";
import './ResetPasswordPage.scss'
import Logo from "../HomePage/HomePageLogo.png";
import {Button, ControlLabel, FormControl, FormGroup, InputGroup, OverlayTrigger, Tooltip} from "react-bootstrap";
import * as actions from './ResetPasswordPageApi'
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {browserHistory} from "react-router";
import InputGroupAddon from "react-bootstrap/lib/InputGroupAddon";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";

const ResetPasswordPage = ({params, actions}) => {
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [errors, setErrors] = useState({password: "", confirmPassword: ""})
    const [showPassword, setShowPassword] = useState(false)
    const [successfulChange, setSuccessfulChange] = useState(false)


    const handlePasswordChange = (e) => {
        e.preventDefault()
        let err = errors;
        const {value} = e.target;
        setPassword(value)
        err.password =
            value.length < 6
                ? 'Password must be 6 characters long.'
                : '';
        err.confirmPassword =
            confirmPassword !== value
                ? "Passwords don't match."
                : '';
        setErrors(err);
    }

    const handleConfirmPasswordChange = (e) => {
        e.preventDefault()
        let err = errors;
        const {value} = e.target;
        setConfirmPassword(value)
        err.confirmPassword =
            password !== value
                ? "Passwords don't match."
                : '';
        setErrors(err);
    }

    const handleShowPasswordChanger = () => {
        return (
            <OverlayTrigger placement="top"
                            onClick={() => setShowPassword(!showPassword)}
                            overlay={<Tooltip
                                id="reset_password_tooltip">{showPassword ? "Hide password" : "Show password"}</Tooltip>}>
                {showPassword ?
                    <p className="no-margin pointer"><FontAwesomeIcon icon={faEye}/></p>
                    :
                    <p className="no-margin pointer"><FontAwesomeIcon icon={faEyeSlash}/></p>}
            </OverlayTrigger>
        )
    }

    useEffect(() => {
        if (successfulChange) {
            setTimeout(() => browserHistory.push('/'), 3000)
        }
    }, [successfulChange])

    return (
        <div id="reset_password_page">
            <div className="reset_password_wrapper">
                <div>
                    <img src={Logo} alt='Logo' className="logo"/>
                </div>
                {successfulChange ?
                    <h1>Successful change password!</h1>
                    :
                    <div className="inputs-wrapper">
                        <div>
                            <h3>Enter your new password</h3>
                        </div>
                        <div>
                            <FormGroup>
                                <ControlLabel>
                                    Password
                                </ControlLabel>
                                <InputGroup>
                                    <FormControl
                                        type={showPassword ? "text" : "password"}
                                        placeholder='Password'
                                        autoComplete="new-password"
                                        value={password}
                                        onChange={e => handlePasswordChange(e)}
                                    />
                                    <InputGroupAddon>
                                        {handleShowPasswordChanger()}
                                    </InputGroupAddon>
                                </InputGroup>
                                {errors.password.length > 0 &&
                                    <span className='error'>{errors.password}</span>}
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>
                                    Password Confirmation
                                </ControlLabel>
                                <InputGroup>
                                    <FormControl
                                        type={showPassword ? "text" : "password"}
                                        placeholder='Password Confirmation'
                                        value={confirmPassword}
                                        onChange={e => handleConfirmPasswordChange(e)}
                                    />
                                    <InputGroupAddon>
                                        {handleShowPasswordChanger()}
                                    </InputGroupAddon>
                                </InputGroup>
                                {errors.confirmPassword.length > 0 &&
                                    <span className='error'>{errors.confirmPassword}</span>}
                            </FormGroup>
                        </div>
                        <div className="buttons">
                            <Button
                                bsStyle="success"
                                disabled={errors.confirmPassword.length > 0 || errors.password.length > 0 || !password.length > 0}
                                onClick={() => actions.changeUserPassword(params.token, {
                                    password,
                                    confirmPassword
                                }, () => setSuccessfulChange(true))}>
                                Save
                            </Button>
                        </div>
                    </div>}
            </div>
        </div>
    )
}

const mapDispatchToProps = dispatch => ({actions: bindActionCreators(actions, dispatch),})

export default connect(undefined, mapDispatchToProps)(ResetPasswordPage)