import {
    FETCH_INVOICES,
    NEW_INVOICE,
    UPDATE_INVOICED_END,
    UPDATE_INVOICED_START,
    UPDATE_INVOICES,
    UPDATE_STATUS,
} from "./constants"

const initialState = {
    invoices: [],
    invoicedStart: null,
    invoicedEnd: null,
    status: "all",
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_INVOICED_END:
            return {
                ...state,
                invoicedEnd: action.invoicedEnd,
                invoices: null,
            }
        case UPDATE_INVOICED_START:
            return {
                ...state,
                invoicedStart: action.invoicedStart,
                myProposals: null,
            }
        case FETCH_INVOICES:
            return {...state, invoices: action.invoices}
        case UPDATE_STATUS:
            return {...state, status: action.status}
        case NEW_INVOICE:
            return {
                ...state,
                invoice: action.payload.invoice,
                // invoiceProposalServices: action.payload.proposalServices,
            }
        case UPDATE_INVOICES:
            let invoices = state.invoices
            if (action.payload.invoice_ids) {

                action.payload.invoice_ids.forEach(invoiceId => {
                    let index = invoices.findIndex(invoice => invoice.id === invoiceId)
                    if (index > -1) {
                        invoices[index] = {...invoices[index], paidAt: action.payload.paid_date}
                    }
                })
            }
            return {
                ...state,
                invoices: [
                    ...invoices,
                ]
            }
        default:
            return state
    }
}
export default reducer
