import React, {Component} from 'react';
import PropTypes from "prop-types"
import {Col, Grid, MenuItem, Nav, NavDropdown, Row,} from "react-bootstrap"

import Dollars from "../../components/Dollars"
import ThDate from "../../components/ThDate"

import {LinkContainer} from "react-router-bootstrap"
import PlantName from "../../components/PlantName";
import {sortAssetsLabels} from "../../common/commonHandlers";

export class InvoicePrint extends Component {

    renderServices = invoice => {
        return (
            <div>
                <br/>
                <Row>
                    <Col md={12}>

                    </Col>
                </Row>
                <Row>
                    <Col xs={1}>
                        <strong>
                            <small><u>#</u></small>
                        </strong>
                    </Col>
                    <Col xs={9}>
                        <strong>
                            <small><u>Service Description</u></small>
                        </strong>
                    </Col>
                    <Col xs={2} className="text-right">
                        <strong>
                            <small><u>Amount</u></small>
                        </strong>
                    </Col>

                </Row>

                {invoice.proposal_services &&
                    invoice.proposal_services
                        .slice()
                        .sort((a, b) => a.service_no - b.service_no)
                        .map(ps => {
                            return (
                                <div key={ps.service_no}>
                                    <Row className="avoid_page_breaking_inside">
                                        <Col xs={1}>
                                            {ps.service_no}
                                        </Col>

                                        <Col xs={9} className="preserve-text-area-format">

                                            <strong>{ps.service_name}</strong>{" "}
                                            <small>Completed On: <ThDate dateString={ps.completed_on}/>{" "}</small>
                                            <br/>

                                            {ps.proposal_service_note}

                                        </Col>

                                        <Col xs={2} className="assetRow text-right">
                                            {<Dollars amount={ps.price}/>}
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col xs={1}>
                                        </Col>
                                        <Col xs={9}>

                                            {ps.assets
                                                .sort((a, b) => sortAssetsLabels(a, b, 'asset_label'))
                                                .map((a, i) => (
                                                    <div key={a.asset_label}
                                                         className={"inline-assets"}>
                                                        <strong>
                                                            #{a.asset_label}{" "}<PlantName plant={a.name}/>{" "}
                                                        </strong>

                                                        {a.plant_count > 1 ? ` ${a.plant_count} Trees` : null}

                                                        {a.location
                                                            ? ` Location: ${a.location}`
                                                            : null}
                                                        {""}
                                                        {a.note && a.note.length > 1
                                                            ? ` Note: ${a.note}  ` : null}
                                                        {console.log(i)}

                                                    </div>
                                                ))}
                                        </Col>
                                        <Col xs={2}>
                                        </Col>
                                    </Row>
                                    <p>&nbsp;</p>
                                </div>
                            )
                        })}
            </div>
        )
    };

    renderPageHeader = invoice => {
        return (
            <Row>
                <Col xs={4}>
                    <h4 style={{marginTop: '0px', marginBottom: '0px'}}>
                        <Nav>
                            <NavDropdown
                                title={`${invoice.customer_name}`}
                                id="customer-print-proposal"
                                className="pull-left"
                            >
                                <LinkContainer style={{paddingBottom: 0}}
                                               to={`/customer/sites/${invoice.customer_id}`}>
                                    <MenuItem>Customer Sites</MenuItem>
                                </LinkContainer>
                                <LinkContainer to={`/customer/info/${invoice.customer_id}`}>
                                    <MenuItem>Customer Info</MenuItem>
                                </LinkContainer>
                                <LinkContainer to={`/customer/work_history/${invoice.customer_id}`}>
                                    <MenuItem>Customer Work History</MenuItem>
                                </LinkContainer>
                                <LinkContainer to={`/customer/proposals/${invoice.customer_id}`}>
                                    <MenuItem>Customer Proposals</MenuItem>
                                </LinkContainer>
                                <LinkContainer to={`/customer/work_orders/${invoice.customer_id}`}>
                                    <MenuItem>Customer Work Orders</MenuItem>
                                </LinkContainer>
                            </NavDropdown>
                        </Nav>
                    </h4>
                    <div className="pull-left">
                        {invoice.customer_address_1}
                        <br/>
                        {invoice.customer_address_2 ? invoice.customer_address_2 : null}
                        {invoice.customer_address_2 ? <br/> : null}
                        {invoice.customer_city}, {invoice.customer_state}{" "}
                        {invoice.customer_zip}
                        <br/>
                        Phone: <a className="ml-4" style={{color: "green"}}
                                  href={"tel:" + invoice.customer_phone.replace(/\D/g, '')}>{invoice.customer_phone}</a>

                    </div>
                </Col>

                <Col xs={4}>
                    <div className="pull-left">
                        <strong>Site:</strong>
                        <br/>
                        {invoice.site_name}
                        <br/>
                        {invoice.site_street}
                        {invoice.site_city_state}
                        <br/>
                        Email:
                        {" "}
                        <a media="screen"
                           href={`mailto:${invoice.customer_email_address}`}>{invoice.customer_email_address}</a>
                    </div>
                </Col>

                <Col xs={4} className="text-right">
                    Arborist:{" "}
                    {invoice.arborist}
                    <br/>
                    <h6 style={{marginTop: 0, marginBottom: 0}}>
                        <Nav>
                            <NavDropdown
                                title={`Invoice No. ${invoice.invoice_no}`}
                                id="customer-print-proposal"
                                className="pull-right">
                                <LinkContainer
                                    to={`/customer/manage_invoices/${invoice.customer_id}?invoice=${invoice.id}`}>
                                    <MenuItem>View Invoice</MenuItem>
                                </LinkContainer>
                                <LinkContainer to={`/mapview/${invoice.proposal_id}`}>
                                    <MenuItem>Edit Proposal</MenuItem>
                                </LinkContainer>
                                <LinkContainer to={`/proposal_service_status/${invoice.proposal_id}`}>
                                    <MenuItem>Proposal Statuses</MenuItem>
                                </LinkContainer>
                                <LinkContainer to={`/work_orders/${invoice.proposal_id}`}>
                                    <MenuItem>Manage Work Orders</MenuItem>
                                </LinkContainer>
                            </NavDropdown>
                        </Nav>
                    </h6>
                    <div className="pad-left">
                        <small>Invoiced On: <ThDate dateString={invoice.invoiced_at}/></small>
                        <br/>
                        <h4 className='text-danger'>
                            {invoice.paid_at === null ? `Due On:` : `Paid On:`}{" "}
                            {invoice.paid_at === null ? <ThDate dateString={invoice.invoiced_at}/> :
                                <ThDate dateString={invoice.paid_at}/>}
                        </h4>
                    </div>

                </Col>

            </Row>
        )
    };

    renderProposalTotal = invoice => {
        if (invoice.proposal_services === undefined) {
            return null
        }

        const total = invoice.proposal_services.reduce((total, ps) => {
            return total + (ps.status !== "Not Offered" ? ps.price : 0)
        }, 0);

        return (
            <Row className="avoid_page_breaking_inside">
                <hr/>
                <Col xs={12} className="text-right">
                    <p>
                        <strong>
                            Subtotal: {<Dollars amount={total}/>}
                        </strong>
                    </p>

                    <p>
                        <strong>
                            Tax: {<Dollars amount={total * invoice.tax}/>}
                        </strong>
                    </p>

                    <p>
                        <strong>
                            Balance Due: {<Dollars amount={total + total * invoice.tax}/>}
                        </strong>
                    </p>
                </Col>
            </Row>
        )
    };

    renderClientHeader = invoice => (
        <Row>
            <Col xs={9} className="text-center">
                <img
                    alt="company header"
                    className="logoHeader"
                    src={invoice.client_header}
                />
            </Col>
            <Col xs={3}>
                <h4 className="text-primary text-right">
                    {invoice.client_name}
                </h4>
                <h6 className="text-primary text-right">
                    {invoice.client_phone}
                    <br/>
                    {invoice.client_email}
                    <br/>
                    {invoice.client_address}
                    <br/>
                    {invoice.client_city}, {invoice.client_state}
                    <br/>
                    {invoice.client_zip}
                    <br/>
                </h6>
            </Col>
        </Row>
    );

    render() {
        const {invoice} = this.props;
        return (
            <Grid>
                <Row>
                    <Col xs={12}>
                        {this.renderClientHeader(invoice)}

                        <h3 className="text-success">
                            Invoice
                        </h3>

                        {this.renderPageHeader(invoice)}

                        {this.renderServices(invoice)}

                        {this.renderProposalTotal(invoice)}

                    </Col>
                </Row>
            </Grid>
        )
    }
}

InvoicePrint.propTypes = {
    invoice: PropTypes.object.isRequired,
    options: PropTypes.object
};
