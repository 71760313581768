import {FETCH_INVOICES, UPDATE_INVOICED_END, UPDATE_INVOICED_START, UPDATE_INVOICES, UPDATE_STATUS,} from "./constants"

import {addAlert} from "../App/actions"
import axios from "axios"
import moment from "moment"

export const updateInvoicedStart = invoicedStart => {
    invoicedStart = moment(invoicedStart).isValid()
        ? moment(invoicedStart).format()
        : null

    return {type: UPDATE_INVOICED_START, invoicedStart}
}

export const updateInvoicedEnd = invoicedEnd => {
    invoicedEnd = moment(invoicedEnd).isValid()
        ? moment(invoicedEnd).endOf('day').format()
        : null
    return {type: UPDATE_INVOICED_END, invoicedEnd}
}

export const updateInvoiceTypeSelection = e => dispatch => {
    dispatch({type: FETCH_INVOICES, invoices: []})
    dispatch({type: UPDATE_STATUS, status: e.target.value})
}

export const markInvoicesPaid = (invoiceIds, paidDate) => async (dispatch, getState) => {
    const store = getState()
    const token = store.auth.token


    const config = {
        method: "POST",
        url: `/api/invoices/mark_invoice_paid`,
        headers: {Authorization: store.auth.token},
        params: {
            invoice_ids: invoiceIds.join(','),
            paid_at: paidDate
        },
    }

    try {
        await axios.request(config)
        dispatch({
            type: UPDATE_INVOICES,
            payload: {invoice_ids: invoiceIds, paid_date: paidDate}
        })
    } catch (exception) {
        dispatch(
            addAlert({
                message: "Unable to mark invoices paid " +
                    exception.toString(),
            })
        )
        throw exception
    }
}

export const fetchInvoices = () => async (
    dispatch,
    getState
) => {
    dispatch(
        addAlert({
            message: "This might take a minute or two.",
            mood: "info",
            dismissAfter: 1500,
        })
    )
    const store = getState()

    const config = {
        url: `/api/invoices`,
        headers: {Authorization: store.auth.token},
        params: {
            start_date: store.invoices.invoicedStart,
            end_date: store.invoices.invoicedEnd,
            status: store.invoices.status,
        },
    }

    try {
        const response = await axios.request(config)
        dispatch({type: FETCH_INVOICES, invoices: response.data})
        if (response.data && response.data.length === 0) {
            dispatch(
                addAlert({
                    message: "No invoices returned. Please change your query and try again.",
                    mood: "warning",
                    dismissAfter: 3500,
                })
            )
        }
    } catch (error) {
        dispatch(
            addAlert({
                message: "Couldn't fetch invoices because: " + error.toString(),
                mood: "danger",
                dismissAfter: 5000,
            })
        )
        throw error
    }
}
