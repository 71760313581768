import React, {Component} from 'react';
import PropTypes from "prop-types"
import {Button, Col, ControlLabel, FormControl, FormGroup, Row,} from 'react-bootstrap'
import Select from 'react-select'
import moment from 'moment'
import {defaultDateFormat, mapForSelect, select} from "../../common/commonHandlers";
import Datetime from "react-datetime";

export default class ProposalForm extends Component {
    static propTypes = {
        salesArborists: PropTypes.array.isRequired,
        salesArboristSearch: PropTypes.string.isRequired,
        proposal: PropTypes.object.isRequired,
    }

    contextTypes = {
        router: PropTypes.object
    };

    componentWillMount() {
        this.props.loadSalesArborists()
    }

    onProposalSubmit = (e) => {
        e.preventDefault()
        this.props.submitProposal(this.props.leadId)
        this.props.toggleProposalInfoPanelState()
        this.props.closeProposalInfoPanelState()
    }

    updateProposalDate(e) {
        console.log(e)
    }

    confirmDelete() {
        const choice = window.confirm('Are you sure you want to delete this proposal?')

        if (choice) {
            this.props.deleteProposal()
        }
    }

    render() {
        const {
            salesArborists,
            selectSalesArborist,

            selectedCustomer,
            customerList,
            updateCustomerSearch,
            onSelectedCustomer,

            customerSites,
            onSelectSite,

            proposal,
            updateAttribute,
        } = this.props
        const salesArboristsOptions = mapForSelect(salesArborists);
        const customersOptions = customerList;
        const customerSitesOptions = mapForSelect(customerSites);
        return (
            <form onSubmit={e => this.onProposalSubmit(e)}>
                <Row>
                    <Col xs={12}>
                        <Row>
                            <Col xs={3}>
                                <FormGroup bsSize="sm">
                                    <ControlLabel>Sales Arborist</ControlLabel>
                                    <br/>

                                    <Select className="Select" classNamePrefix="select"
                                            name="sales-arborist-select"
                                            value={select(salesArboristsOptions, proposal.salesArboristId)}
                                            options={salesArboristsOptions}
                                            onChange={selectSalesArborist}
                                            clearable={false}
                                            placeholder="Sales Arborist"
                                    />
                                </FormGroup>

                                <FormGroup bsSize="sm">
                                    <ControlLabel>Customer</ControlLabel>
                                    <br/>
                                    <h4 className="text-primary">
                                        {proposal.id
                                            ? proposal.customerName
                                            : <Select className="Select" classNamePrefix="select"
                                                      name="whatever-bullshit-really"
                                                      value={select(customersOptions, selectedCustomer.value)}
                                                      options={customersOptions}
                                                      onInputChange={updateCustomerSearch}
                                                      onChange={onSelectedCustomer}
                                                      clearable={false}
                                                      placeholder="Customer"
                                            />}
                                    </h4>
                                </FormGroup>
                            </Col>
                            <Col xs={3}>
                                <FormGroup bsSize="sm">
                                    <ControlLabel>Proposal Date</ControlLabel>
                                    <br/>
                                    <Datetime
                                        dateFormat={defaultDateFormat}
                                        isClearable
                                        timeFormat={null}
                                        value={moment(proposal.proposalDate)}
                                        inputProps={{placeholder: 'Proposal Date'}}
                                        onChange={m =>
                                            updateAttribute('proposalDate', moment(m).format())}/>
                                </FormGroup>
                                <FormGroup bsSize="sm">
                                    <ControlLabel>Site</ControlLabel>
                                    <br/>
                                    <h4 className="text-primary">
                                        {proposal.id
                                            ? proposal.siteName
                                            : <Select className="Select" classNamePrefix="select"
                                                      name="site-select"
                                                      value={select(customerSitesOptions, proposal.siteId)}
                                                      options={customerSitesOptions}
                                                      onChange={onSelectSite}
                                                      clearable={false}
                                                      placeholder="Customer Site"
                                            />}
                                    </h4>
                                </FormGroup>
                            </Col>

                            <Col xs={3}>
                                <FormGroup bsSize="sm">
                                    <ControlLabel>Accepted On</ControlLabel>
                                    <br/>
                                    {proposal.acceptedDate
                                        ? moment(proposal.acceptedDate).format('llll')
                                        : 'Not Accepted Yet'}
                                    <p>&nbsp;</p>
                                    <ControlLabel>Site Contact</ControlLabel>
                                    <br/>
                                    <h4 className="text-primary">
                                        {proposal.siteContact}
                                    </h4>
                                </FormGroup>
                            </Col>
                            <Col xs={3}>
                                <FormGroup bsSize="sm">
                                    <ControlLabel>Purchase Order</ControlLabel>
                                    <br/>
                                    <FormControl
                                        type="text"
                                        placeholder="Purchase Order"
                                        value={proposal.purchaseOrder || ''}
                                        onChange={e => updateAttribute('purchaseOrder', e.target.value)}
                                    />
                                </FormGroup>
                                <FormGroup bsSize="sm">
                                    <ControlLabel>Tax</ControlLabel>
                                    <br/>
                                    <FormControl
                                        type="text"
                                        placeholder="Tax"
                                        value={proposal.tax || ''}
                                        onChange={e => updateAttribute('tax', e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <FormGroup bsSize="sm">
                            <ControlLabel>Proposal Note</ControlLabel>
                            <br/>
                            <FormControl
                                componentClass="textarea"
                                placeholder="Proposal Note"
                                maxLength={2000}
                                value={proposal.proposalNote}
                                onChange={e => updateAttribute('proposalNote', e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        {proposal.deleteable
                            ? <Button
                                bsSize="small"
                                className="pull-left"
                                onClick={() => this.confirmDelete()}
                                bsStyle="danger">
                                Delete Proposal
                            </Button>
                            : <Button bsSize="small" disabled bsStyle="danger">
                                Not Deleteable
                            </Button>}

                        <Button
                            bsSize="small"
                            className="pull-right"
                            onClick={e => this.onProposalSubmit(e)}
                            bsStyle="success">
                            Save & Continue Editing
                        </Button>
                    </Col>
                </Row>
            </form>
        )
    }
}
