import {apiCall} from "../../common/apiActionHandler";
import axios from "axios/index";
import {geocodeAddress} from "../SiteEditorModal/actions";
import {addAlert} from "../App/actions";

export const addressesFormater = (customer, coordsAddress1 = customer?.coords_address_1, coordsAddress2 = customer?.coords_address_2) => (dispatch, getState) => {
    return [
        {
            id: 1,
            name: `${customer.address_1 && customer.address_1}, ${customer.city && customer.city}, ${customer.state && customer.state}, ${customer.zip && customer.zip}`,
            latitude: coordsAddress1 && coordsAddress1.lat,
            longitude: coordsAddress1 && coordsAddress1.lng,
            full_name: `Address 1 - ${customer.address_1 && customer.address_1}, ${customer.city && customer.city}, ${customer.state && customer.state}, ${customer.zip && customer.zip}`
        },
        customer.address_2 && {
            id: 2,
            name: `${customer.address_2 && customer.address_2}, ${customer.city && customer.city}, ${customer.state && customer.state}, ${customer.zip && customer.zip}`,
            latitude: coordsAddress2 && coordsAddress2?.lat,
            longitude: coordsAddress2 && coordsAddress2?.lng,
            full_name: `Address 2 - ${customer.address_2 && customer.address_2}, ${customer.city && customer.city}, ${customer.state && customer.state}, ${customer.zip && customer.zip}`
        }
    ].filter(n => n)
}

export const getLead = (id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/leads/${id}`
    };

    apiCall("Loading lead", config, async config => {
        const result = await axios.request(config);
        callback(result.data);
    }, dispatch, getState, false);
};

export const getAddressCoordination = (result, callback) => (dispatch, getState) => {
    const getCoords = (customers) => {
        customers.map(customer => {
            if (customer.sites.length === 0) {
                customer.addresses = addressesFormater(customer)(dispatch, getState).map(async (address, i) => {
                    try {
                        if (address !== undefined) {
                            await geocodeAddress(address, getState().auth.token).then(coords => {
                                if (coords.length > 0) {
                                    const latitude = coords[0].geometry.location.lat
                                    const longitude = coords[0].geometry.location.lng
                                    customer.addresses[i] = {...address, latitude, longitude}
                                } else {
                                    customer.addresses[i] = {...address}
                                }
                            })
                        }

                    } catch (error) {
                        dispatch(
                            addAlert({
                                message: "Unable to geocode the address." +
                                    error.toString(),
                                mood: "danger",
                                dismissAfter: 3500,
                            })
                        )
                        throw error
                    }
                })
            }
        })
        return customers
    }
    result.customerSearchResults = getCoords(result.customerSearchResults)
    callback(result)
}