import React from 'react';
import {Button, Col, FormControl, Grid, MenuItem, Nav, NavDropdown, Panel, Row,} from "react-bootstrap"
import {LinkContainer} from "react-router-bootstrap"
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table"
import moment from "moment/moment"
import {dateFormatter} from "../../components/BootStrapTableCellFormatters";
import {optionsFor, saveNewCsvDownload, select} from "../../common/commonHandlers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as actions from "./NewActivitiesApi";
import PageHeader from "../../components/PageTitle";
import Select from "react-select";
import ResourceComponent from "../../components/ResourceComponent";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import Helmet from "react-helmet";
import {debounce} from "throttle-debounce";
import "./NewActivities.css";

class ActivitiesSearch extends ResourceComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {
            activities: [],
            isFiltered: true,
            resource: {
                sort: 'DESC',
                sort_by: 'created_at',
                page: 1,
                per_page: 25,
                customer_id: []
            },
            showFilters: false,
            employees: [],
            total: 0
        };
        this.customerSearch = {
            searchOptions: () => [],
            search: () => {
            }
        };
    };

    tableRef = React.createRef()

    toggleColumnFilters = () => {
        this.setState({
            showFilters: !this.state.showFilters
        })
    };

    componentDidMount() {
        this.customerSearch = this.buildSearchComponent('customer_id', this.props.actions.searchForCustomer);
        this.props.actions.load(result => {
            this.setState(result);
            this.reload();
        });
    }

    reload = () => {
        this.props.actions.getActivities(this.state.resource, result => {
            this.setState({
                activities: result.content,
                total: result.total
            })
        });
    };

    render() {
        const {activities, total, resource, employees} = this.state;
        const {page, per_page, sort, sort_by} = resource;
        const showFilters = this.state.showFilters;
        const customerOptions = this.customerSearch.searchOptions();
        const actionTypes = optionsFor('Customer message', 'Proposal email',
            'Bulk email', 'Invoice email', 'User message', 'Follow up', 'Notification email',
            'Scheduler confirmation email', 'Proposal confirmation', 'Acceptance all',
            'Acceptance', 'Unacceptance', 'Pdf download', 'Customer action', 'Proposal delete'
        );

        const _linkFormatter = (cell, row) => (
            <Nav>
                {row.customer === "None" ? row.customer :
                    <NavDropdown
                        eventKey={2}
                        title={`${row.customer}`}
                        id={`proposal-menu`}
                        className="menu-show-fixer proposal-menu-padding-reset"
                    >
                        <LinkContainer to={`/customer/info/${row.customer_id}`}>
                            <MenuItem bsSize="small">Info</MenuItem>
                        </LinkContainer>
                        <LinkContainer to={`/customer/proposals/${row.customer_id}`}>
                            <MenuItem bsSize="small">Proposals</MenuItem>
                        </LinkContainer>
                        <LinkContainer to={`/customer/sites/${row.customer_id}`}>
                            <MenuItem bsSize="small">Sites</MenuItem>
                        </LinkContainer>
                        <LinkContainer to={`/customer/work_orders/${row.customer_id}`}>
                            <MenuItem bsSize="small">Work Orders</MenuItem>
                        </LinkContainer>
                        <LinkContainer to={`/customer/invoices/${row.customer_id}`}>
                            <MenuItem bsSize="small">Invoices</MenuItem>
                        </LinkContainer>
                        <LinkContainer to={`/customer/work_history/${row.customer_id}`}>
                            <MenuItem bsSize="small">Work History</MenuItem>
                        </LinkContainer>
                    </NavDropdown>
                }
            </Nav>
        );

        const _proposalLinkFormatter = (cell, row) => (
            <Nav>
                <NavDropdown
                    eventKey={2}
                    title={cell}
                    id={`proposal-menu`}
                    className="menu-show-fixer proposal-menu-padding-reset"
                >
                    <LinkContainer to={`/mapview/${row.stickable_id}`}>
                        <MenuItem bsSize="small">Edit Proposal</MenuItem>
                    </LinkContainer>
                    <LinkContainer to={`/proposal_service_status/${row.stickable_id}`}>
                        <MenuItem bsSize="small">Proposal Statuses</MenuItem>
                    </LinkContainer>
                    <LinkContainer to={`/print_proposal/${row.stickable_id}`}>
                        <MenuItem bsSize="small">Print Proposal</MenuItem>
                    </LinkContainer>
                </NavDropdown>
            </Nav>
        );

        const isMobile = window.screen.width < 768;

        return (
            <Grid fluid style={isMobile ? {width: "100vw"} : {}} id="newActivitiesGrid">
                <Helmet title="Activity"/>

                <Row>
                    <Col md={12}>
                        <Row>
                            <Col md={6} className="text-left">
                                <PageHeader pageName="Activity"
                                            pageDetail={` found ${total} activities logged in the last 90 days.`}/>
                            </Col>
                            <Col md={6} className="text-left">
                                <Button
                                    className="pointer no-top-margin"
                                    bsStyle="warning"
                                    onClick={this.toggleColumnFilters}>
                                    Filter {" "}<FontAwesomeIcon icon="search" className="small-margin"/>
                                </Button>
                            </Col>
                        </Row>

                        <p>&nbsp;</p>
                        <Panel
                            collapsible
                            expanded={showFilters}
                        >
                            <Row>
                                <Col md={3}>
                                    <FormControl
                                        name="q"
                                        placeholder="Search..."
                                        value={resource.q}
                                        onChange={e => this.updateResourceAttr(e, debounce(500, this.reload))}
                                    />
                                </Col>
                                <Col md={2}>
                                    <Select className="Select" classNamePrefix="select"
                                            value={select(actionTypes, resource.action_type)}
                                            options={actionTypes}
                                            isClearable
                                            onChange={this.selectResourceAttr('action_type', this.reload)}
                                            placeholder="Activity Type"
                                    />
                                </Col>
                                <Col md={1}>
                                    <FormControl
                                        type="text"
                                        placeholder={'Proposal No'}
                                        onChange={e => {
                                            resource.proposal_no = e.target.value;
                                            this.setState(resource, this.reload)
                                        }}
                                    />
                                </Col>
                                <Col md={2}>
                                    <Select className="Select" classNamePrefix="select"
                                            value={select(employees, resource.person_id)}
                                            options={employees}
                                            isClearable
                                            onChange={this.selectResourceAttr('person_id', this.reload)}
                                            placeholder="Arborist"
                                    />
                                </Col>
                                <Col md={2}>
                                    <Select className="Select"
                                            classNamePrefix="select"
                                            value={select(customerOptions, resource.customer_id)}
                                            options={customerOptions}
                                            placeholder="Customer"
                                            isMulti
                                            isClearable
                                            onInputChange={this.customerSearch.search}
                                            onChange={this.selectResourceAttr('customer_id', this.reload)}
                                    />
                                </Col>
                                <Col md={2}>
                                    <FormControl
                                        type="text"
                                        placeholder={'Message/Body'}
                                        onChange={e => {
                                            resource.body = e.target.value;
                                            this.setState(resource, this.reload)
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Panel>

                        <Row>
                            <Col md={12}>
                                <BootstrapTable
                                    ref={this.tableRef}
                                    data={activities}
                                    striped={true}
                                    bordered={false}
                                    hover={true}
                                    exportCSV={true}
                                    pagination={true}
                                    remote
                                    fetchInfo={{dataTotalSize: total}}
                                    csvFileName={() => {
                                        const fileName = `activities-${moment().format()}.csv`
                                        if (this.tableRef?.current) {
                                            this.props.actions.saveNewCsvDownload(this.tableRef.current, fileName, this.state.resource, "new activities")
                                        }
                                        return fileName
                                    }}
                                    options={
                                        {
                                            onPageChange: (page, per_page) => {
                                                let {resource} = this.state;
                                                resource = {...resource, page, per_page};
                                                this.setState({resource}, this.reload);
                                            },
                                            page,
                                            sizePerPage: per_page,
                                            sortName: sort_by,
                                            sortOrder: sort,
                                            onSortChange: (sort_by, sort) => {
                                                let {resource} = this.state;
                                                resource = {...resource, sort_by, sort};
                                                this.setState({resource}, this.reload);
                                            }
                                        }
                                    }
                                >
                                    <TableHeaderColumn hidden isKey dataField="id">
                                        Invoice Id
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        width={isMobile ? "150px" : "15%"}
                                        tdStyle={{whiteSpace: 'normal'}}
                                        thStyle={{whiteSpace: 'normal'}}
                                        dataSort={true}
                                        dataField="action_type"
                                    >
                                        Activity Type
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        thStyle={{textWrap: "pretty"}}
                                        width={isMobile ? "100px" : "10%"}
                                        dataSort={true}
                                        columnClassName='menu-show-fixer'
                                        dataFormat={_proposalLinkFormatter}
                                        dataField="proposal_no"
                                    >
                                        Proposal No
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        thStyle={{textWrap: "pretty"}}
                                        tdStyle={{textWrap: "pretty"}}
                                        width={isMobile ? "100px" : "10%"}
                                        dataSort={true}
                                        dataField="creator"
                                    >
                                        Created by
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        width={isMobile ? "250px" : "25%"}
                                        tdStyle={{whiteSpace: 'normal', wordWrap: 'break-word'}}
                                        thStyle={{whiteSpace: 'normal', textWrap: "pretty"}}
                                        dataSort={true}
                                        dataField="body"
                                    >
                                        Message/Body
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        width={isMobile ? "100px" : "10%"}
                                        tdStyle={{whiteSpace: 'normal'}}
                                        thStyle={{whiteSpace: 'normal', textWrap: "pretty"}}
                                        dataSort={true}
                                        dataFormat={_linkFormatter}
                                        columnClassName="menu-show-fixer"
                                        dataField="customer"
                                    >
                                        Customer
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        width={isMobile ? "100px" : "10%"}
                                        dataSort={true}
                                        dataFormat={cell => cell ?
                                            <p title={dateFormatter(cell)}>{moment(cell).fromNow()}</p> : 'N/A'}
                                        csvFormat={dateFormatter}
                                        dataField="created_at"
                                        tdStyle={{whiteSpace: 'normal'}}
                                        thStyle={{whiteSpace: 'normal'}}
                                    >
                                        Created Date
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        width={isMobile ? "100px" : "10%"}
                                        dataSort={true}
                                        dataFormat={cell => cell ? <p>{dateFormatter(cell)}</p> : ''}
                                        csvFormat={cell => cell ? dateFormatter(cell) : ''}
                                        dataField="last_follow_up_date"
                                        tdStyle={{whiteSpace: 'normal'}}
                                        thStyle={{whiteSpace: 'normal'}}
                                    >
                                        Last Follow Up
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        width={isMobile ? "100px" : "10%"}
                                        dataSort={true}
                                        dataFormat={cell => cell ? <p>{dateFormatter(cell)}</p> : ''}
                                        csvFormat={cell => cell ? dateFormatter(cell) : ''}
                                        dataField="follow_up_date"
                                        tdStyle={{whiteSpace: 'normal'}}
                                        thStyle={{whiteSpace: 'normal'}}
                                    >
                                        Follow Up
                                    </TableHeaderColumn>
                                </BootstrapTable>
                            </Col>
                        </Row>
                    </Col>
                </Row>

            </Grid>
        )
    }
}

function mapStateToProps(state) {
    return {
        state: state
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({...actions, saveNewCsvDownload}, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivitiesSearch);

