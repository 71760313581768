import React from 'react';
import PropTypes from "prop-types"
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import '../../styles/crumbs.css'
import Dollars from "../../components/Dollars";
import {dashboardStatuses, defaultDateFormat, defaultDateTimeFormat, select} from "../../common/commonHandlers";
import {
    Badge,
    Button,
    Col,
    FormControl,
    Glyphicon,
    Grid,
    MenuItem,
    OverlayTrigger,
    Row,
    Tooltip
} from "react-bootstrap";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import Select from "react-select";
import {setEmailErrors, showEmailDialog} from "../../components/email/actions"
import * as api from './SalesDashboardApi'
import {
    ageFormatter,
    customerNameFormatter,
    dateFormatter,
    dollarsFormatter,
    proposalNoFormatterWithEmail
} from "../../components/BootStrapTableCellFormatters";
import moment from "moment-timezone";
import EmailDialog from "../../components/email/EmailDialog";
import './SalesDashboard.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import LeadsFilterModal from "../LeadsPage/LeadsFilterModal";
import ResourceComponent from "../../components/ResourceComponent";
import {debounce} from "throttle-debounce";
import ColorCheckbox from "../Scheduler/ColorCheckbox";
import {LinkContainer} from "react-router-bootstrap";
import {faCog, faExclamationTriangle, faFilter} from "@fortawesome/free-solid-svg-icons";
import PromiseUi from "../../components/PromiseUi";
import ReactDock from "react-dock";
import {SalesDashboardTabFilter} from './SalesDashboardTabFilter'
import {Link} from "react-router";
import ExcelJS from "exceljs";
import {saveAs} from "file-saver";

const actions = {...api, showEmailDialog, setEmailErrors};

const isMobile = window.screen.width <= 1024;
const smMobile = window.screen.width <= 450;
const xsMobile = window.screen.width <= 350;

class SalesDashboard extends ResourceComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {
            resource: {
                date_name: 'all',
                from: moment().clone().startOf('month').format(defaultDateFormat),
                to: moment().clone().endOf('month').format(defaultDateFormat),
                promised_from: null,
                promised_to: null,
                service_id: null,
                page: 1,
                per_page: 10,
                sort: 'asc',
                sort_by: 'created_at',
                selectedFilter: 'total',
                selectedStatus: 'draft',
                onlyProposed: true,
                city: null,
                isBacklog: false,
                sent_from: null,
                sent_to: null,
                ps_completed_statuses: ['COMPLETED'],
                excludeScheduled: false
            },
            isBacklogTableOpened: false,
            service_types: [],
            services: [],
            customer_types: [],
            customers: [],
            assignees: [],
            persons: [],
            reasons: [],
            priorities: [],
            equipments: [],
            plants: [],
            report: null,
            acceptedTotals: null,
            data: [],
            selected: 0,
            isDashboardModalOpened: false,
            selectedRecords: [],
            allSelected: false,
            employees: [],
            backlogEmailRecipients: [],
            isDeclinedModalOpened: false,
            showDock: false,
            columnOptions: {
                draft: ['Customer', 'Services', 'Price', 'Proposal #', 'Age', 'Proposal created', 'Arborist'],
                proposed: ['Customer', 'Services', 'Sent Date', '#Sent', 'Due on', 'Completed', 'Price', 'Proposal #', 'Age', 'Proposal created', 'Arborist'],
                backlog: ['Customer', 'Service', 'Service Type', 'Service $', 'MHRS', 'Status', 'Priority', 'WO#', 'Proposal #', 'Age', 'Accepted', 'Promised', 'Scheduled on', 'WO Notes', 'Arborist', 'Site', 'Email', 'Phone Number', 'Assignee'],
                completed: ['Customer', 'Services', 'Price', 'Proposal #', 'Age', 'Proposal created', 'Completed at', 'WO Notes', 'Arborist'],
                invoiced: ['Customer', 'Services', 'Price', 'Proposal #', 'Age', 'Proposal created', 'Invoice created', 'WOs', 'WO Notes', 'Invoices', 'Arborist', 'Email'],
                paid: ['Customer', 'Services', 'Price', 'Proposal #', 'Age', 'Proposal created', 'Paid Dates', 'Total Paid', 'WOs', 'Invoices', 'Arborist'],
            },
            visibleColumns: {
                draft: [],
                proposed: [],
                backlog: [],
                completed: [],
                invoiced: [],
                paid: [],
            },
            dashboard_settings: {
                draft: [],
                proposed: [],
                backlog: [],
                completed: [],
                invoiced: [],
                paid: [],
            },
            loadingData: false
        };
        this.delayedSearch = debounce(1250, () => {
            const {resource} = this.state
            this.setState({resource: {...resource, page: 1}}, this.fetchTableData)
        });
        this.delayedUpdateZip = debounce(1000, this.updateZip);
        this.delayedCitySearch = debounce(1000, this.fetchTableData);
        this.delayedCustomerSearch = debounce(500, this.search);
        this.delayedProposalSearch = debounce(500, this.search);

        this.proposalSearch = {
            searchOptions: () => [],
            search: () => {
            }
        }
        this.customerSearch = {
            searchOptions: () => [],
            search: () => {
            }
        }
        this.zipSearch = {
            searchOptions: () => [],
            search: () => {
            }
        }
        this.citySearch = {
            searchOptions: () => [],
            search: () => {
            }
        }
    }

    selectStatus = (index, i) => {
        let {resource} = this.state;
        const {dashboardStatuses} = this.props;

        resource.sort = 'asc';
        resource.sort_by = 'created_at';
        resource.selectedFilter = 'total';
        resource.selectedStatus = dashboardStatuses[index].value
        resource.isBacklog = dashboardStatuses[index].label === 'Backlog'
        resource.page = 1;
        resource.per_page = 10;
        const filterParams = ['service', 'reason', 'equipment', 'city', 'zip', 'priority', 'plant', 'customer_id', 'customer_type', 'assignee_id', 'arborist_id', 'proposal_id', 'ex_customer_type', 'ex_equipment', 'ex_service', 'ex_priority', 'service_type', 'ex_service_type']
        const filterDates = ['sent_from', 'sent_to', "promisedFrom", "promisedTo", "scheduledFrom", 'scheduledTo']

        filterParams.map(p => resource[p] = [])
        filterDates.map(p => resource[p] = null)

        this.setState({
            selected: index,
            resource: resource,
            selectedRecords: [],
            allSelected: false,
            loadingData: true
        }, this.fetchTableData)
    };

    selectFilter = (filter) => {
        let {resource} = this.state;
        resource.selectedFilter = filter;
        if (filter === "backlog_report") {
            resource.isBacklog = true
        } else {
            resource.isBacklog = false
        }
        this.setState(resource, this.fetchData);
    };

    rowFormatter = (cell, row, filter, selectedStatus, selectedFilter) => {
        const {selected} = this.state;
        const isBacklogFilter = selectedStatus === "Backlog"
        const {visibleColumns} = this.state;
        const tab = dashboardStatuses()[selected].value

        return <div>
            {((selectedStatus === 'Sent')
                || (selectedStatus === 'In Draft')
                || (selectedStatus === 'Paid')
                || (selectedStatus === 'Invoiced')
                || (selectedStatus === 'Completed'))
            && visibleColumns[tab].includes('Services')
                ?
                <Row>
                    <Col xs={6}>Services</Col>
                    <Col xs={6}>{this.serviceFormatter(cell, row)}</Col>
                </Row> : null
            }
            {visibleColumns[tab].includes('Customer') &&
                <Row>
                    <Col xs={6}>Customer</Col>
                    <Col xs={6}>{customerNameFormatter(cell, row)}</Col>
                </Row>}
            {(selectedStatus === 'Backlog') &&
                (
                    <div>
                        {visibleColumns[tab].includes('Service') &&
                            <Row>
                                <Col xs={6}>Service</Col>
                                <Col xs={6}>{this.serviceNameFormatter(cell, row, "service_name")}</Col>
                            </Row>}
                        {visibleColumns[tab].includes('Service Type') &&
                            <Row>
                                <Col xs={6}>Service Type</Col>
                                <Col xs={6}>{this.serviceNameFormatter(cell, row, "service_type")}</Col>
                            </Row>}
                        {visibleColumns[tab].includes('Service $') &&
                            <Row>
                                <Col xs={6}>Service $</Col>
                                <Col xs={6}>{this.serviceNameFormatter(cell, row, "service_amount")}</Col>
                            </Row>}
                        {visibleColumns[tab].includes('MHRS') &&
                            <Row>
                                <Col xs={6}>MHRS</Col>
                                <Col xs={6}>{this.serviceNameFormatter(cell, row, "service_mhrs")}</Col>
                            </Row>}
                        {visibleColumns[tab].includes('Status') &&
                            <Row>
                                <Col xs={6}>Status</Col>
                                <Col xs={6}>{this.serviceNameFormatter(cell, row, "service_status")}</Col>
                            </Row>}
                        {visibleColumns[tab].includes('Priority') &&
                            <Row>
                                <Col xs={6}>Priority</Col>
                                <Col xs={6}>{this.serviceNameFormatter(cell, row, "service_priority")}</Col>
                            </Row>}
                        {visibleColumns[tab].includes('WO#') &&
                            <Row>
                                <Col xs={6}>WO#</Col>
                                <Col xs={6}>{this.workOrderFormatter(cell, row)}</Col>
                            </Row>}
                    </div>
                )}
            {(selectedStatus === 'Sent') && visibleColumns[tab].includes('#Sent') &&
                (<Row>
                    <Col xs={6}>#Sent</Col>
                    <Col xs={6}>{this.sentFormatter(row.email_count)}</Col>
                </Row>)}
            {(selectedStatus === 'Sent') && visibleColumns[tab].includes('#Sent') &&
                (<Row>
                    <Col xs={6}>Due on</Col>
                    <Col xs={6}>{this.lastSentFormatter(row.last_follow_up_date)}</Col>
                </Row>)}
            {(selectedStatus === 'Sent') && visibleColumns[tab].includes('#Sent') &&
                (<Row>
                    <Col xs={6}>Completed</Col>
                    <Col xs={6}>{this.sentCompleteFormatter(row.complete)}</Col>
                </Row>)}
            {visibleColumns[tab].includes('Price') &&
                <Row>
                    <Col
                        xs={6}>{(isBacklogFilter || selectedStatus === 'Backlog') ? "Total Proposal Price (Accepted)" : "Price"}</Col>
                    <Col xs={6}>{dollarsFormatter(row.price)}</Col>
                </Row>}
            {visibleColumns[tab].includes('Proposal #') &&
                <Row>
                    <Col xs={6}>Proposal#</Col>
                    <Col
                        xs={6}>{proposalNoFormatterWithEmail(cell, row, this.email, filter && ['unscheduled', 'scheduled'].includes(filter.key))}</Col>
                </Row>}
            {visibleColumns[tab].includes('Age') &&
                <Row>
                    <Col xs={6}>Age</Col>
                    <Col xs={6}>{ageFormatter(row.created_at)}</Col>
                </Row>}
            {(!isBacklogFilter || selectedStatus !== 'Backlog') && visibleColumns[tab].includes('Created') &&
                <Row>
                    <Col xs={6}>Created</Col>
                    <Col xs={6}>{moment(row.created_at).format(defaultDateTimeFormat)}</Col>
                </Row>
            }
            {this.statusDateFormatter(cell, row, selectedStatus, selectedFilter)}
            {(selectedStatus === 'Invoiced' || selectedStatus === 'Paid') && visibleColumns[tab].includes('WOs') &&
                <Row>
                    <Col xs={6}>WOs</Col>
                    <Col xs={6}>{this.linkFormatter(row.work_orders, row)}</Col>
                </Row>
            }
            {(selectedStatus === 'Invoiced' || selectedStatus === 'Paid') && visibleColumns[tab].includes('Invoices') &&
                <Row>
                    <Col xs={6}>Invoices</Col>
                    <Col xs={6}>{this.linkFormatter(row.invoices_no, row)}</Col>
                </Row>
            }
            {visibleColumns[tab].includes('Arborist') &&
                <Row>
                    <Col xs={6}>Arborist</Col>
                    <Col xs={6}>{row.arborist}</Col>
                </Row>}
            {selectedStatus === 'Backlog' || selectedStatus === "Accepted" && isBacklogFilter && visibleColumns[tab].includes('Site') &&
                <Row>
                    <Col xs={6}>Site</Col>
                    <Col xs={6}>{row.site}</Col>
                </Row>
            }
            {visibleColumns[tab].includes('Email') && selectedStatus === 'Invoiced' &&
                <Row>
                    <Col xs={6}>Email</Col>
                    <Col xs={6}>{row.billing_email}</Col>
                </Row>
            }
            {visibleColumns[tab].includes('Email') && selectedStatus === 'Backlog' &&
                <Row>
                    <Col xs={6}>Email</Col>
                    <Col xs={6}>{row.site_email}</Col>
                </Row>
            }
            {visibleColumns[tab].includes('Phone Number') && selectedStatus === 'Backlog' &&
                <Row>
                    <Col xs={6}>Phone</Col>
                    <Col xs={6}>
                        {(isMobile && row.phone_number) ? <a media="screen"
                                                             href={`tel:${row.phone_number.replace(/\\D/g, '')}`}>{row.phone_number}</a> :
                            <>{row.phone_number}</>}
                    </Col>
                </Row>
            }
        </div>
    };

    statusDateFormatter = (cell, row, selectedStatus, selectedFilter) => {
        const isBacklogFilter = selectedStatus === "Backlog"
        const {visibleColumns, selected} = this.state;
        const tab = dashboardStatuses()[selected].value
        if (selectedStatus === 'Sent' && visibleColumns[tab].includes('Sent Date')) {
            return (
                <Row>
                    <Col xs={6}>Sent Date</Col>
                    <Col xs={6}>{moment(row.sent_date).format(defaultDateTimeFormat)}</Col>
                </Row>
            )
        }
        if (selectedStatus === 'Accepted') {
            return (
                <span>
                    {!isBacklogFilter &&
                        <Row>
                            <Col xs={6}>Declined Date</Col>
                            <Col xs={6}>{moment(row.declined_date).format(defaultDateTimeFormat)}</Col>
                        </Row>}
                    <Row>
                    <Col xs={6}>Accepted</Col>
                    <Col xs={6}>{moment(row.accepted_date).format(defaultDateTimeFormat)}</Col>
                  </Row>
                    {isBacklogFilter &&
                        <div>
                            <Row>
                                <Col xs={6}>Promised From</Col>
                                <Col
                                    xs={6}>{row.promised_start_at ? moment(row.promised_start_at).format(defaultDateTimeFormat) : 'N/A'}</Col>
                            </Row>
                            <Row>
                                <Col xs={6}>Promised To</Col>
                                <Col
                                    xs={6}>{row.promised_end_at ? moment(row.promised_end_at).format(defaultDateTimeFormat) : 'N/A'}</Col>
                            </Row>
                        </div>
                    }
                </span>
            )
        }
        if (selectedStatus === 'Backlog') {
            return (
                <span>
                    {visibleColumns[tab].includes('Accepted') &&
                        <Row>
                            <Col xs={6}>Accepted</Col>
                            <Col xs={6}>{moment(row.accepted_date).format(defaultDateTimeFormat)}</Col>
                        </Row>}
                    {visibleColumns[tab].includes('Promised') &&
                        <div>
                            <Row>
                                <Col xs={6}>Promised From</Col>
                                <Col
                                    xs={6}>{row.promised_start_at ? moment(row.promised_start_at).format(defaultDateTimeFormat) : 'N/A'}</Col>
                            </Row>
                            <Row>
                                <Col xs={6}>Promised To</Col>
                                <Col
                                    xs={6}>{row.promised_end_at ? moment(row.promised_end_at).format(defaultDateTimeFormat) : 'N/A'}</Col>
                            </Row>
                        </div>}
                </span>
            )
        }
        if (selectedStatus === 'Scheduled') {
            return (
                <Row>
                    <Col xs={6}>Scheduled on</Col>
                    <Col xs={6}>{row.event_dates}</Col>
                </Row>
            )
        }
        if (selectedStatus === 'Completed' && visibleColumns[tab].includes('Completed at')) {
            return (
                <Row>
                    <Col xs={6}>Completed at</Col>
                    <Col xs={6}>{moment(row.completed_at).format(defaultDateTimeFormat)}</Col>
                </Row>
            )
        }
        if (selectedStatus === 'Invoiced' && visibleColumns[tab].includes('Invoiced at')) {
            return (
                <Row>
                    <Col xs={6}>Invoiced at</Col>
                    <Col xs={6}>{this.paidFormatter(cell, row, selectedStatus)}</Col>
                </Row>
            )
        }
        if (selectedStatus === 'Paid') {
            return (
                <div>
                    {visibleColumns[tab].includes('Paid Dates') &&
                        <Row>
                            <Col xs={6}>Paid Dates</Col>
                            <Col xs={6}>{this.paidFormatter(cell, row, selectedStatus)}</Col>
                        </Row>}
                    {visibleColumns[tab].includes('Total Paid') &&
                        <Row>
                            <Col xs={6}>Total Paid</Col>
                            <Col xs={6}>{<Dollars amount={row.total_paid}/>}</Col>
                        </Row>}
                </div>
            )
        }
    };

    saveReportSettings = () => {
        let {dashboard_settings} = this.state;
        this.props.actions.saveDashboardSettings(dashboard_settings)
    };

    handleColumnOptionsChange = e => {
        let {visibleColumns, dashboard_settings, selected} = this.state;
        const tab = dashboardStatuses()[selected].value
        visibleColumns[tab] = e.map(x => x.value)
        dashboard_settings = visibleColumns
        this.setState({
            visibleColumns: visibleColumns,
            dashboard_settings: dashboard_settings
        }, this.saveReportSettings);
    };

    email = proposalId => {
        const {email} = this.props;
        this.props.actions.loadProposalData(proposalId, proposal => {
            let recipientEmail
            const {
                contactEmail,
                contactEmail2,
                secondContactEmail,
                secondContactEmail2,
                additionalContactEmails,
                additionalContactEmails2
            } = proposal
            const primarySiteContactEmails = (contactEmail && contactEmail2) ? `${contactEmail}, ${contactEmail2}` : contactEmail
            const secondarySiteContactEmails = (secondContactEmail && secondContactEmail2) ? `${secondContactEmail}, ${secondContactEmail2}` : secondContactEmail

            recipientEmail = `${primarySiteContactEmails || ""}`
            if (secondarySiteContactEmails) recipientEmail += `, ${secondarySiteContactEmails}`
            if (additionalContactEmails?.length > 0) recipientEmail += `, ${additionalContactEmails.toString()}`
            if (additionalContactEmails2?.length > 0) recipientEmail += `, ${additionalContactEmails2.toString()}`

            this.props.actions.showEmailDialog(
                {
                    emailType: "proposal",
                    referenceId: proposalId,
                    recipient: recipientEmail,
                    defaultEmail: email
                });
        });
    };

    onSelectAll = (isSelected, rows) => {
        const {dashboardStatuses} = this.props
        const {selected} = this.state;
        let selectedRecords = [];
        if (isSelected) {
            selectedRecords = rows.map(p => dashboardStatuses[selected].label !== 'Backlog' ? p.proposalId : p.proposal_service_id)
        }
        this.setState({selectedRecords: [...new Set(selectedRecords)]})
    };

    onRowSelect = (row, isSelected) => {
        let selectedRecords = [...this.state.selectedRecords];
        const proposalId = parseInt(row.proposalId, 10);
        if (isSelected) {
            selectedRecords = selectedRecords.concat(proposalId);
            const uniqSelected = new Set(selectedRecords);
            selectedRecords = [...uniqSelected];
        } else {
            selectedRecords = selectedRecords.filter(s => s !== proposalId);
        }
        this.setState({selectedRecords});
    };

    declineSelected = () => {
        const {selectedRecords, selected} = this.state;
        const isBacklog = this.props.dashboardStatuses[selected].label === 'Backlog'
        this.props.actions.declineProposals(selectedRecords, isBacklog, () => {
            this.fetchData()
        });
    };

    handleChangePromisedFrom = (e) => {
        let {resource} = this.state;
        const promisedFrom = e === "" ? null : moment(e).format(defaultDateFormat);
        this.setState({resource: {...resource, promisedFrom}}, this.search)
    };

    handleChangePromisedTo = (e) => {
        let {resource} = this.state;
        const promisedTo = e === "" ? null : moment(e).format(defaultDateFormat);
        this.setState({resource: {...resource, promisedTo}}, this.search)
    };

    handleChangeDate = (e, date) => {
        let {resource} = this.state;
        if (resource[date] === null) {
            resource[date] = moment(e).format(defaultDateFormat);
        } else {
            resource[date] = moment(e).format(defaultDateFormat);
        }
        this.setState(resource);
    };

    componentDidMount() {
        const {selected, visibleColumns} = this.state;
        this.props.actions.load(result => {
            this.setState(result)
        });
        this.fetchData();
        this.props.actions.getEmployees(employees => this.setState({employees: employees}, this.getEmployeesWithRole));
        this.props.actions.getAssignees(assignees => {
                this.setState({assignees: assignees}, this.formatAssignees)
            }
        );
        this.customerSearch = this.buildSearchComponent('customer_id', this.props.actions.getCustomersSearch);
        this.proposalSearch = this.buildSearchComponent('proposal_id', this.props.actions.searchForProposal);
        this.zipSearch = this.buildSearchComponent('zip', this.props.actions.getZipcodeSearch);
        this.citySearch = this.buildSearchComponent('city', this.props.actions.getCitySearch);
        this.props.actions.getDashboardSettings(settings => {
            this.setState(() => ({visibleColumns: settings.columns}))
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {visibleColumns, selected, resource} = this.state;
        if (prevState.selected !== selected) {
            this.props.actions.getDashboardSettings(settings => {
                const tab = dashboardStatuses()[selected].value
                this.setState(() => ({visibleColumns: {...visibleColumns, [tab]: settings.columns[tab]}, resource}))
            })
        }
    }

    getEmployeesWithRole = () => {
        const {employees} = this.state;
        if (employees && employees.length > 0) {
            const employees_helper = [];
            employees && employees.filter(employee => employee.hidden_arborist !== true).map(c => {
                employees_helper.push({label: c.name, value: c.id});
                this.setState({employees: employees_helper});
            });
        }
    };

    formatAssignees = () => {
        const {assignees} = this.state;
        let selectData = []
        if (assignees && assignees.length > 0) {
            assignees.map(assignee => selectData.push({
                label: `${assignee.first_name} ${assignee.last_name}`,
                value: assignee.id
            }))
            this.setState({assignees: selectData});
        }
    }

    fetchTableData = (recalculateAmount = true) => {
        const {resource, selected, report, selectedStatus} = this.state;
        const {dashboardStatuses, durations} = this.props;

        this.props.actions.fetchTableData({
            ...resource,
            date_name: select(durations, resource.date_name).label,
            status: dashboardStatuses[selected].value,
        }, data => {
            if (recalculateAmount && report) {
                this.props.actions.fetchData({
                    ...resource,
                    status: resource.selectedStatus ? resource.selectedStatus : dashboardStatuses[selected].value,
                    date_name: select(durations, resource.date_name).label,
                    table_report: true
                }, result => {
                    report[resource.selectedStatus] = result.report
                    this.setState({report})
                });
            }
            this.setState({data, selectedRecords: [], allSelected: false, loadingData: false})
        })
    }
    fetchData = () => {
        const {dashboardStatuses, durations} = this.props;
        const {resource, selected, isDashboardModalOpened} = this.state;
        if (isDashboardModalOpened) {
            resource.sort_by = 'created_at'
        }

        this.props.actions.fetchData({
            ...resource,
            date_name: select(durations, resource.date_name).label,
            status: dashboardStatuses[selected].value,
        }, report => {
            this.setState({report});
        });
        this.fetchTableData(false)
    };

    serviceFormatter = (cell, row) => {
        const {resource} = this.state
        const filteredServices = row.service_name.filter(service => {
            const selectedStatus = resource.selectedStatus.toLowerCase();
            const serviceStatus = service.service_status_name.toLowerCase();
            if (selectedStatus === 'paid') {
                return selectedStatus === serviceStatus || serviceStatus === 'payment';
            } else if (selectedStatus === 'completed') {
                return resource.ps_completed_statuses.some(pss => pss.toLowerCase() === serviceStatus);
            } else {
                return selectedStatus === serviceStatus;
            }
        });
        return (filteredServices.length > 0 ?
                <OverlayTrigger placement="right"
                                overlay={this.serviceTooltip(filteredServices)}>
                    <Badge
                        className={`proposalBadge font12 ${smMobile ? null : "ml-6"}`}>{resource.selectedStatus === 'Paid' || resource.selectedStatus === 'Invoiced' ? row.service_date && row.service_date.length : filteredServices.length}</Badge>
                </OverlayTrigger> :
                <Badge className={`proposalBadge font12 ${smMobile ? null : "ml-6"}`}>{filteredServices.length}</Badge>
        )
    };

    woNotesFormatter = (cell, row, selectedStatus) => {
        const {wo_notes} = row
        const wosCheckEmptyNotes = () => {
            let table = []
            wo_notes?.length > 0 && wo_notes.forEach(wo_note => wo_note?.wo_note.length > 0 && (selectedStatus === 'Invoiced' || selectedStatus === 'Completed' ? true : row.completed_at === wo_note.date) && table.push(wo_note))
            return table
        }

        return (
            <>
                {selectedStatus !== "Backlog" && wosCheckEmptyNotes().length > 0
                    ? <OverlayTrigger placement="right"
                                      overlay={this.woNotesTooltip(wosCheckEmptyNotes(), row, selectedStatus)}>
                        <Badge
                            className={`proposalBadge font12 ${smMobile ? null : "ml-6"}`}>{wosCheckEmptyNotes().length}</Badge>
                    </OverlayTrigger>
                    : <p>{wo_notes?.wo_note ? wo_notes.wo_note : "N/A"}</p>
                }
            </>
        )
    };

    woNotesTooltip = (woNotes, row, selectedStatus) => {
        return (
            <Tooltip id="tooltip">
                {woNotes && woNotes.length > 0 && woNotes.map((woNote, i) => (
                    (selectedStatus === 'Invoiced' || selectedStatus === 'Completed' ? true : row.completed_at === woNote.date) &&
                    <div className={`flex-column-start ${i === woNote.length - 1 ? null : "bottom5"}`} key={i}>
                        <span className={`${smMobile ? "font10" : null}`}>{woNote.wo_note}</span>
                    </div>
                ))}
            </Tooltip>
        )
    };

    serviceTooltip = (serviceName) => {
        const serviceDisplay = (service, i) => {
            return (
                <div className={`flex-column-start ${i === serviceName.length - 1 ? null : "bottom5"}`} key={i}>
                    <span className={`text-left bold ${smMobile ? "font10" : null}`}>{service.name}</span>
                    <span className={`text-left ${smMobile ? "font10" : null}`}>{service.date}</span>
                </div>
            )
        }


        return (
            <Tooltip id="tooltip">
                {serviceName && serviceName.length > 0 && serviceName.map((service, i) => (serviceDisplay(service, i)))}
            </Tooltip>
        )
    };

    dateTimeZoneConverter = (value) => {
        return moment.utc(value[1]).tz(moment.tz.guess()).format(defaultDateTimeFormat)
    }

    paidFormatter = (cell, row, selectedStatus) => {
        return (
            <OverlayTrigger placement={smMobile ? "bottom" : "right"}
                            overlay={this.paidTooltip(row.partial_payments, row.invoiced_at, selectedStatus)}>
                <Badge
                    className={`proposalBadge font12 ${smMobile ? null : "ml-6"}`}>{selectedStatus === 'Paid' ? row.partial_payments && row.partial_payments.length : selectedStatus === 'Invoiced' ? row.invoiced_at && row.invoiced_at.length : row.status}</Badge>
            </OverlayTrigger>
        )
    };

    paidTooltip = (paidServices, invoicedServices, selectedStatus) => {
        const invoiced_services = invoicedServices && invoicedServices.map(x => x.split("|"));

        if (selectedStatus === "Paid") {
            return (
                <Tooltip id="tooltip">
                    {paidServices && paidServices.map((service, i) => {
                            return (
                                <div
                                    className={`flex-column-start ${i === paidServices.length - 1 ? null : "bottom5"}`}
                                    key={i}>
                                    <div className="full-width space-between-end">
                                        <span
                                            className={`text-capitalize mr-9 text-left bold ${smMobile ? "font10" : null}`}>{service.status}</span>
                                        <span className={`text-left bold ${smMobile ? "font10" : null}`}>{<Dollars
                                            amount={+service.amount}/>}</span>
                                    </div>
                                    <span
                                        className={`text-left ${smMobile ? "font10" : null}`}>{service.date}</span>
                                </div>
                            )
                        }
                    )}
                </Tooltip>
            )
        } else if (selectedStatus === "Invoiced") {
            return (
                <Tooltip id="tooltip">
                    {invoiced_services && invoiced_services.map((service, i) => {
                            return (
                                <div
                                    className={`flex-column-start ${i === invoiced_services.length - 1 ? null : "bottom5"}`}
                                    key={i}>
                                    <span className={`text-left bold ${smMobile ? "font10" : null}`}>{service[0]}</span>
                                    <span
                                        className={`text-left ${smMobile ? "font10" : null}`}>{this.dateTimeZoneConverter(service)}</span>
                                </div>
                            )
                        }
                    )}
                </Tooltip>
            )
        }
    };

    linkFormatter = (cell, row) => {
        return <div className='dropdown'>{cell && cell.length > 0 && cell.map(item => {
            return <LinkContainer target="_blank"
                                  to={item.work_order_no ? `/work_orders/${row.proposalId}` : `/proposals/${row.proposalId}/invoices/`}>
                <MenuItem bsSize="small">{item.work_order_no ? `#${item.work_order_no}` : `#${item.number}`}</MenuItem>
            </LinkContainer>
        })
        }</div>
    }

    sentFormatter = (cell, row) => cell ? <span>{cell}</span> : 'N/A';
    lastSentFormatter = (cell, row) => cell ? <span>{moment(cell).format(defaultDateTimeFormat)}</span> : 'N/A';
    sentCompleteFormatter = (cell, row) => cell ? `${cell}` : 'false';
    totalPaidFormatter = (cell, row) => cell ? <Dollars amount={cell}/> : 'N/A';
    priceFormatter = (cell, row) => <Dollars amount={cell}/>;
    csvDollarFormatter = cell => {
        return parseFloat(cell ? cell : 0).toFixed(2)
    }

    dateArrayFormatter = (cell, row) => {
        return <div>{cell && cell.length > 0 && cell.map((date, index) => date && <div key={index}>
            {moment(date).format(defaultDateTimeFormat)}
        </div>)}</div>
    }

    renderTooltip = () => {
        return (
            <Tooltip id="tooltip">
                <span>Searching for data within a specified time range</span>
            </Tooltip>
        )
    };

    updateZip = () => {
        const {resource} = this.state;
        this.props.actions.fetchStateAndCityForCustomer(resource.zip, (result) => {
            let {resource} = this.state;
            this.setState(() => ({resource: {...resource, city: result.city}}), this.fetchTableData);
        })
    }

    search = () => {
        let {resource} = this.state;
        resource.sort_by = 'created_at';
        this.props.actions.getCustomers({...resource}, results => {
            this.setState(() => ({resource: {...resource}, customers: results.content}), this.fetchTableData);
        });
    }

    getDate = (date) => {
        if (date && date.length > 0) {
            let datearray = date.split("/");
            let parsed = datearray[1] + '/' + datearray[0] + '/' + datearray[2];
            return parsed;
        } else {
            return null;
        }
    }

    handleRangeDateChange = (start, end, dateStart, dateEnd, tableData = true) => {
        let {resource} = this.state;
        resource[dateStart] = this.getDate(start);
        resource[dateEnd] = this.getDate(end);
        this.setState(resource);
    }

    statusDateFilterFormatter = (selectedStatus) => {
        if (selectedStatus === 'In Draft') {
            return 'Created at date'
        }
        if (selectedStatus === 'Sent') {
            return 'Sent at date'
        }
        if (selectedStatus === 'Backlog') {
            return 'Accepted at date'
        }
        if (selectedStatus === 'Completed') {
            return 'Completed at date'
        }
        if (selectedStatus === 'Invoiced') {
            return 'Invoiced at date'
        }
        if (selectedStatus === 'Paid') {
            return 'Paid at date'
        }
    }

    serviceNameFormatter = (cell, row, service_prop) => {
        if (!this.state.resource.isBacklog) return
        if (service_prop === "service_total_accepted") {
            return <div><Dollars
                amount={row.service_total_accepted}/>
            </div>
        }
        if (service_prop === "service_name") {
            return <div>{row.name}</div>
        } else if (service_prop === "service_type") {
            return <div>{row.service_type}</div>
        } else if (service_prop === "service_amount") {
            return <div><Dollars amount={row.service_total}/></div>
        } else if (service_prop === "service_mhrs") {
            return <div>{row.man_hrs}</div>
        } else if (service_prop === "service_status" && row.ps_status) {
            const status_name = row.ps_status.charAt(0).toUpperCase() + row.ps_status.slice(1).toLowerCase();
            return <div>{status_name}</div>
        } else if (service_prop === "service_priority") {
            return <div>{row.priority ? row.priority : 'N/A'}</div>
        } else if (service_prop === "service_event_dates") {
            return <div>{row.event_dates ? row.event_dates : 'N/A'}</div>
        } else if (service_prop === "service_promised_dates") {
            return <div>{row.ps_promised_start_at && row.ps_promised_end_at ? moment(row.ps_promised_start_at).format(defaultDateFormat)
                + "-" + moment(row.ps_promised_end_at).format(defaultDateFormat) : '' + 'N/A'}</div>
        } else if (service_prop === "wo_notes") {
            return <div>{row.wo_notes ? row.wo_notes : "N/A"}</div>
        }

    }

    workOrderFormatter = (cell, row) => {
        return row.work_orders_no && row.work_orders_no.length > 0 ? <>
            {row.work_orders_no.sort((a, b) => a.number - b.number).map(wo => {
                return <div><a target="_blank" href={`/work_orders/${row?.proposalId}`}>
                    {wo.number}
                </a></div>
            })}
        </> : <>N/A</>
    }

    invoiceAgeFormatter = (cell) => {
        let age = []
        if (cell) {
            cell.map((item) => {
                let time = item.paid_at ? moment(item.paid_at) : moment()
                age.push(Math.round(time.diff(moment(item.due_date ?? item.invoiced_at), 'hours') / (24)))
            })
        }
        return <>{age.map((age) => {
            return <div>{age}</div>
        })}</>
    };

    setFilterModalParams = () => {
        this.setState(() => ({
            resource: {...this.state.resource, page: 1},
            isBacklogTableOpened: !this.state.isBacklogTableOpened
        }), () => this.fetchTableData());
    }

    closeFilterModal = () => {
        this.setState({isBacklogTableOpened: !this.state.isBacklogTableOpened})
    }

    proposalNoFormatterXLSX = (proposal_no, proposal_id) => {
        const primaryUrl = new URL(window.location.href).origin

        return {formula: `=HYPERLINK("${primaryUrl}/mapview/${proposal_id}","${proposal_no}")`};
    }

    woFormatterXLSX = (wo_numbers, proposal_id) => {
        const primaryUrl = new URL(window.location.href).origin

        let wo_number;
        if (wo_numbers && wo_numbers.includes(",")) {
            wo_number = wo_numbers.split(",").join("\n")
        } else {
            wo_number = wo_numbers
        }
        return {formula: `=HYPERLINK("${primaryUrl}/work_orders/${proposal_id}","${wo_number}")`};
    }

    invoiceNoFormatterXLSX = (invoice_no, proposal_id) => {
        const primaryUrl = new URL(window.location.href).origin

        return {formula: `=HYPERLINK("${primaryUrl}/proposals/${proposal_id}/invoices","${invoice_no}")`};
    }

    dateFormatterXLSX = (date) => {
        return moment(date).format(defaultDateTimeFormat);
    }

    siteFormatterXLSX = (site_name, site_full_address) => {
        site_full_address = site_full_address.split(/, (.*)/s)
        return {
            'richText': [
                {'font': {'bold': true, 'size': 8, 'name': 'Calibri'}, 'text': `${site_name}\n`},
                {'font': {'size': 8, 'name': 'Calibri'}, 'text': `${site_full_address[0]}\n${site_full_address[1]}`}
            ]
        }
    };

    autoColumnWidth = (column) => {
        const lengths = column.values.map(v => v.toString().length);
        const maxLength = Math.max(...lengths.filter(v => typeof v === 'number')); //by default it works for font 11, we use font 8 so we need to convert result
        return maxLength;
    }

    exportToXLSX = async (params) => {
        this.props.actions.downloadCsvForExcel({...params}, async (csvData) => {
            function parseCSV(csvString) {
                const rows = [];
                let currentRow = [];
                let currentCell = '';
                let insideQuotes = false;

                for (let i = 0; i < csvString.length; i++) {
                    const char = csvString[i];

                    if (char === '"') {
                        if (insideQuotes && csvString[i + 1] === '"') {
                            currentCell += '"';
                            i++;
                        } else {
                            insideQuotes = !insideQuotes;
                        }
                    } else if (char === ',' && !insideQuotes) {
                        currentRow.push(currentCell);
                        currentCell = '';
                    } else if (char === '\n' && !insideQuotes) {
                        currentRow.push(currentCell);
                        rows.push(currentRow);
                        currentRow = [];
                        currentCell = '';
                    } else {
                        currentCell += char;
                    }
                }
                currentRow.push(currentCell);
                rows.push(currentRow);
                return rows;
            }

            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Sales dashboard');

            const parsedData = parseCSV(csvData);

            parsedData.forEach(row => {
                worksheet.addRow(row);
            });

            const dollarColumnNames = ["Price", "Total Paid", "Service $"]
            let columnNames = parsedData[0]
            let dollarColumnIndexes = []
            let woNotesColumnIndex = -1
            columnNames.forEach((c) => {
                if (dollarColumnNames.includes(c)) {
                    dollarColumnIndexes.push(columnNames.indexOf(c) + 1)
                }
                if (c === "WO Notes") {
                    woNotesColumnIndex = columnNames.indexOf(c) + 1
                }
            })

            worksheet.eachRow({includeEmpty: false}, (row, rowNumber) => {
                if (rowNumber === 1) {
                    row.eachCell((cell) => {
                        cell.fill = {
                            type: "pattern",
                            pattern: "solid",
                            fgColor: {argb: "bfbfbf"},
                        };
                    })
                }
                if (rowNumber > 1) {
                    dollarColumnIndexes.forEach((dci) => {
                        row.getCell(dci).numFmt = '[$$-409]#,##0.00;-[$$-409]#,##0.00'
                        row.getCell(dci).value = row.getCell(dci).value ? parseFloat(row.getCell(dci).value) : ""
                    })
                }
            })

            worksheet.columns.forEach((column) => {
                if (dollarColumnIndexes.includes(column.number)) {
                    column.width = this.autoColumnWidth(column) + 6;
                } else if (column.number === woNotesColumnIndex) {
                    let autoWidth = this.autoColumnWidth(column) + 2
                    column.width = autoWidth > 50 ? 50 : autoWidth;
                } else {
                    column.width = this.autoColumnWidth(column) + 2;
                }
            })

            worksheet.views = [
                {state: 'frozen', xSplit: 0, ySplit: 1, activeCell: 'A1'}
            ];

            const excelBuffer = await workbook.xlsx.writeBuffer();

            const dataBlob = new Blob([excelBuffer], {type: 'application/octet-stream'});
            saveAs(dataBlob, `sales_dashboard-${params?.tab?.toLowerCase()}-${moment().format(defaultDateFormat)}.xlsx`);
        })
    }


    render() {
        const {dashboardStatuses, durations, users, theme} = this.props;
        const {
            report,
            isBacklogTableOpened,
            data,
            selected,
            selectedFilter,
            filters,
            email,
            isDashboardModalOpened,
            resource,
            service_types,
            services,
            customer_types,
            reasons,
            priorities,
            assignees,
            equipments,
            plants,
            acceptedTotals,
            employees,
            selectedRecords,
            backlogEmailRecipients,
            allSelected,
            isDeclinedModalOpened,
            showDock,
            columnOptions,
            customers,
            loadingData
        } = this.state;
        const {
            date_name,
            from,
            to,
            promisedFrom,
            promisedTo,
            page,
            per_page,
            sort,
            sort_by,
            isBacklog,
            sent_from,
            sent_to,
            onlyProposed,
            excludeScheduled
        } = resource;
        let {visibleColumns} = this.state;
        const selectedStatus = dashboardStatuses[selected].label;
        const filter = filters && filters[selectedFilter];
        const customerOptions = this.customerSearch.searchOptions();
        const proposalOptions = this.proposalSearch.searchOptions();
        const zipOptions = this.zipSearch.searchOptions();
        const cityOptions = this.citySearch.searchOptions();
        const darkThemes = ["cyborg", "darkly", "slate", "solar", "superhero"];
        let trHeight;
        if (window.screen.width >= 1250) {
            trHeight = '390px';
        }
        if (window.screen.width < 1250) {
            trHeight = '410px';
        }

        const backlogFilterStatuses = [
            {value: 'ACCEPTED', label: 'Accepted'},
            {value: 'IN_WORK_ORDER', label: 'In Work Order'},
            {value: 'SCHEDULED', label: 'Scheduled'},
            {value: 'PROPOSED', label: 'Proposed'}
        ];

        const completedFilterStatuses = [
            {value: 'COMPLETED', label: 'Completed'},
            {value: 'INVOICED', label: 'Invoiced'},
            {value: 'PAYMENT', label: 'Payment'},
            {value: 'PAID', label: 'Paid'}
        ];

        let statuses;
        if (resource?.selectedStatus === 'backlog') {
            statuses = backlogFilterStatuses
        } else if (resource?.selectedStatus === 'completed') {
            statuses = completedFilterStatuses
        }


        let backlogProposedFilter = false
        if (resource.ps_status && resource.ps_status.length > 0 && resource.ps_status.some(s => s === "PROPOSED" || s === "ACCEPTED" || s === "IN_WORK_ORDER" || s === "SCHEDULED")) {
            backlogProposedFilter = true
        }

        let recipientsEmail = []
        if (dashboardStatuses[selected].label === 'Backlog') {
            recipientsEmail = backlogEmailRecipients.map(r => {
                    const matchRow = data.find(d => d.proposalId === r.proposalId)
                    return {
                        name: matchRow?.customer_name,
                        email: matchRow?.site_email,
                        proposalNo: matchRow?.proposalNo,
                        proposalServiceId: matchRow?.proposal_service_id,
                        workOrderId: r.workOrderId
                    }
                }
            )
        } else {
            recipientsEmail = data.filter(d => selectedRecords?.includes(d.proposalId)).map(d => {
                return {
                    name: d.customer_name,
                    email: selectedStatus === 'Invoiced' ? d.billing_email : d.site_email,
                    proposalNo: d.proposalNo
                }
            })

        }
        recipientsEmail = [...new Set(recipientsEmail.map(r => JSON.stringify(r)))].map(r => JSON.parse(r))

        const selectedRowsPdf = data
            .filter(d => selectedRecords.includes(d.proposalId))
            .flatMap(d => d.invoices_dates
                ? d.invoices_dates.map(invoice => ({
                    dataPdf: {
                        ...invoice,
                        id: Number(invoice.id)
                    }
                }))
                : []
            );

        const recipients = dashboardStatuses[selected].label === 'Backlog' ? [...new Set(backlogEmailRecipients.map(r => r.proposalId))] : selectedRecords

        const statusColor = dashboardStatuses.find(d => d.label === selectedStatus).color
        const tab = dashboardStatuses[selected].value

        let columnOptionsSelect = columnOptions[tab].map(filter => {
            let result = {};
            result.value = filter;
            result.label = filter;
            return result;
        });

        if (visibleColumns && !visibleColumns[tab]) {
            visibleColumns[tab] = []
        }

        const filterModalProps = {
            values: {
                services: services,
                statuses: statuses,
                reasons: reasons,
                equipments: equipments,
                cityOptions: cityOptions,
                zipOptions: zipOptions,
                priorities: priorities,
                service_types: service_types,
                plants: plants,
                customerOptions: customerOptions,
                customer_types: customer_types,
                employees: employees,
                assignees: assignees,
                proposalOptions: proposalOptions,
                isBacklog: isBacklog,
                backlogProposedFilter: backlogProposedFilter,
                resource: resource,
                isBacklogTableOpened: isBacklogTableOpened,
                dashboardStatuses: dashboardStatuses,
                customers: customers,
                excludeScheduled: excludeScheduled
            },
            func: {
                handleRangeDateChange: this.handleRangeDateChange,
                selectResourceAttr: this.selectResourceAttr,
                citySearch: this.citySearch.search,
                zipSearch: this.zipSearch.search,
                customerSearch: this.customerSearch.search,
                proposalSearch: this.proposalSearch.search,
                setFilterModalParams: this.setFilterModalParams,
                closeModal: this.closeFilterModal,
                selectCheckboxAttr: this.selectCheckboxAttr
            }
        }

        const selectedRows = {
            status: tab,
            records: dashboardStatuses[selected].label === 'Backlog' ? recipientsEmail.map(r => {
                return {proposal_service_id: r.proposalServiceId, work_order_id: r.workOrderId}
            }) : selectedRecords
        }

        return (
            <Grid fluid id='new_sales_dashboard' className={darkThemes.includes(theme) ? "" : "light"}>
                <ReactDock position='right'
                           isVisible={showDock}
                           dimMode="none"
                           defaultSize={window.innerWidth > 990 ? 0.24 : window.innerWidth > 600 ? 0.6 : 1}
                           dockStyle={{overflow: 'hidden'}}
                           zIndex={1031}
                >
                    <div className="close-dock">
                        <h4 className="margin10 pull-left">Sales dashboard settings</h4>
                        <div className="pull-right small-margin">
                            <Glyphicon glyph='remove' className="pointer margin10"
                                       onClick={() => this.setState({showDock: null})}/>
                        </div>
                    </div>
                    <div className="margin10 top40">
                        <Row className="no-margin space-between-end">
                            <Col md={8} className='no-left-padding'>
                                <h5 className={isMobile ? 'top25' : 'top50'}>Select columns to display for <span
                                    className='bold' style={{color: `${statusColor}`}}>{selectedStatus}</span> tab</h5>
                            </Col>
                            <Col md={4} className='text-right no-right-padding'>
                                <Button disabled={visibleColumns[tab].length === columnOptions[tab].length}
                                        className={`recipient-btn ${isMobile ? 'top25' : 'top50'}`} onClick={() => {
                                    let {dashboard_settings} = this.state;
                                    visibleColumns[tab] = columnOptions[tab]
                                    dashboard_settings = visibleColumns

                                    this.setState({
                                        visibleColumns: visibleColumns,
                                        dashboard_settings: dashboard_settings
                                    }, this.saveReportSettings)
                                }}>
                                    <span>Select All</span>
                                </Button>
                            </Col>
                        </Row>
                        <Select className="Select"
                                classNamePrefix="small-select"
                                value={select(columnOptionsSelect, visibleColumns[tab])}
                                options={columnOptionsSelect}
                                isClearable={false}
                                isMulti
                                placeholder="Columns"
                                onChange={this.handleColumnOptionsChange}
                        />
                    </div>
                </ReactDock>
                <Row>
                    <Col md={12}>
                    </Col>
                </Row>
                <Row className="vcenter">
                    <Col md={4} className="vcenter full-width">
                        <h2 className="no-top-margin mr-9">Sales dashboard</h2>
                        <h5 className={smMobile ? "text-primary nmt-10" : "text-primary"}>{report && report[dashboardStatuses[selected].value].count} returned</h5>
                    </Col>
                    <Col md={8} className={smMobile ? "mt-20 full-width text-center" : "text-right full-width"}>
                        <Button
                            className="pointer no-top-margin mr-7"
                            bsStyle="warning"
                            onClick={() => this.setState({isDashboardModalOpened: !isDashboardModalOpened})}
                        >
                            Filter {" "}<FontAwesomeIcon icon="search" className="small-margin fontSize14"/>
                        </Button>
                        <Button
                            className="pointer no-top-margin mr-7"
                            onClick={() => {
                                this.setState({showDock: !showDock})
                            }}>
                            Settings {" "}<FontAwesomeIcon icon={faCog} className="small-margin fontSize14"/>
                        </Button>
                        {isMobile &&
                            <Button className="table_btn no-top-margin"
                                    onClick={() => this.setState(() => ({isBacklogTableOpened: true}))}><FontAwesomeIcon
                                icon={faFilter}/><span className="ml-6">Filter table</span>
                            </Button>}
                    </Col>
                </Row>
                <hr className={'mt0 mr-15 ml-15'}/>
                <LeadsFilterModal
                    title="Sales Dashboard Filter"
                    show={isDashboardModalOpened}
                    onHide={() => this.setState(() => ({
                        resource: {...resource, page: 1},
                        date_name: select(durations, resource.date_name).label,
                        isDashboardModalOpened: !isDashboardModalOpened
                    }), () => this.fetchData())}
                    closeButton={() => this.setState({isDashboardModalOpened: !isDashboardModalOpened})}
                >
                    <Row className={'modalLeadsFilter'}>
                        {
                            selectedStatus === 'Paid' && <Col md={12}
                                                              className={"bottom15 mt-15"}>
                                <span className="font11">{this.statusDateFilterFormatter(selectedStatus)}</span>
                                <PromiseUi
                                    promisedStart={resource.from}
                                    promisedEnd={resource.to}
                                    upsertPromise={(id, start, end) => this.handleRangeDateChange(start, end, 'from', 'to', false)}
                                    proposalServiceId={null}
                                    serviceNo={null}
                                    startPlaceholder="From"
                                    endPlaceholder="To"
                                    timeDelay={300}
                                    index={1}
                                />
                            </Col>
                        }
                    </Row>
                    <Row className="bottom10 mt5 no-right-margin no-left-margin bottom0">

                        <Col md={6} className="mt5 bottom15">
                            <Select className="Select" classNamePrefix="select"
                                    value={select(employees, resource.sales_arborist)}
                                    options={employees}
                                    onChange={this.selectResourceAttr('sales_arborist')}
                                    placeholder="Sales Arborist"
                                    isMulti
                                    isClearable
                            />
                        </Col>
                        <Col md={6} className="mt5 bottom15">
                            <Select className="Select" classNamePrefix="select"
                                    value={select(service_types, resource.service_type)}
                                    options={service_types}
                                    isClearable
                                    isMulti
                                    onChange={this.selectResourceAttr('service_type')}
                                    placeholder="Service type"
                            />
                        </Col>
                    </Row>

                    {selectedStatus === 'Sent' &&
                        <span>
                        <Row className={'modalLeadsFilter'}>
                        <Col md={12} className="mt5 bottom15">
                            <Select className="Select" classNamePrefix="select"
                                    name="date_name"
                                    value={select(durations, date_name)}
                                    options={durations}
                                    onChange={this.selectResourceAttr('date_name')}
                            />
                        </Col>
                    </Row>
                        <div>
                             {resource.date_name && resource.date_name === "custom" &&
                                 <Row className={'modalLeadsFilter'}>
                                     <Col className='ml-15 mr-15 bottom15'>
                                         <span className="font11">Sent date</span>
                                         <PromiseUi
                                             promisedStart={resource.sent_from}
                                             promisedEnd={resource.sent_to}
                                             upsertPromise={(id, start, end) => this.handleRangeDateChange(start, end, 'sent_from', 'sent_to')}
                                             proposalServiceId={null}
                                             serviceNo={null}
                                             startPlaceholder="Sent From"
                                             endPlaceholder="Sent To"
                                             timeDelay={300}
                                             index={1}
                                         />
                                     </Col>
                                 </Row>
                             }
                        </div>
                             <hr className={'mb-10-mt-0 ml-15 mr-15'}/>
                    <Row className="bottom10 no-left-margin">
                        <Col md={12}>
                        <ColorCheckbox value={resource.onlyProposed}
                                       label='Show only proposed'
                                       onChange={this.selectCheckboxAttr('onlyProposed')}
                        />
                        </Col>
                        </Row>
                        </span>
                    }
                </LeadsFilterModal>
                <Row className="text-center top5 no-margin">
                    {report && smMobile ?
                        <div className="btn-group btn-breadcrumb">
                            <Row className={xsMobile ? "mt-10" : "margin-2"}>
                                {dashboardStatuses.map((status, i) => {
                                        return (
                                            <Col xs={3} className="no-padding bottom5">
                                                <a onClick={() => this.selectStatus(i)} key={i}
                                                   className={`btn btn-default btn_statuses ${(selected === 0 && i === 0) || i + 1 === selected ? 'beforeSelected' : ''} ${i === selected ? 'selected' : ''}`}>
                                                    <div>{report[status.value].count} {status.label}</div>
                                                    <div style={{color: status.color}}><Dollars
                                                        amount={report[status.value].amount}/></div>
                                                </a>
                                            </Col>
                                        )
                                    }
                                )}
                            </Row>
                        </div>
                        : report && !smMobile ?
                            <div className="btn-group btn-breadcrumb">
                                {dashboardStatuses.map((status, i) => {
                                    return (
                                        <a onClick={() => this.selectStatus(i)} key={i}
                                           className={`btn btn-default top_nav_btn ${(selected === 0 && i === 0) || i + 1 === selected ? 'beforeSelected' : ''} ${i === selected ? 'selected' : ''}`}>
                                            <div>{report[status.value].count} {status.label}</div>
                                            <div style={{color: status.color}}><Dollars
                                                amount={report[status.value].amount}/></div>
                                        </a>
                                    )
                                })}
                            </div>
                            : null}
                </Row>
                <Row className={smMobile ? "table_margin" : "top50"}>
                    <Col
                        md={!isMobile && selectedStatus === 'Accepted' && (!resource.isBacklog) ? 10 : 12}
                        className={isMobile ? "no-padding" : "pl-20"}>
                        <Row className="tableHeader bottom10">
                            <Col md={8} xs={12} className={isMobile ? "no-padding btn-wrapper" : "pl-20"}>
                                <Button bsStyle="success" className={smMobile ? "table_btn" : "mr-7"}
                                        onClick={() => this.exportToXLSX({
                                            ...resource,
                                            date_name: select(durations, resource.date_name).label,
                                            status: dashboardStatuses[selected].value,
                                            selected_columns: visibleColumns[tab].sort((a, b) => columnOptions[tab].indexOf(a) - columnOptions[tab].indexOf(b)),
                                            visible_columns: columnOptions[tab],
                                            selected: this.state.allSelected ? 'all' : this.state.selectedRecords,
                                            export_all: this.state.exportAll,
                                            tab
                                        })}>Export to XLSX</Button>
                                {['In Draft', 'Sent'].includes(selectedStatus) &&
                                    <Button className={smMobile ? "table_btn" : "mr-7"}
                                            onClick={() => this.setState({isDeclinedModalOpened: !isDeclinedModalOpened})}
                                            disabled={selectedRecords.length === 0}>Decline
                                        Selected</Button>}
                                <Button className="table_btn" onClick={() => {
                                    if (resource.isBacklog) {
                                        if (data && data.length > 0 && selectedRecords && selectedRecords.length > 0) {
                                            let proposals_helper = []
                                            selectedRecords.map(s => {
                                                data.forEach(d => {
                                                    if (allSelected && s === d.proposalId) {
                                                        proposals_helper.push({
                                                            proposalId: d.proposalId,
                                                            workOrdersIds: d.work_orders?.map(wo => wo.id)
                                                        })
                                                    } else if (s === d.id) {
                                                        proposals_helper.push({
                                                            proposalId: d.proposalId,
                                                            workOrdersIds: d.work_orders?.map(wo => wo.id)
                                                        })
                                                    }
                                                })
                                            })

                                            const result = []
                                            if (proposals_helper && proposals_helper.length > 0) {
                                                proposals_helper.forEach((item) => {
                                                    if (item.workOrdersIds.length > 0) {
                                                        item.workOrdersIds.map(wo => result.push({
                                                            proposalId: item.proposalId,
                                                            workOrderId: wo
                                                        }))
                                                    } else {
                                                        result.push({proposalId: item.proposalId, workOrderId: null})
                                                    }
                                                });
                                            }
                                            const resultRecipients = [...new Set(result.map(r => JSON.stringify(r)))].map(r => JSON.parse(r))
                                            this.setState({email: true, backlogEmailRecipients: resultRecipients})
                                        }
                                    } else {
                                        this.setState({email: true})
                                    }
                                }}
                                        disabled={selectedRecords.length === 0}>Email Selected</Button>
                                {!isMobile && <Button className="table_btn ml-7"
                                                      onClick={() => this.setState(() => ({isBacklogTableOpened: true}))}><FontAwesomeIcon
                                    icon={faFilter}/><span className="ml-6">Filter table</span></Button>}
                                {['Backlog'].includes(selectedStatus) &&
                                    <Link to={{
                                        pathname: selectedRecords.length === 0 ? "" : "/map_scheduler",
                                        state: {ids: selectedRecords}
                                    }}>
                                        <Button
                                            className={smMobile ? "table_btn" : "ml-7"}
                                            disabled={selectedRecords.length === 0}
                                        >
                                            Map Scheduler
                                        </Button>
                                    </Link>}
                            </Col>
                            <SalesDashboardTabFilter params={filterModalProps}/>
                            <LeadsFilterModal
                                title="Decline Proposal"
                                show={isDeclinedModalOpened}
                                backdrop="static"
                                declinedModal={true}
                                onHide={() => this.setState(() => ({
                                    resource: {...resource, page: 1},
                                    date_name: select(durations, resource.date_name).label,
                                    isDeclinedModalOpened: !isDeclinedModalOpened
                                }), this.declineSelected)}
                                closeButton={() => this.setState({isDeclinedModalOpened: !isDeclinedModalOpened})}
                            >
                                <div className="rate-modal-body-padding d-flex">
                                    <FontAwesomeIcon icon={faExclamationTriangle}
                                                     className={isMobile ? "font20 alert-font-color mt-3" : "small-margin font20 alert-font-color mt-3"}/>
                                    <div className='columnDirection'>
                                        <p className={isMobile ? "ml-10 font12 line_height17_5 bold" : "ml-10 line_height17_5 bold"}>
                                            Number of records selected: {selectedRecords && selectedRecords.length}
                                        </p>
                                        <p className={isMobile ? "ml-10 font12 line_height17_5" : "ml-10 line_height17_5"}>
                                            Yes. Move all selected proposals and services to "DECLINED" status
                                        </p>
                                    </div>
                                </div>
                            </LeadsFilterModal>
                            <Col md={4} xs={12} className="no-padding">
                                <FormControl
                                    name="q"
                                    placeholder="Search..."
                                    className={smMobile ? "mt10" : ""}
                                    value={resource.q}
                                    onChange={e => this.setState(({
                                        resource: {
                                            ...resource,
                                            q: e.target.value
                                        }
                                    }), this.delayedSearch)}
                                />
                            </Col>
                        </Row>
                        {isMobile && report ?
                            <Col className="remaining vertical-responsive-height">
                                <BootstrapTable
                                    data={data}
                                    fetchInfo={{dataTotalSize: (selectedFilter && acceptedTotals) ? acceptedTotals[selectedFilter].count : report[dashboardStatuses[selected].value].count}}
                                    ref="table"
                                    striped
                                    bordered={false}
                                    hover
                                    remote
                                    pagination
                                    options={
                                        {
                                            sizePerPageDropDown: this.renderSizePerPageDropDown,
                                            onPageChange: (page, per_page) => {
                                                let {resource} = this.state;
                                                resource = {...resource, page, per_page};
                                                this.setState({
                                                    resource,
                                                    exportAll: per_page >= report[dashboardStatuses[selected].value].count
                                                }, () => this.fetchTableData(false));
                                            },
                                            page,
                                            sizePerPageList: [{
                                                text: '10', value: 10
                                            }, {
                                                text: '25', value: 25
                                            },
                                                {
                                                    text: '30', value: 30
                                                },
                                                {
                                                    text: '50', value: 50
                                                },
                                                {
                                                    text: '100', value: 100
                                                },
                                                {
                                                    text: '200', value: 200
                                                },
                                                {
                                                    text: 'All', value: report[dashboardStatuses[selected].value].count
                                                }],
                                            sizePerPage: per_page,
                                            sortName: sort_by,
                                            sortOrder: sort,
                                            onSortChange: (sort_by, sort) => {
                                                let {resource} = this.state;
                                                resource = {...resource, sort_by, sort};
                                                this.setState({resource}, () => this.fetchTableData(false));
                                            }
                                        }
                                    }
                                    className="wrapped"
                                    selectRow={{
                                        mode: "checkbox",
                                        clickToSelect: false,
                                        hideSelectColumn: visibleColumns[tab].length === 0 || loadingData,
                                        selected: this.state.allSelected ? data.map(x => resource.isBacklog ? x.id : x.proposalId) : this.state.selectedRecords,
                                        onSelect: (row, selected) => {
                                            let {selectedRecords, allSelected} = this.state;
                                            const index = selectedRecords.indexOf(resource.isBacklog ? row.id : row.proposalId);
                                            if (index > -1) {
                                                allSelected = false
                                                selectedRecords.splice(index, 1);
                                            } else {
                                                selectedRecords.push(resource.isBacklog ? row.id : row.proposalId);
                                                allSelected = selectedRecords.length === data.length
                                            }
                                            this.setState({selectedRecords, allSelected})
                                        },
                                        onSelectAll: () => {
                                            let {allSelected} = this.state;
                                            this.onSelectAll(!allSelected, data)
                                            this.setState({allSelected: !allSelected})
                                        },
                                    }}
                                >
                                    <TableHeaderColumn hidden dataField="proposalId" isKey={!resource.isBacklog}/>
                                    <TableHeaderColumn hidden dataField="id" isKey={resource.isBacklog}/>
                                    <TableHeaderColumn
                                        dataField="proposalNo"
                                        dataFormat={(cell, row) => this.rowFormatter(cell, row, filter, selectedStatus, selectedFilter)}
                                    >
                                    </TableHeaderColumn>
                                </BootstrapTable>
                            </Col>
                            : !isMobile && report &&
                            <Col className="remaining vertical-responsive-height" style={{overflowX: 'inherit'}}>
                                <BootstrapTable
                                    data={data}
                                    fetchInfo={{dataTotalSize: (selectedFilter && acceptedTotals) ? acceptedTotals[selectedFilter].count : report[dashboardStatuses[selected].value].count}}
                                    ref="table"
                                    striped
                                    bordered={false}
                                    hover
                                    remote
                                    trStyle={{
                                        height: data && data.length === 1 ? `${trHeight}` : null,
                                        fontSize: selectedFilter === "backlog_report" && 12
                                    }}
                                    pagination
                                    options={
                                        {
                                            sizePerPageDropDown: this.renderSizePerPageDropDown,
                                            onPageChange: (page, per_page) => {
                                                let {resource} = this.state;
                                                resource = {...resource, page, per_page};
                                                this.setState({
                                                    resource,
                                                    exportAll: per_page >= report[dashboardStatuses[selected].value].count
                                                }, () => this.fetchTableData(false));
                                            },
                                            page,
                                            sizePerPageList: [{
                                                text: '10', value: 10
                                            }, {
                                                text: '25', value: 25
                                            },
                                                {
                                                    text: '30', value: 30
                                                },
                                                {
                                                    text: '50', value: 50
                                                },
                                                {
                                                    text: '100', value: 100
                                                },
                                                {
                                                    text: '200', value: 200
                                                },
                                                {
                                                    text: 'All', value: report[dashboardStatuses[selected].value].count
                                                }],
                                            sizePerPage: per_page,
                                            sortName: sort_by,
                                            sortOrder: sort,
                                            onSortChange: (sort_by, sort) => {
                                                let {resource} = this.state;
                                                resource = {...resource, sort_by, sort};
                                                this.setState({resource}, () => this.fetchTableData(false));
                                            }
                                        }
                                    }
                                    className="wrapped"
                                    selectRow={{
                                        columnWidth: '30px',
                                        mode: "checkbox",
                                        clickToSelect: false,
                                        hideSelectColumn: visibleColumns[tab].length === 0 || loadingData,
                                        selected: this.state.allSelected ? data.map(x => resource.isBacklog ? x.id : x.proposalId) : this.state.selectedRecords,
                                        onSelect: (row, selected) => {
                                            let {selectedRecords, allSelected} = this.state;
                                            const index = selectedRecords.indexOf(resource.isBacklog ? row.id : row.proposalId);
                                            if (index > -1) {
                                                allSelected = false
                                                selectedRecords.splice(index, 1);
                                            } else {
                                                selectedRecords.push(resource.isBacklog ? row.id : row.proposalId);
                                                allSelected = selectedRecords.length === data.length
                                            }

                                            this.setState({selectedRecords, allSelected})
                                        },
                                        onSelectAll: () => {
                                            let {allSelected} = this.state;
                                            this.onSelectAll(!allSelected, data)
                                            this.setState({allSelected: !allSelected})
                                        }
                                    }}
                                >
                                    <TableHeaderColumn
                                        width={selectedStatus === 'Accepted' ? "15%" : selectedStatus === 'In Draft' ? "25%" : "10%"}
                                        dataField="customer_name" dataSort
                                        hidden={!visibleColumns[tab].includes('Customer')}
                                        dataFormat={customerNameFormatter}
                                    >
                                        Customer
                                    </TableHeaderColumn>
                                    <TableHeaderColumn dataField="status" width="10%"
                                                       hidden={!(selectedStatus === 'Sent'
                                                           || (selectedStatus === 'In Draft')
                                                           || (selectedStatus === 'Paid')
                                                           || (selectedStatus === 'Invoiced')
                                                           || (!isBacklog)
                                                       ) || !visibleColumns[tab].includes('Services')}
                                                       dataSort
                                                       dataFormat={(cell, row) => this.serviceFormatter(cell, row)}
                                    >Services</TableHeaderColumn>
                                    <TableHeaderColumn dataField="service_name" width="10%" dataSort
                                                       dataFormat={(cell, row) => this.serviceNameFormatter(cell, row, "service_name")}
                                                       hidden={!isBacklog || !visibleColumns[tab].includes('Service')}
                                    >Service</TableHeaderColumn>
                                    <TableHeaderColumn dataField="service_type" width="10%" dataSort
                                                       dataFormat={(cell, row) => this.serviceNameFormatter(cell, row, "service_type")}
                                                       hidden={!isBacklog || !visibleColumns[tab].includes('Service Type')}
                                    >Service Type</TableHeaderColumn>
                                    <TableHeaderColumn dataField="service_amount" width="10%" dataSort
                                                       dataFormat={(cell, row) => this.serviceNameFormatter(cell, row, "service_amount")}
                                                       hidden={!isBacklog || !visibleColumns[tab].includes('Service $')}
                                    >Service $</TableHeaderColumn>
                                    <TableHeaderColumn dataField="service_mhrs" width="5%" dataSort
                                                       dataFormat={(cell, row) => this.serviceNameFormatter(cell, row, "service_mhrs")}
                                                       hidden={!isBacklog || !visibleColumns[tab].includes('MHRS')}
                                    >MHRS</TableHeaderColumn>
                                    <TableHeaderColumn dataField="sent_date" width="10%" dataSort
                                                       dataFormat={dateFormatter}
                                                       hidden={!(selectedStatus === 'Sent') || !visibleColumns[tab].includes('Sent Date')}>Sent
                                        Date</TableHeaderColumn>
                                    <TableHeaderColumn hidden dataField="proposalId" isKey={!resource.isBacklog}/>
                                    <TableHeaderColumn hidden dataField="id" isKey={resource.isBacklog}/>
                                    <TableHeaderColumn
                                        dataField="email_count"
                                        width={visibleColumns[tab].includes('Email') ? "5%" : "10%"}
                                        dataSort
                                        dataFormat={(cell, row) => this.sentFormatter(cell, row)}
                                        hidden={!(selectedStatus === 'Sent') || !visibleColumns[tab].includes('#Sent')}
                                    >#Sent</TableHeaderColumn>
                                    <TableHeaderColumn dataField="last_follow_up_date" width="10%" dataSort
                                                       dataFormat={(cell, row) => this.lastSentFormatter(cell, row)}
                                                       hidden={!(selectedStatus === 'Sent') || !visibleColumns[tab].includes('Due on')}
                                    >Due on</TableHeaderColumn>
                                    <TableHeaderColumn dataField="complete" width="10%" dataSort
                                                       dataFormat={(cell, row) => this.sentCompleteFormatter(cell, row)}
                                                       hidden={!(selectedStatus === 'Sent') || !visibleColumns[tab].includes('Completed')}
                                    >Completed</TableHeaderColumn>
                                    <TableHeaderColumn
                                        width="10%"
                                        dataField="price"
                                        dataFormat={dollarsFormatter}
                                        dataSort
                                        hidden={isBacklog || !visibleColumns[tab].includes('Price')}
                                    >
                                        Price
                                    </TableHeaderColumn>
                                    <TableHeaderColumn width="10%" dataField="service_status" dataSort
                                                       dataFormat={(cell, row) => this.serviceNameFormatter(cell, row, "service_status")}
                                                       hidden={!isBacklog || !visibleColumns[tab].includes('Status')}>
                                        Status
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        width={visibleColumns[tab].includes('Email') || visibleColumns[tab].includes('Phone Number') ? "10%" : "15%"}
                                        dataField="service_priority"
                                        dataSort
                                        dataFormat={(cell, row) => this.serviceNameFormatter(cell, row, "service_priority")}
                                        hidden={!isBacklog || !visibleColumns[tab].includes('Priority')}>
                                        Priority
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        width="10%"
                                        dataField="wo#"
                                        dataFormat={(cell, row) => this.workOrderFormatter(cell, row)}
                                        dataSort
                                        hidden={!isBacklog || !visibleColumns[tab].includes('WO#')}>
                                        WO#
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        width="10%"
                                        dataField="proposalNo"
                                        dataFormat={(x, y) => proposalNoFormatterWithEmail(x, y, this.email, filter && ['unscheduled', 'scheduled'].includes(filter.key))}
                                        dataSort
                                        hidden={!visibleColumns[tab].includes('Proposal #')}
                                    >
                                        Proposal #
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        width={selectedStatus === 'In Draft' ? "10%" : "5%"}
                                        dataField={isBacklog ? "accepted_date" : "created_at"}
                                        dataFormat={ageFormatter}
                                        dataSort
                                        hidden={(selectedStatus === 'Invoiced') || (selectedStatus === 'Paid') || !visibleColumns[tab].includes('Age')}
                                    >
                                        Age
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        width={"15%"}
                                        dataField="invoices_dates"
                                        dataFormat={this.invoiceAgeFormatter}
                                        dataSort
                                        hidden={!(selectedStatus === 'Invoiced' || selectedStatus === 'Paid') || !visibleColumns[tab].includes('Age')}
                                    >
                                        Age
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        width="15%"
                                        dataField="created_at"
                                        dataFormat={dateFormatter}
                                        dataSort
                                        hidden={isBacklog || !visibleColumns[tab].includes('Proposal created')}
                                    >
                                        Proposal created
                                    </TableHeaderColumn>

                                    <TableHeaderColumn
                                        width="20%"
                                        dataField="partial_payments"
                                        dataFormat={(cell, row) => this.paidFormatter(cell, row, selectedStatus)}
                                        dataSort
                                        hidden={selectedStatus !== 'Paid' || !visibleColumns[tab].includes('Paid Dates')}
                                    >
                                        Paid Dates
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        width="20%"
                                        dataField="total_paid"
                                        dataSort
                                        dataFormat={(cell, row) => this.totalPaidFormatter(cell, row)}
                                        hidden={selectedStatus !== 'Paid' || !visibleColumns[tab].includes('Total Paid')}
                                    >
                                        Total Paid
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        width="20%"
                                        dataField="completed_at"
                                        dataFormat={dateFormatter}
                                        dataSort
                                        hidden={selectedStatus !== 'Completed' || !visibleColumns[tab].includes('Completed at')}
                                    >
                                        Completed at
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        width="20%"
                                        dataSort
                                        dataField="invoiced_at"
                                        dataFormat={(cell, row) => this.paidFormatter(cell, row, selectedStatus)}
                                        hidden={selectedStatus !== 'Invoiced' || !visibleColumns[tab].includes('Invoice created')}
                                    >
                                        Invoice created
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        width="15%"
                                        dataField="accepted_date"
                                        dataFormat={dateFormatter}
                                        dataSort
                                        hidden={!isBacklog || !visibleColumns[tab].includes('Accepted')}
                                    >
                                        Accepted
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        width={visibleColumns[tab].includes('Email') || visibleColumns[tab].includes('Phone Number') ? "10%" : "20%"}
                                        dataField={"service_promised_from"}
                                        dataFormat={(cell, row) => {
                                            return this.serviceNameFormatter(cell, row, "service_promised_dates")
                                        }}
                                        dataSort
                                        hidden={!(isBacklog) || !visibleColumns[tab].includes('Promised')}
                                    >
                                        Promised
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        width='10%'
                                        dataField={"event_dates"}
                                        dataSort
                                        dataFormat={(cell, row) => this.serviceNameFormatter(cell, row, "service_event_dates")}
                                        hidden={!(isBacklog) || !visibleColumns[tab].includes('Scheduled on')}
                                    >
                                        Scheduled on
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        width='10%'
                                        dataField={"assignee"}
                                        dataSort
                                        hidden={!(isBacklog) || !visibleColumns[tab].includes('Assignee')}
                                    >
                                        Assignee
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        width={visibleColumns[tab].includes('Email') ? "15%" : "20%"}
                                        dataField="work_orders"
                                        dataFormat={this.linkFormatter}
                                        dataSort
                                        hidden={(selectedStatus !== 'Paid' && selectedStatus !== 'Invoiced') || !visibleColumns[tab].includes('WOs')}
                                    >
                                        WOs
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        width="20%"
                                        dataField="wo_notes"
                                        dataFormat={(cell, row) => this.woNotesFormatter(cell, row, selectedStatus)}
                                        dataSort
                                        hidden={['In Draft', 'Sent', 'Paid'].includes(selectedStatus) || !visibleColumns[tab].includes('WO Notes')}>
                                        WO Notes
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        width={visibleColumns[tab].includes('Email') ? "15%" : "20%"}
                                        dataField="invoices_no"
                                        dataFormat={this.linkFormatter}
                                        dataSort
                                        hidden={(selectedStatus !== 'Paid' && selectedStatus !== 'Invoiced') || !visibleColumns[tab].includes('Invoices')}
                                    >
                                        Invoices
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        width={visibleColumns[tab].includes('Email') || isBacklog ? "15%" : "20%"}
                                        dataField="arborist"
                                        dataSort
                                        hidden={!visibleColumns[tab].includes('Arborist')}
                                    >
                                        Arborist
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        width="15%"
                                        dataField="site"
                                        dataSort
                                        style={{color: 'blue'}}
                                        hidden={!isBacklog || !visibleColumns[tab].includes('Site')}
                                    >
                                        Site
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        width={"15%"}
                                        dataField={selectedStatus === 'Invoiced' ? "billing_email" : "site_email"}
                                        dataFormat={(cell, row) => {
                                            return <span className="word-break">{cell}</span>
                                        }}
                                        dataSort
                                        hidden={!visibleColumns[tab].includes('Email')}
                                    >
                                        Email
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        width={"10%"}
                                        dataField="phone_number"
                                        dataFormat={(cell, row) => {
                                            return <span className="word-break">{cell}</span>
                                        }}
                                        dataSort
                                        hidden={!visibleColumns[tab].includes('Phone Number')}
                                    >
                                        Phone Number
                                    </TableHeaderColumn>
                                </BootstrapTable>
                            </Col>}
                    </Col>
                </Row>
                {
                    email && <EmailDialog defaultEmail={this.props.email}
                                          bulkEmailData={{recipients, selectedRows}}
                                          isBulk={true}
                                          sendEmail={() => null}
                                          dataPdf={selectedRowsPdf || null}
                                          sendBulkNotification={(email, callback) => {
                                              this.props.actions.sendBulkNotification(email, recipients, "bulk", (res) => {
                                                  callback && callback(res.data)
                                              })
                                          }}
                                          templateMode
                                          referenceId={(resource.selectedStatus === "invoiced" || resource.selectedStatus === 'paid') && data
                                              .filter(item => selectedRecords.includes(item.proposalId))
                                              .flatMap(item => item?.invoices?.map(invoice => Number(invoice.id)) || [])
                                          }
                                          emailType={resource.selectedStatus === "invoiced" ? 'bulk_invoiced' : 'bulk'}
                                          bulkEmailType={resource.selectedStatus}
                                          confirmMessage={true}
                                          selectedRecords={recipientsEmail}
                                          hideEmailDialog={() => {
                                              this.setState({email: null});
                                          }}
                                          sendInBackground={true}
                    />
                }
            </Grid>
        );
    }
}

SalesDashboard.propTypes = {
    dashboardStatuses: PropTypes.object.isRequired,
    durations: PropTypes.array.isRequired,
    users: PropTypes.array.isRequired
};

SalesDashboard.defaultProps = {
    dashboardStatuses: dashboardStatuses(),
    durations: [
        {value: 'all', label: 'All'},
        {value: 'today', label: 'Today'},
        {value: 'yesterday', label: 'Yesterday'},
        {value: 'current_week', label: 'Current Week'},
        {value: 'last_week', label: 'Last Week'},
        {value: 'current_month', label: 'Current Month'},
        {value: 'last_month', label: 'Last Month'},
        {value: 'current_year', label: 'Current Year'},
        {value: 'last_year', label: 'Last Year'},
        {value: 'custom', label: 'Custom'}
    ]
};

function mapStateToProps(state, ownProps) {
    return {
        users: state.users.employees,
        email: state.auth.email,
        theme: state.theme.theme,
        state
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SalesDashboard);
