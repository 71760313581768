import {ROLES} from "../../../constants";


export const checkTerms = (resources, roles) => {
    const termsAcceptance = {
        checked: false,
        admin: false,
        user: false
    }

    termsAcceptance.admin = roles.includes(ROLES.superAdmin) || !roles.includes(ROLES.admin) ||
        resources.admin_term.term_accepted;
    termsAcceptance.user = roles.includes(ROLES.superAdmin) ||
        roles.includes(ROLES.subcontractor) && roles.length === 1 ||
        roles.includes(ROLES.crew) && roles.length === 1 ||
        roles.includes(ROLES.crew) && roles.includes(ROLES.subcontractor) && roles.length === 2
        || resources.user_term.term_accepted;
    termsAcceptance.checked = true;

    return termsAcceptance;
}