import React from 'react';
import {Button, Col, Modal, Row} from "react-bootstrap";
import './TermsModal.scss'

export const TermsModal = ({show, onHide, overflow, title, modalBodyStyle, children, closeButton}) => {

    return (
        <Modal
            animation={false}
            show={show}
            onHide={onHide}
            className="terms-modal"
            style={overflow}
            backdrop="static"
        >
            <Modal.Header className='terms-modal-header'>
                <Modal.Title className='terms-modal-title'>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={modalBodyStyle} className='terms-modal-body'>
                {children}
            </Modal.Body>

            <Modal.Footer>
                <Row>
                    <Col md={12} className='textCenter'>
                        <Button className='terms-modal-btn' type="button" onClick={closeButton}
                                data-dismiss="modal">DECLINE
                        </Button>
                        <Button className='terms-modal-btn' type="button" onClick={onHide}
                                bsStyle="primary">ACCEPT</Button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}
