import React, {Component} from "react"
import * as MyActions from "./NewInvoicePrintApi"
import {connect} from "react-redux";
import {bindActionCreators} from 'redux'
import {NewInvoicePrintOutput} from "./NewInvoicePrintOutput";

class PdfPrintInvoicePage extends Component {

    state = {};

    componentWillMount = () => {
        this.props.actions.fetchInvoice(this.props.params.token, (invoice) => {
            invoice.site_maps.forEach(sitemap => {
                const property = {};
                property[`maps_${sitemap.id}`] = sitemap.include_when_print;
                this.setState(property);
            });
            this.setState({invoice})
        }, true);
    };

    render() {
        const {invoice} = this.state;
        if (!invoice) {
            return <div>Loading...</div>
        }
        return <NewInvoicePrintOutput logoHeight={invoice && invoice.logo_height}
                                      logoPosition={invoice && invoice.logo_position}
                                      invoice={invoice && invoice}
                                      options={invoice && JSON.parse(invoice.print_options)}
                                      client={this.props.client}/>
    }
}

const mapStateToProps = state => {
    return {
        client: state.client.customerInfo
    }
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(MyActions, dispatch),
});


export default connect(mapStateToProps, mapDispatchToProps)(PdfPrintInvoicePage)
