import {Button, Col, Modal, Row} from "react-bootstrap";
import React from "react";
import ResourceComponent from "../../../../components/ResourceComponent";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import {dateFormatter} from "../../../../components/BootStrapTableCellFormatters";
import * as userActions from "./UsersListApi";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

const isMobile = window.screen.width <= 1024;
const actions = {...userActions}

class ActivitiesModal extends ResourceComponent {
    constructor(props) {
        super(props);
        this.state = {
            resource: {
                page: 1,
                per_page: 10
            },
        };
    }

    reload = () => {
        const {user, selectAll} = this.props;
        const {page, per_page} = this.state.resource;
        const params = {
            page: page,
            per_page: per_page,
            user_id: selectAll ? null : user
        };
        if (this.props) {
            this.props.actions.loadUserActivities(params, (result) => {
                this.setState({activities: result.activities, total: result.total})
            })
        }
    };

    indexN = (cell, row, enumObject, index) => {
        return (<div>{index + 1}</div>)
    }

    rowFormatter = (cell, row, enumObject, index) => {
        const {selectAll} = this.props;
        return <div>
            <Row>
                <Col xs={6}>#</Col>
                <Col xs={6}>{this.indexN(cell, row, enumObject, index)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Date/Time</Col>
                <Col xs={6}>{dateFormatter(row.created_at)}</Col>
            </Row>
            {selectAll && <Row>
                <Col xs={6}>User</Col>
                <Col xs={6}>{row.user}</Col>
            </Row>}
            <Row>
                <Col xs={6}>Activity</Col>
                <Col xs={6}>{row.activity}</Col>
            </Row>
        </div>
    };

    render() {
        const {selectAll, user} = this.props;
        const {activities, total} = this.state;
        const {page, per_page} = this.state.resource;
        return (
            <Modal
                {...this.props}
                bsSize="large"
                animation={true}
                onEntered={() => {
                    const params = {
                        page: 1,
                        per_page: per_page,
                        user_id: selectAll ? null : user
                    };
                    if (this.props) {
                        this.props.actions.loadUserActivities(params, (result) => {
                            this.setState({activities: result.activities, total: result.total})
                        })
                    }
                }}
                onHide={() => this.props.onHide()}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{`Activities`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        {activities && activities.length > 0 && <Col xs={12}>
                            <BootstrapTable
                                ref="table"
                                data={activities}
                                striped={true}
                                bordered={false}
                                hover={true}
                                pagination={true}
                                fetchInfo={{dataTotalSize: total}}
                                remote
                                className={`wrapped ${selectAll && `full-height`}`}
                                options={
                                    {
                                        onPageChange: (page, per_page) => {
                                            let {resource} = this.state;
                                            resource = {...resource, page, per_page};
                                            this.setState({resource}, this.reload);
                                        },
                                        page,
                                        sizePerPage: per_page,
                                        sizePerPageDropDown: this.renderSizePerPageDropDown,
                                        sizePerPageList: [{
                                            text: '10', value: 10
                                        }, {
                                            text: '25', value: 25
                                        },
                                            {
                                                text: '30', value: 30
                                            },
                                            {
                                                text: '50', value: 50
                                            },
                                            {
                                                text: '100', value: 100
                                            },
                                            {
                                                text: '200', value: 200
                                            },
                                            {
                                                text: 'All', value: total
                                            }],
                                    }
                                }
                            >
                                <TableHeaderColumn
                                    dataField="id"
                                    hidden
                                    isKey
                                />
                                <TableHeaderColumn
                                    dataField="created_at"
                                    dataFormat={dateFormatter}
                                    width={!selectAll ? "25%" : "50%"}
                                >
                                    Date/Time
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="user"
                                    width={"25%"}
                                    hidden={!selectAll}
                                >
                                    User
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="activity"
                                    width={"50%"}
                                >
                                    Activity
                                </TableHeaderColumn>
                            </BootstrapTable>
                        </Col>}
                    </Row>
                </Modal.Body>

                <Modal.Footer>
                    <Row>
                        <Col md={6} className="text-left">
                            <Button bsSize="small" onClick={() => this.props.onHide()}>Close</Button>
                        </Col>
                    </Row>

                </Modal.Footer>
            </Modal>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

export default connect(null, mapDispatchToProps)(ActivitiesModal);
