import React, {Component} from 'react';
import PropTypes from "prop-types"
import {Button, Table} from "react-bootstrap";
import Attachment from "../../components/image/Attachment";
import SiteHazardForm from "./SiteHazardForm";
import NewAttachment from "../../components/image/Attachment/NewAttachment";


class SiteHazardTable extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            resource: props.resource
        };
    }

    delete = r => {
        this.props.deleteSiteHazard(r, () => this.setState({resource: null, newImages: []}));
    };

    render() {
        const {
            hazards,
            siteHazards,
            siteId,
            assetObjectId,
            rotateImage,
            reloadData,
            removeImage,
            setCwoAndProposalVisibleSiteHazard,
            lockedCrewEditAccess,
            oldAttachment
        } = this.props;
        const {resource} = this.state;
        return (
            <div>
                <Table striped hover>
                    <thead>
                    <tr>
                        <th width="40%">Hazard</th>
                        <th width="40%">Image</th>
                        <th width="20%">Options</th>
                    </tr>
                    </thead>
                    <tbody>
                    {siteHazards.map(h =>
                        (resource && h.id === resource.id) ?
                            <SiteHazardForm key={h.id} resource={resource}
                                            newImages={this.props.newImages}
                                            setNewImages={this.props.setNewImages}
                                            save={(r, fromUpload) => {
                                                this.props.saveSiteHazard(r, null, fromUpload)
                                            }}
                                            removeImages={this.props.removeImages}
                                            cancel={() => this.setState({resource: null, newImages: []})}
                                            hazards={hazards}
                                            uploadAttachment={this.props.uploadAttachment}
                                            delete={this.delete}
                                            reloadData={reloadData}
                            />
                            :
                            <tr key={h.key}>
                                <td>{h.hazard_name}</td>
                                {oldAttachment ?
                                    <td>{h.images.sort((a, b) => a.order - b.order)
                                        .filter(i => lockedCrewEditAccess ? i.cwo_and_proposal_display === true : i)
                                        .map(i =>
                                            <Attachment key={i.id}
                                                        image={i}
                                                        lockedCrewEditAccess={lockedCrewEditAccess}
                                                        reloadData={reloadData}
                                                        rotateImage={rotateImage}
                                                        deleteImage={imageId => {
                                                            removeImage(imageId)
                                                            h.images = siteHazards.find(sh => sh.id === h.id).images.filter(i => i.id !== imageId)
                                                            this.props.saveSiteHazard(h, null, true)
                                                        }}/>)}
                                    </td>
                                    : <td>{h.images.sort((a, b) => a.order - b.order)
                                        .filter(i => lockedCrewEditAccess ? i.cwo_and_proposal_display === true : i)
                                        .map(i =>
                                            <NewAttachment key={i.id}
                                                           image={i}
                                                           rotateImage={rotateImage}
                                                           reloadData={reloadData}
                                                           lockedCrewEditAccess={lockedCrewEditAccess}
                                                           reorderImages={() => this.props.reorderHazardImages(i.id, h)}
                                                           deleteImage={imageId => {
                                                               removeImage(imageId)
                                                               h.images = siteHazards.find(sh => sh.id === h.id).images.filter(i => i.id !== imageId)
                                                               this.props.saveSiteHazard(h, null, true)
                                                           }}
                                                           saveImage={(file, callback) => {
                                                               this.props.uploadAttachment(h.id, file, data => {
                                                                   data = {
                                                                       ...data,
                                                                       order: h.images.length,
                                                                       cwo_and_proposal_display: true
                                                                   }
                                                                   h.images.push(data);
                                                                   this.props.saveSiteHazard(h, null, true)
                                                               })
                                                           }}
                                                           setVisible={visible => {
                                                               setCwoAndProposalVisibleSiteHazard(siteId, h.id, i.id, visible, (res) => {
                                                                   h.images = siteHazards.find(sh => sh.id === h.id).images.map(img => {
                                                                       if (img.id === i.id)
                                                                           return ({
                                                                               ...img,
                                                                               cwo_and_proposal_display: res.cwo_and_proposal_display
                                                                           })
                                                                       return img
                                                                   })
                                                                   this.props.saveSiteHazard(h)
                                                               })
                                                           }}
                                            />)}</td>}

                                <td>
                                    <Button
                                        bsSize="xsmall"
                                        bsStyle="success"
                                        className="top5 full-width"
                                        onClick={() => {
                                            this.setState({
                                                resource: {
                                                    ...h,
                                                    site_id: siteId,
                                                    asset_object_id: assetObjectId
                                                },
                                            })
                                            this.props.setNewImages([])
                                        }}
                                    >
                                        Edit
                                    </Button>
                                </td>
                            </tr>
                    )}
                    {resource && !resource.id &&
                        <SiteHazardForm resource={this.state.resource}
                                        newImages={this.props.newImages}
                                        setNewImages={this.props.setNewImages}
                                        removeImages={this.props.removeImages}
                                        save={r => this.props.saveSiteHazard(r)}
                                        cancel={() => {
                                            this.setState({resource: null})
                                        }}
                                        hazards={hazards}
                                        uploadAttachment={this.props.uploadAttachment}
                                        delete={this.delete}
                                        reloadData={reloadData}
                        />
                    }
                    {!resource &&
                        <tr>
                            <td/>
                            <td/>
                            <td>
                                <Button
                                    bsSize="xsmall"
                                    bsStyle="success"
                                    className="pull-right"
                                    onClick={() => {
                                        this.setState({
                                            resource: {
                                                images: [],
                                                site_id: siteId,
                                                asset_object_id: assetObjectId
                                            },
                                        })
                                        this.props.setNewImages([])
                                    }}
                                >
                                    Add New
                                </Button>
                            </td>
                        </tr>
                    }
                    </tbody>
                </Table>
            </div>
        );
    }
}

SiteHazardTable.propTypes = {
    saveSiteHazard: PropTypes.func.isRequired,
    deleteSiteHazard: PropTypes.func.isRequired,
    newImages: PropTypes.array.isRequired,
    setNewImages: PropTypes.func.isRequired,
    removeImages: PropTypes.func.isRequired,
    uploadAttachment: PropTypes.func.isRequired,
    siteHazards: PropTypes.array.isRequired,
    hazards: PropTypes.array.isRequired,
    siteId: PropTypes.number,
    assetObjectId: PropTypes.number,
};

SiteHazardTable.defaultProps = {
    //myProp: <defaultValue>
};

export default SiteHazardTable;