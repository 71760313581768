import React, {useCallback, useEffect, useState} from 'react'
import {
    Button,
    Col,
    ControlLabel,
    FormControl,
    Glyphicon,
    OverlayTrigger,
    Popover,
    Row,
    Tab,
    Tabs
} from "react-bootstrap";
import PageHeader from "../../../../components/PageTitle";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import NewDocumentModal from "./NewDocumentModal";
import * as actions from "./DocumentsApi";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import './Documents.scss'
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import AttachmentThumbnail from "../../../../components/ReorderingDragAndDrop/AttachmentThumbnail/AttachmentThumbnail";
import DragAndDropTable from "../../../../components/DragAndDropTable/DragAndDropTable";
import MDSpinner from "react-md-spinner";
import ConfirmDeletionModal from "./ConfirmDeletionModal";
import EditTabModal from "./EditTabModal";
import Select from "react-select";
import {select} from "../../../../common/commonHandlers";
import {debounce} from "throttle-debounce";
import HistoryModal from "./HistoryModal";
import Dropzone from "react-dropzone";
import ReorderingDragAndDrop from "../../../../components/ReorderingDragAndDrop/ReorderingDragAndDrop";
import {boxesPerRowReorderingDNDDocuments} from "../../../CustomerPage/CustomerInfo/constants";
import _ from 'lodash'

const isMobile = window.screen.width <= 768;

const Documents = ({actions, currentClient}) => {
    const [resource, setResource] = useState({
        editedRow: null,
        page: 1,
        per_page: 10,
        total: 0,
        usedTabs: [],
        is_any_deleted: false,
        selectedTab: null,
        q: ''
    })
    const [data, setData] = useState([])
    const [newDocumentModal, setNewDocumentModal] = useState({show: false, data: null})
    const [tabs, setTabs] = useState(currentClient.client_documents_tab ? currentClient.client_documents_tab : [{
        eventKey: 1,
        title: "Documents"
    }])
    const [key, setKey] = useState(currentClient.client_documents_tab ? currentClient.client_documents_tab[0]?.eventKey : 1)
    const [client, setClient] = useState(currentClient)
    const [ready, setReady] = useState(true)
    const [temporaryRow, setTemporaryRow] = useState({})
    const [showDeletedModal, setShowDeletedModal] = useState({show: false, id: null})
    const [showDeletedFileModal, setShowDeletedFileModal] = useState({show: false, id: null})
    const [showEditModal, setShowEditModal] = useState({show: false, data: null})
    const [showHistoryModal, setShowHistoryModal] = useState({show: false, data: null})

    const reload = (tab, callback) => {
        actions.getClientDocuments({...resource, tab: tab}, res => {
            setData(res.data)
            setResource({
                ...resource,
                editedRow: null,
                total: res.total,
                usedTabs: res.used_tabs,
                is_any_deleted: res.is_any_deleted
            })
            if (key === 'deleted' && !res.is_any_deleted) {
                setKey(tabs[0]?.eventKey)
            }
            callback && callback()
        })
    }

    const onDocumentSearch = useCallback(debounce(1000, false, q => setResource({...resource, q: q})), [])

    const newTab = e => {
        e.preventDefault()
        const maxEventKey = client.client_documents_tab.map(t => t.eventKey)
        const maxKey = maxEventKey && maxEventKey.length > 0 && Math.max(...maxEventKey) + 1
        const maxValue = maxKey ? maxKey : 1
        client.client_documents_tab.push({eventKey: maxValue, title: "Documents"})
        setClient(client)
        setTabs(client.client_documents_tab)
        actions.updateClient(client, () => {
            setKey(maxValue)
        })
    }

    const removeTab = i => {
        const newTabs = tabs.filter(tab => tab.eventKey !== i)
        setTabs(newTabs)

        client.client_documents_tab = newTabs
        setClient(client)
        actions.updateClient(client, () => {
            if (i === key) {
                setKey(newTabs[0]?.eventKey)
            }
        })
    }

    const updateDocument = (e, attr) => {
        temporaryRow[attr] = e.target.value
        setTemporaryRow({...temporaryRow})
    }

    const titleFormatter = (cell, row) => {
        if (row.id === resource?.editedRow) {
            return <FormControl
                name="title"
                placeholder="Title"
                onChange={e => updateDocument(e, 'title')}
                value={temporaryRow.title}
            />
        } else {
            return cell ? <span className='document-title'>{cell}</span> : <i>Empty title</i>
        }
    }

    const noteFormatter = (cell, row) => {
        if (row.id === resource?.editedRow) {
            return <FormControl
                componentClass="textarea"
                rows={5}
                type="text"
                name="note"
                placeholder="Note"
                onChange={e => updateDocument(e, 'note')}
                value={temporaryRow.note}
            />
        } else {
            return cell ? <span className='document-note'>{cell}</span> : <i>Empty note</i>
        }
    }

    const originalFormatter = (cell, row) => {
        return cell ? cell : <i>Tab has been removed!</i>
    }

    const fileFormatter = (cell, row) => {
        if (row.id === resource?.editedRow) {
            return <div className='document-file'>
                <Dropzone
                    onDrop={file => actions.uploadClientFile(file, res => {
                        if (Array.isArray(temporaryRow?.document_files_ids)) {
                            const newClientFiles = temporaryRow?.document_files_ids?.push(res.id)
                        } else {
                            temporaryRow.document_files_ids = [res.id]
                        }
                        if (Array.isArray(temporaryRow?.files)) {
                            const newFiles = temporaryRow?.files.push(res)
                        } else {
                            temporaryRow.files = [res]
                        }
                        setTemporaryRow({...temporaryRow})
                    })}
                    className="dropzone pointer"
                >
                    <p>Drop a file here or click to select a file to upload.</p>
                </Dropzone>
                <ReorderingDragAndDrop
                    files={temporaryRow?.files ? temporaryRow?.files?.sort((a, b) => a.order - b.order) : []}
                    deleteAttachment={(id) => {
                        if (row.document_files_ids.includes(id)) {
                            setShowDeletedFileModal({show: true, id: id})
                        } else {
                            actions.deleteClientFile(id, () => {
                                temporaryRow.document_files_ids = temporaryRow?.document_files_ids?.filter(cf => cf !== id)
                                temporaryRow.files = temporaryRow?.files.filter(f => f.id !== id)

                                setTemporaryRow({...temporaryRow})
                            })
                        }
                    }}
                    downloadButton={true}
                    reorderAttachments={(orderedFiles) => {
                        temporaryRow.document_files_ids = orderedFiles.map((image) => image.id)
                    }}
                    numberOfColumns={boxesPerRowReorderingDNDDocuments}
                />
            </div>
        } else {
            return <div className='document-file'>
                {cell && cell.map(doc => {
                    return <AttachmentThumbnail
                        key={doc.id}
                        file={doc}
                        allowRemove={false}
                        deleteAttachment={() => {
                        }}
                        downloadButton={true}
                    />
                })}
            </div>
        }
    }

    const actionsFormatter = (cell, row) => {
        const options = tabs.filter(x => +x.eventKey !== +row.tab).map(x => ({value: x.eventKey, label: x.title}))

        if (row.id === resource?.editedRow) {
            return <div className='table-actions'>
                <Glyphicon
                    glyph='ok-sign'
                    className='approved'
                    onClick={() => {
                        actions.saveClientDocument({
                            ...row,
                            title: temporaryRow.title,
                            note: temporaryRow.note,
                            document_files_ids: temporaryRow.document_files_ids
                        }, true, () => {
                            const removed_files_ids = row?.document_files_ids?.filter(number => !temporaryRow?.document_files_ids?.includes(number))
                            if (removed_files_ids && removed_files_ids?.length > 0) {
                                removed_files_ids.map(id => actions.deleteClientFile(id))
                            }

                            setResource({...resource, editedRow: null})
                            setTemporaryRow({})
                            reload(key)
                        })
                    }}/>
                <Glyphicon
                    glyph='remove-sign'
                    className='remove'
                    onClick={() => {
                        const result = temporaryRow?.document_files_ids?.filter(number => !row?.document_files_ids?.includes(number))
                        if (result && result.length > 0) {
                            result && result.map(id => actions.deleteClientFile(id))
                        }
                        setResource({...resource, editedRow: null})
                        setTemporaryRow({})
                    }}/>
            </div>
        } else {
            return <div className='table-actions'>
                <Glyphicon glyph='edit' onClick={() => {
                    if (!resource?.editedRow) {
                        setResource({...resource, editedRow: row.id})
                        setTemporaryRow({
                            title: row.title,
                            note: row.note,
                            document_files_ids: _.cloneDeep(row.document_files_ids),
                            files: _.cloneDeep(row.files)
                        })
                    }
                }}/>
                <Glyphicon
                    glyph='trash'
                    onClick={() => !resource?.editedRow && actions.deleteClientDocument(row.id, true, () => reload(key))}
                />
                <Glyphicon
                    glyph='list-alt'
                    onClick={() => !resource?.editedRow && setShowHistoryModal({show: true, data: row.history})}
                />
                <OverlayTrigger
                    trigger={!resource?.editedRow ? "click" : 'none'}
                    rootClose
                    onEnter={() => setResource({...resource, selectedTab: null})}
                    placement="top"
                    overlay={
                        <Popover id="popover-options">
                            <ControlLabel>Move to other tab:</ControlLabel>
                            <div className='d-flex'>
                                <Select
                                    className="popover-select"
                                    classNamePrefix="select"
                                    name="selectedTab"
                                    value={select(options, resource?.selectedTab)}
                                    options={options}
                                    onChange={e => setResource({...resource, selectedTab: e.value})}
                                />
                                <Button
                                    bsStyle='success'
                                    disabled={!resource?.selectedTab}
                                    onClick={() => {
                                        actions.saveClientDocument({
                                            ...row,
                                            newTab: resource?.selectedTab
                                        }, true, () => {
                                            reload(key)
                                        })
                                    }}>Save</Button>
                            </div>
                        </Popover>
                    }>
                    <Glyphicon
                        glyph='option-horizontal'
                    />
                </OverlayTrigger>
            </div>
        }
    }

    const removeTabActionsFormatter = (cell, row) => {
        const options = tabs.map(x => ({value: x.eventKey, label: x.title}))

        return <div className='table-actions'>
            <OverlayTrigger
                trigger="click"
                rootClose
                onEnter={() => setResource({...resource, selectedTab: null})}
                placement="top"
                overlay={
                    <Popover id="popover-restore">
                        <Select
                            className="popover-select"
                            classNamePrefix="select"
                            name="selectedTab"
                            value={select(options, resource?.selectedTab)}
                            options={options}
                            onChange={e => setResource({...resource, selectedTab: e.value})}
                        />
                        <Button
                            bsStyle='success'
                            disabled={!resource?.selectedTab}
                            onClick={() => {
                                actions.restoreDocument(row.id, resource?.selectedTab, () => {
                                    data.length === 1 ? reload(tabs[0]?.eventKey) : reload(key)
                                })
                            }}>Save</Button>
                    </Popover>
                }>
                <Glyphicon
                    glyph='repeat'
                    className='approved'
                />
            </OverlayTrigger>
            <Glyphicon
                glyph='remove-circle'
                className='remove'
                onClick={() => setShowDeletedModal({show: true, id: row.id})}/>
        </div>
    }

    const indexNFormatter = (cell, row, enumObject, index) =>
        <div>{(resource?.page * resource?.per_page - resource?.per_page) + index + 1}</div>;

    const columns = [{
        dataField: 'order',
        text: '#',
        width: isMobile ? "50px" : '5%'
    }, {
        dataField: 'title',
        text: 'Title',
        width: isMobile ? "250px" : '30%',
        dataFormat: titleFormatter
    }, {
        dataField: 'note',
        text: 'Note',
        width: isMobile ? "250px" : '40%',
        dataFormat: noteFormatter
    }, {
        dataField: 'user_name',
        text: 'Created by',
        width: isMobile ? "150px" : '20%'
    }, {
        dataField: 'files',
        text: 'Documents',
        width: isMobile ? "300px" : '60%',
        dataFormat: fileFormatter
    }, {
        dataField: '',
        text: 'Actions',
        width: isMobile ? "150px" : '20%',
        dataFormat: actionsFormatter
    }];

    const selectedMarkerClass = (row) => {
        return row.selected ? 'highlight' : '';
    };

    const sizePerPageDropDown = (props) => {
        return (
            <div className="btn-group dropup">
                <button className="btn btn-info dropdown-toggle" type="button" id="dropdownMenu2"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span>{props.currSizePerPage}</span>
                    <span className="ml-6 caret"></span>
                </button>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                    {props.sizePerPageList.map(item =>
                        <li>
                            <a
                                className='table-pagination dropdown-item'
                                onClick={() => props.changeSizePerPage(item.value)}
                            >{item.text}</a>
                        </li>
                    )}
                </ul>
            </div>
        );
    }

    useEffect(() => {
        reload(key)
    }, [resource?.page, resource?.per_page, resource?.q, key])

    useEffect(() => {
        setReady(false)
        reload(key, () => setReady(true))
        if (!client?.client_documents_tab) {
            client.client_documents_tab = tabs
            actions.updateClient(client)
        }
    }, [])

    return <div id="admin-documents">
        <Row style={isMobile ? {marginLeft: 0, marginRight: 0} : {}}>
            {isMobile ? <Col xs={12}>
                <Row style={{marginLeft: 0, marginRight: 0}}>
                    <Col xs={12}>
                        <PageHeader pageName="Documents" pageDetail={`${resource?.total} records`}/>
                    </Col>
                </Row>
                <Row style={{marginLeft: 0, marginRight: 0}}>
                    <Col xs={12} className="justify-flex-end mt10">
                        <Button
                            onClick={() => actions.saveClientDocument({tab: key}, false, res => {
                                setNewDocumentModal({show: true, data: res})
                            })}
                        >
                            Add new file
                            <FontAwesomeIcon icon="plus" className="marginLeft10"/>
                        </Button>
                    </Col>
                </Row>
            </Col> : <>
                <Col md={6}>
                    <PageHeader pageName="Documents" pageDetail={`${resource?.total} records`}/>
                </Col>
                <Col md={6} className="justify-flex-end mt10">
                    <Button
                        onClick={() => actions.saveClientDocument({tab: key}, false, res => {
                            setNewDocumentModal({show: true, data: res})
                        })}
                    >
                        Add new file
                        <FontAwesomeIcon icon="plus" className="marginLeft10"/>
                    </Button>
                </Col>
            </>}
        </Row>
        <Row className='container-fluid' style={{marginLeft: 0, marginRight: 0}}>
            {Array.isArray(tabs) &&
                <Tabs
                    defaultActiveKey={tabs[0]?.eventKey}
                    animation={false}
                    activeKey={key}
                    transition={false}
                    onSelect={(k, event) => {
                        if (!(event.target.className.includes('glyphicon-trash') || event.target.className.includes('edit-glyph')) && key !== k) {
                            setResource({...resource, page: 1, q: ''})
                            setKey(k)
                        }
                    }}
                >
                    {tabs.map((tab, index) => {
                        return <Tab
                            tabClassName='edited-tab'
                            eventKey={tab.eventKey}
                            title={
                                <div className='tab-title-wrapper'>
                                    <p className={!resource?.usedTabs.includes(tab.eventKey) ? 'tab-title' : 'non-deletable-tab-title'}>
                                        {tab.title}
                                    </p>
                                    <Glyphicon glyph='edit' className="edit-glyph"
                                               onClick={() => setShowEditModal({show: true, data: tab})}/>
                                    {!resource?.usedTabs.includes(tab.eventKey) && tabs?.length > 1 &&
                                        <Glyphicon
                                            glyph='trash'
                                            className="remove-glyph"
                                            onClick={() => {
                                                removeTab(tab.eventKey)
                                                if (tab.eventKey === key) {
                                                    setKey(tabs[0]?.eventKey)
                                                }
                                            }}/>
                                    }
                                </div>
                            }
                        >
                            {ready ?
                                <div style={isMobile ? {overflowX: "auto", width: "90vw"} : {}}>
                                    <BootstrapTable
                                        data={data}
                                        pagination={true}
                                        remote
                                        fetchInfo={{dataTotalSize: resource?.total}}
                                        trClassName={selectedMarkerClass}
                                        search={true}
                                        options={
                                            {
                                                onPageChange: (page, per_page) => {
                                                    setResource({...resource, page: page, per_page: per_page})
                                                },
                                                page: resource?.page,
                                                sizePerPageList: [
                                                    {text: '10', value: 10},
                                                    {text: '25', value: 25},
                                                    {text: '30', value: 30},
                                                    {text: '50', value: 50},
                                                    {text: '100', value: 100},
                                                    {text: '200', value: 200},
                                                    {text: 'All', value: resource?.total}],
                                                sizePerPage: resource?.per_page,
                                                sizePerPageDropDown: sizePerPageDropDown,
                                                noDataText: ' ',
                                                onSearchChange: onDocumentSearch
                                            }
                                        }
                                    >
                                        <TableHeaderColumn
                                            dataField="order"
                                            hidden
                                            width={isMobile ? "50px" : "10%"}
                                            isKey
                                        >
                                            #
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            hidden
                                            dataField="title"
                                            width={isMobile ? "150px" : "50%"}
                                        >
                                            Title
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            hidden
                                            dataField="note"
                                            width={isMobile ? "250px" : "100%"}
                                        >
                                            Note
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            hidden
                                            dataField="files"
                                            width={isMobile ? "250px" : "100%"}
                                        >
                                            Documents
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            hidden
                                            dataField=""
                                            width={isMobile ? "100px" : "10%"}
                                        >
                                            Actions
                                        </TableHeaderColumn>
                                    </BootstrapTable>
                                    <DragAndDropTable
                                        updateResource={res => {
                                            const newOrder = data.map(r => r.order)
                                            res.map((item, index) => {
                                                item.order = newOrder[index]
                                            })
                                            actions.updateOrder(res, () => setData(res))
                                        }}
                                        data={data}
                                        columns={columns}
                                        trClassName={selectedMarkerClass}
                                    />
                                </div>
                                :
                                <div className="loader">
                                    <MDSpinner size={100} borderSize={8}/>
                                </div>}
                        </Tab>
                    })}
                    {resource?.is_any_deleted &&
                        <Tab
                            eventKey='deleted'
                            tabClassName='edited-tab'
                            title={<div className='tab-title-wrapper'><p className='delete-tab'>Deleted</p></div>}
                        >
                            {ready ?
                                <div style={isMobile ? {overflowX: "auto", width: "90vw"} : {}}>
                                    <BootstrapTable
                                        data={data}
                                        pagination={true}
                                        bordered={false}
                                        hover={true}
                                        remote
                                        fetchInfo={{dataTotalSize: resource?.total}}
                                        className="wrapped"
                                        trClassName={selectedMarkerClass}
                                        options={
                                            {
                                                onPageChange: (page, per_page) => {
                                                    setResource({...resource, page: page, per_page: per_page})
                                                },
                                                page: resource?.page,
                                                sizePerPageList: [
                                                    {text: '10', value: 10},
                                                    {text: '25', value: 25},
                                                    {text: '30', value: 30},
                                                    {text: '50', value: 50},
                                                    {text: '100', value: 100},
                                                    {text: '200', value: 200},
                                                    {text: 'All', value: resource?.total}],
                                                sizePerPage: resource?.per_page,
                                                sizePerPageDropDown: sizePerPageDropDown,
                                                noDataText: ' ',
                                            }
                                        }
                                    >
                                        <TableHeaderColumn
                                            hidden
                                            dataField="order"
                                            width={isMobile ? "100px" : "10%"}
                                            isKey
                                        >
                                            Order
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField=""
                                            width={isMobile ? "50px" : "10%"}
                                            dataFormat={indexNFormatter}
                                        >
                                            #
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="title"
                                            width={isMobile ? "150px" : "30%"}
                                            dataFormat={titleFormatter}
                                        >
                                            Title
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="note"
                                            width={isMobile ? "250px" : "50%"}
                                            dataFormat={noteFormatter}
                                        >
                                            Note
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="user_name"
                                            width={isMobile ? "200px" : "40%"}
                                        >
                                            Created by
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="tab_title"
                                            width={isMobile ? "200px" : "40%"}
                                            dataFormat={originalFormatter}
                                        >
                                            Original tab
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="files"
                                            width={isMobile ? "250px" : "100%"}
                                            dataFormat={fileFormatter}
                                        >
                                            Documents
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField=""
                                            width={isMobile ? "100px" : "20%"}
                                            dataFormat={removeTabActionsFormatter}
                                        >
                                            Actions
                                        </TableHeaderColumn>
                                    </BootstrapTable>
                                </div>
                                :
                                <div className="loader">
                                    <MDSpinner size={100} borderSize={8}/>
                                </div>}
                        </Tab>}
                    <Tab tabClassName='add-new-tab' title={<Glyphicon glyph='plus' onClick={e => newTab(e)}/>}></Tab>
                </Tabs>
            }
        </Row>
        {newDocumentModal?.show &&
            <NewDocumentModal
                newResource={newDocumentModal?.data}
                onHide={() => setNewDocumentModal({show: false, data: null})}
                deleteClientDocument={actions.deleteClientDocument}
                onDrop={actions.uploadClientFile}
                deleteClientFile={actions.deleteClientFile}
                saveClientDocument={actions.saveClientDocument}
                onReload={() => reload(key)}
            />
        }
        {showDeletedModal?.show &&
            <ConfirmDeletionModal
                onHide={() => setShowDeletedModal({show: false, id: null})}
                onDelete={() => actions.deleteClientDocument(showDeletedModal?.id, null, () => {
                    data.length === 1 ? reload(tabs[0]?.eventKey) : reload(key)
                    setShowDeletedModal({show: false, id: null})
                })}
                isDocument={true}
            />
        }
        {showDeletedFileModal?.show &&
            <ConfirmDeletionModal
                onHide={() => setShowDeletedFileModal({show: false, id: null})}
                onDelete={() => {
                    temporaryRow.document_files_ids = temporaryRow?.document_files_ids.filter(cf => cf !== showDeletedFileModal?.id)
                    temporaryRow.files = temporaryRow?.files.filter(f => f.id !== showDeletedFileModal?.id)

                    setTemporaryRow({...temporaryRow})
                    setShowDeletedFileModal({show: false, id: null})
                }}
                isDocument={false}
            />
        }
        {showEditModal?.show &&
            <EditTabModal
                onHide={() => setShowEditModal({show: false, data: null})}
                resource={showEditModal?.data}
                onSave={res => {
                    tabs.map(d => {
                        if (d.eventKey === showEditModal?.data?.eventKey) {
                            d.title = res
                        }
                    })
                    setTabs([...tabs])
                    client.client_documents_tab = tabs
                    setClient(client)
                    actions.updateClient(client)
                    setShowEditModal({show: false, data: null})
                }}
            />
        }
        {showHistoryModal.show &&
            <HistoryModal
                history={showHistoryModal.data}
                onHide={() => setShowHistoryModal({show: false, data: null})}
            />
        }
    </div>
}

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(actions, dispatch)}
}

export default connect(undefined, mapDispatchToProps)(Documents);






