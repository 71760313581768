import moment from "moment";
import {defaultDateFormat, defaultDateTimeFormat} from "../../common/commonHandlers";
import ExcelJS from "exceljs";
import {dateFormatter} from "../../components/BootStrapTableCellFormatters";
import {saveAs} from "file-saver";

const proposalNoFormatterXLSX = (proposal_no, proposal_id) => {
    const primaryUrl = new URL(window.location.href).origin

    return {formula: `=HYPERLINK("${primaryUrl}/mapview/${proposal_id}","${proposal_no}")`};
}

const woFormatterXLSX = (wo_numbers, proposal_id) => {
    const primaryUrl = new URL(window.location.href).origin

    let wo_number;
    if (wo_numbers && wo_numbers.includes(",")) {
        wo_number = wo_numbers.split(",").join("\n")
    } else {
        wo_number = wo_numbers
    }
    return {formula: `=HYPERLINK("${primaryUrl}/work_orders/${proposal_id}","${wo_number}")`};
}

const invoiceNoFormatterXLSX = (invoice_no, proposal_id) => {
    const primaryUrl = new URL(window.location.href).origin

    return {formula: `=HYPERLINK("${primaryUrl}/proposals/${proposal_id}/invoices","${invoice_no}")`};
}

const dateFormatterXLSX = (date) => {
    return moment(date).format(defaultDateTimeFormat);
}

const siteFormatterXLSX = (site_name, site_full_address) => {
    site_full_address = site_full_address.split(/, (.*)/s)
    return {
        'richText': [
            {'font': {'bold': true, 'size': 8, 'name': 'Calibri'}, 'text': `${site_name}\n`},
            {'font': {'size': 8, 'name': 'Calibri'}, 'text': `${site_full_address[0]}\n${site_full_address[1]}`}
        ]
    }
};

const autoColumnWidth = (column) => {
    const lengths = column.values.map(v => v.toString().length);
    const maxLength = Math.max(...lengths.filter(v => typeof v === 'number')) * 0.727; //by default it works for font 11, we use font 8 so we need to convert result
    return maxLength;
}

export const exportToXLSX = async (invoices, resource, columnsSettings) => {

    const data = invoices
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Invoices")

    let rows = [];

    data.forEach((item) => {
        let dataArray = []
        columnsSettings["Customer"] && dataArray.push(item.customer_name)
        columnsSettings["Site"] && dataArray.push(siteFormatterXLSX(item.site_name, item.site_full_address))
        columnsSettings["Arborist"] && dataArray.push(item.arborist)
        columnsSettings["Title"] && dataArray.push(item.proposal_title)
        columnsSettings["Invoice notes"] && dataArray.push(item.invoice_notes)
        columnsSettings["Proposal #"] && dataArray.push(proposalNoFormatterXLSX(item.proposal_no, item.proposal_id))
        columnsSettings["WO #"] && dataArray.push(woFormatterXLSX(item.wo_numbers, item.proposal_id))
        columnsSettings["Invoice #"] && dataArray.push(invoiceNoFormatterXLSX(item.number, item.proposal_id))
        columnsSettings["Status Dates"] && dataArray.push(dateFormatter(item.invoiced_at))
        columnsSettings["Sent"] && dataArray.push(item.invoice_sent_count)
        columnsSettings["Status Dates"] && dataArray.push(dateFormatter(item.due_date))
        columnsSettings["Aging"] && dataArray.push(item.aging)
        columnsSettings["Balance"] && dataArray.push(parseFloat(item.balance))
        columnsSettings["Cost"] && dataArray.push(parseFloat(item.cost))
        rows.push(dataArray)
    })

    let columnsList = []
    columnsSettings["Customer"] && columnsList.push({name: 'Customer', filterButton: true})
    columnsSettings["Site"] && columnsList.push({name: 'Site', filterButton: true})
    columnsSettings["Arborist"] && columnsList.push({name: 'Arborist', filterButton: true})
    columnsSettings["Title"] && columnsList.push({name: 'Title', filterButton: true})
    columnsSettings["Invoice notes"] && columnsList.push({name: 'Invoice notes', filterButton: false})
    columnsSettings["Proposal #"] && columnsList.push({name: 'Proposal', filterButton: true})
    columnsSettings["WO #"] && columnsList.push({name: 'Work Order', filterButton: true})
    columnsSettings["Invoice #"] && columnsList.push({name: 'Invoice', filterButton: true})
    columnsSettings["Status Dates"] && columnsList.push({name: 'Invoice Date', filterButton: true})
    columnsSettings["Sent"] && columnsList.push({name: 'Sent', filterButton: true})
    columnsSettings["Status Dates"] && columnsList.push({name: 'Due Date', filterButton: true})
    columnsSettings["Aging"] && columnsList.push({name: 'Aging', filterButton: true})
    columnsSettings["Balance"] && columnsList.push({name: 'Balance', filterButton: true})
    columnsSettings["Cost"] && columnsList.push({name: 'Cost', filterButton: true})


    worksheet.addTable({
        name: 'Invoices',
        ref: 'A1',
        headerRow: true,
        style: {
            theme: 'TableStyleMedium9',
            showRowStripes: true,
        },
        columns: columnsList,
        rows: rows,
    });

    let cellsColNumbers = {}
    let formattedCellsColNumbers = []
    columnsList.map((c, idx) => {
        if (["Arborist", "Proposal", "Invoice", "Invoice Date", "Sent", "Due Date", "Aging", "Balance", "Cost"].includes(c.name)) {
            formattedCellsColNumbers.push((idx + 1))
        }
        cellsColNumbers[c.name] = (idx + 1)
    })

    worksheet.eachRow({includeEmpty: true}, (row, rowNumber) => {
        if (rowNumber > 1) {
            row.eachCell((cell, colNumber) => {
                if (formattedCellsColNumbers.includes(colNumber)) {
                    cell.style = {font: {name: 'Calibri', size: 8}}
                }
            })
            cellsColNumbers["Customer"] > 0 && (row.getCell(cellsColNumbers["Customer"]).style = {
                alignment: {wrapText: true},
                font: {name: 'Calibri', size: 8}
            })
            cellsColNumbers["Site"] > 0 && (row.getCell(cellsColNumbers["Site"]).style = {
                alignment: {wrapText: true},
                font: {name: 'Calibri', size: 8}
            })
            cellsColNumbers["Title"] > 0 && (row.getCell(cellsColNumbers["Title"]).style = {
                alignment: {wrapText: true},
                font: {name: 'Calibri', size: 8}
            })
            cellsColNumbers["Invoice notes"] > 0 && (row.getCell(cellsColNumbers["Invoice notes"]).style = {
                alignment: {
                    wrapText: true,
                    vertical: "top"
                }, font: {name: 'Calibri', size: 8}
            })
            cellsColNumbers["Work Order"] > 0 && (row.getCell(cellsColNumbers["Work Order"]).style = {
                alignment: {wrapText: true},
                font: {name: 'Calibri', size: 8}
            })
            cellsColNumbers["Balance"] > 0 && (row.getCell(cellsColNumbers["Balance"]).numFmt = '[$$-409]#,##0.00;-[$$-409]#,##0.00')
            cellsColNumbers["Cost"] > 0 && (row.getCell(cellsColNumbers["Cost"]).numFmt = '[$$-409]#,##0.00;-[$$-409]#,##0.00')
            row.height = 50
        }
    })

    worksheet.views = [
        {state: 'frozen', xSplit: 0, ySplit: 1, activeCell: 'A1'}
    ];

    let totalRow = []
    const balanceColumn = cellsColNumbers["Balance"]
    const balanceColumnLetter = String.fromCharCode(65 + balanceColumn - 1)
    if (balanceColumn > 1) {
        for (let i = 1; i <= balanceColumn - 2; i++) {
            totalRow.push('')
        }
        totalRow.push('Total')

        totalRow.push({
            formula: `SUM(${balanceColumnLetter}1:${balanceColumnLetter}${worksheet.rowCount})`,
            date1904: false
        })
    } else if (balanceColumn === 1) {
        totalRow.push('Total')
        totalRow.push({
            formula: `SUM(${balanceColumnLetter}1:${balanceColumnLetter}${worksheet.rowCount})`,
            date1904: false
        })
    }

    worksheet.addRow(totalRow)

    cellsColNumbers["Balance"] > 0 && (worksheet.getCell(worksheet.rowCount, cellsColNumbers["Balance"] - 1 > 0 ? (cellsColNumbers["Balance"] - 1) : 1).style = {
        font: {
            name: 'Calibri',
            size: 8
        }
    })
    cellsColNumbers["Balance"] > 0 && (worksheet.getCell(worksheet.rowCount, cellsColNumbers["Balance"] === 1 ? 2 : cellsColNumbers["Balance"]).style = {
        font: {
            name: 'Calibri',
            size: 8
        }
    })
    cellsColNumbers["Balance"] > 0 && (worksheet.getCell(worksheet.rowCount, cellsColNumbers["Balance"] === 1 ? 2 : cellsColNumbers["Balance"]).numFmt = '[$$-409]#,##0.00;-[$$-409]#,##0.00')

    worksheet.columns.forEach((column) => {
        let autoWidthColumns = [cellsColNumbers["Arborist"], cellsColNumbers["Invoice Date"], cellsColNumbers["Due Date"], cellsColNumbers["Aging"]]
        let colNumber = column._number
        if (autoWidthColumns.includes(colNumber)) {
            column.width = autoColumnWidth(column) + 0.62;
        } else if (colNumber === cellsColNumbers["Customer"] || colNumber === cellsColNumbers["Title"]) {
            column.width = 20 + 0.62
        } else if (colNumber === cellsColNumbers["Site"]) {
            column.width = 18 + 0.62
        } else if (colNumber === cellsColNumbers["Invoice notes"]) {
            column.width = 50 + 0.62;
        } else if (colNumber === cellsColNumbers["Balance"] || colNumber === cellsColNumbers["Cost"]) {
            column.width = 10 + 0.62;
        }
    })

    const excelBuffer = await workbook.xlsx.writeBuffer();

    const dataBlob = new Blob([excelBuffer], {type: 'application/octet-stream'});
    saveAs(dataBlob, `invoices-${moment().format(defaultDateFormat)}.xlsx`);
}
