import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as MyActions from "./actions"
import {addAlert} from "../App/actions";
import {showDock} from "../../components/dock/actions";
import {Button, Col, Row} from "react-bootstrap";
import CustomerViewMenu from "./CustomerViewMenu";
import './CustomerViewPage.scss'
import moment from "moment";
import {LinkContainer} from "react-router-bootstrap";
import Dollars from "../../components/Dollars";
import CustomerViewTabs from "./CustomerViewTabs";
import cardIcon from "../CustomerViewPage/card.png";
import AssetsMap from "./AssetsMap";


const Actions = {...MyActions, addAlert, showDock}
const isMobile = window.screen.width < 1024;

class CustomerViewInvoices extends Component {
    state = {
        invoices: [],
        loading: true,
        loadAllRecords: false
    }
    getInvoices = (loadAll) => {
        this.props.actions.loadInvoices(this.props.params.token, loadAll, this.props.site?.selected.id, res => {
            this.setState({
                    invoices: res.customer_invoices,
                    loading: false,
                    invoicesAssets: res.invoices_assets,
                    loadAllRecords: loadAll
                }
            )
        })
    }

    componentWillMount = () => {
        if (this.props.params.token) {
            this.getInvoices()
            setTimeout(() => {
                this.setState({show: true})
            }, 500)
        } else {
            //should never get here because router should not mount this page and instead 404
            this.props.actions.addAlert({
                message: "Proposal not given. I can't go on!",
            })
        }
    }


    render() {
        const {invoices, loading, loadAllRecords, invoicesAssets} = this.state
        const {client, params} = this.props
        const clientLogo = client ? client.client_logo_selected : ''
        return (<>
                <div id="customerView" className={`${isMobile ? "customerView--Mob" : "customerView"}`}>
                    <Row
                        className='customerView__header customerView__header--disable-grid'>
                        <Col md={2} className='company-logo'><img src={clientLogo}/></Col>
                        <Col md={8} xs={12} className='align-center white-stripe'>
                            <Row className='title-container'>
                                <Col xs={12}>
                                    <p className='title'>{this.props.site?.selected?.name}: Invoices</p>
                                </Col>
                            </Row>

                            {!loading &&
                                <p className='subtitle'>
                                    {invoices.length === 0 ? `It looks like you don't have any invoices yet` : "Past"}
                                </p>
                            }
                        </Col>
                        {!isMobile && <Col md={2} className='float-right'>
                            <div className='image-button image-button--float-right'>
                                <img alt='card'
                                     src={cardIcon}
                                     style={{height: 40}}
                                     onClick={() => {
                                         this.props.router.push(`/customer_view/${this.props.params.token}/wallet`)
                                     }}
                                />
                            </div>
                        </Col>}
                    </Row>
                    {isMobile
                        ? <Row>
                            <Col md={6}>
                                <CustomerViewMenu token={this.props.params.token}/>
                            </Col>
                            <Col md={6} className='text-right'>
                                <div className='image-button image-button--float-right'>
                                    <img alt='card'
                                         src={cardIcon}
                                         style={{height: 40}}
                                         onClick={() => {
                                             this.props.router.push(`/customer_view/${this.props.params.token}/wallet`)
                                         }}
                                    />
                                </div>
                            </Col>
                        </Row>

                        : <Row className="customerView__tabs">
                            <Col md={12}>
                                <CustomerViewTabs token={params.token}/>
                            </Col>
                        </Row>
                    }
                    <Row className='customerView__content align-center'>
                        {!loading &&
                            <>
                                <Col md={isMobile ? 12 : 5}
                                     className={`customerView__map ${this.state.show ? 'showMap' : ''}`}>
                                    <AssetsMap assets={invoicesAssets}/>
                                </Col>
                                <Col md={isMobile ? 12 : 7} xs={12} className='customerView__items items-container'>
                                    {invoices.map(i => {
                                        return <LinkContainer to={`/customer_view/invoice/${i.token}`}>
                                            <Row className='item-link'>
                                                <div className='item-header'>
                                                    <div
                                                        className='item-title'>{i.paid_at ? moment(i.paid_at).format('MMMM Do YYYY') : `Due date ${moment(i.due_date).format('MMMM Do YYYY')}`}
                                                    </div>
                                                    <div className='item-status' style={{
                                                        color: i.paid_at && '#517209',
                                                        backgroundColor: i.paid_at && '#ecf3db'
                                                    }}>{i.paid_at ? 'Paid' : 'Unpaid'}</div>
                                                </div>
                                                <div className='item-data'>
                                                    <p><span className='bold'>Number:</span> {i.number}</p>
                                                    <p><span className='bold'>Proposal:</span> {i.proposal_no}</p>
                                                    <p><span className='bold'>Amount:</span> <Dollars
                                                        amount={i.amount}/>
                                                    </p>
                                                </div>
                                            </Row>
                                        </LinkContainer>
                                    })}
                                    {invoices.length === 10 && !loadAllRecords &&
                                        <Row className='mt-20'>
                                            <Button onClick={() => this.getInvoices(true)}>
                                                <span>Load More...</span>
                                            </Button>
                                        </Row>}
                                </Col>
                            </>
                        }
                    </Row>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        client: state.client.customerInfo,
        site: state.customerView.site
    }
}


const mapDispatchToProps = dispatch => {
    return {actions: bindActionCreators(Actions, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerViewInvoices)

