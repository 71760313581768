import axios from 'axios'

import {
    SET_BASE_URL,
    SIGN_IN_USER_START,
    SIGN_IN_USER_SUCCESS,
    SIGN_OUT_USER,
    UPDATE_CLIENT,
    UPDATE_EMAIL,
    UPDATE_PASSWORD,
} from './constants'

import {FETCH_CUSTOMER_INFO} from "../ClientsPage/constants";


import {browserHistory} from 'react-router'

import {addAlert} from '../App/actions'
import {apiCall} from "../../common/apiActionHandler";
import {SET_ADMIN_TERMS_REQUIRED, SET_ADMIN_TERMS_STATUS, SET_USER_TERMS_STATUS} from "../App/constants";

export const authenticate = (coords, url) => async (dispatch, getState) => {
    const store = getState()
    dispatch({type: SIGN_IN_USER_START})

    let data = {
        email: store.auth.email,
        password: store.auth.password,
    };

    const config = {
        method: 'post',
        url: '/login',
        data: data,
    }

    axios
        .request(config)
        .then(function (response) {
            if (response.data.user_id) {
                dispatch({
                    type: SIGN_IN_USER_SUCCESS,
                    isAuthenticated: true,
                    client: response.data.client,
                    client_id: response.data.client_id,
                    email: store.auth.email,
                    token: response.data.access_token,
                    roles: response.data.roles,
                    employee_id: response.data.employee_id,
                    name: response.data.name,
                    user_id: response.data.user_id,
                    download_notifications: response.data.download_notifications,
                    password: '',
                });
                let next_config = {
                    url: `/api/users/${response.data.user_id}/refresh_activity`,
                    method: 'PUT',
                    headers: {
                        Authorization: response.data.access_token
                    }
                };
                axios.request(next_config);

                if (response.data.client_id) {
                    const client_config = {
                        url: `/api/clients/${response.data.client_id}`,
                        headers: {Authorization: response.data.access_token},
                    }
                    try {
                        axios.request(client_config).then(clientResponse => {
                            dispatch({type: FETCH_CUSTOMER_INFO, customerInfo: clientResponse.data})
                        })
                    } catch (error) {
                        dispatch(
                            addAlert({
                                message: "Client not found in your account because " +
                                    error.toString(),
                                mood: "danger",
                                dismissAfter: 1500,
                            })
                        )
                        throw error
                    }
                    if (url) {
                        browserHistory.push(url)
                    }
                }
            } else {
                dispatch({
                    type: SIGN_IN_USER_SUCCESS,
                    isCustomerAuthenticated: true,
                    token: response.data.access_token,
                    proposal_token: response.data.proposal_token,
                    password: '',
                });
                browserHistory.push(`/customer_view/${response.data.proposal_token}/sites`)
            }
        })
        .catch(function (error) {
            if (error.response && error.response.status === 401) {
                dispatch(
                    addAlert({
                        message: 'E-mail/password combination incorrect.',
                        mood: 'danger',
                        dismissAfter: 1500,
                    })
                )
            } else {
                dispatch(
                    addAlert({
                        message: error?.response?.data?.error ? error.response.data.error : 'Error trying to log you in. Is the network available?',
                        mood: 'danger',
                    })
                )
            }
        })
}

export const logout = (url = null) => async (dispatch, getState) => {
    await dispatch(
        addAlert({message: 'Signed out!', mood: 'info', dismissAfter: 1000})
    )
    const store = getState()
    //leave the client, email as-is for UX because its not common to change them (perhaps we should use client-id? or a 3 letter designation instaed of the whole client?)
    await dispatch({
        type: SIGN_OUT_USER,
        isAuthenticated: false,
        client: store.auth.client,
        email: store.auth.email,
        name: '',
        token: '',
        password: '',
        isCustomerAuthenticated: false,
        clientAppVersion: store.auth.clientAppVersion,
        newClientAppVersion: store.auth.newClientAppVersion,
    })

    browserHistory.push(url ? url : '/')
}

const setUserTermsStatus = status => (dispatch) => {
    dispatch({type: SET_USER_TERMS_STATUS, status})
}

const setAdminTermsStatus = status => (dispatch) => {
    dispatch({type: SET_ADMIN_TERMS_STATUS, status})
}

const setAdminTermsRequired = status => (dispatch) => {
    dispatch({type: SET_ADMIN_TERMS_REQUIRED, status})
}

export const loadAllResources = (user_id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/users/${user_id}/get_router_info?light=false`,
        method: 'GET',
    };
    apiCall("Loading router resources", config, async config => {
        const response = await axios.request(config);
        dispatch(setAdminTermsStatus(response.data.admin_term.term_accepted))
        dispatch(setUserTermsStatus(response.data.user_term.term_accepted))
        dispatch(setAdminTermsRequired(response.data.admin_term.admin_terms_required))
        callback(response.data);
    }, dispatch, getState, false);
};

export const saveTermsAcceptance = (id, role, callback) => async (dispatch, getState) => {
    const config = {
        method: 'PUT',
        url: `/api/terms/accept_term`,
        data: {
            id,
            role
        }
    };

    apiCall('Update user terms', config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState);
};

export const deleteDevice = (params, callback) => (dispatch, getState) => {
    const config = {
        method: "delete",
        url: `/api/access_tokens/${params.user_id}`,
        params
    };

    apiCall("Deleting device", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export function updateClient(val) {
    return {type: UPDATE_CLIENT, client: val}
}

export const updateEmail = e => {
    return {type: UPDATE_EMAIL, email: e.target.value}
}

export const updatePassword = e => {
    return {type: UPDATE_PASSWORD, password: e.target.value}
}

export const setBaseUrl = baseUrl => {
    return {type: SET_BASE_URL, baseUrl}
}

export const getAppSettings = (callback) => (dispatch, getState) => {
    const config = {
        url: `/api/app_settings?id=1`,
        method: 'GET'
    };

    apiCall("Get app settings successfully", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const authenticateCustomer = (token) => async (dispatch, getState) => {
    const store = getState()
    dispatch({type: SIGN_IN_USER_START})

    let data = {
        email: store.auth.email,
        password: store.auth.password,
        token: token
    };

    const config = {
        method: 'post',
        url: '/login/customer',
        data: data,
    }

    axios
        .request(config)
        .then(function (response) {
            dispatch({
                type: SIGN_IN_USER_SUCCESS,
                isCustomerAuthenticated: true,
                token: response.data.access_token,
                password: '',
            });
        })
        .catch(function (error) {
            if (error.response && error.response.status === 401) {
                dispatch(
                    addAlert({
                        message: 'E-mail/password combination incorrect.',
                        mood: 'danger',
                        dismissAfter: 1500,
                    })
                )
            } else {
                dispatch(
                    addAlert({
                        message: 'Error trying to log you in. Is the network available?',
                        mood: 'danger',
                    })
                )
            }
        })
}

export const requestCredentials = (token, captcha, callback) => async (dispatch, getState) => {
    let data = {
        token: token,
        captcha: captcha
    };

    const config = {
        method: 'post',
        url: '/login/request_credentials',
        data: data,
    }

    apiCall("Request credentials", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
}