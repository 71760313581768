import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Modal} from "react-bootstrap";
import './VersionHistoryModal.scss';
import {connect} from "react-redux";
import * as actions from './VersionHistoryActions'
import {bindActionCreators} from "redux";
import VersionHistoryTable from "./VersionHistoryTable";
import VersionHistoryDetails from "./VersionHistoryDetails";
import VersionHistoryModalHeader from "./VersionHistoryModalHeader";

class VersionHistoryModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            proposalVH: [],
            details: {},
            fetching: true,
            tableViewOn: true,
            tableCurrentPage: 1,
            tableSizePerPage: 25
        }
        this.tableRef = React.createRef();
    }

    componentDidMount() {
        const {actions, proposalId} = this.props
        actions.fetchProposalVersionHistory(proposalId, (res) => {
            this.setState({proposalVH: res.proposal_version_history, fetching: false})
        })
    }

    toggleView = (version) => {
        if (version.action_type === "no_details") return
        this.setState({
            tableViewOn: !this.state.tableViewOn,
            details: version?.details,
            modalHeaderData: version,
            tableCurrentPage: this.state.tableViewOn ? this.tableRef?.current?.pagination?.props?.currPage || 1 : this.state.tableCurrentPage,
            tableSizePerPage: this.state.tableViewOn ? this.tableRef?.current?.pagination?.props?.sizePerPage || 25 : this.state.tableSizePerPage
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.tableViewOn !== this.state.tableViewOn && this.tableRef?.current) {
            this.tableRef.current.pagination.changeSizePerPage(this.state.tableSizePerPage)
            setTimeout(() => {
                this.tableRef.current.pagination.changePage(this.state.tableCurrentPage)
            }, 10)
        }
    }

    render() {
        return (
            <>
                {!this.state.fetching
                    && <Modal
                        show={this.props.isVHModalOpened}
                        onHide={this.props.onHide}
                        className="version-history-modal">
                        <Modal.Header>
                            <Modal.Title>
                                <VersionHistoryModalHeader
                                    tableViewOn={this.state.tableViewOn}
                                    modalHeaderData={this.state.modalHeaderData}
                                    onHide={this.props.onHide}
                                    toggleView={this.toggleView}/>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {this.state.tableViewOn
                                ? <VersionHistoryTable
                                    forwardRef={this.tableRef}
                                    toggleView={this.toggleView}
                                    proposalVH={this.state.proposalVH}/>
                                : <VersionHistoryDetails
                                    VHDetails={this.state.details}/>
                            }
                        </Modal.Body>
                    </Modal>
                }

            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        state: state
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

VersionHistoryModal.propTypes = {
    isVHModalOpened: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    proposalVH: PropTypes.array.isRequired
}

VersionHistoryModal.defaultProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(VersionHistoryModal);

