import axios from "axios"
import {addAlert} from "../../App/actions";
import {apiCall} from "../../../common/apiActionHandler";
import {FETCH_CUSTOMER_INFO} from "../../ClientsPage/constants";
import {SET_EQUIPMENT_INTERVAL, SET_EQUIPMENT_TIMEOUT} from "../../LoginPage/constants";

export const getUserStatus = (userId, callback) => async (dispatch, getState) => {
    const store = getState();
    const config = {
        method: "get",
        url: `/api/users/${userId}/status`,
        headers: {Authorization: store.auth.token},
    };

    try {
        const response = await axios.request(config);
        dispatch({type: 'GOT_USER_STATUS', userStatus: response.data})
        callback && callback(response.data)
    } catch (error) {
        dispatch(
            addAlert({
                message: "Internal error, please contact the administrator",
                mood: "danger",
                dismissAfter: 1500,
            })
        );

        throw error
    }
};

export const getGeolocation = (userId, clientId, coords, callback) => async (dispatch, getState) => {
    const store = getState();
    if (coords) {
        const geoConfig = {
            url: `/api/users/${userId}/add_coords`,
            method: 'PUT',
            data: {
                latitude: coords.latitude,
                longitude: coords.longitude,
                token: store.auth.token
            }
        };

        apiCall("Adding coords", geoConfig, async geoConfig => {
            await axios.request(geoConfig);
        }, dispatch, getState, false);
    }

    callback && callback()
};

export const getClientInfo = (clientId, callback) => async (dispatch, getState) => {
    const store = getState();
    if (clientId) {
        const clientConfig = {
            url: `/api/clients/${clientId}`,
            headers: {Authorization: store.auth.token},
        }

        try {
            const clientResponse = await axios.request(clientConfig)
            dispatch({type: FETCH_CUSTOMER_INFO, customerInfo: clientResponse.data})
            callback && callback(clientResponse.data)
        } catch (error) {
            dispatch(
                addAlert({
                    message: "Client not found in your account because " +
                        error.toString(),
                    mood: "danger",
                    dismissAfter: 1500,
                })
            )

            throw error
        }
    }
};

export const setEquipmentsChecklistInterval = (equipmentInterval) => async (dispatch, getState) => {
    dispatch({type: SET_EQUIPMENT_INTERVAL, equipmentInterval: equipmentInterval})
}

export const setEquipmentsChecklistTimeout = (equipmentTimer) => async (dispatch, getState) => {
    dispatch({type: SET_EQUIPMENT_TIMEOUT, equipmentTimer: equipmentTimer})
}

export const updateUserLocale = (locale, callback) => async (dispatch, getState) => {
    const store = getState();
    const config = {
        method: "PUT",
        url: `/api/users/update_locale`,
        params: {
            locale
        },
        headers: {Authorization: store.auth.token},
    };

    try {
        const response = await axios.request(config);
        callback && callback(response.data)
    } catch (error) {
        dispatch(
            addAlert({
                message: "Internal error, please contact the administrator",
                mood: "danger",
                dismissAfter: 1500,
            })
        );

        throw error
    }
};

export const showTaskDialog = () => {
    return {type: 'SHOW_TASK_DIALOG'};
};

export const hideTaskDialog = () => {
    return {type: 'HIDE_TASK_DIALOG'};
};

export const showIssueDialog = (screen) => {
    return {type: 'SHOW_ISSUE_DIALOG', screen};
};

export const hideIssueDialog = () => {
    return {type: 'HIDE_ISSUE_DIALOG'};
};

export const changeLocale = (locale) => {
    return {type: 'CHANGE_LOCALE', locale};
};
