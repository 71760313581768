import React, {Component} from 'react';
import PropTypes from "prop-types"
import {Button, Col, ControlLabel, FormControl, FormGroup, Row, Well} from 'react-bootstrap'


//NOTE: this is the row editor for the plant_stats_history table. We like to call it 3 different things
//to keep it interesting

export default class AssetHistoryForm extends Component {
    static propTypes = {
        assetHistory: PropTypes.object.isRequired,
        cancelHistoryEdit: PropTypes.func.isRequired,
    }


    submitPlantHistory(e) {
        e.preventDefault()
        //submit action
        this.props.submitPlantStatsHistory()
    }


    render() {
        const {assetHistory, updateHistoryAttribute, cancelHistoryEdit} = this.props

        return (
            <Well>
                <form onSubmit={e => this.submitPlantHistory(e)}>
                    <Row>

                        <Col xs={2}>
                            <FormGroup bsSize="small">
                                <ControlLabel>Date</ControlLabel>
                                <FormControl type='text' placeholder='Review Date' value={assetHistory.review_date}
                                             onChange={e => updateHistoryAttribute('review_date', e.target.value)}/>
                            </FormGroup>
                        </Col>


                        <Col xs={2}>
                            <FormGroup bsSize="small">
                                <ControlLabel>Dbh</ControlLabel>
                                <FormControl type='text' placeholder='DBH' value={assetHistory.dbh}
                                             onChange={e => updateHistoryAttribute('dbh', e.target.value)}/>
                            </FormGroup>
                        </Col>

                        <Col xs={2}>
                            <FormGroup bsSize="small">
                                <ControlLabel>Height</ControlLabel>
                                <FormControl type='text' placeholder='Height' value={assetHistory.height}
                                             onChange={e => updateHistoryAttribute('height', e.target.value)}/>
                            </FormGroup>
                        </Col>

                        <Col xs={2}>
                            <FormGroup bsSize="small">
                                <ControlLabel>Spread</ControlLabel>
                                <FormControl type='text' placeholder='Spread' value={assetHistory.width}
                                             onChange={e => updateHistoryAttribute('width', e.target.value)}/>
                            </FormGroup>
                        </Col>

                        <Col xs={2}>
                            <FormGroup bsSize="small">
                                <ControlLabel>Stems</ControlLabel>
                                <FormControl type='text' placeholder='Stems' value={assetHistory.stem_count}
                                             onChange={e => updateHistoryAttribute('stem_count', e.target.value)}/>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='text-right' xs={10}>
                            <Button bsStyle='success' bsSize="xsmall"
                                    type="submit">{assetHistory.id === 0 ? "Add New History" : "Save Modifications"}</Button>
                            {' '}<Button onClick={cancelHistoryEdit} bsStyle='danger' bsSize="xsmall"
                                         type="submit">Cancel</Button>
                        </Col>
                    </Row>
                </form>
            </Well>


        )


    }

}
