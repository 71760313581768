import {
    ADD_INVOICE_PROPOSAL_SERVICES,
    CHANGED_INVOICED_DATE,
    DELETED_INVOICE,
    FETCH_CUSTOMER_SITES,
    FETCH_INVOICE_PS_INFO,
    FETCH_PROPOSAL_INVOICES,
    FETCH_PROPOSALS,
    FETCH_PS_INFO,
    FETCH_SEARCHED_CUSTOMERS,
    FETCH_SEARCHED_PROPOSALS,
    GET_CUSTOMER_BY_ID,
    INVOICE_MARK_PAID,
    NEW_INVOICE,
    REMOVE_INVOICE_PROPOSAL_SERVICE,
    SELECT_CUSTOMER,
    SELECT_INVOICE,
    SELECT_PROPOSAL,
    UPDATE_CUSTOMER_SEARCH,
    UPDATE_INVOICE,
    UPDATE_INVOICE_SEARCH,
    UPDATE_PROPOSAL_SEARCH,
    UPDATE_SELECTED_SITE,
    VERIFY_INVOICE_NUMBER,
} from "./constants"

import {EDITING_ASSET_ID,} from "../../MapViewPage/constants"

import axios from "axios"
import {addAlert} from "../../App/actions"
import {fetchProposalServiceStatuses,} from "../../ProposalServiceStatusPage/actions"

export const updateSelectedSite = (selectedSite, customerId) => dispatch => {
    dispatch(fetchCustomerById(customerId));
    dispatch({type: UPDATE_SELECTED_SITE, selectedSite});
    dispatch(fetchProposalInvoices());
}

export const updateCustomerSearch = customerSearch => dispatch => {
    dispatch({type: UPDATE_CUSTOMER_SEARCH, customerSearch})
    dispatch(searchForCustomer())
}

export const updateSelectedCustomer = selectedCustomer => dispatch => {
    dispatch({type: SELECT_CUSTOMER, selectedCustomer})
}

export const updateProposalSearch = proposalSearch => dispatch => {
    dispatch({type: UPDATE_PROPOSAL_SEARCH, proposalSearch})
    // dispatch(searchForProposal()
}

export const updateInvoiceSearch = invoiceSearch => dispatch => {
    dispatch({type: UPDATE_INVOICE_SEARCH, invoiceSearch})
    // dispatch(searchForProposal()
}

export const updateSelectedInvoice = invoice => dispatch => {
    const invoiceDefined = invoice && !(invoice.length !== undefined && invoice.length === 0);
    const newInvoice = invoiceDefined ? invoice : undefined;
    dispatch({type: SELECT_INVOICE, invoice: newInvoice});
    if (newInvoice) {
        dispatch(fetchInvoiceProposalServicesInfo(newInvoice.value));
    }
};

export const updateSelectedProposal = (selectedProposal, invoice, invoiceProposalServices) => (dispatch) => {
    dispatch({type: SELECT_PROPOSAL, selectedProposal, invoice, invoiceProposalServices})

}

export const updateInvoicesFromProposal = (selectedSite, proposalId, customerId) => (dispatch, getState) => {
    dispatch(fetchProposalInvoices(selectedSite, proposalId, customerId))
    dispatch(fetchProposalServicesInfo(proposalId))
}

export const dismissAssetEditorModal = () => ({
    type: EDITING_ASSET_ID,
    editingAssetHistoryId: null,
})

export const applyHistoryFilter = () => (dispatch, getState) => {
    const store = getState()
    dispatch(fetchCustomerById(store.customerProposals.customer.id))
}

export const fetchCustomerById = customerId => async (dispatch, getState) => {
    const store = getState()

    const config = {
        url: `/api/customers/${customerId}/info`,
        headers: {Authorization: store.auth.token},
    }

    try {
        const response = await axios.request(config)

        dispatch({type: GET_CUSTOMER_BY_ID, customer: response.data})
        dispatch(fetchProposals(customerId))
        dispatch(fetchProposalServiceStatuses("all"))
        const _ = await dispatch(fetchCustomerSites())
    } catch (exception) {
        dispatch(
            addAlert({
                message: "Unable to fetch the customer because " + exception.toString(),
            })
        )
        throw exception
    }
}

export const fetchProposalServicesInfo = proposalId => async (dispatch, getState) => {
    const store = getState()

    const config = {
        url: `/api/proposal_services/info`,
        headers: {'Authorization': store.auth.token},
        params: {
            proposal_id: proposalId,
            proposal_service_status: 4 // completed status
        },
    }

    try {
        const response = await axios.request(config)

        dispatch({type: FETCH_PS_INFO, proposalServices: response.data})
    } catch (exception) {
        dispatch(
            addAlert({
                message: "Unable to fetch available proposal services" + exception.toString(),
            })
        )
    }
}

export const fetchInvoiceProposalServicesInfo = invoiceId => async (dispatch, getState) => {
    const store = getState()

    const config = {
        url: `/api/proposal_services/invoice_info`,
        headers: {'Authorization': store.auth.token},
        params: {
            invoice_id: invoiceId,
        },
    }

    try {
        const response = await axios.request(config)

        dispatch({type: FETCH_INVOICE_PS_INFO, invoiceProposalServices: response.data})
    } catch (exception) {
        dispatch(
            addAlert({
                message: "Unable to fetch available invoice proposal services" + exception.toString(),
            })
        )
    }
}

export const fetchProposalInfo = (token, proposalId) => {
    const config = {
        url: `/api/proposals/${proposalId}/info`,
        headers: {'Authorization': token},
    }
    return axios.request(config)
}

const fetchProposals = () => async (dispatch, getState) => {
    const store = getState()
    const customerId = store.customerProposals.customer.id
    const siteId = store.customerProposals.selectedSite
    // const proposalId = store.custostomerProposals.serviceStatus
    if (!siteId) {
        dispatch({type: FETCH_PROPOSALS, customerProposals: []})
        return;
    }

    const config = {
        url: `/api/proposals/customer_invoice_proposals/`,
        headers: {Authorization: store.auth.token},
        params: {
            customer_id: customerId,
            site_id: siteId,
        },
    }

    try {
        const response = await axios.request(config)

        dispatch({type: FETCH_PROPOSALS, customerProposals: response.data})
    } catch (exception) {
        dispatch(
            addAlert({
                message: "Unable to fetch proposals because " +
                    exception.toString(),
            })
        )
        throw exception
    }
}

const fetchCustomerSites = (siteIds) => async (dispatch, getState) => {
    const store = getState()
    const customerId = store.customerWorkOrders.customer.id
    const config = {
        url: "/api/sites",
        headers: {Authorization: store.auth.token},
        params: {customer_id: customerId},
    }

    try {
        const response = await axios.request(config)
        // dispatch(fetchCustomerWorkOrders(siteIds))
        dispatch({type: FETCH_CUSTOMER_SITES, customerSites: response.data})
    } catch (exception) {
        dispatch(
            addAlert({
                message: "Unable to fetch customer sites because " +
                    exception.toString(),
            })
        )
        throw exception
    }
};

export const searchForCustomer = () => async (dispatch, getState) => {
    const store = getState()
    const searchQuery = store.customerProposals.customerSearch

    if (searchQuery.length < 2) {
        return false
    }

    const config = {
        method: "get",
        url: `/api/customers`,
        headers: {Authorization: store.auth.token},
        params: {
            q: searchQuery,
        },
    }
    try {
        const response = await axios.request(config)
        dispatch({
            type: FETCH_SEARCHED_CUSTOMERS,
            foundCustomers: response.data,
        })
    } catch (exception) {
        dispatch(
            addAlert({
                message: "Unable to search for customers because " +
                    exception.toString(),
            })
        )
        throw exception
    }
}

export const searchForProposal = () => async (dispatch, getState) => {
    const store = getState()
    const searchQuery = store.customerManageInvoices.proposals

    if (searchQuery.length < 2) {
        return false
    }

    const config = {
        method: "get",
        url: `/api/proposals`,
        headers: {Authorization: store.auth.token},
        params: {
            q: searchQuery,
        },
    }
    try {
        const response = await axios.request(config)
        dispatch({
            type: FETCH_SEARCHED_PROPOSALS,
            foundProposals: response.data,
        })
    } catch (exception) {
        dispatch(
            addAlert({
                message: "Unable to search for proposals because " +
                    exception.toString(),
            })
        )
        throw exception
    }
}

export const removePsFromInvoice = (ps) => async (dispatch, getState) => {
    const store = getState()
    const config = {
        method: 'DELETE',
        url: '/api/invoices/remove_ps_from_invoice',
        headers: {'Authorization': store.auth.token},
        data: {psid: ps.id}
    }

    try {
        const response = await axios.request(config)
        dispatch({
            type: UPDATE_INVOICE,
            payload: response.data
        })
        dispatch({type: REMOVE_INVOICE_PROPOSAL_SERVICE, ps: ps})
        dispatch(fetchProposalServicesInfo(ps.proposalId))
    } catch (error) {
        dispatch(
            addAlert({
                message: `Could not remove proposal service from the invoice ${error.toString()}`,
                mood: "danger",
                dismissAfter: 1500,
            })
        );
        throw error
    }
}

export const selectInvoiceServices = (selectedProposalServices) => (dispatch, getState) => {
    const store = getState()
    const token = store.auth.token

    const invoice = store.customerManageInvoices.invoice
    dispatch({type: ADD_INVOICE_PROPOSAL_SERVICES, proposalServices: selectedProposalServices})

    // if we have invoice then we save thse into invoice
    if (invoice) {
        return dispatch(addProposalServicesToInvoice(invoice.id, selectedProposalServices))
    }
    return true
}

export const addProposalServicesToInvoice = (invoiceId, proposalServices) => async (dispatch, getState) => {
    const store = getState()
    const token = store.auth.token

    const psIds = proposalServices.map(ps => ps.id)
    const config = {
        method: 'PUT',
        url: `/api/invoices/${invoiceId}/add_ps_to_invoice`,
        headers: {'Authorization': store.auth.token},
        data: {psid: psIds.join(',')}
    }

    try {
        const response = await axios.request(config)
        dispatch({
            type: UPDATE_INVOICE,
            payload: response.data
        })
    } catch (error) {
        dispatch(
            addAlert({
                message: `Could not update the invoice ${error.toString()}`,
                mood: "danger",
                dismissAfter: 1500,
            })
        )
        throw error
    }
}

export const removeInvoiceService = (psId) => (dispatch, getState) => {
    const store = getState()
    const token = store.auth.token

    dispatch({type: REMOVE_INVOICE_PROPOSAL_SERVICE, psId: psId})
    return true
}


export const fetchInvoice = invoiceId => async (dispatch, getState) => {
    const store = getState()

    const config = {
        url: `/api/invoices/${invoiceId}`,
        headers: {'Authorization': store.auth.token},
    }

    try {
        const response = await axios.request(config)
        dispatch({type: NEW_INVOICE, payload: {invoice: response.data}})
    } catch (exception) {
        dispatch(
            addAlert({
                message: "Unable to fetch available invoice proposal services" + exception.toString(),
            })
        )
    }
}

export const createInvoice = (psIds, invoiceNo, invoicedDate, customerId, selectedSite) => async (dispatch, getState) => {
    const store = getState()
    const token = store.auth.token
    // create invoice from psIDs
    const config = {
        method: 'POST',
        url: '/api/invoices',
        headers: {'Authorization': store.auth.token},
        data: {
            psid: psIds.join(','),
            invoice_no: invoiceNo,
            invoiced_at: invoicedDate,
        }
    }

    try {
        const response = await axios.request(config)
        const proposalId = response.data.proposal.id
        dispatch({
            type: NEW_INVOICE,
            payload: response.data,
        })

        // reload completed proposal services on the left side

        // fetch invoiced proposal services...
        dispatch(fetchProposalServicesInfo(proposalId))
        dispatch(fetchProposalInvoices(selectedSite, proposalId, customerId))
    } catch (error) {
        dispatch(
            addAlert({
                message: `Could not create the invoice ${error.toString()}`,
                mood: "danger",
                dismissAfter: 1500,
            })
        )
        throw error
    }
}

export const deleteInvoice = (invoiceId, customerId, proposalId, selectedSite) => (dispatch, getState) => {
    const store = getState()
    const token = store.auth.token

    // delete invoice
    const config = {
        method: 'DELETE',
        url: `/api/invoices/${invoiceId}`,
        headers: {'Authorization': store.auth.token},
    }

    // refresh manage invoice page
    axios.request(config)
        .then(response => {
            dispatch(
                addAlert({
                    message: 'Deleted an invoice', mood: 'warning', dismissAfter: 1500
                })
            )
            dispatch({
                type: DELETED_INVOICE,
                id: invoiceId
            })
            dispatch(fetchProposalServicesInfo(proposalId));
            dispatch(fetchProposalInvoices(selectedSite, proposalId, customerId));
        })
        .catch((error, response) => {
            dispatch(addAlert({
                message: `Could not Delete Invoice#${invoiceId}`,
                mood: 'danger', dismissAfter: 2000
            }))
        })
}

export const verifyInvoiceNumber = (invoice_no) => (dispatch, getState) => {
    const store = getState()
    const token = store.auth.token

    // get config
    const config = {
        method: 'GET',
        url: '/api/invoices/verify_invoice_number',
        headers: {'Authorization': store.auth.token},
        params: {
            invoice_no: invoice_no
        }
    }

    // shoot
    axios.request(config)
        .then(response => {
            dispatch({
                type: VERIFY_INVOICE_NUMBER,
                payload: response.data
            })
        })
        .catch((error, response) => {
            dispatch(
                addAlert({
                    message: 'Could not verify your invoice no. something went wrong',
                    mood: 'danger', dismissAfter: 2000
                })
            )
        })
}

export const fetchProposalInvoices = (selectedSite, proposalId, customerId) => async (dispatch, getState) => {
    const store = getState()
    // const selectedSite = store.customerInvoices.selectedSite

    if (!selectedSite || !customerId || !proposalId) {
        dispatch({type: FETCH_PROPOSAL_INVOICES, customerInvoices: []});
        return;
    }

    const config = {
        url: `/api/invoices/customer_invoices/`,
        headers: {Authorization: store.auth.token},
        params: {
            site_id: selectedSite,
            customer_id: customerId,
            proposal_id: proposalId,
        },
    }

    try {
        const response = await axios.request(config)
        dispatch({type: FETCH_PROPOSAL_INVOICES, customerInvoices: response.data})
    } catch (exception) {
        dispatch(
            addAlert({
                message: "Unable to fetch customer invoices because " +
                    exception.toString(),
            })
        )
        throw exception
    }
}

export const markInvoicesPaid = (invoiceIds, paidDate) => async (dispatch, getState) => {
    const store = getState();
    const token = store.auth.token;


    const config = {
        method: "POST",
        url: `/api/invoices/mark_invoice_paid`,
        headers: {Authorization: store.auth.token},
        params: {
            invoice_ids: invoiceIds.join(','),
            paid_at: paidDate
        },
    }

    try {
        const response = await axios.request(config);
        dispatch({
            type: INVOICE_MARK_PAID,
            payload: {invoice_ids: invoiceIds, paid_date: paidDate}
        });

        dispatch(
            addAlert({
                message: 'Paid date updated',
                mood: "success",
                dismissAfter: 1500,
            })
        )
    } catch (exception) {
        dispatch(
            addAlert({
                message: "Unable to mark invoices paid " +
                    exception.toString(),
            })
        )
        throw exception
    }
}

export const saveInvoicedDate = (invoiceId, invoicedDate) => async (dispatch, getState) => {
    const store = getState();
    const token = store.auth.token;

    const config = {
        method: "POST",
        url: `/api/invoices/update_invoiced_date`,
        headers: {Authorization: store.auth.token},
        params: {
            invoice_id: invoiceId,
            invoiced_at: invoicedDate
        },
    };

    try {
        const response = await axios.request(config);
        dispatch({
            type: CHANGED_INVOICED_DATE,
            payload: {invoice_id: invoiceId, invoiced_at: invoicedDate}
        });

        dispatch(
            addAlert({
                message: 'Invoice date updated',
                mood: "success",
                dismissAfter: 1500,
            })
        )
    } catch (exception) {
        dispatch(
            addAlert({
                message: "Unable to update invoiced date " +
                    exception.toString(),
            })
        );
        throw exception
    }
};
