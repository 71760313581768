import React, {useCallback, useEffect, useState} from "react";
import {Button, Col, Modal, Row} from "react-bootstrap";
import Select from "react-select";
import {select} from "../../../../common/commonHandlers";
import ColorCheckbox from "../../../Scheduler/ColorCheckbox";
import {debounce} from "throttle-debounce";
import _ from 'lodash'

const QuestionnaireFilterModal = ({onHide, onSave, startFilters, searchForCustomer, searchForInvoices}) => {
    const [filters, setFilters] = useState({})
    const [customerOptions, setCustomerOptions] = useState([])
    const [loadingCustomers, setLoadingCustomers] = useState(false)
    const [invoiceOptions, setInvoiceOptions] = useState([])
    const [loadingInvoice, setLoadingInvoice] = useState(false)

    const onChangeCheckbox = (e, number) => {
        if (e) {
            filters.stars.push(number)
            setFilters({...filters})
        } else {
            filters.stars = filters.stars.filter(f => f !== number)
            setFilters({...filters})
        }
    }

    const customerDelayedSearch = useCallback(debounce(1500, (text, filters) => customerSearch(text, filters)), [])
    const invoiceDelayedSearch = useCallback(debounce(1500, (text, filters) => invoiceSearch(text, filters)), [])

    const customerSearch = (text, filters) => {
        if (text && text.length > 0) {
            setLoadingCustomers(true)
            searchForCustomer(text, (res) => {
                setLoadingCustomers(false)
                const result = filters?.customerIds && filters?.customerIds.length > 0 ? res.concat(filters?.customerIds) : res
                const uniqueResult = Array.from(new Set(result))
                setCustomerOptions(uniqueResult)
            })
        }
    }

    const invoiceSearch = (text, filters) => {
        if (text && text.length > 0) {
            setLoadingInvoice(true)
            searchForInvoices(text, (res) => {
                setLoadingInvoice(false)
                const result = filters?.invoiceIds && filters?.invoiceIds.length > 0 ? res.concat(filters?.invoiceIds) : res
                const uniqueResult = Array.from(new Set(result))
                setInvoiceOptions(uniqueResult)
            })
        }
    }

    useEffect(() => {
        const newFilters = _.cloneDeep(startFilters)
        setFilters(newFilters)

        if (startFilters.customerIds && startFilters.customerIds.length > 0) {
            setCustomerOptions(startFilters.customerIds)
        }
        if (startFilters.invoiceIds && startFilters.invoiceIds.length > 0) {
            setInvoiceOptions(startFilters.invoiceIds)
        }
    }, [])

    return <Modal
        show={true}
        onHide={onHide}
        backdrop="static"
        id="questionnaire-filter-modal"
        className="heightAuto fontIOS"
    >
        <Modal.Header>
            <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row className="padding-top-bottom-8">
                <Col sm={8} xs={12}>
                    <Select className="Select"
                            classNamePrefix="select"
                            menuPortalTarget={document.body}
                            styles={{menuPortal: base => ({...base, zIndex: 9000})}}
                            value={select(customerOptions, filters?.customerIds?.map(e => e.value))}
                            options={customerOptions}
                            placeholder="Customers"
                            isDisabled={loadingCustomers}
                            isClearable
                            isMulti
                            isLoading={loadingCustomers}
                            onInputChange={(e) => customerDelayedSearch(e, filters)}
                            onChange={(e) => {
                                filters.customerIds = e
                                setFilters({...filters})
                            }}
                    />
                    <Select className="Select mt10"
                            classNamePrefix="select"
                            menuPortalTarget={document.body}
                            styles={{menuPortal: base => ({...base, zIndex: 9000})}}
                            value={select(invoiceOptions, filters?.invoiceIds?.map(e => e.value))}
                            options={invoiceOptions}
                            placeholder="Invoices"
                            isDisabled={loadingInvoice}
                            isClearable
                            isMulti
                            isLoading={loadingInvoice}
                            onInputChange={(e) => invoiceDelayedSearch(e, filters)}
                            onChange={(e) => {
                                filters.invoiceIds = e
                                setFilters({...filters})
                            }}
                    />
                </Col>
                <Col sm={4} xs={12}>
                    <ColorCheckbox
                        value={filters?.stars?.includes(1)}
                        label='★'
                        onChange={e => onChangeCheckbox(e, 1)}
                    />
                    <ColorCheckbox
                        value={filters?.stars?.includes(2)}
                        label='★★'
                        onChange={e => onChangeCheckbox(e, 2)}
                    />
                    <ColorCheckbox
                        value={filters?.stars?.includes(3)}
                        label='★★★'
                        onChange={e => onChangeCheckbox(e, 3)}
                    />
                    <ColorCheckbox
                        value={filters?.stars?.includes(4)}
                        label='★★★★'
                        onChange={e => onChangeCheckbox(e, 4)}
                    />
                    <ColorCheckbox
                        value={filters?.stars?.includes(5)}
                        label='★★★★★'
                        onChange={e => onChangeCheckbox(e, 5)}
                    />
                    <ColorCheckbox
                        value={filters?.noAnswer}
                        label='No answer'
                        onChange={e => {
                            filters.noAnswer = e
                            setFilters({...filters})
                        }}
                    />
                </Col>
            </Row>
        </Modal.Body>
        <Modal.Footer>
            <Button
                bsSize="small"
                bsStyle="warning"
                onClick={() => onHide()}
            >
                Close
            </Button>
            <Button
                bsSize="small"
                bsStyle="success"
                onClick={() => {
                    onSave(filters)
                    onHide()
                }}
            >
                Save
            </Button>
        </Modal.Footer>
    </Modal>
}

export default QuestionnaireFilterModal;