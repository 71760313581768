import React, {Component} from 'react';
import {FormControl, FormGroup, Modal, OverlayTrigger, Tooltip} from "react-bootstrap";
import Col from "react-bootstrap/lib/Col";
import Row from "react-bootstrap/lib/Row";
import {unitsTable} from "./ConsumablesHelper";
import {select} from "./commonHandlers";
import Select from "react-select";
import "./ConsumablesConsumptionModal.scss"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";

export default class ConsumablesConsumptionModal extends Component {

    state = {
        resource: {
            input: {},
            consumables: null,
            selectedMeasure: {},
            costs: {},
        },
        proposalServiceName: "",
        currentProposalServiceIterator: null,
        isSaveInProgress: false
    }

    componentDidMount() {
        const {psIds} = this.props
        if (psIds) {
            this.setState({currentProposalServiceIterator: 0})
            this.props.loadConsumables(psIds[0], (res) => {
                if (res.consumables && res.consumables.length > 0) {
                    this.setState({
                        resource: {
                            ...this.state.resource,
                            consumables: res.consumables
                        }
                    }, this.setFields)
                }
            })
        } else {
            this.setState({
                resource: {
                    ...this.state.resource,
                    consumables: this.props.consumables
                }
            }, this.setFields)
        }
    }

    nextProposalService = () => {
        const {psIds} = this.props
        if (psIds) {
            let {currentProposalServiceIterator} = this.state
            currentProposalServiceIterator += 1;
            this.setState({currentProposalServiceIterator})
            if (psIds[currentProposalServiceIterator]) {
                this.props.loadConsumables(psIds[currentProposalServiceIterator], (res) => {
                    if (res.consumables && res.consumables.length > 0) {
                        this.setState({
                            resource: {
                                ...this.state.resource,
                                consumables: res.consumables,
                                input: {}
                            }
                        }, this.setFields)
                    }
                })
            } else {
                this.props.closeModal()
            }
        } else {
            this.props.closeModal()
        }

    }

    setFields = () => {
        this.setState({isSaveInProgress: false})
        let {proposalServices, psIds} = this.props
        if (psIds) {
            const {currentProposalServiceIterator} = this.state
            proposalServices.map(ps => {
                if (ps.proposal_service_id) {
                    if (ps.proposal_service_id === psIds[currentProposalServiceIterator]) {
                        this.setState({proposalServiceName: "#" + ps.service_no + " " + ps.service_name})
                    }
                } else {
                    if (ps.id === psIds[currentProposalServiceIterator]) {
                        this.setState({proposalServiceName: "#" + ps.serviceNo + " " + ps.serviceName})
                    }
                }

            })
        }
        if (this.state.resource.consumables) {
            let consumablesGrouped = []
            let maxMixtureIterator = 0
            this.state.resource.consumables.map((c) => {
                if (c.mixture_group === null) {
                    consumablesGrouped.push(c)
                }
                if (typeof (c.mixture_group) === 'number' && c.mixture_group > maxMixtureIterator) {
                    maxMixtureIterator = c.mixture_group
                }
            })
            let currentMixtureIterator = 1
            let consumablesNames = []
            while (currentMixtureIterator <= maxMixtureIterator) {
                let countOfConsumables = 0
                let measurement_group_per
                this.state.resource.consumables.map((c) => {
                    if (c.mixture_group === currentMixtureIterator) {
                        countOfConsumables++
                        consumablesNames.push(c.name)
                        if (!measurement_group_per) {
                            measurement_group_per = c.measurement_group_per
                        }
                    }
                })
                let cG = {}
                cG.id = -currentMixtureIterator
                cG.mixture_group = currentMixtureIterator
                cG.name = "Mixture " + currentMixtureIterator
                cG.tooltip = 'Is mixture of: ' + consumablesNames.join(', ')
                cG.countOfConsumables = countOfConsumables
                cG.measurement_group_per = measurement_group_per
                consumablesGrouped.push(cG)
                consumablesNames = []
                currentMixtureIterator++
            }

            let selectedMeasure = {}
            consumablesGrouped.map(cG => {
                if (cG.measurement_per) {
                    selectedMeasure[cG.id + '+' + cG.mixture_group] = cG.measurement_per
                }
            })
            this.setState({resource: {...this.state.resource, selectedMeasure, consumablesGrouped}}, this.resetInput)
        }
    }

    calculateCost = () => {
        const {consumables, consumablesGrouped, input, selectedMeasure} = this.state.resource;
        let costs = {};
        let amounts = {}
        let selectedMeasurePer = {}
        consumablesGrouped.map((cG) => {
            if (cG.mixture_group === null) {
                let amount = input[cG.id + '+' + cG.mixture_group]
                if (amount && selectedMeasure[cG.id + '+' + cG.mixture_group]) {
                    let total_cost;
                    let selectedMeasureFactor;
                    let measurement_dilution_rate_factor;
                    let measurement_per_factor;
                    unitsTable[cG.measurement_group_per - 1].options.map(u => {
                        if (u.value === selectedMeasure[cG.id + '+' + cG.mixture_group]) {
                            selectedMeasureFactor = u.factor;
                        }
                        if (u.value === cG.measurement_dilution_rate) {
                            measurement_dilution_rate_factor = u.factor
                        }
                        if (u.value === cG.measurement_per) {
                            measurement_per_factor = u.factor
                        }
                    })
                    total_cost = amount * selectedMeasureFactor / (cG.per * measurement_per_factor) * cG.cost
                    costs[cG.id + '+' + cG.mixture_group] = Number(Number(total_cost).toFixed(2))
                    amounts[cG.id + '+' + cG.mixture_group] = amount
                    selectedMeasurePer[cG.id + '+' + cG.mixture_group] = selectedMeasure[cG.id + '+' + cG.mixture_group]
                }
            } else {
                consumables.filter((c) => c.mixture_group === cG.mixture_group).map(c => {
                    let amount = input[cG.id + '+' + cG.mixture_group]
                    if (amount && selectedMeasure[cG.id + '+' + cG.mixture_group]) {
                        let total_cost;
                        let selectedMeasureFactor;
                        let measurement_dilution_rate_factor;
                        let measurement_per_factor;
                        unitsTable[c.measurement_group_per - 1].options.map(u => {
                            if (u.value === selectedMeasure[cG.id + '+' + cG.mixture_group]) {
                                selectedMeasureFactor = u.factor;
                            }
                            if (u.value === c.measurement_dilution_rate) {
                                measurement_dilution_rate_factor = u.factor
                            }
                            if (u.value === c.measurement_per) {
                                measurement_per_factor = u.factor
                            }
                        })
                        total_cost = (amount / cG.countOfConsumables) * selectedMeasureFactor / (c.per * measurement_per_factor) * c.cost
                        costs[c.id + '+' + c.mixture_group] = Number(Number(total_cost).toFixed(2))
                        amounts[c.id + '+' + c.mixture_group] = amount / cG.countOfConsumables
                        selectedMeasurePer[c.id + '+' + c.mixture_group] = selectedMeasure[cG.id + '+' + cG.mixture_group]
                    }
                })
            }

        })
        this.setState({resource: {...this.state.resource, costs, amounts, selectedMeasurePer}})
    }

    validateInputs = () => {
        const {input, selectedMeasure, consumablesGrouped} = this.state.resource
        let valid = true;
        if (Object.keys(input).length === consumablesGrouped?.length) {
            if (Object.values(input).some(i => (i === "" || i.length < 1))) {
                valid = false;
            }
        } else {
            valid = false;
        }
        if (Object.keys(selectedMeasure).length !== consumablesGrouped?.length) {
            valid = false
        }
        return !valid;
    }

    resetInput = () => {
        const {consumablesGrouped} = this.state.resource
        let input = {}
        consumablesGrouped && consumablesGrouped.map(c => {
            input[c.id + '+' + c.mixture_group] = ""
        })
        this.setState({resource: {...this.state.resource, input}})
    }

    handleInput = (e) => {
        const {resource} = this.state
        const {value, name} = e.target

        if (value >= 0) {
            this.setState({
                resource: {...resource, input: {...this.state.resource.input, [name]: value}}
            }, () => {
                this.calculateCost()
            })
        }
    }

    renderTooltip = (text) => (
        <Tooltip id="tooltip">
            <div className="text-left">
                {text}
            </div>
        </Tooltip>
    );

    render() {
        const {consumablesGrouped, selectedMeasure} = this.state.resource;
        const {proposalServiceName, currentProposalServiceIterator, isSaveInProgress} = this.state
        const {psIds} = this.props
        return (
            <Modal
                id='consumption-modal'
                animation={true}
                show={this.props.showModal}
                className={"heightAuto"}
                bsSize="normal"
            >
                <Modal.Header>
                    {proposalServiceName || this.props.proposal_service_name ?
                        <Modal.Title>Please enter amount consumed in
                            service: {proposalServiceName || this.props.proposal_service_name}</Modal.Title>
                        : <Modal.Title>Please enter amount consumed in service</Modal.Title>}
                </Modal.Header>
                <Modal.Body>
                    {consumablesGrouped && consumablesGrouped.map(cG => {
                            return (
                                <Row>
                                    <FormGroup>
                                        <Col xs={4} style={{marginTop: "8px"}}>
                                            <b>{cG.name}</b>
                                            {cG.tooltip &&
                                                <OverlayTrigger placement="right" overlay={this.renderTooltip(cG.tooltip)}>
                                                    <FontAwesomeIcon color="#777777" icon={faInfoCircle}
                                                                     className="marginLeft10 font16"/>
                                                </OverlayTrigger>}
                                        </Col>
                                        <Col xs={4}>
                                            <FormControl
                                                placeholder="Amount"
                                                value={this.state.resource.input[cG.id + '+' + cG.mixture_group]}
                                                name={cG.id + '+' + cG.mixture_group}
                                                type="number"
                                                onChange={this.handleInput}
                                            />
                                        </Col>
                                        <Col xs={4}>
                                            <Select className="Select" classNamePrefix="select"
                                                    value={select(unitsTable[cG.measurement_group_per - 1]?.options, selectedMeasure[cG.id + '+' + cG.mixture_group])}
                                                    options={unitsTable[cG.measurement_group_per - 1]?.options}
                                                    onChange={(e) => {
                                                        let selectedMeasureCopy = selectedMeasure
                                                        selectedMeasureCopy[cG.id + '+' + cG.mixture_group] = e.value
                                                        this.setState({
                                                            resource: {
                                                                ...this.state.resource,
                                                                selectedMeasure: selectedMeasureCopy
                                                            }
                                                        })
                                                        this.calculateCost();
                                                    }}
                                            />
                                        </Col>
                                    </FormGroup>
                                </Row>
                            )
                        }
                    )}

                </Modal.Body>
                <Modal.Footer>
                    <button onClick={this.nextProposalService} type="button" className="btn btn-default"
                            data-dismiss="modal">Skip
                    </button>
                    <button
                        type="button"
                        className="btn btn-success"
                        disabled={this.validateInputs() || isSaveInProgress}
                        onClick={() => {
                            this.setState({isSaveInProgress: true})
                            this.props.sendConsumablesConsumption(this.state.resource.amounts, this.state.resource.costs,
                                this.state.resource.selectedMeasurePer, psIds ? psIds[currentProposalServiceIterator]
                                    : this.props.proposal_service_id, this.nextProposalService)
                        }}>
                        Save
                    </button>
                </Modal.Footer>
            </Modal>
        )
            ;
    }
}