import React, {useEffect, useState} from "react";
import './DragAndDropTable.scss'
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";

const isMobile = window.screen.width <= 1024;

const DragAndDropTable = ({
                              data,
                              columns,
                              visibleColumns,
                              updateResource,
                              noDataDescription,
                              onRowClick,
                              trClassName
                          }) => {
    const [resource, setResource] = useState(data)
    const [sort, setSort] = useState()
    const [availableDND, setAvailableDND] = useState(false)

    const iconUp =
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
             className="bi bi-caret-up-fill" viewBox="0 0 16 16">
            <path
                d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
        </svg>

    const iconDown =
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
             className="bi bi-caret-down-fill" viewBox="0 0 16 16">
            <path
                d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
        </svg>

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const newResource = reorder(
            resource,
            result.source.index,
            result.destination.index
        )
        setResource(newResource)
        if (typeof updateResource === 'function') {
            updateResource(newResource)
        }
    }

    const handleSorting = (param) => {
        const sortData = [].concat(resource)
            .sort((a, b) => {
                if (sort?.value) {
                    return a[param] < b[param] ? 1 : -1
                } else {
                    return a[param] > b[param] ? 1 : -1
                }
            })
        setResource(sortData)
        if (typeof updateResource === 'function') {
            updateResource(sortData)
        }
    }

    const cellValue = (cell, row) => {
        if (cell.dataFormat === undefined) {
            return row[cell.dataField]
        } else {
            const cellFormat = cell.dataFormat(row[cell.dataField], row)
            if (typeof cellFormat === "object") {
                return cellFormat
            } else {
                return `${cellFormat}`
            }
        }
    }

    const iconForSorting = (param) => {
        if (param === sort?.param) {
            if (sort.value === true) {
                return <div className='ascendingly'> {iconDown} </div>
            } else {
                return <div className='descending'> {iconUp} </div>
            }
        } else {
            return <div className='non-sort'> {iconDown} {iconUp} </div>
        }
    }

    const visibleColumnsPercentSum = () => {
        let percentSum = 0;
        columns.map(c => {
            if (visibleColumns[c.text]) {
                percentSum += parseFloat(c.width);
            }
        })
        return percentSum
    }

    const calculateColumnWidth = (width) => {
        if (visibleColumns) {
            let result = (parseFloat(width) / visibleColumnsPercentSum() * 100).toString() + '%'
            return result
        } else {
            return width
        }

    }

    useEffect(() => {
        setResource(data)
    }, [data])

    return (
        <>
            {!isMobile &&
                <div className="padlock hright pointer mr-10 font20" onClick={() => setAvailableDND(!availableDND)}>
                    {availableDND ? "🔓" : "🔒"}
                </div>
            }
            <table className="dnd-table">
                <thead>
                <tr className="header">
                    {columns.map((header, id) => {
                            return (
                                <>{((visibleColumns && visibleColumns[header.text]) || !visibleColumns) ?
                                    <th
                                        style={header.width !== undefined ?
                                            {width: `${calculateColumnWidth(header.width)}`} : {width: 'auto'}}
                                        key={id}
                                        onClick={() => {
                                            header.sortable === true &&
                                            handleSorting(header.dataField)
                                            setSort({param: header.dataField, value: !sort?.value})
                                        }}>
                                        {header.text}
                                        {header.sortable === true ?
                                            <div className="sort">
                                                {iconForSorting(header.dataField)}
                                            </div> : ''}
                                    </th> : null}
                                </>)
                        }
                    )
                    }
                    {isMobile && <th className="padlock" onClick={() => setAvailableDND(!availableDND)}>
                        {availableDND ? "🔓" : "🔒"}
                    </th>}
                </tr>

                </thead>
                {availableDND ? <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided) => (
                                <tbody
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                {resource.length > 0 ?
                                    resource.map((row, id) =>
                                        <Draggable
                                            key={row.id}
                                            draggableId={row.id.toString()}
                                            index={id}
                                        >
                                            {(provided) => (
                                                <tr
                                                    onClick={() => onRowClick && onRowClick(row)}
                                                    className={trClassName(row)}
                                                    key={id}
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    {columns.map((cell, index) =>
                                                        ((visibleColumns && visibleColumns[cell.text]) || !visibleColumns) ?
                                                            <td key={index}>{cellValue(cell, row)}</td> : null
                                                    )}
                                                    <td/>
                                                </tr>)}
                                        </Draggable>
                                    ) :
                                    <tr>
                                        <td className="no-data-td"
                                            colspan={columns.length + 1}>{noDataDescription === undefined ? 'There is no data to display' : noDataDescription}</td>
                                    </tr>
                                }
                                {provided.placeholder}
                                </tbody>)}
                        </Droppable>
                    </DragDropContext>
                    :
                    <tbody>
                    {resource.length > 0 ?
                        resource.map((row, id) =>
                            <tr key={id} onClick={() => onRowClick && onRowClick(row)} className={trClassName(row)}>
                                {columns.map((cell, index) =>
                                    ((visibleColumns && visibleColumns[cell.text]) || !visibleColumns) ?
                                        <td key={index}>{cellValue(cell, row)}</td> : null)}
                                <td/>
                            </tr>
                        ) :
                        <tr>
                            <td className="no-data-td"
                                colspan={columns.length + 1}>{noDataDescription === undefined ? 'There is no data to display' : noDataDescription}</td>
                        </tr>
                    }
                    </tbody>
                }
            </table>
        </>
    )
}

export default DragAndDropTable
