import {FETCH_SEARCH_RESULTS} from './constants'
import axios from 'axios'

export const doSearch = (q, where) => (dispatch, getState) => {
    let url = ''

    switch (where) {
        case 'customer':
            url = '/api/customers/search';
            break;
        case 'contacts':
            url = '/api/customer_contacts/search';
            break;
        case 'sites':
            url = '/api/sites/search';
            break;
        default:
            console.error("Unknown search location/context. Defaulting to Customer")
            url = '/api/customers/search';
    }

    const store = getState()
    const config = {
        url: url,
        headers: {'Authorization': store.auth.token},
        params: {
            q: q,
            context: where
        }
    }

    return axios.request(config)
        .then(response => {
            dispatch({type: FETCH_SEARCH_RESULTS, searchResults: response.data});
        })

}