import React from 'react';
import PropTypes from "prop-types"
import {Button, Col, ControlLabel, FormControl, FormGroup, Glyphicon, Row, Well,} from 'react-bootstrap'
import {Link} from "react-router";

const icon = ['star']

const LoginWidget = ({
                         isAuthenticated,
                         email,
                         password,
                         updateEmail,
                         updatePassword,
                         logout,
                         authenticate,
                         allowRegistration
                     }) => {
    const onAuthenticate = e => {
        e.preventDefault()
        authenticate()
    }

    return (
        <div>
            {isAuthenticated
                ? <Row>
                    <Col xs={12} className="text-center">
                        <h4>You are logged in as {email}.</h4><br/>
                        <Button bsStyle="danger" onClick={logout}>Log Out</Button>
                    </Col>
                </Row>
                : <Well>
                    <h4>Please Login</h4>
                    <form onSubmit={onAuthenticate} className="mt33">
                        <FormGroup>
                            <ControlLabel>Email</ControlLabel>
                            <FormControl
                                type="text"
                                className='font16'
                                placeholder="Email Address"
                                value={email}
                                onChange={updateEmail}
                            />
                        </FormGroup>

                        <FormGroup>
                            <ControlLabel>Password</ControlLabel>
                            <FormControl
                                type="password"
                                className='font16'
                                placeholder="Password"
                                value={password}
                                onChange={updatePassword}
                            />
                        </FormGroup>
                        <Button bsStyle="info" type="submit" className="mt33">
                            <Glyphicon glyph={icon[icon.length - 1]}/> Login
                        </Button>
                        {allowRegistration && <p>Don't have an account? <Link to="register">Register</Link></p>}
                    </form>
                </Well>}
        </div>
    )

}

LoginWidget.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    client: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
    updateClient: PropTypes.func.isRequired,
    updateEmail: PropTypes.func.isRequired,
    updatePassword: PropTypes.func.isRequired,
    authenticate: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
}

export default LoginWidget
