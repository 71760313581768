import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Button, Col, Glyphicon, Grid, Row, Tab, Tabs} from "react-bootstrap";
import moment from "moment/moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import createApiService from "../../common/clientActionsBuilder";
import HolidayModal from "./HolidayModal";
import './Holidays.css';
import {addAlert} from "../App/actions"
import {
    fetchHolidayFiles,
    fetchHolidayImages,
    getHoliday,
    removeHolidayFile,
    removeHolidayImage,
    rotateImage,
    saveHolidayTabs,
    uploadFiles,
    uploadImages
} from './HolidaysApi'
import {browserHistory} from "react-router";
import {defaultDateFormatShort} from "../../common/commonHandlers";
import StandardHolidayTab from "./Tabs/StandardHolidayTab";
import EditedHolidayTab from "./Tabs/EditedHolidayTab";
import NewTabModal from "./NewTabModal";

const isMobile = window.screen.width <= 1024;
const smMobile = window.screen.width <= 450;

const holidayAPI = createApiService('holidays', 'holiday', 'Holidays')

const api = {
    ...holidayAPI,
    uploadImages,
    uploadFiles,
    fetchHolidayImages,
    fetchHolidayFiles,
    removeHolidayImage,
    removeHolidayFile,
    rotateImage,
    addAlert,
    getHoliday,
    saveHolidayTabs
};

const Holidays = ({actions, location, roles}) => {
    const [holidays, setHolidays] = useState([])
    const [editedHoliday, setEditedHoliday] = useState({})
    const [resource, setResource] = useState({sort: 'ASC', sort_by: 'date'})
    const [activeKey, setActiveKey] = useState(2)
    const [tabs, setTabs] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [showNewTabModal, setShowNewTabModal] = useState(false)
    const [loadingHolidays, setLoadingHolidays] = useState(false)
    const [errorLoadingHolidays, setErrorLoadingHolidays] = useState(false)

    const dateFormatter = (cell, row) => {
        const dateDiff = moment(row.date).startOf('day').diff(moment().startOf('day'))

        if (dateDiff >= 0) {
            return (
                <div>
                    <div className={'word-break'}>{moment(row.date).format(defaultDateFormatShort)}
                        {dateDiff === 0 ? ` (today)` : ` (in ${moment(row.date).startOf('day').diff(moment().startOf('day'), 'days')} days)`}
                    </div>
                </div>
            );
        } else {
            return (
                <div className={'word-break'}>{moment(row.date).format(defaultDateFormatShort)}</div>
            )
        }
    }

    const indexN = (cell, row, enumObject, index) => {
        return <div>{index + 1}</div>
    };

    const rowFormatter = (cell, row, enumObject, index) => {
        const selectedTab = tabs[activeKey - 1]?.key

        return <div>
            <Row>
                <Col xs={6}>#</Col>
                <Col xs={6}>{indexN(cell, row, enumObject, index)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Name</Col>
                <Col xs={6}>{row.name}</Col>
            </Row>
            <Row>
                <Col xs={6}>Date</Col>
                <Col xs={6}>{dateFormatter(cell, row)}</Col>
            </Row>
            {selectedTab !== 'holidays' && <Row>
                <Col xs={6}>Options</Col>
                <Col xs={6}>
                    <div>
                    <span
                        onClick={() => {
                            setEditedHoliday(row)
                            setShowModal(true)
                        }}
                        className="pointer"
                    >
                        <FontAwesomeIcon icon="edit" className="bigger"/>
                    </span>
                    </div>
                </Col>
            </Row>}
        </div>
    };

    const optionsFormatter = (cell, row) => {
        return <div>
            <span
                onClick={() => {
                    setEditedHoliday(row)
                    setShowModal(true)
                }} className="pointer"
            >
                <FontAwesomeIcon icon="edit" className="bigger"/>
            </span>
        </div>
    }

    const rowStyleFormat = (cell) => {
        if (cell && cell.date && moment(cell.date).startOf('day').diff(moment().startOf('day'), 'days') < 0) {
            return {backgroundColor: 'rgba(211, 217, 227, 0.4)'}
        }
    };

    const reload = () => {
        const holidayId = location?.query?.holiday_id
        const selectedTab = tabs[activeKey - 1]?.key

        setLoadingHolidays(true)
        setErrorLoadingHolidays(false)

        actions.getHoliday(resource, selectedTab, holidayId, results => {
            let holidays = results.holidays.map(h => {
                if (h.recurring && moment(h.date) < moment()) {
                    const parsedDate = moment(h.date, "YYYY-MM-DD")
                    const currentYear = moment().year();
                    let formattedDate = parsedDate.year(currentYear).format("YYYY-MM-DD")

                    if (formattedDate < moment().format("YYYY-MM-DD")) {
                        const nextYear = moment().year() + 1;
                        formattedDate = parsedDate.year(nextYear).format("YYYY-MM-DD")
                    }
                    h.date = formattedDate
                }
                return h
            })

            let currentHolidays = []
            let pastHolidays = []

            if (holidays && holidays.length > 0) {
                holidays.map(holiday => {
                    holiday.duration = moment(holiday.date).startOf('day').diff(moment().startOf('day'), 'days') >= 0

                    if (!holiday.duration) {
                        pastHolidays.push(holiday)
                    } else {
                        currentHolidays.push(holiday)
                    }
                })
                currentHolidays = currentHolidays.sort((a, b) => new Date(a.date) - new Date(b.date))
                holidays = currentHolidays.concat(pastHolidays.sort((a, b) => new Date(b.date) - new Date(a.date)))
            }

            setHolidays(holidays)
            setTabs(JSON.parse(results.tabs))

            if (holidayId) {
                const holiday = holidays.find((h) => h.id === parseInt(holidayId))
                if (holiday) {
                    if (holiday.tab) {
                        const clientTab = JSON.parse(results.tabs)
                        const tabIndex = clientTab.findIndex(el => el.key === holiday.tab)
                        if (tabIndex > 0) {
                            setActiveKey(tabIndex + 1)
                        }
                    }
                    setEditedHoliday(holiday)
                    setShowModal(true)
                } else {
                    setActiveKey(1)
                }

                browserHistory.push({
                    pathname: window.location.pathname,
                    search: ''
                });
            }
            setLoadingHolidays(false)
        }, () => {
            setErrorLoadingHolidays(true)
            setLoadingHolidays(false)
        });
    };

    const onDelete = (holiday) => {
        actions.remove(holiday, () => closeModal())
    }

    const save = (holiday) => {
        holiday.tab = tabs[activeKey - 1]?.key

        actions.save(holiday, () => closeModal())
    }

    const addNewTab = (newTabName) => {
        const newTab = {
            name: newTabName.trim(),
            key: newTabName.trim().replace(/\s/g, '_').toLowerCase()
        }
        const newTabs = tabs.concat(newTab)

        onSaveTabs(JSON.stringify(newTabs), () => setActiveKey(newTabs.length))
    }

    const removeTab = (tabName) => {
        const newTabs = tabs.filter(tab => tab.name !== tabName)

        onSaveTabs(JSON.stringify(newTabs), () => setActiveKey(2))
    }

    const onSaveTabs = (tabs, callback) => {
        actions.saveHolidayTabs(tabs, res => {
            setTabs(res)
            callback && callback()
        })
    }

    const closeModal = () => {
        setEditedHoliday({})
        setShowModal(false)
        reload()
    };

    const onSortChange = (sort_by, sort) => {
        setResource({...resource, sort_by, sort})
    }

    useEffect(() => {
        reload()
    }, [resource.sort_by, resource.sort, activeKey])

    useEffect(() => {
        reload()
    }, [])

    return (
        <Grid fluid id='holidays'>
            <Row className="vcenter">
                <Col md={4} className={isMobile ? "vcenter full-width" : "vcenter"}>
                    <h2 className="no-top-margin mr-9">Holidays</h2>
                    <h5 className={smMobile ? "text-primary nmt-10" : "text-primary"}>{holidays?.length} returned</h5>
                </Col>
                <Col md={8} className={isMobile ? "text-right full-width" : "text-right"}>
                    {activeKey !== 1 && <Button
                        bsStyle="primary"
                        className="no-top-margin small-margin"
                        onClick={() => {
                            setEditedHoliday({})
                            setShowModal(true)
                        }}
                    >
                        New {" "}
                        <FontAwesomeIcon
                            icon="plus"
                            className="small"
                        />
                    </Button>}
                </Col>
            </Row>
            <Tabs
                defaultActiveKey={2}
                animation={false}
                id="noanim-tab-example"
                onSelect={(key) => {
                    if (key === 'new_tab') {
                        setShowNewTabModal(true)
                    } else if (key !== activeKey) {
                        setLoadingHolidays(true)
                        setHolidays([])
                        setActiveKey(key)
                    }
                }}
                activeKey={activeKey}
            >
                {tabs?.map((tab, i) => {
                    return <Tab
                        eventKey={i + 1}
                        title={
                            <span>
                                <span>{tab.name}</span>
                                {!loadingHolidays && !errorLoadingHolidays && holidays && holidays.length === 0 && roles.includes("admin") && activeKey === i + 1 && tab.key !== 'existing' && tab.key !== 'holidays' &&
                                    <Glyphicon
                                        glyph='trash'
                                        className="pointer ml-6"
                                        onClick={() => {
                                            removeTab(tab.name)
                                        }}/>
                                }
                            </span>
                        }
                    >
                        {activeKey === (i + 1) && tab.key === 'holidays' ?
                            <StandardHolidayTab
                                holidays={holidays}
                                resource={resource}
                                onSortChange={onSortChange}
                                rowStyleFormat={rowStyleFormat}
                                rowFormatter={rowFormatter}
                                indexN={indexN}
                                dateFormatter={dateFormatter}
                            />
                            :
                            <EditedHolidayTab
                                holidays={holidays}
                                resource={resource}
                                onSortChange={onSortChange}
                                rowStyleFormat={rowStyleFormat}
                                rowFormatter={rowFormatter}
                                indexN={indexN}
                                dateFormatter={dateFormatter}
                                optionsFormatter={optionsFormatter}
                            />
                        }
                    </Tab>
                })}
                {roles.includes("admin") &&
                    <Tab eventKey='new_tab' tabClassName='add-new-tab' title={<Glyphicon glyph='plus'/>}></Tab>}
            </Tabs>
            {showModal &&
                <HolidayModal
                    onHide={closeModal}
                    editedHoliday={editedHoliday}
                    onSave={save}
                    onDelete={onDelete}
                    fetchHolidayImages={actions.fetchHolidayImages}
                    fetchHolidayFiles={actions.fetchHolidayFiles}
                    removeHolidayImage={actions.removeHolidayImage}
                    removeHolidayFile={actions.removeHolidayFile}
                    uploadImages={actions.uploadImages}
                    uploadFiles={actions.uploadFiles}
                    rotateImage={actions.rotateImage}
                    addAlert={actions.addAlert}
                />
            }
            {showNewTabModal &&
                <NewTabModal
                    onHide={() => setShowNewTabModal(false)}
                    onSave={addNewTab}
                    existingTabsNames={tabs.map(tab => tab.name.toLowerCase())}
                />
            }
        </Grid>
    );
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(api, dispatch)
    };
}

export default connect(null, mapDispatchToProps)(Holidays);