import {Button, Col, MenuItem, Nav, NavDropdown, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import {dateFormatter, dollarsFormatter} from "../../components/BootStrapTableCellFormatters";
import React from "react";
import {LinkContainer} from "react-router-bootstrap";
import {Link} from "react-router";
import moment from "moment";
import cardIcon from "../CustomerViewPage/card.png";

const isMobile = window.screen.width <= 1024;

const InvoicesTable = ({
                           renderPaginationPanel,
                           createCustomToolBar,
                           sizePerPageDropDown,
                           tableRef,
                           total,
                           onRowSelect,
                           onSelectAll,
                           resource,
                           updatePaging,
                           updateSorting,
                           columnsSettings,
                           invoices,
                           loaded,
                           selectedRows,
                           doExportToXLSX,
                           onPayButtonClick,
                           email
                       }) => {
    const columnsList = [
        {label: "Customer", width: "10%"},
        {label: "Site", width: "15%"},
        {label: "Arborist", width: "10%"},
        {label: "Title", width: "14%"},
        {label: "Invoice notes", width: "10%"},
        {label: "Proposal #", width: "8%"},
        {label: "WO #", width: "7%"},
        {label: "Invoice #", width: "7%"},
        {label: "Status Dates", width: "18%"},
        {label: "Sent", width: "9%"},
        {label: "Aging", width: "7%"},
        {label: "Balance", width: "8%"},
        {label: "Cost", width: "8%"},
        {label: 'Pay', width: "7%"}
    ]

    const visibleColumnsPercentSum = () => {
        let percentSum = 0;
        columnsList.map(c => {
            if (columnsSettings[c.label]) {
                percentSum += parseFloat(c.width);
            }
        })
        return percentSum
    }

    const calculateColumnWidth = (width) => {
        if (columnsSettings) {
            let result = (parseFloat(width) / visibleColumnsPercentSum() * 100).toString() + '%'
            return result
        } else {
            return width
        }

    }

    const customerFormatter = (cell, row) => (
        <NavDropdown
            title={row.customer_name}
        >
            <LinkContainer to={`/customer/info/${row.customer_id}`}>
                <MenuItem bsSize="small" eventKey={2.6}>Info</MenuItem>
            </LinkContainer>
            <LinkContainer to={`/customer/proposals/${row.customer_id}`}>
                <MenuItem bsSize="small" eventKey={2.6}>Proposals</MenuItem>
            </LinkContainer>
            <LinkContainer to={`/customer/sites/${row.customer_id}`}>
                <MenuItem bsSize="small" eventKey={2.6}>Sites</MenuItem>
            </LinkContainer>
            <LinkContainer to={`/customer/work_orders/${row.customer_id}`}>
                <MenuItem bsSize="small" eventKey={2.6}>Work Orders</MenuItem>
            </LinkContainer>
            <LinkContainer to={`/customer/invoices/${row.customer_id}`}>
                <MenuItem bsSize="small" eventKey={2.6}>Invoices</MenuItem>
            </LinkContainer>
            <LinkContainer to={`/customer/work_history/${row.customer_id}`}>
                <MenuItem bsSize="small" eventKey={2.6}>Work History</MenuItem>
            </LinkContainer>
        </NavDropdown>
    )

    const siteFormatter = (cell, row) => (
        <div className="columnDirection">
            <span className="bold">{row.site_name}</span>
            <span>{row.site_full_address}</span>
        </div>
    );

    const invoiceFormatter = (cell, row) => {
        return <Nav>
            <NavDropdown
                title={row.number}
                className="menu-show-fixer no-caret-in-dropdown nmargin"
            >
                <li className="pointer">
                    <Link to={`/proposals/${row.proposal_id}/invoices`}>Invoice</Link>
                </li>
                <li className="pointer">
                    <Link onClick={() => email(row)}>Email</Link>
                </li>
            </NavDropdown>
        </Nav>
    };

    const invoiceNotesFormatter = (cell, row) => {
        let invoice_notes = ""
        let compact = false
        if (row.invoice_notes?.length > 80) {
            invoice_notes = row.invoice_notes?.substring(0, 80) + "..."
            compact = true
        }

        return <div>{compact ?
            <div>
                <OverlayTrigger
                    placement={'bottom'}
                    overlay={
                        <Tooltip id={`tooltip-edit-description`}>
                            {row.invoice_notes?.split(';').map((invoice_note) => {
                                return <div>{invoice_note}</div>
                            })}
                        </Tooltip>
                    }
                >
                    <div className="word-break">
                        {invoice_notes?.split(';').map((invoice_note) => {
                            return <div>{invoice_note}</div>
                        })}
                    </div>
                </OverlayTrigger>
            </div> :
            <div>
                {row.invoice_notes?.split(';').map((invoice_note) => {
                    return <div className="word-break">{invoice_note}</div>
                })}
            </div>
        }</div>
    }

    const proposalFormatter = (cell, row) => (
        <Nav>
            <NavDropdown
                eventKey={2}
                title={row.proposal_no}
                id={`proposal-menu`}
                className="menu-show-fixer proposal-menu-padding-reset"
            >
                <LinkContainer to={`/mapview/${row.proposal_id}`}>
                    <MenuItem bsSize="small">Edit Proposal</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/proposal_service_status/${row.proposal_id}`}>
                    <MenuItem bsSize="small">Proposal Statuses</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/print_proposal/${row.proposal_id}`}>
                    <MenuItem bsSize="small">Print Proposal</MenuItem>
                </LinkContainer>
            </NavDropdown>
        </Nav>
    );

    const woFormatter = (cell, row) => {
        let wo_number;
        if (row.wo_numbers && row.wo_numbers.includes(",")) {
            wo_number = row.wo_numbers.split(",").join("\n")
        } else {
            wo_number = row.wo_numbers
        }
        return (
            <Nav>
                <NavDropdown
                    title={wo_number}
                    className="menu-show-fixer no-caret-in-dropdown nmargin max_width_70"
                >
                    <li className="pointer">
                        <Link to={`/work_orders/${row.proposal_id}`}>Work Order</Link>
                    </li>
                    <li className="pointer">
                        <Link to={`/complete_work_orders/${row.wo_numbers}`}>Complete Work Order</Link>
                    </li>
                    <li className="pointer">
                        <Link to={`/print_work_orders/${row.wo_numbers}`}>Print Work Order</Link>
                    </li>
                </NavDropdown>
            </Nav>
        )
    }

    const _statusDateFormatter = (cell, row) => {
        const currentDate = moment(moment(new Date()), "MM/DD/YYYY, h:mm a");
        const paidDate = moment(moment(row.paid_at), "MM/DD/YYYY, h:mm a");
        const dueDate = moment(moment(row.due_date), "MM/DD/YYYY, h:mm a");
        const tooltip = row.payments && row.payments.length >= 1 && row.payments.map((p, index) => (<div>
            <span
                key={index}>{moment(p.payment_date).format("MM/DD/YYYY, h:mm a")}{' '}Payment #{p.id}{' '}Amount: {dollarsFormatter(p.amount)}
            </span>
        </div>));
        return (
            <div style={{display: 'grid'}}>
                <div>
                    <small style={{color: 'black'}}>
                        <span>{row.invoiced_at ?
                            `Invoiced: ${row && moment(row.invoiced_at).format("MM/DD/YYYY, h:mm a")}`
                            : null}
                        </span>
                        {row.viewed && <span>{' '}(Viewed)</span>}
                    </small>
                </div>
                <div>
                    <small>
                        <span>{row.paid_at ?
                            (<div
                                className="text-success">{`Paid: ${row && moment(row.paid_at).format("MM/DD/YYYY, h:mm a")} (${dueDate.diff(paidDate) > 0 ? `Early` : `Late`} ${Math.abs(Math.round((dueDate.diff(paidDate, 'minutes') / 1440)))})`}{row.payments && row.payments.length === 1 && `Payment #${row.payments[0].id}`}</div>)
                            : null}
                        </span>
                        <span>{!row.paid_at && dueDate.diff(currentDate) > 0 ?
                            (<div
                                className="text-warning">{`Open: ${row && moment(row.due_date).format("MM/DD/YYYY, h:mm a")} (Due ${Math.abs(Math.round(dueDate.diff(currentDate, 'minutes') / 1440))})`}
                            </div>)
                            : null}
                        </span>
                        <span>{!row.paid_at && dueDate.diff(currentDate) < 0 ?
                            (<div
                                className="text-danger">{`Overdue: ${row && moment(row.due_date).format("MM/DD/YYYY, h:mm a")} (Late ${Math.abs(Math.round(dueDate.diff(currentDate, 'minutes') / 1440))})`}
                            </div>)
                            : null}
                        </span>
                        {
                            row.last_email_send && <span
                                className={'text-blue font-weight-normal'}>Last send: {moment(row.last_email_send).format("MM/DD/YYYY, h:mm a")}</span>
                        }
                    </small>
                </div>
                <div>
                    {!row.paid_at && row.payments && row.payments.length >= 1 &&
                        <OverlayTrigger placement="top"
                                        overlay={<Tooltip>{tooltip}</Tooltip>}>
                            <small>
                            <span
                                className={'text-purple'}>Payment:{' '}{moment(row.payments[0].created_at).format("MM/DD/YYYY, h:mm a")}</span>
                            </small>
                        </OverlayTrigger>}
                </div>
            </div>
        )
    }

    const payFormatter = (cell, row) => {
        return <div>
            <div className="pay-flex-center"><Button
                onClick={() => row.token && onPayButtonClick(row.token)}
            >
                Pay
            </Button>
                {row.customer_payment_settings === "payByStripe" &&
                    <img alt='card'
                         onClick={() => row.token && onPayButtonClick(row.token)}
                         src={cardIcon} style={{height: 20}}/>}
            </div>
        </div>
    }

    const rowFormatter = (cell, row) => {
        return <div>
            {columnsSettings["Customer"] && <Row>
                <Col xs={6}>Customer</Col>
                <Col xs={6}>{customerFormatter(cell, row)}</Col>
            </Row>}
            {columnsSettings["Site"] && <Row>
                <Col xs={6}>Site</Col>
                <Col xs={6}>{siteFormatter(cell, row)}</Col>
            </Row>}
            {columnsSettings["Arborist"] && <Row>
                <Col xs={6}>Arborist</Col>
                <Col xs={6}>{row.arborist}</Col>
            </Row>}
            {columnsSettings["Title"] && <Row>
                <Col xs={6}>Title</Col>
                <Col xs={6}>{row.proposal_title}</Col>
            </Row>}
            {columnsSettings["Invoice notes"] && <Row>
                <Col xs={6}>Invoice notes</Col>
                <Col xs={6}>{invoiceNotesFormatter(cell, row)}</Col>
            </Row>}
            {columnsSettings["Proposal #"] && <Row>
                <Col xs={6}>Proposal #</Col>
                <Col xs={6}>{proposalFormatter(cell, row)}</Col>
            </Row>}
            {columnsSettings["WO #"] && <Row>
                <Col xs={6}>WO #</Col>
                <Col xs={6} className='link-no-padding'>{woFormatter(cell, row)}</Col>
            </Row>}
            {columnsSettings["Invoice #"] && <Row>
                <Col xs={6}>Invoice #</Col>
                <Col xs={6} className='link-no-padding'>{invoiceFormatter(cell, row)}</Col>
            </Row>}
            {columnsSettings["Status Dates"] && <Row>
                <Col xs={6}>Status Dates</Col>
                <Col xs={6}>{_statusDateFormatter(cell, row)}</Col>
            </Row>}
            {columnsSettings["Sent"] && <Row>
                <Col xs={6}>Sent</Col>
                <Col xs={6}>{row.invoice_sent_count}</Col>
            </Row>}
            {columnsSettings["Aging"] && <Row>
                <Col xs={6}>Aging</Col>
                <Col xs={6}>{row.aging}</Col>
            </Row>}
            {columnsSettings["Balance"] && <Row>
                <Col xs={6}>Balance</Col>
                <Col xs={6}>{dollarsFormatter(row.balance)}</Col>
            </Row>}
            {columnsSettings["Cost"] && <Row>
                <Col xs={6}>Cost</Col>
                <Col xs={6}>{dollarsFormatter(row.cost)}</Col>
            </Row>}
            {columnsSettings["Pay"] && <Row>
                <Col xs={6}>Pay</Col>
                <Col xs={6}>{payFormatter(cell, row)}</Col>
            </Row>}
        </div>
    };

    const csvDollarFormatter = cell => {
        return parseFloat(cell ? cell : 0).toFixed(2)
    }

    const rowStyleFormat = (cell, row) => {
        return {
            background: invoices.map((invoice, i) => invoice.inactive === true ? i : -1).filter(index => index !== -1).includes(row) ? '#EBEBE4' : null
        }
    };

    return <>{
        isMobile ?
            <div>
                <Row className="nomarginLR">
                    <Col md={6} className="bottom15">
                        <Button bsStyle="success" disabled={!loaded || selectedRows.length === 0}
                                onClick={doExportToXLSX}>Export to XLSX
                        </Button>
                    </Col>
                </Row>
                <Col xs={12} className="remaining vertical-responsive-height">
                    <BootstrapTable
                        ref={tableRef}
                        data={invoices}
                        striped={true}
                        bordered={false}
                        hover={true}
                        pagination={true}
                        remote
                        trStyle={(cell, row) => rowStyleFormat(cell, row)}
                        fetchInfo={{dataTotalSize: total}}
                        className="wrapped"
                        exportCSV={false}
                        options={
                            {
                                paginationPanel: renderPaginationPanel,
                                onPageChange: (page, per_page) => {
                                    if (resource.per_page !== per_page) {
                                        page = 1
                                    }
                                    updatePaging(page, per_page)
                                },
                                page: resource.page,
                                toolBar: createCustomToolBar,
                                sizePerPageList: [{
                                    text: '10', value: 10
                                }, {
                                    text: '25', value: 25
                                },
                                    {
                                        text: '30', value: 30
                                    },
                                    {
                                        text: '50', value: 50
                                    },
                                    {
                                        text: '100', value: 100
                                    },
                                    {
                                        text: '200', value: 200
                                    },
                                    {
                                        text: 'All', value: total
                                    }],
                                sizePerPage: resource.per_page,
                                sortName: resource.sort_by,
                                sortOrder: resource.sort,
                                sizePerPageDropDown: sizePerPageDropDown,
                                onSortChange: (sort_by, sort) => {
                                    updateSorting(sort_by, sort)
                                },
                            }
                        }
                        selectRow={{
                            mode: "checkbox",
                            clickToSelect: false,
                            onSelect: onRowSelect,
                            onSelectAll: onSelectAll,
                        }}
                    >
                        <TableHeaderColumn
                            dataField="id"
                            hidden
                            isKey
                        />
                        <TableHeaderColumn
                            dataFormat={rowFormatter}
                            export={false}
                        >
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="customer_name"
                            dataFormat={customerFormatter}
                            defaultASC
                            dataSort
                            width={"10%"}
                            hidden
                            export={true}
                        >
                            Customer
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="site_full_address"
                            dataFormat={siteFormatter}
                            defaultASC
                            dataSort
                            width={"15%"}
                            hidden
                            export={true}
                        >
                            Site
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="arborist"
                            defaultASC
                            dataSort
                            width={"10%"}
                            hidden
                            export={true}
                        >
                            Arborist
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="proposal_title"
                            defaultASC
                            dataSort
                            width={"14%"}
                            hidden
                            export={true}
                        >
                            Title
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="invoice_notes"
                            dataFormat={invoiceNotesFormatter}
                            defaultASC
                            width={"10%"}
                            hidden
                            export={true}
                        >
                            Invoice notes
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="proposal_no"
                            dataFormat={proposalFormatter}
                            defaultASC
                            dataSort
                            width={"8%"}
                            hidden
                            export={true}
                        >
                            Proposal #
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="wo_numbers"
                            dataFormat={woFormatter}
                            defaultASC
                            dataSort
                            width={"7%"}
                            hidden
                            export={true}
                        >
                            WO #
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="number"
                            dataFormat={invoiceFormatter}
                            defaultASC
                            dataSort
                            width={"7%"}
                            hidden
                            export={true}
                        >
                            Invoice #
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="invoiced_at"
                            dataFormat={_statusDateFormatter}
                            defaultASC
                            dataSort
                            width={"18%"}
                            hidden
                            export={true}
                            csvFormat={dateFormatter}
                        >
                            Status Dates
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="invoice_sent_count"
                            dataSort
                            defaultASC
                            width={"9%"}
                            hidden
                            export={true}
                        >
                            Sent
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="aging"
                            dataSort
                            defaultASC
                            width={"7%"}
                            export={true}
                            hidden
                        >
                            Aging
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="balance"
                            dataFormat={dollarsFormatter}
                            defaultASC
                            dataSort
                            width={"8%"}
                            csvFormat={csvDollarFormatter}
                            hidden
                            export={true}
                        >
                            Balance
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="cost"
                            dataFormat={dollarsFormatter}
                            defaultASC
                            dataSort
                            width={"8%"}
                            csvFormat={csvDollarFormatter}
                            hidden
                            export={true}
                        >
                            Cost
                        </TableHeaderColumn>
                    </BootstrapTable>
                </Col>
            </div>
            :
            <Col xs={12}>
                <BootstrapTable
                    ref={tableRef}
                    data={invoices}
                    striped={true}
                    bordered={false}
                    hover={true}
                    pagination={true}
                    remote
                    trStyle={(cell, row) => rowStyleFormat(cell, row)}
                    fetchInfo={{dataTotalSize: total}}
                    className="wrapped"
                    exportCSV={false}
                    options={
                        {
                            paginationPanel: renderPaginationPanel,
                            onPageChange: (page, per_page) => {
                                if (resource.per_page !== per_page) {
                                    page = 1
                                }
                                updatePaging(page, per_page)
                            },
                            page: resource.page,
                            toolBar: createCustomToolBar,
                            sizePerPageList: [{
                                text: '10', value: 10
                            }, {
                                text: '25', value: 25
                            },
                                {
                                    text: '30', value: 30
                                },
                                {
                                    text: '50', value: 50
                                },
                                {
                                    text: '100', value: 100
                                },
                                {
                                    text: '200', value: 200
                                },
                                {
                                    text: 'All', value: total
                                }],
                            sizePerPage: resource.per_page,
                            sortName: resource.sort_by,
                            sortOrder: resource.sort,
                            sizePerPageDropDown: sizePerPageDropDown,
                            onSortChange: (sort_by, sort) => {
                                updateSorting(sort_by, sort)
                            },
                        }
                    }
                    selectRow={{
                        mode: "checkbox",
                        clickToSelect: false,
                        onSelect: onRowSelect,
                        onSelectAll: onSelectAll,
                    }}
                >
                    <TableHeaderColumn
                        dataField="id"
                        hidden
                        width={"5%"}
                        isKey
                    />
                    <TableHeaderColumn
                        hidden={!columnsSettings["Customer"]}
                        dataField="customer_name"
                        dataFormat={customerFormatter}
                        defaultASC
                        dataSort
                        width={calculateColumnWidth("10%")}
                    >
                        Customer
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        hidden={!columnsSettings["Site"]}
                        dataField="site_full_address"
                        dataFormat={siteFormatter}
                        defaultASC
                        dataSort
                        width={calculateColumnWidth("15%")}
                    >
                        Site
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        hidden={!columnsSettings["Arborist"]}
                        dataField="arborist"
                        defaultASC
                        dataSort
                        width={calculateColumnWidth("10%")}
                    >
                        Arborist
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        hidden={!columnsSettings["Title"]}
                        dataField="proposal_title"
                        defaultASC
                        dataSort
                        width={calculateColumnWidth("14%")}
                    >
                        Title
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        hidden={!columnsSettings["Invoice notes"]}
                        dataField="invoice_notes"
                        dataFormat={invoiceNotesFormatter}
                        defaultASC
                        width={calculateColumnWidth("10%")}
                    >
                        Invoice notes
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        hidden={!columnsSettings["Proposal #"]}
                        dataField="proposal_no"
                        dataFormat={proposalFormatter}
                        defaultASC
                        dataSort
                        width={calculateColumnWidth("8%")}
                    >
                        Proposal #
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        hidden={!columnsSettings["WO #"]}
                        dataField="wo_numbers"
                        dataFormat={woFormatter}
                        defaultASC
                        dataSort
                        width={calculateColumnWidth("7%")}
                    >
                        WO #
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        hidden={!columnsSettings["Invoice #"]}
                        dataField="number"
                        dataFormat={invoiceFormatter}
                        defaultASC
                        dataSort
                        width={calculateColumnWidth("7%")}
                    >
                        Invoice #
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        hidden={!columnsSettings["Status Dates"]}
                        dataField="invoiced_at"
                        dataFormat={_statusDateFormatter}
                        defaultASC
                        dataSort
                        width={calculateColumnWidth("18%")}
                        csvFormat={dateFormatter}
                    >
                        Status Dates
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        hidden={!columnsSettings["Sent"]}
                        dataField="invoice_sent_count"
                        dataSort
                        defaultASC
                        width={calculateColumnWidth("9%")}
                    >
                        Sent
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        hidden={!columnsSettings["Aging"]}
                        dataField="aging"
                        dataSort
                        defaultASC
                        width={calculateColumnWidth("7%")}
                    >
                        Aging
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        hidden={!columnsSettings["Balance"]}
                        dataField="balance"
                        dataFormat={dollarsFormatter}
                        defaultASC
                        dataSort
                        width={calculateColumnWidth("8%")}
                    >
                        Balance
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        hidden={!columnsSettings["Cost"]}
                        dataField="cost"
                        dataFormat={dollarsFormatter}
                        defaultASC
                        dataSort
                        width={calculateColumnWidth("8%")}
                    >
                        Cost
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        hidden={!columnsSettings["Pay"]}
                        dataField="any"
                        dataFormat={payFormatter}
                        width={calculateColumnWidth("7%")}
                    >
                        Pay
                    </TableHeaderColumn>
                </BootstrapTable>
            </Col>
    }</>
}

export default InvoicesTable;