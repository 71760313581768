import axios from "axios/index";
import {apiCall} from "../../common/apiActionHandler";
import {mapForSelect} from "../../common/commonHandlers";
import {dataUpload} from "../../common/upload";

export const getProposalCost = (resource, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/proposal_cost/${resource.id}`,
        params: resource
    };

    apiCall("Getting customers", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const load = (id, callback) => (dispatch, getState) => {
    const config = [
        {url: `/api/search/all_employees`},
        {url: `/api/time_track_types`},
        {url: `/api/new_proposals/search`, params: id ? {id} : {q: ''}}
    ];

    apiCall("Loading data", config, async config => {
        let actions = config.map(c => axios.request(c));
        const all = await axios.all(actions);
        callback({
            employees: all[0].data,
            time_track_types: mapForSelect(all[1].data),
            proposals: mapForSelect(all[2].data)

        });
    }, dispatch, getState, false);
};

export const searchForCustomer = (searchText, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/customers/name_zip_city_search`,
        params: {q: searchText}
    };

    apiCall("Searching for customers", config, async config => {
        const response = await axios.request(config);
        callback(mapForSelect(response.data));
    }, dispatch, getState, false);
};

export const searchForProposal = (searchText, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/new_proposals/search`,
        params: {q: searchText}
    };

    apiCall("Searching for proposals", config, async config => {
        const response = await axios.request(config);
        callback(mapForSelect(response.data));
    }, dispatch, getState, false);
};

export const saveTimeTracks = (time_track, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/time_tracks/${time_track.id || ''}`,
        data: {time_track},
        method: time_track.id ? 'PUT' : 'POST'
    };
    apiCall("Saving time tracks", config, async config => {
        await axios.request(config);
        callback();
    }, dispatch, getState);
};

export const getWorkOrders = (proposal_id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/work_orders/new_search`,
        params: {proposal_id}
    };

    apiCall("Searching for work orders", config, async config => {
        const response = await axios.request(config);
        callback(mapForSelect(response.data));
    }, dispatch, getState, false);
};

export const remove = (id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/time_tracks/${id}`,
        method: 'DELETE'
    };

    apiCall("Deleting time track", config, async config => {
        await axios.request(config);
        callback();
    }, dispatch, getState);
};
export const stopTimeTracks = (time_track_ids, stop_time, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/crew_work_orders/stop_time_tracks`,
        method: 'POST',
        data: {
            time_track_ids: time_track_ids,
            stop_time: stop_time
        }
    };

    apiCall("Stop time", config, async config => {
        const result = await axios.request(config);
        callback && callback(result.data);
    }, dispatch, getState, false);
};

export const saveDownloadedFile = (blob, params, source) => (dispatch, getState) => {
    dataUpload(`/api/download_history`, blob, null, null, {
        params: JSON.stringify(params ? params : {}),
        source: source
    }, false)(dispatch, getState)
}