import React, {useState} from 'react';
import {Button, Modal} from "react-bootstrap";
import moment from "moment-timezone";
import "./AssetWatermarkModal.scss"
import ColorCheckbox from "../../Scheduler/ColorCheckbox";

const OPTIONS = {
    CREATED_AT_DT: 'created_at_dt',
    CREATED_AT_D: 'created_at_d',
    CREATED_AT_T: 'created_at_t',
    TAKEN_AT_DT: 'taken_at_dt',
    TAKEN_AT_D: 'taken_at_d',
    TAKEN_AT_T: 'taken_at_t'
}

const AssetWatermarkModal = ({show, closeModal, createdAt, takenAt, saving, onSave, timezone}) => {

    const [selectedOption, setSelectedOption] = useState("");

    const handleChange = (selected) => {
        if (selectedOption === selected) {
            setSelectedOption("");
        } else {
            setSelectedOption(selected)
        }
    }

    return <Modal
        animation={false}
        show={show}
        onHide={closeModal}
        className={"heightAuto maxHeight90 fontIOS watermark-modal"}
        backdrop="static"
        bsSize="xs"
    >
        <Modal.Header closeButton>
            <Modal.Title>Add watermark to image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="body">
                <div className="watermark-modal-row option-label">
                    <span>Uploaded At Options</span>
                </div>
                <div className="watermark-modal-row">
                    <ColorCheckbox label={moment(createdAt).tz(timezone).format("MM/DD/YYYY hh:mm A")}
                                   value={selectedOption === OPTIONS.CREATED_AT_DT}
                                   onChange={() => handleChange(OPTIONS.CREATED_AT_DT)}
                    />
                </div>
                <div className="watermark-modal-row">
                    <ColorCheckbox label={moment(createdAt).tz(timezone).format("MM/DD/YYYY")}
                                   value={selectedOption === OPTIONS.CREATED_AT_D}
                                   onChange={() => handleChange(OPTIONS.CREATED_AT_D)}
                    />
                </div>
                <div className="watermark-modal-row">
                    <ColorCheckbox label={moment(createdAt).tz(timezone).format("hh:mm A")}
                                   value={selectedOption === OPTIONS.CREATED_AT_T}
                                   onChange={() => handleChange(OPTIONS.CREATED_AT_T)}
                    />
                </div>
                <div className="watermark-modal-row option-label">
                    <span>Taken At Options</span>
                </div>
                <div className="watermark-modal-row">
                    <ColorCheckbox
                        label={takenAt ? moment(takenAt).tz(timezone).format("MM/DD/YYYY hh:mm A") : "No taken at date"}
                        disabled={!takenAt}
                        value={selectedOption === OPTIONS.TAKEN_AT_DT}
                        onChange={() => handleChange(OPTIONS.TAKEN_AT_DT)}
                    />
                </div>
                <div className="watermark-modal-row">
                    <ColorCheckbox
                        label={takenAt ? moment(takenAt).tz(timezone).format("MM/DD/YYYY") : "No taken at date"}
                        disabled={!takenAt}
                        value={selectedOption === OPTIONS.TAKEN_AT_D}
                        onChange={() => handleChange(OPTIONS.TAKEN_AT_D)}
                    />
                </div>
                <div className="watermark-modal-row">
                    <ColorCheckbox label={takenAt ? moment(takenAt).tz(timezone).format("hh:mm A") : "No taken at date"}
                                   disabled={!takenAt}
                                   value={selectedOption === OPTIONS.TAKEN_AT_T}
                                   onChange={() => handleChange(OPTIONS.TAKEN_AT_T)}
                    />
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <div className="footer-buttons">
                <Button type="button" bsSize="small" bsStyle="default" onClick={() => {
                    closeModal();
                    setSelectedOption("");
                }}>Cancel</Button>
                <Button type="button" bsSize="small" bsStyle="success" disabled={selectedOption === "" || saving}
                        onClick={() => {
                            onSave(selectedOption);
                            setSelectedOption("");
                        }}>Save & Close</Button>
            </div>
        </Modal.Footer>
    </Modal>
}

export default AssetWatermarkModal;