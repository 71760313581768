import {apiCall} from "../../../common/apiActionHandler";
import axios from "axios";

export const loadStripeCredentials = (client_id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/admin/stripe_licence_credentials`,
        method: 'GET',
        params: {
            client_id: client_id
        }
    };
    apiCall("Stripe credentials get successfully", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const getClientSubscriptionInfo = (client_id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/admin/get_client_subscription_info`,
        method: 'GET',
        params: {client_id}
    };
    apiCall("Strip subscription info get successfully", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};
export const getSubscription = (subscription_id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/stripe/get_subscription`,
        method: 'GET',
        params: {subscription_id}
    };
    apiCall("Stripe subscription get successfully", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const getProduct = (product_id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/stripe/get_product`,
        method: 'GET',
        params: {product_id}
    };
    apiCall("Subscription product get successfully", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const getActiveUsersCount = (client_id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/admin/count_client_active_users`,
        method: 'GET',
        params: {client_id}
    };
    apiCall("Active users count get successfully", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const changeTrialPermission = (client_id, permission, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/admin/stripe_licence_change_client_trial_permission`,
        method: 'PUT',
        params: {client_id, permission}
    };
    apiCall("Trial permission changed successfully", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};

export const createTrialSubscription = (client_id, callback) => async (dispatch, getState) => {
    const config = {
        method: 'POST',
        url: '/api/admin/stripe_licence_start_client_trial',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {client_id}
    }

    apiCall("Subscription created successfully", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
}

export const changeTrialUsersLimit = (client_id, users_amount, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/admin/stripe_licence_change_trial_users_limit`,
        method: 'POST',
        params: {client_id, users_amount}
    };
    apiCall("Trial users limit changed successfully", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};

export const changePermanentSubscription = (client_id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/admin/change_permanent_subscription`,
        method: 'POST',
        params: {client_id}
    };
    apiCall("Account type changed successfully", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};

export const getInvoices = (client_id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/stripe/get_client_invoices`,
        method: 'GET',
        params: {
            client_id: client_id
        }
    };
    apiCall("Get invoices", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};
export const updateSubscription = (client_id, subscription, auto_renew, callback) => async (dispatch, getState) => {
    const config = {
        method: 'PUT',
        url: '/api/stripe/update_subscription',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {client_id, subscription, auto_renew}
    }

    apiCall("Subscription Updated", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState);
}

export const getClientPrice = (client_price_id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/stripe/get_client_price`,
        method: 'GET',
        params: {
            client_price_id: client_price_id
        }
    };
    apiCall("Get client price", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};