import React, {Profiler} from 'react';
import PropTypes from "prop-types"
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Button, Col, Glyphicon, ListGroup, ListGroupItem, MenuItem, NavDropdown, Panel, Row} from "react-bootstrap";
import ResourceComponent from "../../components/ResourceComponent";
import * as Actions from "./CrewWorkOrdersDetailsApi";
import * as equipmentActions from "../lists/EquipmentChecklistModalApi"
import './CrewWorkOrderDetails.scss';
import Dollars from "../../components/Dollars";
import TextareaAutosize from "react-textarea-autosize";
import InlineEditable from "../Scheduler/InlineEditable";
import AssetEditorModal from "../MapViewPage/new/AssetEditorModal";
import {
    colors,
    convertMillisToTime,
    defaultDateFormat,
    defaultMapParams,
    formatInlineDate,
    select,
    sortAssetsLabels
} from "../../common/commonHandlers";
import Select from "react-select";
import moment from "moment-timezone"
import {LinkContainer} from "react-router-bootstrap";
import {GoogleMapLoader} from "../../components/GoogleMapLoader";
import {getColorIcon} from "../MapViewPage/IconProvider";
import {Link} from "react-router";
import ColorCheckbox from "../Scheduler/ColorCheckbox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {geolocated} from "react-geolocated";
import {debounce} from "throttle-debounce";
import dot from './dot.png'
import ReactDock from "react-dock";
import PlantName from "../../components/PlantName";
import CrewMessages from "../Scheduler/CrewMessages/CrewMessages";
import SiteNotes from "../Scheduler/SiteNotes";
import MDSpinner from "react-md-spinner";
import UpdateJobStatusModal from "./UpdateJobStatusModal";
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import {defineMessages, injectIntl} from "react-intl";
import {buildMessages} from "../../common/CommonMessages";
import {locales} from "../AppWrapper/contents/constants";
import GoogleMapElement from "../MapScheduler/GoogleMapElement";
import AssetMarkers from "./AssetMarkers";
import TotalTime from "./TotalTime";
import ConsumablesConsumptionModal from "../../common/ConsumablesConsumptionModal";
import ChemicalComplianceModal from "./ChemicalComplianceModal";

const isMobile = window.screen.width <= 1024;
const actions = {...Actions, ...equipmentActions}

const fullDaySeconds = 86400

const messages = buildMessages(defineMessages({
    printAll: {
        id: 'CrewWorkOrdersDetails.PrintAll.Link',
        defaultMessage: 'Print All'
    },
    arborist: {
        id: 'CrewWorkOrdersDetails.Arborist.Label',
        defaultMessage: 'Arborist'
    },
    crewLeader: {
        id: 'CrewWorkOrdersDetails.CrewLeader.Label',
        defaultMessage: 'Crew Leader'
    },
    crew: {
        id: 'CrewWorkOrdersDetails.Crew.Label',
        defaultMessage: 'Crew'
    },
    start: {
        id: 'CrewWorkOrdersDetails.Start.Button.Label',
        defaultMessage: 'Start'
    },
    startAll: {
        id: 'CrewWorkOrdersDetails.StartAll.Button.Label',
        defaultMessage: 'Start all'
    },
    stopAll: {
        id: 'CrewWorkOrdersDetails.StopAll.Button.Label',
        defaultMessage: 'Stop all'
    },
    stop: {
        id: 'CrewWorkOrdersDetails.Stop.Button.Label',
        defaultMessage: 'Stop'
    },
    timeTrack: {
        id: 'CrewWorkOrdersDetails.TimeTrack.Button.Label',
        defaultMessage: 'Time Track'
    },
    job: {
        id: 'CrewWorkOrdersDetails.Job.Label',
        defaultMessage: 'Job'
    },
    crewMessages: {
        id: 'CrewWorkOrdersDetails.CrewMessages.Label',
        defaultMessage: 'Crew Messages'
    },
    showAllServices: {
        id: 'CrewWorkOrdersDetails.ShowAllServices.Checkbox.Label',
        defaultMessage: 'Show all services'
    },
    showCompleted: {
        id: 'CrewWorkOrdersDetails.ShowCompleted.Checkbox.Label',
        defaultMessage: 'Show completed'
    },
    resources: {
        id: 'CrewWorkOrdersDetails.Resources.Header',
        defaultMessage: 'Resources'
    },
    actualHours: {
        id: 'CrewWorkOrdersDetails.ActualHours.Label',
        defaultMessage: 'Actual Hrs'
    },
    totalPrice: {
        id: 'CrewWorkOrdersDetails.TotalPrice.Label',
        defaultMessage: 'Total Price'
    },
    trees: {
        id: 'CrewWorkOrdersDetails.Trees.Label',
        defaultMessage: 'trees'
    },
    anotherWO: {
        id: 'CrewWorkOrdersDetails.AnotherWO.Label',
        defaultMessage: 'is in another work order'
    },
    isScheduled: {
        id: 'CrewWorkOrdersDetails.IsScheduled.Label',
        defaultMessage: 'is scheduled'
    },
    inProgress: {
        id: 'CrewWorkOrdersDetails.InProgress.Label',
        defaultMessage: 'In Progress'
    },
    pending: {
        id: 'CrewWorkOrdersDetails.Pending.Label',
        defaultMessage: 'Pending'
    },
    workOrder: {
        id: 'CrewWorkOrdersDetails.WorkOrder.Label',
        defaultMessage: 'Work Order'
    },
    hereYouAre: {
        id: 'CrewWorkOrdersDetails.HereYouAre.Marker.Name',
        defaultMessage: 'Here you are!'
    },
    print: {
        id: 'CrewWorkOrdersDetails.Print.Link',
        defaultMessage: 'Print'
    },
    proposal: {
        id: 'CrewWorkOrdersDetails.Proposal.Label',
        defaultMessage: 'Proposal'
    },
    printProposal: {
        id: 'CrewWorkOrdersDetails.PrintProposal.Link',
        defaultMessage: 'Print Proposal'
    },
    billingAddress: {
        id: 'CrewWorkOrdersDetails.BillingAddress.Link',
        defaultMessage: 'Billing Address'
    },
    notAssigned: {
        id: 'CrewWorkOrdersDetails.NotAssigned.Link',
        defaultMessage: 'Not Assigned'
    },
    type: {
        id: 'CrewWorkOrdersDetails.Type.Label',
        defaultMessage: 'Type'
    },
    date: {
        id: 'CrewWorkOrdersDetails.Date.Label',
        defaultMessage: 'Date'
    },
    totalHours: {
        id: 'CrewWorkOrdersDetails.TotalHours.Label',
        defaultMessage: 'Total Hrs'
    },
    action: {
        id: 'CrewWorkOrdersDetails.Action.Label',
        defaultMessage: 'Action'
    },
    clickStart: {
        id: 'CrewWorkOrdersDetails.ClickStart.Label',
        defaultMessage: 'Click start to start counting'
    },
    edit: {
        id: 'CrewWorkOrdersDetails.Edit.Link',
        defaultMessage: 'Edit'
    },
    cancel: {
        id: 'CrewWorkOrdersDetails.Cancel.Link',
        defaultMessage: 'Cancel'
    },
    newTime: {
        id: 'CrewWorkOrdersDetails.NewTime.Label',
        defaultMessage: 'New time'
    },
    woNotes: {
        id: 'CrewWorkOrdersDetails.WONotes.Placeholder',
        defaultMessage: 'Work order notes'
    },
    empty: {
        id: 'CrewWorkOrdersDetails.Empty.Label',
        defaultMessage: 'Empty'
    },
    crewWorkOrders: {
        id: 'CrewWorkOrdersDetails.CrewWorkOrders.Label',
        defaultMessage: 'Crew work orders'
    },
    serviceNotes: {
        id: 'CrewWorkOrdersDetails.ServiceNotes.Label',
        defaultMessage: 'Service Notes'
    },
}))

class CrewWorkOrdersDetails extends ResourceComponent {
    state = {
        asset: null,
        employees: [],
        time_track_types: [],
        center: true,
        showCompleted: true,
        showCrewNotesModal: false,
        showCrewNotes: false,
        showAllServices: false,
        resource: {},
        expanded: null,
        hazardsNumber: 0,
        showDock: null,
        selectedEventId: null,
        myLocation: false,
        isCrewUser: false,
        isSubcontractor: false,
        isTechnician: false,
        isCrewGroupUser: false,
        showTimeTrack: false,
        editStartId: null,
        editStopId: null,
        showId: null,
        showDetails: false,
        consumableConsumptionModal: {
            show: false,
            proposal_service_id: null,
            consumables: null
        },
        lastChangedWOPSId: null,
        showChemicalCompliance: false,
    };

    colorFormatter = (service) => {
        let status = service.status;
        if (service.removal_service) {
            status = 'Removed';
        }
        switch (status) {
            case 'Pending':
                return 'pending';
            case 'In Progress':
                return 'in progres';
            case 'Completed':
                return 'completed';
            case 'Removed':
                return 'removed';
            default:
                return 'pending';
        }
    };
    renderServiceHeader = service => {
        const {client, roles} = this.props;
        const {formatMessage} = this.props.intl;

        let hazardsNumber = 0;
        service.assets.map(asset => {
            return hazardsNumber += asset.asset_object_hazards_length;
        });

        const disabledStatuses = service.proposal_service_status_name === "Invoiced" || service.proposal_service_status_name === "Payment" || service.proposal_service_status_name === "Paid"
        const serviceValue = roles?.length === 1 && roles[0] === "subcontractor" ? (service.service_total * (client.price_percent_value / 100)) : service.service_total

        return (<div style={{
            margin: "-10px -15px",
            padding: "10px 15px"
        }} className={`pointer ${this.colorFormatter(service)}`}
                     onClick={() => this.handleHeaderClick(service.id)}>
            <Row className='vcenter'>
                <Col xs={1}>
                    <ColorCheckbox value={service.status === 'Completed'}
                                   label=""
                                   disabled={service.complete_candidate === false || disabledStatuses}
                                   onChange={this.handleSetCompleted(service.id)}
                    />
                </Col>
                <Col xs={3} className='text-ellipsis'>
                    {`#${service.order_number_in_proposal} ${this.getServiceText(service, 'name')}`}
                </Col>
                <Col xs={2}>
                    {`${service.assets.length} ${formatMessage(messages.trees)}`}
                </Col>
                <Col xs={1}>
                    {hazardsNumber > 0 &&
                        <Glyphicon glyph="exclamation-sign"/>
                    }
                </Col>
                <Col xs={2}>
                    {`${service.man_hours} Hrs`}
                </Col>
                <Col xs={2}>
                    {service.priorities.length > 0 ? (
                        service.priorities.map(priority => (
                            <div key={priority.id} className="priority">{priority.name}</div>
                        ))
                    ) : (
                        "---"
                    )}
                </Col>
                {service.service_total && <Col xs={2}>
                    <Dollars amount={parseFloat(service.service_total)}/>
                </Col>}
            </Row>
            <Row><Col xs={12}><span className='service-status'>{service.proposal_service_status_name}</span></Col></Row>
            {service.work_order_no && <Row><Col xs={12}>

                <div>{formatMessage(messages.anotherWO)}: #{service.work_order_no}</div>
                {service.scheduler_events && service.scheduler_events.length > 0 &&
                    <div>{formatMessage(messages.isScheduled)} # {service.scheduler_events.map(event =>
                        <Link onClick={e => {
                            e.stopPropagation();
                            this.setState({showDock: true, selectedEventId: event.id})
                        }}>
                            <span>
                                {event.name}
                            </span>
                            <span>({formatInlineDate(event.date_from)})</span>
                        </Link>
                    )}</div>}
            </Col></Row>}
        </div>)
    };

    renderBundleServiceHeader = (proposalService, bundleService) => {
        const isBundleChecked = proposalService.status === 'Completed'

        let hazardsNumber = 0;
        proposalService.assets.map(asset => {
            return hazardsNumber += asset.asset_object_hazards_length;
        });

        return (
            <div className='bundle-service-header'>
                <div style={{display: 'flex'}}>
                    <ColorCheckbox value={isBundleChecked} disabled={true}/>
                    <p>{bundleService.name}</p>
                </div>
                {hazardsNumber > 0 &&
                    <Glyphicon glyph="exclamation-sign"/>
                }
            </div>
        )
    }


    startTimeTracks = (timeTrackIds) => {
        const {resource} = this.state;
        let {time_tracks} = this.state.resource;
        const now = moment();
        const timeString = now.format('HH:mm:ss');
        const from = moment.duration(timeString).asSeconds();
        let {schedulerEventId} = this.props;
        schedulerEventId = schedulerEventId ? schedulerEventId : this.props.params.id;

        this.props.actions.startTimeTracks(timeTrackIds, now, (res) => {
            time_tracks = time_tracks.map(tt => {
                if (timeTrackIds.includes(tt.id)) {
                    tt.time_from = from
                    tt.track_date = tt.track_date || moment(resource.scheduler_event_date_from)
                    return tt
                } else {
                    return tt
                }
            })
            if (this.props.updateJobButton) {
                this.props.updateJobButton(schedulerEventId, true, 'time_track_in_progress')
            }
            this.setState({time_tracks});
        })
    }
    startAllTimeTracks = () => {
        let {time_tracks} = this.state.resource;
        let timeTracksIdsToStart = time_tracks.filter(tt => !tt.time_from).map(tt => tt.id)
        this.startTimeTracks(timeTracksIdsToStart);
    }

    stopTimeTracks = (timeTrackIds) => {
        console.log('stop timer')
        let {time_tracks} = this.state.resource;
        const now = moment();
        const timeString = now.format('HH:mm:ss');
        const to = moment.duration(timeString).asSeconds();
        this.props.actions.stopTimeTracks(timeTrackIds, now, (res) => {
            time_tracks = time_tracks.map(tt => {
                if (timeTrackIds.includes(tt.id)) {
                    tt.time_to = to
                    const diff = to - tt.time_from;
                    tt.total_seconds = moment.duration(diff * 1000).asSeconds();
                    return tt
                } else {
                    return tt
                }
            })
            this.setState({time_tracks});
            let {schedulerEventId} = this.props;
            schedulerEventId = schedulerEventId ? schedulerEventId : this.props.params.id;
            if (this.props.updateJobButton) {
                this.props.updateJobButton(schedulerEventId, time_tracks.some(tt => tt.time_from && !tt.time_to), 'time_track_in_progress')
            }
            this.reload(schedulerEventId)
        })
    }
    stopAllTimeTracks = () => {
        let {time_tracks} = this.state.resource;
        let timeTracksIdsToStop = time_tracks.filter(tt => tt.time_from && !tt.time_to).map(tt => tt.id)
        this.stopTimeTracks(timeTracksIdsToStop);
    }
    updateJobStatus = () => {
        let {time_tracks} = this.state.resource;
        let {schedulerEventId} = this.props;
        schedulerEventId = schedulerEventId ? schedulerEventId : this.props.params.id;
        const now = moment();
        const timeString = now.format('HH:mm:ss');
        const to = moment.duration(timeString).asSeconds();
        let timeTracksIdsToStop = time_tracks.filter(tt => tt.time_from && !tt.time_to).map(tt => tt.id)
        this.props.actions.updateJobStatus(schedulerEventId, now, (res) => {
            time_tracks = time_tracks.map(tt => {
                if (timeTracksIdsToStop.includes(tt.id)) {
                    tt.time_to = to
                    const diff = to - tt.time_from;
                    tt.total_seconds = moment.duration(diff * 1000).asSeconds();
                    return tt
                } else {
                    return tt
                }
            })
            this.setState({time_tracks});
            if (this.props.updateJobButton) {
                this.props.updateJobButton(schedulerEventId, false, 'time_track_in_progress')
            }
            this.reload(schedulerEventId)
        })
    }

    addTimeTrack = (id) => {
        const {resource, time_track_types} = this.state;
        let {time_tracks} = this.state.resource;
        const type = time_track_types[0];
        if (!time_tracks.some(tt => tt.person_id === id && !tt.time_to)) {
            this.props.actions.addTimeTrack(resource.id, type.value, id || null, (res) => {
                if (res) {
                    time_tracks.push({
                        id: res.id,
                        person_id: id ? id : null,
                        track_date: null,
                        time_from: null,
                        time_to: null,
                        total_seconds: 0,
                        time_track_type_id: type.value
                    });
                    this.setState({time_tracks})
                }
            })
        }
    };
    removeTimeTrack = (id) => {
        let {resource} = this.state;
        this.props.actions.removeTimeTrack(id, (res) => {
            resource.time_tracks = resource.time_tracks.filter(tt => tt.id !== id)
            this.setState({resource});
        })
    }

    handleSetCompleted = (id) => (value, e) => {
        e.stopPropagation();
        const choice = window.confirm('Are you sure?');
        let {work_order_proposal_services} = this.state.resource;
        const {client} = this.props
        let service = work_order_proposal_services.find(service => service.id === id || service.proposal_service_id === id);
        if (choice) {
            if (!service) {
                service = {proposal_service_id: id};
                work_order_proposal_services.push(service);
            }
            if (service.status === 'Completed') {
                service.status = this.defaultStatus()
                if (service.proposal_service_id) {
                    this.props.actions.deleteConsumption(service.proposal_service_id)
                }
            } else {
                if (service.proposal_service_id) {
                    if (service.isPHC) {
                        this.props.actions.createConsumption(service.proposal_service_id, () => this.setState({showChemicalCompliance: true}))
                    } else {
                        this.props.actions.loadConsumables(service.proposal_service_id, (res) => {
                            if (res.consumables && res.consumables.length > 0) {
                                this.setState({
                                    consumableConsumptionModal: {
                                        show: true,
                                        proposal_service_id: service.proposal_service_id,
                                        consumables: res.consumables,
                                        service_name: "#" + service.service_number + " " + service.name
                                    }
                                })
                            }
                        })
                    }
                }
                service.status = 'Completed';
            }
            const lastChangedWOPSId = id
            work_order_proposal_services = work_order_proposal_services.sort((a, b) => a.service_number - b.service_number)

            if (service.status === 'Completed' && service.has_equipment_questions && client.create_equipment_checklist) {
                this.delayedShowModal()
            }
            this.setState({work_order_proposal_services, lastChangedWOPSId}, this.delayedSave);
        }
    };

    handleShowCompleted = () => {
        const {showCompleted} = this.state;
        let {services} = this.state;
        const {work_order_proposal_services} = this.state.resource;
        this.setState({showCompleted: !showCompleted}, () => {
            if (showCompleted) {
                services = services.filter(service => service.proposal_service_status_name !== 'Completed').sort((a, b) => a.service_number - b.service_number);
                this.setState({services: services});
            } else {
                this.setState({services: work_order_proposal_services.sort((a, b) => a.service_number - b.service_number)});
            }
        })
    };

    handleShowAllServices = () => {
        const {showAllServices, resource} = this.state;
        const {services} = this.state;
        this.setState({showAllServices: !showAllServices}, () => {
            let newServices;
            if (!showAllServices) {
                newServices = resource.proposal_services.map(ps => services.find(s => s.proposal_service_id === ps.id) || ps);
            } else {
                newServices = services.filter(service => service.status);
            }
            this.setState({services: newServices.sort((a, b) => a.service_number - b.service_number), loaded: false});
        });
    };

    handleScrollToMap = (i, idx) => (e) => {
        e.stopPropagation();
        const map = document.getElementById('woDetailsMap');
        map.scrollIntoView({behavior: "smooth"});
        this.handleSelectMarker(i, idx);
    };

    handleSelectMarker = (i, idx) => {
        let {services} = this.state;
        services.map(service => {
            return service.assets.forEach(asset => asset.selected = false);
        });
        services[i].assets[idx].selected = true;
        this.setState(services, () => {
            this.selectMarkerNoScroll('services')(this.state.services[i])
        })
    };

    handleRemoveTrack = (id) => e => {
        const choice = window.confirm('Are you sure?');
        if (choice) {
            e.stopPropagation();
            let {resource} = this.state;
            this.props.actions.removeTimeTrack(id, (res) => {
                resource.time_tracks = resource.time_tracks.filter(tt => tt.id !== id)
                this.setState({resource});
            })
        }
    };

    handleHeaderClick = (id) => {
        const {expanded, services} = this.state;
        let {hazardsNumber} = this.state;
        if (expanded === id) {
            this.setState({expanded: null}, () => {
                hazardsNumber = 0;
                this.setState({hazardsNumber});
            });
        } else {
            this.setState({expanded: id}, () => {
                services.filter(service => service.id === id)[0].assets.map(asset => {
                    hazardsNumber += asset.asset_object_hazards_length;
                    this.setState({hazardsNumber});
                });

            })
        }
    };

    formatPeriod = (date) => {
        return <span>{isMobile ? <h6>{moment(date).format('dddd')}, {moment(date).format('MMMM D')}</h6> :
            <h5>{moment(date).format('dddd')}, {moment(date).format('MMMM D')}</h5>}</span>
    };

    defaultStatus = () => {
        const {time_tracks} = this.state.resource;
        const {formatMessage} = this.props.intl;
        return time_tracks.filter(track => track.time_to).length > 0 ? formatMessage(messages.inProgress) : formatMessage(messages.pending);
    };

    saveCrewWorkOrder = () => {
        const {resource, schedulerEventId, lastChangedWOPSId} = this.state;
        let {work_order_proposal_services, time_tracks} = this.state.resource;
        work_order_proposal_services = work_order_proposal_services.map(service => {
            let status = null;
            if (service.status === 'Completed') {
                status = 'Completed';
            } else {
                status = this.defaultStatus();
            }
            return {
                status: status,
                id: service.id,
                proposal_service: {
                    id: service.proposal_service_id,
                    wo_notes: service.wo_notes
                },
            }
        });
        this.props.actions.saveCrewWorkOrder({
            id: resource.id,
            scheduler_event_id: schedulerEventId,
            crew_notes: resource.crew_notes,
            work_order_proposal_services,
            time_tracks,
            lastChangedWOPSId
        }, result => {
            const newWorkOrder = {...resource, ...result};
            this.setState({
                showAllServices: false,
                resource: newWorkOrder,
                services: newWorkOrder.work_order_proposal_services.sort((a, b) => a.service_number - b.service_number)
            });
            if (this.props.updateJobButton) {
                this.props.updateJobButton(schedulerEventId, newWorkOrder.work_order_proposal_services.every(s => s.proposal_service_status_name !== 'Scheduled'), 'all_services_completed')
            }
        })
    };

    reload = (schedulerEventId) => {
        this.props.actions.load(schedulerEventId, result => this.setState(result, () => {
            result.resource.time_tracks.forEach(track => {
                let timeZoneDifference;
                timeZoneDifference = this.getTimeZone(track.track_date)
                track.time_from = track.time_from ? (track.time_from - timeZoneDifference) : track.time_from
                track.time_to = track.time_to ? (track.time_to - timeZoneDifference) : track.time_to
            });

            this.props.actions.getCrew([schedulerEventId], res => {
                const guests = res.crew || [];
                guests.map(guest => {
                    if (!result.resource.time_tracks.some(tt => tt.person_id === guest.id && !tt.time_to)) {
                        this.addTimeTrack(guest.id);
                    }
                })
                let filteredTimeTracks = result.resource.time_tracks.filter(timeTrack => {
                    return !guests.some(guest => guest.id === timeTrack.person_id) && !timeTrack.time_from;
                });

                filteredTimeTracks.forEach(tt => {
                    this.removeTimeTrack(tt.id)
                })
            })


            this.setState({
                schedulerEventId,
                showAllServices: false,
                result,
                loaded: false,
                services: this.state.resource.work_order_proposal_services.sort((a, b) => parseFloat(a.service_number) - parseFloat(b.service_number))
            });
        }));
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.reloadData !== this.props.reloadData) {
            let {schedulerEventId} = this.props;
            schedulerEventId = schedulerEventId ? schedulerEventId : this.props.params.id;
            this.reload(schedulerEventId)
        }
        if (prevState.showId !== this.state.showId) {
            this.setState({showDetails: true})
        }
    }

    componentDidMount() {
        let {schedulerEventId, roles, client} = this.props;
        schedulerEventId = schedulerEventId ? schedulerEventId : this.props.params.id;
        if (client?.hidden_prices && roles?.length > 0) {
            switch (roles[0]) {
                case 'crew':
                    this.setState({isCrewGroupUser: true, isCrewUser: true})
                    break;
                case 'subcontractor':
                    this.setState({isCrewGroupUser: true, isSubcontractor: true})
                    break;
                case 'technician':
                    this.setState({isCrewGroupUser: true, isCrewUser: true, isTechnician: true})
                    break;
            }
        }
        this.reload(schedulerEventId);
    };


    componentWillReceiveProps(nextProps) {
        const {coords, schedulerEventId} = this.props;
        if (nextProps.schedulerEventId !== schedulerEventId) {
            this.reload(nextProps.schedulerEventId);
        }
        if (!coords && nextProps.coords) {
            this.setState({loaded: false});
        }
    }

    constructor(props, context) {
        super(props, context);
        this.delayedSave = debounce(1000, this.saveCrewWorkOrder);
        this.delayedShowModal = debounce(1000, () => this.props.actions.fetchChecklistModal(true));
    }

    totalTimeFormatter = (seconds) => {
        let total
        total = convertMillisToTime(seconds * 1000)

        return total;
    }

    getZIndex = (asset, assets) => {
        if (asset.selected === true || asset.__locked === true) {
            //we injected this property to modify state on the next rerender - its slow. maybe we shoudl instead use setState?
            return assets.length + 10
        } else {
            return assets.findIndex(a => a.id === asset.id) + 1
        }

    }


    getTimeZone = (date) => {
        date = new Date(moment(date).format(defaultDateFormat))
        return date.getTimezoneOffset() * 60
    }

    renderWOHyperLink = () => {
        const {resource, isCrewGroupUser} = this.state;
        const {roles, client} = this.props
        const {formatMessage} = this.props.intl;

        const printWOUrl = `/print_work_orders/${resource.work_order_no}`
        const woUrl = `/work_orders/${resource.proposal_id}`

        if (roles && !(roles.length === 1 && (roles[0] === 'crew' || roles[0] === 'subcontractor') || roles[0] === 'technician')) {
            return <Link to={woUrl} target="_blank">{formatMessage(messages.workOrder)} #{resource.work_order_no}</Link>
        } else if (!isCrewGroupUser || (roles?.length === 1 && roles[0] === 'subcontractor' && client.display_subcontractor_price)) {
            return <Link to={printWOUrl}
                         target="_blank">{formatMessage(messages.workOrder)} #{resource.work_order_no}</Link>
        } else if (isCrewGroupUser) {
            return null
        }
    }

    shouldShowTranslation = () => this.props.locale === locales.ES

    getServiceText = (service, key) => {
        const hasTranslation = !!service.translation?.[key];
        return (this.shouldShowTranslation() && hasTranslation) ? service.translation[key] : service[key];
    }

    renderMarkers = (assets) => {
        const props = {
            assets,
            services: this.state.services,
            proposal_services: this.state.resource.proposal_services
        }
        return <AssetMarkers {...props} />
    }

    render() {
        const {email, showHeader, client, roles, hazard} = this.props;
        const {formatMessage} = this.props.intl;
        const {
            asset,
            employees,
            showCompleted,
            resource,
            services,
            time_track_types,
            isCrewUser,
            showAllServices,
            expanded,
            hazardsNumber,
            showDock,
            selectedEventId,
            myLocation,
            isSubcontractor,
            isCrewGroupUser,
            schedulerEventId,
            consumableConsumptionModal,
            showChemicalCompliance
        } = this.state;

        let assets = [];
        services && services.map(service => {
            service.assets.sort((a, b) => a.label - b.label).forEach(asset => {
                if (asset.disabled !== true) {
                    assets.push(asset);
                }
            });
        });
        const {isGeolocationAvailable, isGeolocationEnabled, coords} = this.props;
        if (isGeolocationAvailable && isGeolocationEnabled && coords) {
            let customMarker = {
                latitude: coords.latitude,
                longitude: coords.longitude,
                name: formatMessage(messages.hereYouAre),
                coords: true
            };
            assets = assets.filter(a => !a.coords);
            myLocation && assets.push(customMarker);
        }
        assets.forEach(asset => {
            if (asset.selected) {
                asset.icon = getColorIcon('#f8e500');
                asset.label = asset.asset_label.toString();
            } else if (asset.coords) {
                asset.icon = dot;
                asset.label = ""
            } else {
                asset.label = asset.asset_label.toString();
                asset.icon = getColorIcon(colors[asset.color]);
            }
        });
        let actual_seconds = resource.time_tracks && resource.time_tracks.length
            && resource.time_tracks.map(item => item.total_seconds).reduce((prev, next) => prev + next);

        let coordinates = [];
        assets.forEach(asset => {
            if (asset.polygons && asset.polygons.length > 0) {
                const polygon = asset.polygons.find(p => p.chosen)
                for (let p of polygon.polygon_points) {
                    coordinates.push({latitude: p.latitude, longitude: p.longitude});
                }
            } else if (asset.polygons && asset.polygons.length === 0) {
                asset.lat = asset.lat
                asset.lng = asset.lng
                coordinates.push({latitude: asset.lat, longitude: asset.lng});
            }
        })

        assets && assets.sort((a, b) => b.lat - a.lat);


        let hasCrewRole = false

        if (roles && roles.every(r => r === 'crew' || r === 'subcontractor' || r === 'technician')) {
            hasCrewRole = true
        }

        let eventPersonIds = []
        let guestsPersonIds = []
        let personIds = []

        if (resource) {
            const {arborist_person_id, created_by_person_id, person_id, guests} = resource
            if (guests && guests.length > 0) {
                guestsPersonIds = guests.map(g => g.id)
            }
            eventPersonIds = [arborist_person_id, created_by_person_id, person_id]
            personIds = eventPersonIds.concat(guestsPersonIds).filter((p, i, arr) => arr.indexOf(p) === i)
        }

        const subcontractor = roles?.length === 1 && roles[0] === 'subcontractor'


        let recipientEmail
        const {
            contact_email,
            contact_email2,
            secondContactEmail,
            secondContactEmail2,
            arborist_email,
            additionalContactEmails,
            additionalContactEmails2
        } = resource
        const primarySiteContactEmails = (contact_email && contact_email2) ? `${contact_email}, ${contact_email2}` : contact_email
        const secondarySiteContactEmails = (secondContactEmail && secondContactEmail2) ? `${secondContactEmail}, ${secondContactEmail2}` : secondContactEmail
        recipientEmail = `${primarySiteContactEmails || ""}`

        if (secondarySiteContactEmails) recipientEmail += `, ${secondarySiteContactEmails}`
        if (additionalContactEmails?.length > 0) recipientEmail += `, ${additionalContactEmails.toString()}`
        if (additionalContactEmails2?.length > 0) recipientEmail += `, ${additionalContactEmails2.toString()}`

        const getPlantName = plant => {
            return this.shouldShowTranslation() && !!plant.translated_name ? plant.translated_name : plant.name
        }

        return <Row id='crew-work-orders-details'>
            {this.state.showJobStatusModal && <UpdateJobStatusModal
                show={this.state.showJobStatusModal}
                onHide={() => this.setState({showJobStatusModal: false})}
                stopAllTimeTracks={() => this.updateJobStatus()}
                services={services}
            />}
            {consumableConsumptionModal.show && <ConsumablesConsumptionModal
                consumables={consumableConsumptionModal.consumables}
                sendConsumablesConsumption={this.props.actions.sendConsumablesConsumption}
                proposal_service_id={consumableConsumptionModal.proposal_service_id}
                showModal={consumableConsumptionModal.show}
                proposal_service_name={consumableConsumptionModal.service_name}
                closeModal={() => {
                    this.setState({
                        consumableConsumptionModal: {
                            show: false,
                            proposal_service_id: null
                        }
                    })
                }}
            />}
            <Col xs={12}>
                {!isMobile || !showHeader ? <Row className='mobile-margin'>
                    <Col xs={12} className='top-menu text-right justify-flex-end dropdown'>
                        <li>
                            <Link onClick={() => this.props.actions.showJSADialog({siteId: resource.site_id})}>
                            <span>{
                                resource.site_hazards && ((hazardsNumber + resource.site_hazards.length) > 0) &&
                                <Glyphicon glyph="exclamation-sign" className='small-margin'/>
                            }
                                JSA
                            </span>
                            </Link>
                        </li>
                        {((subcontractor && client.display_subcontractor_price) || (!subcontractor && !isCrewGroupUser)) &&
                            <LinkContainer to={`/print_work_orders/${resource.work_order_no}`} target="_blank">
                                <MenuItem>{formatMessage(messages.print)}</MenuItem>
                            </LinkContainer>
                        }
                        {((subcontractor && client.display_subcontractor_price) || (!subcontractor && !isCrewGroupUser)) &&
                            <li>
                                <Link to={this.props.workOrdersPrintAll ? {
                                    pathname: '/print_work_orders/all',
                                    query: {
                                        scheduler_event_date_from: JSON.stringify(this.props.filters.scheduler_event_date_from),
                                        person_id: this.props.filters.person_id
                                    }
                                } : ''}
                                      target="_blank"
                                      className={`${this.props.workOrdersPrintAll ? '' : 'disabled'} pointer`}
                                >
                                    {formatMessage(messages.printAll)}
                                </Link>
                            </li>}
                    </Col>
                </Row> : <div><Row className="vcenter">
                    <Col xs={2} className="hstart">
                        <Link
                            to={`/crew_work_orders?scheduler_event_date_from="${resource.scheduler_event_date_from}"`}>
                            <Glyphicon glyph="chevron-left"/>
                        </Link>
                    </Col>
                    <Col xs={10} className='top-menu text-right justify-flex-end dropdown'>
                        <li>
                            <Link onClick={() => this.props.actions.showJSADialog({siteId: resource.site_id})}>
                            <span>{resource.site_hazards && ((hazardsNumber + resource.site_hazards.length) > 0) &&
                                <Glyphicon glyph="exclamation-sign" className='small-margin'/>}JSA</span>
                            </Link>
                        </li>
                        {((subcontractor && client.display_subcontractor_price) || (!subcontractor && !isCrewGroupUser)) &&
                            <LinkContainer to={`/print_work_orders/${resource.work_order_no}`}>
                                <MenuItem>{formatMessage(messages.print)}</MenuItem>
                            </LinkContainer>}
                    </Col>
                </Row>
                    <Row>
                        <Panel
                            expanded={false}
                        />
                    </Row>
                </div>}
                <Row className={isMobile && (showHeader ? 'no-margin' : 'no-header-margin')}>
                    <Col xs={9}>
                        <div>
                            {(roles?.length === 1 && roles[0] === 'subcontractor' && !client.display_subcontractor_price) ? null : this.renderWOHyperLink()}
                        </div>
                    </Col>
                    <Col xs={3}>
                        {this.props.roles && !(this.props.roles.every(r => r === 'crew' || r === 'subcontractor' || r === 'technician')) &&
                            <NavDropdown
                                title={<div
                                    className='text-blue flex-column-end bottom10'>{`${formatMessage(messages.proposal)} #${resource.proposal_no}`}</div>}
                                noCaret
                            >
                                <LinkContainer
                                    to={`/mapview/${resource.proposal_id || ''}?lead_id=${resource.lead_id}&customer_id=${resource.customer_id}&site_id=${resource.site_id}`}
                                    target="_blank">
                                    <MenuItem bsSize="small"
                                              eventKey={2.6}>{formatMessage(messages.proposal)}</MenuItem>
                                </LinkContainer>
                                <LinkContainer to={`/print_proposal/${resource.proposal_id}`} target="_blank">
                                    <MenuItem bsSize="small">{formatMessage(messages.printProposal)}</MenuItem>
                                </LinkContainer>
                                <li className='pointer'><Link onClick={() => this.props.actions.showEmailDialog({
                                    emailType: "proposal",
                                    referenceId: resource.proposal_id,
                                    recipient: recipientEmail,
                                    defaultEmail: email
                                })}
                                                              className={resource.proposal_id ? '' : 'disabled'}>Email</Link>
                                </li>
                            </NavDropdown>}
                    </Col>
                </Row>
                <Row className={isMobile ? 'no-margin bottom10' : 'bottom10'}>
                    <Col xs={6}>
                        <div>
                            <span>{resource.customer_name}</span>
                            {this.props.roles && this.props.roles.includes('technician') &&
                                <span>
                               <Glyphicon
                                   glyph="envelope"
                                   className='pointer colorRed ml-7'
                                   onClick={
                                       () => this.props.actions.showEmailDialog({
                                           emailType: "scheduler_event",
                                           referenceId: schedulerEventId,
                                           recipient: recipientEmail,
                                           defaultEmail: email,
                                           cc_recipients: [{email_address: client.arborist_as_technician_cc ? resource.arborist_email : ''}],
                                           client: client,
                                           technicianOnly: true
                                       })}
                               />
                            </span>}
                        </div>
                        <div>{resource.site_name ? resource.site_name : formatMessage(messages.billingAddress)}</div>
                        {resource.site_primary_phone_number &&
                            <a href={`tel:${resource.phone_number}`}>{resource.site_primary_phone_number}</a>}
                        <div>{formatMessage(messages.job)}:</div>
                        <Link target={'_blank'}
                              to={`https://maps.apple.com/maps?daddr=${resource.latitude},${resource.longitude}`}>{resource.site_address}</Link>
                    </Col>
                    <Col xs={6} className={isMobile && !showHeader ? 'text-right margin-left-15' : 'text-right'}>
                        <div>{formatMessage(messages.arborist)}:</div>
                        <div className='bottom10'>{resource.employee_name}</div>
                        <div>{formatMessage(messages.crewLeader)}:</div>
                        <div className='bottom10'>{resource.person_full_name}</div>
                        <div>{formatMessage(messages.crew)}:</div>
                        <div className='bottom10'>{resource.crew}</div>
                    </Col>
                </Row>
                <Row className={isMobile ? 'no-margin bottom10' : 'bottom10'}>
                    <Col md={12}>
                        <SiteNotes
                            resource={resource}/>
                    </Col>
                </Row>
                <Row className='work-order-details bottom10'>
                    <Col xs={3}>
                        <span>Est Hrs</span>
                        <span>{convertMillisToTime(resource.service_hours * 60 * 60 * 1000)}</span>
                    </Col>
                    {resource.service_price && <Col xs={3}>
                        <span>{formatMessage(messages.totalPrice)}</span>
                        <Dollars amount={parseFloat(resource.service_price)}/>
                    </Col>}
                    <Col xs={3}>
                        <span>{formatMessage(messages.actualHours)}</span>
                        <span>{convertMillisToTime(actual_seconds * 1000)}</span>
                    </Col>
                    {resource.service_price && <Col xs={3}>
                        <span>PMH</span>
                        <Dollars
                            amount={actual_seconds > 0 ? parseFloat(resource.service_price / (actual_seconds / (60 * 60))) : 0}/>
                    </Col>}
                </Row>
                <Row className='no-margin mb-35'>
                    <Col xs={4} className='no-padding'>
                        <Button
                            className={`full-width btn ${resource.time_tracks?.some(tt => !tt.time_from) ? 'btn-success' : 'btn-danger'}`}
                            disabled={!resource.time_tracks?.some(tt => !tt.time_from || !tt.time_to)}
                            onClick={() => resource.time_tracks?.some(tt => !tt.time_from) ? this.startAllTimeTracks() : this.stopAllTimeTracks()}>{resource.time_tracks?.some(tt => !tt.time_from) ? formatMessage(messages.startAll) : formatMessage(messages.stopAll)}
                        </Button>
                    </Col>
                    <Col xs={4}>
                        <Button className='btn btn-success full-width'
                                onClick={() => this.setState({showTimeTrack: !this.state.showTimeTrack})}>{formatMessage(messages.timeTrack)}</Button>
                    </Col>
                    <Col xs={4} className='no-padding'>
                        <Button className='btn btn-danger full-width'
                                onClick={() => this.setState({showJobStatusModal: true})}
                        >
                            {services && services.every(s => s.proposal_service_status_name !== 'Scheduled') ? formatMessage(messages.jobCompleted) : formatMessage(messages.updateJobStatus)}
                        </Button>
                    </Col>
                </Row>
                {this.state.showTimeTrack && <Row>
                    <Col xs={12}>
                        <Panel
                            className="time-panel pointer"
                            header={<span>{formatMessage(messages.timeTrack)}</span>}
                        >
                            <ListGroup>
                                <ListGroupItem>
                                    <Row>
                                        <Col sm={6} xs={6}>
                                            {formatMessage(messages.employee)}
                                        </Col>
                                        <Col sm={3} xs={3}>
                                            {formatMessage(messages.action)}
                                        </Col>
                                        <Col sm={3} xs={3}>
                                            {formatMessage(messages.totalHours)}
                                        </Col>
                                    </Row>
                                </ListGroupItem>
                                {

                                    resource.time_tracks && resource.time_tracks.length > 0 && Object.values(resource.time_tracks.filter(x => !x._destroy).reduce((result, time_track) => {
                                        const personId = time_track.person_id;
                                        if (!result[personId]) {
                                            result[personId] = {
                                                person_id: personId,
                                                time_tracks: []
                                            }
                                        }
                                        result[personId].time_tracks.push(time_track);
                                        return result;
                                    }, {})).map((data, j) => {
                                        const usersWithNotCompletedTimeTracks = resource.time_tracks.filter(tt => (!tt.time_from || !tt.time_to)).map(tt => tt.person_id)
                                        const employeeOptions = employees.filter(x => x.label !== 'Not assigned' && !usersWithNotCompletedTimeTracks.includes(x.value));
                                        const employee = employees.find(e => e.value === data.person_id);
                                        return <ListGroupItem key={j}>
                                            <Row className='vcenter' onClick={() => this.setState({
                                                showId: j,
                                                showDetails: !this.state.showDetails
                                            })}>
                                                <Col md={6} xs={6}>
                                                    <InlineEditable
                                                        withSubmit={false}
                                                        editable={false}
                                                        value={
                                                            <h4>{employee ?
                                                                <div>{employee.label}
                                                                </div>
                                                                : formatMessage(messages.notAssigned)}
                                                            </h4>
                                                        }
                                                    />
                                                </Col>
                                                <Col md={3} xs={3}>
                                                    {data.time_tracks.every(tt => tt.time_from) ?
                                                        data.time_tracks.some(tt => !tt.time_to) &&
                                                        <Button className='btn btn-danger'
                                                                onClick={(e) => {
                                                                    e.stopPropagation()
                                                                    this.stopTimeTracks([data.time_tracks.find(tt => !tt.time_to).id])
                                                                }}>
                                                            {formatMessage(messages.stop)}
                                                        </Button>
                                                        :
                                                        <Button className='btn btn-success'
                                                                onClick={(e) => {
                                                                    e.stopPropagation()
                                                                    this.startTimeTracks([data.time_tracks.find(tt => !tt.time_from).id])
                                                                }}>
                                                            {formatMessage(messages.start)}
                                                        </Button>}
                                                </Col>
                                                <Col md={3} xs={3}>
                                                    <TotalTime
                                                        timeFn={(timeCounter) => data.time_tracks.reduce((sum, item) => sum + item.total_seconds, 0) + (timeCounter - data.time_tracks.find(tt => tt.time_from && !tt.time_to)?.time_from || 0)}/>
                                                </Col>
                                            </Row>
                                            {this.state.showId === j && this.state.showDetails &&
                                                <ListGroup className='mt10'>
                                                    <ListGroupItem>
                                                        <Row>
                                                            {!employee && <Col sm={2} xsHidden>
                                                                {formatMessage(messages.employee)}
                                                            </Col>}
                                                            <Col sm={2} xsHidden>
                                                                {formatMessage(messages.type)}
                                                            </Col>
                                                            <Col sm={employee ? 3 : 2} xsHidden>
                                                                {formatMessage(messages.date)}
                                                            </Col>
                                                            <Col sm={2} xsHidden>
                                                                {formatMessage(messages.start)}
                                                            </Col>
                                                            <Col sm={2} xsHidden>
                                                                {formatMessage(messages.stop)}
                                                            </Col>
                                                            <Col sm={2} xsHidden>
                                                                {formatMessage(messages.totalHours)}
                                                            </Col>
                                                        </Row>
                                                    </ListGroupItem>
                                                    {data.time_tracks.map((time, i) => {
                                                        const employee = employees.find(e => e.value === time.person_id);
                                                        return <ListGroupItem>
                                                            <Row className='vcenter' key={i}>
                                                                {!employee && <Col md={2} xs={12}>
                                                                    <InlineEditable
                                                                        withSubmit={false}
                                                                        editable={!employee}
                                                                        value={
                                                                            <h4>{employee ?
                                                                                <div>{employee.label}
                                                                                </div>
                                                                                : formatMessage(messages.notAssigned)}
                                                                            </h4>
                                                                        }
                                                                        form={(done) =>
                                                                            <Select className="Select"
                                                                                    classNamePrefix="select"
                                                                                    options={employeeOptions}
                                                                                    isClearable
                                                                                    autoFocus menuIsOpen
                                                                                    value={select(employeeOptions, time.person_id)}
                                                                                    onMenuClose={() => done()}
                                                                                    onChange={e => {
                                                                                        let {time_tracks} = this.state.resource
                                                                                        if (e) {
                                                                                            time.person_id = e && e.value;
                                                                                            this.setState({time_tracks}, () => {
                                                                                                done();
                                                                                                this.delayedSave();
                                                                                            })
                                                                                        }
                                                                                    }
                                                                                    }
                                                                            />
                                                                        }
                                                                    />
                                                                </Col>}
                                                                <Col md={2} xs={12}>
                                                                    <InlineEditable
                                                                        withSubmit={false}
                                                                        value={
                                                                            <h5>{time.time_track_type_id ? select(time_track_types, time.time_track_type_id).label : formatMessage(messages.notAssigned)}</h5>
                                                                        }
                                                                        form={(done) =>
                                                                            <Select className="Select"
                                                                                    classNamePrefix="select"
                                                                                    options={time_track_types}
                                                                                    value={select(time_track_types, time.time_track_type_id)}
                                                                                    onMenuClose={() => done()}
                                                                                    autoFocus
                                                                                    menuIsOpen
                                                                                    placeholder={formatMessage(messages.type)}
                                                                                    onChange={e => {
                                                                                        time.time_track_type_id = e !== null && e.value;
                                                                                        this.setState({resource}, () => {
                                                                                            done();
                                                                                            this.delayedSave();
                                                                                        })
                                                                                    }}/>
                                                                        }
                                                                    />
                                                                </Col>
                                                                <Col md={employee ? 3 : 2} xs={12}>
                                                                    <h6>{time.track_date && this.formatPeriod(time.track_date)}</h6>
                                                                </Col>
                                                                <Col md={2} xs={12}>
                                                                    {(time.time_from && time.time_to) ? <>
                                                                        <TimePicker
                                                                            showSecond={false}
                                                                            value={moment.utc(time.time_from * 1000)}
                                                                            onChange={e => {
                                                                                if (moment.duration(e.format('HH:mm:ss')).asSeconds() < time.time_to) {
                                                                                    time.time_from = moment.duration(e.format('HH:mm:ss')).asSeconds() % fullDaySeconds
                                                                                    this.setState({
                                                                                        invalidDate: false,
                                                                                        changesMade: true
                                                                                    })
                                                                                } else {
                                                                                    this.setState({
                                                                                        invalidDate: true,
                                                                                        changesMade: false
                                                                                    })
                                                                                }
                                                                            }}
                                                                            onClose={() => {
                                                                                if (!this.state.invalidDate && this.state.changesMade) {
                                                                                    const diff = time.time_to - time.time_from;
                                                                                    time.total_seconds = moment.duration(diff * 1000).asSeconds()
                                                                                    this.setState({editStartId: null})
                                                                                    this.setState(resource, this.delayedSave)
                                                                                } else {
                                                                                    this.setState({editStartId: null})
                                                                                }
                                                                            }
                                                                            }
                                                                            format={'h:mm:ss a'}
                                                                            use12Hours
                                                                            disabled={this.state.editStartId !== time.id}
                                                                            allowEmpty={false}
                                                                            disabledHours={() => this.getDisabledHours(time.time_from, time.time_to, 'higher')}
                                                                            disabledMinutes={() => this.getDisabledMinutes(time.time_from, time.time_to, 'higher')}
                                                                        />
                                                                        <Link
                                                                            onClick={() => (time.id === this.state.editStartId) ? this.setState({editStartId: null}) : this.setState({editStartId: time.id})}>{time.id === this.state.editStartId ? formatMessage(messages.cancel) : formatMessage(messages.edit)}</Link>
                                                                    </> : time.time_from ?
                                                                        <div>{formatMessage(messages.inProgress)}</div> :
                                                                        <div>{formatMessage(messages.clickStart)}</div>}
                                                                </Col>
                                                                <Col md={2} xs={12}>
                                                                    {time.time_to && <>
                                                                        <TimePicker
                                                                            showSecond={false}
                                                                            value={moment.utc(time.time_to * 1000)}
                                                                            onChange={e => {
                                                                                if (moment.duration(e.format('HH:mm:ss')).asSeconds() > time.time_from) {
                                                                                    time.time_to = moment.duration(e.format('HH:mm:ss')).asSeconds() % fullDaySeconds
                                                                                    this.setState({
                                                                                        invalidDate: false,
                                                                                        changesMade: true
                                                                                    })
                                                                                } else {
                                                                                    console.log(moment.duration(e.format('HH:mm:ss')).asSeconds(), time.time_from)
                                                                                    this.setState({
                                                                                        invalidDate: true,
                                                                                        changesMade: false
                                                                                    })
                                                                                }
                                                                            }}
                                                                            onClose={() => {
                                                                                if (!this.state.invalidDate && this.state.changesMade) {
                                                                                    const diff = time.time_to - time.time_from;
                                                                                    time.total_seconds = moment.duration(diff * 1000).asSeconds()
                                                                                    this.setState({editStopId: null})
                                                                                    this.setState(resource, this.delayedSave);
                                                                                } else {
                                                                                    this.setState({editStopId: null})
                                                                                }
                                                                            }
                                                                            }
                                                                            format={'h:mm:ss a'}
                                                                            use12Hours
                                                                            disabled={this.state.editStopId !== time.id}
                                                                            allowEmpty={false}
                                                                            disabledHours={() => this.getDisabledHours(time.time_to, time.time_from, 'lower')}
                                                                            disabledMinutes={() => this.getDisabledMinutes(time.time_to, time.time_from, 'lower')}
                                                                        />
                                                                        <Link
                                                                            onClick={() => (time.id === this.state.editStopId) ? this.setState({editStopId: null}) : this.setState({editStopId: time.id})}>{time.id === this.state.editStopId ? formatMessage(messages.cancel) : formatMessage(messages.edit)}</Link>
                                                                    </>}
                                                                </Col>
                                                                <Col md={2} xs={12} className='vertical-align'>
                                                                    <h4 className={isMobile && 'small-margin'}>{((time.time_from && time.time_to) || (!time.time_from && !time.time_to)) ? this.totalTimeFormatter(time.total_seconds) :
                                                                        <TotalTime
                                                                            timeFn={(timeCounter) => timeCounter - time.time_from}/>}</h4>
                                                                </Col>
                                                                <Col md={1} xs={12}>
                                                                    <FontAwesomeIcon icon="times"
                                                                                     className="small-margin"
                                                                                     onClick={this.handleRemoveTrack(time.id)}/>
                                                                </Col>
                                                            </Row>
                                                        </ListGroupItem>
                                                    })}
                                                </ListGroup>}

                                        </ListGroupItem>
                                    })
                                }
                            </ListGroup>
                            <Row className='time-panel-header'>
                                <Col xs={6}>
                                        <span className='pointer text-blue'
                                              onClick={() => this.addTimeTrack(null)}>{formatMessage(messages.newTime)} +</span>
                                </Col>
                            </Row>
                        </Panel>
                    </Col>
                </Row>}
                <Row className='no-margin'>
                    <span className='text-blue'>{formatMessage(messages.crewMessages)}</span>
                    {resource && <CrewMessages
                        workOrderId={resource.id}
                        siteId={resource.site_id}
                        employees={employees}
                        eventPersonIds={personIds}
                    />}
                </Row>
                <Row>
                    <Col xs={12}>
                        <button
                            className="btn btn-default edit-chemical-compliance-btn"
                            disabled={!resource?.work_order_proposal_services?.some(ps => ps.isPHC)}
                            onClick={(e) => {
                                e.stopPropagation();
                                this.setState({showChemicalCompliance: true})
                            }}
                        >
                            <span>Chemical Compliance</span>
                            <Glyphicon glyph="pencil"/>
                        </button>
                    </Col>
                </Row>
                <Row className='vertical-align'>
                    <Col xs={6} className='hstart'>
                        <ColorCheckbox label={formatMessage(messages.showAllServices)}
                                       disabled={!resource.proposal_services}
                                       value={showAllServices}
                                       onChange={this.handleShowAllServices}/>
                    </Col>
                    <Col xs={6} className='justify-flex-end'>
                        <ColorCheckbox label={formatMessage(messages.showCompleted)}
                                       value={showCompleted}
                                       onChange={this.handleShowCompleted}/>
                    </Col>
                </Row>
                <Row>
                    {services ? <Col xs={12}>
                        {services.map((service, i) => {
                            return (
                                <Panel
                                    key={i}
                                    className="service-panel"
                                    collapsible
                                    header={this.renderServiceHeader(service)}
                                    expanded={expanded}
                                >
                                    {expanded === service.id &&
                                        <ListGroup>
                                            <ListGroupItem
                                                header={formatMessage(messages.serviceNotes)}>{this.getServiceText(service, 'notes')}</ListGroupItem>
                                            <ListGroupItem header={formatMessage(messages.woNotes)} className='pointer'>
                                                <InlineEditable
                                                    form={() =>
                                                        <TextareaAutosize
                                                            style={{width: '100%'}}
                                                            name="wo_notes"
                                                            value={service.wo_notes}
                                                            onChange={(e) => {
                                                                let {work_order_proposal_services} = this.state.resource;
                                                                work_order_proposal_services[i].wo_notes = e.target.value;
                                                                this.setState({work_order_proposal_services}, this.delayedSave)
                                                            }}
                                                            minRows={3}
                                                            maxRows={10}
                                                            placeholder={formatMessage(messages.woNotes)}
                                                        />
                                                    }
                                                    value={service.wo_notes ?
                                                        <div
                                                            dangerouslySetInnerHTML={{__html: this.getServiceText(service, 'wo_notes')}}/> : formatMessage(messages.empty)}
                                                />
                                            </ListGroupItem>
                                            {service.is_bundle_service && service.bundle_services.sort((a, b) => a.service_number - b.service_number).map(bs => {
                                                return (
                                                    <div id='bundle-panel'>
                                                        <Panel key={i}
                                                               collapsible
                                                               header={this.renderBundleServiceHeader(service, bs)}
                                                        >
                                                            <div className='bundle-services__body'>
                                                                <ListGroupItem
                                                                    header={formatMessage(messages.serviceNotes)}>
                                                                    {this.getServiceText(bs, 'notes')}
                                                                </ListGroupItem>
                                                                <ListGroupItem header={formatMessage(messages.woNotes)}
                                                                               className='pointer'>
                                                                    {this.getServiceText(bs, 'wo_notes')}
                                                                </ListGroupItem>

                                                            </div>
                                                            {bs.assets && bs.assets.sort((a, b) => sortAssetsLabels(a, b, 'asset_label')).map((plant, idx) => {
                                                                return (
                                                                    <ListGroupItem
                                                                        key={idx}
                                                                        className={service.assets.filter(a => a.selected).map(a => a.asset_number === plant.asset_number).pop() ? 'plant-panel pointer highlight' : ' pointer plant-panel'}
                                                                        onClick={() => this.handleSelectMarker(i, idx)}>
                                                                        <Link
                                                                            onClick={this.handleScrollToMap(i, idx)}>#{plant.asset_label}{" "} {plant.plant_count > 1 ? ' [' + plant.plant_count + ']' : ""}
                                                                            <PlantName
                                                                                plant={getPlantName(plant)}/></Link>
                                                                        <Glyphicon
                                                                            glyph='modal-window'
                                                                            className="pointer pull-right"
                                                                            onClick={() => {
                                                                                this.setState({asset: plant})
                                                                            }}/>
                                                                        {plant.asset_object_hazards_length > 0 &&
                                                                            <Glyphicon glyph="exclamation-sign"
                                                                                       className="pull-right small-margin"/>}
                                                                    </ListGroupItem>
                                                                )
                                                            })
                                                            }

                                                        </Panel>
                                                    </div>
                                                )
                                            })}

                                            {!service.is_bundle_service && service.assets && service.assets.sort((a, b) => a.asset_label - b.asset_label).map((plant, idx) => {
                                                return (
                                                    <ListGroupItem
                                                        key={idx}
                                                        className={plant.selected ? 'plant-panel pointer highlight' : ' pointer plant-panel'}
                                                        onClick={() => this.handleSelectMarker(i, idx)}>
                                                        <Link
                                                            onClick={this.handleScrollToMap(i, idx)}>#{plant.asset_label}{" "}{plant.plant_count > 1 ? ' [' + plant.plant_count + ']' : ""}
                                                            <PlantName
                                                                plant={getPlantName(plant)}/></Link>
                                                        <Glyphicon
                                                            glyph='modal-window' className="pointer pull-right"
                                                            onClick={() => {
                                                                this.setState({asset: plant})
                                                            }}/>
                                                        {plant.asset_object_hazards_length > 0 &&
                                                            <Glyphicon glyph="exclamation-sign"
                                                                       className="pull-right small-margin"/>}
                                                    </ListGroupItem>
                                                )
                                            })
                                            }
                                        </ListGroup>
                                    }
                                </Panel>
                            )
                        })
                        }
                    </Col> : <Col xs={12}><MDSpinner size={70}/></Col>}
                    <Col xs={12}>
                        <Panel
                            className="resources-panel pointer"
                            collapsible
                            header={<span>{formatMessage(messages.resources)}</span>}
                        ><ListGroup>
                            {
                                resource.equipments && resource.equipments.length > 0 && resource.equipments.map((resource, i) =>
                                    <ListGroupItem key={i}
                                                   header={resource.name}>{resource.description}</ListGroupItem>
                                )}</ListGroup>
                        </Panel>
                    </Col>
                </Row>
            </Col>
            <Col xs={12}>
                <ColorCheckbox value={this.state.myLocation}
                               label={formatMessage(messages.myLocation)}
                               onChange={() => this.setState({myLocation: !this.state.myLocation})}
                />
            </Col>
            <Col xs={12}>
                <Profiler id={1} onRender={() => {
                }}>

                    <GoogleMapLoader
                        containerElement={
                            <div
                                id="woDetailsMap"
                                className="remaining vertical-responsive-height"
                            />
                        }
                        googleMapElement={
                            <GoogleMapElement
                                ref={it => {
                                    this.refGoogleMap(it, coordinates)
                                }}
                                {...defaultMapParams(assets, null, null, client)}
                            >
                                {this.renderMarkers(assets)}
                            </GoogleMapElement>
                        }
                    />
                </Profiler>
            </Col>
            <ReactDock position='right'
                       isVisible={showDock}
                       dimMode="none"
                       defaultSize={window.innerWidth > 990 ? 0.6 : 0.9}
                       dockStyle={{overflow: 'hidden'}}
                       zIndex={1031}
            >
                <div className="close-dock">
                    <strong className="margin10 pull-left">{formatMessage(messages.crewWorkOrders)}</strong>
                    <div className="pull-right small-margin">
                        <Glyphicon glyph='remove' className="pointer margin10"
                                   onClick={() => this.setState({showDock: null})}/>
                    </div>
                </div>
                <div className="margin10">
                    {
                        selectedEventId &&
                        <CrewWorkOrdersDetails schedulerEventId={selectedEventId} actions={this.props.actions}
                                               showHeader={false}/>
                    }

                </div>
            </ReactDock>
            {asset && <AssetEditorModal
                translateAsset={this.shouldShowTranslation()}
                asset={asset}
                isCrewUser={isCrewUser}
                isCrewGroupUser={isCrewGroupUser}
                isSubcontractor={isSubcontractor}
                oldAttachment={true}
                roles={roles}
                lockedCrewEditAccess={hasCrewRole && (client && client.block_crews_modal_access)}
                siteId={resource.site_id}
                onClose={() => this.setState({asset: null})}
                onSave={() => {
                    let newServices = services.map(service => {
                        return {...service, assets: service?.assets.map(a => a.id !== asset.id ? a : asset)}
                    })
                    this.setState({asset: null, services: newServices})
                    this.reload(schedulerEventId)
                }
                }
            />}
            {showChemicalCompliance && resource && <ChemicalComplianceModal
                workOrder={resource}
                onHide={() => this.setState({showChemicalCompliance: false})}
                isOpen={showChemicalCompliance}
            />}
        </Row>;
    }
}

CrewWorkOrdersDetails.propTypes = {
    showHeader: PropTypes.bool
};

CrewWorkOrdersDetails.defaultProps = {
    showHeader: true
};

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

function mapStateToProps(state, ownProps) {
    return {
        email: state.auth.email,
        roles: state.auth.roles,
        client: state.client.customerInfo,
        user: state.auth.user,
        locale: state.userStatus.userStatus?.locale,
    };
}

export default geolocated({
    positionOptions: {
        enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
})(connect(mapStateToProps, mapDispatchToProps)(injectIntl(CrewWorkOrdersDetails)));
