import React from "react";
import {Button, Col, Modal, Row} from "react-bootstrap";
import moment from "moment";
import {defaultDateTimeFormatShort} from "../../../../common/commonHandlers";

const HistoryModal = ({onHide, history}) => {
    return <Modal
        show={true}
        animation={true}
        className={"heightAuto maxHeight90 fontIOS"}
        id='document-history-modal'
        onHide={() => onHide()}
    >
        <Modal.Header>
            <Modal.Title>History</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {history ?
                history.map((h, i) => {
                    return <Row>
                        <Col xs={4} className='text-right'>
                            <b>{h.user_name}</b>
                            <p>{moment(h.time).format(defaultDateTimeFormatShort)}</p>
                        </Col>
                        <Col xs={8}>
                            {h.initialize_document &&
                                <div>
                                    <p><b>The Document has been created with data:</b></p>
                                    <p>Title: {h.initialize_document.title ? <b>{h.initialize_document.title}</b> :
                                        <i> No title</i>}</p>
                                    <p>Note: {h.initialize_document.note ? <b>{h.initialize_document.note}</b> :
                                        <i> No note</i>}</p>
                                    <p>Tab title: <b>{h.initialize_document.tab}</b></p>
                                    <p className='no-margin'>With files:
                                        {h.initialize_document.files?.length > 0 ?
                                            <b>{h.initialize_document.files?.length}</b>
                                            :
                                            <i> No files</i>
                                        }
                                    </p>
                                    {h.initialize_document.files && h.initialize_document.files.map((f, i) => {
                                        return <Row>
                                            <Col xs={2} className='text-right'>{i + 1}.</Col>
                                            <Col xs={10}>
                                                <p className='no-margin'>File name: <b>{f.name}</b></p>
                                                <p>File type: <b>{f.type}</b></p>
                                            </Col>
                                        </Row>
                                    })}
                                </div>
                            }
                            {h.changes && h.changes.map(change => {
                                return <div>
                                    <p className='no-margin'><b>{change.description}</b></p>
                                    {change.old && <p className='no-margin'>Old value: <b>{change.old}</b></p>}
                                    {change.new && <p>New value: <b>{change.new}</b></p>}
                                    {change.files && change.files.length > 0 && change.files.map((f, i) => {
                                        return <Row>
                                            <Col xs={2} className='text-right'>{i + 1}.</Col>
                                            <Col xs={10}>
                                                <p className='no-margin'>File name: <b>{f.name}</b></p>
                                                <p>File type: <b>{f.type}</b></p>
                                            </Col>
                                        </Row>
                                    })}
                                </div>
                            })
                            }
                        </Col>
                        <Col xs={12}>{i + 1 !== history.length && <hr/>}</Col>
                    </Row>
                })
                :
                <h4 className='text-center'>The Document has no history yet!</h4>
            }
        </Modal.Body>
        <Modal.Footer>
            <Row>
                <Col mdOffset={6} md={6} className="text-right">
                    <Button
                        bsStyle="success"
                        bsSize="small"
                        onClick={() => onHide()}
                    >
                        Close
                    </Button>
                </Col>
            </Row>
        </Modal.Footer>
    </Modal>
}

export default HistoryModal;