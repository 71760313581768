import {
    FETCH_MY_PROPOSALS,
    UPDATE_PROPOSAL_COMPLETED_END,
    UPDATE_PROPOSAL_COMPLETED_START,
    UPDATE_STATUS,
} from "./constants"

import {addAlert} from "../App/actions"
import axios from "axios"
import moment from "moment"

export const updateProposalCompletedStart = proposalProposedStart => {
    proposalProposedStart = moment(proposalProposedStart).isValid()
        ? moment(proposalProposedStart).format()
        : null

    return {type: UPDATE_PROPOSAL_COMPLETED_START, proposalProposedStart}
}

export const updateProposalCompletedEnd = proposalProposedEnd => {
    proposalProposedEnd = moment(proposalProposedEnd).isValid()
        ? moment(proposalProposedEnd).format()
        : null
    return {type: UPDATE_PROPOSAL_COMPLETED_END, proposalProposedEnd}
}

export const updateProposalTypeSelection = e => dispatch => {
    dispatch({type: FETCH_MY_PROPOSALS, myProposals: []})
    dispatch({type: UPDATE_STATUS, status: e.target.value})
}

export const fetchMyProposals = (arborist) => async (
    dispatch,
    getState
) => {
    dispatch(
        addAlert({
            message: "This might take a minute or two.",
            mood: "info",
            dismissAfter: 1500,
        })
    )
    const store = getState()

    const config = {
        url: `/api/proposals/sales_funnel`,
        headers: {Authorization: store.auth.token},
        params: {
            // start_date: store.myProposals.proposalProposedStart,
            // end_date: store.myProposals.proposalProposedEnd,
            // status: store.myProposals.status,
            arborist: arborist,
        },
    }

    try {
        const response = await axios.request(config)
        dispatch({type: FETCH_MY_PROPOSALS, myProposals: response.data})
        if (response.data && response.data.length === 0) {
            dispatch(
                addAlert({
                    message: "No Proposals returned. Please change your query and try again.",
                    mood: "warning",
                    dismissAfter: 3500,
                })
            )
        }
    } catch (error) {
        dispatch(
            addAlert({
                message: "Couldn't fetch Proposals because: " + error.toString(),
                mood: "danger",
                dismissAfter: 5000,
            })
        )
        throw error
    }
}

export const fetchAcceptedProposals = (accepted) => async (
    dispatch,
    getState
) => {
    dispatch(
        addAlert({
            message: "This might take a minute or two.",
            mood: "info",
            dismissAfter: 1500,
        })
    )
    const store = getState()

    const config = {
        url: `/api/proposals/sales_funnel`,
        headers: {Authorization: store.auth.token},
        params: {
            accepted: accepted,
        },
    }

    try {
        const response = await axios.request(config)
        dispatch({type: FETCH_MY_PROPOSALS, myProposals: response.data})
        if (response.data && response.data.length === 0) {
            dispatch(
                addAlert({
                    message: "No Proposals returned. Please change your query and try again.",
                    mood: "warning",
                    dismissAfter: 3500,
                })
            )
        }
    } catch (error) {
        dispatch(
            addAlert({
                message: "Couldn't fetch Proposals because: " + error.toString(),
                mood: "danger",
                dismissAfter: 5000,
            })
        )
        throw error
    }
}


export const fetchProposedProposals = (proposed) => async (
    dispatch,
    getState
) => {
    dispatch(
        addAlert({
            message: "This might take a minute or two.",
            mood: "info",
            dismissAfter: 1500,
        })
    )
    const store = getState()

    const config = {
        url: `/api/proposals/sales_funnel`,
        headers: {Authorization: store.auth.token},
        params: {
            proposed: proposed,
        },
    }

    try {
        const response = await axios.request(config)
        dispatch({type: FETCH_MY_PROPOSALS, myProposals: response.data})
        if (response.data && response.data.length === 0) {
            dispatch(
                addAlert({
                    message: "No Proposals returned. Please change your query and try again.",
                    mood: "warning",
                    dismissAfter: 3500,
                })
            )
        }
    } catch (error) {
        dispatch(
            addAlert({
                message: "Couldn't fetch Proposals because: " + error.toString(),
                mood: "danger",
                dismissAfter: 5000,
            })
        )
        throw error
    }
}

export const fetchWoProposals = (in_wo) => async (
    dispatch,
    getState
) => {
    dispatch(
        addAlert({
            message: "This might take a minute or two.",
            mood: "info",
            dismissAfter: 1500,
        })
    )
    const store = getState()

    const config = {
        url: `/api/proposals/proposals_in_work_order`,
        headers: {Authorization: store.auth.token},
        // params: {
        //   in_wo: in_wo,
        // },
    }

    try {
        const response = await axios.request(config)
        dispatch({type: FETCH_MY_PROPOSALS, myProposals: response.data})
        if (response.data && response.data.length === 0) {
            dispatch(
                addAlert({
                    message: "No Proposals in Work order returned. Please change your query and try again.",
                    mood: "warning",
                    dismissAfter: 3500,
                })
            )
        }
    } catch (error) {
        dispatch(
            addAlert({
                message: "Couldn't fetch Proposals in Work Orders because: " + error.toString(),
                mood: "danger",
                dismissAfter: 5000,
            })
        )
        throw error
    }
}
