import {Button, Col, Modal, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {mapForSelect, select} from "../../../common/commonHandlers";
import {Creatable} from "react-select";
import createApiService from "../../../common/clientActionsBuilder";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import Dropzone from "react-dropzone";
import * as actions from "./CrewMessageApi"
import Attachment from "../../../components/image/Attachment";
import './HazardModal.css'

const Actions = createApiService('hazards', 'hazard', 'Hazard');

const api = {...Actions, ...actions}

function HazardModal({show, onSave, onHide, ...props}) {

    const [resource, setResource] = useState({hazard_id: null, name: '', images: []});
    const [hazards, setHazards] = useState();
    const [isImageModalOpened, setIsImageModalOpened] = useState(false)

    useEffect(() => {
        props.actions.load(null, response => {
            setHazards(response)
        })
    }, [])

    const forceDownload = (blob, filename) => {
        let a = document.createElement('a');
        a.download = filename;
        a.href = blob;
        document.body.appendChild(a);
        a.click();
        a.remove();
    }

    const removeHazardItem = (id) => {
        props.actions.removeFile(id, "images", () => {
            let newFiles = []
            newFiles = resource.images.filter(img => img.id !== id)
            setResource({...resource, images: newFiles})
        })
    }
    const downloadResource = (url, filename) => {
        if (!filename) filename = url.split('\\').pop().split('/').pop();
        filename = filename.replace(/\.heic/ig, '.jpeg')
        fetch(url, {
            headers: new Headers({
                'Origin': window.location.origin
            }),
            mode: 'cors'
        })
            .then(response => response.blob())
            .then(blob => {
                let blobUrl = window.URL.createObjectURL(blob);
                forceDownload(blobUrl, filename);
            })
            .catch(e => console.error(e));
    }

    const hazardsOptions = hazards ? mapForSelect(hazards) : [];
    return (
        <Modal
            show={show}
            bsSize="big"
            animation={true}
            dialogClassName='modal-smaller'
            onHide={() => onHide()}
        >
            <Modal.Header closeButton>
                <Modal.Title>Add hazard</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={12}>
                        <Creatable options={hazardsOptions}
                                   value={select(hazardsOptions, resource && resource.hazard_id)}
                                   onChange={e => {
                                       const doSave = () => {
                                           let newResource = {
                                               hazard_id: e.value,
                                               name: e.label,
                                               images: resource.images
                                           }
                                           setResource(newResource);
                                       };

                                       if (isNaN(e.value)) {
                                           props.actions.save({name: e.label}, result => {
                                               e.value = result.id;
                                               doSave();
                                           });
                                       } else {
                                           doSave();
                                       }
                                   }}/>
                        <Dropzone
                            onDrop={(files, data) => {
                                props.actions.uploadImages(files, 1, file => {
                                    setResource({...resource, images: [...resource.images, file]});
                                })
                            }}
                            className="dropzone pointer">
                            <p>Drop a file here or click to select a file to upload.</p>
                        </Dropzone>
                        <div className='attachments'>
                            {resource.images?.length > 0 && resource.images.map(i => <Attachment key={i.id}
                                                                                                 image={i}
                                                                                                 allowRemove={true}
                                                                                                 downloadButton={true}
                                                                                                 downloadResource={downloadResource}
                                                                                                 deleteImage={imageId => removeHazardItem(imageId)}
                                                                                                 showName={true}/>
                            )}
                        </div>
                    </Col>
                </Row>
            </Modal.Body>

            <Modal.Footer>
                <Row>
                    <Col md={6} className="text-left">
                        <Button bsSize="small" onClick={() => {
                            if (resource.images.length) props.actions.removeImages(resource.images.map(image => image.id))
                            onHide();
                            setResource({hazard_id: null, name: '', images: []});
                        }}>
                            Cancel
                        </Button>
                    </Col>
                    <Col md={6} className="text-right">
                        <Button
                            bsStyle="success"
                            bsSize="small"
                            onClick={() => {
                                props.actions.save({
                                    id: resource.hazard_id,
                                    name: resource.name,
                                    images: resource.images
                                });
                                onSave(resource);
                                setResource({hazard_id: null, name: '', images: []});
                            }}
                        >Save
                        </Button>
                    </Col>
                </Row>

            </Modal.Footer>
        </Modal>
    )
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(api, dispatch)
    };
}

export default connect(null, mapDispatchToProps)(HazardModal);
