import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Button, Col, Grid, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import moment from "moment";
import * as api from "./QuickbooksCustomersApi";
import {select} from "../../../common/commonHandlers";
import ResourceComponent from "../../../components/ResourceComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAngleDown,
    faAngleUp,
    faLongArrowAltLeft,
    faLongArrowAltRight,
    faMinusCircle,
    faSync,
    faTimes,
    faTimesCircle
} from '@fortawesome/free-solid-svg-icons'
import Select from "react-select";
import InlineEditable from "../../Scheduler/InlineEditable";
import MDSpinner from "react-md-spinner";
import './QuickbooksCustomers.css';
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import SyncAllModal from "../SyncAllModal";
import * as progressActions from "../QuickbooksApi";
import {debounce} from "throttle-debounce";
import ConfirmDisassociateModal from "./ConfirmDisassociateModal";
import {Link} from "react-router";
import ErrorInfoModal from "../ErrorInfoModal";
import MatchedCustomersModal from "../MatchedCustomersModal";

const actions = {...api, ...progressActions};

class QuickbooksCustomers extends ResourceComponent {

    state = {
        filterVisible: true,
        resource: {
            from: moment().subtract(7, "days").utc().startOf('day'),
            to: moment().utc().endOf('day'),
            customer_id: null,
            showSynchronized: false,
            qb_customer: null,
            page: 1,
            per_page: 25,
            byInvoice: true,
            showNotSyncCustomer: false
        },
        collapsedRows: [],
        content: [],
        rowLoading: null,
        isSearching: false,
        isSearchingQbCustomer: false,
        customerQbOptions: [],
        showDisassociateModal: {
            show: false,
            th_id: null,
            index: null
        },
        editedRow: null,
        refreshingCustomerId: null,
        errorModal: {show: false, error_sites: [], message: ''},
        matchedCustomerModal: {show: false, matched_customers: null, index: null},
    };

    constructor(props, context) {
        super(props, context);
        this.customerSearch = {
            searchOptions: () => [],
            search: () => {
            }
        };
        this.qbCustomerSearch = {
            searchOptions: () => [],
            search: () => {

            }
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {
            eventKey,
            selectedKey,
            dateTo,
            dateFrom,
            showSynchronized,
            customerFilterType,
            customerQbFilter,
            customerThFilter,
            byInvoice,
            showNotSyncCustomer
        } = this.props;
        const {resource, collapsedRows} = this.state;
        if (dateTo && dateFrom &&
            eventKey === selectedKey &&
            (prevProps.dateTo !== dateTo ||
                prevProps.dateFrom !== dateFrom ||
                prevProps.showSynchronized !== showSynchronized ||
                prevProps.byInvoice !== byInvoice ||
                prevProps.showNotSyncCustomer !== showNotSyncCustomer
            )) {
            resource["from"] = dateFrom;
            resource["to"] = dateTo;
            resource["showSynchronized"] = showSynchronized;
            resource["byInvoice"] = byInvoice;
            resource["showNotSyncCustomer"] = showNotSyncCustomer;
            resource["page"] = 1;
            this.setState({resource: resource}, this.sync);
        }
        if (prevProps.eventKey !== eventKey || prevProps.selectedKey !== selectedKey) {
            if (eventKey === selectedKey) {
                this.customerSearch = this.buildSearchComponent('customer_id', this.props.actions.searchForCustomer);
                this.qbCustomerSearch = this.buildSearchComponent('qb_customer', e => this.props.actions.searchForQbCustomer(e, (res) => this.setState({
                    customerQbOptions: res,
                    isSearchingQbCustomer: false
                })));
                this.sync();
            }
        }
        if (prevProps.customerFilterType !== customerFilterType) {
            resource.page = 1
            this.setState({resource: resource})
        }
    }

    componentDidMount() {
        this.customerSearch = this.buildSearchComponent('customer_id', this.props.actions.searchForCustomer);
        this.qbCustomerSearch = this.buildSearchComponent('qb_customer', e => this.props.actions.searchForQbCustomer(e, (res) => this.setState({
            customerQbOptions: res,
            isSearchingQbCustomer: false
        })));
        if (window.location.href.indexOf("customer_id") === -1) {
            this.sync();
        }
        this.props.onRef(this)
    }

    componentWillUnmount() {
        this.props.onRef(undefined)
    }

    sync = () => {
        const {resource} = this.state;
        const {customerFilterType, customerQbFilter, customerThFilter, page} = this.props
        this.props.actions.syncCustomers({
            ...resource,
            customerFilterType: customerFilterType,
            customerQbFilter: customerQbFilter,
            customerThFilter: customerThFilter,
            page: page ? page : resource.page
        }, response => this.setState(response, () => {
            this.props.setIsNotLoading();
        }));
    }

    syncQbCustomer = (qbId, index) => {
        let {content} = this.state;
        this.props.actions.getQbCustomer(qbId,
            (result) => {
                content[index].th = result.content[0].customer;
                content.rowLoading = null;
                this.setState(content);

            },
            res => {
                if (res?.data?.message) {
                    const errorModal = {
                        show: true,
                        message: res?.data?.message
                    }
                    this.setState({errorModal})
                }
                content.rowLoading = null;
                this.setState(content);
            }
        )
    }

    syncThCustomer = (thId, index) => {
        let {content} = this.state;
        this.props.actions.getCustomer(thId,
            (result) => {
                result.sites.sort(function (a, b) {
                    if (b.name.trim() < a.name.trim()) {
                        return -1;
                    }
                    if (b.name.trim() > a.name.trim()) {
                        return 1;
                    }
                    return 0;
                })
                content[index].th = result;
                if (result.quickbooks_id) {
                    this.props.actions.getQbCustomer(result.quickbooks_id, (response) => {
                        if (response.content) {
                            let qbCustomer = response.content[0]
                            result.sites.sort(function (a, b) {
                                if (b.name.trim() < a.name.trim()) {
                                    return -1;
                                }
                                if (b.name.trim() > a.name.trim()) {
                                    return 1;
                                }
                                return 0;
                            })
                            content[index].qb = qbCustomer;
                            content.rowLoading = null;
                            this.setState(content);
                        }
                    })
                } else {
                    content.rowLoading = null;
                    this.setState(content);
                }
            },
            res => {
                if (res?.data?.message) {
                    const errorModal = {
                        show: true,
                        message: res?.data?.message
                    }
                    this.setState({errorModal})
                }
                content.rowLoading = null;
                this.setState(content);
            }
        )
    }

    assignCustomer = (customer, index) => {
        const {content} = this.state;
        const obj = {
            id: customer.id,
            company_name: customer.company_name,
            first_name: customer.first_name,
            last_name: customer.last_name,
            address_1: customer.address_1,
            state: customer.state,
            zip: customer.zip,
            city: customer.city,
            s_address_1: customer.s_address_1,
            s_state: customer.s_state,
            s_zip: customer.s_zip,
            s_city: customer.s_city,
            email_address: customer.email_address,
            phone_number: customer.phone_number,
            isAssigned: true
        }
        content[index]["th"] = obj
        this.setState({
            content: content,
            isSearching: false
        });
    }

    assignQbCustomer = (customer, index) => {
        let {content} = this.state;
        customer.isAssigned = true;
        content[index]["qb"] = customer;
        this.setState({
            content: content,
            isSearching: false
        });
    }

    syncAllOnThisPage = () => {
        this.setState({syncAllModal: !this.state.syncAllModal})
    }

    handleSearchQbCustomer = (resource, index) => {
        if (resource[`qb_customer_${index}`]) {
            this.props.actions.getQbCustomer(resource[`qb_customer_${index}`], (response) =>
                this.assignQbCustomer(response.content[0], index))
        }
    }

    handleSearchCustomer = (resource, index, callback = null) => {
        if (resource[`customer_id_${index}`]) {
            this.props.actions.getCustomer(resource[`customer_id_${index}`], (response) => {
                this.assignCustomer(response, index)
                callback && callback(response);
            })
        }
    }


    searchUser = (index) => {
        const customerOptions = this.customerSearch.searchOptions();
        const {resource, content, editedRow} = this.state;
        return (
            <div>{
                content[index]["th"] ? <Row>
                        <Col md={10}>
                            <InlineEditable
                                style={{minWidth: "50%"}}
                                className="pointer"
                                fullWidth={true}
                                onClick={() => !editedRow && this.setState({editedRow: true})}
                                editable={!editedRow}
                                value={this.userDataField(content[index]["th"], content[index]["qb"], "left", index, true)}
                                form={(done) =>
                                    <Select className="Select"
                                            classNamePrefix="select"
                                            value={select(customerOptions, resource[`customer_id_${index}`])}
                                            options={customerOptions}
                                            placeholder="Customer"
                                            filterOption={false}
                                            isClearable
                                            isLoading={resource.customer_id && resource.customer_id.length < 1}
                                            onInputChange={this.customerSearch.search}
                                            onChange={this.selectResourceAttr(`customer_id_${index}`,
                                                () => this.handleSearchCustomer(resource, index))}

                                    />
                                }
                            />
                        </Col>
                        <Col md={1}>
                            <div onClick={() => {
                                let rows = this.state.collapsedRows
                                if (rows.includes(index)) {
                                    rows = rows.filter(r => r !== index)
                                } else {
                                    rows.push(index)
                                }
                                this.setState({collapsedRows: rows})
                            }}>
                                {this.state.collapsedRows.includes(index) ? <FontAwesomeIcon
                                    className={`pointer`}
                                    size='2x'
                                    icon={faAngleUp}/> : <FontAwesomeIcon
                                    className={`pointer`}
                                    size='2x'
                                    icon={faAngleDown}/>}
                            </div>
                        </Col>
                    </Row> :
                    <Row>
                        <Col xs={12} className="hover-cell">
                            <InlineEditable
                                style={{minWidth: "50%"}}
                                className="pointer"
                                onClick={() => !editedRow && this.setState({editedRow: true})}
                                editable={!editedRow}
                                value={<h4><b>Not Assigned</b></h4>}
                                form={(done) =>
                                    <Select className="Select"
                                            classNamePrefix="select"
                                            value={select(customerOptions, resource[`customer_id_${index}`])}
                                            options={customerOptions}
                                            placeholder="Customer"
                                            filterOption={false}
                                            isClearable
                                            isLoading={resource.customer_id && resource.customer_id.length < 1}
                                            onInputChange={this.customerSearch.search}
                                            onChange={this.selectResourceAttr(`customer_id_${index}`,
                                                () => this.handleSearchCustomer(resource, index))}

                                    />
                                }
                            />
                        </Col>
                    </Row>
            }
            </div>
        );
    }

    searchQbUser = (index) => {
        const {
            resource,
            content,
            customerQbOptions,
            isSearchingQbCustomer,
            editedRow,
            refreshingCustomerId
        } = this.state;

        const searchFn = e => {
            if (e && e.length > 0 && !isSearchingQbCustomer) {
                this.setState({isSearchingQbCustomer: true})
                this.qbCustomerSearch.search(e)
            }
        }
        const debouncedSearchFn = debounce(1000, searchFn);
        const isRefreshing = content[index]["qb"]?.quickbooks_id === refreshingCustomerId
        const deleteable = content[index]["qb"] && content[index]["th"] && content[index]["qb"].quickbooks_id !== content[index]["th"].quickbooks_id
        return (
            <div>{
                content[index]["qb"] ?
                    <Row>
                        <Col md={10}>
                            <InlineEditable
                                style={{minWidth: "50%"}}
                                className="pointer"
                                fullWidth={true}
                                onClick={() => !editedRow && this.setState({editedRow: true})}
                                editable={!editedRow}
                                value={this.userDataField(content[index]["qb"], content[index]["th"], "right", index, true)}
                                handleSubmit={() => {
                                    this.handleSearchQbCustomer(resource, index);
                                    this.setState({editedRow: null, customerQbOptions: []})
                                }}
                                form={(done) =>
                                    <Select className="Select"
                                            classNamePrefix="select"
                                            value={select(customerQbOptions, resource[`qb_customer_${index}`])}
                                            options={!isSearchingQbCustomer ? customerQbOptions : []}
                                            placeholder="Customer"
                                            filterOption={false}
                                            isClearable
                                            isDisabled={isSearchingQbCustomer}
                                            isLoading={isSearchingQbCustomer}
                                            onInputChange={debouncedSearchFn}
                                            onChange={this.selectResourceAttr(`qb_customer_${index}`)}
                                    />
                                }
                            />
                        </Col>
                        <Col md={1}>
                            <div onClick={() => {
                                let rows = this.state.collapsedRows
                                if (rows.includes(index)) {
                                    rows = rows.filter(r => r !== index)
                                } else {
                                    rows.push(index)
                                }
                                this.setState({collapsedRows: rows})
                            }}>
                                {this.state.collapsedRows.includes(index) ? <FontAwesomeIcon
                                    className={`pointer`}
                                    size='2x'
                                    icon={faAngleUp}/> : <FontAwesomeIcon
                                    className={`pointer`}
                                    size='2x'
                                    icon={faAngleDown}/>}
                            </div>
                        </Col>
                        {deleteable &&
                            <Col md={1} className='text-right'>
                                <FontAwesomeIcon
                                    className="pointer"
                                    icon={faTimesCircle}
                                    size="1x"
                                    data-toggle={'tooltip'}
                                    title={'Delete match'}
                                    onClick={() => {
                                        const {content} = this.state
                                        if (content[index]) {
                                            content[index].qb = null;
                                            this.setState(content);
                                        }
                                    }}/>
                                <FontAwesomeIcon
                                    className={`pointer mt-3 ${isRefreshing ? 'refresh-customer-active' : ''}`}
                                    icon={faSync}
                                    size="1x"
                                    data-toggle={'tooltip'}
                                    title={'Refresh customer'}
                                    onClick={() => {
                                        const {content} = this.state
                                        if (!refreshingCustomerId) {
                                            this.setState({refreshingCustomerId: content[index]["qb"].quickbooks_id})
                                            this.props.actions.refreshCustomer(content[index]["qb"].quickbooks_id, false,
                                                res => {
                                                    if (content[index]) {
                                                        content[index].qb = res;
                                                        this.setState(content);
                                                    }
                                                    this.setState({refreshingCustomerId: null})
                                                },
                                                () => {
                                                    this.setState({refreshingCustomerId: null})
                                                }
                                            )
                                        }
                                    }}/>
                            </Col>
                        }
                    </Row> :
                    <Row>
                        <Col xs={12} className="hover-cell">
                            <InlineEditable
                                style={{minWidth: "50%"}}
                                className="pointer"
                                onClick={() => !editedRow && this.setState({editedRow: true})}
                                editable={!editedRow}
                                value={<h4><b>Not Assigned</b></h4>}
                                handleSubmit={() => {
                                    this.handleSearchQbCustomer(resource, index);
                                    this.setState({editedRow: null, customerQbOptions: []})
                                }}
                                form={(done) =>
                                    <Select className="Select"
                                            classNamePrefix="select"
                                            value={select(customerQbOptions, resource[`qb_customer_${index}`])}
                                            options={!isSearchingQbCustomer ? customerQbOptions : []}
                                            placeholder="Customer"
                                            filterOption={false}
                                            isClearable
                                            isDisabled={isSearchingQbCustomer}
                                            isLoading={isSearchingQbCustomer}
                                            onInputChange={debouncedSearchFn}
                                            onChange={this.selectResourceAttr(`qb_customer_${index}`)}
                                    />
                                }
                            />
                        </Col>
                    </Row>
            }

            </div>
        );
    }

    addressFormatter = (cell, row, enumObj, index, template) => {
        row.address_1 = row.address_1 ? row.address_1 : row.address;
        return <p
            style={{color: template && template.sites && template.sites[index] && template.sites[index].address_1 && template.sites[index].address_1?.trim() === row.address_1?.trim() ? "green" : "red"}}>{row.address_1}</p>
    }

    nameFormatter = (cell, row, enumObj, index, template) => {
        return <p
            style={{color: template && template.sites && template.sites[index] && template.sites[index].name && template.sites[index].name?.trim() === cell?.trim() ? "green" : "red"}}>{cell.trim()}</p>
    }

    cityFormatter = (cell, row, enumObj, index, template) => {
        return <p
            style={{color: template && template.sites && template.sites[index] && template.sites[index].city && template.sites[index].city?.trim() === cell?.trim() ? "green" : "red"}}>{cell}</p>
    }

    zipFormatter = (cell, row, enumObj, index, template) => {
        return <p
            style={{color: template && template.sites && template.sites[index] && template.sites[index].zip && template.sites[index].zip?.trim() === cell?.trim() ? "green" : "red"}}>{cell}</p>
    }

    countryFormatter = (cell, row, enumObj, index, template) => {
        return <p
            style={{color: template && template.sites && template.sites[index] && template.sites[index].country && template.sites[index].country?.trim() === cell?.trim() ? "green" : "red"}}>{cell}</p>
    }

    siteSyncFormatter = (cell, row, enumObj, siteIndex, template, index) => {
        const {content} = this.state

        const allowSync = (template?.id && template?.quickbooks_id) || (!template?.id && template?.customer?.quickbooks_id)
        return <span className="flex-column-center">
            <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip">Sync site</Tooltip>}>
                <FontAwesomeIcon
                    className={`pointer ${allowSync ? '' : 'arrow-disabled'}`}
                    style={{color: allowSync ? 'green' : 'gray'}}
                    icon={template?.id ? faLongArrowAltLeft : faLongArrowAltRight}
                    size="1x"
                    onClick={() => {
                        allowSync && this.setState({rowLoading: index}, () => {
                            this.props.actions.syncCustomersSite({
                                    site_id: row.id,
                                    quickbooks_id: row.quickbooks_id,
                                    customer_quickbooks_id: row.quickbooks_parent_ref?.value
                                },
                                () => {
                                    this.props.actions.refreshCustomer(template?.id || template?.customer.id, true,
                                        res => {
                                            if (content[index]) {
                                                content[index].th = res;
                                                this.setState(content);
                                            }
                                        })
                                    this.props.actions.refreshCustomer(template?.quickbooks_id, false,
                                        res => {
                                            if (content[index]) {
                                                content[index].qb = res;
                                                this.setState(content);
                                            }
                                        })
                                    this.setState({rowLoading: false})
                                },
                                (res) => {
                                    if (res?.data?.message) {
                                        this.setState({errorModal: {show: true, message: res?.data?.message}})
                                    }
                                    this.setState({rowLoading: false})
                                }
                            )
                        })
                    }}
                />
            </OverlayTrigger>
            {allowSync && row.id && row.quickbooks_sub_customer_id && <OverlayTrigger placement='right'
                                                                                      overlay={<Tooltip id='tooltip'>Remove
                                                                                          Quickbook ID from this
                                                                                          Site</Tooltip>}>
                <FontAwesomeIcon
                    className="pointer mt7"
                    style={{color: row.id ? 'red' : '#ff9999'}}
                    icon={faMinusCircle}
                    onClick={() => {
                        if (row.id) {
                            const choice = window.confirm('Are you sure you want to delete Quickbook ID?');
                            if (choice) {
                                this.props.actions.removeSiteQBId(row.id, () => {
                                    this.props.actions.refreshCustomer(row.customer_id, true,
                                        res => {
                                            if (content[index]) {
                                                content[index].th = res;
                                                this.setState(content);
                                            }
                                        })
                                })
                            }
                        }
                    }}
                />
            </OverlayTrigger>}
        </span>
    }

    stateFormatter = (cell, row, enumObj, index, template) => {
        return <p
            style={{color: template && template.sites && template.sites[index] && template.sites[index].state?.trim() && template.sites[index].state?.trim() === cell ? "green" : "red"}}>{cell}</p>
    }

    idFormatter = (cell, row) => {
        return row.id ? row.id : row.quickbooks_id;
    }

    userDataField = (user, template, column, index, isEditable) => {
        const {refreshingCustomerId} = this.state
        const isRefreshing = (column === 'right' && user.quickbooks_id === refreshingCustomerId) || user.id === refreshingCustomerId
        return (
            <Row>
                {!isEditable && column === 'left' &&
                    <Col md={1} className='text-right flex-column-start'>
                        <FontAwesomeIcon
                            className={`pointer mt-3 ${isRefreshing ? 'refresh-customer-active' : ''}`}
                            icon={faSync}
                            size="1x"
                            data-toggle={'tooltip'}
                            title={'Refresh customer'}
                            onClick={() => {
                                const {content} = this.state

                                if (!refreshingCustomerId) {
                                    if (column === 'right') {
                                        if (user?.quickbooks_id) {
                                            this.setState({refreshingCustomerId: user.quickbooks_id})
                                            this.props.actions.refreshCustomer(user.quickbooks_id, false,
                                                res => {
                                                    if (content[index]) {
                                                        content[index].qb = res;
                                                        this.setState(content);
                                                    }
                                                    this.setState({refreshingCustomerId: null})
                                                },
                                                () => {
                                                    this.setState({refreshingCustomerId: null})
                                                }
                                            )
                                        }
                                    } else {
                                        this.setState({refreshingCustomerId: user.id})
                                        this.props.actions.refreshCustomer(user.id, true,
                                            res => {
                                                if (content[index]) {
                                                    content[index].th = res;
                                                    this.setState(content);
                                                }
                                                this.setState({refreshingCustomerId: null})
                                            },
                                            () => {
                                                this.setState({refreshingCustomerId: null})
                                            }
                                        )
                                    }
                                }
                            }}/>
                    </Col>
                }
                {this.state.collapsedRows.includes(index) ? <Col md={isEditable ? 12 : 10}>
                        <Row>
                            <Col style={{textDecoration: "none"}} className="text-right" xs={3}>
                                Company Name:
                            </Col>
                            <Col xs={9} className="text-left"
                                 style={{color: template && user.company_name?.trim() === template.company_name?.trim() ? "green" : "red"}}>
                                <p>{user.company_name}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{textDecoration: "none"}} className="text-right" xs={3}>
                                Name:
                            </Col>
                            <Col xs={9} className="text-left" style={{
                                color: template && user.first_name?.trim() === template.first_name?.trim() && user.last_name?.trim()
                                === template.last_name?.trim() ? "green" : "red", marginBottom: '9px'
                            }}>
                                {column === 'left' ?
                                    <OverlayTrigger
                                        placement='bottom'
                                        overlay={
                                            <Tooltip id='tooltip-customer-info'>
                                                Show customer
                                            </Tooltip>
                                        }
                                    >
                                        <Link target="_blank" to={`/customer/info/${user?.id}`} style={{
                                            color: template && user.first_name?.trim() === template.first_name?.trim() && user.last_name?.trim()
                                            === template.last_name?.trim() ? "green" : "red"
                                        }}>
                                            <span>{user.first_name} {user.last_name}</span>
                                        </Link>
                                    </OverlayTrigger>
                                    :
                                    <span>{user.first_name} {user.last_name}</span>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{textDecoration: "none"}} className="text-right" xs={3}>
                                Billing Address:
                            </Col>
                            <Col xs={9} className="text-left" style={{
                                color: template && user.address_1?.trim() === template.address_1?.trim() && user.state?.trim()
                                === template.state?.trim() && user.zip?.trim() === template.zip?.trim() && user.city?.trim() === template.city?.trim() ? "green" : "red"
                            }}>
                                <p>{user.address_1} {user.state} {user.zip} {user.city}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{textDecoration: "none"}} className="text-right" xs={3}>
                                Email:
                            </Col>
                            <Col xs={8} className="text-left"
                                 style={{
                                     color: template && user.email_address?.trim() === template.email_address?.trim() ? "green" : "red",
                                     wordBreak: "break-all"
                                 }}>
                                <p>{user.email_address}</p>
                            </Col>
                            <Col xs={1}>
                                {(user.quickbooks_id && template && template.quickbooks_id && +user.quickbooks_id === +template.quickbooks_id) &&
                                    <div style={{textDecoration: "none"}} className="text-right">
                                        {column === 'right' ?
                                            user.email_address && user.email_address.length > 0 && template &&
                                            <div>
                                                <FontAwesomeIcon className="pointer"
                                                                 icon={faLongArrowAltLeft}
                                                                 data-toggle={'tooltip'}
                                                                 title={'Move only email address'}
                                                                 onClick={() => this.setState({rowLoading: index}, () => this.props.actions.updateThParam({
                                                                     id: user.customer.id,
                                                                     email_address: user.email_address
                                                                 }, () => this.syncQbCustomer(user.quickbooks_id, index)))}
                                                                 size="1x"/>
                                            </div>
                                            : user.email_address && user.email_address.length > 0 && template &&
                                            <div>
                                                <FontAwesomeIcon className="pointer"
                                                                 icon={faLongArrowAltRight}
                                                                 data-toggle={'tooltip'}
                                                                 title={'Move only email address'}
                                                                 onClick={() => this.setState({rowLoading: index}, () => this.props.actions.updateQbParam({
                                                                     id: user.quickbooks_id,
                                                                     email_address: user.email_address
                                                                 }, () => this.syncThCustomer(user.id, index)))}
                                                                 size="1x"/>
                                            </div>}
                                    </div>}
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{textDecoration: "none"}} xs={3} className="text-right">
                                Phone number:
                            </Col>
                            <Col xs={8} className="text-left"
                                 style={{color: template && user.phone_number?.trim() === template.phone_number?.trim() ? "green" : "red"}}>
                                <p>{user.phone_number}</p>
                            </Col>
                            <Col xs={1}>
                                {(user.quickbooks_id && template && template.quickbooks_id && +user.quickbooks_id === +template.quickbooks_id) &&
                                    <div style={{textDecoration: "none"}} className="text-right">
                                        {column === 'right' ?
                                            user.phone_number && user.phone_number.length > 0 && template &&
                                            <div>
                                                <FontAwesomeIcon className="pointer"
                                                                 icon={faLongArrowAltLeft}
                                                                 data-toggle={'tooltip'}
                                                                 title={'Move only phone number'}
                                                                 onClick={() => this.setState({rowLoading: index}, () => this.props.actions.updateThParam({
                                                                     id: user.customer.id,
                                                                     phone_number: user.phone_number
                                                                 }, () => this.syncQbCustomer(user.quickbooks_id, index)))}
                                                                 size="1x"/>
                                            </div>
                                            : user.phone_number && user.phone_number.length > 0 && template &&
                                            <div>
                                                <FontAwesomeIcon className="pointer"
                                                                 icon={faLongArrowAltRight}
                                                                 data-toggle={'tooltip'}
                                                                 title={'Move only phone number'}
                                                                 onClick={() => this.setState({rowLoading: index}, () => this.props.actions.updateQbParam({
                                                                     id: user.quickbooks_id,
                                                                     phone_number: user.phone_number
                                                                 }, () => this.syncThCustomer(user.id, index)))}
                                                                 size="1x"/>
                                            </div>}
                                    </div>}
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{textDecoration: "none"}} className="text-right" xs={3}>
                                Sites:
                            </Col>
                            <Col xs={9}>
                                {user.sites && user.sites.length > 0 &&
                                    <BootstrapTable
                                        ref="table"
                                        data={user.sites}
                                        striped={false}
                                        bordered
                                        remote
                                        className="wrapped"
                                    >
                                        <TableHeaderColumn
                                            dataField="id"
                                            hidden
                                            isKey
                                            dataFormat={(cell, row) => this.idFormatter(cell, row)}
                                        />
                                        <TableHeaderColumn
                                            className='align-center'
                                            columnClassName='align-center'
                                            dataField="name"
                                            dataFormat={(cell, row, enumObj, index) => this.nameFormatter(cell, row, enumObj, index, template)}
                                        >
                                            Name
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            className='align-center'
                                            columnClassName='align-center'
                                            dataField="address_1"
                                            dataFormat={(cell, row, enumObj, index) => this.addressFormatter(cell, row, enumObj, index, template)}
                                        >
                                            Address
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            className='align-center'
                                            columnClassName='align-center'
                                            dataField="city"
                                            dataFormat={(cell, row, enumObj, index) => this.cityFormatter(cell, row, enumObj, index, template)}
                                        >
                                            City
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            className='align-center'
                                            columnClassName='align-center'
                                            dataField="state"
                                            dataFormat={(cell, row, enumObj, index) => this.stateFormatter(cell, row, enumObj, index, template)}
                                        >
                                            State
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            className='align-center'
                                            columnClassName='align-center'
                                            dataField="zip"
                                            dataFormat={(cell, row, enumObj, index) => this.zipFormatter(cell, row, enumObj, index, template)}
                                        >
                                            Zip
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            className='align-center'
                                            columnClassName='align-center'
                                            dataField="country"
                                            dataFormat={(cell, row, enumObj, index) => this.countryFormatter(cell, row, enumObj, index, template)}
                                        >
                                            Country
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            className='align-center'
                                            columnClassName='align-center'
                                            dataField="site_sync"
                                            dataFormat={(cell, row, enumObj, siteIndex) => this.siteSyncFormatter(cell, row, enumObj, siteIndex, template, index)}
                                        >
                                            Sync
                                        </TableHeaderColumn>
                                    </BootstrapTable>
                                }
                            </Col>
                        </Row>
                    </Col> :
                    <Col md={isEditable ? 12 : 10}>
                        <Row>
                            <Col xs={4} className="text-left">
                                <p>Company Name: <span
                                    style={{color: template && user.company_name?.trim() === template.company_name?.trim() ? "green" : "red"}}>{user.company_name && user.company_name}</span>
                                </p>
                            </Col>
                            <Col xs={4} className="text-left">
                                {column === 'left' ?
                                    <OverlayTrigger
                                        placement='bottom'
                                        overlay={
                                            <Tooltip id='tooltip-customer-info'>
                                                Show customer
                                            </Tooltip>
                                        }
                                    >
                                        <p>Name:
                                            <Link target="_blank" to={`/customer/info/${user?.id}`} style={{
                                                color: template && user.first_name?.trim() === template.first_name?.trim() && user.last_name?.trim()
                                                === template.last_name?.trim() ? "green" : "red"
                                            }}>
                                            <span style={{
                                                color: template && user.first_name?.trim() === template.first_name?.trim() && user.last_name?.trim()
                                                === template.last_name?.trim() ? "green" : "red"
                                            }}> {user.first_name && user.first_name} {user.last_name && user.last_name}</span>
                                            </Link>
                                        </p>
                                    </OverlayTrigger>
                                    :
                                    <p>Name: <span style={{
                                        color: template && user.first_name?.trim() === template.first_name?.trim() && user.last_name?.trim()
                                        === template.last_name?.trim() ? "green" : "red"
                                    }}>{user.first_name && user.first_name} {user.last_name && user.last_name}</span>
                                    </p>
                                }
                            </Col>
                            <Col xs={4} className="text-left">
                                <p>Email: <span
                                    style={{
                                        color: template && user.email_address?.trim() === template.email_address?.trim() ? "green" : "red",
                                        wordBreak: "break-all"
                                    }}>{user.email_address && user.email_address}</span>
                                </p>
                            </Col>
                        </Row>
                    </Col>
                }
                {!isEditable && <Col md={1}>
                    <div onClick={() => {
                        let rows = this.state.collapsedRows
                        if (rows.includes(index)) {
                            rows = rows.filter(r => r !== index)
                        } else {
                            rows.push(index)
                        }
                        this.setState({collapsedRows: rows})
                    }}>
                        {this.state.collapsedRows.includes(index) ? <FontAwesomeIcon
                            className={`pointer`}
                            size='2x'
                            icon={faAngleUp}/> : <FontAwesomeIcon
                            className={`pointer`}
                            size='2x'
                            icon={faAngleDown}/>}
                    </div>
                </Col>}
                {!isEditable && column === 'right' &&
                    <Col md={1}
                         className={`text-right ${column === 'right' ? 'flex-column-end' : 'flex-column-start'}`}>
                        {user && template && user.quickbooks_id !== template.quickbooks_id && <FontAwesomeIcon
                            className="pointer"
                            icon={faTimesCircle}
                            size="1x"
                            data-toggle={'tooltip'}
                            title={'Delete match'}
                            onClick={() => {
                                const {content} = this.state
                                if (content[index]) {
                                    content[index].qb = null;
                                    this.setState(content);
                                }
                            }}/>}
                        <FontAwesomeIcon
                            className={`pointer mt-3 ${isRefreshing ? 'refresh-customer-active' : ''}`}
                            icon={faSync}
                            size="1x"
                            data-toggle={'tooltip'}
                            title={'Refresh customer'}
                            onClick={() => {
                                const {content} = this.state

                                if (!refreshingCustomerId) {
                                    if (column === 'right') {
                                        if (user?.quickbooks_id) {
                                            this.setState({refreshingCustomerId: user.quickbooks_id})
                                            this.props.actions.refreshCustomer(user.quickbooks_id, false,
                                                res => {
                                                    if (content[index]) {
                                                        content[index].qb = res;
                                                        this.setState(content);
                                                    }
                                                    this.setState({refreshingCustomerId: null})
                                                },
                                                () => {
                                                    this.setState({refreshingCustomerId: null})
                                                }
                                            )
                                        }
                                    } else {
                                        this.setState({refreshingCustomerId: user.id})
                                        this.props.actions.refreshCustomer(user.id, true,
                                            res => {
                                                if (content[index]) {
                                                    content[index].th = res;
                                                    this.setState(content);
                                                }
                                                this.setState({refreshingCustomerId: null})
                                            },
                                            () => {
                                                this.setState({refreshingCustomerId: null})
                                            }
                                        )
                                    }
                                }
                            }}/>
                    </Col>
                }
            </Row>
        )
    }

    customerFormatter = (cell, row, enumObject, index, direction = null) => {
        if (direction === 'right') {
            return (cell && !cell.isAssigned) ? this.userDataField(cell, row.th, direction, index) : this.searchQbUser(index)
        } else if (direction === 'left') {
            return (cell && !cell.isAssigned) ? this.userDataField(cell, row.qb, direction, index) : this.searchUser(index)
        }
    }

    choiceFormatter = (cell, row, enumObject, index) => {
        const {
            rowLoading
        } = this.state;
        const items = row;
        return (
            rowLoading !== null && rowLoading === index ? <div><MDSpinner/></div> :
                <div>
                    {items.qb &&
                        <div>
                            <FontAwesomeIcon className="pointer"
                                             icon={faLongArrowAltLeft}
                                             size="2x"
                                             onClick={() => {
                                                 this.setState({rowLoading: index}, () => {
                                                     this.props.actions.updateCustomer(items, 'left',
                                                         (res) => {
                                                             if (res?.message && res?.error_sites) {
                                                                 this.setState({
                                                                     errorModal: {
                                                                         show: true,
                                                                         message: res?.message,
                                                                         error_sites: res?.error_sites
                                                                     }
                                                                 })
                                                             }
                                                             this.syncQbCustomer(items.qb.quickbooks_id, index)
                                                         },
                                                         (res) => {
                                                             if (res?.data?.message) {
                                                                 this.setState({
                                                                     errorModal: {
                                                                         show: true,
                                                                         message: res?.data?.message
                                                                     }
                                                                 })
                                                             }
                                                             this.setState({rowLoading: false})
                                                         }
                                                     )
                                                 })
                                             }}/>
                        </div>}

                    {items.th && <div>
                        <FontAwesomeIcon className="pointer"
                                         icon={faLongArrowAltRight}
                                         size="2x"
                                         onClick={() => {
                                             this.setState({rowLoading: index}, () => {
                                                 this.props.actions.updateCustomer(items, 'right',
                                                     (res) => {
                                                         if (res?.message && res?.error_sites) {
                                                             this.setState({
                                                                 errorModal: {
                                                                     show: true,
                                                                     message: res?.message,
                                                                     error_sites: res?.error_sites
                                                                 }
                                                             })
                                                         }
                                                         if (res?.matched_customers) {
                                                             this.setState({
                                                                 matchedCustomerModal: {
                                                                     show: true,
                                                                     matched_customers: res?.matched_customers,
                                                                     index: index
                                                                 }
                                                             })
                                                         }
                                                         this.syncThCustomer(items.th.id, index)
                                                     },
                                                     (res) => {
                                                         if (res?.data?.message || res?.data?.error_sites) {
                                                             this.setState({
                                                                 errorModal: {
                                                                     show: true,
                                                                     message: res?.data?.message,
                                                                     error_sites: res?.data?.error_sites
                                                                 }
                                                             })
                                                         }
                                                         this.syncThCustomer(items.th.id, index)
                                                     }
                                                 )
                                             })
                                         }}/>
                    </div>}
                    {items.qb && items.th && items.qb.quickbooks_id === items.th.quickbooks_id && <div>
                        <FontAwesomeIcon className="pointer"
                                         icon={faTimes}
                                         size="2x"
                                         data-toggle={'tooltip'}
                                         title={'Disassociate an account'}
                                         onClick={() => this.setState({
                                             showDisassociateModal: {
                                                 show: true,
                                                 th_id: items?.th?.id,
                                                 index: index
                                             }
                                         })}
                        />
                    </div>}
                </div>
        )
    }


    render() {
        const {
            syncAllModal,
            filterVisible,
            resource,
            content,
            rowLoading,
            total,
            showDisassociateModal,
            errorModal,
            matchedCustomerModal
        } = this.state;
        const {page, per_page} = this.state.resource;
        return (
            <Grid fluid>
                <Row>
                    <Col md={4}>
                        <Button
                            bsSize={'sm'}
                            bsStyle={'link'}
                            onClick={() => this.syncAllOnThisPage()}
                        >
                            Synchronize all customers
                        </Button>
                    </Col>
                </Row>
                <Row>
                    {syncAllModal &&
                        <SyncAllModal
                            createImport={this.props.actions.createImport}
                            checkProgress={this.props.actions.checkProgress}
                            getTotalNumber={this.props.actions.getTotalNumber}
                            from={resource.from}
                            to={resource.to}
                            byInvoice={resource.byInvoice}
                            reload={this.sync}
                            synchronizeManyRecords={this.props.actions.synchronizeManyRecords}
                            show={syncAllModal}
                            synchronizeSelectedCustomer={this.props.actions.synchronizeSelectedCustomer}
                            onHide={() => this.syncAllOnThisPage()}
                            category={'customers'}
                        />}
                    {showDisassociateModal?.show &&
                        <ConfirmDisassociateModal
                            type="customer"
                            onHide={() => this.setState({
                                showDisassociateModal: {
                                    show: false,
                                    th_id: null,
                                    index: null
                                }
                            })}
                            onSave={() => {
                                this.props.actions.disassociateAccount(showDisassociateModal?.th_id, () => {
                                    const {content} = this.state
                                    if (content[showDisassociateModal?.index]) {
                                        let rowCustomers = content[showDisassociateModal?.index]
                                        rowCustomers.qb = null;

                                        this.props.actions.refreshCustomer(rowCustomers?.th?.id || rowCustomers?.th?.customer.id, true,
                                            res => rowCustomers.th = res)

                                        this.setState(content);
                                    }
                                })
                            }}
                        />
                    }
                    {errorModal?.show &&
                        <ErrorInfoModal
                            type="Customer"
                            message={errorModal?.message}
                            errorSites={errorModal?.error_sites}
                            onHide={() => {
                                const errorModal = {
                                    show: false,
                                    error_sites: [],
                                    message: ''
                                }
                                this.setState({errorModal})
                            }}
                        />
                    }
                    {matchedCustomerModal?.show &&
                        <MatchedCustomersModal
                            matchedCustomers={matchedCustomerModal.matched_customers}
                            onHide={() => {
                                const matchedCustomerModal = {
                                    show: false,
                                    matched_customers: null
                                }
                                this.setState({matchedCustomerModal})
                            }}
                            syncMany={false}
                            reload={(QBCustomer) => {
                                const rowCustomer = content[matchedCustomerModal?.index]
                                if (rowCustomer) {
                                    this.props.actions.refreshCustomer(rowCustomer?.th?.id || rowCustomer?.th?.customer.id, true,
                                        res => {
                                            rowCustomer.th = res;
                                            rowCustomer.qb = QBCustomer;
                                            content[matchedCustomerModal?.index] = rowCustomer
                                            this.setState(content);
                                        })
                                }
                                this.setState({rowLoading: false})
                            }}
                            onSync={this.props.actions.synchronizeSelectedCustomer}
                        />
                    }
                    <Col xs={12} className={`top25 no-padding`}>
                        <BootstrapTable
                            ref="table"
                            data={content}
                            striped={false}
                            bordered
                            hover={true}
                            pagination={true}
                            remote
                            fetchInfo={{dataTotalSize: total}}
                            className="wrapped"
                            trClassName={this.rowSynchronizedClass}
                            options={
                                {
                                    onPageChange: (page, per_page) => {
                                        let {resource} = this.state;
                                        resource = {...resource, page, per_page};
                                        this.setState({resource}, this.sync);
                                    },
                                    page,
                                    sizePerPageList: [{
                                        text: '10', value: 10
                                    }, {
                                        text: '25', value: 25
                                    },
                                        {
                                            text: '30', value: 30
                                        },
                                        {
                                            text: '50', value: 50
                                        },
                                        {
                                            text: '100', value: 100
                                        },
                                        {
                                            text: '200', value: 200
                                        },
                                        {
                                            text: 'All', value: total
                                        }],
                                    sizePerPage: per_page,
                                    sizePerPageDropDown: this.renderSizePerPageDropDown,
                                }
                            }
                            selectRow={{
                                mode: "hidden",
                                clickToSelect: false,
                                onSelect: this.onRowSelect,
                                onSelectAll: this.onSelectAll,
                            }}
                        >
                            <TableHeaderColumn
                                dataField="id"
                                hidden
                                width={"5%"}
                                isKey
                            />
                            <TableHeaderColumn
                                className='align-center'
                                columnClassName='align-center'
                                width={"45%"}
                                dataField="th"
                                dataFormat={(cell, row, enumObj, index) => this.customerFormatter(cell, row, enumObj, index, 'left')}
                            >
                                TreeHub Customers
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width={"10%"}
                                className='align-center'
                                columnClassName='align-center'
                                dataFormat={this.choiceFormatter}
                            >
                                Choice
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                className='align-center'
                                columnClassName='align-center'
                                dataField="qb"
                                dataFormat={(cell, row, enumObj, index) => this.customerFormatter(cell, row, enumObj, index, 'right')}
                                width={"45%"}
                            >
                                QuickBooks Customers
                            </TableHeaderColumn>
                        </BootstrapTable>
                    </Col>
                </Row>
            </Grid>
        );
    }


}

QuickbooksCustomers.propTypes = {
    //myProp: PropTypes.object.isRequired
};

QuickbooksCustomers.defaultProps = {
    //myProp: <defaultValue>
};

function mapStateToProps(state, ownProps) {
    return {
        clientId: state.auth.client_id
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(QuickbooksCustomers);

