import React from "react"
import MasterDetail from "../../common/MasterDetail";
import {Col, ControlLabel, FormControl, FormGroup, Grid, Row} from "react-bootstrap";
import {merge, select, validateName} from "../../common/commonHandlers";
import {bindActionCreators} from 'redux'
import createApiService from "../../common/clientActionsBuilder";
import {connect} from "react-redux";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ResourceComponent from "../../components/ResourceComponent";
import moment from "moment-timezone";
import ColorCheckbox from "../Scheduler/ColorCheckbox";
import Select from "react-select";
import * as actions from "./FactorsApi";

const factors = createApiService('factors', 'factor', 'Factor');
const api = {
    ...factors,
    ...actions,
};

class Factors extends ResourceComponent {
    defaultValues = {name: '', description: '', disabled: false};
    state = {
        allowEditing: false,
        ready: false
    };

    updateResources = resources => {
        this.setState({resources})
    };

    componentDidMount = () => {
        this.props.actions.load({}, this.updateResources);
        this.props.actions.loadFactorSelects(res => {
            this.setState({factor_types: res.factor_types, plants: res.plants, ready: true})
        })
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {resource, resources} = this.state;
        if ((prevState.resources !== resources) && resource) {
            const selected = resources.find(r => r.id === resource.id)
            this.setState(() => ({resource: {...resource, modified_at: selected.modified_at}}))
        }
    }

    handleSelect = e => {
        this.setState({resource: merge(e.resource, this.defaultValues)});
    };

    reloadResources = resource => {
        this.setState({resource});
        this.componentDidMount();
    };
    allowEditingChange = () => {
        this.setState({allowEditing: !this.state.allowEditing})
    }

    render() {
        const {resource, resources, allowEditing, ready} = this.state;
        return (
            (resources && ready) ?
                <Grid fluid>
                    <MasterDetail
                        resourceName="Factor"
                        resourceNamePlural="Factors"
                        resources={resources}
                        resource={resource}
                        select={this.handleSelect}
                        save={() => {
                            let validation = validateName(resource.name)
                            if (validation.is_valid) {
                                this.props.actions.save(resource, this.reloadResources)
                            } else {
                                this.setState({errorMessage: validation.errorMessage})
                            }
                        }}
                        delete={() => this.props.actions.remove(resource, x => {
                            this.setState({resource: null}, () => this.props.actions.load({}, this.updateResources));
                        })}>
                        {resource &&
                            <div>
                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <ControlLabel>
                                                Name
                                            </ControlLabel>
                                            <FormControl
                                                name="name"
                                                onChange={this.updateResourceAttr}
                                                value={resource.name}
                                            />
                                            {this.state.errorMessage &&
                                                <p className="error-message-master-detail ">{this.state.errorMessage}</p>}
                                        </FormGroup>

                                        <FormGroup>
                                            <ControlLabel>
                                                Description
                                            </ControlLabel>
                                            <FormControl
                                                name='description'
                                                componentClass="textarea"
                                                placeholder="Description"
                                                rows="10"
                                                value={resource.description || ""}
                                                onChange={this.updateResourceAttr}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <ColorCheckbox value={resource.disabled}
                                                           label="Disabled"
                                                           onChange={this.selectCheckboxAttr('disabled')}/>
                                        </FormGroup>
                                        <FormGroup>
                                            Last
                                            updated {resource.modified_at ? moment(resource.modified_at).format('llll') :
                                            <i>Unknown</i>} by {resource.modified_by_name}
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <ControlLabel>{'Factor Type'}</ControlLabel>
                                        <Select
                                            value={select(this.state.factor_types, resource.factor_type_id)}
                                            options={this.state.factor_types}
                                            placeholder="Select Factor Type"
                                            isClearable
                                            onChange={e => this.selectResourceAttr('factor_type_id')(e)}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} className="text-center">
                                        <h5>Factor Associations</h5>
                                        <label>
                                            <input
                                                type="checkbox"
                                                value={allowEditing}
                                                onChange={this.allowEditingChange}
                                            />
                                            Allow Editing
                                        </label>
                                        <hr className="grey"/>
                                        <Row className={'bottom15'}>
                                            <Col md={4} mdOffset={4}>
                                                <Select className="Select"
                                                        classNamePrefix="select"
                                                        value={select(this.state.plants, resource.plant_factors)}
                                                        options={this.state.plants}
                                                        placeholder="Plants"
                                                        isClearable
                                                        isMulti
                                                        isDisabled={!allowEditing}
                                                        onChange={e => this.selectResourceAttrWithName('plant_factors')(e)}
                                                />
                                            </Col>

                                        </Row>
                                        <hr className="grey"/>
                                    </Col>
                                </Row>
                            </div>
                        }
                    </MasterDetail>
                </Grid> : null);
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(api, dispatch),
});

export default connect(undefined, mapDispatchToProps)(Factors)
