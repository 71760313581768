import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Button, Col, ControlLabel, FormControl, FormGroup, Modal, Row} from "react-bootstrap";
import * as notificationsActions from "../NotificationsApi"
import Dropzone from "react-dropzone";
import Attachment from "../../../components/image/Attachment";
import {getUserStatus, hideIssueDialog} from "../../AppWrapper/contents/actions";
import {showEmailDialog} from "../../../components/email/actions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import IssueRecorder from "./IssuesRecorder";
import {DocAttachment} from "../../../components/docs/DocAttachment";
import {clearVideos, removeIssueMedia, removeVideo} from "./actions";

const actions = {
    ...notificationsActions,
    hideIssueDialog,
    showEmailDialog,
    removeIssueMedia,
    removeVideo,
    getUserStatus,
    clearVideos
};

class Issues extends Component {
    state = {
        resource: {
            title: '',
            description: '',
            images: []
        },
        isSaveInProgress: false
    };

    updateResourceAttr = (e, callback) => {
        this.state.resource[e.target.name] = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        this.setState({resource: this.state.resource}, callback);
    };

    sendIssue = (e) => {
        e.preventDefault();
        this.setState({isSaveInProgress: true})
        const {resource} = this.state;
        const {auth} = this.props
        const employee_id = this.props.auth.employee_id;
        const {origin, pathname} = new URL(window.location.href)
        resource.page_address = `${origin}${pathname}`
        resource.videos = this.props.recorder?.videos
        if (this.saveValidation()) {
            resource.employee_id = employee_id && employee_id;
            this.setState(resource, () => {
                this.props.actions.sendIssue(resource, () => {
                    this.setState({
                        resource: {
                            title: '',
                            description: '',
                            employee_id: employee_id,
                            images: []
                        }
                    });
                    this.props.actions.getUserStatus(auth.user_id)
                    this.props.actions.hideIssueDialog();
                    this.props.actions.clearVideos()
                });
            });
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {resource} = this.state;
        const {showIssueDialog, screenshot, actions, recorder} = this.props;

        if ((prevProps.showIssueDialog !== showIssueDialog) && showIssueDialog) {
            this.setState({isSaveInProgress: false})
            if (screenshot) {
                const files = [];
                files.push(screenshot);
                actions.uploadImages(files, data => {
                    resource.images = [...resource.images, data]
                    this.setState({resource})
                })
            }
        }

        if ((prevProps.recorder.isRecording !== recorder.isRecording) && recorder.isRecording) {
            actions.hideIssueDialog()
        }
    }

    onCloseModal = () => {
        const {hideIssueDialog} = this.props.actions
        let {resource} = this.state
        resource.images = []
        this.setState({resource}, hideIssueDialog)
    }

    modalInfoContent = () => (
        <div className="vcenter">
            <FontAwesomeIcon icon={faInfoCircle} className="mr-9 font20"/>
            <span className="font11">All media will be attached to issue report</span>
        </div>
    );

    removeFile = (id) => {
        const {removeIssueMedia, removeVideo} = this.props.actions;
        removeIssueMedia(id, () => {
            removeVideo(id)
        })
    }

    saveValidation = () => {
        const {resource} = this.state
        return resource.title.trim() !== '' && resource.description.trim() !== ''
    }

    render() {
        const {resource, isSaveInProgress} = this.state;
        const {showIssueDialog, recorder} = this.props;

        return (
            showIssueDialog &&
            <Modal
                bsSize="large"
                animation={false}
                show={true}
                onHide={() => this.props.actions.hideIssueDialog()}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        New issue
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <Row className='mt10'>
                            <Col md={12} xs={12}>
                                <FormGroup>
                                    <ControlLabel>
                                        Title
                                    </ControlLabel>
                                    <FormControl
                                        placeholder="Title"
                                        name="title"
                                        onChange={this.updateResourceAttr}
                                        value={resource.title}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <ControlLabel>
                                        Description/How can we help?
                                    </ControlLabel>
                                    <FormControl
                                        rows={5}
                                        componentClass="textarea" placeholder="Description"
                                        name="description"
                                        onChange={this.updateResourceAttr}
                                        value={resource.description}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <ControlLabel>
                                        Attachments
                                    </ControlLabel>
                                    <Dropzone
                                        onDrop={files => this.props.actions.uploadImages(files, data => {
                                            resource.images.push(data);
                                            this.setState({resource});
                                        })}
                                        className="dropzone pointer small">
                                        <p>Drop a file here or click to select a file to upload.</p>
                                    </Dropzone>
                                    {resource.images?.length > 0 &&
                                        <Row className='mt-15'>
                                            <Col md={12}>
                                                <div>
                                                    <p className="bottom15 bold">Images:</p>
                                                    <div className='d-flex'>
                                                        {resource.images.map(i => <Attachment key={i.id}
                                                                                              image={i}
                                                                                              isIssueModal={true}
                                                                                              allowRemove={true}
                                                                                              deleteImage={imageId => {
                                                                                                  resource.images = resource.images.filter(i => i.id !== imageId);
                                                                                                  this.setState({resource});
                                                                                              }}/>)}
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>}
                                </FormGroup>
                                {recorder.videos?.length > 0 &&
                                    <Row>
                                        <Col md={12}>
                                            <div>
                                                <p className="bottom15 bold">Media:</p>
                                                {recorder.videos && recorder.videos.map(video => <DocAttachment
                                                    key={video.id}
                                                    file={video}
                                                    deleteDoc={video => this.removeFile(video)}
                                                />)}
                                            </div>
                                        </Col>
                                    </Row>
                                }
                            </Col>
                        </Row>
                    </form>
                    <hr className='mt-20'/>
                    <IssueRecorder closeIssueDialog={this.props.actions.hideIssueDialog} removeFile={this.removeFile}/>
                </Modal.Body>
                <Modal.Footer>
                    <Row>
                        <Col md={6} className="text-left">
                            {this.modalInfoContent()}
                        </Col>
                        <Col md={6}>
                            <Button
                                bsSize="small"
                                bsStyle="warning"
                                onClick={() => this.props.actions.hideIssueDialog()}
                            >
                                Cancel
                            </Button>
                            <Button
                                bsSize="small"
                                bsStyle="success"
                                onClick={this.sendIssue}
                                disabled={!this.saveValidation() || isSaveInProgress}
                                type="submit"
                            >
                                Send issue
                            </Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        showIssueDialog: state.userStatus.showIssueDialog,
        screenshot: state.userStatus.screen,
        docks: state.docks,
        users: state.users.users,
        email: state.auth.email,
        recorder: state.recorder,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Issues);
