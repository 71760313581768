import {
    FETCH_CUSTOMER_SITES,
    FETCH_PROPOSALS,
    FETCH_SEARCHED_CUSTOMERS,
    FETCH_SELECTED_SITEINFO,
    FETCH_SITES_ASSETS,
    GET_CUSTOMER_BY_ID,
    SELECT_CUSTOMER,
    UPDATE_CUSTOMER_SEARCH,
    UPDATE_SELECTED_SITE,
    UPDATE_SERVICE_STATUS,
} from "./constants"

const initialState = {
    customer: null,
    proposals: null,
    serviceStatus: null,
    customerSites: null,
    selectedSite: null,
    selectedSiteInfo: null,
    customerSearch: null,
    foundCustomers: null,
    selectedCustomer: null,
    sitesAssets: null,
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_SELECTED_SITEINFO:
            return {...state, selectedSiteInfo: action.selectedSiteInfo}
        case FETCH_SITES_ASSETS:
            return {...state, sitesAssets: action.sitesAssets}
        case SELECT_CUSTOMER:
            return {
                ...state,
                selectedCustomer: action.selectedCustomer,
                customerSites: null,
                selectedSite: null,
                selectedSiteInfo: null,
                sitesAssets: null,
                customerProposals: null,
            }
        case UPDATE_CUSTOMER_SEARCH:
            return {
                ...state,
                customerSearch: action.customerSearch,
            }
        case FETCH_SEARCHED_CUSTOMERS:
            return {...state, foundCustomers: action.foundCustomers}
        case UPDATE_SELECTED_SITE:
            return {...state, selectedSite: action.selectedSite}
        case FETCH_CUSTOMER_SITES:
            return {...state, customerSites: action.customerSites}
        case UPDATE_SERVICE_STATUS:
            return {...state, serviceStatus: action.serviceStatus}
        case FETCH_PROPOSALS:
            return {...state, proposals: action.customerProposals}
        case GET_CUSTOMER_BY_ID:
            /*we can load the page via customer id or search for customer in the page. simulate a search so the ui is happy*/
            return {
                ...state,
                customer: action.customer,
                foundCustomers: [
                    {id: action.customer.id, name: action.customer.name},
                ],
                sitesAssets: null,
            }
        default:
            return state
    }
}
