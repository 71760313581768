import React, {Component} from "react"
import {bindActionCreators} from "redux"
import {connect} from "react-redux"
import {addAlert} from "../App/actions"
import {showDock} from "../../components/dock/actions"
import * as MyActions from "./actions"
import "./fixedTable.css"
import {Badge, Button, ButtonGroup, Carousel, Col, Grid, Image, Modal, Panel, Row, Table} from "react-bootstrap"
import Dollars from "../../components/Dollars"
import {Circle, GoogleMap, InfoWindow, Marker, Polygon, Polyline, Rectangle} from "react-google-maps"
import {GoogleMapLoader} from "../../components/GoogleMapLoader";
import moment from "moment-timezone"
import {getUniqueAssets} from "../../utilities/commonProposals";
import {getLabel} from "../MapViewPage/IconProvider";
import Conversation from "../Stickies/Conversation";
import Dock from "../../components/dock/Dock";
import paid from './paid.png';
import './paid.css';
import {checkRectangleCoordinates, colors, defaultDateFormat, sortAssetsLabels} from "../../common/commonHandlers";
import PlantName from "../../components/PlantName";
import PaymentModal from "./Payment/PaymentModal";
import "./CustomerViewInvoice.scss"
import messageIcon from "../CustomerViewPage/email.png";
import cardIcon from "../CustomerViewPage/card.png";
import CustomerViewMenu from "../CustomerViewPage/CustomerViewMenu";
import CustomerViewTabs from "../CustomerViewPage/CustomerViewTabs";
import CustomerViewInvoiceHeader from "./CustomerViewInvoiceHeader";
import MDSpinner from "react-md-spinner";
import {getBasicAssetMarker} from "../../utilities";

const width600 = window.screen.width > 600 && window.screen.width < 1024;
const isMobile = window.screen.width < 1024

const Actions = {...MyActions, addAlert, showDock};

//NOTE: Page must be bootstrap xs={} for printing to not do weird things

class CustomerViewInvoice extends Component {
    state = {
        invoice: [],
        invoiceTotal: true,
        servicePrices: true,
        allDone: false,
        stickies: [],
        open: null,

        invoiceServiceNotes: true,
        assetNotes: true,
        invoiceCustomerNote: true,

        maps: true,

        locationDescription: true,

        largeAssetPhotos: true,
        assInline: true,
        selectedAsset: null,

        dbh: false,

        servicePriorities: true,
        status: true,
        serviceDateRanges: true,
        servicesNotOffered: false,

        tos: true,
        invoice_footer: true,
        include_maps: {},
        arboristInfo: true,
        accepted: [],
        declined: [],
        payByACH: false,
        payByStripe: false,
        showPhotoPopUp: null,

        toggle_bundle_services: [],

        downloadInProgress: false
    };

    resize = () => this.forceUpdate();

    componentDidMount() {
        const plaidCustomerId = localStorage.getItem('plaid_customer')

        if (plaidCustomerId) {
            this.setState({showPaymentPopUp: true, payByACH: true})
        }

        window.addEventListener('resize', this.resize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize)
    }

    showPaymentPopUp = showPaymentPopUp => {
        this.setState({showPaymentPopUp}, () => {
            const localStorageData = ["invoice_token", "link_token", "plaid_customer"]

            if (!showPaymentPopUp) {
                localStorageData.map(data => localStorage.removeItem(data))
            }
        })
    };

    calculateACHvalue = (amount) => {
        if (amount) {
            let basedAmount = parseFloat(amount)
            let totalACHAmount = basedAmount / (1 - 0.008)

            if (basedAmount > 625) {
                return 5
            } else {
                return (totalACHAmount - basedAmount)
            }
        }
    }

    renderBalanceDue = (invoice, balanceDue) => {
        const {payByACH, payByStripe} = this.state;

        if (payByACH && invoice.proposal_services.filter(ps => ps.service_name === 'Convenience fee')?.length === 0) {
            let stripeCharge = {};
            stripeCharge.service_name = 'Convenience fee';
            stripeCharge.price = this.calculateACHvalue(balanceDue);
            if (invoice.proposal_services.filter(ps => ps.service_name === 'Convenience fee').length === 0) {
                invoice.proposal_services.push(stripeCharge);
            }
        }

        if (invoice.paid_at) {
            return 0
        } else if (payByStripe) {
            return balanceDue + invoice.proposal_services.filter(ps => ps.service_name === 'Convenience fee')[0].price
        } else if (payByACH) {
            return balanceDue + invoice.proposal_services.filter(ps => ps.service_name === 'Convenience fee')[0].price
        } else {
            return balanceDue
        }
    }

    rowFormatter = (invoice, total, balanceDue) => {
        const {hoverAsset} = this.state;
        const last_invoice_ps = invoice.proposal_services.length - 1
        const print_options = JSON.parse(invoice.print_options)
        const showServiceNotes = JSON.parse(invoice.print_options).proposalServiceNotes

        return (
            <div>
                <Table striped hover fill condensed className="header-fixed mt-13">
                    <tr className="margin-2 text-center font12" style={{background: '#dddddd'}}>
                        <strong>Invoice details</strong>
                    </tr>
                    <tbody>
                    {invoice.proposal_services &&
                        invoice.proposal_services
                            .slice()
                            .sort((a, b) => a.service_no - b.service_no)
                            .map(ps => {
                                if (!this.state.servicesNotOffered && ps.status === "Not Offered") {
                                    return <div className="skip_not_offered"/>
                                }

                                let rowClass = this.state.accepted.includes(ps.proposal_service_id) ? 'accepted' : '';
                                rowClass += this.state.declined.includes(ps.proposal_service_id) ? ' declined' : '';

                                return (
                                    <div className="mt-5 full-width">
                                        <tr style={{backgroundColor: ps.assets && ps.assets.find(a => a.asset_number === this.state.selectedAsset) && '#f4ff93'}}
                                            key={ps.proposal_service_id} className={isMobile ? "full-width" : rowClass}>
                                            <td>
                                                <strong className="pointer ml-15" onClick={() => {
                                                    const open = this.state.open !== ps.service_no ? ps.service_no : null;
                                                    this.setState({open});
                                                }}>
                                                    #{ps?.order_number_in_proposal}{" "}{ps.service_name}
                                                </strong>
                                                {!this.state.declined.includes(ps.proposal_service_id) &&
                                                    <Panel className="no-border pre-line" collapsible
                                                           expanded={this.state.open !== ps.service_no}>
                                                        {showServiceNotes && ps.proposal_service_note}
                                                    </Panel>}
                                                {!this.state.declined.includes(ps.proposal_service_id) && ps.assets &&
                                                    ps.assets.sort((a, b) => sortAssetsLabels(a, b, 'asset_label'))
                                                        .map((a, i) => (
                                                            <div key={a.asset_number}
                                                                 className={this.state.assInline ? "inline-assets ml-15 line_height15" : ""}>
                                                                {(print_options.assetPhotosCustomerView && a.photos.length > 0) ? a.photos.filter(p => p.cwo_and_proposal_display === true).sort((a, b) => a.order - b.order).map(p =>
                                                                    <span onClick={() => this.showPhotoPopUp(a)}><span
                                                                        className='text-success pointer'>
                                                                <img src={p.url}
                                                                     className="asset-thumbnail"/></span></span>) : ""}
                                                                <small onClick={() => this.onMarkerClick(a)}
                                                                       className="pointer"
                                                                       style={{color: (hoverAsset && hoverAsset.asset_number === a.asset_number ? '#FF9E00' : '')}}>
                                                                    <PlantName
                                                                        plant={a.name}/>{" "}(#{a.asset_label}){" "}
                                                                </small>

                                                                {a.plant_count > 1 ? ` ${a.plant_count} Trees` : null}
                                                                {print_options.dbh && a.dbh ? ` DBH: ${a.dbh}"` : null}
                                                                {a.plant_count > 1 || (this.state.dbh && a.dbh)
                                                                    ? " "
                                                                    : null}
                                                                <span>{print_options.condition && a.condition_type ? ` Condition: "${a.condition_type}"` : null}</span>
                                                                <span>{print_options.lat && a.lat ? ` Latitude: ${a.lat}` : null}</span>
                                                                <span>{print_options.lng && a.lng ? ` Longitude: ${a.lng}` : null}</span>
                                                                <span>{print_options.height && a.height ? ` Height: ${a.height}` : null}</span>
                                                                <span>{print_options.width && a.width ? ` Width: ${a.width}` : null}</span>
                                                                <span>{print_options.stems && a.stems ? ` Stems: ${a.stems}` : null}</span>
                                                                <span>{print_options.factors && a.factors ? ` Factors: ${a.factors.map(f => ' ' + f.name)}` : null}</span>
                                                                {print_options.locationDescription && a.location
                                                                    ? `Location: ${a.location}`
                                                                    : null}
                                                                {print_options.locationDescription ? " " : null}
                                                                {print_options.assetNotes && a.note && a.note.length > 1
                                                                    ? `Note: ${a.note}  `
                                                                    : null}
                                                                {print_options.assInline ? ((ps.assets.length - 1) == i) ? null : "," : ""}
                                                            </div>))}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={isMobile && "line_height15"}>
                                                <h6 className="text-warning ml-15 bottom0 line_height15">
                                                    {print_options.status && ps.status
                                                        ? `Status: ${ps.status}`
                                                        : null}
                                                    <br/>
                                                    {print_options.servicePriorities && ps.priorities && ps.priorities.length > 0 ? 'Priority: ' : null}
                                                    {print_options.servicePriorities && ps.priorities && ps.priorities.length > 0 &&
                                                        ps.priorities.map(p => <span
                                                            key={p.id}>{p.name}{ps.priorities.length > 1 ? ', ' : ' '}</span>)}
                                                </h6>
                                                {print_options.status && ps.status
                                                    ? <br/>
                                                    : null}

                                                {print_options.serviceDateRanges && ps.date_ranges && ps.date_ranges.length
                                                    ? ps.date_ranges.map(dr => (
                                                        <div key={dr.id}>
                                                            Typical Timing:
                                                            {" "}
                                                            {moment(dr.from_date).format("MMMM Do")}
                                                            {" "}
                                                            -
                                                            {" "}
                                                            {moment(dr.to_date).format("MMMM Do")}
                                                        </div>
                                                    ))
                                                    : null}
                                            </td>
                                            <td width="20%" className={isMobile && "pr-10 text-right"}>
                                                {print_options.servicePrices
                                                    ? <Dollars amount={ps.price}/>
                                                    : null}
                                            </td>
                                        </tr>
                                        <hr className={invoice.proposal_services && invoice.proposal_services[last_invoice_ps] ? 'bottom0 mt0' : 'mb-10-mt-0'}/>
                                    </div>
                                )
                            })}
                    <div>
                        <tr style={{background: '#dddddd'}}>
                            <td className="full-width">
                                <p className="ml-15 mb-2-mt-2">Total price:</p>
                            </td>
                            <td width="20%"><Dollars amount={total}/></td>
                        </tr>
                        <tr>
                            <td className="full-width">
                                <p className="ml-15 mb-2-mt-2">Balance due:</p>
                            </td>
                            <td width="20%">
                                <div className="mr-10">
                                    <Dollars amount={this.renderBalanceDue(invoice, balanceDue)}/>
                                </div>
                            </td>
                        </tr>
                    </div>
                    </tbody>
                </Table>
            </div>
        )
    };

    componentWillMount = () => {
        if (this.props.params.token) {
            setTimeout(() => {
                this.setState({show: true})
            }, 500)
            this.props.actions.fetchInvoice(this.props.params.token, result => {
                    this.setState({
                        invoice: result,
                        customerViewOptions: JSON.parse(result.customer_view_options)
                    });

                    this.props.actions.getConversation(result.proposal_token, result => this.setState({
                        stickies: result
                    }));
                }
            );
            // fetch site and site map settings for an invoice
        } else {
            //should never get here because router should not mount this page and instead 404
            this.props.actions.addAlert({
                message: "Invoice not given. I can't go on!",
            })
        }
    };

    reloadInvoice = () => {
        if (this.props.params.token) {
            this.props.actions.fetchInvoice(this.props.params.token, result => {
                this.setState({
                    invoice: result,
                    showPaymentPopUp: false
                })
            })
        }
    };

    onMarkerClick = (ass) => {
        this.setState({selectedAsset: ass.asset_number, hoverAsset: ass});
    };

    componentWillReceiveProps = nextProps => {
        if (!this.props.invoice?.id && nextProps.invoice) {
            // fetch site and site map settings for an invoice
            this.setState({
                allDone: nextProps.invoice.accepted_date !== null
                    || nextProps.invoice.declined_date !== null
            });
            this.setState({
                accepted: nextProps.invoice.proposal_services
                    .filter(ps => ps.status === 'Accepted')
                    .map(ps => ps.proposal_service_id)
            });
            this.setState({
                declined: nextProps.invoice.proposal_services
                    .filter(ps => ps.status === 'Declined')
                    .map(ps => ps.proposal_service_id)
            });

            let include_maps = {};
            // include main site by default
            include_maps[0] = true;

            this.setState({include_maps: include_maps})
        }
        if (nextProps.customerSiteMaps) {
            let include_maps = {};
            // include main site by default
            include_maps[0] = true;
            const siteId = this.props.invoice?.site_id || nextProps.invoice?.site_id;
            const siteMaps = nextProps.customerSiteMaps[siteId];
            if (siteMaps && siteMaps.length > 0) {
                siteMaps.map(siteMap => {
                    include_maps[siteMap.id] = siteMap.include_when_print
                });

                this.setState({include_maps: include_maps})
            }
        }
    };

    markerPolygon = (ass) => {
        const polygon = ass.polygons.find(p => p.chosen)
        const points = polygon.polygon_points
        let averageLat = (polygon.type_polygon !== 'circle' && polygon.type_polygon !== 'polyLine')
            ? points.reduce((previousValue, currentValue, i) => {
            if (currentValue.lat) {
                if (i === 1) {
                    return previousValue.lat + currentValue.lat
                }
                return previousValue + currentValue.lat
            } else {
                if (i === 1) {
                    return previousValue.latitude + currentValue.latitude
                }
                return previousValue + currentValue.latitude
            }
        }) / points.length : ass.lat
        let averageLng = (polygon.type_polygon !== 'circle' && polygon.type_polygon !== 'polyLine')
            ? points.reduce((previousValue, currentValue, i) => {
            if (currentValue.lng) {
                if (i === 1) {
                    return previousValue.lng + currentValue.lng
                }
                return previousValue + currentValue.lng
            } else {
                if (i === 1) {
                    return previousValue.longitude + currentValue.longitude
                }
                return previousValue + currentValue.longitude
            }
        }) / points.length : ass.lng;
        if (polygon.type_polygon === 'polyLine') {
            const index = Math.round(points.length / 2) - 1;
            if (points.length % 2 !== 0) {
                averageLat = points[index].lat ? points[index].lat : points[index].latitude;
                averageLng = points[index].lng ? points[index].lng : points[index].longitude
            } else {
                if (points[0].lat) {
                    averageLat = (points[index].lat + points[index + 1].lat) / 2;
                    averageLng = (points[index].lng + points[index + 1].lng) / 2
                } else {
                    averageLat = (points[index].latitude + points[index + 1].latitude) / 2;
                    averageLng = (points[index].longitude + points[index + 1].longitude) / 2
                }
            }
        }

        return (
            <Marker
                position={{lat: averageLat, lng: averageLng}}
                icon={getLabel(ass.asset_label)}
                strokeColor={"#ffffff"}
                zIndex={1000}
                onClick={() => this.onMarkerClick(ass)}
            >
                {this.infoWindow(ass, averageLat, averageLng)}
            </Marker>
        )
    }

    renderLiveMap = (invoice, mapNum) => {
        if (invoice.proposal_services === undefined) {
            return null
        }

        let coordinates = [];
        getUniqueAssets(invoice).forEach(a => {
            if (a.polygons.length > 0) {
                const polygon = a.polygons.find(p => p.chosen);
                if (polygon.type_polygon === 'circle') {
                    let centerSfo = new window.google.maps.LatLng(polygon.polygon_points[0].latitude, polygon.polygon_points[0].longitude);
                    let circle = new window.google.maps.Circle({radius: polygon.radius, center: centerSfo});
                    let bounds = circle.getBounds()
                    let lat = Object.values(bounds)[0]
                    let long = Object.values(bounds)[1]
                    if (bounds && lat && long) {
                        coordinates.push(
                            {latitude: lat && lat[Object.keys(lat)[0]], longitude: long && long[Object.keys(long)[0]]},
                            {latitude: lat && lat[Object.keys(lat)[0]], longitude: long && long[Object.keys(long)[1]]},
                            {latitude: lat && lat[Object.keys(lat)[1]], longitude: long && long[Object.keys(long)[1]]},
                            {latitude: lat && lat[Object.keys(lat)[1]], longitude: long && long[Object.keys(long)[0]]})
                    }
                } else {
                    for (let p of polygon.polygon_points) {
                        coordinates.push({latitude: p.latitude, longitude: p.longitude});
                    }
                }
            } else {
                coordinates.push({latitude: a.lat, longitude: a.lng});
            }
        });

        const {hoverAsset} = this.state;
        const defaultCenter = {
            lat: invoice.site_lat,
            lng: invoice.site_lng,
        };
        const center = hoverAsset ? {lat: hoverAsset.lat, lng: hoverAsset.lng} : defaultCenter;
        return (
            <>
                <GoogleMapLoader
                    containerElement={
                        <div
                            id="map_view"
                            style={{
                                height: `${(window.innerHeight / 1.18) - 70}px`,
                                width: `${isMobile ? null : (window.innerWidth > 990) ? (window.innerWidth / 2.5) : (window.innerWidth / 1.1)}px`
                            }}
                        />
                    }
                    googleMapElement={
                        <GoogleMap
                            ref={(map) => {
                                const bounds = new window.google.maps.LatLngBounds();
                                coordinates && coordinates.map(ass => bounds.extend(new window.google.maps.LatLng(ass.latitude, ass.longitude)));
                                map && map.fitBounds(bounds)
                            }}
                            defaultZoom={mapNum === 1 ? invoice.site_zoom : 20}
                            mapTypeId={invoice.site_map_type || "hybrid"}
                            tilt={0}
                            center={center}
                        >
                            {
                                getUniqueAssets(invoice).map((ass, i) => {
                                    const polygon = ass.polygons.find(p => p.chosen);
                                    if (polygon && polygon.type_polygon !== 'marker') {

                                        const color = polygon.color ? colors[polygon.color] : colors[parseInt(ass.plant_color)];
                                        if (polygon.type_polygon === 'circle') {
                                            return (<Circle
                                                ref={(ref) => {
                                                    this [i] = ref;
                                                }}
                                                onClick={(e) => this.onMarkerClick(ass, this [i], 'circle')}
                                                defaultOpacity={1}
                                                defaultCenter={{
                                                    lat: polygon.polygon_points[0].latitude,
                                                    lng: polygon.polygon_points[0].longitude
                                                }}
                                                options={{
                                                    fillOpacity: ass.opacity ? 0.5 : 1,
                                                    strokeColor: color,
                                                    fillColor: color
                                                }}
                                                key={ass.asset_number}
                                                radius={polygon.radius}
                                            />)
                                        } else if (polygon.type_polygon === 'polyLine') {
                                            polygon.polygon_points.map(p => {
                                                p.lng = p.longitude;
                                                p.lat = p.latitude
                                            })
                                            return (<Polyline
                                                ref={(ref) => {
                                                    this [i] = ref;
                                                }}
                                                onClick={(e) => this.onMarkerClick(ass, this [i], 'polyline')}
                                                key={ass.asset_number}
                                                path={polygon.polygon_points}
                                                options={{
                                                    strokeOpacity: ass.opacity ? 0.5 : 1,
                                                    strokeColor: color,
                                                    fillColor: color
                                                }}
                                            />)
                                        } else if (polygon.type_polygon === 'rectangle' && checkRectangleCoordinates(polygon)) {
                                            return (<Rectangle
                                                ref={(ref) => {
                                                    this [i] = ref;
                                                }}
                                                onClick={(e) => this.onMarkerClick(ass, this [i], 'rectangle')}
                                                key={ass.asset_number}
                                                bounds={{
                                                    north: polygon.polygon_points[0].latitude,
                                                    south: polygon.polygon_points[3].latitude,
                                                    east: polygon.polygon_points[1].longitude,
                                                    west: polygon.polygon_points[0].longitude
                                                }}
                                                options={{
                                                    fillOpacity: ass.opacity ? 0.5 : 1,
                                                    strokeColor: color,
                                                    fillColor: color
                                                }}
                                            />)
                                        } else {
                                            polygon.polygon_points.map(p => {
                                                p.lng = p.longitude;
                                                p.lat = p.latitude
                                            })
                                            return (<Polygon
                                                ref={(ref) => {
                                                    this [i] = ref;
                                                }}
                                                onClick={(e) => this.onMarkerClick(ass, this [i], 'polygon')}
                                                path={polygon.polygon_points}
                                                key={ass.asset_number}
                                                options={{
                                                    fillOpacity: ass.opacity ? 0.5 : 1,
                                                    strokeColor: color,
                                                    fillColor: color
                                                }}
                                            />)
                                        }
                                    } else {
                                        return (
                                            <Marker
                                                key={ass.asset_number}
                                                position={{lat: ass.lat, lng: ass.lng}}
                                                icon={getBasicAssetMarker({
                                                    asset: ass,
                                                    highlighted: hoverAsset && hoverAsset.asset_number === ass.asset_number,
                                                    label: ass.asset_label.toString()
                                                })}
                                                draggable={false}
                                                onClick={() => this.onMarkerClick(ass)}
                                            >
                                                {this.infoWindow(ass)}
                                            </Marker>

                                        )
                                    }
                                })}
                            {getUniqueAssets(invoice).map((ass) => {
                                const polygon = ass.polygons.find(p => p.chosen);
                                if (polygon && polygon.type_polygon !== 'marker') {
                                    return (
                                        this.markerPolygon(ass)
                                    )
                                }
                            })}
                        </GoogleMap>
                    }
                />
            </>
        )
    };

    changePaymentMethod = (key, value) => {
        if (key === 'payByACH' && value) {
            this.setState({[key]: value, payByStripe: !value})
        } else if (key === 'payByStripe' && value) {
            this.setState({[key]: value, payByACH: !value})
        } else {
            this.setState({payByStripe: false, payByACH: false})
        }
    }

    renderServicesTable = (invoice, total, balanceDue) => {
        const {hoverAsset, toggle_bundle_services} = this.state;

        const print_options = JSON.parse(invoice.print_options)

        const handleDropDown = (bundleProposalId) => {
            let value = ''
            const findBundleService = toggle_bundle_services.indexOf(bundleProposalId)

            if (findBundleService < 0) {
                value = bundleProposalId
            } else {
                toggle_bundle_services.splice(findBundleService, 1)
                value = toggle_bundle_services
            }

            this.setState({
                toggle_bundle_services:
                    [...toggle_bundle_services, value]
            })
        }

        const handlePanelExpanded = (bundleProposalId) => {
            let filterBundleServices = toggle_bundle_services.filter(tbs => tbs === bundleProposalId).length > 0
            return filterBundleServices
        }
        return (
            <Table striped hover fill condensed className="header-fixed">
                <thead>
                <tr>
                    <th width="50%">Service</th>
                    <th width="30%">Timing</th>
                    <th width="20%">Price</th>
                </tr>
                </thead>
                <tbody>
                {invoice.proposal_services &&
                    invoice.proposal_services
                        .slice()
                        .sort((a, b) => a.service_no - b.service_no)
                        .map(ps => {
                            if (!this.state.servicesNotOffered && ps.status === "Not Offered") {
                                return <div className="skip_not_offered"/>
                            }

                            let rowClass = this.state.accepted.includes(ps.proposal_service_id) ? 'accepted' : '';
                            rowClass += this.state.declined.includes(ps.proposal_service_id) ? ' declined' : '';

                            const showServiceNotes = JSON.parse(invoice.print_options).proposalServiceNotes
                            const showServicePO = JSON.parse(invoice.print_options).servicePurchaseOrder

                            return (
                                <tr style={{backgroundColor: ps.assets && ps.assets.find(a => a.asset_number === this.state.selectedAsset) && '#f4ff93'}}
                                    key={ps.proposal_service_id} className={rowClass}>
                                    <td width="50%">
                                        <strong className="pointer" onClick={() => {
                                            const open = this.state.open !== ps.service_no ? ps.service_no : null;
                                            this.setState({open});
                                        }}>
                                            #{ps?.order_number_in_proposal}{" "}{ps.service_name}
                                        </strong>
                                        <br/>
                                        {showServicePO && ps.purchase_order ? <span>
                                            <strong>PO:</strong>
                                            {" "}{ps.purchase_order}
                                        </span> : null}
                                        {!this.state.declined.includes(ps.proposal_service_id) &&
                                            <Panel className="no-border pre-line" collapsible
                                                   expanded={this.state.open !== ps.service_no}>
                                                {ps.proposal_service_note}
                                                {ps.is_bundle_service &&
                                                    <>
                                                        <Panel className="no-border pre-line" collapsible
                                                               expanded={this.state.open !== ps.service_no}>
                                                            {ps.bundle_services.map((bs, index) => {
                                                                return (
                                                                    <React.Fragment key={index}>
                                                                        <strong
                                                                            className={`pointer ${toggle_bundle_services.includes(bs.id) ? "" : "blue-underline-text"}`}
                                                                            onClick={() => handleDropDown(bs.id)}>
                                                                            {bs.name}
                                                                        </strong>
                                                                        <Panel
                                                                            className="no-border pre-line"
                                                                            style={{padding: '0px'}}
                                                                            collapsible
                                                                            expanded={handlePanelExpanded(bs.id)}
                                                                        >
                                                                            {print_options.proposalServiceNotes &&
                                                                                <p className={toggle_bundle_services.includes(bs.id) ? "" : "bs-hidden-notes"}>{bs.notes}</p>}
                                                                            <div
                                                                                className='inline-assets mt3'>
                                                                                {bs.assets.sort((a, b) => sortAssetsLabels(a, b, 'asset_label')).map((a, index) => {
                                                                                    return (
                                                                                        <div key={index}
                                                                                             className='customer-assets__photos'>
                                                                                            {print_options.assetPhotosCustomerView && a?.photos.map(p => {
                                                                                                return (
                                                                                                    <img
                                                                                                        src={p.url}/>
                                                                                                )
                                                                                            })}
                                                                                            <small
                                                                                                onClick={() => this.onMarkerClick(a)}
                                                                                                className="pointer"
                                                                                                style={{color: (hoverAsset && hoverAsset.asset_number === a.asset_number ? '#FF9E00' : '')}}>
                                                                                                <PlantName
                                                                                                    plant={a.name}/>{" "}({a.label}){" "}
                                                                                            </small>
                                                                                        </div>
                                                                                    )
                                                                                })}
                                                                            </div>

                                                                        </Panel>

                                                                    </React.Fragment>
                                                                )
                                                            })}
                                                        </Panel>
                                                    </>}
                                            </Panel>}
                                        {!this.state.declined.includes(ps.proposal_service_id) && ps.assets &&
                                            ps.assets.sort((a, b) => sortAssetsLabels(a, b, 'asset_label'))
                                                .map((a, i) => (
                                                    <div key={a.asset_number}
                                                         className={this.state.assInline ? "inline-assets" : ""}>
                                                        {(print_options.assetPhotosCustomerView && a.photos.length > 0) ? a.photos.filter(p => p.cwo_and_proposal_display === true).sort((a, b) => a.order - b.order).map(p =>
                                                            <span onClick={() => this.showPhotoPopUp(a)}><span
                                                                className='text-success pointer'>
                                                                <img src={p.url}
                                                                     className="asset-thumbnail"/></span></span>) : ""}
                                                        <small onClick={() => this.onMarkerClick(a)}
                                                               className="pointer"
                                                               style={{color: (hoverAsset && hoverAsset.asset_number === a.asset_number ? '#FF9E00' : '')}}>
                                                            <PlantName plant={a.name}/>{" "}(#{a.asset_label}){" "}
                                                        </small>

                                                        <span>{a.plant_count > 1 ? ` ${a.plant_count} Trees` : null}</span>
                                                        <span>{print_options.dbh && a.dbh ? ` DBH: ${a.dbh}"` : null}</span>
                                                        <span>{a.plant_count > 1 || (this.state.dbh && a.dbh)
                                                            ? " "
                                                            : null}</span>
                                                        <span>{print_options.condition && a.condition_type ? ` Condition: "${a.condition_type}"` : null}</span>
                                                        <span>{print_options.lat && a.lat ? ` Latitude: ${a.lat}` : null}</span>
                                                        <span>{print_options.lng && a.lng ? ` Longitude: ${a.lng}` : null}</span>
                                                        <span>{print_options.height && a.height ? ` Height: ${a.height}` : null}</span>
                                                        <span>{print_options.width && a.width ? ` Width: ${a.width}` : null}</span>
                                                        <span>{print_options.stems && a.stems ? ` Stems: ${a.stems}` : null}</span>
                                                        <span>{print_options.factors && a.factors ? ` Factors: ${a.factors.map(f => ' ' + f.name)}` : null}</span>
                                                        <span>{print_options.locationDescription && a.location
                                                            ? ` Location: ${a.location}`
                                                            : null}</span>
                                                        <span>{print_options.locationDescription ? " " : null}</span>
                                                        <span>{print_options.assetNotes && a.note && a.note.length > 1
                                                            ? ` Note: ${a.note}  `
                                                            : null}</span>
                                                        <span>{print_options.assInline ? ((ps.assets.length - 1) == i) ? null : "," : ""}</span>
                                                    </div>
                                                ))}
                                    </td>
                                    <td width="30%">
                                        <h6 className="text-warning">
                                            {print_options.status && ps.status
                                                ? `Status: ${ps.status}`
                                                : null}
                                        </h6>
                                        {print_options.status && ps.status
                                            ? <br/>
                                            : null}

                                        <h6 className="text-success">
                                            {print_options.servicePriorities && ps.priorities && ps.priorities.length > 0 ? 'Priority: ' : null}
                                            {print_options.servicePriorities && ps.priorities && ps.priorities.length > 0 &&
                                                ps.priorities.map(p => <span
                                                    key={p.id}>{p.name}{ps.priorities.length > 1 ? ', ' : ' '}</span>)}
                                        </h6>
                                        {print_options.serviceDateRanges && ps.date_ranges && ps.date_ranges.length
                                            ? ps.date_ranges.map(dr => (
                                                <div key={dr.id}>
                                                    Typical Timing:
                                                    {" "}
                                                    {moment(dr.from_date).format("MMMM Do")}
                                                    {" "}
                                                    -
                                                    {" "}
                                                    {moment(dr.to_date).format("MMMM Do")}
                                                </div>
                                            ))
                                            : null}
                                    </td>
                                    <td width="20%">
                                        {print_options.servicePrices
                                            ? <Dollars amount={ps.price}/>
                                            : null}
                                    </td>
                                </tr>
                            )
                        })}
                <tr>
                    <td width="50%"/>
                    <td width="20%"/>
                    <td width="30%" className={"text-center"}>
                        <h4>
                            <strong>Total price: <Dollars amount={total}/></strong>
                        </h4>
                    </td>
                </tr>
                <tr>
                    <td width="50%"/>
                    <td width="20%"/>
                    <td width="30%" className={"text-center"}>
                        <h4>
                            <strong>Balance due: <Dollars amount={this.renderBalanceDue(invoice, balanceDue)}/></strong>
                        </h4>
                    </td>
                </tr>
                </tbody>
            </Table>
        )
    };

    renderProposalNote = invoice => {
        const showProposalNote = JSON.parse(invoice?.print_options).proposalCustomerNote

        if (showProposalNote) {
            return (
                invoice.proposal_customer_note && <Row className="avoid_page_breaking_inside">
                    <Col xs={12}>
                        <p>&nbsp;</p>
                        <em className='pre-line'>
                            {invoice.proposal_customer_note || ""}
                        </em>
                        <p>&nbsp;</p>
                    </Col>
                </Row>
            )
        }
    }

    renderPageHeader = (invoice, newMessages, stickiesIds) => {
        const {downloadInProgress} = this.state

        return (
            <>
                {this.state.invoiceTotal && this.renderProposalTotal(invoice)}
                <div>
                    <ButtonGroup className='flex-wrap space-evenly'>
                        <div className='image-button'>
                            <img alt='message' src={messageIcon} style={{height: 40}}
                                 onClick={() => {
                                     this.props.actions.readUserMessages(this.props.token, stickiesIds, () => {
                                         this.props.actions.fetchInvoice(this.props.params.token, result => {
                                             this.setState({
                                                 invoice: result
                                             });

                                             this.props.actions.getConversation(result.proposal_token, result => this.setState({
                                                 stickies: result
                                             }));
                                         })
                                         this.props.actions.showDock("notes")
                                     })
                                 }}/>
                            {newMessages && newMessages.length > 0 &&
                                <Badge variant="danger" className="message-notification-view"><span
                                    className="font13 text-white">{newMessages && newMessages.length}</span></Badge>}
                        </div>
                        {invoice && <div className='image-button'>
                            <img alt='card' src={cardIcon} style={{height: 40}}
                                 onClick={() => {
                                     const localStorageData = ["invoice_token", "link_token", "plaid_customer"]
                                     localStorageData.map(data => localStorage.removeItem(data))
                                     this.showPaymentPopUp(true)
                                 }}/>
                        </div>}
                        <div className='image-button'>
                            <Button
                                disabled={downloadInProgress}
                                onClick={() => {
                                    this.setState({downloadInProgress: true}, () => this.props.actions.downloadPdf(invoice,
                                        () => this.setState({downloadInProgress: false}),
                                        () => this.setState({downloadInProgress: false})
                                    ))
                                }}
                                className="proposal-button"
                            >
                                {downloadInProgress ?
                                    <div><MDSpinner size={20} className="mr-5"/>Processing...</div> : 'Download PDF'}
                            </Button>
                        </div>
                    </ButtonGroup>
                </div>
            </>

        )
    };

    renderPaymentPopUp = invoice => {
        return <PaymentModal
            show={this.state.showPaymentPopUp}
            reload={this.reloadInvoice}
            invoice={invoice}
            onHide={() => this.showPaymentPopUp(false)}
            changePaymentMethod={this.changePaymentMethod}
            payByACH={this.state.payByACH}
            payByStripe={this.state.payByStripe}
            newViewEnabled={this.state.customerViewOptions.newCustomerView}
        />

    }

    infoWindow = (ass, lat, lng) => {

        if (this.state.selectedAsset === ass.asset_number) {
            return (
                <InfoWindow
                    key={this.state.selectedAsset}
                    position={{lat: lat ? lat : ass.latitude, lng: lng ? lng : ass.longitude}}
                    onCloseclick={() => this.setState({selectedAsset: null})}>
                    <div className="info-window no_white-space">
                        <div>
                            <Row>
                                <Col md={8}>
                                    <b>#{ass.asset_label}{" "}<PlantName plant={ass.name}/></b><br/>
                                    {ass.dbh ? `DBH: ${ass.dbh}"` : ""}
                                    {ass.dbh ? <br/> : null}
                                </Col>
                            </Row>
                        </div>
                    </div>
                </InfoWindow>
            )
        }
    }

    renderCustomerInfoHeader = invoice => {
        return (
            <Row className={width600 ? null : isMobile ? "mt-13" : null}>
                <Col md={12}>
                    <Row>
                        <Col xs={width600 ? 3 : isMobile ? 4 : 4}
                             className={`${isMobile ? "justify-flex-start reset-right-padding" : "justify-flex-end"}`}>
                            <p className="text-primary"><strong className={isMobile && "font12"}>Customer:</strong></p>
                        </Col>
                        <Col xs={width600 ? 9 : isMobile ? 8 : 6} className='justify-flex-start reset-left-padding'><p
                            className={isMobile ? "text-primary font12" : "text-primary"}>{invoice.customer_full_name}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={width600 ? 3 : isMobile ? 4 : 4}
                             className={`${isMobile ? "justify-flex-start reset-right-padding" : "justify-flex-end"}`}>
                            <p className="text-primary"><strong className={isMobile && "font12"}>Site:</strong></p>
                        </Col>
                        <Col xs={width600 ? 9 : isMobile ? 8 : 6} className='justify-flex-start reset-left-padding'><p
                            className={isMobile ? "text-primary font12" : "text-primary"}>{invoice.site_name}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={width600 ? 3 : isMobile ? 4 : 4}><p
                            className={isMobile ? "text-primary justify-flex-start" : "text-primary justify-flex-end"}>
                            <strong
                                className={isMobile && "font12"}>Site Address:</strong></p></Col>
                        <Col xs={width600 ? 9 : isMobile ? 8 : 6} className='justify-flex-start reset-left-padding'><p
                            className={isMobile ? "text-primary text-left font12" : "text-primary text-left"}>{invoice.site_street}<br/>
                            {invoice.site_city_state}</p></Col>
                    </Row>
                    <Row>
                        <Col xs={width600 ? 3 : isMobile ? 4 : 4}><p
                            className={isMobile ? "text-primary justify-flex-start" : "text-primary justify-flex-end"}>
                            <strong
                                className={isMobile && "font12"}>Term:</strong></p></Col>
                        <Col xs={width600 ? 9 : isMobile ? 8 : 6} className='justify-flex-start reset-left-padding'><p
                            className={isMobile ? "text-primary text-left font12" : "text-primary text-left"}>{invoice.term_name}</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    };

    renderProposalTotal = invoice => {
        if (invoice.proposal_services === undefined) {
            return null
        }
        const total = invoice.proposal_services.reduce((total, ps) => {
            return total + (ps.status !== "Not Offered" ? ps.price : 0)
        }, 0);

        const accepted = invoice.proposal_services
            .filter(ps => this.state.accepted.includes(ps.proposal_service_id))
            .reduce((total, ps) => {
                return total + (ps.status !== "Not Offered" ? ps.price : 0)
            }, 0);

        return (
            <div className="header-price">
                {isMobile && <hr/>}
                <p className="text-danger"><strong>Total Price: </strong></p>
                <p className="text-danger price">{<Dollars amount={total + total * invoice.tax}/>}</p>
            </div>
        )
    };

    getNotAcceptedIds(invoice) {
        if (!invoice.proposal_services) {
            return [];
        }
        return invoice.proposal_services.filter(ps => !this.state.accepted.includes(ps.proposal_service_id))
            .map(ps => ps.proposal_service_id);
    }

    showPhotoPopUp = showPhotoPopUp => {
        this.setState({showPhotoPopUp});
    };

    renderPhotoPopUp = asset => (
        <div className="static-modal">
            <Modal
                id="customer-view-modal"
                style={{zIndex: 99999}}
                animation={false}
                show={true}
                onHide={() => this.showPhotoPopUp(null)}
            >
                <Modal.Header>
                    <Modal.Title>Photos for Tree #{asset.asset_label} {asset.name} </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Carousel>
                        {
                            asset.photos.filter(p => p.cwo_and_proposal_display === true).sort((a, b) => a.order - b.order).map((p, id) => (
                                    <Carousel.Item key={id}>
                                        <Image src={p.url} className='modal-image'/>
                                        <Carousel.Caption>
                                            <h4 style={{color: 'white', backgroundColor: 'black', opacity: 0.5}}>
                                                #{asset.asset_label} {asset.name}<br/>
                                                {asset.location}<br/>
                                                {asset.description}
                                            </h4>
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                )
                            )
                        }
                    </Carousel>
                </Modal.Body>

                <Modal.Footer>
                    <Button bsStyle="primary" onClick={() => this.showPhotoPopUp(null)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )

    render() {
        const {invoice, showPaymentPopUp, stickies, showPhotoPopUp, declined} = this.state;
        if (!invoice.client_name) {
            return <div className='loading-screen'><MDSpinner size={100}/></div>;
        }
        let total = invoice.proposal_services.reduce((total, ps) => {
            return total + (ps.status !== "Not Offered" ? ps.price : 0)
        }, 0);
        total = total + total * invoice.tax;

        const balanceDue = invoice.invoice_total - invoice.paid_amount


        let messages;
        let newMessages;
        const allMessages = stickies;
        const messagesReplies = stickies && stickies.map(sticky => sticky.replies).reduce((arr, b) => arr.concat(b), []);

        if (allMessages && allMessages.length > 0 && messagesReplies && messagesReplies.length === 0) {
            messages = allMessages;
            messages.sort((a, b) => a.id - b.id);
            const customerMessages = messages && messages.filter(x => x.created_by.includes(invoice.customer_full_name.split(" ")[1]));
            const lastCustomerMsgId = customerMessages?.length > 0 && customerMessages.pop().id;

            newMessages = messages.filter(message => message.id > lastCustomerMsgId && message.user_message_read !== true)
        } else if (allMessages && allMessages.length > 0 && messagesReplies && messagesReplies.length > 0) {
            messages = allMessages.concat(messagesReplies);
            messages.sort((a, b) => a.id - b.id);
            const customerMessages = messages && messages.filter(x => x.created_by.includes(invoice.customer_full_name.split(" ")[1]));
            const lastCustomerMsgId = customerMessages?.length > 0 && customerMessages.pop().id;

            newMessages = messages.filter(message => message.id > lastCustomerMsgId && message.user_message_read !== true)
        }

        const stickiesIds = stickies && stickies.map(sticky => sticky.id)
        const clientLogo = invoice ? invoice.customer_view_client_header : ''
        const newViewEnabled = this.state.customerViewOptions.newCustomerView
        return (
            <div id="invoice_payment" className={`${isMobile ? "customerView--Mob" : "customerView"}`}>
                {declined && <Dock type="notes" reload={false}>
                    <Conversation stickies={stickies}
                                  save={
                                      (note, callback) => {
                                          this.props.actions.addNote(invoice.proposal_token,
                                              note, (element, list) => this.setState({stickies: list}));
                                          callback();
                                      }}>
                        <Grid fluid>
                            <Row>
                                <Col md={12}>
                                    <p className="margin10">
                                        Please use this note field to note any important information regarding this
                                        invoice
                                    </p>
                                </Col>
                            </Row>
                        </Grid>
                    </Conversation>
                </Dock>}
                {showPhotoPopUp && this.renderPhotoPopUp(showPhotoPopUp)}
                <CustomerViewInvoiceHeader invoice={invoice} newMessages={newMessages}
                                           stickiesIds={stickiesIds}
                                           clientLogo={clientLogo}
                                           isMobile={isMobile}
                                           renderPageHeader={this.renderPageHeader}/>
                {invoice && <Row className="customerView__payment_row">
                    <div
                        className='vhCenter due-date'>{`DUE ${moment(invoice.due_date).format(defaultDateFormat)}`}
                    </div>
                    <div className="vhCenter balance">
                        <strong><Dollars amount={this.renderBalanceDue(invoice, balanceDue)}/></strong>
                    </div>
                    <div className='vhCenter bottom3'>
                        <Button
                            onClick={() => {
                                const localStorageData = ["invoice_token", "link_token", "plaid_customer"]
                                localStorageData.map(data => localStorage.removeItem(data))
                                this.showPaymentPopUp(true)
                            }}
                            className="proposal-button"
                        >
                            Review and pay
                        </Button>
                    </div>
                </Row>}
                {newViewEnabled && <Row className="customerView__tabs">
                    {isMobile
                        ? <Col xs={12} sm={12} className='menu-column'>
                            <CustomerViewMenu token={invoice.proposal_token}/>
                        </Col>
                        :
                        <Col>
                            <CustomerViewTabs token={invoice.proposal_token}/>
                        </Col>
                    }
                </Row>}
                <Row className='customerView__content'>
                    {isMobile
                        ? <Col xs={12} sm={12} md={isMobile ? 12 : 9}
                               className={`customerView__map ${this.state.show ? 'showMap' : ''}`}>
                            {this.renderLiveMap(invoice)}</Col>
                        : <Col md={5} className={`customerView__map ${this.state.show ? 'showMap' : ''}`}
                               style={{borderRightColor: "black"}}>
                            {this.renderLiveMap(invoice)}</Col>
                    }
                    <Col md={isMobile ? 12 : 7} sm={12} xs={12} id='#sheet-table'
                         className={isMobile ? "customerView__items" : "customerView__items sheet-table"}>
                        {this.state.invoiceCustomerNote &&
                            this.renderProposalNote(invoice)}
                        {invoice.paid_at && <img className='paid' style={{marginLeft: isMobile && 0}} src={paid}/>}
                        {isMobile ? this.rowFormatter(invoice, total, balanceDue) : this.renderServicesTable(invoice, total, balanceDue)}
                    </Col>
                </Row>
                {showPaymentPopUp && this.renderPaymentPopUp(invoice)}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        invoice: state.customerView.invoice,
        customerSiteMaps: state.siteMaps.siteMaps,
        client: state.auth
    }
};

const mapDispatchToProps = dispatch => {
    return {actions: bindActionCreators(Actions, dispatch)}
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerViewInvoice)
