import React from 'react';
import PropTypes from "prop-types"
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from "./AssetEditorModalApi";
import {
    Button,
    Clearfix,
    Col,
    ControlLabel,
    Form,
    FormControl,
    FormGroup,
    Label,
    Modal,
    Panel,
    Row,
    Table
} from "react-bootstrap";
import ResourceComponent from "../../../components/ResourceComponent";
import {checkRectangleCoordinates, colors, mapToApi, select} from "../../../common/commonHandlers";
import Select from "react-select";
import ColorCheckbox from "../../Scheduler/ColorCheckbox";
import moment from "moment/moment";
import SiteHazardForm from "./SiteHazardForm";
import Dropzone from "react-dropzone";
import {Link} from "react-router";
import Dollars from "../../../components/Dollars";
import PlantStatsForm from "./PlantStatsForm";
import {CirclePicker} from "react-color";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import PlantName from "../../../components/PlantName";
import './AssetEditorModal.scss'
import NewAttachment from "../../../components/image/Attachment/NewAttachment";
import Attachment from "../../../components/image/Attachment";
import AudioRecorder from "./AudioRecorder";
import {addAlert} from "../../App/actions";
import AssetWatermarkModal from "./AssetWatermarkModal";
import _ from "lodash";

const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

const TIMESTAMP_OPTIONS = [
    {value: "no_date", label: "No Date"},
    {value: "created_at_dt", label: "Date Uploaded [DateTime]"},
    {value: "created_at_d", label: "Date Uploaded [Date]"},
    {value: "created_at_t", label: "Date Uploaded [Time]"},
    {value: "taken_at_dt", label: "Date Taken [DateTime]"},
    {value: "taken_at_d", label: "Date Taken [Date]"},
    {value: "taken_at_t", label: "Date Taken [Time]"},
]

const filesQueues = {}

class AssetEditorModal extends ResourceComponent {

    state = {
        resource: {
            asset_number: null,
            label: '',
            plant_count: 1,
            plant: null,
            disabled: false,
            opacity: !!this.props.asset.polygons,
            note: '',
            description: '',
            current_stats: {},
            latitude: null,
            longitude: null,
            asset_object_hazards: [],
            images: [],
            polygons: [],
            proposal_services: [],
            image_order: ''
        },
        plants: [],
        allPlants: [],
        hazards: [],
        loaded: false,
        loadingAsset: true,
        modified: false,
        validationError: false,
        showModal: false,
        uploading: false,
        newHazards: [],
        onlyFirstImage: false,
        timestamp: "no_date",
        imagesVisibilityFromDB: [],
        watermarkModal: {
            show: false,
            created_at: null,
            taken_at: null,
            saving: false
        },
        removeWatermarkLoading: false,
        initialPolygons: []
    };

    reloadDataImage = () => {
        this.doSave()
        this.reloadData()
    }

    reloadData = (asset_id, prev_asset) => {
        const {siteId} = this.props;
        let {asset} = this.props;
        let {resource} = this.state;
        const id = asset_id || resource.id || asset.id;
        asset = this.props.assets?.find(a => a.id === id) || asset
        if ((resource?.plant_name && resource?.plant_name !== prev_asset?.plant_name) || (resource?.label && resource?.label !== prev_asset?.label)) {
            this.props.refreshList(resource)
        }
        let assetType = !asset?.polygons || asset.polygons?.length <= 0 ? 'marker' :
            Array.isArray(asset.polygons) ? asset.polygons.find(p => p.chosen).type_polygon : asset.polygons.type_polygon
        resource.images = []
        this.props.actions.load(assetType, result => this.setState(result, () => {
            if (!id) {
                this.props.actions.loadNextNumber(siteId, result => {
                    resource = {...resource, ...asset, ...result};
                    if (this.props.selectedAssetVal) {
                        resource.polygons.circuit = this.props.selectedAssetVal.circuit
                        resource.polygons.field = this.props.selectedAssetVal.field
                    }
                    this.setState({
                        resource,
                        loaded: true,
                        loadingAsset: false,
                        assetType: assetType,
                        showVoiceRecorder: (isMobile && asset.enableAI)
                    });
                });
            } else {
                this.props.actions.loadAsset(siteId, id, asset => {
                    asset.polygons = asset.polygons.filter(p => p.chosen)
                    if (this.props.selectedAssetVal) {
                        asset.polygons.circuit = this.props.selectedAssetVal.circuit
                        asset.polygons.field = this.props.selectedAssetVal.field
                    }
                    if (asset.image_order) {
                        let order = JSON.parse(asset.image_order);
                        asset.images = asset.images.map(img => {
                            img.order = order.findIndex((o) => o === img.id)
                            return img
                        })
                    } else {
                        asset.images = asset.images.map((img, index) => {
                            img.order = index
                            return img
                        })
                    }
                    if (asset.images) {
                        asset.images = asset.images.sort((a, b) => a.order - b.order)
                    }
                    if (asset.asset_object_hazards) {
                        asset.asset_object_hazards = asset.asset_object_hazards.map(ah => {
                            if (ah.image_order) {
                                let order = JSON.parse(ah.image_order);
                                ah.images = ah.images.map(img => {
                                    img.order = order.findIndex((o) => o === img.id || o === img.parent_id)
                                    return img
                                })
                            } else {
                                ah.images = ah.images.map((img, index) => {
                                    img.order = index
                                    return img
                                })
                            }
                            ah.images = ah.images.sort((a, b) => a.order - b.order)
                            return ah
                        })
                    }
                    const headerSettings = JSON.parse(asset.header_settings);
                    const imagesVisibilityFromDB = new Map();
                    if (headerSettings?.onlyFirstImage) {
                        const imagesState = JSON.parse(asset.images_state);
                        imagesState.forEach(i => imagesVisibilityFromDB.set(i.id, i.visible))
                    } else {
                        asset.images.forEach(i => imagesVisibilityFromDB.set(i.id, i.cwo_and_proposal_display))
                    }
                    let invalidRectangleCoordinates = false
                    if (assetType === "rectangle") {
                        const polygon = asset.polygons.find(p => p.chosen)
                        if (!checkRectangleCoordinates(polygon)) {
                            invalidRectangleCoordinates = true
                        }
                    }
                    this.setState({
                        assetType: assetType,
                        resource: {...resource, current_stats: {}, ...asset},
                        loaded: true,
                        invalidRectangleCoordinates,
                        loadingAsset: false,
                        onlyFirstImage: headerSettings ? headerSettings.onlyFirstImage : this.state.onlyFirstImage,
                        timestamp: headerSettings ? headerSettings.timestamp : this.state.timestamp,
                        imagesVisibilityFromDB: imagesVisibilityFromDB,
                        removeWatermarkLoading: false
                    })
                });
            }
        }));
    }

    checkCoordinates = () => {
        const {resource} = this.state
        const {polygons} = resource
        if ((!resource.latitude && resource.latitude !== 0) || (!resource.longitude && resource.longitude !== 0)) {
            this.setState({showModal: true})
        }
        if (polygons.length > 0) {
            (polygons.map(polygon => polygon.polygon_points.map(pp => {
                if ((!pp.lat && pp.lat !== 0) || (!pp.lng && pp.lng !== 0)) {
                    this.setState({showModal: true})
                }
            })))
        }
    }

    componentDidMount() {
        this.reloadData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {resource} = this.state
        if (prevState.resource.id !== this.state.resource.id) {
            this.setState({initialPolygons: _.cloneDeep(resource.polygons)})
            this.state.newHazards.map((ah) => {
                const {hazards} = this.state
                const hazard_id = hazards.find(h => h.label.replace(/\s/g, '').toLowerCase().includes(ah["name (string)"]?.replace(/\s/g, '').toLowerCase()))?.value
                if (hazard_id) {
                    const siteHazard = {
                        key: resource.asset_object_hazards.length,
                        hazard_id: hazard_id,
                        images: [],
                        image_order: "[]"
                    };
                    this.state.resource.asset_object_hazards.push(siteHazard);
                    this.setState({resource: this.state.resource, siteHazard: siteHazard});
                }
            })
        }

        if (prevState.onlyFirstImage !== this.state.onlyFirstImage) {
            this.handleHideImages()
        }

        if (!_.isEqual(prevState.resource.images, this.state.resource.images)) {
            let {images, image_order} = this.state.resource
            if (image_order) {
                let order = JSON.parse(image_order);
                images = images.map(img => {
                    img.order = order.findIndex((o) => o === img.id || o === img.parent_id)
                    return img
                })
            } else {
                images = images.map((img, index) => {
                    img.order = index
                    return img
                })
            }
            if (images) {
                images = images.sort((a, b) => a.order - b.order)
            }
            this.setState({resource: {...resource, images}})
        }
    }

    handleHideImages = (autoSave) => {
        const {resource, onlyFirstImage, imagesVisibilityFromDB} = this.state;

        const updatedResourceImages = [...resource.images].map((i, index) => {
            i.cwo_and_proposal_display = index === 0;
            return i;
        })

        const newState = {
            ...resource,
            images: onlyFirstImage ? updatedResourceImages : [...resource.images].map(i => {
                i.cwo_and_proposal_display = imagesVisibilityFromDB.get(i.id);
                return i;
            })
        }

        this.setState({resource: newState}, () => {
            if (autoSave) {
                this.setState({removeWatermarkLoading: true})
                this.doSave(() => this.reloadData())
            }
        })
    }

    reorderHazardImages = (imageId, hazardId, direction) => {
        const {resource} = this.state
        const {asset_object_hazards} = this.state.resource
        let assetHazard = asset_object_hazards.find(ah => ah.id === hazardId)
        let assetHazardIndex = asset_object_hazards.indexOf(assetHazard)
        const length = assetHazard.images.length
        let nextImgIndex
        let newImages
        let reordered = false
        if (direction === "right") {
            newImages = assetHazard.images.map((img, i) => {
                if (img.id === imageId) {
                    if (img.order < length - 1) {
                        reordered = true
                        nextImgIndex = i + 1
                        img.order += 1
                    }
                }
                return img;
            });
            if (reordered) {
                let nextImage = assetHazard.images[nextImgIndex]
                nextImage.order -= 1
            }
        } else if (direction === "left") {
            newImages = assetHazard.images.map((img, i) => {
                if (img.id === imageId) {
                    if (img.order > 0) {
                        reordered = true
                        nextImgIndex = i - 1
                        img.order -= 1
                    }
                }
                return img;
            });
            if (reordered) {
                let nextImage = assetHazard.images[nextImgIndex]
                nextImage.order += 1
            }
        }
        assetHazard = {...assetHazard, images: newImages.sort((a, b) => a.order - b.order)}
        assetHazard = {...assetHazard, image_order: JSON.stringify(assetHazard.images.map(i => i.id))}
        asset_object_hazards[assetHazardIndex] = assetHazard
        this.setState({...resource, asset_object_hazards})
    }

    reorderImages = (imageId, direction) => {
        const clonedResource = _.cloneDeep(this.state.resource);
        const images = clonedResource.images;

        const currentIndex = images.findIndex((img) => img.id === imageId);

        if (currentIndex === -1) {
            return;
        }

        const newIndex = direction === "right" ? currentIndex + 1 : currentIndex - 1;

        if (newIndex < 0 || newIndex >= images.length) {
            return;
        }

        const reorderedImages = _.cloneDeep(images);
        [reorderedImages[currentIndex], reorderedImages[newIndex]] = [reorderedImages[newIndex], reorderedImages[currentIndex]];

        clonedResource.images = reorderedImages.map((img, index) => ({
            ...img,
            order: index,
        }));

        clonedResource.image_order = JSON.stringify(clonedResource.images.map(i => i.id))

        this.setState(
            {resource: clonedResource},
            () => this.state.onlyFirstImage && this.handleHideImages()
        )

    }

    onClose = () => {
        const {modified} = this.state;
        if (modified) {
            const choice = window.confirm('You have unsaved changes. Are you sure you want to leave?');
            if (choice) {
                this.props.onClose(this.state.resource);
            }
        } else {
            this.props.onClose(this.state.resource);
        }
    };

    updateModified = () => {
        this.setState({modified: true}, () => this.checkCoordinates());
    };

    getAssetValue = (resource, assetTypes) => {

        return (resource.polygons && resource.polygons[0] && !resource.polygons[0]._destroy) ||
        (!resource.id && !(resource.polygons && !resource.polygons.type_polygon))
            ? resource.polygons[0] ? {label: resource.polygons[0].type_polygon} : {label: resource.polygons.type_polygon} : assetTypes[0]
    }

    doSave = (callback) => {
        const {resource} = this.state;
        const {siteId, asset} = this.props;
        const id = resource.id || asset.id;

        if (resource.polygons && resource.polygons[0] && resource.polygons[0].polygon_points) {
            resource.polygons[0].polygon_points_attributes = resource.polygons[0].polygon_points.map((val) => ({
                _destroy: val._destroy,
                id: val.id,
                latitude: val.lat,
                longitude: val.lng,
            }))
            if (id) {
                const isPolygonSaved = resource.polygons[0]
                if (isPolygonSaved) {

                    const savedPolygon = resource.polygons[0]
                    if (savedPolygon.chosen) {
                        if (this.props.openedInMap && this.props.selectedAssetVal && this.props.selectedAssetVal !== undefined) {
                            savedPolygon.circuit = this.props.selectedAssetVal.circuit
                            savedPolygon.field = this.props.selectedAssetVal.field
                        }

                    }

                    savedPolygon.color = resource.polygons[0].color
                    savedPolygon.chosen = true
                    const initialPolygon = this.state.initialPolygons[0];
                    initialPolygon.polygon_points_attributes = resource.polygons[0].polygon_points_attributes

                    if (!_.isEqual(initialPolygon, savedPolygon)) {
                        this.props.actions.changePolygon(id, savedPolygon)
                    }
                } else {
                    const {id, ...newPolygon} = resource.polygons[0]
                    newPolygon.chosen = true
                    newPolygon.asset_object_id = this.props.asset.id
                    this.props.actions.saveNewPolygon(id, newPolygon)
                }
            } else {
                resource.polygons = resource.polygons[0]
            }
        }
        resource.header_settings = JSON.stringify({
            timestamp: this.state.timestamp,
            onlyFirstImage: this.state.onlyFirstImage
        })
        resource.asset_object_hazards = resource.asset_object_hazards.map(ah => {
            ah.image_order = JSON.stringify(ah.images.map(i => i.id))
            return ah
        })
        const tempImages = [];
        resource.images.forEach(i => {
            if (i.parent_image) {
                tempImages.push(i.parent_image)
                delete i.parent_image
            }
            tempImages.push(i)
        })
        resource.image_order = JSON.stringify(tempImages.map(i => i.id))
        resource.images = tempImages;

        if (resource?.images?.length > 0) {
            this.props.actions.hideImages(
                siteId,
                resource.id,
                tempImages.filter(i => !i.cwo_and_proposal_display).map(i => i.id),
                this.state.onlyFirstImage,
                () => {
                    this.props.actions.saveAsset(siteId, resource, (asset) => {
                        this.setState({
                            modified: false,
                            resource: {...resource, ...asset}
                        }, () => callback && callback());
                    });
                }
            )
        } else {
            this.props.actions.saveAsset(siteId, resource, (asset) => {
                this.setState({modified: false, resource: {...resource, ...asset}}, () => callback && callback());
            });
        }
    };

    renderProposalColumn = (isCrewUser, lockedCrewEditAccess, s, isPriceColumn = false) => {
        const {client, roles} = this.props
        const component = isPriceColumn ? <Dollars amount={s.service_total}/> :
            <Link to={`/mapview/${s.proposal_id}`}>{s.proposal_no}</Link>
        const value = isPriceColumn ? <Dollars amount={s.service_total}/> : <span>{s.proposal_no}</span>

        const userRole = roles?.length === 1 && roles[0]
        const isCrewGroupRoles = roles?.every(r => r === 'crew' || r === 'subcontractor' || r === 'technician')

        if (!isCrewGroupRoles || (isCrewGroupRoles && !lockedCrewEditAccess)) {
            return component
        } else if (client.hidden_prices && (userRole === 'subcontractor' && isPriceColumn && client.display_subcontractor_price)) {
            return value
        } else if (isCrewGroupRoles && !client.hidden_prices && (userRole === 'subcontractor' && isPriceColumn && !client.display_subcontractor_price)) {
            return '-'
        } else if (isCrewGroupRoles && lockedCrewEditAccess && !client.hidden_prices) {
            return value
        } else if (isCrewGroupRoles && client.hidden_prices) {
            return '-'
        }
    }
    checkUniqueLabel = () => {
        const {siteId} = this.props;
        let {resource} = this.state;
        this.props.actions.checkUniqueLabel(siteId, resource, unique => {
            this.setState({invalidLabel: !unique})
        });
        this.updateModified()
    }

    setRecordedFile = (file) => {
        this.setState({isVoiceProcess: true})
        this.props.actions.processVoice(file, res => {

            const {resource, allPlants} = this.state
            const plant_id = allPlants.find(p => {
                const formattedLabel = p.label.replace(/\s/g, '').toLowerCase();
                const searchTerm = res["tree_species (string)"]?.replace(/\s/g, '').toLowerCase();
                return formattedLabel.startsWith(searchTerm);
            })?.value;
            if (plant_id) {
                this.props.actions.fetchConditions(conditions => {
                    this.props.actions.fetchFactors(plant_id, plant => {
                        const factors = plant.plant_factors.filter(f => res["tree_stats"]["factors"].some(factor => factor["name (string)"] && f.label.toLowerCase().includes(factor["name (string)"].toLowerCase())))
                        const conditionTypeSearchText = res["tree_stats"]["condition (string)"]?.toLowerCase().replace(/grade/g, '').replace(/\s/g, '')
                        const conditionTypeComparer = conditionTypeSearchText?.length === 1 ?
                            c => c.name.replace(/\s/g, '').toLowerCase().startsWith(conditionTypeSearchText)
                            : c => c.name.replace(/\s/g, '').toLowerCase().includes(conditionTypeSearchText)
                        this.setState({
                            resource: {
                                ...resource, description: res["location (string)"], note: res["notes (string)"],
                                plant_id: plant_id,
                                current_stats: {
                                    ...resource.current_stats,
                                    dbh: res["tree_stats"]["dbh (number)"],
                                    height: res["tree_stats"]["height (number)"],
                                    width: res["tree_stats"]["width (number)"],
                                    condition_type: conditions.find(conditionTypeComparer)?.id,
                                    factors: mapToApi(factors),
                                },
                            },
                            newHazards: res["asset_hazards"],
                            isVoiceProcess: false
                        }, () => {
                            this.doSave(() => {
                                this.props.onSave(this.state.resource, false);
                            })
                            this.setState({showVoiceRecorder: false})
                        })
                    })
                })
            } else if (!res.failed) {
                this.props.actions.fetchConditions(conditions => {
                    this.setState({
                        resource: {
                            ...resource, description: res["location (string)"], note: res["notes (string)"],
                            plant_id: plant_id,
                            current_stats: {
                                ...resource.current_stats,
                                dbh: res["tree_stats"]["dbh (number)"],
                                height: res["tree_stats"]["height (number)"],
                                width: res["tree_stats"]["width (number)"],
                                condition_type: conditions.find(c => c.name.replace(/\s/g, '').toLowerCase().includes(res["tree_stats"]["condition (string)"]?.replace(/\s/g, '').toLowerCase()))?.id,
                            },
                        },
                        newHazards: res["asset_hazards"],
                        isVoiceProcess: false,
                        showVoiceRecorder: false
                    })
                })
            } else {
                this.setState({showVoiceRecorder: false})
                alert("Cannot process voice correctly.")
            }
        })
    }

    handleAddWatermark = (image) => {
        this.setState({
            watermarkModal: {
                show: true,
                image_id: image.id,
                created_at: image.created_at,
                taken_at: image.taken_at,
                saving: false
            }
        })
    }

    handleWatermarkModalSave = (selectedWatermark) => {
        if (this.state?.watermarkModal?.image_id && this.props?.asset?.id) {
            this.setState({watermarkModal: {...this.state.watermarkModal, saving: true}})
            this.props.actions.addWatermarkToImage({
                image_id: this.state.watermarkModal.image_id,
                asset_id: this.state.resource.id,
                selectedWatermark: selectedWatermark,
                timezone: this.props.client.client_timezone || moment.tz.guess()
            }, (image) => {
                this.setState({
                    watermarkModal: {
                        show: false,
                        image_id: null,
                        created_at: null,
                        taken_at: null,
                        saving: false
                    }
                })

                const updatedImages = this.state.resource.images.map(i =>
                    i.id === image.parent_id ? image : i
                );

                this.setState({
                    resource: {
                        ...this.state.resource,
                        images: updatedImages
                    }
                });
            })
        }
    }

    asyncUploadAssetAttachment = async (id, clientTimezone, timestampType, asHidden, acceptedFiles) => {
        return new Promise((resolve, reject) => {
            this.props.actions.uploadAssetAttachment(id, clientTimezone, timestampType, asHidden, acceptedFiles, (result) => {
                    resolve(result);
                },
                error => {
                    reject(error)
                })
        });
    }

    render() {
        const {
            asset,
            fullScreen,
            siteId,
            isCrewUser,
            lockedCrewEditAccess,
            isSubcontractor,
            client,
            oldAttachment,
            translateAsset,
        } = this.props;
        const {removeImage} = this.props.actions;
        const {
            showModal,
            validationError,
            resource,
            plants,
            allPlants,
            assetType,
            hazards,
            siteHazard,
            loaded,
            loadingAsset,
            showPicker,
            coordinateVisible,
            uploading,
            onlyFirstImage,
            timestamp,
            watermarkModal,
            invalidRectangleCoordinates
        } = this.state;


        const siteHazardFormProps = {
            resource: siteHazard,
            close: r => {
                this.setState({siteHazard: null});
            },
            save: r => {
                const index = resource.asset_object_hazards.findIndex(x => x.key === r.key);
                if (index > -1) {
                    resource.asset_object_hazards[index] = {...resource.asset_object_hazards[index], ...r}
                } else {
                    resource.asset_object_hazards.push(r);
                }
                this.setState({resource, siteHazard: {...siteHazard, ...r}}, this.doSave(() => {
                    this.setState({siteHazard: this.state.resource.asset_object_hazards.find(x => x.key === r.key)});
                }));
            },
            hazards: hazards,
            uploadAttachment: this.props.actions.uploadHazardAttachment,
            delete: r => {
                resource.asset_object_hazards.find(x => x.key === r.key)._destroy = true;
                this.setState({resource, siteHazard: null});
            },
            saveHazard: hazard => {
                hazards.push(hazard);
                this.setState({hazards});
            }
        };

        const formatOptionLabel = ({value, label, customAbbreviation}) => (
            <div style={{display: "flex"}}>
                <PlantName plant={label}/>
                <div style={{marginLeft: "10px", color: "#ccc"}}>
                    {customAbbreviation}
                </div>
            </div>
        );

        const closestTo = (arr, key, direction) => {
            const keyIndex = arr.indexOf(key)
            let indexToReturn = null

            if (direction === 'prev') {
                indexToReturn = keyIndex > 0 ? keyIndex - 1 : arr.length - 1;
            } else if (direction === 'next') {
                indexToReturn = keyIndex < arr.length - 1 ? keyIndex + 1 : 0;
            }

            return arr[indexToReturn];
        }

        const assetTypes = [
            {value: 'marker', label: 'marker'},
            {value: 'polyLine', label: 'polyLine'},
            {value: 'circle', label: 'circle'},
            {value: 'polygon', label: 'polygon'},
            {value: 'rectangle', label: 'rectangle'}
        ]
        const plant = select(allPlants, resource.plant_id);
        const plantName = translateAsset && resource.translation?.name ? resource.translation.name : plant?.label
        const getImages = () => {
            const parentIds = resource.images.filter(i => i.parent_id).map(i => i.parent_id);
            return resource.images
                .filter(i => !parentIds.includes(i.id))
                .sort((a, b) => a.order - b.order)
                .filter(i => lockedCrewEditAccess ? i.cwo_and_proposal_display === true : i);
        };
        return (
            <Modal
                id={fullScreen && asset && "myModal"}
                bsSize="large"
                animation={false}
                className={fullScreen && asset && "asset-modal"}
                show={true}
                onHide={this.onClose}
                backdrop={!uploading}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{resource.id ? 'Editing ' + resource.label + ' - ' : 'New asset'}
                        {plant && <PlantName plant={plantName}/>}
                        {(resource.asset_moved === "original" || resource.asset_moved === "copy") &&
                            <span className='text-orange'>*</span>}
                        {resource.asset_moved === "original" &&
                            <p className='font12 text-gray no-margin'><span className='text-orange'>*</span>{' '}Asset
                                moved from other customer</p>
                        }
                        {resource.asset_moved === "copy" &&
                            <p className='font12 text-gray no-margin'><span className='text-orange'>*</span>{' '}Asset
                                moved to other customer</p>
                        }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={() => {
                    }}>
                        <Row className="vcenter mt10">
                            <Col md={2}>
                                <FormGroup bsSize="sm" className='bottom5'>
                                    <ControlLabel className="small-margin">Asset #</ControlLabel>
                                    <Label bsStyle="info">{resource.asset_number}</Label>
                                </FormGroup>
                                {resource.id &&
                                    <FormGroup bsSize="sm">
                                        <ControlLabel className="small-margin">Asset ID:</ControlLabel>
                                        <span bsStyle="info">{resource.id}</span>
                                    </FormGroup>}
                            </Col>
                            <Col md={2}>
                                <FormGroup bsSize="sm" validationState={this.state.invalidLabel ? 'error' : ''}>
                                    <ControlLabel>Label</ControlLabel><br/>
                                    <FormControl
                                        disabled={!resource.id || lockedCrewEditAccess}
                                        type="text"
                                        name="label"
                                        placeholder="label"
                                        value={resource.label}
                                        onChange={e => this.updateResourceAttr(e, this.checkUniqueLabel)}
                                    />
                                    {this.state.invalidLabel && <p className='text-danger'>Label already exists</p>}
                                </FormGroup>
                            </Col>

                            <Col md={2}>
                                <FormGroup bsSize="sm">
                                    <ControlLabel># Plants</ControlLabel><br/>
                                    <FormControl
                                        placeholder="# Plants"
                                        name="plant_count"
                                        pattern="\d*"
                                        disabled={lockedCrewEditAccess}
                                        min={1}
                                        inputmode='decimal'
                                        type='number'
                                        value={resource.plant_count}
                                        onChange={e => this.updateResourceAttr(e, this.updateModified)}
                                    />
                                </FormGroup>
                            </Col>

                            <Col md={4}>
                                <FormGroup bsSize="sm">
                                    <ControlLabel>Plant</ControlLabel>
                                    <Select
                                        styles={{
                                            container: (provided) => ({
                                                ...provided,
                                                border: 1 + "px solid",
                                                borderRadius: 4 + "px",
                                                borderColor: validationError ? "#FF0221" : "#aaa"
                                            })
                                        }}
                                        formatOptionLabel={formatOptionLabel}
                                        className="Select"
                                        classNamePrefix="select"
                                        value={plant}
                                        options={plants}
                                        isDisabled={lockedCrewEditAccess}
                                        onChange={this.selectResourceAttr('plant_id', () => {
                                            resource.id ? this.updateModified() : this.doSave(() => {
                                                this.props.onSave(this.state.resource, false);
                                            });
                                        })}
                                        placeholder="Select Plant"
                                    />
                                </FormGroup>
                            </Col>

                            <Col md={2}>
                                <FormGroup bsSize="sm">
                                    <ColorCheckbox value={resource.removed}
                                                   label="Removed"
                                                   disabled={lockedCrewEditAccess}
                                                   onChange={this.selectCheckboxAttr('removed', this.updateModified)}/>
                                </FormGroup>
                                <FormGroup bsSize="sm">
                                    <ColorCheckbox value={resource.disabled}
                                                   label="Disabled"
                                                   disabled={lockedCrewEditAccess}
                                                   onChange={this.selectCheckboxAttr('disabled', this.updateModified)}/>
                                </FormGroup>

                                {loaded && <FormGroup bsSize="sm">
                                    <ColorCheckbox value={resource.opacity}
                                                   disabled={assetType === 'marker' && !resource.semi_removed}
                                                   label="Opacity"
                                                   onChange={val => {
                                                       this.setState({
                                                           updateOpacity: {
                                                               id: resource.id,
                                                               val: val
                                                           }
                                                       })
                                                       this.selectCheckboxAttr('opacity', this.updateModified)(val)
                                                   }}/>
                                </FormGroup>}
                            </Col>

                        </Row>

                        <Row>
                            <Col md={6}>
                                <FormGroup bsSize="small">
                                    <ControlLabel>Note</ControlLabel>
                                    <FormControl
                                        componentClass="textarea"
                                        rows={3}
                                        name="note"
                                        disabled={lockedCrewEditAccess}
                                        placeholder="Note"
                                        value={translateAsset ? (resource.translation?.note || resource.note) : resource.note}
                                        onChange={e => this.updateResourceAttr(e, this.updateModified)}
                                    />
                                </FormGroup>
                            </Col>

                            <Col md={6}>
                                <FormGroup bsSize="small">
                                    <ControlLabel>Location</ControlLabel>
                                    <FormControl
                                        componentClass="textarea"
                                        rows={3}
                                        name="description"
                                        placeholder="Description"
                                        disabled={lockedCrewEditAccess}
                                        value={translateAsset ? (resource.translation?.description || resource.description) : resource.description}
                                        onChange={e => this.updateResourceAttr(e, this.updateModified)}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        {asset.id && resource.asset_number && <FormGroup bsSize="sm">
                            <ControlLabel>Marker type</ControlLabel><br/>
                            <Select
                                className="Select"
                                classNamePrefix="select"
                                isDisabled
                                value={this.getAssetValue(resource, assetTypes)}
                                options={assetTypes}
                                onChange={e => {
                                    if (e.value === 'marker') {
                                        if (resource.polygons[0]) {
                                            resource.polygons[0].type_polygon = 'marker'
                                        }
                                        this.setState({resource});
                                    } else if (resource.polygons[0] && resource.polygons[0].type_polygon === 'marker') {
                                        resource.polygons[0].type_polygon = e.value
                                        this.setState({resource});
                                    } else {
                                        const savedPolygon = resource.polygons.find(p => p.type_polygon === e.value)
                                        const oldResource = {...resource}
                                        if (!savedPolygon && e.value === 'polyLine') {
                                            resource.polygons = [{
                                                chosen: true,
                                                color: 1,
                                                id: resource.polygons[0] && resource.polygons[0].id,
                                                type_polygon: 'polyLine',
                                                circuit: '365.22',
                                                field: null,
                                                polygon_points: [{
                                                    lat: resource.latitude,
                                                    lng: resource.longitude
                                                }, {
                                                    lat: resource.latitude + 0.001,
                                                    lng: resource.longitude
                                                }]
                                            }]
                                        } else if (!savedPolygon && e.value === 'circle') {
                                            resource.polygons = [{
                                                chosen: true,
                                                color: 1,
                                                id: resource.polygons[0] && resource.polygons[0].id,
                                                type_polygon: 'circle',
                                                circuit: '2061.41',
                                                field: '103070.63',
                                                radius: 100,
                                                polygon_points: [{
                                                    lat: resource.latitude,
                                                    lng: resource.longitude
                                                }]
                                            }]
                                        } else if (!savedPolygon && e.value === 'rectangle') {
                                            resource.polygons = [{
                                                chosen: true,
                                                color: 1,
                                                id: resource.polygons[0] && resource.polygons[0].id,
                                                type_polygon: 'rectangle',
                                                circuit: '924.91',
                                                field: '31152.07',
                                                polygon_points: [{
                                                    lat: resource.latitude,
                                                    lng: resource.longitude
                                                }, {
                                                    lat: resource.latitude,
                                                    lng: resource.longitude + 0.001
                                                }, {
                                                    lat: resource.latitude + 0.001,
                                                    lng: resource.longitude + 0.001
                                                }, {
                                                    lat: resource.latitude + 0.001,
                                                    lng: resource.longitude
                                                }]
                                            }]
                                        } else if (!savedPolygon && e.value === 'polygon') {
                                            resource.polygons = [{
                                                chosen: true,
                                                color: 1,
                                                id: resource.polygons[0] && resource.polygons[0].id,
                                                type_polygon: 'polygon',
                                                circuit: '825.33',
                                                field: '15576.49',
                                                polygon_points: [{
                                                    lat: resource.latitude,
                                                    lng: resource.longitude
                                                }, {
                                                    lat: resource.latitude + 0.001,
                                                    lng: resource.longitude + 0.001
                                                }, {
                                                    lat: resource.latitude,
                                                    lng: resource.longitude + 0.001
                                                }]
                                            }]
                                        } else {
                                            resource.polygons = [savedPolygon]
                                        }
                                        this.setState({resource});
                                    }
                                }}
                                placeholder="Asset type"
                            />
                        </FormGroup>}
                        <Row>
                            {resource.id && assetType !== 'marker' && resource.polygons && resource.polygons.length > 0 &&
                                <Col sm={6} md={6}>
                                    {resource.polygons.find(p => p.chosen).field &&
                                        <Col md={6}>
                                            <ControlLabel
                                                className="padding-top-bottom-8">{resource.polygons.find(p => p.chosen).field} Sq.Ft</ControlLabel>
                                        </Col>
                                    }
                                    {resource.polygons.find(p => p.chosen).circuit &&
                                        <Col md={6}>
                                            <ControlLabel
                                                className="padding-top-bottom-8">{resource.polygons.find(p => p.chosen).circuit} LF.</ControlLabel>
                                        </Col>
                                    }
                                </Col>
                            }
                            <Col md={6}>
                                <Button
                                    className={'paddingBottom10t'}
                                    bsStyle="danger"
                                    bsSize="small"
                                    disabled={lockedCrewEditAccess}
                                    onClick={() => this.setState({coordinateVisible: !coordinateVisible})}
                                >
                                    Advanced
                                </Button>
                            </Col>
                            {invalidRectangleCoordinates && <Col md={6}>
                                <span className="errorText">Incorrect coordinates for rectangle polygon!</span>
                            </Col>}
                        </Row>
                        <Panel
                            className={'top10'}
                            collapsible
                            expanded={coordinateVisible}
                        >
                            {resource.polygons && !resource.polygons[0] &&
                                <BootstrapTable
                                    ref="table"
                                    data={[resource]}
                                    striped={true}
                                    bordered={false}
                                    hover={true}
                                    remote
                                    className="wrapped"
                                >
                                    <TableHeaderColumn autoValue={true} dataField='_id' hidden={true} isKey={true}
                                                       width={"10%"}>
                                        Id
                                    </TableHeaderColumn>
                                    <TableHeaderColumn autoValue={true} dataFormat={(_, val, __, i) => (i + 1)}
                                                       width={"10%"}>
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="latitude"
                                        dataFormat={(_, val, __, i) =>
                                            <FormControl
                                                type="text"
                                                name="latitude"
                                                placeholder="latitude"
                                                value={resource.latitude}
                                                onChange={e => this.updateResourceAttr(e, this.updateModified)}
                                            />}
                                    >
                                        Latitude
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="longitude"
                                        dataFormat={(_, val, __, i) =>
                                            <FormControl
                                                type="text"
                                                name="longitude"
                                                placeholder="longitude"
                                                value={resource.longitude}
                                                onChange={e => this.updateResourceAttr(e, this.updateModified)}
                                            />}
                                    >
                                        Longitude
                                    </TableHeaderColumn>

                                </BootstrapTable>}
                            <Modal show={showModal}
                                   onHide={() => this.setState({showModal: false})}
                                   className={"heightAuto fontIOS"}>
                                <Modal.Header closeButton><Modal.Title>Loading Asset
                                    Error</Modal.Title></Modal.Header>
                                <Modal.Body>
                                    <div className='columnDirection rate-modal-body-padding'>
                                        Asset did not record the coordinates, please reload and try again or enter them
                                        manually in advanced section.
                                    </div>
                                </Modal.Body>
                            </Modal>
                            {resource.polygons && resource.polygons[0] &&
                                <BootstrapTable
                                    ref="table"
                                    data={resource.polygons[0].polygon_points}
                                    striped={true}
                                    bordered={false}
                                    hover={true}
                                    remote
                                    className="wrapped"
                                >
                                    <TableHeaderColumn autoValue={true} dataField='_id' hidden={true} isKey={true}
                                                       width={"10%"}>
                                        Id
                                    </TableHeaderColumn>
                                    <TableHeaderColumn autoValue={true} dataFormat={(_, val, __, i) => (i + 1)}
                                                       width={"10%"}>
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="latitude"
                                        dataFormat={(_, val, __, i) =>
                                            <FormControl
                                                className="form-control-mobile"
                                                type="text"
                                                name="latitude"
                                                placeholder="latitude"
                                                width={"45%"}
                                                value={val.lat ? val.lat : null}
                                                onChange={e => {
                                                    this.updateModified()
                                                    if (i === 0) {
                                                        resource.latitude = parseFloat(e.target.value)
                                                    }
                                                    resource.polygons[0].polygon_points[i].lat = parseFloat(e.target.value)
                                                    this.setState({resource: resource})
                                                }
                                                }
                                            />}
                                    >
                                        Latitude
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="longitude"
                                        dataFormat={(_, val, __, i) =>
                                            <FormControl
                                                className="form-control-mobile"
                                                type="text"
                                                name="longitude"
                                                width={"45%"}
                                                placeholder="longitude"
                                                value={val.lng ? val.lng : null}
                                                onChange={e => {
                                                    this.updateModified()
                                                    if (i === 0) {
                                                        resource.longitude = parseFloat(e.target.value)
                                                    }
                                                    resource.polygons[0].polygon_points[i].lng = parseFloat(e.target.value)
                                                    this.setState({resource: resource})
                                                }
                                                }
                                            />
                                        }
                                    >
                                        Longitude
                                    </TableHeaderColumn>

                                </BootstrapTable>}
                        </Panel>
                        {resource.polygons && resource.polygons[0] &&
                            <Col md={12}>
                                <FormGroup>
                                    <ControlLabel>
                                        Color
                                    </ControlLabel>

                                    <div
                                        className={`c${resource.polygons[0].color} color-picker pointer visible bigger`}
                                        onClick={() => this.setState({showPicker: !showPicker})}/>
                                    {showPicker &&
                                        <CirclePicker width={400} colors={colors} onChange={e => {
                                            resource.polygons[0].color = colors.findIndex(c => c === e.hex);
                                            this.setState({showPicker: false, resource: this.state.resource});
                                        }}/>}
                                </FormGroup>
                            </Col>
                        }
                        {loaded && <div><Row>
                            <Col md={6}>
                                <Panel bsStyle="info" header="Plant History">
                                    <PlantStatsForm lockedCrewEditAccess={lockedCrewEditAccess}
                                                    onChange={(plantStats) => {
                                                        resource.current_stats = plantStats;
                                                        this.setState({resource}, this.updateModified);
                                                    }}
                                                    asset_id={resource.id}
                                                    plantStats={resource.current_stats}
                                                    plant_id={resource.plant_id}
                                    />
                                </Panel>
                            </Col>
                            <Col md={6}>
                                <Panel bsStyle="info" header="Asset Hazards">
                                    <Table striped bordered condensed hover>
                                        <thead>
                                        <tr>
                                            <th width="40%">Hazard</th>
                                            <th width="40%">Image</th>
                                            <th width="20%">Options</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {resource.asset_object_hazards
                                            .map((h, i) => {
                                                    const hazard = select(hazards, h.hazard_id);
                                                    if (h._destroy) {
                                                        return null;
                                                    }
                                                    return (siteHazard && i === siteHazard.key) ?
                                                        <SiteHazardForm key={i}
                                                                        {...siteHazardFormProps}
                                                        />
                                                        :
                                                        <tr key={h.key}>
                                                            <td>{hazard && hazard.label}</td>
                                                            {oldAttachment ?
                                                                <td>{h.images.sort((a, b) => a.order - b.order).filter(i => lockedCrewEditAccess ? i.cwo_and_proposal_display === true : i).map(i =>
                                                                    <Attachment key={i.id}
                                                                                image={i}
                                                                                lockedCrewEditAccess={lockedCrewEditAccess}
                                                                                reloadData={this.reloadDataImage}
                                                                                rotateImage={this.props.actions.rotateImage}
                                                                                deleteImage={imageId => {
                                                                                    removeImage(imageId)
                                                                                    const {asset_object_hazards} = this.state.resource;
                                                                                    const selectedAssetHazard = this.state.resource.asset_object_hazards.find(x => x.key === h.key)

                                                                                    if (selectedAssetHazard && selectedAssetHazard.images && selectedAssetHazard.images.length > 0) {
                                                                                        selectedAssetHazard.images = selectedAssetHazard.images.filter(x => x.id !== i.id)
                                                                                    }

                                                                                    this.setState(resource => ({
                                                                                        ...resource,
                                                                                        asset_object_hazards: asset_object_hazards
                                                                                    }))
                                                                                }}
                                                                    />)}</td> :
                                                                <td>{h.images.filter(i => lockedCrewEditAccess ? i.cwo_and_proposal_display === true : i).map(i =>
                                                                    <NewAttachment
                                                                        key={i.id}
                                                                        image={i}
                                                                        rotateImage={this.props.actions.rotateImage}
                                                                        reloadData={this.reloadDataImage}
                                                                        saveData={this.doSave}
                                                                        reloadDataNoSave={this.reloadData}
                                                                        reorderImages={(direction) => this.reorderHazardImages(i.id, h.id, direction)}
                                                                        lockedCrewEditAccess={lockedCrewEditAccess}
                                                                        setVisible={visible => {
                                                                            this.props.actions.setCwoAndProposalVisibleHazard(this.props.siteId, h.id, i.id, visible, (res) => {
                                                                                const newHazards = resource.asset_object_hazards.map(ah => {
                                                                                    if (ah.id === h.id) {
                                                                                        ah.images = ah.images.map(img => {
                                                                                            if (img.id === i.id)
                                                                                                return ({
                                                                                                    ...img,
                                                                                                    cwo_and_proposal_display: res.cwo_and_proposal_display
                                                                                                })
                                                                                            return img
                                                                                        })
                                                                                    }
                                                                                    return ah
                                                                                })
                                                                                const newState = {
                                                                                    ...resource,
                                                                                    asset_object_hazards: newHazards
                                                                                }

                                                                                this.setState({resource: newState});
                                                                            })
                                                                        }}
                                                                        deleteImage={imageId => {
                                                                            removeImage(imageId)
                                                                            const {asset_object_hazards} = this.state.resource;
                                                                            const selectedAssetHazard = this.state.resource.asset_object_hazards.find(x => x.key === h.key)

                                                                            if (selectedAssetHazard && selectedAssetHazard.images && selectedAssetHazard.images.length > 0) {
                                                                                selectedAssetHazard.images = selectedAssetHazard.images.filter(x => x.id !== i.id)
                                                                            }
                                                                            selectedAssetHazard.images = selectedAssetHazard.images.sort((a, b) => a.order - b.order)
                                                                            this.setState(resource => ({
                                                                                ...resource,
                                                                                asset_object_hazards: asset_object_hazards
                                                                            }))
                                                                        }}
                                                                        saveImage={(file, callback) => {
                                                                            this.props.actions.uploadHazardAttachment(h.id, file, data => {
                                                                                data = {
                                                                                    ...data,
                                                                                    order: h.images.length,
                                                                                    cwo_and_proposal_display: true
                                                                                }
                                                                                resource.asset_object_hazards[h.key].images.push(data);
                                                                                resource.asset_object_hazards[h.key].images = resource.asset_object_hazards[h.key].images.sort((a, b) => a.order - b.order)
                                                                                resource.asset_object_hazards[h.key] =
                                                                                    {
                                                                                        ...resource.asset_object_hazards[h.key],
                                                                                        image_order: JSON.stringify(resource.asset_object_hazards[h.key].images.map(i => i.id))
                                                                                    }
                                                                                this.setState({resource});
                                                                            })

                                                                        }}
                                                                    />)}</td>}

                                                            <td>
                                                                <Button
                                                                    bsSize="xsmall"
                                                                    bsStyle="success"
                                                                    disabled={lockedCrewEditAccess}
                                                                    className="top5 full-width"
                                                                    onClick={() => this.setState({siteHazard: h})}
                                                                >
                                                                    Edit
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                }
                                            )}
                                        </tbody>
                                    </Table>
                                    {!siteHazard && <Button
                                        bsSize="xsmall"
                                        bsStyle="success"
                                        className="pull-right"
                                        disabled={lockedCrewEditAccess}
                                        onClick={() => {
                                            const siteHazard = {key: resource.asset_object_hazards.length, images: []};
                                            resource.asset_object_hazards.push(siteHazard);
                                            this.setState({siteHazard: siteHazard}, this.updateModified);
                                        }}
                                    >
                                        Add New
                                    </Button>}
                                </Panel>
                            </Col>
                        </Row>
                            <Row>
                                <Col md={12}>
                                    <Panel bsStyle="info"
                                           header={oldAttachment ? "Images" : <div className="switches-container">
                                               <div className="left-switches-wrapper">
                                                   <div>
                                                       <span>Images</span>
                                                   </div>
                                                   <div className="material-switch">
                                                       <span className="mr-10">Only First</span>
                                                       <input id="showAllImagesToggle" type="checkbox"
                                                              disabled={resource.images?.length <= 1}
                                                              checked={onlyFirstImage}
                                                              onChange={() => {
                                                                  this.setState({onlyFirstImage: !onlyFirstImage})
                                                              }}
                                                       />
                                                       <label htmlFor="showAllImagesToggle"
                                                              className="label-default"></label>
                                                   </div>
                                               </div>
                                               <div className="right-switches-wrapper">
                                                   <Select
                                                       className="timestamp-select"
                                                       classNamePrefix="select"
                                                       isSearchable={false}
                                                       value={select(TIMESTAMP_OPTIONS, timestamp)}
                                                       options={TIMESTAMP_OPTIONS}
                                                       onChange={({value}) => this.setState({timestamp: value})}
                                                       clearable={false}
                                                   />
                                               </div>
                                           </div>} className={oldAttachment ? "" : 'images-panel'}>
                                        <div className="images-container">
                                            {oldAttachment ? getImages().map(i =>
                                                <Attachment key={i.id}
                                                            image={i}
                                                            lockedCrewEditAccess={lockedCrewEditAccess}
                                                            reloadData={this.reloadDataImage}
                                                            rotateImage={this.props.actions.rotateImage}
                                                            deleteImage={imageId => {
                                                                resource.images = resource.images.filter(i => i.id !== imageId);
                                                                this.setState({resource});
                                                            }}
                                                />) : getImages().map(i =>
                                                <NewAttachment
                                                    key={i.id}
                                                    className='image-miniature'
                                                    image={i}
                                                    removeWatermarkLoading={this.state.removeWatermarkLoading}
                                                    lockedCrewEditAccess={lockedCrewEditAccess}
                                                    client_timezone={client.client_timezone || moment.tz.guess()}
                                                    reloadData={this.reloadDataImage}
                                                    saveData={this.doSave}
                                                    reloadDataNoSave={this.reloadData}
                                                    rotateImage={this.props.actions.rotateImage}
                                                    reorderImages={(direction) => this.reorderImages(i.id, direction)}
                                                    timestamp={timestamp}
                                                    saveFormat={i.save_format}
                                                    visibilityLocked={onlyFirstImage}
                                                    addWatermarkClick={i.parent_id ? null : () => this.handleAddWatermark(i)}
                                                    originalImage={i.parent_id ? i.parent_image : null}
                                                    setVisible={visible => {
                                                        if (onlyFirstImage) {
                                                            return;
                                                        }
                                                        this.props.actions.setCwoAndProposalVisible(this.props.siteId, resource.id, i.id, visible, (res) => {
                                                            const newImages = resource.images.map(img => {
                                                                if (img.id === i.id)
                                                                    return ({
                                                                        ...img,
                                                                        cwo_and_proposal_display: res.cwo_and_proposal_display
                                                                    })
                                                                return img
                                                            })

                                                            const imagesVisibilityFromDB = new Map();
                                                            newImages.forEach(i => imagesVisibilityFromDB.set(i.id, i.cwo_and_proposal_display))
                                                            const newState = {
                                                                ...resource,
                                                                images: newImages,
                                                                imagesVisibilityFromDB
                                                            }
                                                            this.setState({
                                                                ...resource,
                                                                images: newImages,
                                                                imagesVisibilityFromDB
                                                            })
                                                            this.setState({resource: newState});
                                                        })
                                                    }}
                                                    deleteImage={imageId => {
                                                        const deletedImage = resource.images.find(i => i.id === imageId);
                                                        const shouldAutoSave = deletedImage.parent_id !== null;
                                                        if (deletedImage.parent_id) {
                                                            const copiedImagesVisibility = new Map(this.state.imagesVisibilityFromDB);
                                                            const parsedOrder = JSON.parse(resource.image_order);
                                                            parsedOrder[deletedImage.order] = deletedImage.parent_id;
                                                            resource.image_order = JSON.stringify(parsedOrder);

                                                            const mappedImages = resource.images.map((image) => {
                                                                if (image.id === deletedImage.id) {
                                                                    const tempImg = {...deletedImage.parent_image}
                                                                    tempImg.parent_id = null;
                                                                    tempImg.parent_image = null;
                                                                    tempImg.order = deletedImage.order;
                                                                    tempImg.cwo_and_proposal_display = deletedImage.cwo_and_proposal_display;
                                                                    copiedImagesVisibility.delete(deletedImage.id)
                                                                    copiedImagesVisibility.set(tempImg.id, deletedImage.cwo_and_proposal_display);
                                                                    this.props.actions.setCwoAndProposalVisible(this.props.siteId, resource.id, tempImg.id, deletedImage.cwo_and_proposal_display, () => null)
                                                                    return tempImg;
                                                                } else {
                                                                    return image;
                                                                }
                                                            });
                                                            resource.images = _.uniqBy(mappedImages, "id").sort((a, b) => a.order - b.order)
                                                            this.setState({imagesVisibilityFromDB: copiedImagesVisibility})
                                                        } else {
                                                            resource.images = resource.images.filter(i => i.id !== imageId);

                                                        }

                                                        if (resource.images.length <= 1) {
                                                            this.setState({onlyFirstImage: false})
                                                        }
                                                        this.setState({
                                                            resource
                                                        }, () => this.handleHideImages(shouldAutoSave));
                                                    }}
                                                    saveImage={(file, callback, originalImage) => {
                                                        this.props.actions.uploadAssetAttachment(resource.id, false, false, false, file, data => {
                                                            data = {
                                                                ...data,
                                                                order: resource.images.length,
                                                                cwo_and_proposal_display: true
                                                            }
                                                            resource.images.push(data);
                                                            this.setState({resource}, () => {
                                                                if (originalImage) {
                                                                    this.reloadData()
                                                                }
                                                            });

                                                        })
                                                    }}
                                                />)}
                                        </div>
                                        <div className="dropzone-wrapper">
                                            <Dropzone
                                                disabled={lockedCrewEditAccess}
                                                onDrop={async (files) => {
                                                    const assetId = this.state.resource.id
                                                    filesQueues[assetId] = filesQueues[assetId] || []

                                                    const assetFilesQueue = filesQueues[assetId]
                                                    if (assetFilesQueue.length > 0) {
                                                        assetFilesQueue.push(...files)
                                                        return
                                                    }
                                                    assetFilesQueue.push(...files)
                                                    const copiedResource = _.cloneDeep(resource)
                                                    const orderArray = [...copiedResource.images].sort((a, b) => a.order - b.order).map(i => i.order);
                                                    const highestOrder = orderArray.length > 0 ? orderArray[orderArray.length - 1] + 1 : 0

                                                    const uploadedImages = [];
                                                    const m = new Map(this.state.imagesVisibilityFromDB)

                                                    let index = 0;
                                                    while (assetFilesQueue.length > 0) {
                                                        const file = assetFilesQueue[0]
                                                        try {
                                                            const uploadedImage = await this.asyncUploadAssetAttachment(resource.id, client.client_timezone || moment.tz.guess(), timestamp, onlyFirstImage, [file])
                                                            const {watermark_image, ...original_image} = uploadedImage;
                                                            const order = highestOrder + index;
                                                            index++

                                                            if (watermark_image) {
                                                                const img = {
                                                                    ...watermark_image,
                                                                    order: order,
                                                                    cwo_and_proposal_display: watermark_image.cwo_and_proposal_display,
                                                                    parent_image: {
                                                                        ...original_image,
                                                                        cwo_and_proposal_display: original_image.cwo_and_proposal_display
                                                                    }
                                                                }
                                                                m.set(img.id, img.cwo_and_proposal_display)
                                                                uploadedImages.push(img);
                                                            } else {
                                                                const img = {
                                                                    ...original_image,
                                                                    order: order,
                                                                    cwo_and_proposal_display: original_image.cwo_and_proposal_display
                                                                }
                                                                m.set(img.id, img.cwo_and_proposal_display)
                                                                uploadedImages.push(img);
                                                            }
                                                        } finally {
                                                            assetFilesQueue.shift()
                                                        }
                                                    }
                                                    copiedResource.images = [...copiedResource.images, ...uploadedImages];
                                                    const parsedOrder = JSON.parse(resource.image_order) || [];
                                                    copiedResource.image_order = JSON.stringify(parsedOrder.concat(uploadedImages.map(i => i.id)));

                                                    this.props.refreshList && this.props.refreshList(copiedResource)
                                                    this.setState({
                                                        resource: copiedResource,
                                                        imagesVisibilityFromDB: m
                                                    }, () => {
                                                        if (this.state.timestamp !== "no_date") {
                                                            this.setState({removeWatermarkLoading: true})
                                                            this.doSave(() => {
                                                                this.setState({removeWatermarkLoading: false});
                                                            })
                                                        }
                                                    });
                                                }}
                                                className="dropzone pointer small">
                                                <p>Drop a file here or click to select a file to upload.</p>
                                            </Dropzone>
                                        </div>
                                    </Panel>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} xsHidden smHidden>
                                    <Panel bsStyle="info" header="Services">
                                        <Table striped bordered condensed hover>
                                            <thead>
                                            <tr>
                                                <th>Service</th>
                                                <th>Proposal #</th>
                                                <th>Status</th>
                                                <th>Proposed</th>
                                                <th>Completed</th>
                                                <th>Sales Arborist</th>
                                                <th>Price</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {resource.proposal_services
                                                .map(s => {
                                                    return (
                                                        <tr key={s.id}>
                                                            <td>{s.service_name}</td>
                                                            <td>
                                                                {this.renderProposalColumn(isCrewUser, lockedCrewEditAccess, s)}
                                                            </td>
                                                            <td>{s.service_status_name}</td>
                                                            <td>{moment(s.service_proposed).format("L")}</td>
                                                            <td>
                                                                {s.completed_at
                                                                    ? moment(s.completed_at).format("L")
                                                                    : "Not Completed"}
                                                            </td>
                                                            <td>{s.person_name}</td>
                                                            <td>{this.renderProposalColumn(isCrewUser, lockedCrewEditAccess, s, true, isSubcontractor)}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </Table>
                                    </Panel>
                                </Col>
                                <Clearfix visibleXsBlock visibleSmBlock>
                                    <Col md={12}>
                                        <Panel bsStyle="info" header="Services">
                                            <Table striped bordered condensed hover>
                                                <thead>
                                                <tr>
                                                    <th>Service</th>
                                                    <th>Data</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {resource.proposal_services
                                                    .map(s => {
                                                        return (
                                                            <tr key={s.id}>
                                                                <td>{s.service_name}</td>
                                                                <td>
                                                                    <ul>
                                                                        <li>
                                                                            Proposal: {!isCrewUser ? <Link
                                                                            to={`/mapview/${s.proposal_id}`}>
                                                                            {s.proposal_no}
                                                                        </Link> : '-'}
                                                                        </li>
                                                                        <li>
                                                                            Status: {s.service_status_name}
                                                                        </li>
                                                                        <li>
                                                                            Proposed: {moment(s.service_proposed).format("L")}
                                                                        </li>
                                                                        <li>
                                                                            Completed: {s.completed_at
                                                                            ? moment(s.completed_at).format("L")
                                                                            : "Not Completed"}
                                                                        </li>
                                                                        <li>
                                                                            Sales Arborist: {s.person_name}
                                                                        </li>
                                                                        <li>
                                                                            Price: {(!isCrewUser && !isSubcontractor) || (isSubcontractor && client.display_subcontractor_price) ?
                                                                            <Dollars amount={s.service_total}/> : '-'}
                                                                        </li>
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </Table>
                                        </Panel>
                                    </Col>
                                </Clearfix>
                            </Row>
                        </div>
                        }
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Row className={isMobile && "space-between"}>
                        <Col md={6} className="text-left">
                            <Button
                                bsStyle="danger"
                                bsSize="small"
                                onClick={() => this.props.actions.deleteAsset(siteId, asset.id, () => this.props.onDelete(asset.id))}
                                disabled={(loaded ? resource.proposal_services.length > 0 : true) || lockedCrewEditAccess}
                            >
                                Delete
                            </Button>
                        </Col>
                        <Col md={6} className="text-right">
                            {this.props.assets && <>
                                <Button bsSize="small" bsStyle="default" disabled={loadingAsset}
                                        onClick={() => {
                                            this.setState({loadingAsset: true}, () =>
                                                this.doSave(() => {
                                                    this.reloadData(closestTo(this.props.assets, this.props.assets.find(a => a.id === resource.id), 'prev').id, this.props.assets.find(a => a.id === resource.id))
                                                }));
                                        }}>Prev</Button>
                                <Button bsSize="small" bsStyle="default" disabled={loadingAsset}
                                        onClick={() => {
                                            this.setState({loadingAsset: true}, () =>
                                                this.doSave(() => {
                                                    this.reloadData(closestTo(this.props.assets, this.props.assets.find(a => a.id === resource.id), 'next').id, this.props.assets.find(a => a.id === resource.id))
                                                }));
                                        }}>Next</Button>
                            </>}
                            <Button bsSize="small" bsStyle="success"
                                    disabled={!loaded || lockedCrewEditAccess || uploading || loadingAsset}
                                    onClick={() => {
                                        const {resource} = this.state
                                        const newPolygons = resource.polygons
                                        if (!resource.plant_id) {
                                            this.setState({validationError: true})
                                        } else {
                                            this.doSave(() => {
                                                this.props.onSave(this.state.resource);
                                                this.props.closeModal && this.props.closeModal()
                                            });
                                        }
                                    }}>Save & Close</Button>
                            {this.state.showVoiceRecorder && <AudioRecorder setRecordedFile={this.setRecordedFile}
                                                                            onClose={() => this.setState({showVoiceRecorder: false})}
                                                                            isVoiceProcess={this.state.isVoiceProcess}
                            />}
                        </Col>
                    </Row>
                </Modal.Footer>
                <AssetWatermarkModal closeModal={() => this.setState({
                    watermarkModal: {
                        show: false,
                        image_id: null,
                        created_at: null,
                        taken_at: null
                    }
                })}
                                     show={watermarkModal.show}
                                     createdAt={watermarkModal.created_at}
                                     takenAt={watermarkModal.taken_at}
                                     onSave={(selectedWatermark) => this.handleWatermarkModalSave(selectedWatermark)}
                                     saving={watermarkModal.saving}
                                     timezone={client.client_timezone || moment.tz.guess()}
                />
            </Modal>
        );
    }
}

AssetEditorModal.propTypes = {
    onClose: PropTypes.func,
    onDelete: PropTypes.func,
    onSave: PropTypes.func,
    asset: PropTypes.object.isRequired,
    siteId: PropTypes.number.isRequired,
};

AssetEditorModal.defaultProps = {
    onSave: () => {
    }
};

function mapStateToProps(state, ownProps) {
    return {
        state: state,
        client: state.client.customerInfo
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({...actions, addAlert}, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AssetEditorModal);
