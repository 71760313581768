import React, {Component} from 'react';
import PropTypes from "prop-types"

import {Glyphicon, Table} from 'react-bootstrap'
import ColorCheckbox from "../Scheduler/ColorCheckbox";

const styles = {
    active: {
        color: '#000',
        backgroundColor: '#62c462'
    }
}

export default class AssetList extends Component {

    static propTypes = {
        assetList: PropTypes.array.isRequired,
        onMouseOver: PropTypes.func.isRequired,
        onMouseOut: PropTypes.func.isRequired,
        onAssetClick: PropTypes.func.isRequired,
        onAssetDoubleClick: PropTypes.func.isRequired
    }


    changeSelection(e, id) {
        // console.log("updating id.selected = checked?", e.target.value, 'selected', e.target.checked)
        this.props.updateAssetAttribute(id, 'selected', e)
    }

    toggleSelectAll(e) {
        this.props.assetList.forEach(a => {
            this.props.updateAssetAttribute(a.id, 'selected', e)
        })
    }

    renderPlants(assets) {
        if (assets === null || assets === undefined) {
            return null
        }

        return (
            <Table bordered striped condensed hover>
                <tbody>
                {assets.map(a => {
                    return (
                        <tr style={a.__active || a.__locked ? styles.active : {}} key={a.id}>
                            <td onClick={() => this.props.onAssetClick(a)}>
                                <ColorCheckbox value={a.selected} disabled={false} label={
                                    `${a.label} - ${a.plant_name} ${a.plant_count > 1 ? ' [' + a.plant_count + ']' : ""}`
                                }
                                               onChange={(e) => this.changeSelection(e, a.id)}
                                               color={a.plant_color}/>

                                <span className="pull-right">
              {(a.images.length > 0) ?
                  <span className='text-success small-right-padding'><Glyphicon glyph='camera'/></span> : ""}
                                    <Glyphicon glyph='modal-window'
                                               onClick={() => this.props.onAssetDoubleClick(a.id)}/>
            </span>
                            </td>
                        </tr>)
                })
                }
                </tbody>
            </Table>
        )
    }

    render() {
        const {assetList} = this.props
        return (
            <div>
                <h5>{assetList.length} Assets</h5>
                <ColorCheckbox value={assetList.length === assetList.filter(a => a['selected']).length}
                               onChange={e => this.toggleSelectAll(e)}
                               disabled={false} label="Select All"/>
                {this.renderPlants(assetList)}
            </div>
        )
    }
}


