import React, {useState} from "react";
import {Button, Col, Modal, Row} from "react-bootstrap";
import MDSpinner from "react-md-spinner";

const ConfirmationSaveModal = ({onSaveWith, onSave, onHide, message, type}) => {
    const [loading, setLoading] = useState(false)

    return <Modal
        bsSize="small"
        show={true}
        onHide={onHide}
        backdrop="static"
        id="confirmation-modal"
        className="heightAuto fontIOS"
    >
        <Modal.Header>
            <Modal.Title>Confirm saving</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <h4 className="text-center">{message}</h4>
        </Modal.Body>
        <Modal.Footer>
            <Row>
                <Col xs={12}>
                    <Button
                        bsSize="small"
                        bsStyle="success"
                        disabled={loading}
                        onClick={() => {
                            setLoading(true)
                            onSaveWith(() => {
                                setLoading(false)
                                onHide()
                            }, () => {
                                setLoading(false)
                            })
                        }}
                    >
                        {loading ? <div className="vertical-align"><MDSpinner size={20} className="mr-5"/> Processing...
                        </div> : `Yes, Save ${type} and Update Events`}
                    </Button>
                    <Button
                        bsSize="small"
                        bsStyle="success"
                        disabled={loading}
                        onClick={() => {
                            onSave()
                            onHide()
                        }}
                    >
                        No, just Save {type}
                    </Button>
                    <Button
                        bsSize="small"
                        bsStyle="warning"
                        disabled={loading}
                        onClick={() => onHide()}
                    >
                        Close without Save
                    </Button>
                </Col>
            </Row>
        </Modal.Footer>
    </Modal>
}

export default ConfirmationSaveModal;