import React, {useState} from "react";
import {Button, Col, FormControl, FormGroup, Modal, Row} from "react-bootstrap";
import MDSpinner from "react-md-spinner";
import Dollars from "../../../../components/Dollars";
import moment from "moment";
import {defaultDateTimeFormat} from "../../../../common/commonHandlers";

const SearchTransactionModal = ({onHide, searchTransaction}) => {
    const [stripeId, setStripeId] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [transaction, setTransaction] = useState(null)

    const transactionType = (id) => {
        switch (true) {
            case id.includes('tr_'):
                return "Transfer"
            case id.includes('py_'):
                return "Payment"
            case id.includes('pyr_'):
                return "Payment Refund"
            case id.includes('ch_'):
                return "Charge"
            case id.includes('fee_'):
                return "Application Fee"
            case id.includes('po_'):
                return "Payout"
            case id.includes('pi_'):
                return "Payment Intent"
            default:
                return ""
        }
    }

    return <Modal
        show={true}
        onHide={onHide}
        backdrop="static"
        className={"heightAuto maxHeight90 fontIOS"}
    >
        <Modal.Header closeButton>
            <Modal.Title>Search Stripe Transaction</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{maxHeight: '400px'}}>
            <Row>
                <Col xs={12} sm={9}>
                    <FormGroup className="bottom0">
                        <FormControl
                            className="top10"
                            placeholder="Stripe transaction ID"
                            name="stripe_transaction_id"
                            disabled={isLoading}
                            value={stripeId}
                            onChange={(e) => setStripeId(e.target.value)}
                        />
                    </FormGroup>
                </Col>
                <Col xs={12} sm={3} className="text-right">
                    <Button
                        className="top10 bottom10"
                        disabled={isLoading}
                        bsStyle="success"
                        onClick={() => {
                            if (stripeId && stripeId.length > 0) {
                                setIsLoading(true)
                                searchTransaction(stripeId, res => {
                                    setTransaction(res)
                                    setIsLoading(false)
                                }, () => {
                                    setIsLoading(false)
                                })
                            }
                        }}
                    >
                        {isLoading ? <span><MDSpinner size={15} className="mr-3"/>Processing...</span> : "Search"}
                    </Button>
                </Col>
            </Row>
            {transaction ? transaction?.stripe_transaction ?
                    <Col xs={12}>
                        <hr/>
                        <Row>
                            <Col className="bold">Stripe transaction found:</Col>
                            <Col xs={12}><span className="font11">Transaction ID:</span><span
                                className="font14 ml-6">{transaction.stripe_transaction?.id}</span></Col>
                            <Col xs={12}><span className="font11">Amount:</span><span
                                className="font14 ml-6">{transaction.stripe_transaction?.amount &&
                                <Dollars amount={transaction.stripe_transaction?.amount / 100}/>}</span></Col>
                            <Col xs={12}><span className="font11">Created:</span><span
                                className="font14 ml-6">{moment.unix(transaction.stripe_transaction?.created).format(defaultDateTimeFormat)}</span></Col>
                            <Col xs={12}><span className="font11">Transaction ID:</span><span
                                className="font14 ml-6">{transactionType(transaction.stripe_transaction?.id)}</span></Col>
                        </Row>
                    </Col>
                    :
                    <Col xs={12} className="text-center font16">Stripe transaction not found</Col>
                :
                null
            }
            {transaction && transaction?.match_by_ids &&
                <Col xs={12}>
                    <Row>
                        <hr/>
                        <Col className="bold bottom10">TreeHub payments found by id:</Col>
                        {transaction?.match_by_ids.map((p, i) => {
                            return <>
                                <Col xs={1} className="text-right">#{i + 1}</Col>
                                <Col xs={11}>
                                    <Row className="bottom10 borderDiv">
                                        <Col xs={12}><span className="font11">Transaction ID:</span><span
                                            className="font14 ml-6">{p?.id}</span></Col>
                                        <Col xs={12}><span className="font11">Amount:</span><span
                                            className="font14 ml-6">{p?.amount && <Dollars amount={p?.amount}/>}</span></Col>
                                        <Col xs={12}><span className="font11">Created:</span><span
                                            className="font14 ml-6">{moment(p?.created).format(defaultDateTimeFormat)}</span></Col>
                                        <Col xs={12}><span className="font11">Client name:</span><span
                                            className="font14 ml-6">{p?.client_name}</span></Col>
                                        <Col xs={12}><span className="font11">Invoice:</span><span
                                            className="font14 ml-6">{`${p?.invoice_no} (ID: ${p?.invoice_id})`}</span></Col>
                                        <Col xs={12}><span className="font11">Proposal:</span><span
                                            className="font14 ml-6">{`${p?.proposal_no} (ID: ${p?.proposal_id})`}</span></Col>
                                        <Col xs={12}><span className="font11">Customer name:</span><span
                                            className="font14 ml-6">{p?.customer_name}</span></Col>
                                    </Row>
                                </Col>
                            </>
                        })}
                    </Row>
                </Col>
            }
            {transaction && transaction?.match_by_params &&
                <Col xs={12}>
                    <Row>
                        <hr/>
                        <Col className="bold bottom10">TreeHub payments found by params:</Col>
                        {transaction?.match_by_params.map((p, i) => {
                            return <>
                                <Col xs={1} className="text-right">#{i + 1}</Col>
                                <Col xs={11}>
                                    <Row className="bottom10 borderDiv">
                                        <Col xs={12}><span className="font11">Transaction ID:</span><span
                                            className="font14 ml-6">{p?.id}</span></Col>
                                        <Col xs={12}><span className="font11">Amount:</span><span
                                            className="font14 ml-6">{p?.amount && <Dollars amount={p?.amount}/>}</span></Col>
                                        <Col xs={12}><span className="font11">Created:</span><span
                                            className="font14 ml-6">{moment(p?.created).format(defaultDateTimeFormat)}</span></Col>
                                        <Col xs={12}><span className="font11">Client name:</span><span
                                            className="font14 ml-6">{p?.client_name}</span></Col>
                                        <Col xs={12}><span className="font11">Invoice:</span><span
                                            className="font14 ml-6">{`${p?.invoice_no} (ID: ${p?.invoice_id})`}</span></Col>
                                        <Col xs={12}><span className="font11">Proposal:</span><span
                                            className="font14 ml-6">{`${p?.proposal_no} (ID: ${p?.proposal_id})`}</span></Col>
                                        <Col xs={12}><span className="font11">Customer name:</span><span
                                            className="font14 ml-6">{p?.customer_name}</span></Col>
                                    </Row>
                                </Col>
                            </>
                        })}
                    </Row>
                </Col>
            }
            {transaction && !transaction?.match_by_ids && !transaction?.match_by_params &&
                <Col xs={12} className="text-center font16 bottom15">
                    <hr/>
                    There are no matching saved transactions
                </Col>
            }
        </Modal.Body>
        <Modal.Footer>
            <Button
                bsStyle="success"
                onClick={onHide}
            >
                Cancel
            </Button>
        </Modal.Footer>
    </Modal>
}

export default SearchTransactionModal;