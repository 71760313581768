import {
    ADD_TO_WO,
    FETCH_PROPOSAL_INFO,
    FETCH_PS_INFO,
    PRINT_WO,
    PS_TO_WO_MOD_SUCCESS,
    REMOVE_PS_FROM_WO,
    REMOVE_SELECT_PROPOSAL_SERVICE,
    SELECT_PROPOSAL_SERVICE,
    UN_SELECT_PROPOSAL_SERVICE,
    UPDATE_PROPOSAL_SEARCH_TEXT,
    UPDATE_PS_INFO_ATTR,
    WORK_ORDER_SEARCH_TEXT
} from '../constants'

import axios from 'axios'
import {addAlert} from '../containers/App/actions'
import {browserHistory} from 'react-router'
import * as Client from '../containers/WorkOrdersPage/client'
import {apiCall} from "../common/apiActionHandler";
import {CLEAR_WO_DATA} from "../containers/ChangeServiceStatusPage/constants";

const fetchProposalServicesInfo = (proposalId, callback) => (dispatch, getState) => {
    const store = getState()
    const token = store.auth.token

    Client.fetchProposalServicesInfo(token, proposalId)
        .then(response => {
            dispatch({type: FETCH_PS_INFO, proposalServicesInfo: response.data})
            callback && callback()
        })
        .catch(error => {
            dispatch(
                addAlert({
                    message: error.toString(),
                    mood: 'warning',
                    dismissAfter: 3000,
                })
            )
        })
}

export const clearWOData = () => (dispatch) => {
    return dispatch({type: CLEAR_WO_DATA})
}

export const upsertPromise = (proposalServiceId, startDate, endDate) => (dispatch, getState) => {
    const config = {
        url: `/api/promised_dates`,
        method: 'POST',
        data: {
            proposal_service_id: proposalServiceId,
            start_date: startDate,
            end_date: endDate
        }
    };
    apiCall("Saved Promised Date", config, async config => {
        const response = await axios.request(config);
        dispatch(fetchProposalServicesInfo(response.data.proposal_id))
    }, dispatch, getState);
};

const fetchProposalInfo = (proposalId, callback) => (dispatch, getState) => {
    const store = getState()
    const token = store.auth.token

    Client.fetchProposalInfo(token, proposalId)
        .then(response => {
            dispatch({type: FETCH_PROPOSAL_INFO, proposalInfo: response.data})
            callback && callback()
        })
        .catch(error => {
            dispatch(
                addAlert({
                    message: error.toString(),
                    mood: 'warning',
                    dismissAfter: 3000,
                })
            )
        })
}

export const updateProposalNoSearchText = proposalNoSearchText => {
    return {type: UPDATE_PROPOSAL_SEARCH_TEXT, proposalNoSearchText}
}

export const updateWorkOrderSearchText = workOrderSearchText => {
    return {type: WORK_ORDER_SEARCH_TEXT, workOrderSearchText}
}

export const searchByProposalNo = () => (dispatch, getState) => {
    const store = getState()
    const proposalNo = store.workOrders.proposalNoSearchText

    if (proposalNo === undefined || proposalNo.length < 1) {
        dispatch(
            addAlert({
                message: 'Proposal Number is required',
                mood: 'warning',
                dismissAfter: 3000,
            })
        )

        return false
    }

    const config = {
        method: 'get',
        url: `/api/proposals/`,
        headers: {Authorization: store.auth.token},
        params: {q: proposalNo},
    }

    axios
        .request(config)
        .then(response => {
            const proposalId = response.data.id
            browserHistory.push(`/work_orders/${proposalId}`)
            dispatch({type: 'found_proposal_no_whatever'})
        })
        .catch(error => {
            dispatch(
                addAlert({
                    message: 'Proposal Number not found',
                    mood: 'warning',
                    dismissAfter: 3000,
                })
            )
        })
}

export const searchByWorkOrderNo = () => (dispatch, getState) => {
    const store = getState()
    const workOrderNo = store.workOrders.workOrderSearchText

    if (workOrderNo.length < 1 || workOrderNo === undefined) {
        dispatch(
            addAlert({
                message: 'A Work Order Number is required',
                mood: 'warning',
                dismissAfter: 3000,
            })
        )

        return false
    }
    const config = {
        url: "/api/work_orders/search",
        params: {q: workOrderNo},
        headers: {Authorization: store.auth.token},
    }

    return axios
        .request(config)
        .then(response => {

            if (response.data.length < 1) {
                dispatch(
                    addAlert({
                        message: 'Proposal not found based on work order number',
                        mood: 'warning',
                        dismissAfter: 3000,
                    })
                )

                return false
            }

            /// Fetch Proposal
            let proposalNo = response.data[0].proposal_no

            const config = {
                method: 'get',
                url: `/api/proposals/`,
                headers: {Authorization: store.auth.token},
                params: {q: proposalNo},
            }

            axios
                .request(config)
                .then(response => {
                    const proposalId = response.data.id
                    browserHistory.push(`/work_orders/${proposalId}`)
                    dispatch({type: 'found_proposal_no_whatever'})
                })
                .catch(error => {
                    dispatch(
                        addAlert({
                            message: 'Proposal Number not found',
                            mood: 'warning',
                            dismissAfter: 3000,
                        })
                    )
                })
            ////  EOF Fetch Proposal
        })
        .catch(error => {
            if (error.response && error.response.status === 404) {
                dispatch(
                    addAlert({
                        message: `Work Order Number ${workOrderNo} was not found.`,
                        mood: "warning",
                        dismissAfter: 3000,
                    })
                )
            } else {
                console.error(error)
            }
        })


}

export const printWo = wo => ({type: PRINT_WO, wo})

export const addToWo = wo => ({type: ADD_TO_WO, wo})

export const updateAttr = (psId, attr, newVal) => {
    return {type: UPDATE_PS_INFO_ATTR, psId, attr, newVal}
}

export const updateWoNote = (proposalServiceId, woNote) => (
    dispatch,
    getState
) => {
    const store = getState()
    const token = store.auth.token
    const proposalId = store.workOrders.proposalInfo.id

    Client.updateWoNote(proposalServiceId, woNote, token)
        .then(response => {
            dispatch(fetchProposalServicesInfo(proposalId)) //reload optimistic paranoia
        })
        .catch(error => {
            dispatch(
                addAlert({
                    message: error.toString(),
                    mood: 'warning',
                    dismissAfter: 3000,
                })
            )
        })
}

export const doAddToWo = callback => (dispatch, getState) => {
    //Make an api call to the CLient... We have to wait and refresh
    //because the client supplies the WONumber

    const store = getState()
    const wo = store.workOrders.addToWo
    const selectedPs = store.workOrders.selectedPs
    const token = store.auth.token
    const proposalId = store.workOrders.proposalInfo.id

    if (selectedPs.length < 1) {
        dispatch(
            addAlert({
                message: 'Please select at least 1 Proposal Service to Add!',
                mood: 'danger',
                dismissAfter: 3000,
            })
        )
        return false
    }

    //send to the API the WO that this PS should be in along with the WOnote in case the WOnote was changed
    Client.addToWo(wo, selectedPs, token)
        .then(response => {
            dispatch(
                addAlert({
                    message: 'Work order updated!',
                    mood: 'success',
                    dismissAfter: 3000,
                })
            )
            dispatch(fetchProposalServicesInfo(proposalId)) //reload optimistic paranoia
            dispatch({type: PS_TO_WO_MOD_SUCCESS})
            callback && callback()
        })
        .catch(error => {
            dispatch(
                addAlert({
                    message: error.toString(),
                    mood: 'warning',
                    dismissAfter: 3000,
                })
            )
        })
}

export const removePsFromWo = (psId, callback) => (dispatch, getState) => {
    const store = getState()
    const token = store.auth.token
    const proposalId = store.workOrders.proposalInfo.id

    Client.removePsFromWo(psId, token)
        .then(response => {
            dispatch(
                addAlert({
                    message: 'Proposal Service removed form Work Order',
                    mood: 'warning',
                    dismissAfter: 3000,
                })
            )
            callback && callback(response)
            if (proposalId) {
                dispatch(fetchProposalServicesInfo(proposalId)); //reload optimistic paranoia
            }
            dispatch({type: PS_TO_WO_MOD_SUCCESS})
            dispatch({type: REMOVE_PS_FROM_WO, psId})
        })
        .catch(error => {
            dispatch(
                addAlert({
                    message: error.toString(),
                    mood: 'warning',
                    dismissAfter: 3000,
                })
            )
        })
}

export const loadProposalAndPServicesInfo = (proposalId, callback) => dispatch => {
    dispatch({type: REMOVE_SELECT_PROPOSAL_SERVICE})
    dispatch(fetchProposalInfo(proposalId, () => {
        dispatch(fetchProposalServicesInfo(proposalId, () => {
            callback && callback()
        }))
    }))
}

export const doPrintWo = () => (dispatch, getState) => {
    const store = getState()

    //figure out which type they want. and fetch teh work order ids and send them
    const wo = store.workOrders.printWo

    if (wo.includes('-')) {
        console.info('Placeholder selected. Noop.')
        return false
    }

    let workOrders = []
    switch (wo) {
        case 'phc':
            workOrders = [
                ...new Set(
                    store.workOrders.proposalServicesInfo
                        .filter(ps => ps.workOrderNo !== null && ps.isPhc)
                        .map(ps => ps.workOrderNo)
                ),
            ]
            break
        case 'treeCare':
            workOrders = [
                ...new Set(
                    store.workOrders.proposalServicesInfo
                        .filter(ps => ps.workOrderNo !== null && !ps.isPhc)
                        .map(ps => ps.workOrderNo)
                ),
            ]
            break
        default:
            workOrders = [wo]
    }

    if (workOrders.length === 0) {
        dispatch(
            addAlert({
                message: 'No Work Orders of that kind were found associated with this Proposal to Print-Preview!',
            })
        )
        return false
    }
    browserHistory.push(`/print_work_orders/${workOrders.join(',')}`)
    dispatch({type: 'print_work_orders'}) // a noop
}

export const selectPs = (e, psId) => {
    if (e.target.checked) {
        return {type: SELECT_PROPOSAL_SERVICE, psId}
    } else {
        return {type: UN_SELECT_PROPOSAL_SERVICE, psId}
    }
}

export const fetchWorkOrders = (callback) => async (dispatch, getState) => {
    const store = getState()
    const config = {
        url: '/api/work_orders/recent_work_orders/',
        headers: {Authorization: store.auth.token},
    }

    apiCall("Fetching recent work orders", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
}

export const updatePONumberInPS = (ps_id, po_number, callback) => async (dispatch, getState) => {
    const config = {
        method: "put",
        url: `/api/proposal_services/update_po_number`,
        data: {
            id: ps_id,
            po_number
        },
    };

    apiCall("Updating proposal service PO number", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState);
};