import React, {Component} from 'react';
import {Button, Col, ControlLabel, Form, FormControl, FormGroup, Jumbotron, Row} from "react-bootstrap"
import StepZilla from "react-stepzilla";
import "react-stepzilla/src/css/main.css";
import './form.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const isMobile = window.screen.width <= 1024;

const Info = ({text}) => (
    <Jumbotron style={{
        height: '7vh',
        marginBottom: 5,
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingLeft: isMobile ? 8 : 20
    }}>
        <span style={{fontWeight: 600, textAlign: isMobile ? 'initial' : null}}>{text}</span>
    </Jumbotron>
);

class BillingContact extends Component {
    state = {
        customerFieldActive: false,
        companyFieldActive: false,
        addressFieldActive: false,
        firstNameFieldActive: false,
        lastNameFieldActive: false,
        phoneFieldActive: false,
        phoneTwoFieldActive: false,
        addressTwoFieldActive: false,
        emailFieldActive: false,
        emailTwoFieldActive: false,
        cityFieldActive: false,
        countyFieldActive: false,
        stateFieldActive: false,
        zipFieldActive: false,
        countryFieldActive: false,
        text: "The following fields are required for billing's address"
    }

    onChangeFunc = () => {
        this.setState({active: true});
        this.props.onCustomerValueChange();
    }


    render() {
        const {text} = this.state;
        return (
            <div>
                <Info text={text}/>
                <Jumbotron className="formJumbotron"
                           style={{paddingTop: isMobile ? 8 : 28, paddingBottom: 10, marginBottom: 15}}>
                    <div>
                        {isMobile ? (<Row className="titleMobile">Billings</Row>) : null}
                        <Row style={{padding: isMobile ? 8 : null}}>
                            <FormGroup style={{marginBottom: 12}} controlId="CompanyName">
                                <Col componentClass={ControlLabel} sm={2}>
                                    Company*
                                </Col>
                                <Col sm={10}>
                                    <FormControl
                                        value={this.props.value[1]}
                                        onChange={(e) => {
                                            this.props.onCompanyValueChange(e);
                                            this.setState({companyFieldActive: true})
                                        }}
                                        placeholder="Company name"
                                        type="text"/>
                                </Col>
                            </FormGroup>
                            <FormGroup controlId="FirstName">
                                <Col componentClass={ControlLabel} sm={2}>
                                    First Name*
                                </Col>
                                <Col sm={10}>
                                    <FormControl
                                        value={this.props.value[2]}
                                        onChange={(e) => {
                                            this.props.onFirstNameFieldChange(e);
                                            this.setState({firstNameFieldActive: true})
                                        }}
                                        type="text"
                                        placeholder="First Name"/>
                                </Col>
                            </FormGroup>
                            <FormGroup controlId="LastName">
                                <Col componentClass={ControlLabel} sm={2}>
                                    Last Name*
                                </Col>
                                <Col sm={10}>
                                    <FormControl
                                        value={this.props.value[3]}
                                        onChange={(e) => {
                                            this.props.onLastNameFieldChange(e);
                                            this.setState({lastNameFieldActive: true})
                                        }}
                                        type="text"
                                        placeholder="Last Name"/>
                                </Col>
                            </FormGroup>
                            <FormGroup controlId="PhoneOne">
                                <Col componentClass={ControlLabel} sm={2}>
                                    Phone*
                                </Col>
                                <Col sm={10}>
                                    <FormControl
                                        value={this.props.value[4]}
                                        onChange={(e) => {
                                            this.props.onPhoneFieldChange(e);
                                            this.setState({phoneFieldActive: true})
                                        }}
                                        type="number"
                                        placeholder="Phone"
                                        style={{paddingRight: 5}}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup controlId="formControlsEmail">
                                <Col componentClass={ControlLabel} sm={2}>
                                    Email*
                                </Col>
                                <Col sm={10}>
                                    <FormControl
                                        value={this.props.value[6]}
                                        onChange={(e) => {
                                            this.props.onEmailFieldChange(e);
                                            this.setState({emailFieldActive: true})
                                        }}
                                        type="email"
                                        placeholder="Email"/>
                                </Col>
                            </FormGroup>
                            <FormGroup controlId="AddressOne">
                                <Col componentClass={ControlLabel} sm={2}>
                                    Address 1*
                                </Col>
                                <Col sm={10}>
                                    <FormControl
                                        value={this.props.value[8]}
                                        onChange={(e) => {
                                            this.props.onAddressFieldChange(e);
                                            this.setState({addressFieldActive: true})
                                        }}
                                        type="text"
                                        placeholder="Address 1"/>
                                </Col>
                            </FormGroup>
                            <FormGroup controlId="AddressTwo">
                                <Col componentClass={ControlLabel} sm={2}>
                                    Address 2
                                </Col>
                                <Col sm={10}>
                                    <FormControl
                                        value={this.props.value[9]}
                                        onChange={(e) => {
                                            this.props.onAddressTwoFieldChange(e);
                                            this.setState({addressTwoFieldActive: true})
                                        }}
                                        type="text"
                                        placeholder="Address 2"/>
                                </Col>
                            </FormGroup>
                            <Row style={{textAlign: isMobile ? 'left' : null}}>
                                <Col componentClass={ControlLabel} sm={2}>
                                    {isMobile ? 'City' : 'City/State/Zip'}
                                </Col>
                                <Col md={10} style={{paddingLeft: 0, paddingRight: 0}}>
                                    <Col sm={4} className={isMobile ? "bottom20" : null}>
                                        <FormControl
                                            value={this.props.value[10]}
                                            onChange={(e) => {
                                                this.props.onCityFieldChange(e);
                                                this.setState({cityFieldActive: true})
                                            }}
                                            type="text"
                                            placeholder="City"/>
                                    </Col>
                                    <Col sm={4} className={isMobile ? "bottom20" : null}>
                                        {isMobile ? 'State' : null}
                                        <FormControl
                                            value={this.props.value[12]}
                                            onChange={(e) => {
                                                this.props.onStateFieldChange(e);
                                                this.setState({stateFieldActive: true})
                                            }}
                                            type="text"
                                            placeholder="State"/>
                                    </Col>
                                    <Col sm={4} className={isMobile ? "bottom20" : null}>
                                        {isMobile ? 'Zip' : null}
                                        <FormControl
                                            value={this.props.value[13]}
                                            onChange={(e) => {
                                                this.props.onZipFieldChange(e);
                                                this.setState({zipFieldActive: true})
                                            }}
                                            type="text"
                                            placeholder="Zip"/>
                                    </Col>
                                </Col>
                            </Row>
                        </Row>
                    </div>
                </Jumbotron>
            </div>
        );
    }
}

class ContactWrapper extends Component {
    state = {
        text: "The following fields are required for billing's contact",
    }

    render() {
        const {text} = this.state;
        const {
            value,
            clearContactFormInput,
            onContactEmailChange,
            onContactEmailTwoChange,
            onContactFirstNameChange,
            onContactLastNameChange,
            onContactPhoneChange,
            onContactPhoneTwoChange,
            onContactTypeChange
        } = this.props;
        return (
            <div>
                <Info text={text}/>
                <Jumbotron className="formJumbotron"
                           style={{paddingTop: isMobile ? 8 : 10, paddingBottom: 10, marginBottom: 15}}>
                    {isMobile ? (<Row className="titleMobile">Contacts</Row>) : null}
                    <ContactForm
                        value={value}
                        clearContactFormInput={clearContactFormInput}
                        onContactEmailChange={onContactEmailChange}
                        onContactEmailTwoChange={onContactEmailTwoChange}
                        onContactFirstNameChange={onContactFirstNameChange}
                        onContactLastNameChange={onContactLastNameChange}
                        onContactPhoneChange={onContactPhoneChange}
                        onContactPhoneTwoChange={onContactPhoneTwoChange}
                        onContactTypeChange={onContactTypeChange}
                    />
                </Jumbotron>
            </div>
        );
    }
}

class ContactForm extends Component {
    state = {
        contactTypeFieldActive: false,
        contactFirstNameFieldActive: false,
        contactLastNameFieldActive: false,
        contactPhoneFieldActive: false,
        contactPhoneTwoFieldActive: false,
        contactEmailFieldActive: false,
        contactEmailTwoFieldActive: false,
        moreContacts: false,
        btnContactSubmitted: false,
        contacts: [],
        formContactVisible: false
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.btnContactSubmitted !== this.state.btnContactSubmitted) {
            this.props.clearContactFormInput();
        }
    }

    createDivs = (e) => {
        e.preventDefault()
        this.setState({
            contacts: this.state.contacts.concat(<ContactForm/>)
            , formContactVisible: true
        })
    };

    renderForm = () => {
        const {
            contactTypeFieldActive,
            contactFirstNameFieldActive,
            contactLastNameFieldActive,
            contactPhoneFieldActive
        } = this.state;
        return (
            <Row id="form" style={{
                paddingLeft: isMobile ? 40 : null,
                paddingTop: 10,
                marginBottom: 10,
                paddingRight: isMobile ? 40 : 15
            }}>
                <FormGroup controlId="ContactType"
                           validationState={this.props.value[0].length === 0 && contactTypeFieldActive ? 'error' : null}
                >
                    <Col componentClass={ControlLabel} sm={2}>
                        Contact Type
                    </Col>
                    <Col sm={10}>
                        <FormControl
                            componentClass="select"
                            type="text"
                            placeholder="Select contact type">
                            <option value="typeA">Select Type</option>
                            <option value="typeB">Type 1</option>
                            <option value="typeC">Type 2</option>
                        </FormControl>
                    </Col>
                </FormGroup>
                <FormGroup controlId="ContactFirstName"
                           validationState={this.props.value[1].length === 0 && contactFirstNameFieldActive ? 'error' : null}>
                    <Col componentClass={ControlLabel} sm={2}>
                        First Name
                    </Col>
                    <Col sm={10}>
                        <FormControl
                            type="text"
                            placeholder="First Name"/>
                    </Col>
                </FormGroup>
                <FormGroup controlId="ContactLastName"
                           validationState={this.props.value[2].length === 0 && contactLastNameFieldActive ? 'error' : null}>
                    <Col componentClass={ControlLabel} sm={2}>
                        Last Name
                    </Col>
                    <Col sm={10}>
                        <FormControl
                            type="text"
                            placeholder="Last Name"/>
                    </Col>
                </FormGroup>
                <FormGroup controlId="ContactPhoneOne"
                           validationState={this.props.value[3].length === 0 && contactPhoneFieldActive ? 'error' : null}>
                    <Col componentClass={ControlLabel} sm={2}>
                        Phone
                    </Col>
                    <Col sm={10}>
                        <FormControl
                            type="number"
                            placeholder="Phone 1"
                            style={{paddingRight: 5}}
                        />
                    </Col>
                </FormGroup>
                <FormGroup controlId="ContactEmailOne">
                    <Col componentClass={ControlLabel} sm={2}>
                        Email
                    </Col>
                    <Col sm={10}>
                        <FormControl
                            type="email"
                            placeholder="Email 1"/>
                    </Col>
                </FormGroup>
            </Row>
        );
    }

    render() {
        return (
            <Row>
                <Row>
                    {this.renderForm()}
                    <Row style={{textAlign: 'right', paddingRight: isMobile ? 42 : 17}}>
                        <Button onClick={(e) => this.createDivs(e)} className="btnNewRecord"> <FontAwesomeIcon
                            icon="plus"/><span
                            className="ml4">Add More</span></Button>
                    </Row>
                    <div className={this.state.formContactVisible ? "formPage" : null}>
                        {this.state.contacts && this.state.contacts.map((contact, i) => (
                            <div className="text-left" style={{marginBottom: 5}}>
                                <span style={{fontWeight: 'bold', marginLeft: isMobile ? 26 : 0}}>Contact {i + 2}</span>
                                <span key={i}>{this.renderForm()}</span>
                            </div>
                        ))}
                    </div>
                </Row>
            </Row>
        );
    }
}

class SiteWrapper extends Component {
    state = {
        text: 'Please fill the following fields, to let us know your site address'
    }

    render() {
        const {text} = this.state;
        const {
            onSiteZipChange,
            onSiteStateChange,
            onSiteCityChange,
            onSiteAddressChange,
            onSiteFullAddressChange,
            onSiteNameChange,
            onSiteContactChange,
            value
        } = this.props;
        return (
            <div>
                <Info text={text}/>
                <Jumbotron className="formJumbotron"
                           style={{paddingTop: isMobile ? 8 : 10, paddingBottom: 10, marginBottom: 15}}>
                    {isMobile ? (<Row className="titleMobile">Sites</Row>) : null}
                    <Sites
                        value={value}
                        onSiteZipChange={onSiteZipChange}
                        onSiteStateChange={onSiteStateChange}
                        onSiteCityChange={onSiteCityChange}
                        onSiteAddressChange={onSiteAddressChange}
                        onSiteFullAddressChange={onSiteFullAddressChange}
                        onSiteNameChange={onSiteNameChange}
                        onSiteContactChange={onSiteContactChange}
                    />
                </Jumbotron>
            </div>
        );
    }
}

class Sites extends Component {
    state = {
        siteContactFieldActive: false,
        siteNameFieldActive: false,
        siteFullAddressFieldActive: false,
        siteAddressFieldActive: false,
        siteCityFieldActive: false,
        siteStateFieldActive: false,
        siteZipFieldActive: false,
        moreSites: false,
        btnSiteSubmitted: false,
        sites: [],
        formSiteVisible: false
    }

    addForm = (e) => {
        e.preventDefault()
        this.setState({
            sites: this.state.sites.concat(<Sites/>)
            , formSiteVisible: true
        })
    };


    componentDidUpdate(prevProps, prevState) {
        if (prevState.btnSiteSubmitted !== this.state.btnSiteSubmitted) {
            this.props.clearSiteFormInput();
        }
    }

    siteFormRender = () => {
        const {
            siteContactFieldActive,
            siteNameFieldActive,
            siteFullAddressFieldActive,
            siteAddressFieldActive
        } = this.state;
        return (
            <Row style={{
                paddingLeft: isMobile ? 40 : null,
                paddingTop: 10,
                marginBottom: 10,
                paddingRight: isMobile ? 40 : 15
            }}>
                <FormGroup controlId="ContactAddressOne"
                           validationState={this.props.value[0].length === 0 && siteContactFieldActive ? 'error' : null}>
                    <Col componentClass={ControlLabel} sm={2}>
                        Contact*
                    </Col>
                    <Col sm={10}>
                        <FormControl
                            componentClass="select"
                            type="text"
                            placeholder="Select Contact"
                        >
                            <option value="typeA">Select Type</option>
                            <option value="typeB">Type 1</option>
                            <option value="typeC">Type 2</option>
                        </FormControl>
                    </Col>
                </FormGroup>
                <FormGroup controlId="SiteName"
                           validationState={this.props.value[1].length === 0 && siteNameFieldActive ? 'error' : null}>
                    <Col componentClass={ControlLabel} sm={2}>
                        Site Name*
                    </Col>
                    <Col sm={10}>
                        <FormControl
                            type="text"
                            placeholder="Site Name"/>
                    </Col>
                </FormGroup>
                <FormGroup controlId="ContactlAddressTwo"
                           validationState={this.props.value[2].length === 0 && siteFullAddressFieldActive ? 'error' : null}>
                    <Col componentClass={ControlLabel} sm={2}>
                        Full Address
                    </Col>
                    <Col sm={10}>
                        <FormControl
                            type="text"
                            placeholder="Address 2"/>
                    </Col>
                </FormGroup>
                <FormGroup controlId="ContactCounty"
                           validationState={this.props.value[3].length === 0 && siteAddressFieldActive ? 'error' : null}>
                    <Col componentClass={ControlLabel} sm={2}>
                        Address*
                    </Col>
                    <Col sm={10}>
                        <FormControl
                            type="text"
                            placeholder="Address"/>
                    </Col>
                </FormGroup>
                <Row style={{textAlign: isMobile ? 'left' : null, marginBottom: isMobile ? 0 : 15}}>
                    <Col componentClass={ControlLabel} sm={2}>
                        {isMobile ? 'City*' : 'City/State/Zip'}
                    </Col>
                    <Col md={10} style={{paddingLeft: 0, paddingRight: 0}}>
                        <Col sm={4} className={isMobile ? "bottom20" : null}>
                            <FormControl
                                type="text"
                                placeholder="City"/>
                        </Col>
                        <Col sm={4} className={isMobile ? "bottom20" : null}>
                            {isMobile ? 'State*' : null}
                            <FormControl
                                type="text"
                                placeholder="State"/>
                        </Col>
                        <Col sm={4} className={isMobile ? "bottom20" : null}>
                            {isMobile ? 'Zip*' : null}
                            <FormControl
                                type="text"
                                placeholder="Zip"/>
                        </Col>
                    </Col>
                </Row>
            </Row>
        );
    }

    render() {
        return (
            <Row>
                <Row>
                    {this.siteFormRender()}
                    <Row style={{textAlign: 'right', paddingRight: isMobile ? 42 : 17}}>
                        <Button className="btnNewRecord"
                                onClick={(e) => this.addForm(e)}> <FontAwesomeIcon icon="plus"/><span className="ml4">Add More</span></Button>
                    </Row>
                    <div className={this.state.formSiteVisible ? "formPage" : null}>
                        {this.state.sites && this.state.sites.map((site, i) => (
                            <div className="text-left" style={{marginBottom: 5}}>
                                <span style={{fontWeight: 'bold', marginLeft: isMobile ? 26 : 0}}>Site {i + 2}</span>
                                <span key={i}>{this.siteFormRender()}</span>
                            </div>
                        ))}
                    </div>
                </Row>
            </Row>
        );
    }
}


class Message extends Component {
    state = {
        noteActive: false,
        messageActive: false,
        text: "If you need more detailed information, fill below fields"
    }

    render() {
        const {text, noteActive, messageActive} = this.state;
        return (
            <div>
                <Info text={text}/>
                <Jumbotron className="formJumbotron"
                           style={{paddingTop: isMobile ? 8 : 28, paddingBottom: 10, marginBottom: 15}}>
                    {isMobile ? (<Row className="titleMobile">Message</Row>) : null}
                    <div style={{padding: isMobile ? 12 : null}}>
                        <Row>
                            <FormGroup controlId="Note"
                                       validationState={this.props.value[0].length === 0 && noteActive ? 'error' : null}>
                                <Col componentClass={ControlLabel} sm={2}>
                                    Note
                                </Col>
                                <Col sm={10}>
                                    <FormControl
                                        value={this.props.value[0]}
                                        onChange={(e) => {
                                            this.props.onNoteChange(e);
                                            this.setState({noteActive: true})
                                        }}
                                        className="textAreaInput"
                                        componentClass="textarea"
                                        rows="10"
                                        placeholder="Note"/>
                                </Col>
                            </FormGroup>
                        </Row>
                        <Row>
                            <FormGroup controlId="Message"
                                       validationState={this.props.value[1].length === 0 && messageActive ? 'error' : null}>
                                <Col componentClass={ControlLabel} sm={2}>
                                    Message
                                </Col>
                                <Col sm={10}>
                                    <FormControl
                                        value={this.props.value[1]}
                                        onChange={(e) => {
                                            this.props.onMessageChange(e);
                                            this.setState({messageActive: true})
                                        }}
                                        className="textAreaInput"
                                        componentClass="textarea"
                                        rows="10"
                                        placeholder="Message"/>
                                </Col>
                            </FormGroup>
                        </Row>
                    </div>
                </Jumbotron>
            </div>
        )
    }
}

class CustomerContactForm extends Component {
    state = {
        hasBeenNewRecordAdded: false,
        firstPage: true,
        lastPage: false,
        isSubmitted: false,
        companyValue: '',
        addressValue: '',
        addressTwoValue: '',
        firstNameValue: '',
        lastNameValue: '',
        phoneValue: '',
        phoneTwoValue: '',
        emailValue: '',
        emailTwoValue: '',
        cityValue: '',
        stateValue: '',
        zipValue: '',
        countyValue: '',
        countryValue: '',

        contactType: '',
        contactFirstName: '',
        contactLastName: '',
        contactPhone: '',
        contactEmail: '',

        siteContact: '',
        siteName: '',
        siteFullAddress: '',
        siteAddress: '',
        siteCity: '',
        siteState: '',
        siteZip: '',

        note: '',
        message: ''
    }

    onSubmit = () => {
        this.setState({isSubmitted: true});
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            firstPage,
            contacts,
            emailValue,
            contactEmail,
            customerValue,
            companyValue,
            firstNameValue,
            lastNameValue,
            phoneValue,
            addressValue,
            contactType,
            contactFirstName,
            contactLastName,
            contactPhone,
            contactAddress
        } = this.state;
        const invalidEmail = !emailValue.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) && firstPage;
        const billingRequiredValuesEmpty = invalidEmail || !companyValue || !firstNameValue || !lastNameValue || !phoneValue || !addressValue;
        const contactRequiredValuesEmpty = !contactType || !contactEmail || !contactFirstName || !contactLastName || !contactPhone;
        const billingValues = prevState.emailValue !== emailValue || prevState.companyValue !== companyValue || prevState.firstNameValue !== firstNameValue || prevState.lastNameValue !== lastNameValue || prevState.phoneValue !== phoneValue || prevState.addressValue !== addressValue;
        const contactValues = prevState.contactType !== contactType || prevState.contactEmail !== contactEmail || prevState.contactFirstName !== contactFirstName || prevState.contactLastName !== contactLastName || prevState.contactPhone !== contactPhone;
        const requiredFields = billingValues;
        if (requiredFields) {
            document.getElementById("next-button").disabled = false;
            document.getElementById("prev-button").disabled = false;
        }
        if (billingRequiredValuesEmpty && firstPage) {
            document.getElementById("next-button").disabled = true;
        }
    }


    checkEmailInput = () => {
        const validEmail = this.state.emailValue.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        if (validEmail) {
            return null;
        }
        return 'Your email address is invalid';
    }

    onValueChange(key, event) {
        this.setState({[key]: event.target.value})
    }

    stepChange = (step) => {
        if (step === 3) {
            this.setState({lastPage: true})
        }
        if (step === 2) {
            this.setState({firstPage: false, lastPage: false})
        }
        if (step === 1) {
            this.setState({firstPage: false, lastPage: false})
        }
        if (step === 0) {
            this.setState({firstPage: true, lastPage: false})
        }
    }

    addNewForm = () => {
        return <ContactForm style={{marginTop: 20}}/>
    }

    clearContactFormInput = () => {
        this.setState({
            contactType: '',
            contactFirstName: '',
            contactLastName: '',
            contactPhone: '',
            contactEmail: ''
        })
    }

    render() {
        const {
            note,
            message,
            siteAddress,
            siteCity,
            contactEmail,
            contactEmailTwo,
            contactPhoneTwo,
            siteState,
            siteZip,
            firstPage,
            emailTwoValue,
            emailValue,
            addressTwoValue,
            cityValue,
            countryValue,
            countyValue,
            phoneTwoValue,
            stateValue,
            zipValue,
            lastPage,
            isSubmitted,
            customerValue,
            companyValue,
            firstNameValue,
            lastNameValue,
            phoneValue,
            addressValue,
            contactType,
            contactFirstName,
            contactLastName,
            contactPhone,
            siteContact,
            siteFullAddress,
            siteName
        } = this.state;
        const requiredBillingForm = !emailValue || !customerValue || !companyValue || !firstNameValue || !lastNameValue || !phoneValue || !addressValue;
        const requiredContactForm = !contactEmail || !contactType || !contactFirstName || !contactLastName || !contactPhone;
        const validEmail = this.state.emailValue.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        const steps =
            [
                {
                    name: 'Billings', component: <BillingContact
                        value={[customerValue, companyValue, firstNameValue, lastNameValue, phoneValue, phoneTwoValue, emailValue, emailTwoValue, addressValue, addressTwoValue, cityValue, countyValue, stateValue, zipValue, countryValue]}
                        onCustomerValueChange={this.onValueChange.bind(this, 'customerValue')}
                        onCompanyValueChange={this.onValueChange.bind(this, 'companyValue')}
                        onFirstNameFieldChange={this.onValueChange.bind(this, 'firstNameValue')}
                        onLastNameFieldChange={this.onValueChange.bind(this, 'lastNameValue')}
                        onAddressFieldChange={this.onValueChange.bind(this, 'addressValue')}
                        onAddressTwoFieldChange={this.onValueChange.bind(this, 'addressTwoValue')}
                        onPhoneFieldChange={this.onValueChange.bind(this, 'phoneValue')}
                        onEmailFieldChange={this.onValueChange.bind(this, 'emailValue')}
                        onCityFieldChange={this.onValueChange.bind(this, 'cityValue')}
                        onZipFieldChange={this.onValueChange.bind(this, 'zipValue')}
                        onStateFieldChange={this.onValueChange.bind(this, 'stateValue')}
                        checkEmailInput={this.checkEmailInput}
                    />
                },
                {
                    name: 'Contacts', component: <ContactWrapper
                        clearContactFormInput={this.clearContactFormInput}
                        value={[contactType, contactFirstName, contactLastName, contactPhone, contactPhoneTwo, contactEmail, contactEmailTwo]}
                        onContactTypeChange={this.onValueChange.bind(this, 'contactType')}
                        onContactFirstNameChange={this.onValueChange.bind(this, 'contactFirstName')}
                        onContactLastNameChange={this.onValueChange.bind(this, 'contactLastName')}
                        onContactPhoneChange={this.onValueChange.bind(this, 'contactPhone')}
                        onContactPhoneTwoChange={this.onValueChange.bind(this, 'contactPhoneTwo')}
                        onContactEmailChange={this.onValueChange.bind(this, 'contactEmail')}
                        onContactEmailTwoChange={this.onValueChange.bind(this, 'contactEmailTwo')}
                    />
                },
                {
                    name: 'Sites', component: <SiteWrapper
                        value={[siteContact, siteName, siteFullAddress, siteAddress, siteCity, siteState, siteZip]}
                        onSiteContactChange={this.onValueChange.bind(this, 'siteContact')}
                        onSiteNameChange={this.onValueChange.bind(this, 'siteName')}
                        onSiteFullAddressChange={this.onValueChange.bind(this, 'siteFullAddress')}
                        onSiteAddressChange={this.onValueChange.bind(this, 'siteAddress')}
                        onSiteCityChange={this.onValueChange.bind(this, 'siteCity')}
                        onSiteStateChange={this.onValueChange.bind(this, 'siteState')}
                        onSiteZipChange={this.onValueChange.bind(this, 'siteZip')}
                    />
                },
                {
                    name: 'Message', component: <Message
                        value={[note, message]}
                        onNoteChange={this.onValueChange.bind(this, 'note')}
                        onMessageChange={this.onValueChange.bind(this, 'message')}
                    />
                }
            ]
        return (
            <div className="formWrapper">
                <Col md={3}/>
                <Col md={6} style={{height: '80vh', padding: 0}}>
                    {!isSubmitted ?
                        (<Form horizontal onSubmit={this.onSubmit}>
                            <div className='step-progress'>
                                <StepZilla
                                    steps={steps}
                                    stepsNavigation={requiredBillingForm && firstPage || requiredContactForm && !firstPage ? false : true}
                                    preventEnterSubmission={true}
                                    nextButtonCls='btn btn-prev btn-primary pull-right'
                                    backButtonCls='btn btn-next btn-primary pull-left'
                                    onStepChange={(step) => this.stepChange(step)}
                                />
                            </div>
                            {lastPage ?
                                <Button type="submit" bsStyle="success"
                                        className="saveBtnPosition">Send</Button> : null}
                        </Form>)
                        :
                        <Jumbotron
                            style={{paddingTop: isMobile ? 8 : 10, paddingBottom: 10, marginBottom: 15, marginTop: 58}}
                            className="formJumbotron centerJumbotronItems">
                            <FontAwesomeIcon icon="check" className="accept"/>
                            <h3>Your form has been sent, thank you</h3>
                        </Jumbotron>
                    }
                </Col>
                <Col md={3}/>
            </div>
        );
    }
}

export default CustomerContactForm;