import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Button, Col, FormControl, Glyphicon, Grid, MenuItem, NavDropdown, Panel, Row} from "react-bootstrap";
import Select from "react-select";
import {GoogleMap, Marker} from "react-google-maps";
import {GoogleMapLoader} from "../../components/GoogleMapLoader";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import * as actions from "./TimeTracksApi";
import {
    convertMillisToTime,
    defaultDateFormat,
    defaultMapParams,
    saveNewCsvDownload,
    select
} from "../../common/commonHandlers";
import ResourceComponent from "../../components/ResourceComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {debounce} from "throttle-debounce";
import Datetime from "react-datetime";
import moment from "moment-timezone"
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import {LinkContainer} from "react-router-bootstrap";
import {browserHistory, Link} from "react-router";
import './TimeTracks.scss'
import ExcelJS from 'exceljs';
import {dateFormatter, dateNoTimeFormatter, dollarsFormatter} from "../../components/BootStrapTableCellFormatters";
import _ from 'lodash'
import {saveAs} from 'file-saver';
import {getBasicMarker} from "../../utilities";
import {dollar} from "../../components/Dollars";
import ColorCheckbox from "../Scheduler/ColorCheckbox";

const isMobile = window.screen.width <= 1024;
const fullDaySeconds = 86400

class CustomerSearch extends ResourceComponent {
    state = {
        filterVisible: true,
        time_tracks: [],
        resource: {page: 1, per_page: 10, label: null},
        employees: [],
        total: 0,
        center: true,
        dateFilterOptions: ['Today', 'Yesterday', 'This Week', 'Last Week', 'This Month-to-date', 'This Month', 'Last Month', 'This Year-to-date', 'Last Year', 'Custom'],
        customFilter: false,
        time_track_types: [],
        time_track_id_to_update: null,
        proposals: [],
        work_orders: [],
        show_not_started: false,
    };

    tableRef = React.createRef()

    stopTimeTracks = (timeTrackIds) => {
        console.log('stop timer')
        let {time_tracks} = this.state;
        const now = moment();
        const timeString = now.format('HH:mm:ss');
        const to = moment.duration(timeString).asSeconds();
        this.props.actions.stopTimeTracks(timeTrackIds, now, (res) => {
            time_tracks = time_tracks.map(tt => {
                if (timeTrackIds.includes(tt.id)) {
                    tt.time_to = to
                    const diff = to - tt.time_from;
                    tt.total_seconds = moment.duration(diff * 1000).asSeconds();
                    return tt
                } else {
                    return tt
                }
            })
            this.setState({time_tracks});
        })
    }

    resourceFormatter = (cell, row) => {
        const {employees, time_tracks} = this.state;
        const employee = employees.find(e => e.value === row.person_id);
        const employeeOptions = employees.filter(x => x.label !== 'Not assigned');
        return <Select className="Select" classNamePrefix="select"
                       options={employeeOptions}
                       isClearable
                       value={select(employeeOptions, row.person_id)}
                       onChange={e => {
                           row.person_id = e && e.value;
                           this.setState({time_tracks, time_track_id_to_update: row.id}, () => {
                               this.delayedSave();
                           })
                       }
                       }
        />
    };

    csvResourceFormatter = (cell, row) => {
        const {employees, time_tracks} = this.state;
        const employee = employees.find(e => e.value === row.person_id);
        const employeeOptions = employees.filter(x => x.label !== 'Not assigned');
        let name = select(employeeOptions, row.person_id)?.label
        return name || '';
    }

    timeStartFormatter = (cell, row) => {
        let {time_tracks} = this.state;
        return <TimePicker
            showSecond={false}
            placeholder="Start"
            defaultValue={row.time_from ? moment.utc(row.time_from * 1000) : null}
            value={row.time_from ? moment.utc(row.time_from * 1000) : null}
            onChange={e => {
                if (moment.duration(e.format('HH:mm:ss')).asSeconds() < row.time_to || row.time_to === null) {
                    row.time_from = moment.duration(e.format('HH:mm:ss')).asSeconds() % fullDaySeconds
                    this.setState({invalidDate: false, changesMade: true})
                } else {
                    this.setState({invalidDate: true, changesMade: false})
                }
            }}
            onClose={() => {
                if (!this.state.invalidDate && this.state.changesMade) {
                    const diff = row.time_to - row.time_from;
                    row.total_seconds = moment.duration(diff * 1000).asSeconds();
                    this.setState({time_tracks, time_track_id_to_update: row.id}, this.delayedSave);
                }
            }
            }
            format={'h:mm:ss a'}
            use12Hours
            allowEmpty={false}
            disabledHours={() => row.time_to ? this.getDisabledHours(row.time_from, row.time_to, 'higher') : null}
            disabledMinutes={() => row.time_to ? this.getDisabledMinutes(row.time_from, row.time_to, 'higher') : null}
        />
    };

    timeStopFormatter = (cell, row) => {
        let {time_tracks} = this.state;
        return row.time_to || (!row.time_to && !row.time_from) ? <TimePicker
                showSecond={false}
                placeholder="Stop"
                defaultValue={row.time_to ? moment.utc(row.time_to * 1000) : null}
                value={row.time_to ? moment.utc(row.time_to * 1000) : null}
                onChange={e => {
                    if (moment.duration(e.format('HH:mm:ss')).asSeconds() > row.time_from) {
                        row.time_to = moment.duration(e.format('HH:mm:ss')).asSeconds() % fullDaySeconds
                        this.setState({invalidDate: false, changesMade: true})
                    } else {
                        this.setState({invalidDate: true, changesMade: false})
                    }
                }}
                onClose={() => {
                    if (!this.state.invalidDate && this.state.changesMade) {
                        const diff = row.time_to - row.time_from;
                        row.total_seconds = moment.duration(diff * 1000).asSeconds();
                        this.setState({time_tracks, time_track_id_to_update: row.id}, this.delayedSave);
                    }
                }
                }
                format={'h:mm:ss a'}
                use12Hours
                allowEmpty={false}
                disabledHours={() => row.time_from ? this.getDisabledHours(row.time_to, row.time_from, 'lower') : null}
                disabledMinutes={() => row.time_from ? this.getDisabledMinutes(row.time_to, row.time_from, 'lower') : null}
            /> :
            <Button className='btn btn-danger'
                    onClick={() => this.stopTimeTracks([row.id])}>
                Stop
            </Button>
    };

    csvStartStopTimeFormatter = (cell) => {
        return cell ? moment.utc(cell * 1000).format('h:mm:ss a') : '';
    }

    totalTimeFormatter = (cell, row) => {
        if (!row.time_from && !row.time_to) {
            return 'N/A';
        }
        let total
        total = row.total_seconds > 0 ? convertMillisToTime(row.total_seconds * 1000) : 'In progress'
        return total;
    }

    typeFormatter = (cell, row) => {
        const {time_track_types} = this.state;
        return <Select className="Select"
                       classNamePrefix="select"
                       options={time_track_types}
                       value={select(time_track_types, row.time_track_type_id)}
                       placeholder="Type"
                       onChange={e => {
                           let {time_tracks} = this.state;
                           row.time_track_type_id = e !== null && e.value;
                           this.setState({time_tracks, time_track_id_to_update: row.id}, () => {
                               this.delayedSave();
                           })
                       }}/>
    };

    csvTypeFormatter = (cell, row) => {
        const {time_track_types} = this.state;
        let type = select(time_track_types, row.time_track_type_id)?.label
        return type || ''
    }

    handleButtonsFilterChange = e => {
        let {resource} = this.state;
        if (e?.value === 'Custom') {
            this.setState({customFilter: !this.state.customFilter}, () => {
                resource.label = e.value;
                this.setState({resource}, this.handleFilterChange);
            });
        } else {
            this.setState({customFilter: false}, () => {
                resource.label = e?.value;
                this.setState({resource}, this.handleFilterChange);
            });
        }
    };

    dateFormatter = (cell, row) => {
        let {time_tracks} = this.state;
        return <Datetime
            dateFormat={defaultDateFormat}
            timeFormat={false}
            value={row.track_date ? moment(row.track_date).format(defaultDateFormat) : null}
            className="full-width table-auto"
            inputProps={{placeholder: 'Date'}}
            onChange={e => {
                row.track_date = e;
                this.setState({time_tracks, time_track_id_to_update: row.id}, () => {
                    this.delayedSave();
                });
            }}/>
    };

    customerFormatter = (cell, row) => {
        return <NavDropdown
            eventKey={2}
            title={<div
                className={isMobile ? 'text-blue bottom10' : 'text-blue flex-column-end bottom10 break-word-issue'}>{row.customer_name}</div>}
            noCaret
        >
            <LinkContainer to={`/customer/info/${row.customer_id}`}>
                <MenuItem>Info</MenuItem>
            </LinkContainer>
            <LinkContainer to={`/customer/proposals/${row.customer_id}`}>
                <MenuItem bsSize="small">Proposals</MenuItem>
            </LinkContainer>
            <LinkContainer to={`/customer/sites/${row.customer_id}`}>
                <MenuItem bsSize="small">Sites</MenuItem>
            </LinkContainer>
            <LinkContainer to={`/customer/work_orders/${row.customer_id}`}>
                <MenuItem bsSize="small">Work Orders</MenuItem>
            </LinkContainer>
            <LinkContainer to={`/customer/invoices/${row.customer_id}`}>
                <MenuItem bsSize="small">Invoices</MenuItem>
            </LinkContainer>
            <LinkContainer to={`/customer/work_history/${row.customer_id}`}>
                <MenuItem>Work History</MenuItem>
            </LinkContainer>
        </NavDropdown>
    };

    proposalFormatter = (cell, row) => {
        return <div>
            <NavDropdown
                title={<div
                    className='text-blue flex-column-end bottom10'>P #{row.proposal_no}</div>}
                noCaret
            >
                <LinkContainer
                    to={`/mapview/${row.proposal_id}`}>
                    <MenuItem bsSize="small" eventKey={2.6}>Proposal</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/proposal_cost/${row.proposal_id}`}>
                    <MenuItem bsSize="small">Proposal Cost</MenuItem>
                </LinkContainer>
            </NavDropdown>
            <LinkContainer
                to={`/crew_work_orders`}
                className='text-blue flex-column-end bottom10'>
                <MenuItem bsSize="small" eventKey={2.9}>WO #{row.work_order_no}</MenuItem>
            </LinkContainer>
        </div>
    };

    rowFormatter = (cell, row) => {
        return <div>
            <Row>
                <Col xs={6}>Proposal #</Col>
                <Col xs={3} className='text-center'>
                    <NavDropdown
                        title={<div
                            className='text-blue bottom10'>P #{row.proposal_no}</div>}
                        noCaret
                    >
                        <LinkContainer
                            to={`/mapview/${row.proposal_id}`}>
                            <MenuItem bsSize="small" eventKey={2.6}>Proposal</MenuItem>
                        </LinkContainer>
                        <LinkContainer to={`/proposal_cost/${row.proposal_id}`}>
                            <MenuItem bsSize="small">Proposal Cost</MenuItem>
                        </LinkContainer>
                    </NavDropdown>
                </Col>
                <Col xs={3} className='text-center'>
                    <LinkContainer
                        to={`/crew_work_orders`}
                        className='text-blue bottom10 dropdown'>
                        <MenuItem bsSize="small" eventKey={2.9}>WO #{row.work_order_no}</MenuItem>
                    </LinkContainer>
                </Col>
            </Row>
            <Row>
                <Col xs={6}>Customer</Col>
                <Col xs={6} className='text-center'>{this.customerFormatter(cell, row)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Time Type</Col>
                <Col xs={6}>{this.typeFormatter(cell, row)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Date</Col>
                <Col xs={6}>{this.dateFormatter(cell, row)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Start</Col>
                <Col xs={6}>{this.timeStartFormatter(cell, row)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Stop</Col>
                <Col xs={6}>{this.timeStopFormatter(cell, row)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Total time</Col>
                <Col xs={6} className='text-center padding-top-10'>{this.totalTimeFormatter(cell, row)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Arborist</Col>
                <Col xs={6} className='text-center padding-top-10'>{row.arborist}</Col>
            </Row>
            <Row>
                <Col xs={6}>Resource</Col>
                <Col xs={6}>{this.resourceFormatter(cell, row)}</Col>
            </Row>
        </div>
    };

    saveTimeTracks = () => {
        const {time_tracks, time_track_id_to_update} = this.state;
        const time_track = time_tracks.find(track => track.id === time_track_id_to_update);
        this.props.actions.saveTimeTracks(
            {
                id: time_track.id,
                person_id: time_track.person_id,
                time_from: time_track.time_from,
                time_to: time_track.time_to,
                time_track_type_id: time_track.time_track_type_id,
                total_seconds: time_track.total_seconds,
                track_date: time_track.track_date
            })
    };

    constructor(props, context) {
        super(props, context);
        this.delayedSearch = debounce(500, this.search);
        this.customerSearch = {
            searchOptions: () => [],
            search: () => {
            }
        };
        this.proposalSearch = {
            searchOptions: () => [],
            search: () => {
            }
        };
        this.workOrderSearch = {
            searchOptions: () => [],
            search: () => {
            }
        };
        this.delayedSave = debounce(1000, this.saveTimeTracks);
    }

    search() {
        const {resource, show_not_started} = this.state;
        this.props.actions.getTimeTracks({...resource, show_not_started}, results => {
            this.setState({time_tracks: results.content, total: results.total});
        });
    }

    handleFilterChange() {
        const {resource} = this.state
        if (resource.page > 1) {
            this.setState({resource: {...resource, page: 1}}, () => {
                this.delayedSearch()
            })
        } else {
            this.delayedSearch()
        }
    }

    componentDidMount() {
        this.customerSearch = this.buildSearchComponent('customer_id', this.props.actions.searchForCustomer);
        this.proposalSearch = this.buildSearchComponent('proposal_id', this.props.actions.searchForProposal);
        this.workOrderSearch = this.buildSearchComponent('work_order_id', this.props.actions.searchForWorkOrder);
        this.props.actions.load(result => this.setState(result, this.delayedSearch));
    }

    timeTracksCSVFormatter = (array) => {
        return array.map(data => {
            Object.keys(data).forEach(key => {
                if (['id', 'updated_at', 'work_order_id', 'proposal_id', 'latitude', 'longitude', 'customer_id'].includes(key)) {
                    delete data[key]
                } else if (['person_id'].includes(key)) {
                    data[key] = select(this.state.employees.filter(x => x.label !== 'Not assigned'), data[key])?.label ?? ""
                } else if (['track_date'].includes(key)) {
                    data[key] = dateNoTimeFormatter(data[key])
                } else if (['time_track_type_id'].includes(key)) {
                    data[key] = select(this.state.time_track_types, data[key])?.label ?? ""
                } else if (['total_seconds', 'actual_wo_time'].includes(key)) {
                    data[key] = data[key] > 0 ? convertMillisToTime(data[key] * 1000) : 'In progress'
                } else if (['time_from', 'time_to'].includes(key)) {
                    data[key] = data[key] > 0 ? convertMillisToTime(data[key] * 1000) : 'N/A'
                } else if (['est_wo_time'].includes(key)) {
                    data[key] = data[key] > 0 ? convertMillisToTime(data[key] * 1000 * 60 * 60) : 'N/A'
                } else if (['pmh', 'estimated_total'].includes(key)) {
                    data[key] = data[key] ? dollar(data[key]) : 'N/A'
                } else {
                    data[key] = data[key]
                }
            })
            return data
        })
    }
    createCustomToolBar = props => {
        const {time_tracks} = this.state
        return (
            <div className='table-toolbar'>
                <div className='buttons'>
                    {props.components.btnGroup}
                    <Button
                        bsStyle="success"
                        disabled={time_tracks.length < 1}
                        onClick={async () => {
                            const timeTracks = this.timeTracksCSVFormatter(_.cloneDeep(time_tracks))

                            const workbook = new ExcelJS.Workbook();
                            const worksheet = workbook.addWorksheet('Time Tracks');

                            worksheet.addRow(['Employee',
                                'Est Time',
                                'Actual WO Time',
                                'Total Time',
                                'Time Track Type',
                                'Track Date',
                                'Time From',
                                'Time To',
                                'Estimated Total',
                                'PMH',
                                'Proposal No',
                                'Work Order No',
                                'Arborist',
                                'Site Address',
                                'Customer Name',
                                'Customer Email Address',
                            ]);

                            timeTracks.forEach((track) => {
                                worksheet.addRow([track.person_id,
                                    track.est_wo_time,
                                    track.actual_wo_time,
                                    track.total_seconds,
                                    track.time_track_type_id,
                                    track.track_date,
                                    track.time_from,
                                    track.time_to,
                                    track.estimated_total,
                                    track.pmh,
                                    track.proposal_no,
                                    track.work_order_no,
                                    track.arborist,
                                    track.site_address,
                                    track.customer_name,
                                    track.customer_email_address,
                                ]);
                            });

                            worksheet.eachRow({includeEmpty: true}, (row, rowNumber) => {
                                if (rowNumber === 1) {
                                    row.eachCell((cell) => {
                                        cell.fill = {type: 'pattern', pattern: 'solid', fgColor: {argb: 'ffb2b2b2'}};
                                        cell.font = {bold: true};
                                    })
                                }
                                if (rowNumber > 1) {
                                    const cellValueB = row.getCell(2).value;
                                    const cellValueC = row.getCell(3).value;

                                    const actTime = moment.duration(cellValueC, 'seconds')
                                    const positiveTime = moment.duration(cellValueB, 'seconds').add(30, 'minutes')
                                    const moderateTime = moment.duration(cellValueB, 'seconds').add(60, 'minutes')
                                    if (actTime.asSeconds() < positiveTime.asSeconds()) {
                                        row.eachCell((cell) => {
                                            cell.fill = {
                                                type: 'pattern',
                                                pattern: 'solid',
                                                fgColor: {argb: 'ffa4ffa4'}
                                            };
                                        });
                                    } else if (actTime.asSeconds() < moderateTime.asSeconds()) {
                                        row.eachCell((cell) => {
                                            cell.fill = {
                                                type: 'pattern',
                                                pattern: 'solid',
                                                fgColor: {argb: 'ffd2b48c'}
                                            };
                                        });
                                    } else {
                                        row.eachCell((cell) => {
                                            cell.fill = {
                                                type: 'pattern',
                                                pattern: 'solid',
                                                fgColor: {argb: 'ffff6347'}
                                            };
                                        });
                                    }
                                }
                            });
                            worksheet.columns.forEach(column => {
                                const lengths = column.values.map(v => v.toString().length);
                                const maxLength = Math.max(...lengths.filter(v => typeof v === 'number'));
                                column.width = maxLength + 5;
                            });

                            const excelBuffer = await workbook.xlsx.writeBuffer();
                            const dataBlob = new Blob([excelBuffer], {type: 'application/octet-stream'});
                            saveAs(dataBlob, 'time-tracks.xlsx');
                            dataBlob.name = 'time-tracks.xlsx'
                            this.props.actions.saveDownloadedFile(dataBlob, null, "time-tracks")
                        }}>
                        <i class="fa glyphicon glyphicon-export fa-download"/> Export worksheet
                    </Button>
                </div>
            </div>
        );
    }

    render() {
        const {
            filterVisible, time_tracks, employees, resource,
            total, center, customFilter, time_track_types, dateFilterOptions, proposals, work_orders
        } = this.state;
        const {page, per_page} = resource;
        const {client} = this.props;
        const customerOptions = this.customerSearch.searchOptions();
        const proposalsOptions = this.proposalSearch.searchOptions();
        const workOrdersOptions = this.workOrderSearch.searchOptions();
        //DEPRECATED
        if (center) {
            this.setState({center: false});
        }
        let dateOptions = dateFilterOptions.map(filter => {
            let result = {};
            result.value = filter;
            result.label = filter;
            return result;
        });
        return (
            <Grid fluid id={'time-tracks'}>
                <Row>
                    <Col md={12}>
                    </Col>
                </Row>
                <Row className="top-menu">
                    <Col md={6} className="time_tracks vcenter">
                        <h2 className="no-top-margin small-margin">Job Cost</h2>
                        <h5 className="text-primary">{total} returned</h5>
                        <Link onClick={() => browserHistory.push(`/proposal_cost`)}
                              className={`${window.location.href.includes('/time_tracks') ? '' : 'active'} small-margin`}>Proposal
                            Cost</Link>
                    </Col>
                    <Col md={6} className="text-left">
                        <Button
                            className="pointer no-top-margin"
                            bsStyle="warning"
                            onClick={() => {
                                this.setState({filterVisible: !filterVisible})
                            }}>
                            Filter {" "}<FontAwesomeIcon icon="search" className="small-margin"/>
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Panel
                        collapsible
                        expanded={filterVisible}
                    >
                        <div>
                            <Row className='bottom10'>
                                <Col md={3}>
                                    <FormControl
                                        name="q"
                                        placeholder="Search..."
                                        value={resource.q}
                                        onChange={e => this.updateResourceAttr(e, this.handleFilterChange)}
                                    />
                                </Col>
                                <Col md={2}>
                                    <Select className="Select"
                                            classNamePrefix="select"
                                            value={select(customerOptions, resource.customer_id)}
                                            options={customerOptions}
                                            placeholder="Customer"
                                            isClearable
                                            onInputChange={this.customerSearch.search}
                                            onChange={this.selectResourceAttr('customer_id', this.handleFilterChange)}
                                    />
                                </Col>
                                <Col md={2}>
                                    <Select className="Select" classNamePrefix="select"
                                            value={select(employees, resource.arborist_id)}
                                            options={employees}
                                            isClearable
                                            onChange={this.selectResourceAttr('arborist_id', this.handleFilterChange)}
                                            placeholder="Arborist"
                                    />
                                </Col>
                                <Col md={2}>
                                    <Select className="Select" classNamePrefix="select"
                                            value={select(employees, resource.person_id)}
                                            options={employees}
                                            isClearable
                                            onChange={this.selectResourceAttr('person_id', this.handleFilterChange)}
                                            placeholder="Resource"
                                    />
                                </Col>
                                <Col md={2}>
                                    <Select className="Select" classNamePrefix="select"
                                            value={select(time_track_types, resource.time_track_type_id)}
                                            options={time_track_types}
                                            isClearable
                                            onChange={this.selectResourceAttr('time_track_type_id', this.handleFilterChange)}
                                            placeholder="Type"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={2}>
                                    <Select className="Select"
                                            classNamePrefix="select"
                                            value={select(proposalsOptions, resource.proposal_id)}
                                            options={proposalsOptions}
                                            placeholder="Proposal #"
                                            isClearable
                                            onInputChange={this.proposalSearch.search}
                                            onChange={this.selectResourceAttr('proposal_id', this.handleFilterChange)}
                                    />
                                </Col>
                                <Col md={2}>
                                    <Select className="Select"
                                            classNamePrefix="select"
                                            value={select(workOrdersOptions, resource.work_order_id)}
                                            onInputChange={this.workOrderSearch.search}
                                            options={workOrdersOptions}
                                            placeholder="Work Order #"
                                            isClearable
                                            onChange={this.selectResourceAttr('work_order_id', this.handleFilterChange)}
                                    />
                                </Col>
                                <Col md={2} className={`bottom10`}>
                                    <Select className="Select"
                                            classNamePrefix="select"
                                            value={select(dateOptions, resource.label)}
                                            options={dateOptions}
                                            placeholder="Date"
                                            isClearable
                                            onChange={this.handleButtonsFilterChange}
                                    />
                                </Col>
                                {
                                    customFilter && <Col md={4} className={`small-right-padding`}>
                                        <Datetime
                                            dateFormat={defaultDateFormat}
                                            isClearable
                                            timeFormat={null}
                                            value={resource.from}
                                            className="half small-margin"
                                            inputProps={{placeholder: 'From'}}
                                            onChange={this.dateResourceAttr('from', this.search)}/>
                                        <span className='text-default'><Glyphicon glyph='minus'/></span>
                                        <Datetime
                                            dateFormat={defaultDateFormat}
                                            isClearable
                                            timeFormat={null}
                                            value={resource.to}
                                            className="half small-margin"
                                            inputProps={{placeholder: 'To'}}
                                            onChange={this.dateResourceAttr('to', this.search)}
                                        />
                                    </Col>
                                }
                                <Col md={2} className={`bottom10`}>
                                    <ColorCheckbox
                                        label={"Show not started time tracks"}
                                        value={this.state.show_not_started}
                                        onChange={() => this.setState({
                                            show_not_started: !this.state.show_not_started
                                        }, this.search)}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Panel>
                </Row>
                {isMobile ?
                    <Col md={7} className={`remaining vertical-responsive-height`}>
                        <BootstrapTable
                            ref={this.tableRef}
                            data={time_tracks}
                            striped={true}
                            bordered={false}
                            hover={true}
                            exportCSV={false}
                            pagination={true}
                            remote
                            fetchInfo={{dataTotalSize: total}}
                            className="wrapped"
                            trClassName={this.selectedMarkerClass}
                            csvFileName={() => {
                                const fileName = `customers-${moment().format()}.csv`
                                if (this.tableRef?.current) {
                                    this.props.actions.saveNewCsvDownload(this.tableRef.current, fileName, this.state.resource, "time tracks")
                                }
                                return fileName
                            }}
                            options={
                                {
                                    toolBar: this.createCustomToolBar,
                                    onPageChange: (page, per_page) => {
                                        let {resource} = this.state;
                                        resource = {...resource, page, per_page};
                                        this.setState({resource}, this.delayedSearch);
                                    },
                                    page,
                                    sizePerPage: per_page,
                                    onRowClick: row => {
                                        this.selectMarker('time_tracks')(row);
                                    }
                                }
                            }
                        >
                            <TableHeaderColumn
                                dataField="id"
                                hidden
                                width={"5%"}
                                isKey
                            />
                            <TableHeaderColumn
                                dataFormat={this.rowFormatter}
                                width={"20%"}
                                export={false}
                            >
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="proposal_no"
                                width={"10%"}
                                hidden
                                export
                            >
                                Proposal #
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="customer_name"
                                width={"10%"}
                                hidden
                                export
                            >
                                Customer
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width={"15%"}
                                dataField={'time_type'}
                                csvFormat={this.csvTypeFormatter}
                                hidden
                                export
                            >
                                Time Type
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="track_date"
                                csvHeader="updated_at"
                                csvFormat={dateFormatter}
                                width={"15%"}
                                hidden
                                export
                            >
                                Date
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="time_from"
                                csvFormat={this.csvStartStopTimeFormatter}
                                width={"15%"}
                                hidden
                                export
                            >
                                Start
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="time_to"
                                csvFormat={this.csvStartStopTimeFormatter}
                                width={"15%"}
                                hidden
                                export
                            >
                                Stop
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="totalTime"
                                csvFormat={this.totalTimeFormatter}
                                width={"10%"}
                                hidden
                                export
                            >
                                Total time
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="arborist"
                                width={"10%"}
                                csvFormat={(cell) => cell ? cell : ""}
                                hidden
                                export
                            >
                                Rep
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="resource"
                                csvFormat={this.csvResourceFormatter}
                                width={"20%"}
                                hidden
                                export
                            >
                                Resource
                            </TableHeaderColumn>
                        </BootstrapTable>
                    </Col>
                    :
                    <Col md={7}
                         className={`remaining vertical-responsive-height ${filterVisible ? 'filter-visible' : ''}`}>
                        <BootstrapTable
                            ref={this.tableRef}
                            data={time_tracks}
                            striped={true}
                            bordered={false}
                            hover={true}
                            exportCSV={false}
                            pagination={true}
                            remote
                            fetchInfo={{dataTotalSize: total}}
                            className="wrapped"
                            trClassName={this.selectedMarkerClass}
                            csvFileName={() => {
                                const fileName = `customers-${moment().format()}.csv`
                                if (this.tableRef?.current) {
                                    this.props.actions.saveNewCsvDownload(this.tableRef.current, fileName, this.state.resource, "time tracks")
                                }
                                return fileName
                            }}
                            options={
                                {
                                    toolBar: this.createCustomToolBar,
                                    sizePerPageDropDown: this.renderSizePerPageDropDown,
                                    sizePerPageList: [{
                                        text: '10', value: 10
                                    }, {
                                        text: '25', value: 25
                                    },
                                        {
                                            text: '30', value: 30
                                        },
                                        {
                                            text: '50', value: 50
                                        },
                                        {
                                            text: '100', value: 100
                                        },
                                        {
                                            text: '200', value: 200
                                        },
                                        {
                                            text: 'All', value: total
                                        }],
                                    onPageChange: (page, per_page) => {
                                        let {resource} = this.state;
                                        resource = {...resource, page, per_page};
                                        this.setState({resource}, this.delayedSearch);
                                    },
                                    page,
                                    sizePerPage: per_page,
                                    onRowClick: row => {
                                        this.selectMarker('time_tracks')(row);
                                    }
                                }
                            }
                        >
                            <TableHeaderColumn
                                dataField="id"
                                hidden
                                width={"5%"}
                                isKey
                            />
                            <TableHeaderColumn
                                dataField="proposal_no"
                                dataFormat={this.proposalFormatter}
                                width={"10%"}
                            >
                                Contract
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="customer_name"
                                dataFormat={this.customerFormatter}
                                width={"10%"}
                            >
                                Customer
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="time_track_type_id"
                                width={"15%"}
                                dataField={'time_type'}
                                dataFormat={this.typeFormatter}
                                csvFormat={this.csvTypeFormatter}
                            >
                                Time Type
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="track_date"
                                csvHeader="updated_at"
                                dataFormat={this.dateFormatter}
                                csvFormat={dateFormatter}
                                width={"15%"}
                            >
                                Date
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="time_from"
                                dataFormat={this.timeStartFormatter}
                                csvFormat={this.csvStartStopTimeFormatter}
                                width={"15%"}
                            >
                                Start
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="time_to"
                                dataFormat={this.timeStopFormatter}
                                csvFormat={this.csvStartStopTimeFormatter}
                                width={"15%"}
                            >
                                Stop
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="total_seconds"
                                dataFormat={this.totalTimeFormatter}
                                csvFormat={this.totalTimeFormatter}
                                width={"10%"}
                            >
                                Total time
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="estimated_total"
                                width={"15%"}
                                dataFormat={(cell) => dollarsFormatter(cell ? cell.replace(/[^0-9.-]/g, '') : cell)}
                            >
                                Estimated Total
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="pmh"
                                width={"15%"}
                                dataFormat={(cell) => dollarsFormatter(cell ? cell.replace(/[^0-9.-]/g, '') : cell)}
                            >
                                PMH
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="arborist"
                                width={"10%"}
                                csvFormat={(cell) => cell ? cell : ""}
                            >
                                Arborist
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="person_id"
                                dataFormat={this.resourceFormatter}
                                csvFormat={this.csvResourceFormatter}
                                width={"20%"}
                            >
                                Resource
                            </TableHeaderColumn>
                        </BootstrapTable>
                    </Col>}
                <Col md={5}>
                    <GoogleMapLoader
                        containerElement={
                            <div
                                id="timeTracksMap"
                                className={`remaining vertical-responsive-height ${filterVisible ? 'filter-visible' : ''}`}
                            />
                        }
                        googleMapElement={
                            <GoogleMap
                                ref={it => this.refGoogleMap(it, time_tracks.filter(param => param.latitude))}
                                {...defaultMapParams(time_tracks, center, null, client)}
                            >
                                {time_tracks.map(track => {
                                    const employee = select(employees, track.person_id);
                                    const employeeColor = (employee ? employee.color : '#3a87ad');
                                    return track.latitude && (
                                        <Marker
                                            key={track.id}
                                            position={{lat: track.latitude, lng: track.longitude}}
                                            icon={getBasicMarker({
                                                selected: track.selected,
                                                color: employeeColor
                                            })}
                                            title={track.site_address}
                                            onClick={() => {
                                                const page = this.refs.table.getPageByRowKey(track.id);
                                                this.setState({page});
                                                this.selectMarker('time_tracks')(track);
                                            }}
                                            zIndex={track.selected ? 1000 : 1}
                                        />
                                    )

                                })}
                            </GoogleMap>
                        }
                    />
                </Col>
            </Grid>
        );
    }
}

CustomerSearch.propTypes = {
    //myProp: PropTypes.object.isRequired
};

CustomerSearch.defaultProps = {
    //myProp: <defaultValue>
};

function mapStateToProps(state, ownProps) {
    return {
        email: state.auth.email,
        client: state.client.customerInfo
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({...actions, saveNewCsvDownload}, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerSearch);
