import React, {useEffect, useState} from "react";
import {Button, Col, FormControl, Glyphicon, Modal, Row} from "react-bootstrap";
import './TrucksModal.scss'
import {faCaretDown, faCheck, faEllipsisV, faToggleOff, faToggleOn} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {HuePicker} from 'react-color'
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import ColorCheckbox from "../Scheduler/ColorCheckbox";

const colors = ['#F090BB', '#FBC2CD', '#FADD8D', '#82DEAB', '#93ECEA', '#3ECACB', '#00BFDC', '#9EA3E4', '#CA7EC4', '#FF6875', '#B6DEE7', '#FCD9DD', '#F9E2CF', '#F2CDAF', '#ED5D8F', '#FF98A8', '#FFB4C9', '#FFC89A', '#FF9D60', '#FF6875']
export const TruckModal = ({
                               isOpen,
                               closeModal,
                               data,
                               inputType,
                               addOption,
                               updateOption,
                               removeOption,
                               updateOptionsOrder
                           }) => {

    const [addingNew, setAddingNew] = useState(false)
    const [newValue, setNewValue] = useState("")
    const [options, setOptions] = useState([])
    const [field, setField] = useState("")
    const [visible, setVisible] = useState(true)
    const [order, setOrder] = useState(-1)
    const [description, setDescription] = useState('')
    const [showPicker, setShowPicker] = useState(false)
    const [showOnCalendar, setShowOnCalendar] = useState(null)
    const [selectedItem, setSelectedItem] = useState({
        color: 'fff',
        index: null
    })

    useEffect(() => {
        setOptions(data.options)
        setField(data.field)
    }, [data])

    const submitModal = () => {
        addItem()
        closeModal()
    }

    const deleteItem = (id) => {
        if (id) {
            removeOption(field, id)
        }
    }

    const addItem = () => {
        if (newValue) {
            let color = colors[options.length % colors.length]
            let customValue = ''
            let valid = true
            let isNum = /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/.test(newValue)
            let isPercent = /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)?%$/.test(newValue)
            if (field === 'financial_interest_rate' && isNum) {
                customValue = newValue + '%'
            } else if (field === 'financial_interest_rate' && isPercent) {
                customValue = newValue
            } else if (field === 'financial_interest_rate') {
                valid = false
            }
            if (valid) {
                let newOption = {
                    id: null,
                    value: null,
                    label: customValue ? customValue : newValue,
                    color: color,
                    description: description,
                    order: options.length
                }
                setAddingNew(false)
                setNewValue('')
                setDescription('')
                if (field === 'truck_service_status') {
                    newOption.show_on_calendar = showOnCalendar == null ? false : showOnCalendar
                }
                addOption(field, newOption)
            }

        } else {
            setAddingNew(false)
        }
    }
    const updateItem = (item) => {
        if (item.id) {
            if (field === 'truck_service_status') {
                item.show_on_calendar = showOnCalendar == null ? item.show_on_calendar : showOnCalendar
            }
            updateOption(field, item.id, item)
            setShowPicker(!showPicker)
        }
    }

    const alphabetize = () => {
        let sorted = [...options]
        setOrder(order * -1)

        sorted.sort((a, b) => {
            if (a.label < b.label) return order
            return -order
        })
        setOptions(sorted)
        updateOptionsOrder(field, sorted.map(i => i.id))
    }

    const changeShowOnCalendar = (item, show) => {
        setShowOnCalendar(show)
        item.show_on_calendar = show
        updateOption(field, item.id, item)
    }
    const handleColorChangeComplete = (color) => {
        setSelectedItem({...selectedItem, color: color.hex})
        setShowOnCalendar(null)
    }

    const handleDrop = (droppedItem) => {
        if (!droppedItem.destination) return;
        let updatedList = [...options];
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
        setOptions(updatedList);
        updateOptionsOrder(field, updatedList.map(i => i.id))
    };

    return <Modal
        bsSize="small"
        animation={false}
        centered={true}
        show={isOpen}
        onHide={closeModal}
        backdrop="static"
        dialogClassName="truck-modal"
    >
        <Modal.Body>
            <div className='header-wrapper'>
                <div className='option-header'>
                    <FormControl type='text'
                                 value={data.field && `${data.field.split('_')[0]} ${data.field.split('_')[1]}`}
                                 disabled={true}/>
                </div>
                <div className='option-tools'>
                    <div className='colored-options'>
                        <button onClick={() => setVisible(!visible)}>
                            <FontAwesomeIcon
                                style={{background: 'transparent', fontSize: '20px'}}
                                icon={visible ? faToggleOn : faToggleOff}/>
                        </button>
                        <p>Colored options</p>
                    </div>
                    <div className='alphabetize' onClick={alphabetize}>
                        <FontAwesomeIcon
                            icon={faCaretDown}
                            className="option-arrow"/>
                        <p>Alphabetize</p>
                    </div>
                </div>
            </div>
            <div className='body-wrapper'>
                <DragDropContext onDragEnd={handleDrop}>
                    <Droppable droppableId="option-list">
                        {(provided) => (
                            <ul
                                className="option-list"
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {options && options.map((o, index) => (
                                    <Draggable key={o.id} draggableId={o.id.toString()} index={index}>
                                        {(provided) => (
                                            <li className='list-item'
                                                ref={provided.innerRef}
                                                {...provided.dragHandleProps}
                                                {...provided.draggableProps}>
                                    <span>
                                        <div className='ellipsis'>
                                            <FontAwesomeIcon icon={faEllipsisV}/>
                                            <FontAwesomeIcon icon={faEllipsisV}/>
                                        </div>
                                        <div>

                                    <FontAwesomeIcon
                                        style={{backgroundColor: selectedItem.index === o.id ? selectedItem.color : visible ? o.color : 'transparent'}}
                                        icon={faCaretDown}
                                        className="option-arrow"
                                        onClick={() => {
                                            setSelectedItem(o)
                                            setShowPicker(!showPicker)
                                        }}
                                    />
                                        </div>
                                        {o.label} <span className='description'>{o.description}</span>
                                        {field === 'truck_service_status' && <ColorCheckbox value={o.show_on_calendar}
                                                                                            label='Show on calendar'
                                                                                            className='vertical-align'
                                                                                            onChange={(show) => {
                                                                                                changeShowOnCalendar(o, show)
                                                                                            }}/>}
                                    </span>
                                                <span onClick={() => deleteItem(o.id)}
                                                      className='remove-icon'>
                                        <Glyphicon glyph='remove' className="pointer"/>
                                    </span>
                                            </li>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </ul>
                        )}
                    </Droppable>
                </DragDropContext>

                {addingNew ?
                    <FormControl type={inputType} placeholder={`${data.field}`} value={newValue}
                                 autoFocus={addingNew}
                                 onChange={(e) => setNewValue(e.target.value)}
                                 onKeyPress={e => {
                                     if (e.key === 'Enter') {
                                         addItem()
                                     }
                                 }}
                    /> :
                    <>
                        {showPicker &&
                            <div className='hue-picker-container'>
                                <HuePicker
                                    className='hue-picker'
                                    onChangeComplete={handleColorChangeComplete}
                                    color={selectedItem.color}
                                />
                                <div className='color-picker-btn' style={{background: selectedItem.color}}
                                     onClick={() => updateItem(selectedItem)}>
                                    <FontAwesomeIcon icon={faCheck}/>
                                </div>
                            </div>}
                        <div className='add-new' onClick={() => setAddingNew(true)}>
                            <p>+ Add an option</p>
                        </div>
                    </>
                }
            </div>
            <div className='body-footer-wrapper'>
                <div>
                    <p>Description:</p>
                    <FormControl type='text' placeholder={`description (optional)`} value={description}
                                 onChange={(e) => setDescription(e.target.value)}/>
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Row>
                <Col className="text-left" xs={6}>
                    <Button
                        bsSize="small"
                        bsStyle="warning"
                        onClick={closeModal}
                    >
                        Cancel
                    </Button>
                </Col>

                <Col className="text-right" xs={6}>
                    <Button
                        bsSize="small"
                        bsStyle="success"
                        onClick={submitModal}
                        type="submit"
                    >
                        Save
                    </Button>
                </Col>
            </Row>
        </Modal.Footer>
    </Modal>

}