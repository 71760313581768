import {
    ADD_NEW_ASSET,
    ADD_NEW_PS,
    CLEAR_BLANK_ASSET,
    DELETE_PROPOSAL_SERVICE,
    EDITING_ASSET_ID,
    FETCH_DBHS,
    FETCH_PRIORITIES,
    FETCH_PROPOSAL_SERVICES,
    FETCH_PS_EQUIPMENT,
    FETCH_SERVICES,
    FETCH_SITE_INFO,
    FETCHED_CUSTOMER_SEARCH,
    FETCHED_CUSTOMER_SITES,
    FETCHED_SITE_ASSETS,
    RESET_MAP_VIEW,
    SELECT_CUSTOMER,
    UPDATE_ASSET,
    UPDATE_CUSTOMER_SEARCH,
    UPDATE_HAVE_OPEN_SERVICE,
    UPDATE_PROPOSAL,
    UPDATE_PROPOSAL_SERVICE,
    UPDATE_SALES_ARBORIST_SEARCH,
    UPDATE_SITE_SEARCH
} from "./constants"
import {addAlert} from "../App/actions"
import axios from "axios"
import {browserHistory} from "react-router"
import moment from "moment"

import {clearPlantData} from "../AssetEditorModal/actions"
import {apiCall} from "../../common/apiActionHandler";

//import deepFreeze from 'deep-freeze';//its already needed by other things in react so just use it

export function resetMapView() {
    //this is fired when theres no proposal to edit. we need to make sure that the new proposal starts with a good date in case they dont change it

    const blankProposal = {
        proposalDate: moment().format(),
        proposalNote: "",
        purchaseOrder: "",
    }
    return {type: RESET_MAP_VIEW, proposal: blankProposal}
}

export function updateSite(customerSiteSearch) {
    return {type: UPDATE_SITE_SEARCH, customerSiteSearch, selectedSite: {}}
}

export function updateHaveOpenService(serviceId, open) {
    return {type: UPDATE_HAVE_OPEN_SERVICE, serviceId: serviceId, haveOpenService: open}
}

export function updateSalesArborist(salesArboristSearch) {
    return {
        type: UPDATE_SALES_ARBORIST_SEARCH,
        salesArboristSearch,
        selectedSalesArborist: {},
    }
}

export const selectSalesArborist = selectedSalesArborist => (
    dispatch,
    getState
) => {
    const store = getState()
    let proposal = Object.assign({}, store.mapView.proposal)
    proposal.salesArboristId = parseInt(selectedSalesArborist.value, 10)
    proposal.salesArboristName = selectedSalesArborist.label
    dispatch({type: UPDATE_PROPOSAL, proposal}) //update the object we're building to submit
}

export const updateAttribute = (attribute, value) => (dispatch, getState) => {
    if (attribute === null || attribute === undefined) {
        return
    }
    const store = getState()
    let proposal = Object.assign({}, store.mapView.proposal) // need to create a new object!
    proposal[attribute] = value
    dispatch({type: UPDATE_PROPOSAL, proposal})
}

export const selectSite = selectedSite => (dispatch, getState) => {
    const store = getState()
    let proposal = Object.assign({}, store.mapView.proposal)
    proposal.siteId = selectedSite.value
    proposal.siteName = selectedSite.label
    dispatch({type: UPDATE_PROPOSAL, proposal}) //update the object we're building to submit
    dispatch(fetchAssetsForSite())
    dispatch(fetchSiteInfo())
}

export const fetchEquipmentList = proposalServiceId => (dispatch, getState) => {
    //proposalServiceId can be undefined (and not sent) in which case rails will process it as a list of all equipment for the current client

    const store = getState()
    const config = {
        method: "get",
        url: "/api/proposal_service_equipments",
        headers: {Authorization: store.auth.token},
        params: {
            proposal_service_id: proposalServiceId,
        },
    }

    return axios.request(config).then(response => {
        dispatch({type: FETCH_PS_EQUIPMENT, equipments: response.data})
    })
}
export const editProposal = proposalId => (dispatch, getState) => {
    const store = getState()
    const config = {
        method: "get",
        url: `/api/proposals/${proposalId}/edit`,
        headers: {Authorization: store.auth.token},
    }

    return axios.request(config).then(response => {
        dispatch({type: UPDATE_PROPOSAL, proposal: response.data})
        return response
    })
}

export const loadProposalForEditing = proposalId => dispatch => {
    //pull data from an endpoint that can 'rehydrate' this reducer. which includes adding information to the dropdown fields with names
    //even though we only really care about the id's. also also the array of ps, etc which i'm not sure should be its own array
    //or as a giant sub object inside proposal:{} yet probably not as long as everything has a parent id. better nosqlwise too prolly.
    // consider the "edit" endpoint
    // const store = getState()
    // const config = {
    //   method: 'get',
    //   url: `/api/proposals/${proposalId}/edit`,
    //   headers: {'Authorization': store.auth.token},
    // }

    return dispatch(editProposal(proposalId)).then(response => {
        dispatch({type: UPDATE_PROPOSAL, proposal: response.data})
        //now since we need to reconstitute teh form, we need to add UI type info as well.
        //  dispatch(selectSalesArborist({name: response.data.salesArboristName, id: response.data.salesArboristId})); //pretend we selected it from the dropdown
        dispatch(
            onSelectedCustomer({
                label: response.data.customerName,
                value: response.data.customerId,
            })
        ) //again same as if we selected it
        //   dispatch(selectSite({name: response.data.siteName, id: response.data.siteId}));//again same as if we selected it
        return response
    })
}

export const fetchProposalServices = proposalId => (dispatch, getState) => {
    const store = getState()
    const config = {
        method: "get",
        url: "/api/proposal_services",
        headers: {Authorization: store.auth.token},
        params: {
            proposal_id: proposalId,
        },
    }

    return axios.request(config).then(response => {
        dispatch({type: FETCH_PROPOSAL_SERVICES, proposalServices: response.data})
    })
}

export const fetchServices = () => (dispatch, getState) => {
    const store = getState()
    const config = {
        method: "get",
        url: "/api/services",
        headers: {Authorization: store.auth.token},
    }

    return axios.request(config).then(response => {
        dispatch({type: FETCH_SERVICES, services: response.data})
    })
}

export const fetchPriorities = () => (dispatch, getState) => {
    const store = getState()
    const config = {
        method: "get",
        url: "/api/priorities/search",
        headers: {Authorization: store.auth.token},
    }

    return axios.request(config).then(response => {
        dispatch({type: FETCH_PRIORITIES, priorities: response.data})
    })
}

const resetSite = () => (dispatch, getState) => {
    //if we change the customer then we have to nullify the site
    dispatch({type: UPDATE_SITE_SEARCH, customerSiteSearch: ""})

    dispatch(updateAttribute(("site_id", null)))
    // const store = getState()
    // let proposal = store.mapView.proposal
    // proposal.site_id = null
    // dispatch({type: UPDATE_PROPOSAL, proposal});
}

export const deleteProposal = () => (dispatch, getState) => {
    const store = getState()
    const p = store.mapView.proposal //we need toformat our data for what rails likes and what we want the user to be able to modify/create

    const config = {
        method: "delete",
        url: `/api/proposals/${p.id}`,
        headers: {Authorization: store.auth.token},
    }

    axios.request(config).then(response => {
        if (response.status === 200) {
            dispatch(
                addAlert({
                    message: "Proposal Deleted!",
                    mood: "warning",
                    dismissAfter: 2500,
                })
            )
            browserHistory.push("/mapview/")
        }
    })
}

export function onAssetDoubleClick(editingAssetId) {
    return {type: EDITING_ASSET_ID, editingAssetId}
}

export const doneEditingAsset = assetsSiteId => (dispatch, getState) => {
    dispatch({type: EDITING_ASSET_ID, editingAssetId: null})

    const store = getState()

    //just in case they cancelled out of creating a new asset
    dispatch({
        type: CLEAR_BLANK_ASSET,
        siteAssets: store.mapView.siteAssets.filter(a => a.id !== 0),
    })

    if (assetsSiteId) {
        dispatch(fetchAssetsForSite(assetsSiteId)) //the asset editor modal that uses this method from a different page passes in a site id
    }
}

export function submitProposal(leadId) {
    return function (dispatch, getState) {
        const store = getState()
        const p = store.mapView.proposal //we need toformat our data for what rails likes and what we want the user to be able to modify/create

        const config = {
            method: p.id ? "put" : "post",
            url: p.id ? `/api/proposals/${p.id}` : "/api/proposals",
            headers: {Authorization: store.auth.token},
            data: {
                proposal: {
                    lead_id: leadId,
                    id: p.id,
                    site_id: p.siteId,
                    purchase_order: p.purchaseOrder,
                    tax: p.tax,
                    employee_id: p.salesArboristId,
                    note_customer: p.proposalNote,
                    proposal_date: p.proposalDate,
                },
            },
        }

        axios
            .request(config)
            .then(response => {
                if (response.status === 200) {
                    dispatch(
                        addAlert({
                            message: "Proposal Updated!",
                            mood: "success",
                            dismissAfter: 1500,
                        })
                    )
                } else if (response.status === 201) {
                    dispatch(
                        addAlert({
                            message: "Proposal Created!",
                            mood: "success",
                            dismissAfter: 1500,
                        })
                    )
                }

                dispatch(editProposal(response.data.id))
                //dispatch(loadProposalForEditing(response.data.id))
                browserHistory.push(`/mapview/${response.data.id}`)
            })
            .catch(error => {
                dispatch(
                    addAlert({
                        message: `Error! ${error.response && error.response.data ? error.response.data : error.toString()}`,
                    })
                )
            })
    }
}

export const duplicateProposal = () => (dispatch, getState) => {
    const store = getState()
    const p = store.mapView.proposal //we need toformat our data for what rails likes and what we want the user to be able to modify/create

    const config = {
        method: "post",
        url: `/api/proposals/${p.id}/duplicate`,
        headers: {Authorization: store.auth.token},
    }

    axios.request(config).then(response => {
        if (response.status === 201) {
            dispatch(
                addAlert({
                    message: "Proposal Duplicated! Taking you to it.",
                    mood: "success",
                    dismissAfter: 2500,
                })
            )
            browserHistory.push(`/mapview/${response.data.id}`)
        }
    })
}

export function loadCustomer(id, siteId) {
    return function (dispatch, getState) {
        const store = getState();

        const config = {
            method: "get",
            url: `/api/customers/${id}`,
            headers: {Authorization: store.auth.token},
        };
        return axios.request(config).then(response => {
            const selectedCustomer = {value: response.data.id, label: response.data.name};
            dispatch({
                type: FETCHED_CUSTOMER_SEARCH,
                customerSearchList: [selectedCustomer]
            });
            dispatch(onSelectedCustomer(selectedCustomer));
            dispatch(selectSite({value: siteId}));
        })
    }
}

export function searchForCustomer() {
    return function (dispatch, getState) {
        const store = getState()
        const searchQuery = store.mapView.customerSearch

        if (searchQuery.length < 2) {
            console.log("search query is less than 2, i'm not searching")
            return false
        }

        const config = {
            method: "get",
            url: `/api/customers`,
            headers: {Authorization: store.auth.token},
            params: {
                q: searchQuery,
            },
        }
        return axios.request(config).then(response => {
            const customerSearchList = response.data.map(c => ({
                label: c.name,
                value: c.id,
            }))
            dispatch({
                type: FETCHED_CUSTOMER_SEARCH,
                customerSearchList: customerSearchList,
            })
        })
    }
}

export function fetchSitesForCustomer() {
    return function (dispatch, getState) {
        const store = getState()
        const customerId = store.mapView.selectedCustomer.value

        const config = {
            method: "get",
            url: `/api/sites`,
            headers: {Authorization: store.auth.token},
            params: {
                customer_id: customerId,
            },
        }
        axios
            .request(config)
            .then(response => {
                dispatch({type: FETCHED_CUSTOMER_SITES, customerSites: response.data})
                //    dispatch(anotherAction());//todo make the api return this on the initial call
            })
            .catch(error => {
            })
    }
}

export const fetchDbhs = () => (dispatch, getState) => {
    const store = getState()
    const assetIds = store.mapView.siteAssets.map(a => a.id)

    const config = {
        method: "get",
        url: "/api/plant_stats_histories/latest_dbh",
        headers: {Authorization: store.auth.token},
        params: {
            asset_ids: assetIds.join(","),
        },
    }

    return axios.request(config).then(response => {
        dispatch({type: FETCH_DBHS, siteAssetsDbhs: response.data})
    })
}

export function fetchAssetsForSite(assetSiteId = null) {
    return function (dispatch, getState) {
        const store = getState()
        const siteId =
            store.mapView.proposal.siteId ||
            assetSiteId /*the asset editor modal passes in a siteId when using these */

        console.log(
            `got an explicit assetSiteId ${assetSiteId}. siteId is finally ${siteId}`
        )

        const config = {
            method: "get",
            url: `/api/assets`,
            headers: {Authorization: store.auth.token},
            params: {
                site_id: siteId,
            },
        }
        return axios.request(config).then(response => {
            dispatch({type: FETCHED_SITE_ASSETS, siteAssets: response.data})
            dispatch(fetchDbhs())
            return response
        })
    }
}

export function fetchSiteInfo() {
    return function (dispatch, getState) {
        const store = getState()
        const siteId = store.mapView.proposal.siteId

        const config = {
            method: "get",
            url: `/api/sites/${siteId}`,
            headers: {Authorization: store.auth.token},
        }
        axios.request(config).then(response => {
            dispatch({type: FETCH_SITE_INFO, sites: response.data})
        })
    }
}

export function updateCustomerSearch(customerSearch) {
    return function (dispatch, getState) {
        dispatch({type: UPDATE_CUSTOMER_SEARCH, customerSearch}) //this is just storage so search for customer can get it.. is this necessary??
        dispatch({type: FETCHED_SITE_ASSETS, siteAssets: []}) //reset teh sites so its not showing someone elses
        dispatch(searchForCustomer())
    }
}

export function onSelectedCustomer(selectedCustomer) {
    return function (dispatch) {
        //we can create 1 reducer to do both but lets just use teh ones we have to compose actions
        dispatch({
            type: UPDATE_CUSTOMER_SEARCH,
            customerSearch: selectedCustomer.label,
        })

        dispatch({type: SELECT_CUSTOMER, selectedCustomer})

        dispatch(resetSite()) //blank out any selected site (possibly belonging to a different customer!)
        dispatch({type: FETCHED_SITE_ASSETS, siteAssets: []}) //reset teh sites so its not showing someone elses
        dispatch(fetchSitesForCustomer())
    }
}

//******************** only proposal service form actions here pls

export const updatePsAttribute = (psId, attr, newVal) => (
    dispatch,
    getState
) => {
    //slice from 0 to the one we wnat to edit, concat in the edited, concat taht with the sliced rest of it
    //https://egghead.io/lessons/javascript-redux-avoiding-array-mutations-with-concat-slice-and-spread

    const store = getState()

    //1. copy the one we want to edit
    const proposalServices = store.mapView.proposalServices // yes this is a reference!

    //deepFreeze(proposalServices);// just to ensure we're not really modifying it

    const editPsIdx = proposalServices.findIndex(ps => ps.id === psId)

    let editedProposalService = Object.assign({}, proposalServices[editPsIdx]) // need to create a new object!

    //2. edit it
    editedProposalService[attr] = newVal

    //3. use slice/concat to substitute it in. note that we can do this different ways but because of the UI
    //and sorting, we dont want it to rerender in a different sot order. we want the proposal service to stay put
    //so also you can spread over instead of using concat() keyword

    //TODO: redux really likes the nosql approach of having objects with keys as identifiers rather than having arrays of objects that you search thru
    //http://stackoverflow.com/questions/38445006/redux-state-as-array-of-objects-vs-object-keyed-by-id

    const updatedProposalServices = [
        ...proposalServices.slice(0, editPsIdx),
        editedProposalService,
        ...proposalServices.slice(editPsIdx + 1),
    ] // spreading is creating a new array. deepFreeze ensures that

    dispatch({
        type: UPDATE_PROPOSAL_SERVICE,
        proposalServices: updatedProposalServices,
    })
}

export const reorderProposalServices = (sourceIndex, destinationIndex) => (
    dispatch,
    getState
) => {
    //slice from 0 to the one we wnat to edit, concat in the edited, concat taht with the sliced rest of it
    //https://egghead.io/lessons/javascript-redux-avoiding-array-mutations-with-concat-slice-and-spread

    const store = getState()

    //1. copy the one we want to edit
    const proposalServices = store.mapView.proposalServices // yes this is a reference!

    let result = Array.from(proposalServices);
    const [removed] = result.splice(sourceIndex, 1);
    result.splice(destinationIndex, 0, removed);

    result = result.map((proposalService, index) => {
        if (proposalService.serviceNo != index + 1) {
            // update proposalServiceNo
            const config = {
                method: "put",
                url: `/api/proposal_services/${proposalService.id}/updateServiceNumber?serviceNo=${index + 1}`,
                headers: {Authorization: store.auth.token},
            }
            axios.request(config).then(() => {
                // dispatch(fetchProposalServices(store.mapView.proposal.id)) //if we do this then itll refresh the unsaved things :(
            })
        }
        proposalService.serviceNo = index + 1
        return proposalService
    })

    dispatch({
        type: UPDATE_PROPOSAL_SERVICE,
        proposalServices: result,
    })
}

export const savePsChanges = psId => (dispatch, getState) => {
    const store = getState()

    //TODO: since the user can edit multiple proposal services before saving them we should 1. update all, or 2. mark dirty and send those
    const ps = store.mapView.proposalServices.find(ps => ps.id === psId) //we need toformat our data for what rails likes and what we want the user to be able to modify/create

    if (ps.serviceId === undefined || ps.notes === "") {
        dispatch(
            addAlert({
                message: "A Service must be selected and the Proposal Service Notes can't be blank!",
            })
        )
        return false
    }

    if (ps.assetIds.length === 0) {
        dispatch(
            addAlert({
                message: "Please add at least 1 Tree/Asset to this Proposal Service and try again!",
            })
        )
        return false
    }

    const config = {
        method: ps.id > 0 ? "put" : "post",
        url: ps.id > 0
            ? `/api/proposal_services/${ps.id}`
            : "/api/proposal_services",
        headers: {Authorization: store.auth.token},
        data: {
            proposal_service: {
                id: ps.id > 0 ? ps.id : null,
                proposal_id: ps.proposalId,
                service_id: ps.serviceId,
                service_total: ps.serviceCost,
                notes: ps.notes,
                wo_notes: ps.workOrderNotes,
                man_hours: ps.manHours,
                ave_salary_rate: ps.aveSalaryRate,
                priority_id: parseInt(ps.priorityId, 10) === 0 ? "nil" : ps.priorityId,
                asset_object_list: ps.assetIds.join(),
                equipment_list: ps.equipmentIds.join(),
            },
        },
    }

    axios.request(config).then(response => {
        if (response.status === 200) {
            dispatch(
                addAlert({
                    message: "Proposal Service Updated!",
                    mood: "success",
                    dismissAfter: 1500,
                })
            )
        } else if (response.status === 201) {
            dispatch(
                addAlert({
                    message: "Proposal Service Created!",
                    mood: "success",
                    dismissAfter: 1500,
                })
            )
        }

        //TODO: maybe catch error and if so then reget the ps list to show whats saved in the db?
        //TODO:fetch just this PS so the exra junk we added (is dirty) can be removed on the get?
        dispatch(fetchProposalServices(ps.proposalId)) //if we do this then itll refresh the unsaved things :(
    })
}

export const selectService = service => (dispatch, getState) => {
    alert("selecting service" + JSON.stringify(service))
}

export const updateService = service => (dispatch, getState) => {
    alert("updating service")
}

//this is the same process as editing any objet in a list. if this breaks then other stuff will break
export const updateAssetAttribute = (assetId, attr, newVal, dirty) => (
    dispatch,
    getState
) => {
    //  console.log("updating asset id attr to x", assetId, attr, newVal)
    const store = getState()

    //1. copy the one we want to edit
    const siteAssets = store.mapView.siteAssets // yes this is a reference!

    //deepFreeze(siteAssets);// just to ensure we're not really modifying it

    const editAssetIdx = siteAssets.findIndex(a => a.id === assetId) // a refernce. dont edit this!
    // console.log("found asset requested index by id", assetId, editAssetIdx)

    // if you want to lock asset, need to unlock others
    if (attr === '__locked') {
        siteAssets.forEach(siteAsset => {
            if (siteAsset['__locked']) {
                siteAsset['__locked'] = false
            }
        })
    }

    let editedAsset = Object.assign({}, siteAssets[editAssetIdx]) // need to create a new object!

    // console.log("found asset  object", editedAsset)
    // console.log(`setting ${attr} to ${newVal}`)
    //2. edit it
    editedAsset[attr] = newVal

    //set the model as dirty whih we use to tell the user to push the button
    if (dirty !== undefined) {
        editedAsset["dirty"] = dirty
    }

    //3. use slice/concat to substitute it in. note that we can do this different ways but because of the UI
    //and sorting, we dont want it to rerender in a different sot order. we want the proposal service to stay put
    //so also you can spread over instead of using concat() keyword

    //TODO: redux really likes the nosql approach of having objects with keys as identifiers rather than having arrays of objects that you search thru
    //http://stackoverflow.com/questions/38445006/redux-state-as-array-of-objects-vs-object-keyed-by-id

    const updatedSiteAssets = [
        ...siteAssets.slice(0, editAssetIdx),
        editedAsset,
        ...siteAssets.slice(editAssetIdx + 1),
    ] // spreading is creating a new array. deepFreeze ensures that

    dispatch({type: UPDATE_ASSET, siteAssets: updatedSiteAssets})
}

export const addAssetsToPs = psId => (dispatch, getState) => {
    // console.log("Adding selected assets to psid", psId)
    const store = getState()

    const proposalService = store.mapView.proposalServices.find(
        ps => ps.id === psId
    )

    const selectedAssetIds = store.mapView.siteAssets
        .filter(a => a.selected)
        .map(s => s.id)
    if (selectedAssetIds.length === 0) {
        dispatch(
            addAlert({
                message: "You didn't select any Trees. Please select at least 1 and click the button again.",
            })
        )
        return false
    }
    // console.log("selected Asset Ids are", selectedAssetIds)

    //since there may exist asset ids, we need to add them to any existing list
    //a set is a cheap way to .uniq
    const allSelectedAssetIdsSet = new Set(
        selectedAssetIds.concat(proposalService.assetIds || [])
    )

    // console.log("unique ps assetids are", [...allSelectedAssetIdsSet])
    dispatch(updatePsAttribute(psId, "assetIds", [...allSelectedAssetIdsSet]))
    //dispatch(updatePsAttribute(psId, 'dirty', true))
}

export const removeAssetFromPs = (psId, assetId) => (dispatch, getState) => {
    // console.log("removing assetid from psid", assetId, psId)
    const store = getState()

    const proposalService = store.mapView.proposalServices.find(
        ps => ps.id === psId
    )
    // console.log("found ps to delete assed it", proposalService)
    const remainingAssetIds = proposalService.assetIds.filter(a => a !== assetId)
    //  console.log("remaining asset ids are now", remainingAssetIds)
    dispatch(updatePsAttribute(psId, "assetIds", remainingAssetIds))
}

export const addEquipment = (psId, equipmentId) => (dispatch, getState) => {
    if (equipmentId === "0") {
        return false
    }

    //  console.log('adding PSequipment psid= then eqid', psId, equipmentId)

    const store = getState()

    const proposalService = store.mapView.proposalServices.find(
        ps => ps.id === psId
    )

    //const selectedEquipmentIds = proposalService.equipmentIds;// this is a reference!
    // console.log("selected equipment Ids are", selectedEquipmentIds)

    //since there may exist asset ids, we need to add them to any existing list
    //a set is a cheap way to .uniq
    const allSelectedEquipmentIdsSet = new Set(
        (proposalService.equipmentIds || []).concat(parseInt(equipmentId, 10))
    )

    // console.log("unique ps assetids are", [...allSelectedEquipmentIdsSet])
    dispatch(
        updatePsAttribute(psId, "equipmentIds", [...allSelectedEquipmentIdsSet])
    )
    //dispatch(updatePsAttribute(psId, 'dirty', true))
}

export const removeEquipment = (psId, equipmentId) => (dispatch, getState) => {
    // console.log("removing eqipment from psid", equipmentId, psId)
    const store = getState()

    const proposalService = store.mapView.proposalServices.find(
        ps => ps.id === psId
    )
    // console.log("found ps to delete equip it", proposalService)
    const remainingEquipmentIds = proposalService.equipmentIds.filter(
        a => a !== parseInt(equipmentId, 10)
    )
    // console.log("remaining equip ids are now", remainingEquipmentIds)
    dispatch(updatePsAttribute(psId, "equipmentIds", remainingEquipmentIds))
}

export const onAddNewPs = () => (dispatch, getState) => {
    //append a blank ps to the existing ps'es
    const store = getState()
    const serviceNos = store.mapView.proposalServices
        .filter(ps => ps.id > 0)
        .map(ps => ps.serviceNo)
        .sort((a, b) => a - b)
    //console.log("service numbers are", serviceNos)
    const nextServiceNo = (serviceNos[serviceNos.length - 1] || 0) + 1

    const blankPs = {
        id: 0,
        proposalId: store.mapView.proposal.id,
        serviceNo: nextServiceNo,
        serviceId: "",
        serviceName: "",
        serviceStatusName: "",
        notes: "",
        workOrderNotes: "",
        priority: "",
        priorityId: "",
        manHours: 0,
        aveSalaryRate: 0,
        serviceCost: 0,
        assetIds: [],
        equipmentIds: [],
    }
    // if (store.mapView.proposalServices.find(ps => ps.id === 0) === undefined) {
    const proposalServices = (store.mapView.proposalServices || [])
        .concat(blankPs)
    dispatch({type: ADD_NEW_PS, proposalServices})
    //}else{
    //  console.log("There's already a ps id of 0 so I wont add another one")
    return false
    //}
}

//******** marker stuff?

export const onAssetMouseOver = assetId => dispatch => {
    //set the .__highlighted = true on this marker
    dispatch(updateAssetAttribute(assetId, "__highlighted", true, false))
}

export const onAssetMouseOut = assetId => dispatch => {
    dispatch(updateAssetAttribute(assetId, "__highlighted", undefined, false))
}

export const onMarkerMouseOver = assetId => dispatch => {
    //set the .__highlighted = true on this marker
    dispatch(updateAssetAttribute(assetId, "__active", true, false))
    dispatch(onAssetMouseOver(assetId))
}

export const onMarkerMouseOut = assetId => dispatch => {
    dispatch(updateAssetAttribute(assetId, "__active", undefined, false))
    dispatch(onAssetMouseOut(assetId))
}

export const onAssetLocked = assetId => dispatch => {
    //set the .__highlighted = true on this marker
    dispatch(updateAssetAttribute(assetId, "__locked", true, false))
}

export const onAssetUnlocked = assetId => dispatch => {
    dispatch(updateAssetAttribute(assetId, "__locked", undefined, false))
}

export const onMarkerClick = assetId => dispatch => {
    //same as another action so lets call it
    dispatch(onAssetDoubleClick(assetId))
}

export const saveDuplicates = (assetId, markers, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/assets/save_duplicates`,
        method: "post",
        data: {
            duplicates: {
                positions: markers.map(marker => ({
                    lat: marker.getPosition().lat(),
                    lng: marker.getPosition().lng()
                })),
                asset_id: assetId
            }
        }
    };

    apiCall("Saving duplicates", config, async config => {
        await axios.request(config);
        callback && callback();
    }, dispatch, getState);
};

export const onMarkerDragEnd = (marker, assetId) => (dispatch, getState) => {
    const store = getState()
    const lat = marker.latLng.lat()
    const lng = marker.latLng.lng()

    dispatch(updateAssetAttribute(assetId, "latitude", lat, false))
    dispatch(updateAssetAttribute(assetId, "longitude", lng, false))
}

export const saveMarkerPosition = (asset) => (dispatch, getState) => {
    const store = getState()
    const config = {
        method: "put",
        url: `/api/assets/${asset.id}`,
        headers: {Authorization: store.auth.token},
        data: {
            lat: asset.latitude,
            lng: asset.longitude,
        },
    }

    axios
        .request(config)
        .then(response => {
            console.log(
                "fetching assets for site due to marker movement. not sure if we need this cause we updated it locally"
            )
            //  dispatch(fetchAssetsForSite())
        })
        .catch(error => {
            alert(error)
        })

}

export const editNewMarker = (lat, lng) => (dispatch, getState) => {
    //create a new asset in edit mode with the supplied location
    const store = getState()
    const assetNumber =
        store.mapView.siteAssets.reduce(
            (memo, asset) => Math.max(memo, asset.asset_number),
            0
        ) + 1
    const site = store.mapView.sites[`site_${store.mapView.proposal.siteId}`]
    if (site === undefined) {
        alert("Error! Can't create new Asset - unknown Site!")
        return false
    }
    dispatch(clearPlantData())

    const newAsset = {
        id: 0,
        site_id: site.id,
        disabled: false,
        parent_asset_id: null,
        asset_kind: "p",
        label: assetNumber.toString(),
        note: "",
        plant_count: 1,
        images: [],
        dirty: true,
        latitude: lat,
        longitude: lng,
        asset_number: assetNumber,
        plant_name: "Editing New Asset",
    }

    dispatch({
        type: ADD_NEW_ASSET,
        siteAssets: store.mapView.siteAssets.concat([newAsset]),
    })
    dispatch({type: EDITING_ASSET_ID, editingAssetId: 0})
}

export const deleteProposalService = psId => (dispatch, getState) => {
    const store = getState()
    const p = store.mapView.proposal //the current proposal being edited. well need this to refresh the proposal list. alternatively we could  have
    //foudn the proposalservice by its id and then grabbe the proposalId from it. whatevs.

    const config = {
        method: "delete",
        url: `/api/proposal_services/${psId}`,
        headers: {Authorization: store.auth.token},
    }

    dispatch({type: DELETE_PROPOSAL_SERVICE, psId}) //optimistic delete

    axios
        .request(config)
        .then(response => {
            if (response.status === 200) {
                dispatch(
                    addAlert({
                        message: "Proposal Service Deleted!",
                        mood: "warning",
                        dismissAfter: 2500,
                    })
                )
                dispatch(fetchProposalServices(p.id)) //really refresh the list
            }
        })
        .catch(error => {
            dispatch(
                addAlert({
                    message: "Proposal Service NOT deleted!" + error.toString(),
                    mood: "danger",
                    dismissAfter: 2500,
                })
            )
            dispatch(fetchProposalServices(p.id)) //reget the list
        })
}

export const updateSiteInfo = (siteMap) => async (dispatch, getState) => {
    const store = getState()
    const isNew = siteMap.id === 0 ? true : false

    const config = {
        method: isNew ? "POST" : "PUT",
        url: isNew ? "/api/sites" : `/api/sites/${siteMap.id}`,
        headers: {Authorization: store.auth.token},
        data: {site: {...siteMap, id: undefined, __dirty: undefined}},
    }

    try {
        const response = await axios.request(config)
        /*
        dispatch(
          addAlert({
            message: `${isNew ? "Created" : "Edited"} site!`,
            mood: "success",
            dismissAfter: 1500,
          })
        )
        */
        return true
    } catch (error) {
        dispatch(
            addAlert({
                message: `Could not ${isNew ? "CREATE" : "EDIT"} the site ${error.toString()}`,
                mood: "danger",
                dismissAfter: 1500,
            })
        )
        throw error
    }
}

export const moveToProposed = (proposalId, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/proposals/${proposalId}/propose`,
        method: "post",
    };

    apiCall("Moving to proposed", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState);
};
