import React from 'react'
import {GoogleMap, Marker} from "react-google-maps";
import {GoogleMapLoader} from "../../components/GoogleMapLoader";
import {Row} from "react-bootstrap";
import ResourceComponent from "../../components/ResourceComponent";
import * as actions from "./CustomerMapApi";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import MarkerClusterer from "react-google-maps/lib/components/addons/MarkerClusterer";
import {debounce} from "throttle-debounce";
import {getBasicMarker} from "../../utilities";

class CustomerMap extends ResourceComponent {
    state = {
        sites: [],
        center: true,
    }

    constructor() {
        super();
        this.delayedMapChange = debounce(500, this.onMapChange);
    }

    onMapChange() {
        if (this.map) {
            const bounds = this.map.getBounds();
            const params = {
                min_lng: bounds.getSouthWest().lng(),
                max_lng: bounds.getNorthEast().lng(),
                min_lat: bounds.getSouthWest().lat(),
                max_lat: bounds.getNorthEast().lat()

            };
            this.props.actions.getSites(params, (sites) => {
                this.setState({...this.state, sites})
            });
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextState.sites !== this.state.sites;
    }

    render() {
        const {sites} = this.state;
        return <Row style={{width: "100vw"}}>
            {sites && <GoogleMapLoader
                containerElement={
                    <div
                        id="customersMap"
                        className="remaining vertical-responsive-height bottom15"
                    />
                }
                googleMapElement={
                    <GoogleMap
                        defaultCenter={
                            {
                                lat: 40.0,
                                lng: -105.0
                            }
                        }
                        ref={it => this.map = it}
                        onZoomChanged={() => this.delayedMapChange()}
                        onCenterChanged={() => this.delayedMapChange()}
                        defaultZoom={15}
                    >
                        <MarkerClusterer
                            averageCenter
                            enableRetinaIcons
                            gridSize={100}
                        >
                            {sites.map((x, index) => {
                                return x.latitude && (
                                    <Marker
                                        key={x.id}
                                        position={{lat: x.latitude, lng: x.longitude}}
                                        icon={getBasicMarker({selected: false, color: '#f8e500'})}
                                    />
                                )
                            })}
                        </MarkerClusterer>
                    </GoogleMap>
                }
            />}
        </Row>;
    }

}

function mapStateToProps(state, ownProps) {
    return {
        state: state,
        client: state.client.customerInfo
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(CustomerMap);