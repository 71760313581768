import React from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import * as actions from './actions'
import {Button, Col, ControlLabel, FormGroup, Glyphicon, OverlayTrigger, Row, Well} from 'react-bootstrap'
import TextareaAutosize from "react-textarea-autosize";
import Sticky from "./contents/Sticky";
import ResourceComponent from "../../components/ResourceComponent";
import {Link} from "react-router"
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import './Stickies.css';
import {bindActionCreators} from "redux";
import Tooltip from "react-bootstrap/lib/Tooltip";

const Actions = {...actions}

class Stickies extends ResourceComponent {
    static defaultProps = {
        withFollowUp: false,
        replyButtonLabel: 'Reply'
    };
    static propTypes = {
        children: PropTypes.object,
        stickies: PropTypes.array.isRequired,
        save: PropTypes.func,
        remove: PropTypes.func,
        hide: PropTypes.func,
        read: PropTypes.func,
        complete: PropTypes.func,
        notify: PropTypes.func,
        type: PropTypes.string,
        replyButtonLabel: PropTypes.string,
        withFollowUp: PropTypes.bool,
        compact: PropTypes.bool,
        stickable: PropTypes.object,
    };
    state = {resource: {note: ''}};

    submitNewStickyForm = (e, sticky) => {
        e.preventDefault();
        const body = this.state.resource[`sticky_${sticky.id}_body`];
        const follow_up_date = this.state.resource[`sticky_${sticky.id}_follow_up_date`];
        const {stickable_id, stickable_type} = sticky;
        this.props.save(sticky, {body, follow_up_date, stickable_id, stickable_type}, () => {
            const resource = this.state.resource;
            resource[`sticky_${sticky.id}_body`] = '';
            resource[`sticky_${sticky.id}_follow_up_date`] = '';
            resource[`sticky_${sticky.id}_reply`] = false;
            this.setState({resource});
            this.handleReplyClick(e, sticky);
        });
    };

    getStickyLink = (sticky) => {
        if (sticky.stickable_type === 'Proposal') {
            return `/proposal_service_status/${sticky.stickable_id}`;
        }
        return '#'
    };

    handleReplyClick = (sticky) => {
        const resource = this.state.resource;
        resource[`sticky_${sticky.id}_reply`] = !resource[`sticky_${sticky.id}_reply`];
        this.setState({resource});
    };

    render() {
        let {
            stickies,
            children,
            withFollowUp,
            compact,
            replyButtonLabel,
            stickable,
            isProposalView,
            proposalId,
            proposal,
            searchResult
        } = this.props;
        if ((stickable && !isProposalView) || (stickable && isProposalView && (this.props.type === 'messages' || this.props.type === 'follow ups') && stickies && stickies.length === 0)) {
            stickies = [...stickies, {
                id: 'new',
                stickable_type: stickable.type,
                stickable_id: stickable.value,
                key: stickable.label,
                replies: [],
                read: true,
                sticky_type: []
            }]

        }
        if (proposalId) {
            stickies = [...stickies, {
                id: 'new',
                stickable_type: 'Proposal',
                stickable_id: proposal,
                key: proposalId,
                replies: [],
                read: true,
                sticky_type: []
            }]
        }

        return (
            <div className={`stickies-wrapper ${compact ? 'compact' : ''}`}>
                <section>
                    {children}
                    {stickies.length === 0 && <div className="stickies-spacer"/>}
                    {!isProposalView && <p>&nbsp;</p>}
                    {
                        stickies.map(sticky => {
                            if (!proposalId || proposalId === sticky.key) return (
                                <Well key={sticky.id} className={isProposalView && `no-margin`}>
                                    <Row>

                                        {!isProposalView &&
                                            <Col xs={8}>{
                                                !sticky.stickable_type ?
                                                    <h4 className="margin10">{sticky.key}</h4>
                                                    :
                                                    <h4 className="margin10">{sticky.stickable_type}: <Link
                                                        to={this.getStickyLink(sticky)}>{sticky.key} </Link>
                                                        {sticky.read ? null :
                                                            <OverlayTrigger
                                                                placement={'bottom'}
                                                                overlay={
                                                                    <Tooltip id={`tooltip-visibility`}>
                                                                        Unread follow up notifications
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <Glyphicon glyph="exclamation-sign"
                                                                           className="notification"/>
                                                            </OverlayTrigger>}
                                                        {!sticky.complete && withFollowUp && sticky.after_follow_up ?
                                                            <OverlayTrigger
                                                                placement={'bottom'}
                                                                overlay={
                                                                    <Tooltip id={`tooltip-followup`}>
                                                                        Missed follow ups
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <Glyphicon glyph="exclamation-sign"
                                                                           className="follow_up"/>
                                                            </OverlayTrigger> : null}

                                                        {sticky.id === 'new' && <span> New thread</span>}
                                                    </h4>
                                            }
                                            </Col>
                                        }
                                        {this.props.save &&
                                            <Col xs={4} className="pull-right text-right">
                                                {!sticky.stickable_type ? null : <Button bsStyle="link"
                                                                                         onClick={() => this.handleReplyClick(sticky)}>{replyButtonLabel}</Button>}
                                            </Col>
                                        }
                                    </Row>
                                    {this.props.save && this.state.resource[`sticky_${sticky.id}_reply`] &&
                                        <form onSubmit={e => this.submitNewStickyForm(e, sticky)}>
                                            {withFollowUp && <Row>
                                                <Col xs={8}>
                                                    <FormGroup>
                                                        <ControlLabel>Follow up date</ControlLabel>
                                                        <Datetime
                                                            value={this.state.resource[`sticky_${sticky.id}_follow_up_date`]}
                                                            onChange={this.dateResourceAttr(`sticky_${sticky.id}_follow_up_date`)}/>
                                                    </FormGroup>
                                                </Col>
                                            </Row>}
                                            <Row>
                                                <Col xs={8}>
                                                    <TextareaAutosize
                                                        name={`sticky_${sticky.id}_body`}
                                                        value={this.state.resource[`sticky_${sticky.id}_body`]}
                                                        minRows={3}
                                                        maxRows={10}
                                                        placeholder="Your note"
                                                        className="sticky-textarea align-bottom"
                                                        onChange={this.updateResourceAttr}
                                                    />
                                                </Col>
                                                <Col xs={4}>
                                                    <Button type="submit" bsStyle="success"
                                                            className="align-bottom sticky-button">Submit</Button>
                                                </Col>
                                            </Row>
                                        </form>}
                                    {
                                        [...sticky.replies, sticky].map(s => s.sticky_type.includes(this.props.type) &&
                                            <Sticky key={s.id} sticky={s}
                                                    onRemove={this.props.remove && (() => this.props.remove(s))}
                                                    onVisibilityChange={this.props.hide && (() => this.props.hide(s))}
                                                    onNotify={this.props.notify && (() => this.props.notify(s))}
                                                    onRead={this.props.read && (() => this.props.read(s))}
                                                    onComplete={this.props.complete && (() => this.props.complete(s))}
                                                    showActionType
                                                    withFollowUp={withFollowUp}
                                                    withRead
                                                    isProposalView={isProposalView}
                                            />)}
                                </Well>)
                        })
                    }
                </section>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({actions: bindActionCreators(Actions, dispatch)})

export default connect(undefined, mapDispatchToProps)(Stickies)
