import React, {useState} from "react";
import {Col, Row} from "react-bootstrap";
import moment from "moment";
import {defaultDateFormat} from "../../../common/commonHandlers";
import * as actions from './Answers/FilesAnswerApi'
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import AttachmentThumbnail from "../../../components/ReorderingDragAndDrop/AttachmentThumbnail/AttachmentThumbnail";

const AnswerTableFormatter = ({content, answer, number, actions}) => {
    const [files, setFiles] = useState([])
    const [loadFiles, setLoadFiles] = useState(false)

    const questionHeader = (question) => <Col xs={12} className="bold pre-line word-break">{number}. {question}</Col>
    const noAnswer = () => <Col xs={12} className="ml-9"><i className="text-gray">No answer</i></Col>

    const answerSwitch = (content) => {
        switch (content.answer_type) {
            case 'text':
                return textAnswer(content.question, answer)
            case 'checkbox':
                return choiceAnswer(content.question, content.options.checkboxOptions, answer)
            case 'switch':
                return choiceAnswer(content.question, content.options.switchOptions, answer)
            case 'dates':
                return datesAnswer(content.question, answer)
            case 'files':
                return filesAnswer(content.question, answer)
            case 'select':
                return selectAnswer(content.question, content.options.selectOptions, answer)
            default:
                return null
        }
    }

    const textAnswer = (question, answer) => {
        return <Row>
            {questionHeader(question)}
            {answer ? <Col xs={12} className="pre-line word-break"><span>{answer}</span></Col> : noAnswer()}
        </Row>
    }

    const choiceAnswer = (question, options, answer) => {
        return <Row>
            {questionHeader(question)}
            {answer && answer.length > 0 ?
                answer.map((a, i) => {
                    return <Col xs={12} className="ml-9">{i + 1}. {options.filter(o => o.id === a)[0]?.label}</Col>
                })
                :
                noAnswer()
            }
        </Row>
    }

    const datesAnswer = (question, answer) => {
        return <Row>
            {questionHeader(question)}
            {answer.startDate && answer.endDate ?
                <Col xs={12} className="ml-9">
                    <span className="mr-9">From: {moment(answer.startDate).format(defaultDateFormat)}</span>
                    <span>To: {moment(answer.endDate).format(defaultDateFormat)}</span>
                </Col>
                :
                noAnswer()
            }
        </Row>
    }

    const selectAnswer = (question, options, answer) => {
        return <Row>
            {questionHeader(question)}
            {answer && answer.length > 0 ?
                answer.map((a, i) => {
                    return <Col xs={12} className="ml-9">{i + 1}. {options.filter(o => o.value === a)[0]?.label}</Col>
                })
                :
                noAnswer()
            }
        </Row>
    }

    const filesAnswer = (question, answer) => {
        if (!loadFiles) {
            setLoadFiles(true)
            actions.getAnswerFiles(answer, res => {
                setFiles(res)
            })
        }

        return <Row>
            {questionHeader(question)}
        </Row>
    }

    return <>
        {answerSwitch(content)}
        <Col xs={12} className="d-flex">
            {files && files.length > 0 ? files.map(f => {
                return <div style={{textWrap: 'wrap'}}>
                    <AttachmentThumbnail
                        key={f.id}
                        file={f}
                        allowRemove={false}
                        deleteAttachment={() => {
                        }}
                        downloadButton={false}
                    />
                </div>
            }) : content.answer_type === 'files' ? noAnswer() : null}
        </Col>
    </>
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch),
});

export default connect(undefined, mapDispatchToProps)(AnswerTableFormatter)