import React from "react"
import MasterDetail from "../../common/MasterDetail";
import {Col, ControlLabel, FormControl, FormGroup, Grid, Row} from "react-bootstrap";
import {merge, validateName} from "../../common/commonHandlers";
import {bindActionCreators} from 'redux'
import createApiService from "../../common/clientActionsBuilder";
import {connect} from "react-redux";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ResourceComponent from "../../components/ResourceComponent";
import ColorCheckbox from "../Scheduler/ColorCheckbox";

const api = createApiService('term_types', 'term_type', 'Term type');

class Terms extends ResourceComponent {
    defaultValues = {
        name: '',
        term: null,
        default_term: false,
        resource: null
    };
    state = {};
    updateResources = resources => {
        this.setState({resources})
    };

    componentDidMount = () => {
        this.props.actions.load({}, this.updateResources);
    };

    handleSelect = e => {
        this.setState({resource: merge(e.resource, this.defaultValues)});
    };

    reloadResources = resource => {
        this.setState({resource});
        this.componentDidMount();
    };

    render() {
        const {resource, resources} = this.state;
        return (
            resources ?
                <Grid fluid>
                    <MasterDetail
                        resourceName="Term"
                        resourceNamePlural="Terms"
                        resources={resources}
                        resource={resource}
                        select={this.handleSelect}
                        save={() => {
                            let validation = validateName(resource.name)
                            if (validation.is_valid) {
                                this.props.actions.save(resource, this.reloadResources)
                            } else {
                                this.setState({errorMessage: validation.errorMessage})
                            }
                        }}
                        delete={() => this.props.actions.remove(resource, x => {
                            this.setState({resource: null}, () => this.props.actions.load({}, this.updateResources));
                        })}>
                        {resource && <div>
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <ControlLabel>
                                            Name
                                        </ControlLabel>
                                        <FormControl
                                            name="name"
                                            onChange={this.updateResourceAttr}
                                            value={resource.name}
                                        />
                                        {this.state.errorMessage &&
                                            <p className="error-message-master-detail ">{this.state.errorMessage}</p>}
                                    </FormGroup>
                                    <FormGroup>
                                        <ControlLabel>
                                            Term
                                        </ControlLabel>
                                        <FormControl
                                            name="term"
                                            type="number"
                                            onChange={this.updateResourceAttr}
                                            value={resource.term ? resource.term : ''}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <ColorCheckbox
                                            label='Default Term?'
                                            onChange={this.selectCheckboxAttr('default_term')}
                                            value={resource.default_term}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                        }
                    </MasterDetail>
                </Grid> : null);
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(api, dispatch),
});

export default connect(undefined, mapDispatchToProps)(Terms)
