import React, {Component} from 'react';
import PropTypes from "prop-types"
import Dollars from "../../components/Dollars";
import {Button, Col, Grid, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import ResourceComponent from "../../components/ResourceComponent";
import moment from "moment";
import {faCalendarDay} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Popover from "react-bootstrap/lib/Popover";
import Overlay from "react-bootstrap/lib/Overlay";
import * as ReactDOM from "react-dom";
import {Draggable} from '@fullcalendar/interaction';

const smMobile = window.screen.width <= 450;

class EventsTooltip extends Component {

    state = {
        isOpen: false
    }

    render() {
        const {workOrder, handleClick, isMobile} = this.props;
        const {isOpen} = this.state;
        const tooltip = workOrder.events && workOrder.events.length > 0 && workOrder.events.map((e, index) => (<div>
            <span
                key={index}>#{index + 1}{' '}{moment(e.start_date).format('LLL')}{' - '}{moment(e.end_date).format('LT')}{' '}<a
                onClick={() => {
                    handleClick(e)
                    isMobile && this.setState({isOpen: false})
                }}>see details...</a></span>
        </div>));

        return (
            <div style={{display: 'grid', color: 'black'}}>
                {workOrder.events.length >= 1 &&
                    <span ref={span => {
                        this.target = span;
                    }} onClick={() => this.setState({isOpen: true})}>
                    <FontAwesomeIcon icon={faCalendarDay}/>
                    <Overlay
                        rootClose
                        show={isOpen}
                        container={this}
                        placement="top"
                        target={() => ReactDOM.findDOMNode(this.target)}
                        onHide={() => this.setState({isOpen: false})}
                    >
                        <Popover>{tooltip}</Popover>
                    </Overlay>
                </span>
                }
            </div>
        )
    }
}

class WorkOrders extends ResourceComponent {
    constructor(props, context) {
        super(props, context);
        this.draggableInstances = {};
    }

    setDraggable = () => {
        const keys = Object.keys(this.draggableInstances);
        const lastKey = keys[keys.length - 1];

        if (lastKey && this.draggableInstances[lastKey]) {
            this.draggableInstances[lastKey].destroy();
            delete this.draggableInstances[lastKey];
        }

        Array.from(document.getElementsByClassName('work-order')).forEach((el) => {
            const elementId = el.getAttribute('data-id')
            if (el && !this.draggableInstances[elementId]) {
                this.draggableInstances[elementId] = new Draggable(el);
            }
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.workOrders.length !== this.props.workOrders.length) {
            this.setDraggable();
        }
    }

    componentDidMount() {
        this.setDraggable();
    }

    render() {
        const {workOrders, onWoClick, setWorkOrder, removePsFromWo, loadWorkOrders} = this.props;
        return (
            <div className="work-orders-select" style={{overflowY: 'auto'}}>
                {workOrders.map((workOrder, i) =>
                    <Grid fluid className="fc-event work-order" key={i} data-id={workOrder.id} onMouseEnter={() => {
                        onWoClick(false)
                        setWorkOrder(workOrder)
                    }}>
                        <Row>
                            <Col md={6}>WO <b>#{workOrder.work_order_no}</b></Col>
                            <Col md={6} className="text-right"><b><Dollars
                                amount={workOrder.service_price}/></b></Col>
                        </Row>
                        <Row>
                            <Col md={6}><b>{workOrder.service_count}</b> services</Col>
                            <Col md={6}
                                 className="text-right"><b>{workOrder.service_hours && workOrder.service_hours.toString().length > 3 ? workOrder.service_hours.toFixed(2) : workOrder.service_hours}</b> hours</Col>
                        </Row>
                        <Row>
                            <Col md={6}>{workOrder.customer_name}</Col>
                            <Col md={6} className={!smMobile && "text-right"}>{workOrder.site_address}</Col>
                        </Row>
                        <Row>
                            <Col md={4}><EventsTooltip
                                isMobile={this.props.isMobile}
                                handleClick={this.props.handleClick}
                                workOrder={workOrder}/></Col>
                            <Col md={8} className="align-right">
                                <OverlayTrigger placement="top"
                                                overlay={<Tooltip id="tooltip">Remove all Proposal Services from Work
                                                    Order</Tooltip>}>
                                    <Button className="pointer text-right modal-body"
                                            bsStyle="info"
                                            disabled={workOrder?.ps_ids?.length === 0}
                                            onClick={() => removePsFromWo(workOrder?.ps_ids, () => loadWorkOrders())}
                                    >
                                        Remove WO
                                    </Button>
                                </OverlayTrigger>
                            </Col>
                        </Row>
                    </Grid>
                )}
            </div>
        );
    }
}

WorkOrders.propTypes = {
    workOrders: PropTypes.array.isRequired,
};

export default WorkOrders;
