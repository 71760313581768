import React, {useEffect, useRef, useState} from 'react';
import {Button, Modal} from "react-bootstrap";
import {Circle, Image as KonvaImage, Layer, Line, Rect, Stage, Text, Transformer} from 'react-konva';
import useImage from 'use-image';
import './ImageDrawTool.scss'
import {
    faCircle,
    faCropAlt,
    faCut,
    faEraser,
    faFont,
    faHandPaper,
    faPencilRuler,
    faSlash,
    faSquare,
    faTimes,
    faTrash,
    faUndo,
    faWaveSquare
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import penIcon from './pen.png'
import eraserIcon from './double-sided-eraser.png'
import Select from "react-select";

const isMobile = window.screen.width < 1024
const scaleBy = 1.01;

function getDistance(p1, p2) {
    return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
}

function getCenter(p1, p2) {
    return {
        x: (p1.x + p2.x) / 2,
        y: (p1.y + p2.y) / 2,
    };
}

const strokeWidthOptions = [{value: 1, label: 1}, {value: 3, label: 3}, {
    value: 6,
    label: 6
}, {value: 10, label: 10}]
const fontSizeOptions = []
for (let i = 1; i < 32; i++)
    fontSizeOptions.push({value: i, label: i});
const opacityOptions = []
for (let i = 1; i < 11; i++)
    opacityOptions.push({value: i / 10, label: i / 10});


const Rectangle = ({
                       shapeProps,
                       isSelected,
                       onSelect,
                       onChange,
                       tool,
                       history,
                       historyStep,
                       setHistory,
                       setHistoryStep
                   }) => {
    const shapeRef = React.useRef();
    const trRef = React.useRef();

    React.useEffect(() => {
        if (isSelected) {
            // we need to attach transformer manually
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [isSelected]);

    return (
        <React.Fragment>
            <Rect
                onClick={tool === 'hand' && onSelect}
                onTap={tool === 'hand' && onSelect}
                ref={shapeRef}
                {...shapeProps}
                draggable={tool === 'hand'}
                onDragEnd={(e) => {
                    onChange({
                        ...shapeProps,
                        x: e.target.x(),
                        y: e.target.y(),
                    });
                    setHistory([...history, {type: 'drag', shapeId: shapeProps.id, x: shapeProps.x, y: shapeProps.y}])
                    setHistoryStep(historyStep + 1)
                }}
                onMouseEnter={e => {
                    const container = e.target.getStage().container();
                    if (tool === 'hand') {
                        container.style.cursor = "grabbing";
                    }
                }}
                onMouseLeave={e => {
                    const container = e.target.getStage().container();
                    if (tool === 'pen') {
                        container.style.cursor = `url(${penIcon}) 0 32, auto`;
                    } else if (tool === 'eraser') {
                        container.style.cursor = `url(${eraserIcon}) 0 32, auto`;
                    } else {
                        container.style.cursor = "crosshair";
                    }

                }}
                onTransformEnd={(e) => {
                    const node = shapeRef.current;
                    const scaleX = node.scaleX();
                    const scaleY = node.scaleY();

                    // we will reset it back
                    node.scaleX(1);
                    node.scaleY(1);
                    onChange({
                        ...shapeProps,
                        x: node.x(),
                        y: node.y(),
                        // set minimal value
                        width: Math.max(5, node.width() * scaleX),
                        height: Math.max(node.height() * scaleY),
                    });
                    setHistory([...history, {
                        type: 'resize',
                        shapeId: shapeProps.id,
                        width: shapeProps.width,
                        height: shapeProps.height
                    }])
                    setHistoryStep(historyStep + 1)
                }}
            />
            {isSelected && (
                <Transformer
                    ref={trRef}
                    boundBoxFunc={(oldBox, newBox) => {
                        // limit resize
                        if (newBox.width < 5 || newBox.height < 5) {
                            return oldBox;
                        }
                        return newBox;
                    }}
                />
            )}
        </React.Fragment>
    );
};
const CropShape = ({
                       shapeProps, isSelected, onSelect, onChange, tool, history,
                       historyStep,
                       setHistory,
                       setHistoryStep
                   }) => {
    const shapeRef = React.useRef();
    const trRef = React.useRef();

    React.useEffect(() => {
        if (isSelected) {
            // we need to attach transformer manually
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [isSelected]);

    return (
        <React.Fragment>
            <Rect
                onClick={tool === 'hand' && onSelect}
                onTap={tool === 'hand' && onSelect}
                ref={shapeRef}
                {...shapeProps}
                draggable={tool === 'hand'}
                onDragEnd={(e) => {
                    onChange({
                        ...shapeProps,
                        x: e.target.x(),
                        y: e.target.y(),
                    });
                    setHistory([...history, {type: 'drag', shapeId: shapeProps.id, x: shapeProps.x, y: shapeProps.y}])
                    setHistoryStep(historyStep + 1)
                }}
                onMouseEnter={e => {
                    const container = e.target.getStage().container();
                    if (tool === 'hand') {
                        container.style.cursor = "grabbing";
                    }
                }}
                onMouseLeave={e => {
                    const container = e.target.getStage().container();
                    if (tool === 'pen') {
                        container.style.cursor = `url(${penIcon}) 0 32, auto`;
                    } else if (tool === 'eraser') {
                        container.style.cursor = `url(${eraserIcon}) 0 32, auto`;
                    } else {
                        container.style.cursor = "crosshair";
                    }

                }}
                onTransformEnd={(e) => {
                    const node = shapeRef.current;
                    const scaleX = node.scaleX();
                    const scaleY = node.scaleY();

                    // we will reset it back
                    node.scaleX(1);
                    node.scaleY(1);
                    onChange({
                        ...shapeProps,
                        x: node.x(),
                        y: node.y(),
                        // set minimal value
                        width: Math.max(5, node.width() * scaleX),
                        height: Math.max(node.height() * scaleY),
                    });
                    setHistory([...history, {
                        type: 'resize',
                        shapeId: shapeProps.id,
                        width: shapeProps.width,
                        height: shapeProps.height
                    }])
                    setHistoryStep(historyStep + 1)
                }}
            />
            {isSelected && (
                <Transformer
                    ref={trRef}
                    boundBoxFunc={(oldBox, newBox) => {
                        // limit resize
                        if (newBox.width < 5 || newBox.height < 5) {
                            return oldBox;
                        }
                        return newBox;
                    }}
                />
            )}
        </React.Fragment>
    );
};
const CutShape = ({
                      shapeProps, isSelected, onSelect, onChange, tool, history,
                      historyStep,
                      setHistory,
                      setHistoryStep
                  }) => {
    const shapeRef = React.useRef();
    const trRef = React.useRef();

    React.useEffect(() => {
        if (isSelected) {
            // we need to attach transformer manually
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [isSelected]);

    return (
        <React.Fragment>
            <Rect
                onClick={tool === 'hand' && onSelect}
                onTap={tool === 'hand' && onSelect}
                ref={shapeRef}
                {...shapeProps}
                draggable={tool === 'hand'}
                onDragEnd={(e) => {
                    onChange({
                        ...shapeProps,
                        x: e.target.x(),
                        y: e.target.y(),
                    });
                    setHistory([...history, {type: 'drag', shapeId: shapeProps.id, x: shapeProps.x, y: shapeProps.y}])
                    setHistoryStep(historyStep + 1)
                }}
                onMouseEnter={e => {
                    const container = e.target.getStage().container();
                    if (tool === 'hand') {
                        container.style.cursor = "grabbing";
                    }
                }}
                onMouseLeave={e => {
                    const container = e.target.getStage().container();
                    if (tool === 'pen') {
                        container.style.cursor = `url(${penIcon}) 0 32, auto`;
                    } else if (tool === 'eraser') {
                        container.style.cursor = `url(${eraserIcon}) 0 32, auto`;
                    } else {
                        container.style.cursor = "crosshair";
                    }

                }}
                onTransformEnd={(e) => {
                    const node = shapeRef.current;
                    const scaleX = node.scaleX();
                    const scaleY = node.scaleY();

                    // we will reset it back
                    node.scaleX(1);
                    node.scaleY(1);
                    onChange({
                        ...shapeProps,
                        x: node.x(),
                        y: node.y(),
                        // set minimal value
                        width: Math.max(5, node.width() * scaleX),
                        height: Math.max(node.height() * scaleY),
                    });
                    setHistory([...history, {
                        type: 'resize',
                        shapeId: shapeProps.id,
                        width: shapeProps.width,
                        height: shapeProps.height
                    }])
                    setHistoryStep(historyStep + 1)
                }}
            />
            {isSelected && (
                <Transformer
                    ref={trRef}
                    boundBoxFunc={(oldBox, newBox) => {
                        // limit resize
                        if (newBox.width < 5 || newBox.height < 5) {
                            return oldBox;
                        }
                        return newBox;
                    }}
                />
            )}
        </React.Fragment>
    );
};

const CircleShape = ({
                         shapeProps, isSelected, onSelect, onChange, tool, history,
                         historyStep,
                         setHistory,
                         setHistoryStep
                     }) => {
    const shapeRef = React.useRef();
    const trRef = React.useRef();

    React.useEffect(() => {
        if (isSelected) {
            // we need to attach transformer manually
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [isSelected]);

    return (
        <React.Fragment>
            <Circle
                onClick={tool === 'hand' && onSelect}
                onTap={tool === 'hand' && onSelect}
                ref={shapeRef}
                {...shapeProps}
                draggable={tool === 'hand'}
                onDragEnd={(e) => {
                    onChange({
                        ...shapeProps,
                        x: e.target.x(),
                        y: e.target.y(),
                    });
                    setHistory([...history, {type: 'drag', shapeId: shapeProps.id, x: shapeProps.x, y: shapeProps.y}])
                    setHistoryStep(historyStep + 1)
                }}
                onMouseEnter={e => {
                    const container = e.target.getStage().container();
                    if (tool === 'hand') {
                        container.style.cursor = "grabbing";
                    }
                }}
                onMouseLeave={e => {
                    const container = e.target.getStage().container();
                    if (tool === 'pen') {
                        container.style.cursor = `url(${penIcon}) 0 32, auto`;
                    } else if (tool === 'eraser') {
                        container.style.cursor = `url(${eraserIcon}) 0 32, auto`;
                    } else {
                        container.style.cursor = "crosshair";
                    }

                }}
                onTransformEnd={(e) => {
                    const node = shapeRef.current;
                    const scaleX = node.scaleX();
                    const scaleY = node.scaleY();

                    // we will reset it back
                    node.scaleX(1);
                    node.scaleY(1);
                    onChange({
                        ...shapeProps,
                        x: node.x(),
                        y: node.y(),
                        // set minimal value
                        width: Math.max(5, node.width() * scaleX),
                        height: Math.max(node.height() * scaleY),
                    });
                    setHistory([...history, {
                        type: 'resize',
                        shapeId: shapeProps.id,
                        width: shapeProps.width,
                        height: shapeProps.height
                    }])
                    setHistoryStep(historyStep + 1)
                }}
            />
            {isSelected && (
                <Transformer
                    ref={trRef}
                    boundBoxFunc={(oldBox, newBox) => {
                        // limit resize
                        if (newBox.width < 5 || newBox.height < 5) {
                            return oldBox;
                        }
                        return newBox;
                    }}
                />
            )}
        </React.Fragment>
    );
};
const ExShape = ({
                     shapeProps, isSelected, onSelect, onChange, tool, history,
                     historyStep,
                     setHistory,
                     setHistoryStep
                 }) => {
    const shapeRef = React.useRef();
    const trRef = React.useRef();

    React.useEffect(() => {
        if (isSelected) {
            // we need to attach transformer manually
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [isSelected]);

    return (
        <React.Fragment>
            <Line
                onClick={tool === 'hand' && onSelect}
                onTap={tool === 'hand' && onSelect}
                ref={shapeRef}
                {...shapeProps}
                draggable={tool === 'hand'}
                onDragEnd={(e) => {
                    onChange({
                        ...shapeProps,
                        x: e.target.x(),
                        y: e.target.y(),
                    });
                    setHistory([...history, {type: 'drag', shapeId: shapeProps.id, x: shapeProps.x, y: shapeProps.y}])
                    setHistoryStep(historyStep + 1)
                }}
                onMouseEnter={e => {
                    const container = e.target.getStage().container();
                    if (tool === 'hand') {
                        container.style.cursor = "grabbing";
                    }
                }}
                onMouseLeave={e => {
                    const container = e.target.getStage().container();
                    if (tool === 'pen') {
                        container.style.cursor = `url(${penIcon}) 0 32, auto`;
                    } else if (tool === 'eraser') {
                        container.style.cursor = `url(${eraserIcon}) 0 32, auto`;
                    } else {
                        container.style.cursor = "crosshair";
                    }

                }}
                onTransformEnd={(e) => {
                    const node = shapeRef.current;
                    const scaleX = node.scaleX();
                    const scaleY = node.scaleY();

                    // we will reset it back
                    node.scaleX(1);
                    node.scaleY(1);
                    onChange({
                        ...shapeProps,
                        x: node.x(),
                        y: node.y(),
                        points: shapeProps.points.map(p => {
                            if (p % 2 === 0) {
                                return p * scaleX
                            } else {
                                return p * scaleY
                            }
                        }),
                        // set minimal value
                        width: Math.max(5, node.width() * scaleX),
                        height: Math.max(node.height() * scaleY),
                    });
                    setHistory([...history, {
                        type: 'resize',
                        shapeId: shapeProps.id,
                        width: shapeProps.width,
                        height: shapeProps.height
                    }])
                    setHistoryStep(historyStep + 1)
                }}
            />
            {isSelected && (
                <Transformer
                    ref={trRef}
                    boundBoxFunc={(oldBox, newBox) => {
                        // limit resize
                        if (newBox.width < 5 || newBox.height < 5) {
                            return oldBox;
                        }
                        return newBox;
                    }}
                />
            )}
        </React.Fragment>
    );
};
const PolylineShape = ({
                           shapeProps, isSelected, onSelect, onChange, tool, history,
                           historyStep,
                           setHistory,
                           setHistoryStep
                       }) => {
    const shapeRef = React.useRef();
    const trRef = React.useRef();

    React.useEffect(() => {
        if (isSelected) {
            // we need to attach transformer manually
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [isSelected]);

    return (
        <React.Fragment>
            <Line
                onClick={tool === 'hand' && onSelect}
                onTap={tool === 'hand' && onSelect}
                ref={shapeRef}
                {...shapeProps}
                draggable={tool === 'hand'}
                onDragEnd={(e) => {
                    onChange({
                        ...shapeProps,
                        x: e.target.x(),
                        y: e.target.y(),
                    });
                    setHistory([...history, {type: 'drag', shapeId: shapeProps.id, x: shapeProps.x, y: shapeProps.y}])
                    setHistoryStep(historyStep + 1)
                }}
                onMouseEnter={e => {
                    const container = e.target.getStage().container();
                    if (tool === 'hand') {
                        container.style.cursor = "grabbing";
                    }
                }}
                onMouseLeave={e => {
                    const container = e.target.getStage().container();
                    if (tool === 'pen') {
                        container.style.cursor = `url(${penIcon}) 0 32, auto`;
                    } else if (tool === 'eraser') {
                        container.style.cursor = `url(${eraserIcon}) 0 32, auto`;
                    } else {
                        container.style.cursor = "crosshair";
                    }

                }}
                onTransformEnd={(e) => {
                    const node = shapeRef.current;
                    const scaleX = node.scaleX();
                    const scaleY = node.scaleY();

                    // we will reset it back
                    node.scaleX(1);
                    node.scaleY(1);
                    onChange({
                        ...shapeProps,
                        x: node.x(),
                        y: node.y(),
                        points: shapeProps.points.map(p => {
                            if (p % 2 === 0) {
                                return p * scaleX
                            } else {
                                return p * scaleY
                            }
                        }),
                        // set minimal value
                        width: Math.max(5, node.width() * scaleX),
                        height: Math.max(node.height() * scaleY),
                    });
                    setHistory([...history, {
                        type: 'resize',
                        shapeId: shapeProps.id,
                        width: shapeProps.width,
                        height: shapeProps.height
                    }])
                    setHistoryStep(historyStep + 1)
                }}
            />
            {isSelected && (
                <Transformer
                    ref={trRef}
                    boundBoxFunc={(oldBox, newBox) => {
                        // limit resize
                        if (newBox.width < 5 || newBox.height < 5) {
                            return oldBox;
                        }
                        return newBox;
                    }}
                />
            )}
        </React.Fragment>
    );
};
const LineShape = ({
                       shapeProps, isSelected, onSelect, onChange, tool, history,
                       historyStep,
                       setHistory,
                       setHistoryStep
                   }) => {
    const shapeRef = React.useRef();
    const trRef = React.useRef();

    React.useEffect(() => {
        if (isSelected) {
            // we need to attach transformer manually
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [isSelected]);

    return (
        <React.Fragment>
            <Line
                onClick={tool === 'hand' && onSelect}
                onTap={tool === 'hand' && onSelect}
                ref={shapeRef}
                {...shapeProps}
                draggable={tool === 'hand'}
                onDragEnd={(e) => {
                    onChange({
                        ...shapeProps,
                        x: e.target.x(),
                        y: e.target.y(),
                    });
                    setHistory([...history, {type: 'drag', shapeId: shapeProps.id, x: shapeProps.x, y: shapeProps.y}])
                    setHistoryStep(historyStep + 1)
                }}
                onMouseEnter={e => {
                    const container = e.target.getStage().container();
                    if (tool === 'hand') {
                        container.style.cursor = "grabbing";
                    }
                }}
                onMouseLeave={e => {
                    const container = e.target.getStage().container();
                    if (tool === 'pen') {
                        container.style.cursor = `url(${penIcon}) 0 32, auto`;
                    } else if (tool === 'eraser') {
                        container.style.cursor = `url(${eraserIcon}) 0 32, auto`;
                    } else {
                        container.style.cursor = "crosshair";
                    }

                }}
                onTransformEnd={(e) => {
                    const node = shapeRef.current;
                    const scaleX = node.scaleX();
                    const scaleY = node.scaleY();

                    // we will reset it back
                    node.scaleX(1);
                    node.scaleY(1);
                    onChange({
                        ...shapeProps,
                        x: node.x(),
                        y: node.y(),
                        points: shapeProps.points.map(p => {
                            if (p % 2 === 0) {
                                return p * scaleX
                            } else {
                                return p * scaleY
                            }
                        }),
                        // set minimal value
                        width: Math.max(5, node.width() * scaleX),
                        height: Math.max(node.height() * scaleY),
                    });
                    setHistory([...history, {
                        type: 'resize',
                        shapeId: shapeProps.id,
                        width: shapeProps.width,
                        height: shapeProps.height
                    }])
                    setHistoryStep(historyStep + 1)
                }}
            />
            {isSelected && (
                <Transformer
                    ref={trRef}
                    boundBoxFunc={(oldBox, newBox) => {
                        // limit resize
                        if (newBox.width < 5 || newBox.height < 5) {
                            return oldBox;
                        }
                        return newBox;
                    }}
                />
            )}
        </React.Fragment>
    );
};
const TextArea = ({
                      shapeProps, isSelected, onSelect, onChange, onDblClick, onDblTap, tool, history,
                      historyStep,
                      setHistory,
                      setHistoryStep
                  }) => {
    const shapeRef = React.useRef();
    const trRef = React.useRef();

    React.useEffect(() => {
        if (isSelected) {
            // we need to attach transformer manually
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [isSelected]);

    return (
        <React.Fragment>
            <Rect
                x={shapeProps.x}
                y={shapeProps.y}
                width={shapeProps.width}
                height={shapeProps.height}
                fill={shapeProps.backgroundRect.fill}
            />
            <Text
                onClick={tool === 'hand' && onSelect}
                onTap={tool === 'hand' && onSelect}
                padding={10}
                ref={shapeRef}
                {...shapeProps}
                draggable={tool === 'hand'}
                onDragEnd={(e) => {
                    onChange({
                        ...shapeProps,
                        x: e.target.x(),
                        y: e.target.y()
                    });
                    setHistory([...history, {type: 'drag', shapeId: shapeProps.id, x: shapeProps.x, y: shapeProps.y}])
                    setHistoryStep(historyStep + 1)
                }}
                onMouseEnter={e => {
                    const container = e.target.getStage().container();
                    if (tool === 'hand') {
                        container.style.cursor = "grabbing";
                    }
                }}
                onMouseLeave={e => {
                    const container = e.target.getStage().container();
                    if (tool === 'pen') {
                        container.style.cursor = `url(${penIcon}) 0 32, auto`;
                    } else if (tool === 'eraser') {
                        container.style.cursor = `url(${eraserIcon}) 0 32, auto`;
                    } else {
                        container.style.cursor = "crosshair";
                    }

                }}
                onTransformEnd={(e) => {
                    const node = shapeRef.current;
                    const scaleX = node.scaleX();
                    const scaleY = node.scaleY();

                    // we will reset it back
                    node.scaleX(1);
                    node.scaleY(1);
                    onChange({
                        ...shapeProps,
                        x: node.x(),
                        y: node.y(),
                        // set minimal value
                        width: Math.max(5, node.width() * scaleX),
                        height: Math.max(node.height() * scaleY),
                    });
                    setHistory([...history, {
                        type: 'resize',
                        shapeId: shapeProps.id,
                        width: shapeProps.width,
                        height: shapeProps.height
                    }])
                    setHistoryStep(historyStep + 1)
                }}
                onDblClick={onDblClick}
                onDblTap={onDblTap}
            />
            {isSelected && (
                <Transformer
                    ref={trRef}
                    boundBoxFunc={(oldBox, newBox) => {
                        // limit resize
                        if (newBox.width < 5 || newBox.height < 5) {
                            return oldBox;
                        }
                        return newBox;
                    }}
                />
            )}
        </React.Fragment>
    );
};
const ImageDrawTool = ({imageId, imageUrl, onClose, onSave}) => {
    const [tool, setTool] = useState(isMobile ? 'hand' : 'pen');
    const [lines, setLines] = useState([]);
    const [imgDimensions, setImgDimensions] = useState({width: 0, height: 0});
    const [stageDimensions, setStageDimensions] = useState({width: 0, height: 0});
    const isDrawingRef = useRef(false);
    const stageRef = useRef(null);
    const imgRef = useRef(null);
    const headerRef = useRef(null);
    const [image] = useImage(imageUrl.replace('original', 'med'), 'Anonymous');
    const [rotation, setRotation] = useState(0)
    const [color, setColor] = useState('#ff0000')
    const [backgroundColor, setBackgroundColor] = useState('#ffffff')
    const [strokeWidth, setStrokeWidth] = useState(3)
    const [fontSize, setFontSize] = useState(16)
    const [opacity, setOpacity] = useState(1)
    const [textEditor, setTextEditor] = useState({
        textEditVisible: false,
        textX: 0,
        fill: "black",
        textY: 0,
        textValue: "Double click to edit\nand finish",
        fontSize: 8,
        width: 400,
        fontStyle: "normal",
        align: "left",
        id: 0,
        areaHeight: 0,
        areaWidth: 0,
        className: 'text0'
    })
    const [polylinePoints, setPolylinePoints] = useState([]);
    const [crops, setCrops] = useState([]);
    const [cuts, setCuts] = useState([]);
    const [rectangles, setRectangles] = useState([]);
    const [circles, setCircles] = useState([]);
    const [texts, setTexts] = useState([]);
    const [straightLines, setStraightLines] = useState([]);
    const [exes, setExes] = useState([]);
    const [polylines, setPolylines] = useState([]);
    const [tempPolylines, setTempPolylines] = useState([]);
    const [selectedId, selectShape] = useState(null);
    const [history, setHistory] = useState([]);
    const [historyStep, setHistoryStep] = useState(0);
    const [newLineAdded, setNewLineAdded] = useState(false);
    const textareaRef = useRef()
    const [tempScale, setTempScale] = useState(1);
    const [readyToExport, setReadyToExport] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (readyToExport) {
            handleExport()
        }
    }, [readyToExport])
    useEffect(() => {
        if (tempScale) {
            resizeShapes(tempScale)
        }
    }, [tempScale])

    useEffect(() => {

        if (!loading && isMobile) {
            const stage = stageRef.current;
            const image = imgRef.current;
            const headerHeight = headerRef.current.clientHeight

            let scale

            if (image.attrs.height > (image.attrs.width * 1.7)) {
                scale = (window.innerHeight - (headerHeight + 60)) / image.attrs.height;
            } else {
                scale = (window.innerWidth - 25) / image.attrs.width;
            }


            setTempScale(scale)

            stage.width(stage.attrs.width * scale);
            image.width(image.attrs.width * scale);
            stage.height(stage.attrs.height * scale);
            image.height(image.attrs.height * scale);
            stage.batchDraw();
        }
    }, [loading])
    let lastCenter = null;
    let lastDist = 0;

    const resizeShapes = (newScale) => {
        rectangles && setRectangles(rectangles.map(r => {
            r.x = r.x * newScale
            r.y = r.y * newScale
            r.width = r.width * newScale
            r.height = r.height * newScale
            return r
        }))
        circles && setCircles(circles.map(c => {
            c.x = c.x * newScale
            c.y = c.y * newScale
            c.radius = Math.abs(c.radius) * newScale
            return c
        }))
        cuts && setCuts(cuts.map(c => {
            c.x = c.x * newScale
            c.y = c.y * newScale
            c.width = c.width * newScale
            c.height = c.height * newScale
            return c
        }))
        crops && setCrops(crops.map(c => {
            c.x = c.x * newScale
            c.y = c.y * newScale
            c.width = c.width * newScale
            c.height = c.height * newScale
            return c
        }))
        texts && setTexts(texts.map(t => {
            t.x = t.x * newScale
            t.y = t.y * newScale
            t.width = t.width * newScale
            t.height = t.height * newScale
            return t
        }))
        exes && setExes(exes.map(e => {
            e.x = e.x * newScale
            e.y = e.y * newScale
            e.width = e.width * newScale
            e.height = e.height * newScale
            return e
        }))
        straightLines && setStraightLines(straightLines.map(sl => {
            sl.x = sl.x * newScale
            sl.y = sl.y * newScale
            sl.points = sl.points.map(p => {
                p = p * newScale
                return p
            })
            return sl
        }))
        polylines && setPolylines(polylines.map(pl => {
            pl.x = pl.x * newScale
            pl.y = pl.y * newScale
            pl.points = pl.points.map(p => {
                p = p * newScale
                return p
            })
            return pl
        }))
        lines && setLines(lines.map(l => {
            l.x = l.x * newScale
            l.y = l.y * newScale
            l.points = l.points.map(p => {
                p = p * newScale
                return p
            })
            return l
        }))
    }

    function zoomStage(event) {
        event.evt.preventDefault();
        if (stageRef.current !== null) {
            const stage = stageRef.current;
            const image = imgRef.current;
            const oldScale = stage.scaleX();
            const newScale = event.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;

            resizeShapes(newScale)
            stage.width(stage.attrs.width * newScale);
            image.width(image.attrs.width * newScale);
            stage.height(stage.attrs.height * newScale);
            image.height(image.attrs.height * newScale);

            stage.batchDraw();
        }
    }

    function handleTouchResize(e) {
        e.evt.preventDefault();
        let touch1 = e.evt.touches[0];
        let touch2 = e.evt.touches[1];
        const stage = stageRef.current;
        const image = imgRef.current;
        if (stage !== null) {
            if (touch1 && touch2) {
                if (stage.isDragging()) {
                    stage.stopDrag();
                }

                let p1 = {
                    x: touch1.clientX,
                    y: touch1.clientY
                };
                let p2 = {
                    x: touch2.clientX,
                    y: touch2.clientY
                };

                if (!lastCenter) {
                    lastCenter = getCenter(p1, p2);
                    return;
                }
                let newCenter = getCenter(p1, p2);

                let dist = getDistance(p1, p2);

                if (!lastDist) {
                    lastDist = dist;
                }

                let scale = stage.scaleX() * (dist / lastDist);

                setTempScale(scale)

                stage.width(stage.attrs.width * scale);
                image.width(image.attrs.width * scale);
                stage.height(stage.attrs.height * scale);
                image.height(image.attrs.height * scale);
                stage.batchDraw();
                lastDist = dist;
                lastCenter = newCenter;
            }
        }
    }

    function handleTouchResizeEnd() {
        lastCenter = null;
        lastDist = 0;
    }

    useEffect(() => {
        if (textareaRef && textareaRef.current) {
            textareaRef.current.addEventListener("touchstart", tapHandler);
        }

        return () => {
            textareaRef.current.removeEventListener("touchstart", tapHandler);
        }
    })

    let tapedTwice = false;
    const tapHandler = (e) => {
        if (!tapedTwice) {
            tapedTwice = true;
            setTimeout(function () {
                tapedTwice = false;
            }, 300);
            return false;
        }
        e.preventDefault();
        handleTextareaSave(e)
    }

    const handleChangeColor = e => {
        setColor(e.target.value)
    };

    const handleChangeBackgroundColor = e => {
        setBackgroundColor(e.target.value)
    }

    const handleChangeStrokeWidth = e => {
        setStrokeWidth(e.value)
    }
    const handleChangeFontSize = e => {
        setFontSize(e.value)
    }
    const handleChangeOpacity = e => {
        setOpacity(e.value)
    }

    const addExes = (e) => {
        const container = e.target.getStage().container();
        container.style.cursor = 'crosshair'
        let newExes = exes
        newExes.push({
            x: e.currentTarget.pointerPos.x,
            y: e.currentTarget.pointerPos.y,
            points: [0, 0, 20, 20, 10, 10, 20, 0, 10, 10, 0, 20],
            stroke: color,
            strokeWidth: strokeWidth,
            id: `exes${newExes.length}`,
        })
        setHistory([...history, {type: 'shape', shapeId: `exes${newExes.length - 1}`}])
        setHistoryStep(historyStep + 1)
        setExes(newExes)
        setTool('hand')
        setTool('x')

    }
    const handlePolylineFinish = () => {
        isDrawingRef.current = false;
        setPolylines([...polylines, tempPolylines[tempPolylines.length - 1]])
        setPolylinePoints([])
        setTempPolylines([])
        setTool('hand')
        setHistory([...history, {type: 'shape', shapeId: `polyline${polylines.length}`}])
        setHistoryStep(historyStep + 1)
    }
    const handlePolylineStart = (e) => {
        const container = e.target.getStage().container();
        container.style.cursor = 'crosshair';
        isDrawingRef.current = true;
        const pos = e.target.getStage().getPointerPosition();
        setTempPolylines([
            ...tempPolylines,
            {
                x: 0,
                y: 0,
                points: polylinePoints,
                stroke: color,
                strokeWidth: strokeWidth,
                id: `polyline${polylines.length}`
            },
        ]);
        setPolylinePoints(polylinePoints.concat([pos.x, pos.y]))
    }
    const handlePolylineMove = (e) => {
        if (!isDrawingRef.current) {
            return;
        }
        const stage = e.target.getStage();
        const point = stage.getPointerPosition();
        let lastPolyline = tempPolylines[tempPolylines.length - 1];
        lastPolyline.points = polylinePoints.concat([point.x, point.y]);
        tempPolylines.splice(lastPolyline.length - 1, 1, lastPolyline);
        setTempPolylines(tempPolylines.concat());
    }
    const handleUndo = () => {
        if (historyStep === 0) {
            return;
        }
        const previous = history[historyStep - 1];
        setHistory(history.slice(0, historyStep - 1))
        setHistoryStep(historyStep - 1)
        if (previous.type === 'crop') {
            setStageDimensions(previous.stage)
            setImgDimensions(previous.imageDimensions)
            imgRef.current.crop({
                x: previous.image.x,
                y: previous.image.y,
                width: previous.image.width,
                height: previous.image.height
            });
        } else if (previous.type === 'shape') {
            setCircles(circles.filter(c => c.id !== previous.shapeId))
            setStraightLines(straightLines.filter(sl => sl.id !== previous.shapeId))
            setPolylines(polylines.filter(p => p.id !== previous.shapeId))
            setRectangles(rectangles.filter(r => r.id !== previous.shapeId))
            setExes(exes.filter(ex => ex.id !== previous.shapeId))
            setTexts(texts.filter(t => t.id !== previous.shapeId))
            setCrops(crops.filter(c => c.id !== previous.shapeId))
            setCuts(cuts.filter(c => c.id !== previous.shapeId))
        } else if (previous.type === 'drag') {
            if (previous.shapeId.includes('rect')) {
                setRectangles(rectangles.map(r => {
                    if (r.id === previous.shapeId) {
                        r.x = previous.x
                        r.y = previous.y
                    }
                    return r
                }))
            } else if (previous.shapeId.includes('circle')) {
                setCircles(circles.map(c => {
                    if (c.id === previous.shapeId) {
                        c.x = previous.x
                        c.y = previous.y
                    }
                    return c
                }))
            } else if (previous.shapeId.includes('straightLine')) {
                setStraightLines(straightLines.map(sl => {
                    if (sl.id === previous.shapeId) {
                        sl.x = previous.x
                        sl.y = previous.y
                    }
                    return sl
                }))
            } else if (previous.shapeId.includes('polyline')) {
                setPolylines(polylines.map(pl => {
                    if (pl.id === previous.shapeId) {
                        pl.x = previous.x
                        pl.y = previous.y
                    }
                    return pl
                }))
            } else if (previous.shapeId.includes('exes')) {
                setExes(exes.map(ex => {
                    if (ex.id === previous.shapeId) {
                        ex.x = previous.x
                        ex.y = previous.y
                    }
                    return ex
                }))
            } else if (previous.shapeId.includes('text')) {
                setTexts(texts.map(t => {
                    if (t.id === previous.shapeId) {
                        t.x = previous.x
                        t.y = previous.y
                        t.backgroundRect.x = previous.x
                        t.backgroundRect.y = previous.y
                    }
                    return t
                }))
            } else if (previous.shapeId.includes('crop')) {
                setCrops(crops.map(c => {
                    if (c.id === previous.shapeId) {
                        c.x = previous.x
                        c.y = previous.y
                    }
                    return c
                }))
            } else if (previous.shapeId.includes('cut')) {
                setCuts(cuts.map(c => {
                    if (c.id === previous.shapeId) {
                        c.x = previous.x
                        c.y = previous.y
                    }
                    return c
                }))
            }
        } else if (previous.type === 'resize') {
            if (previous.shapeId.includes('rect')) {
                setRectangles(rectangles.map(r => {
                    if (r.id === previous.shapeId) {
                        r.width = previous.width
                        r.height = previous.height
                    }
                    return r
                }))
            } else if (previous.shapeId.includes('circle')) {
                setCircles(circles.map(c => {
                    if (c.id === previous.shapeId) {
                        c.width = previous.width
                        c.height = previous.height
                    }
                    return c
                }))
            } else if (previous.shapeId.includes('straightLine')) {
                setStraightLines(straightLines.map(sl => {
                    if (sl.id === previous.shapeId) {
                        sl.width = previous.width
                        sl.height = previous.height
                    }
                    return sl
                }))
            } else if (previous.shapeId.includes('polyline')) {
                setPolylines(polylines.map(pl => {
                    if (pl.id === previous.shapeId) {
                        pl.width = previous.width
                        pl.height = previous.height
                    }
                    return pl
                }))
            } else if (previous.shapeId.includes('exes')) {
                setExes(exes.map(ex => {
                    if (ex.id === previous.shapeId) {
                        ex.width = previous.width
                        ex.height = previous.height
                    }
                    return ex
                }))
            } else if (previous.shapeId.includes('text')) {
                setTexts(texts.map(t => {
                    if (t.id === previous.shapeId) {
                        t.width = previous.width
                        t.height = previous.height
                    }
                    return t
                }))
            } else if (previous.shapeId.includes('crop')) {
                setCrops(crops.map(c => {
                    if (c.id === previous.shapeId) {
                        c.width = previous.width
                        c.height = previous.height
                    }
                    return c
                }))
            } else if (previous.shapeId.includes('cut')) {
                setCuts(cuts.map(c => {
                    if (c.id === previous.shapeId) {
                        c.width = previous.width
                        c.height = previous.height
                    }
                    return c
                }))
            }
        } else if (previous.type === 'drawing') {
            lines.pop()
            setLines(lines)

        }
    };

    const crop = () => {
        const stage = stageRef.current
        let width = image.width
        let scale = stage.width() / width
        scale = 1 / scale
        const currentImage = imgRef.current.crop()
        let croppedImage = imgRef.current.crop()
        imgRef.current.crop({
            x: crops[0].width < 0 ? (crops[0].x + crops[0].width - (stageRef.current.attrs.width - imgRef.current.attrs.width) / 2 + croppedImage.x) * scale : (crops[0].x - (stageRef.current.attrs.width - imgRef.current.attrs.width) / 2 + croppedImage.x) * scale,
            y: crops[0].height < 0 ? (crops[0].y + crops[0].height - (stageRef.current.attrs.height - imgRef.current.attrs.height) / 2 + croppedImage.y) * scale : (crops[0].y - (stageRef.current.attrs.height - imgRef.current.attrs.height) / 2 + croppedImage.y) * scale,
            width: Math.abs(crops[0].width) * scale,
            height: Math.abs(crops[0].height) * scale
        });
        let stageWidth = Math.abs(crops[0].width) * scale
        let stageHeight = Math.abs(crops[0].height) * scale
        setStageDimensions({width: stageWidth, height: stageHeight})
        setImgDimensions({width: Math.abs(crops[0].width) * scale, height: Math.abs(crops[0].height) * scale})
        setHistory([...history, {
            type: 'crop',
            image: currentImage,
            imageDimensions: {width: imgDimensions.width, height: imgDimensions.height},
            stage: {width: stageDimensions.width, height: stageDimensions.height}
        }])
        setHistoryStep(historyStep + 1)
        setCrops([])
    }

    const handleMouseDown = (e) => {
        setNewLineAdded(false)
        const container = e.target.getStage().container();
        if (tool === 'pen') {
            container.style.cursor = `url(${penIcon}) 0 32, auto`;
        } else if (tool === 'eraser') {
            container.style.cursor = `url(${eraserIcon}) 0 32, auto`;
        } else {
            container.style.cursor = 'crosshair';
        }
        isDrawingRef.current = true;
        const pos = e.target.getStage().getPointerPosition();
        if (tool === 'rectangle') {
            setRectangles([
                ...rectangles,
                {
                    x: pos.x,
                    y: pos.y,
                    width: 0,
                    height: 0,
                    fill: "rgba(255, 0, 0, 0.0)",
                    opacity: opacity,
                    stroke: color,
                    strokeWidth: strokeWidth,
                    id: `rect${rectangles.length}`
                },
            ]);
            setHistory([...history, {type: 'shape', shapeId: `rect${rectangles.length}`}])
            setHistoryStep(historyStep + 1)
        } else if (tool === 'circle') {
            setCircles([
                ...circles,
                {
                    x: pos.x,
                    y: pos.y,
                    radius: 0,
                    fill: "rgba(255, 0, 0, 0.0)",
                    opacity: opacity,
                    stroke: color,
                    strokeWidth: strokeWidth,
                    id: `circle${circles.length}`
                },
            ]);
            setHistory([...history, {type: 'shape', shapeId: `circle${circles.length}`}])
            setHistoryStep(historyStep + 1)
        } else if (tool === 'crop') {
            setCrops([
                ...crops,
                {
                    x: pos.x,
                    y: pos.y,
                    width: 0,
                    height: 0,
                    fill: 'rgba(0, 0, 139, 0.3)',
                    stroke: 'rgb(0, 0, 139)',
                    strokeWidth: 3,
                    dash: [10, 10],
                    id: `crop${crops.length}`
                },
            ]);
            setHistory([...history, {type: 'shape', shapeId: `crop${crops.length}`}])
            setHistoryStep(historyStep + 1)
        } else if (tool === 'cut') {
            setCuts([
                ...cuts,
                {
                    x: pos.x,
                    y: pos.y,
                    width: 0,
                    height: 0,
                    fill: 'white',
                    id: `cut${cuts.length}`
                },
            ]);
            setHistory([...history, {type: 'shape', shapeId: `cut${cuts.length}`}])
            setHistoryStep(historyStep + 1)
        } else if (tool === 'text') {
            setTexts([
                ...texts,
                {
                    x: pos.x,
                    y: pos.y,
                    text: "Double click to edit\nand finish",
                    width: 0,
                    height: 0,
                    fill: color,
                    fontSize: fontSize,
                    align: 'left',
                    id: `text${texts.length}`,
                    backgroundRect: {
                        fill: "white",
                        opacity: opacity
                    },
                },
            ]);
            setHistory([...history, {type: 'shape', shapeId: `text${texts.length}`}])
            setHistoryStep(historyStep + 1)
        } else if (tool === 'line') {
            setStraightLines([
                ...straightLines,
                {
                    x: 0,
                    y: 0,
                    points: [pos.x, pos.y],
                    stroke: color,
                    strokeWidth: strokeWidth,
                    id: `straightLine${straightLines.length}`
                },
            ]);
            setHistory([...history, {type: 'shape', shapeId: `straightLine${straightLines.length}`}])
            setHistoryStep(historyStep + 1)
        } else {
            let scale = stageRef.current.attrs.scaleX
            let x = stageRef.current.attrs.x
            let y = stageRef.current.attrs.y
            if (scale < 1) {
                setLines([...lines, {
                    tool,
                    points: [(1 - scale) * pos.x + scale * pos.x, (1 - scale) * pos.y + scale * pos.y],
                    stroke: color,
                    strokeWidth: strokeWidth
                }]);
                setNewLineAdded(true)
            } else if (scale > 1) {
                setLines([...lines, {tool, points: [pos.x, pos.y], stroke: color, strokeWidth: strokeWidth}]);
                setNewLineAdded(true)
            } else {
                setLines([...lines, {tool, points: [pos.x, pos.y], stroke: color, strokeWidth: strokeWidth}]);
                setNewLineAdded(true)
            }

        }

    };
    const handleMouseMove = (e) => {
        // no drawing - skipping
        if (!isDrawingRef.current) {
            return;
        }
        const stage = e.target.getStage();
        const point = stage.getPointerPosition();
        if (tool === 'rectangle') {
            let lastRec = rectangles[rectangles.length - 1];
            lastRec.width = point.x - lastRec.x;
            lastRec.height = point.y - lastRec.y;
            rectangles.splice(rectangles.length - 1, 1, lastRec);
            setRectangles(rectangles.concat());
        } else if (tool === 'circle') {
            let lastCirc = circles[circles.length - 1]
            lastCirc.radius = Math.sqrt(Math.pow(point.x - lastCirc.x, 2) + Math.pow(point.y - lastCirc.y, 2)) / 2
            circles.splice(circles.length - 1, 1, lastCirc);
            setCircles(circles.concat());
        } else if (tool === 'crop') {
            let lastCrop = crops[crops.length - 1];
            lastCrop.width = point.x - lastCrop.x;
            lastCrop.height = point.y - lastCrop.y;
            crops.splice(crops.length - 1, 1, lastCrop);
            setCrops(crops.concat());
        } else if (tool === 'cut') {
            let lastCut = cuts[cuts.length - 1];
            lastCut.width = point.x - lastCut.x;
            lastCut.height = point.y - lastCut.y;
            cuts.splice(cuts.length - 1, 1, lastCut);
            setCuts(cuts.concat());
        } else if (tool === 'text') {
            let lastText = texts[texts.length - 1];
            lastText.width = point.x - lastText.x;
            lastText.height = point.y - lastText.y;
            texts.splice(crops.length - 1, 1, lastText);
            setTexts(texts.concat());
        } else if (tool === 'line') {
            let lastStraightLine = straightLines[straightLines.length - 1];
            lastStraightLine.points = [lastStraightLine.points[0], lastStraightLine.points[1], point.x, point.y];
            straightLines.splice(straightLines.length - 1, 1, lastStraightLine);
            setStraightLines(straightLines.concat());
        } else {
            let lastLine = lines[lines.length - 1];
            // add point
            lastLine.points = lastLine.points.concat([point.x, point.y]);

            // replace last
            lines.splice(lines.length - 1, 1, lastLine);
            setLines(lines.concat());
        }
    };

    const handleMouseUp = () => {
        isDrawingRef.current = false;
        lastCenter = null;
        lastDist = 0;
        if (newLineAdded) {
            setHistory([...history, {type: 'drawing'}])
            setHistoryStep(historyStep + 1)
        }
        if (tool === 'crop' || tool === 'text') {
            setTool('hand')
        }
    };

    const prepareExport = () => {
        const stage = stageRef.current
        let width = image.width
        let scale = stage.width() / width
        let newScale = 1 / scale
        rectangles && setRectangles(rectangles.map(r => {
            r.x = r.x * newScale
            r.y = r.y * newScale
            r.width = r.width * newScale
            r.height = r.height * newScale
            return r
        }))
        circles && setCircles(circles.map(c => {
            c.x = c.x * newScale
            c.y = c.y * newScale
            c.radius = Math.abs(c.radius) * newScale
            return c
        }))
        cuts && setCuts(cuts.map(c => {
            c.x = c.x * newScale
            c.y = c.y * newScale
            c.width = c.width * newScale
            c.height = c.height * newScale
            return c
        }))
        crops && setCrops(crops.map(c => {
            c.x = c.x * newScale
            c.y = c.y * newScale
            c.width = c.width * newScale
            c.height = c.height * newScale
            return c
        }))
        texts && setTexts(texts.map(t => {
            t.x = t.x * newScale
            t.y = t.y * newScale
            t.width = t.width * newScale
            t.height = t.height * newScale
            return t
        }))
        exes && setExes(exes.map(e => {
            e.x = e.x * newScale
            e.y = e.y * newScale
            e.width = e.width * newScale
            e.height = e.height * newScale
            return e
        }))
        straightLines && setStraightLines(straightLines.map(sl => {
            sl.x = sl.x * newScale
            sl.y = sl.y * newScale
            sl.points = sl.points.map(p => {
                p = p * newScale
                return p
            })
            return sl
        }))
        polylines && setPolylines(polylines.map(pl => {
            pl.x = pl.x * newScale
            pl.y = pl.y * newScale
            pl.points = pl.points.map(p => {
                p = p * newScale
                return p
            })
            return pl
        }))
        lines && setLines(lines.map(l => {
            l.x = l.x * newScale
            l.y = l.y * newScale
            l.points = l.points.map(p => {
                p = p * newScale
                return p
            })
            return l
        }))
        setReadyToExport(true)
    }
    const handleExport = () => {
        const config = {
            callback: (img) => {
                onSave(img.src)
                onClose()
            },
            mimeType: "image/jpeg",
        }
        const stage = stageRef.current
        const imageRef = imgRef.current
        let width = image.width
        let height = image.height

        stage.width(width)
        imageRef.width(width)
        stage.height(height)
        imageRef.height(height)
        stage.toImage(config);
    };
    const handleDelete = (e) => {
        if (e.key === 'Delete') {
            setCircles(circles.filter(c => c.id !== selectedId))
            setStraightLines(straightLines.filter(sl => sl.id !== selectedId))
            setPolylines(polylines.filter(p => p.id !== selectedId))
            setRectangles(rectangles.filter(r => r.id !== selectedId))
            setExes(exes.filter(ex => ex.id !== selectedId))
            setTexts(texts.filter(t => t.id !== selectedId))
            setCrops(crops.filter(c => c.id !== selectedId))
            setCuts(cuts.filter(c => c.id !== selectedId))
        }
    }
    const deleteSelectedItem = () => {
        setCircles(circles.filter(c => c.id !== selectedId))
        setStraightLines(straightLines.filter(sl => sl.id !== selectedId))
        setPolylines(polylines.filter(p => p.id !== selectedId))
        setRectangles(rectangles.filter(r => r.id !== selectedId))
        setExes(exes.filter(ex => ex.id !== selectedId))
        setTexts(texts.filter(t => t.id !== selectedId))
        setCrops(crops.filter(c => c.id !== selectedId))
        setCuts(cuts.filter(c => c.id !== selectedId))
    }
    const handleTextareaSave = e => {
        setTextEditor({...textEditor, textEditVisible: false})
        let newTexts = texts.map(t => {
            if (t.id == textEditor.className) {
                t.fill = color
                t.fontSize = fontSize
                t.text = e.target.value
                t.backgroundRect.fill = backgroundColor;
                return t
            } else {
                return t
            }
        })
        setTexts(newTexts)
    };
    const handleTextEdit = e => {
        setTextEditor({...textEditor, textValue: e.target.value})
    };
    const handleTextDblClick = e => {
        selectShape(null);
        setTextEditor({
            ...textEditor,
            textValue: texts.find(t => t.id === e.currentTarget.attrs.id).text,
            className: e.currentTarget.attrs.id,
            textEditVisible: true,
            textX: window.innerWidth > stageRef.current.attrs.width ? (window.innerWidth - stageRef.current.attrs.width) / 2 + e.currentTarget.attrs.x : (window.innerWidth / 2) - e.currentTarget.attrs.width / 2,
            textY: e.currentTarget.attrs.y,
            areaHeight: e.currentTarget.attrs.height,
            areaWidth: e.currentTarget.attrs.width,
        })
    };

    const checkDeselect = (e) => {
        const container = e.target.getStage().container();
        container.style.cursor = 'crosshair'
        const clickedOnEmpty = (e.target === e.target.getStage()) || (e.target.attrs.name === 'image');
        if (clickedOnEmpty) {
            selectShape(null);
        }
    };
    useEffect(() => {
        const img = new Image()
        img.onload = function () {
            setImgDimensions({
                width: this.width,
                height: this.height
            })
            setStageDimensions({
                width: this.width,
                height: this.height
            })
            setLoading(false)
        }
        img.src = imageUrl.replace('original', 'med')
    }, [])

    const toolSelect = (tool) => {
        switch (tool) {
            case 'pen':
                return handleMouseDown;
            case 'eraser':
                return handleMouseDown;
            case 'text':
                return handleMouseDown;
            case 'rectangle':
                return handleMouseDown;
            case 'circle':
                return handleMouseDown;
            case 'x':
                return addExes;
            case 'line':
                return handleMouseDown;
            case 'polyline':
                return handlePolylineStart;
            case 'crop':
                return handleMouseDown;
            case 'cut':
                return handleMouseDown;
            case 'hand':
                return checkDeselect;
            default:
                return checkDeselect;
        }
    }
    return (
        <Modal
            id={"myModalxxx"}
            bsSize="large"
            animation={false}
            className={"image-draw-wrapper"}
            show={true}
            onHide={onClose}
        >
            <Modal.Header>
                <span className='modal-header-ref' ref={headerRef}>
                    <div className="title">
                        <span>Draw tool</span>
                    </div>
                    <div className="tools">
                        <div className='tool-wrapper'>
                            <div className="text">
                                <Button className={tool === 'text' && 'active'} onClick={() => setTool('text')
                                }>
                                    <FontAwesomeIcon icon={faFont}/>
                                    <span className='tooltiptext'>Text</span>
                                </Button>
                                <Button style={{color: 'black', width: 90}}>
                                    <Select className="Select" classNamePrefix="select"
                                            value={fontSizeOptions.find(o => o.value === fontSize)}
                                            options={fontSizeOptions}
                                            onChange={handleChangeFontSize}
                                            placeholder="Font size"
                                    />
                                    <span className='tooltiptext'>Font size</span>
                                </Button>
                                <Button className="main" onClick={() => handleUndo()}>
                                    <FontAwesomeIcon icon={faUndo}/>
                                    <span className='tooltiptext'>Undo</span>
                                </Button>
                                {crops.length < 1 ?
                                    <Button className={tool === 'crop' && 'active'} onClick={() => setTool('crop')}>
                                        <FontAwesomeIcon icon={faCropAlt}/>
                                        <span className='tooltiptext'>Crop</span>
                                    </Button> : <Button className={tool === 'crop' && 'active'} onClick={() => crop()}>
                                        <FontAwesomeIcon icon={faCropAlt}/>
                                        <span className='tooltiptext'>Crop</span>
                                    </Button>}
                                <Button className={tool === 'cut' && 'active'} onClick={() => setTool('cut')}>
                                    <FontAwesomeIcon icon={faCut}/>
                                    <span className='tooltiptext'>Cut</span>
                                </Button>
                                <Button className={tool === 'hand' && 'active'} onClick={() => setTool('hand')}>
                                    <FontAwesomeIcon icon={faHandPaper}/>
                                    <span className='tooltiptext'>Grab</span>
                                </Button>
                                <Button onClick={() => deleteSelectedItem()}>
                                    <FontAwesomeIcon icon={faTrash}/>
                                    <span className='tooltiptext'>Delete</span>
                                </Button>
                                <Button className={tool === 'pen' && 'active'} onClick={() => setTool('pen')}>
                                    <FontAwesomeIcon icon={faPencilRuler}/>
                                    <span className='tooltiptext'>Pen</span>
                                </Button>
                                <Button className={tool === 'eraser' && 'active'} onClick={() => setTool('eraser')}>
                                    <FontAwesomeIcon icon={faEraser}/>
                                    <span className='tooltiptext'>Eraser</span>
                                </Button>
                                <Button className={tool === 'rectangle' && 'active'}
                                        onClick={() => setTool('rectangle')}>
                                    <FontAwesomeIcon icon={faSquare}/>
                                    <span className='tooltiptext'>Square</span>
                                </Button>
                                <Button className={tool === 'polyline' && 'active'} onClick={() => setTool('polyline')}>
                                    <FontAwesomeIcon icon={faWaveSquare}/>
                                    <span className='tooltiptext'>Polyline</span>
                                </Button>
                                <Button className={tool === 'line' && 'active'} onClick={() => setTool('line')}>
                                    <FontAwesomeIcon icon={faSlash}/>
                                    <span className='tooltiptext'>Line</span>
                                </Button>
                                <Button className={tool === 'circle' && 'active'} onClick={() => setTool('circle')}>
                                    <FontAwesomeIcon icon={faCircle}/>
                                    <span className='tooltiptext'>Circle</span>
                                </Button>
                                <Button className={tool === 'x' && 'active'} onClick={() => setTool('x')}>
                                    <FontAwesomeIcon icon={faTimes}/>
                                    <span className='tooltiptext'>X</span>
                                </Button>
                                <Button style={{color: 'black', width: 90}}>
                                    <Select className="Select" classNamePrefix="select"
                                            value={opacityOptions.find(o => o.value === opacity)}
                                            options={opacityOptions}
                                            onChange={handleChangeOpacity}
                                            placeholder="Opacity"
                                    />
                                    <span className='tooltiptext'>Opacity</span>
                                </Button>
                                <Button style={{color: 'black', width: 90}}>
                                    <Select className="Select" classNamePrefix="select"
                                            value={strokeWidthOptions.find(o => o.value === strokeWidth)}
                                            options={strokeWidthOptions}
                                            onChange={handleChangeStrokeWidth}
                                            placeholder="Stroke width"
                                    />
                                    <span className='tooltiptext'>Stroke Weight</span>
                                </Button>
                                <Button><input type="color" value={color} onChange={handleChangeColor}/>
                                    <span
                                        className='tooltiptext'>{textEditor.textEditVisible ? "Text Color" : "Color"}</span>
                                </Button>
                                {textEditor.textEditVisible && <Button><input type="color" value={backgroundColor}
                                                                              onChange={handleChangeBackgroundColor}/>
                                    <span className='tooltiptext'>Background Color</span>
                                </Button>}
                            </div>
                        </div>
                    </div>
                    <div className='confirm-buttons'>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button onClick={prepareExport}>Save</Button>
                    </div>
                </span>
            </Modal.Header>
            <Modal.Body>
                <div className='image-draw-wrapper' tabIndex="0" onKeyPress={handleDelete}>
                    <div id='scroll-container'>
                        <div id="large-container">
                            <div className={`canvas ${tool === 'pen' && 'pencil-cursor'}`}>
                                {imgDimensions && stageDimensions ?
                                    <Stage
                                        width={stageDimensions.width}
                                        height={stageDimensions.height}
                                        onTouchStart={toolSelect(tool)}
                                        onTouchMove={(tool === 'text' || tool === 'line' || tool === 'pen' || tool === 'eraser' || tool === 'rectangle' || tool === 'circle' || tool === 'crop' || tool === 'cut') ? handleMouseMove : tool === 'hand' ? handleTouchResize : handlePolylineMove}
                                        onTouchEnd={(tool === 'text' || tool === 'line' || tool === 'pen' || tool === 'eraser' || tool === 'rectangle' || tool === 'circle' || tool === 'crop' || tool === 'cut') ? handleMouseUp : tool === 'hand' && handleTouchResizeEnd}
                                        onMouseDown={toolSelect(tool)}
                                        onMousemove={(tool === 'text' || tool === 'line' || tool === 'pen' || tool === 'eraser' || tool === 'rectangle' || tool === 'circle' || tool === 'crop' || tool === 'cut') ? handleMouseMove : handlePolylineMove}
                                        onMouseup={(tool === 'text' || tool === 'line' || tool === 'pen' || tool === 'eraser' || tool === 'rectangle' || tool === 'circle' || tool === 'crop' || tool === 'cut') && handleMouseUp}
                                        onDblClick={tool === 'polyline' && handlePolylineFinish}
                                        onDblTap={tool === 'polyline' && handlePolylineFinish}
                                        stroke={color}
                                        strokeWidth={strokeWidth}
                                        ref={stageRef}
                                        tool={tool}
                                        onWheel={zoomStage}
                                        style={{backgroundColor: 'white'}}
                                    >
                                        <Layer>
                                            <KonvaImage
                                                x={(stageDimensions.width - imgDimensions.width) / 2}
                                                y={(stageDimensions.height - imgDimensions.height) / 2}
                                                image={image}
                                                width={imgDimensions.width}
                                                height={imgDimensions.height}
                                                rotation={rotation}
                                                ref={imgRef}
                                                name={"image"}
                                            ></KonvaImage>
                                            {cuts.length > 0 && cuts.map((cu, i) => {
                                                return (
                                                    <CutShape
                                                        key={i}
                                                        shapeProps={{
                                                            ...cu,
                                                            x: Math.min(cu.x, cu.x + cu.width),
                                                            y: Math.min(cu.y, cu.y + cu.height),
                                                            width: Math.abs(cu.width),
                                                            height: Math.abs(cu.height)
                                                        }}
                                                        isSelected={cu.id === selectedId}
                                                        onSelect={() => {
                                                            selectShape(cu.id);
                                                        }}
                                                        onChange={(newAttrs) => {
                                                            const cus = cuts.slice();
                                                            cus[i] = newAttrs;
                                                            setCuts(cus);
                                                        }}
                                                        tool={tool}
                                                        history={history}
                                                        setHistory={setHistory}
                                                        historyStep={historyStep}
                                                        setHistoryStep={setHistoryStep}
                                                    />
                                                );
                                            })}
                                            {crops.length > 0 && crops.map((cro, i) => {
                                                return (
                                                    <CropShape
                                                        key={i}
                                                        shapeProps={{
                                                            ...cro,
                                                            x: Math.min(cro.x, cro.x + cro.width),
                                                            y: Math.min(cro.y, cro.y + cro.height),
                                                            width: Math.abs(cro.width),
                                                            height: Math.abs(cro.height)
                                                        }}
                                                        isSelected={cro.id === selectedId}
                                                        onSelect={() => {
                                                            selectShape(cro.id);
                                                        }}
                                                        onChange={(newAttrs) => {
                                                            const cros = crops.slice();
                                                            cros[i] = newAttrs;
                                                            setCrops(cros);
                                                        }}
                                                        tool={tool}
                                                        history={history}
                                                        setHistory={setHistory}
                                                        historyStep={historyStep}
                                                        setHistoryStep={setHistoryStep}
                                                    />
                                                );
                                            })}
                                            {exes.length > 0 && exes.map((ex, i) => {
                                                return (
                                                    <ExShape
                                                        key={i}
                                                        shapeProps={ex}
                                                        isSelected={ex.id === selectedId}
                                                        onSelect={() => {
                                                            selectShape(ex.id);
                                                        }}
                                                        onChange={(newAttrs) => {
                                                            const exs = exes.slice();
                                                            exs[i] = newAttrs;
                                                            setExes(exs);
                                                        }}
                                                        tool={tool}
                                                        history={history}
                                                        setHistory={setHistory}
                                                        historyStep={historyStep}
                                                        setHistoryStep={setHistoryStep}
                                                    />
                                                );
                                            })}
                                            {straightLines.length > 0 && straightLines.map((lin, i) => {
                                                return (
                                                    <LineShape
                                                        key={i}
                                                        shapeProps={lin}
                                                        isSelected={lin.id === selectedId}
                                                        onSelect={() => {
                                                            selectShape(lin.id);
                                                        }}
                                                        onChange={(newAttrs) => {
                                                            const lins = straightLines.slice();
                                                            lins[i] = newAttrs;
                                                            setStraightLines(lins);
                                                        }}
                                                        tool={tool}
                                                        history={history}
                                                        setHistory={setHistory}
                                                        historyStep={historyStep}
                                                        setHistoryStep={setHistoryStep}
                                                    />
                                                );
                                            })}
                                            {tempPolylines.length > 0 && tempPolylines.map((tpoly, i) => {
                                                return (
                                                    <LineShape
                                                        key={i}
                                                        shapeProps={tpoly}
                                                        isSelected={tpoly.id === selectedId}
                                                        onSelect={() => {
                                                            selectShape(tpoly.id);
                                                        }}
                                                        onChange={(newAttrs) => {
                                                            const tpolys = tempPolylines.slice();
                                                            tpolys[i] = newAttrs;
                                                            setTempPolylines(tpolys);
                                                        }}
                                                        tool={tool}
                                                        history={history}
                                                        setHistory={setHistory}
                                                        historyStep={historyStep}
                                                        setHistoryStep={setHistoryStep}
                                                    />
                                                );
                                            })}
                                            {polylines.length > 0 && polylines.map((poly, i) => {
                                                return (
                                                    <PolylineShape
                                                        key={i}
                                                        shapeProps={poly}
                                                        isSelected={poly.id === selectedId}
                                                        onSelect={() => {
                                                            selectShape(poly.id);
                                                        }}
                                                        onChange={(newAttrs) => {
                                                            const polys = polylines.slice();
                                                            polys[i] = newAttrs;
                                                            setPolylines(polys);
                                                        }}
                                                        tool={tool}
                                                        history={history}
                                                        setHistory={setHistory}
                                                        historyStep={historyStep}
                                                        setHistoryStep={setHistoryStep}
                                                    />
                                                );
                                            })}
                                            {texts.length > 0 && texts.map((tex, i) => {
                                                return (<TextArea
                                                        key={i}
                                                        shapeProps={{
                                                            ...tex,
                                                            x: Math.min(tex.x, tex.x + tex.width),
                                                            y: Math.min(tex.y, tex.y + tex.height),
                                                            width: Math.abs(tex.width),
                                                            height: Math.abs(tex.height)
                                                        }}
                                                        isSelected={tex.id === selectedId}
                                                        onSelect={() => {
                                                            selectShape(tex.id);
                                                        }}
                                                        onChange={(newAttrs) => {
                                                            const texs = texts.slice();
                                                            texs[i] = newAttrs;
                                                            setTexts(texs);
                                                        }}
                                                        onDblClick={e => handleTextDblClick(e)}
                                                        onDblTap={e => handleTextDblClick(e)}
                                                        tool={tool}
                                                        history={history}
                                                        setHistory={setHistory}
                                                        historyStep={historyStep}
                                                        setHistoryStep={setHistoryStep}
                                                    />
                                                );
                                            })}
                                            {rectangles.length > 0 && rectangles.map((rect, i) => {
                                                return (
                                                    <Rectangle
                                                        key={i}
                                                        shapeProps={{
                                                            ...rect,
                                                            x: Math.min(rect.x, rect.x + rect.width),
                                                            y: Math.min(rect.y, rect.y + rect.height),
                                                            width: Math.abs(rect.width),
                                                            height: Math.abs(rect.height)
                                                        }}
                                                        isSelected={rect.id === selectedId}
                                                        onSelect={() => {
                                                            selectShape(rect.id);
                                                        }}
                                                        onChange={(newAttrs) => {
                                                            const rects = rectangles.slice();
                                                            rects[i] = newAttrs;
                                                            setRectangles(rects);
                                                        }}
                                                        tool={tool}
                                                        history={history}
                                                        setHistory={setHistory}
                                                        historyStep={historyStep}
                                                        setHistoryStep={setHistoryStep}
                                                    />
                                                );
                                            })}
                                            {circles.length > 0 && circles.map((circ, i) => {
                                                return (
                                                    <CircleShape
                                                        key={i}
                                                        shapeProps={{
                                                            ...circ,
                                                            radius: Math.abs(circ.radius)
                                                        }}
                                                        isSelected={circ.id === selectedId}
                                                        onSelect={() => {
                                                            selectShape(circ.id);
                                                        }}
                                                        onChange={(newAttrs) => {
                                                            const circs = circles.slice();
                                                            circs[i] = newAttrs;
                                                            setCircles(circs);
                                                        }}
                                                        tool={tool}
                                                        history={history}
                                                        setHistory={setHistory}
                                                        historyStep={historyStep}
                                                        setHistoryStep={setHistoryStep}
                                                    />
                                                );
                                            })}


                                        </Layer>
                                        <Layer>
                                            {lines.map((line, i) => (
                                                <Line
                                                    key={i}
                                                    points={line.points}
                                                    stroke={line.stroke}
                                                    strokeWidth={line.tool === 'eraser' ? 20 : line.strokeWidth}
                                                    tension={0.5}
                                                    lineCap="round"
                                                    globalCompositeOperation={
                                                        line.tool === 'eraser' ? 'destination-out' : 'source-over'
                                                    }
                                                />
                                            ))}
                                        </Layer>
                                    </Stage> :
                                    <div>Loadiing...</div>
                                }
                            </div>
                            <textarea
                                value={textEditor.textValue}
                                style={{
                                    display: textEditor.textEditVisible ? "block" : "none",
                                    position: "absolute",
                                    top: textEditor.textY + "px",
                                    left: textEditor.textX + "px",
                                    width: textEditor.areaWidth,
                                    height: textEditor.areaHeight,
                                    resize: 'none',
                                    fontSize: fontSize,
                                    color: color,
                                    backgroundColor: backgroundColor
                                }}
                                onChange={e => handleTextEdit(e)}
                                onDoubleClick={e => handleTextareaSave(e)}
                                ref={textareaRef}
                            />
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );

}

export default ImageDrawTool;
