import React from "react"
import MasterDetail from "../../common/MasterDetail";
import {
    Button,
    Col,
    ControlLabel,
    FormControl,
    FormGroup,
    Glyphicon,
    Grid,
    Modal,
    OverlayTrigger,
    Row,
    Tab,
    Tabs,
    Tooltip
} from "react-bootstrap";
import {merge, validateName} from "../../common/commonHandlers";
import {bindActionCreators} from 'redux'
import createApiService from "../../common/clientActionsBuilder";
import {connect} from "react-redux";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ResourceComponent from "../../components/ResourceComponent";
import moment from "moment-timezone";
import ColorCheckbox from "../Scheduler/ColorCheckbox";
import * as actions from "./EquipmentsApi";
import NewQuestionModal from "./EquipmentQuestionsFormatters/NewQuestionModal";
import EquipmentQuestionsPreview from "./EquipmentQuestionsFormatters/EquipmentQuestionsPreview";
import EquipmentChecklistHistory from "./EquipmentChecklistHistory";

const equipments = createApiService('equipments', 'equipment', 'Equipment');
const api = {
    ...equipments,
    ...actions
};

class Equipments extends ResourceComponent {
    defaultValues = {name: '', description: '', disabled: false};
    state = {
        showEditModal: false,
        is_assigned: false,
        key: 1,
        newQuestionModal: {show: false, question: null},
        employees: [],
    };

    updateResources = resources => {
        const newResource = resources.find(r => r.id === this.state.resource?.id)
        this.setState({resources, resource: newResource})
    };

    componentDidMount = () => {
        this.props.actions.load({}, this.updateResources);
        this.props.actions.getEmployees(res => {
            this.setState({employees: res})
        })
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {resource, resources, key, filters, page, per_page, sort_by, sort, equipmentFilterModal} = this.state;
        if ((prevState.resources !== resources) && resource) {
            const selected = resources.find(r => r.id === resource.id)
            this.setState(() => ({resource: {...resource, modified_at_date: selected.modified_at_date}}))
        }
    }

    handleSelect = e => {
        this.setState({resource: merge(e.resource, this.defaultValues)});
    };

    reloadResources = resource => {
        this.setState({resource});
        this.componentDidMount();
    };

    saveQuestion = (id, question, callback) => {
        const {resource} = this.state

        const maxOrder = resource?.equipment_questions && resource?.equipment_questions.length > 0 ? Math.max(...resource?.equipment_questions?.map(q => q.order)) : 0
        const new_question = {
            id: id,
            content: JSON.stringify(question),
            required: question.options.required
        }

        if (!id) {
            new_question.order = maxOrder + 1
            new_question.dict_equipment_id = resource.id
        }

        this.props.actions.saveQuestion(new_question, (res) => {
            resource.equipment_questions = res
            callback && callback()
            this.setState({resource}, () => {
                if (!id) {
                    let x = document.getElementById('equipment-questions')
                    x.scrollTop = x.scrollHeight
                }
            })
        })
    }

    render() {
        const {resource, resources, key, employees, newQuestionModal} = this.state;
        const nameValidation = resources && resource && resources.some(r => r.name === resource.name && r.disabled === false && resource.disabled === false && r.id !== resource.id)
        const selectedEquipment = resources && resource && resources.find(r => r.id === resource.id)
        const checkEquipmentInActive = resources && resource && resources.some(r => r.name === selectedEquipment?.name && r.disabled === false)
        return (
            resources ?
                <Grid fluid>
                    <Modal show={this.state.showEditModal}
                           onHide={() => this.setState({showEditModal: false})}
                           className={"heightAuto fontIOS"}>
                        <Modal.Header closeButton><Modal.Title>Equipment already exist</Modal.Title></Modal.Header>
                        <Modal.Body>
                            <div className='columnDirection rate-modal-body-padding'>
                                <p>You are editing existing Equipment with the
                                    name <strong>{selectedEquipment?.name}</strong>.</p>
                                <p>New Equipment will be created with the name <strong>{resource?.name}</strong> and the
                                    old - <strong>{selectedEquipment?.name}</strong> Equipment will be transferred to
                                    Archived Tab</p>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button type="button" onClick={() => this.setState({showEditModal: false})}
                                    className="btn btn-default"
                                    data-dismiss="modal">No
                            </Button>
                            <Button className="btn btn-success" onClick={() => {
                                this.props.actions.save(resource, this.reloadResources)
                                this.setState({showEditModal: false})
                            }}>
                                Yes
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <MasterDetail
                        resourceName="Equipment"
                        resourceNamePlural="Equipments"
                        resources={resources}
                        resource={resource}
                        blockSave={resource && resources.some(r => r.name === resource.name && r.disabled === false && resource.disabled === false && r.id !== resource.id)}
                        select={this.handleSelect}
                        save={() => {
                            let validation = validateName(resource.name)
                            if (validation.is_valid) {
                                if (resource.id) {
                                    this.props.actions.checkIfAssigned(resource.id, (is_assigned) => {
                                        if (is_assigned && !selectedEquipment?.disabled && selectedEquipment.name !== resource.name) {
                                            this.setState({showEditModal: true})
                                        } else {
                                            this.props.actions.save(resource, this.reloadResources)
                                        }
                                    })
                                } else {
                                    this.props.actions.save(resource, this.reloadResources)
                                }
                            } else {
                                this.setState({errorMessage: validation.errorMessage})
                            }
                        }}
                        delete={() => this.props.actions.remove(resource, x => {
                            this.setState({resource: null}, () => this.props.actions.load({}, this.updateResources));
                        })}>
                        <Tabs
                            className="top25"
                            defaultActiveKey={1}
                            animation={false}
                            activeKey={key}
                            onSelect={k => {
                                this.setState({key: k})
                            }}>
                            <Tab eventKey={1} title="Equipment">
                                <div>
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup
                                                validationState={nameValidation ? "error" : ""}>
                                                {selectedEquipment?.assigned_in_ps &&
                                                    <p className="text-danger fontSize10">This Equipment is already
                                                        assigned to Proposal Service.</p>}
                                                <ControlLabel>
                                                    Name
                                                </ControlLabel>
                                                <FormControl
                                                    name="name"
                                                    onChange={this.updateResourceAttr}
                                                    value={resource?.name}
                                                    disabled={selectedEquipment?.disabled && selectedEquipment?.assigned_in_ps}
                                                />
                                                {nameValidation && <span
                                                    className="text-danger">Name already exist.</span>}
                                                {this.state.errorMessage &&
                                                    <p className="error-message-master-detail ">{this.state.errorMessage}</p>}
                                            </FormGroup>

                                            <FormGroup>
                                                <ControlLabel>
                                                    Description
                                                </ControlLabel>
                                                <FormControl
                                                    componentClass="textarea"
                                                    rows={5}
                                                    style={{resize: 'vertical'}}
                                                    name="description"
                                                    type="text"
                                                    onChange={this.updateResourceAttr}
                                                    value={resource?.description}
                                                    disabled={selectedEquipment?.disabled && selectedEquipment?.assigned_in_ps}
                                                />
                                            </FormGroup>
                                            {selectedEquipment?.disabled && checkEquipmentInActive ?
                                                <OverlayTrigger placement="bottom" overlay={
                                                    <Tooltip id="tooltip">
                                                        Other equipment with the same name is included in Active Tab!
                                                    </Tooltip>
                                                }>
                                                    <FormGroup style={{width: "fit-content"}}>
                                                        <ColorCheckbox value={resource?.disabled}
                                                                       label="Disabled"
                                                                       onChange={this.selectCheckboxAttr('disabled')}
                                                                       disabled={true}
                                                        />
                                                    </FormGroup>
                                                </OverlayTrigger>
                                                :
                                                <FormGroup>
                                                    <ColorCheckbox value={resource?.disabled}
                                                                   label="Disabled"
                                                                   onChange={this.selectCheckboxAttr('disabled')}
                                                    />
                                                </FormGroup>
                                            }
                                            {resource?.id && <FormGroup>
                                                Last
                                                updated {resource.modified_at_date ? moment(resource.modified_at_date).format('llll') :
                                                <i>Unknown</i>} by {resource.modified_by_name}
                                            </FormGroup>}
                                        </Col>
                                        {resource?.id && <Col md={6} style={{maxHeight: "300px", overflow: "auto"}}
                                                              id="equipment-questions">
                                            <Row className="vertical-align bottom10">
                                                <Col xs={2}/>
                                                <Col xs={8} className="text-center">
                                                    <ControlLabel className="no-margin">
                                                        Equipment Checklist
                                                    </ControlLabel>
                                                </Col>
                                                <Col xs={2} className="text-center">
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={<Tooltip id="equipment_plus_tooltip">Add new
                                                            Question</Tooltip>}
                                                    >
                                                        <Button
                                                            bsSize="small"
                                                            bsStyle="success"
                                                            onClick={() => {
                                                                this.setState({
                                                                    newQuestionModal: {
                                                                        show: true,
                                                                        question: null
                                                                    }
                                                                })
                                                            }}
                                                        >
                                                            <Glyphicon glyph="plus"/>
                                                        </Button>
                                                    </OverlayTrigger>
                                                </Col>
                                            </Row>
                                            <EquipmentQuestionsPreview
                                                equipmentQuestions={resource?.equipment_questions}
                                                onDelete={(q) => {
                                                    this.props.actions.deleteQuestion(q.id, () => {
                                                        this.componentDidMount()
                                                    })
                                                }}
                                                onEdit={(q) => {
                                                    this.setState({newQuestionModal: {show: true, question: q}})
                                                }}
                                            />
                                        </Col>}
                                    </Row>
                                </div>
                            </Tab>
                            {resource?.id && <Tab eventKey={2} title="Equipment Checklist History">
                                {key === 2 && <EquipmentChecklistHistory
                                    eventKey={key}
                                    id={resource?.id}
                                    employees={employees}
                                    getEquipmentAnswers={this.props.actions.getEquipmentAnswers}
                                    findWorkOrders={this.props.actions.findWorkOrders}
                                    findProposals={this.props.actions.findProposals}
                                />}
                            </Tab>}
                        </Tabs>
                    </MasterDetail>
                    {newQuestionModal?.show && <NewQuestionModal
                        editedQuestion={newQuestionModal.question}
                        onHide={() => this.setState({newQuestionModal: {show: false, content: null}})}
                        onSave={this.saveQuestion}
                    />}
                </Grid> : null);
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(api, dispatch),
});

export default connect(undefined, mapDispatchToProps)(Equipments)
