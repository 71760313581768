import React, {useEffect, useState} from 'react'
import {Button, Col, Glyphicon, Grid, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from "./SubscriptionApi";
import {PaymentElement} from './StripeCard'
import './StripeCard.css'
import LeadsFilterModal from "../../../LeadsPage/LeadsFilterModal";
import './Subscription.css'
import Dollars from "../../../../components/Dollars";
import * as moment from "moment";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import {dollarsFormatter, downloadLinkFormatter} from "../../../../components/BootStrapTableCellFormatters";
import {defaultDateFormat} from "../../../../common/commonHandlers";
import {browserHistory} from "react-router";

const smMobile = window.screen.width <= 450;

const Subscription = ({registerSubData, client_id, clientName, client, token, ...props}) => {
    const [stripeCredentials, setStripeCredentials] = useState(null)
    const [isModalOpened, setIsModalOpened] = useState(false)
    const [product, setProduct] = useState([])
    const [prices, setPrices] = useState([])
    const [selectedPrice, setSelectedPrice] = useState(null)
    const [isSubscriptionActivated, setIsSubscriptionActivated] = useState(false)
    const [activeUsers, setActiveUsers] = useState(0)
    const [subscriptionLoaded, setSubscriptionLoaded] = useState(false)
    const [availableSubscription, setAvailableSubscription] = useState(0)
    const [cancelAtThePeriodEnd, setCancelAtThePeriodEnd] = useState(false)
    const [invoices, setInvoices] = useState([])
    const [subscription, setSubscription] = useState({id: 0, user_count: 0})
    const [permanentSubscription, setPermanentSubscription] = useState(false)
    const [page, setPage] = useState(1)
    const [per_page, setPer_page] = useState(5)
    const [firstUserPrice, setFirstUserPrice] = useState(0)
    const [userPrice, setUserPrice] = useState(0)
    const [autoRenew, setAutoRenew] = useState(false)
    const [upcomingInvoice, setUpcomingInvoice] = useState()

    const {
        createSubscription,
        cancelSubscription,
        getSubscription,
        loadStripeCredentials,
        getPrices,
        getClientPrice,
        sendTrialRenewRequest,
        updateSubscription,
        getInvoices,
        getUpcomingInvoice
    } = props.actions

    let subscriptionData = {}
    if (registerSubData) {
        subscriptionData = registerSubData
    }
    const loadCredentials = () => {
        loadStripeCredentials(token, result => {
            setSubscription({
                subscription_id: result.stripe_subscription.subscription_id,
                user_count: result.stripe_subscription.user_count,
                auto_renew: result.stripe_subscription.auto_renew,
                expire_date: result.stripe_subscription.expire_date,
                last4: result.stripe_subscription?.last4
            })
            setStripeCredentials(result)
            setAutoRenew(result.stripe_subscription.auto_renew)
            setCancelAtThePeriodEnd(result.stripe_subscription.auto_renew)
            setAvailableSubscription(result.stripe_subscription.user_count)
            setPermanentSubscription(result.permanent_subscription)

            if (result.permanent_subscription && (window.location.href.includes('subscription_renew') || window.location.href.includes('subscription_reminder'))) {
                browserHistory.push('/')
            }

            !window.location.href.includes('subscription_renew') && !result.permanent_subscription && getClientPrice(result.stripe_subscription.stripe_price, price => {
                setFirstUserPrice(price.first)
                setUserPrice(price.next)
                setPrices(price)
            })


        });
        getUpcomingInvoice(invoice => {
            setUpcomingInvoice(invoice)
        })
    }


    const renderSizePerPageDropDown = (props) => {
        return (
            <div className="btn-group dropup">
                <button className="btn btn-info dropdown-toggle" type="button" id="dropdownMenu2"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span>{props.currSizePerPage}</span>
                    <span className="ml-6 caret"></span>
                </button>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                    {[5, 10, 25, 50].map(item =>
                        <li>
                            <a
                                className='table-pagination dropdown-item'
                                onClick={() => props.changeSizePerPage(item)}
                            >{item}</a>
                        </li>
                    )}
                </ul>
            </div>
        );
    }

    const loadActiveUsersCount = () => {
        props.actions.getActiveUsersCount(res => {
            setActiveUsers(res.active_users_count)
        });
    }

    const subscriptionId = stripeCredentials?.stripe_subscription?.subscription_id
    const sub = subscription?.stripe_subscription
    const isSubscriptionActive = sub?.status === 'active' || sub?.status === 'trialing'
    const modalTitle = isSubscriptionActive ? 'Subscription details' : 'Enter your card details'
    const subscriptionAction = {cancelSubscription, createSubscription, sendTrialRenewRequest}

    const renderProductTooltip = (title, description) => (
        <Tooltip id="tooltip">
            <div className="text-left">
                <strong>{title}</strong><span> - {description}</span>
            </div>
        </Tooltip>
    );

    if (subscriptionId && product) {
        stripeCredentials.stripe_subscription.product_name = product.name
    }

    if (subscriptionData) {
        subscriptionData.customer_id = stripeCredentials?.stripe_client_id
    }

    useEffect(() => {
        if (client_id) {
            loadCredentials();
            loadActiveUsersCount();
            window.location.href.includes('subscription_renew') && getPrices(prices => {
                setFirstUserPrice(prices.first)
                setUserPrice(prices.next)
                setPrices(prices)
            })

            getInvoices(invoices => {
                setInvoices(invoices)
            })

        }
    }, [])


    return (permanentSubscription ? <Grid fluid className='top25'>
            <Col md={3}/>
            <Col md={6}>
                <Row>
                    <h4>App Subscription</h4>
                </Row>
                <Row className='d-flex mt-15'>
                    <Glyphicon glyph="info-sign text-info" className="notification"/>
                    <p className='text-info text-small small-margin'>You have permanent subscription.</p>
                </Row>
            </Col>
        </Grid> : <>
            <Grid fluid className="top25">
                <Col md={3}/>
                <Col md={6}>
                    <Row>
                        <h4>App Subscription</h4>
                    </Row>
                    <Row className='d-flex mt-15'>
                        <Glyphicon glyph="info-sign text-info" className="notification"/>
                        <p className='text-info text-small small-margin'>Subscription allows the application to be
                            used
                            by a
                            certain number of users of a given client. You can unsubscribe app by clicking "Cancel
                            subscription" in subscription modal</p>
                    </Row>
                    <Row className='textCenter mt33'>
                        {stripeCredentials && prices && <OverlayTrigger placement='top'
                                                                        overlay={renderProductTooltip(prices.product_name, prices.product_description)}>
                            <div className='subscribe-product' onClick={() => {
                                if (prices.id === stripeCredentials?.stripe_subscription?.stripe_price || !subscriptionId) {
                                    setSelectedPrice(prices.next)
                                    setIsModalOpened(true)
                                    getSubscription(subscriptionId, subscription => {
                                        setSubscription(subscription)
                                        setSubscriptionLoaded(true)
                                    })
                                }
                            }}>
                                <span className={`font16 bottom15 alert-font-color`}>{prices.product_name}</span>
                                <span className='bottom10'><Dollars
                                    amount={firstUserPrice}/> first and <Dollars
                                    amount={prices.next}/>{` each next user / ${prices.period}`}</span>
                                {prices.id === stripeCredentials?.stripe_subscription?.stripe_price && stripeCredentials?.stripe_subscription?.status === 'active' &&
                                    <span className='text-green font11 subscription-status'>Active</span>}
                            </div>
                        </OverlayTrigger>
                        }
                    </Row>
                </Col>
                {subscriptionLoaded && stripeCredentials &&
                    <LeadsFilterModal
                        title={modalTitle}
                        show={isModalOpened}
                        onHide={() => setIsModalOpened(!isModalOpened)}
                        closeButton={() => setIsModalOpened(!isModalOpened)}
                        modalBodyStyle={{margin: smMobile ? '0 5%' : '0 20%'}}
                        hiddenFooter={true}
                    >
                        <PaymentElement
                            stripeCredentials={stripeCredentials}
                            hideModal={() => setIsModalOpened(false)}
                            subscriptionData={subscriptionData}
                            loadCredentials={loadCredentials}
                            subscriptionAction={subscriptionAction}
                            subscription={subscription}
                            activeUsers={activeUsers}
                            clientName={subscriptionData?.companyName ? subscriptionData?.companyName : clientName}
                            isModalOpened={isModalOpened}
                            setIsSubscriptionActivated={setIsSubscriptionActivated}
                            selectedPrice={selectedPrice}
                            firstUserPrice={firstUserPrice}
                        />
                    </LeadsFilterModal>
                }
            </Grid>
            {!window.location.href.includes('subscription_renew') && !window.location.href.includes('subscription_reminder') &&
                <Grid fluid className="top25">
                    <Col md={12}>
                        <Row className='license-row'>
                            <Col md={2}>
                                Upcoming invoice:
                            </Col>
                            <Col md={6}>
                                {upcomingInvoice?.total ? <>Your next payment will
                                    be <b>{upcomingInvoice?.period_end}</b> the amount of invoice
                                    is <b><Dollars
                                        amount={upcomingInvoice?.total}/></b> </> : <>You don't have upcoming
                                    payment.</>}
                            </Col>
                        </Row>
                        <Row className='license-row'>
                            <Col md={2}>
                                Monthly fee:
                            </Col>
                            <Col md={6} className='monthly-fee'><strong><Dollars
                                amount={(availableSubscription - 1) * userPrice + firstUserPrice}/> /
                                month</strong>
                            </Col>
                        </Row>
                        <Row className='license-row'>
                            <Col md={2}>
                                {autoRenew ? 'Next payment date:' : 'Expire date:'}
                            </Col>
                            <Col md={6}>
                                {moment.unix(subscription.expire_date).format(defaultDateFormat)}
                            </Col>
                        </Row>
                        <Row className='license-row'>
                            <Col md={2}>
                                Credit card:
                            </Col>
                            <Col md={6}>
                                **** **** **** {subscription.last4 ? subscription.last4 : '****'}
                            </Col>
                        </Row>
                        <Row className='license-row'>
                            <Col md={2}>
                                Auto renew:
                            </Col>
                            <Col md={1}>
                                <input className='subscription-checkbox' type='checkbox'
                                       checked={cancelAtThePeriodEnd}
                                       onChange={(e) => {
                                           setCancelAtThePeriodEnd(e.target.checked)
                                           setSubscription({...subscription, auto_renew: e.target.checked})
                                       }}
                                />
                            </Col>
                            <Col md={1}><Button onClick={() => updateSubscription(subscription, res => {
                                if (res) {
                                    loadCredentials()
                                }
                            })}>Update</Button></Col>
                        </Row>
                        {invoices &&
                            <Row className='license-row'>
                                <Col md={2}>
                                    Invoices
                                </Col>
                                <Col md={8}>
                                    <BootstrapTable
                                        data={invoices}
                                        striped={true}
                                        bordered={false}
                                        hover={true}
                                        pagination
                                        options={{
                                            onPageChange: (page, per_page) => {
                                                setPage(page)
                                                setPer_page(per_page)
                                            },
                                            sizePerPageDropDown: renderSizePerPageDropDown,
                                            page: page,
                                            sizePerPage: per_page,
                                        }
                                        }
                                    >
                                        <TableHeaderColumn hidden isKey dataField="id">
                                            Id
                                        </TableHeaderColumn>
                                        <TableHeaderColumn dataField="full_date" width='50%'>
                                            Name
                                        </TableHeaderColumn>
                                        <TableHeaderColumn dataField="amount" width='20%'
                                                           dataFormat={dollarsFormatter}>
                                            Amount
                                        </TableHeaderColumn>
                                        <TableHeaderColumn dataField="invoice_pdf"
                                                           dataFormat={downloadLinkFormatter}
                                                           width='30%' dataAlign='center' headerAlign='center'>
                                            Download
                                        </TableHeaderColumn>
                                    </BootstrapTable>
                                </Col>
                            </Row>}

                    </Col>
                </Grid>}
        </>

    )
}

const mapStateToProps = (state) => {
    return {
        client_id: state.auth?.client_id,
        clientName: state.client?.customerInfo?.name,
        token: state.client?.customerInfo?.client_token,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Subscription);
