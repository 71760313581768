import React, {Component} from "react"
import PropTypes from "prop-types"
import {Col, MenuItem, Nav, NavDropdown, Row,} from "react-bootstrap"
import {LinkContainer} from "react-router-bootstrap"
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table"
import moment from "moment/moment"

class SearchResults extends Component {
    state = {
        isFiltered: true,
        showFilters: true,
    }

    tableRef = React.createRef()

    static propTypes = {
        rows: PropTypes.array.isRequired,
    }

    _linkFormatter = (cell, row) => (
        <Nav>
            <NavDropdown
                eventKey={2}
                title={`${row.customer_name}`}
                id={`proposal-menu`}
                className="menu-show-fixer proposal-menu-padding-reset"
            >
                <LinkContainer to={`/customer/info/${row.customer_id}`}>
                    <MenuItem bsSize="small">Info</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/customer/proposals/${row.customer_id}`}>
                    <MenuItem bsSize="small">Proposals</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/customer/sites/${row.customer_id}`}>
                    <MenuItem bsSize="small">Sites</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/customer/work_orders/${row.customer_id}`}>
                    <MenuItem bsSize="small">Work Orders</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/customer/invoices/${row.customer_id}`}>
                    <MenuItem bsSize="small">Invoices</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/customer/work_history/${row.customer_id}`}>
                    <MenuItem bsSize="small">Work History</MenuItem>
                </LinkContainer>
            </NavDropdown>
        </Nav>
    )

    render() {
        const {showFilters} = this.state
        const {_linkFormatter} = this.props
        const options = {
            page: 1,  //which page you want to show as default
            sizePerPageList: [5, 25, 75, 100], //you can change the dropdown list for size per page
            sizePerPage: 25,  //which size per page you want to locate as default
            paginationSize: 3,  //the pagination bar size.
        }

        const toggleColumnFilters = () => {
            this.setState({
                showFilters: !this.state.showFilters
            })
        }
        return (
            <Row>
                <Col md={12}>
                    <BootstrapTable
                        ref={this.tableRef}
                        data={this.props.rows}
                        striped={true}
                        bordered={false}
                        hover={true}
                        exportCSV={true}
                        search={true}
                        pagination={true}
                        csvFileName={() => {
                            const fileName = `Customer-List-${moment().format()}.csv`
                            if (this.tableRef?.current) {
                                this.props.saveNewCsvDownload(this.tableRef.current, fileName, this.props.rows, "customer search")
                            }
                            return fileName
                        }}
                        options={options}
                    >
                        <TableHeaderColumn hidden isKey dataField="customer_id">
                            Customer
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            width="15%"
                            tdStyle={{whiteSpace: 'normal'}}
                            thStyle={{whiteSpace: 'normal'}}
                            dataSort={true}
                            columnClassName="menu-show-fixer"
                            dataFormat={this._linkFormatter}
                            dataField="customer_name"
                        >
                            Customer
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            tdStyle={{whiteSpace: 'normal'}}
                            thStyle={{whiteSpace: 'normal'}}
                            width="10%"
                            dataSort={true}
                            dataField="arborist"
                        >
                            Arborist
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            tdStyle={{whiteSpace: 'normal'}}
                            thStyle={{whiteSpace: 'normal'}}
                            width="75%"
                            dataSort={true}
                            dataField="result"
                        >
                            Result
                        </TableHeaderColumn>
                    </BootstrapTable>
                </Col>
            </Row>
        )
    }
}

export default SearchResults
