import axios from "axios";
import {addAuthorizationHeader, addTimeZoneHeader, apiCall} from "../../common/apiActionHandler";
import {mapForSelect} from "../../common/commonHandlers";
import * as FileSaver from "file-saver";
import {addAlert} from "../App/actions";
import {dataUpload} from "../../common/upload";

export const getProposalInvoices = (proposal_id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/proposal_invoices`,
        params: {id: proposal_id}

    };
    apiCall("Getting invoices", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const getInvoice = (resource, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/proposal_invoices/${resource.id}`,
        params: resource

    };
    apiCall("Getting invoice", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const load = (id, callback) => (dispatch, getState) => {
    const config = [
        {url: `/api/term_types`}
    ];

    apiCall("Loading data", config, async config => {
        let actions = config.map(c => axios.request(c));
        const all = await axios.all(actions);
        callback({
            term_types: all[0].data

        });
    }, dispatch, getState, false);
};

export const getWorkOrders = (proposal_id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/work_orders/new_search`,
        params: {proposal_id}
    };

    apiCall("Searching for work orders", config, async config => {
        const response = await axios.request(config);
        callback(mapForSelect(response.data));
    }, dispatch, getState, false);
};

export const saveInvoice = (invoice, purchase_order, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/proposal_invoices/create_invoice`,
        method: 'POST',
        data: {
            invoice,
            purchase_order
        }
    };

    apiCall("Creating invoice", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};

export const removeInvoice = (invoice_id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/new_invoices/${invoice_id}`,
        method: 'DELETE'
    };

    apiCall("Removing invoice", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};


export const createInvoicePropoosalService = (invoice_service, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/proposal_invoices/create_proposal_invoice_service`,
        method: 'POST',
        data: {invoice_service}
    };

    apiCall("Adding service to invoice", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};


export const removeInvoiceProposalService = (invoice_service, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/proposal_invoices/remove_invoice_proposal_service/`,
        params: invoice_service,
        method: 'DELETE'
    };

    apiCall("Removing service from invoice", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};

export const updateInvoice = (id, invoice, callback) => async (dispatch, getState) => {
    const config = {
        method: "put",
        url: `/api/proposal_invoices/update_invoice?invoice_id=${id}`,
        data: {
            invoice
        },
    };

    apiCall("Updating invoice", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState);
};

export const showJSADialog = (options) => async dispatch => {
    dispatch({type: 'SHOW_JSA_DIALOG', options});
};

export const downloadPdf = (invoice, callback, errorCallback) => async (dispatch, getState) => {
    let config = {
        url: `/api/restricted/${invoice.token}/invoice_pdf`,
        method: 'GET',
        responseType: 'blob'
    };
    config = addAuthorizationHeader(config, getState);
    config = addTimeZoneHeader(config, getState);
    try {
        const response = await axios.request(config);
        const blob = new Blob([response.data], {
            type: 'application/pdf',
        });
        const fileName = `${invoice.client_name}-Invoice #${invoice.number}.pdf`
        blob.name = fileName
        dataUpload(`/api/download_history`, blob, null, null, {
            params: JSON.stringify(invoice ? invoice : {}),
            source: "invoice pdf"
        }, false)(dispatch, getState)
        FileSaver.saveAs(blob, fileName);
        callback && callback()
    } catch (exception) {
        dispatch(
            addAlert({
                message: "Unable to download pdf" + exception.toString(),
            })
        );
        errorCallback && errorCallback()
        throw exception
    }
};

export const refund = (charge_id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/payments/refund`,
        method: 'PUT',
        data: {
            charge_id: charge_id
        }
    };
    apiCall("Refunding", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const enablePayments = (params, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/proposal_invoices/enable_payments`,
        method: 'PUT',
        params: params
    };
    apiCall("Saving payments settings", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};

export const enableACHPayments = (params, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/proposal_invoices/enable_ach_payments`,
        method: 'PUT',
        params: params
    };
    apiCall("Saving payments settings", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};

export const addPayment = (token, partialAmount, paymentDate, payment_type_id, reference_no, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/payments/add`,
        method: 'POST',
        data: {
            token: token,
            partial_amount: partialAmount,
            payment_date: paymentDate,
            payment_type_id,
            reference_no
        }
    };
    apiCall("Creating payment", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const removePayment = (payment_id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/payments/remove_payment`,
        method: 'DELETE',
        params: {
            id: payment_id
        }
    };

    apiCall("Removing payment", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};

export const updatePayment = (payment, callback) => async (dispatch, getState) => {
    const config = {
        method: "put",
        url: `/api/payments/update_amount`,
        data: {
            id: payment.id,
            amount: payment.amount
        },
    };

    apiCall("Updated payment amount", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};

export const updateProposalService = (ps_id, proposal_service) => async (dispatch, getState) => {
    const config = {
        method: "put",
        url: `/api/proposal_services/` + ps_id,
        data: {
            proposal_service: {
                id: ps_id,
                ...proposal_service
            }
        },
    };

    apiCall("Updated proposal service", config, async config => {
        const response = await axios.request(config);
    }, dispatch, getState, false);
};

export const updatePONumber = (proposal_id, po_number, callback) => async (dispatch, getState) => {
    const config = {
        method: "put",
        url: `/api/new_proposals/update_po_number`,
        data: {
            id: proposal_id,
            po_number
        },
    };

    apiCall("Updating proposal PO number", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState);
};

export const updatePONumberInPS = (ps_id, po_number, callback) => async (dispatch, getState) => {
    const config = {
        method: "put",
        url: `/api/proposal_services/update_po_number`,
        data: {
            id: ps_id,
            po_number
        },
    };

    apiCall("Updating proposal service PO number", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState);
};

export const getManualPaymentTypes = (callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/payment_types/get_manual_payment_types`,
        method: 'GET'
    };

    apiCall("Get manual payment types", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false);
};
