import {
    Alert,
    Button,
    Col,
    ControlLabel,
    FormGroup,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Radio,
    Row,
    Well
} from "react-bootstrap";
import React, {useEffect, useState} from 'react'
import MDSpinner from "react-md-spinner";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLongArrowAltLeft, faLongArrowAltRight} from "@fortawesome/free-solid-svg-icons";
import ErrorInfoModal from "./ErrorInfoModal";
import MatchedCustomersModal from "./MatchedCustomersModal";

const SyncAllModal = (props) => {


    const synchronizeRecords = (importId) => {
        const {category, to, from, byInvoice} = props
        let progressTimer = setInterval(() => props.checkProgress(importId, (result) => {
            setTotal(result.total)
            setProgress(result.current)
            setStatus(result.status)
        }), 3000);
        const filters = {from, to, byInvoice}
        props.synchronizeManyRecords(category, choice, importId, filters, (res) => {
            clearInterval(progressTimer);
            props.checkProgress(importId, (result) => {
                setProgress(result.current)
                setStatus(result.status)
                setLoading(false)
            })
            props.reload()
            setProgressTable(res?.progress)
            setErrorsTable(res?.errors)
            if (res?.matched_customer && res?.matched_customer.length > 0) {
                setMatchedCustomer({show: true, customers: res?.matched_customer})
            }
        }, (res) => {
            clearInterval(progressTimer);
            setStatus('failed')
            setLoading(false)
            if (res?.data?.message) {
                setErrorModal({show: true, message: res?.data?.message})
            }
        })
    }

    const dateRangeMoreThanMonth = (from, to) => {
        return to.diff(from, 'days') > 30
    }

    const alertRender = () => {
        let alertText
        let additionalText = undefined
        if (props.category === 'customers' && dateRangeMoreThanMonth(props.from, props.to)) {
            alertText = `Please narrow date filter search to one month or less`
        } else if (status === 'completed') {
            alertText = progressTable && progressTable.length > 0 ? `Successfully imported ${progressTable.length} ${props.category}` : undefined
            additionalText = errorsTable && errorsTable.length > 0 ? `Unsuccessfully imported ${errorsTable.length} ${props.category}` : undefined
        } else if (status === 'failed') {
            alertText = `Import error occurred. \n
            Unsuccessfully imported ${total - progress || 0} ${props.category}`
        } else {
            alertText = `You are about to export/import ${props.category}.
            Please make sure that you have a stable internet connection as it will take some time.`
        }
        return (
            <>
                {alertText &&
                    <Alert bsStyle={status === 'completed' ? "success" : status === 'failed' ? "danger" : "warning"}>
                        <p>{alertText}</p>
                        {progressTable?.map(el => <p>{el}</p>)}
                    </Alert>}
                {additionalText && <Alert bsStyle={"danger"}>
                    <p>{additionalText}</p>
                    {errorsTable?.map(el => {
                        return <Row>
                            <Col xs={5}>{el.item}</Col>
                            <Col xs={7} className="text-left">{el.errors.map(e => <p>{e}</p>)}</Col>
                            <Col xs={10} xsOffset={2}>
                                {el.error_sites?.map(e => {
                                    return <Row>
                                        <Col xs={5}>{e.site_name}</Col>
                                        <Col xs={7}>{e.error_message}</Col>
                                    </Row>
                                })}
                            </Col>
                            <Col xs={12}>
                                <hr/>
                            </Col>
                        </Row>
                    })}
                </Alert>}
            </>
        )
    }


    const [choice, setChoice] = useState('right')
    const [loading, setLoading] = useState(true)
    const [progress, setProgress] = useState(null)
    const [status, setStatus] = useState(null)
    const [total, setTotal] = useState(null)
    const [progressTable, setProgressTable] = useState([])
    const [errorsTable, setErrorsTable] = useState([])
    const [errorModal, setErrorModal] = useState({show: false, message: ''})
    const [matchedCustomer, setMatchedCustomer] = useState({show: false, customers: []})

    useEffect(() => {
        setLoading(false)
    }, [])

    const syncNotAllowed = props.category === 'customers' && dateRangeMoreThanMonth(props.from, props.to)

    return <Modal
        show={props.show}
        bsSize="medium"
        backdrop="static"
        animation={true}
        onHide={() => {
            if (!loading) {
                props.onHide()
            }
        }}
        id={`qb-import-modal`}
    >
        <ModalHeader closeButton>
            Synchronize all {props.category}
        </ModalHeader>
        <ModalBody>
            <Row className="align-center marginBottom10 marginTop-16">Choose direction:</Row>
            <Row>
                <Col md={6} className="align-right">
                    <FormGroup>
                        <ControlLabel>Import from Quickbooks</ControlLabel>
                        <Radio
                            disabled={loading}
                            name="choice"
                            checked={choice === 'left'}
                            onChange={() => {
                                setStatus(null)
                                setChoice('left')
                                setProgressTable([])
                                setErrorsTable([])
                            }}
                            value={'left'}
                        />
                    </FormGroup>
                </Col>
                <Col md={6} className="align-left">
                    <FormGroup>
                        <ControlLabel>Export to Quickbooks</ControlLabel>
                        <Radio
                            disabled={loading}
                            name="choice"
                            checked={choice === 'right'}
                            onChange={() => {
                                setStatus(null)
                                setChoice('right')
                                setProgressTable([])
                                setErrorsTable([])
                            }}
                            value={'right'}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Well bsSize="large" className="align-center">
                    {loading ?
                        <div>
                            {status === 'in_progress' && total === 500 &&
                                <Alert bsStyle={'info'}>
                                    The number of {props.category} to import has been exceeded (maximum is 500 per
                                    action). Retry the operation after it is completed
                                </Alert>}
                            Please wait...
                            <br/>
                            <MDSpinner className="mt-20"/>
                            {status === 'in_progress' &&
                                <div className="mt-20">
                                    {progress}/{total}
                                </div>}
                        </div>
                        :
                        <div>
                            {alertRender()}
                            {choice === 'left' &&
                                <div>
                                    You can import {props.category} from Quickbooks that don't yet exist in
                                    Treehub.<br/>
                                    <br/>
                                    <FontAwesomeIcon
                                        className={`pointer ${syncNotAllowed && 'arrow-disabled'}`}
                                        icon={faLongArrowAltLeft}
                                        size="4x"
                                        onClick={() => {
                                            if (!syncNotAllowed) {
                                                setLoading(true)
                                                setTotal(0)
                                                props.createImport((result) => {
                                                    setStatus(result.status)
                                                    synchronizeRecords(result.id)
                                                })
                                            }
                                        }}/>
                                </div>
                            }
                            {choice === 'right' &&
                                <div>
                                    You can export {props.category} from Treehub that don't yet exist in
                                    Quickbooks.<br/>
                                    <br/>
                                    <FontAwesomeIcon
                                        className={`pointer ${syncNotAllowed && 'arrow-disabled'}`}
                                        icon={faLongArrowAltRight}
                                        size="4x"
                                        onClick={() => {
                                            if (!syncNotAllowed) {
                                                setLoading(true)
                                                setTotal(0)
                                                props.createImport((result) => {
                                                    setStatus(result.status)
                                                    synchronizeRecords(result.id)
                                                })
                                            }
                                        }}/>
                                </div>}
                        </div>
                    }
                </Well>
                {errorModal?.show &&
                    <ErrorInfoModal
                        type="Customer"
                        message={errorModal?.message}
                        onHide={() => setErrorModal({show: false, message: ''})}
                    />
                }
                {matchedCustomer.show &&
                    <MatchedCustomersModal
                        matchedCustomers={matchedCustomer.customers}
                        onHide={() => {
                            const matchedCustomerModal = {
                                show: false,
                                customers: []
                            }
                            setMatchedCustomer(matchedCustomerModal)
                        }}
                        reload={props.reload}
                        onSync={props.synchronizeSelectedCustomer}
                    />
                }
            </Row>

        </ModalBody>
        <ModalFooter>
            <Button disabled={loading} onClick={() => props.onHide()}>Close</Button>
        </ModalFooter>
    </Modal>
}

export default SyncAllModal
