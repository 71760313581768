import React from "react";
import Dollars from "../../components/Dollars";
import Select from "react-select";
import {select} from "../../common/commonHandlers";
import {Col, FormGroup, Row} from "react-bootstrap";
import {Rnd} from "react-rnd";
import windowSize from 'react-window-size';
import ColorCheckbox from "../Scheduler/ColorCheckbox";
import DragComponent from "./DragComponent";
import PromiseUi from "../../components/PromiseUi"

const isMobile = window.screen.width <= 1024;
const screenHeightL = window.screen.height <= 900;
const screenHeightXL = window.screen.height > 1100;

class BottomLeftPanel extends DragComponent {
    state = {
        y: 0,
        width: 0,
        x: 0,
        height: 0,
        heightParent: 0,
        startPosition: false,
        oldHeight: this.props.parentRef.offsetHeight,
        selectedDuration: null
    }

    newValue = (_old, _new, value) => {
        const x = value * 100 / _old;
        return _new * x / 100;
    }

    componentDidMount() {
        let new_info = this.props.info;
        new_info.x = this.state.x;
        new_info.y = this.state.y;
        new_info.width = this.state.width;
        new_info.height = this.state.height;
        this.props.handleData("left_panel", new_info);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {selectedStatus} = this.props;
        if (!this.state.startPosition && !isMobile) {
            this.setState({
                width: screenHeightL ? this.props.parentRef.offsetWidth / 3 : selectedStatus === 'Promised' ? this.props.parentRef.offsetWidth / 3.15 : this.props.parentRef.offsetWidth / 3.05,
                x: 0,
                height: this.props.parentRef.offsetHeight * 0.8,
                y: screenHeightXL ? this.props.parentRef.offsetHeight * 0.45 : screenHeightL ? this.props.parentRef.offsetHeight * 0.32 : this.props.parentRef.offsetHeight * 0.1,
                startPosition: true,
                oldHeight: this.props.parentRef.offsetHeight
            });
        }

        if (prevState.x !== this.state.x || prevState.y !== this.state.y || prevState.width !== this.state.width ||
            prevState.height !== this.state.height) {
            let new_info = this.props.info;
            new_info.x = this.state.x;
            new_info.y = this.state.y;
            new_info.width = this.state.width;
            new_info.height = this.state.height;
            this.props.handleData("left_panel", new_info);
        }
        if ((prevProps.windowWidth !== this.props.windowWidth || this.state.oldHeight !== this.props.parentRef.offsetHeight) && this.state.startPosition) {
            this.setState({
                width: this.newValue(prevProps.windowWidth, this.props.windowWidth,
                    this.state.width),
                x: this.newValue(prevProps.windowWidth, this.props.windowWidth,
                    this.state.x),
                height: this.newValue(this.state.oldHeight, this.props.parentRef.offsetHeight, this.state.height),
                y: this.newValue(this.state.oldHeight, this.props.parentRef.offsetHeight,
                    this.state.y),
                oldHeight: this.props.parentRef.offsetHeight
            });
        }
        this.rnd.updatePosition({x: this.state.x, y: this.state.y});
        this.rnd.updateSize({width: this.state.width, height: this.state.height});
    }

    rowStyleFormat = (row, rowIdx) => {
        let border = this.props.selectedRow === rowIdx ? "4px solid yellow" : "";
        return {border: border};
    }

    createData = (cell, row) => {
        return (
            <div style={{cursor: "pointer"}}>
                <div>
                    {row.label}
                </div>
                <div style={{color: "#3cc6e2"}}>
                    <Dollars amount={row.value.amount}/>
                </div>
            </div>
        );
    }

    render() {
        const {
            resource,
            selectedStatus,
            service_types,
            employees,
            dashboardState,
            statusDateFilterFormatter,
            durations,
        } = this.props;

        const acceptedFilters = [
            {label: 'Total Accepted', value: 'total'},
            {label: 'Accepted', value: 'accepted'},
            {label: 'Declined', value: 'declined'},
            {label: 'Open', value: 'open'},
            {label: 'Pure Accepted', value: 'pure_accepted'},
            {label: 'Pure Declined', value: 'pure_declined'}]

        const formatOptionLabel = ({value, label, customAbbreviation}) => (
            <Row style={{display: "flex"}}>
                <Col xs={3}>
                    {acceptedFilters.find(f => f.value === value).label}
                </Col>
                <Col xs={5} style={{color: "#3cc6e2"}}>
                    Amount: <Dollars amount={label.amount}/>
                </Col>
                <Col xs={3} style={{color: "#000"}}>
                    Count: {label.count}
                </Col>
                <Col xs={1} style={{marginLeft: "10px", color: "#ccc"}}>
                    {customAbbreviation}
                </Col>
            </Row>
        );

        const backlogFilterStatuses = [
            {value: 'ACCEPTED', label: 'Accepted'},
            {value: 'IN_WORK_ORDER', label: 'In Work Order'},
            {value: 'SCHEDULED', label: 'Scheduled'},
            {value: 'PROPOSED', label: 'Proposed'}
        ];

        const completedFilterStatuses = [
            {value: 'COMPLETED', label: 'Completed'},
            {value: 'INVOICED', label: 'Invoiced'},
            {value: 'PAYMENT', label: 'Payment'},
            {value: 'PAID', label: 'Paid'}
        ];

        let statuses;
        if (resource?.selectedStatus === 'backlog') {
            statuses = backlogFilterStatuses
        } else if (resource?.selectedStatus === 'completed') {
            statuses = completedFilterStatuses
        }

        const {customerInputSearch, zipInputSearch, cityInputSearch, proposalInputSearch} = this.props;
        const {date_name} = resource
        const gridHandler = this.state.width < 600 ? 6 : 3

        let backlogProposedFilter = false
        if (resource.ps_status && resource.ps_status.length > 0 && resource.ps_status.some(s => s === "PROPOSED" || s === "ACCEPTED" || s === "IN_WORK_ORDER" || s === "SCHEDULED")) {
            backlogProposedFilter = true
        }

        return (
            <Rnd
                ref={c => {
                    this.rnd = c;
                }}
                size={{width: this.state.width, height: this.state.height}}
                position={{x: this.state.x, y: this.state.y}}
                onDragStop={(e, d) => {
                    this.setState({x: d.x, y: d.y});
                }}
                onResizeStop={(e, direction, ref, delta, position) => {
                    this.setState({
                        width: parseInt(ref.style.width),
                        height: parseInt(ref.style.height),
                        ...position
                    });
                }}
                bounds="parent"
                dragHandleClassName="panel-heading"
            >
                <div className="panel panel-default"
                     style={{
                         overflow: "hidden",
                         width: "100%",
                         height: "100%",
                         flexFlow: "column",
                         marginBottom: "0px",
                         display: this.props.info.show ? "flex" : "none"
                     }}>

                    <div style={{cursor: "pointer"}} className="panel-heading">
                        Filter
                    </div>
                    <div style={{
                        height: "calc(100% - 39px)",
                        display: "flex",
                        flexFlow: "column",
                        flexGrow: "1",
                        borderBottom: "1px solid"
                    }} className="panel-body">
                        {
                            selectedStatus === 'Paid' && <Row>
                                <Col md={12}
                                     className={isMobile ? "mt5 bottom15" : "mt5 bottom5"}>
                                    <span className="font11">{statusDateFilterFormatter(selectedStatus)}</span>
                                    <PromiseUi
                                        promisedStart={resource.from}
                                        promisedEnd={resource.to}
                                        upsertPromise={(id, start, end) => this.props.handleRangeDateChange(start, end, 'from', 'to', true)}
                                        proposalServiceId={null}
                                        serviceNo={null}
                                        startPlaceholder="From"
                                        endPlaceholder="To"
                                        index={1}
                                    />
                                </Col>
                            </Row>
                        }
                        <Row>
                            <Col md={6} className="mt5 bottom15">
                                <Select className="Select" classNamePrefix="select"
                                        isClearable={true}
                                        options={employees}
                                        onChange={this.props.selectResourceAttr('sales_arborist', this.props.reloadReport)}
                                        placeholder="Sales Arborist"
                                />
                            </Col>
                            <Col md={6} className="mt5 bottom15">
                                <Select className="Select" classNamePrefix="select"
                                        isClearable={true}
                                        value={select(service_types, resource.service_type)}
                                        isMulti
                                        options={service_types}
                                        onChange={this.props.selectResourceAttr('service_type', this.props.reloadReport)}
                                        placeholder="Service Types"
                                />
                            </Col>
                        </Row>
                        {selectedStatus === 'Sent' &&
                            <span>
                        <Row className={'modalLeadsFilter'}>
                        <Col md={12} className="mt5 bottom15 no-padding">
                            <Select className="Select" classNamePrefix="select"
                                    name="date_name"
                                    value={select(durations, date_name)}
                                    options={durations}
                                    onChange={this.props.selectResourceAttr('date_name', this.props.reloadReport)}
                            />
                        </Col>
                    </Row>
                        <div>
                             {resource.date_name && resource.date_name === "custom" &&
                                 <Row className={'modalLeadsFilter'}>
                                     <Col className='no-left-margin no-right-margin bottom15'>
                                         <span className="font11">Sent date</span>
                                         <PromiseUi
                                             promisedStart={resource.sent_from}
                                             promisedEnd={resource.sent_to}
                                             upsertPromise={(id, start, end) => this.props.handleRangeDateChange(start, end, 'sent_from', 'sent_to', true)}
                                             proposalServiceId={null}
                                             serviceNo={null}
                                             startPlaceholder="Sent From"
                                             endPlaceholder="Sent To"
                                             timeDelay={300}
                                             index={1}
                                         />
                                     </Col>
                                 </Row>
                             }
                        </div>
                             <hr className={'mb-10-mt-0 ml-15 mr-15'}/>
                    <Row className="bottom10 no-left-margin">
                        <Col md={12}>
                        <ColorCheckbox value={resource.onlyProposed}
                                       label='Show only proposed'
                                       onChange={this.props.selectCheckboxAttr('onlyProposed', this.props.reloadReport)}
                        />
                        </Col>
                        </Row>
                        </span>
                        }
                        <div>
                            <Row className="backlog-border padding-top-bottom-8">
                                <Col md={6}>
                                    <span>{selectedStatus} Filter</span>
                                </Col>
                                <Col md={6}>
                                    <button type="button" onClick={this.props.reloadTableData}
                                            className="btn-sm btn-success float-right">Filter Table
                                    </button>
                                </Col>
                            </Row>
                            {["backlog", "completed", "invoiced", "paid"].indexOf(resource.selectedStatus) > -1 &&
                                <Row className="top10 bottom5 no-left-margin no-right-margin">
                                    <Col md={6}
                                         className={isMobile ? "mt5 bottom15" : "mt5 bottom5 no-left-padding"}>
                                        <span className="font11">Promised date</span>
                                        <PromiseUi
                                            promisedStart={resource.promisedFrom}
                                            promisedEnd={resource.promisedTo}
                                            upsertPromise={(id, start, end) => this.props.handleRangeDateChange(start, end, 'promisedFrom', 'promisedTo')}
                                            proposalServiceId={null}
                                            serviceNo={null}
                                            startPlaceholder="P From"
                                            endPlaceholder="P To"
                                            index={selectedStatus === 'Paid' ? 2 : 1}
                                        />
                                    </Col>
                                    <Col md={6}
                                         className={isMobile ? "mt5 bottom15" : "mt5 bottom5 no-right-padding"}>
                                        <div className="ml-7">
                                            <span className="font11">Scheduled date</span>
                                            <PromiseUi
                                                promisedStart={resource.scheduledFrom}
                                                promisedEnd={resource.scheduledTo}
                                                upsertPromise={(id, start, end) => this.props.handleRangeDateChange(start, end, 'scheduledFrom', 'scheduledTo')}
                                                proposalServiceId={null}
                                                serviceNo={null}
                                                startPlaceholder="S From"
                                                endPlaceholder="S To"
                                                index={selectedStatus === 'Paid' ? 3 : 2}
                                            />
                                        </div>
                                    </Col>
                                </Row>}
                            {(resource.selectedStatus === "backlog" && backlogProposedFilter || ["proposed", "completed", "invoiced", "paid"].indexOf(resource.selectedStatus) > -1) &&
                                <Row className={'modalLeadsFilter'}>
                                    <Col className='no-left-margin no-right-margin bottom15'>
                                        <span className="font11">Sent date</span>
                                        <PromiseUi
                                            promisedStart={resource.sent_from}
                                            promisedEnd={resource.sent_to}
                                            upsertPromise={(id, start, end) => this.props.handleRangeDateChange(start, end, 'sent_from', 'sent_to')}
                                            proposalServiceId={null}
                                            serviceNo={null}
                                            startPlaceholder="Sent From"
                                            endPlaceholder="Sent To"
                                            timeDelay={300}
                                            index={resource.selectedStatus === 'proposed' ? 1 : 3}
                                        />
                                    </Col>
                                </Row>}
                            <hr className={'bottom10 mt10 mr-15 ml-15'}/>
                            <Row className="bottom5 no-left-margin no-right-margin">

                                <Col md={gridHandler}
                                     className={isMobile ? "mt5 bottom15" : "mt5 bottom5"}>
                                    <Select className="Select" classNamePrefix="select"
                                            value={select(dashboardState.services, resource.service)}
                                            options={dashboardState.services}
                                            isClearable
                                            isMulti
                                            onChange={this.props.selectResourceAttr('service')}
                                            placeholder="Service"
                                    />
                                </Col>
                                {["backlog", "completed"].indexOf(resource.selectedStatus) > -1 &&
                                    <Col md={gridHandler}
                                         className={isMobile ? "mt5 bottom15" : "mt5 bottom5"}>
                                        <Select className="Select" classNamePrefix="select"
                                                value={select(statuses, resource.selectedStatus === 'backlog' ? resource.ps_status : resource.ps_completed_statuses)}
                                                options={statuses}
                                                isMulti
                                                isClearable
                                                onChange={this.props.selectResourceAttr(resource.selectedStatus === 'backlog' ? 'ps_status' : 'ps_completed_statuses')}
                                                placeholder="Status"
                                        />
                                    </Col>}
                                <Col md={gridHandler}
                                     className={isMobile ? "mt5 bottom15" : "mt5 bottom5"}>
                                    <Select className="Select" classNamePrefix="select"
                                            value={select(customerInputSearch.customerOptions, resource.customer_id)}
                                            options={customerInputSearch.customerOptions}
                                            isClearable
                                            isMulti
                                            onInputChange={customerInputSearch.onInputChange}
                                            onChange={this.props.selectResourceAttr('customer_id')}
                                            placeholder="Customer"
                                    />
                                </Col>
                                <Col md={gridHandler}
                                     className={isMobile ? "mt5 bottom15" : "mt5 bottom5"}>
                                    <Select className="Select" classNamePrefix="select"
                                            value={select(dashboardState.customer_types, resource.customer_type)}
                                            options={dashboardState.customer_types}
                                            isClearable
                                            isMulti
                                            onChange={this.props.selectResourceAttr('customer_type')}
                                            placeholder="Customer type"
                                    />
                                </Col>
                                <Col md={gridHandler}
                                     className={isMobile ? "mt5 bottom15" : "mt5 bottom5"}>
                                    <FormGroup className="bottom0">
                                        <Select className="Select" classNamePrefix="select"
                                                value={select(cityInputSearch.cityOptions, resource.city)}
                                                options={cityInputSearch.cityOptions}
                                                isClearable
                                                isMulti
                                                onInputChange={cityInputSearch.onInputChange}
                                                onChange={this.props.selectResourceAttr('city')}
                                                placeholder="City"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={gridHandler}
                                     className={isMobile ? "mt5 bottom15" : "mt5 bottom5"}>
                                    <FormGroup className="bottom0">
                                        <Select className="Select" classNamePrefix="select"
                                                value={select(zipInputSearch.zipOptions, resource.zip)}
                                                options={zipInputSearch.zipOptions}
                                                isClearable
                                                isMulti
                                                onInputChange={zipInputSearch.onInputChange}
                                                onChange={this.props.selectResourceAttr('zip')}
                                                placeholder="Zip"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={gridHandler}
                                     className="mt5 bottom5">
                                    <FormGroup className="bottom0">
                                        <Select className="Select" classNamePrefix="select"
                                                value={select(dashboardState.reasons, resource.reason)}
                                                options={dashboardState.reasons}
                                                isClearable
                                                isMulti
                                                onChange={this.props.selectResourceAttr('reason')}
                                                placeholder="Reason"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={gridHandler}
                                     className="mt5 bottom5">
                                    <FormGroup className="bottom0">
                                        <Select className="Select" classNamePrefix="select"
                                                value={select(dashboardState.equipments, resource.equipment)}
                                                options={dashboardState.equipments}
                                                isClearable
                                                isMulti
                                                onChange={this.props.selectResourceAttr('equipment')}
                                                placeholder="Equipment"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={gridHandler}
                                     className="mt5 bottom5">
                                    <FormGroup className="bottom0">
                                        <Select className="Select" classNamePrefix="select"
                                                value={select(dashboardState.priorities, resource.priority)}
                                                options={dashboardState.priorities}
                                                isMulti
                                                isClearable
                                                onChange={this.props.selectResourceAttr('priority')}
                                                placeholder="Priority"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={gridHandler}
                                     className="mt5 bottom5">
                                    <FormGroup className="bottom0">
                                        <Select className="Select" classNamePrefix="select"
                                                value={select(dashboardState.plants, resource.plant)}
                                                options={dashboardState.plants}
                                                isMulti
                                                isClearable
                                                onChange={this.props.selectResourceAttr('plant')}
                                                placeholder="Plants"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={gridHandler}
                                     className="mt5 bottom5">
                                    <FormGroup className="bottom0">
                                        <Select className="Select" classNamePrefix="select"
                                                value={select(dashboardState.employees, resource.arborist_id)}
                                                options={dashboardState.employees}
                                                isClearable
                                                onChange={this.props.selectResourceAttr('arborist_id')}
                                                placeholder="Arborist"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={gridHandler}
                                     className="mt5 bottom5">
                                    <FormGroup className="bottom0">
                                        <Select className="Select" classNamePrefix="select"
                                                value={select(proposalInputSearch.proposalOptions, resource.proposal_id)}
                                                options={proposalInputSearch.proposalOptions}
                                                isClearable
                                                isMulti
                                                onInputChange={proposalInputSearch.onInputChange}
                                                onChange={this.props.selectResourceAttr('proposal_id')}
                                                placeholder="Proposal #"
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <hr className={'bottom10 mt10 mr-15 ml-15'}/>
                            <Row className="bottom5 no-left-margin no-right-margin">
                                <Col md={gridHandler}
                                     className="mt5 bottom5">
                                    <FormGroup className="bottom0">
                                        <Select
                                            className="Select"
                                            classNamePrefix="select"
                                            value={select(dashboardState.services, resource.ex_service)}
                                            options={dashboardState.services}
                                            isClearable
                                            isMulti
                                            onChange={this.props.selectResourceAttr('ex_service')}
                                            placeholder="Excluded Services"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={gridHandler}
                                     className="mt5 bottom5">
                                    <FormGroup className="bottom0">
                                        <Select
                                            className="Select"
                                            classNamePrefix="select"
                                            value={select(dashboardState.priorities, resource.ex_priority)}
                                            options={dashboardState.priorities}
                                            isClearable
                                            isMulti
                                            onChange={this.props.selectResourceAttr('ex_priority')}
                                            placeholder="Excluded Priority"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={gridHandler}
                                     className="mt5 bottom5">
                                    <FormGroup className="bottom0">
                                        <Select className="Select" classNamePrefix="select"
                                                value={select(dashboardState.equipments, resource.ex_equipment)}
                                                options={dashboardState.equipments}
                                                isClearable
                                                isMulti
                                                onChange={this.props.selectResourceAttr('ex_equipment')}
                                                placeholder="Excluded Equipment"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={gridHandler}
                                     className="mt5 bottom5">
                                    <FormGroup className="bottom0">
                                        <Select className="Select" classNamePrefix="select"
                                                value={select(dashboardState.customer_types, resource.ex_customer_type)}
                                                options={dashboardState.customer_types}
                                                isClearable
                                                isMulti
                                                onChange={this.props.selectResourceAttr('ex_customer_type')}
                                                placeholder="Excluded Customer Type"
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </Rnd>
        );
    }
}

export default windowSize(BottomLeftPanel);
