import {
    CREATE_NEW_SITE,
    DELETE_SITE,
    EDITING_SITE_ID,
    FETCH_SEARCHED_CUSTOMERS_MODAL,
    FETCH_SITE,
    FETCH_SITE_CONTACT,
    FETCH_STATE_AND_CITY,
    UPDATE_SITE_ATTR
} from "./constants"
import {FETCH_SEARCHED_CUSTOMERS, UPDATE_CUSTOMER_SEARCH} from "../CustomerPage/CustomerSites/constants";

import {deleteKeyFromObject} from '../../utilities'

const initialState = {
    editingSiteId: null,
    site: null,
    siteContact: null,
}

const newSite = customer_id => ({
    site_0: {
        id: 0,
        customer_id,
        customer_contact_id: 0,
        name: "",
        full_address: "",
        address: "",
        city: "",
        state: "",
        zip: "",
        country: "United States",
        latitude: null,
        longitude: null,
        __dirty: false,
    },
})

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case CREATE_NEW_SITE:
            return {...state, site: newSite(action.customerId), editingSiteId: 0}
        case EDITING_SITE_ID:
            return {...state, editingSiteId: action.editingSiteId}
        case FETCH_SITE_CONTACT:
            return {...state, siteContact: action.siteContact}
        case UPDATE_SITE_ATTR:
        case FETCH_SITE:
            return {...state, site: action.site}
        case FETCH_STATE_AND_CITY:
            if (state.editingSiteId !== null) {
                state.site[`site_${state.editingSiteId}`]['state'] = action.location.state
                state.site[`site_${state.editingSiteId}`]['city'] = action.location.city
            }
            return state
        case DELETE_SITE:
            return {
                ...state,
                sites: deleteKeyFromObject(
                    state.sites,
                    'sites',
                    action.id
                ),
            }
        case UPDATE_CUSTOMER_SEARCH:
            return {
                ...state,
                customerSearch: action.customerSearch,
            }
        case FETCH_SEARCHED_CUSTOMERS:
            return {...state, foundCustomers: action.foundCustomers}
        case FETCH_SEARCHED_CUSTOMERS_MODAL:
            return {...state, foundCustomersModal: action.foundCustomers}
        default:
            return state
    }
}
