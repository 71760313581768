import React, {useRef, useState} from "react";
import {Button, Checkbox, Col, Modal, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import {Link} from "react-router";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";
import MDSpinner from "react-md-spinner";

const MovedSiteModal = ({onSave, onClose, data}) => {
    const [showTable, setShowTable] = useState({old: false, new: false, all: false})
    const [moveSiteCheckbox, setMoveSiteCheckbox] = useState({separateProposalServices: false, moveEverything: false})
    const [loading, setLoading] = useState(false)
    const tableRef = useRef()

    const isExpandableRow = (row) => {
        return row?.proposal_services?.length > 0;
    }

    const expandComponent = (row) => {
        return <div className='expand-component'>
            <Row>
                <Col md={1}/>
                <Col md={7}><b>Service name</b></Col>
                <Col md={3} className="text-center"><b>Status</b></Col>
                <Col md={1}/>
            </Row>
            {row?.proposal_services?.map(ps => {
                return <Row>
                    <Col md={1}/>
                    <Col md={7}>{ps.service_name}</Col>
                    <Col md={3} className="text-center">{ps.ps_status}</Col>
                    <Col md={1}/>
                </Row>
            })}
        </div>
    }

    const expandColumnComponent = ({isExpandableRow, isExpanded}) => {
        let content = '';

        if (isExpandableRow) {
            content = (isExpanded ? <FontAwesomeIcon icon={faChevronUp}/> : <FontAwesomeIcon icon={faChevronDown}/>);
        } else {
            content = ' ';
        }
        return (
            <div> {content} </div>
        );
    }

    const proposalTable = (customer, key) => {
        return <div className="proposal-table">
            <p
                className={`table-button ${showTable[key] ? 'red-background' : 'green-background'}`}
                onClick={() => setShowTable({...showTable, [key]: !showTable[key]})}
            >
                {showTable[key] ? 'Hide' : 'Show'} Proposals
            </p>
            <BootstrapTable
                ref={tableRef}
                data={moveSiteCheckbox.separateProposalServices ? customer.proposals_with_separate : moveSiteCheckbox.moveEverything ? customer.all_proposals : customer.proposals_without_separate}
                striped={true}
                bordered={false}
                hover={true}
                remote
                className={`${showTable[key] ? "proposal-bootstrap-table-show" : "proposal-bootstrap-table-hide"}`}
                expandableRow={isExpandableRow}
                expandComponent={expandComponent}
                expandColumnOptions={{
                    expandColumnVisible: true,
                    expandColumnComponent: expandColumnComponent
                }}
            >
                <TableHeaderColumn
                    dataField="proposal_no"
                    width={"10%"}
                    isKey
                >
                    Proposal No
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="title"
                    tdStyle={{whiteSpace: 'normal'}}
                    width={"25%"}
                >
                    Proposal title
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="customer_name"
                    width={"20%"}
                >
                    Customer
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="note"
                    dataFormat={(cell) => <b className="pre-line">{cell}</b>}
                    width={"30%"}
                >
                    Note
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="id"
                    dataFormat={(cell) => <Link target="_blank" to={`/mapview/${cell}`} bla>Show Proposal</Link>}
                    width={"15%"}
                />
            </BootstrapTable>
        </div>
    }

    const submit = e => {
        e.preventDefault()
        setLoading(true)
        onSave(moveSiteCheckbox, () => setLoading(false), () => setLoading(false))
    }

    return (
        <Modal
            id="moved-site-modal"
            className={"heightAuto maxHeight90 modalLeadsFilterBody fontIOS pointer"}
            bsSize="large"
            show={true}
            onHide={onClose}
        >
            <form onSubmit={submit}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Are you sure you want to move this site to customer - <b>{data?.new_customer?.name}</b>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!moveSiteCheckbox.moveEverything ? (moveSiteCheckbox.separateProposalServices ?
                            data?.new_customer_tables?.proposals_with_separate?.length > 0 || data?.old_customer_tables?.proposals_with_separate?.length > 0
                            :
                            data?.new_customer_tables?.proposals_without_separate?.length > 0 || data?.old_customer_tables?.proposals_without_separate?.length > 0) ?
                            <>
                                <div>
                                    {(moveSiteCheckbox.separateProposalServices ? data?.new_customer_tables?.proposals_with_separate?.length > 0 : data?.new_customer_tables?.proposals_without_separate?.length > 0) ?
                                        <>
                                            <h4>These Proposals will be transferred to the new Customer
                                                - <b>{data?.new_customer?.name}</b></h4>
                                            {proposalTable(data?.new_customer_tables, 'new')}
                                        </>
                                        :
                                        <h5 className="text-center"><i>All Proposals will remain with that Customer
                                            - <b>{data?.old_customer?.name}</b></i></h5>
                                    }
                                </div>
                                <div>
                                    {(moveSiteCheckbox.separateProposalServices ? data?.old_customer_tables?.proposals_with_separate?.length > 0 : data?.old_customer_tables?.proposals_without_separate?.length > 0) ?
                                        <>
                                            <h4>These Proposals will remain with that Customer
                                                - <b>{data?.old_customer?.name}</b></h4>
                                            {proposalTable(data?.old_customer_tables, 'old')}
                                        </>
                                        :
                                        <h5 className="text-center"><i>All Proposals will be transferred to the new Customer
                                            - <b>{data?.new_customer?.name}</b></i></h5>
                                    }
                                </div>
                            </>
                            :
                            <h4 className="text-center"><i>Site does not contain any proposals</i></h4>
                        :
                        <div>
                            {data?.new_customer_tables?.all_proposals?.length > 0 ?
                                <>
                                    <h4>All Proposals will be transferred to the new Customer
                                        - <b>{data?.new_customer?.name}</b></h4>
                                    {proposalTable(data?.new_customer_tables, 'all')}
                                </>
                                :
                                <h4 className="text-center"><i>Site does not contain any proposals</i></h4>
                            }
                        </div>
                    }
                </Modal.Body>

                <Modal.Footer className="vertical-align reset-top-padding reset-bottom-padding">
                    <Col xs={6} className="flex-column-start">
                        <Checkbox
                            checked={moveSiteCheckbox.separateProposalServices}
                            onChange={e => {
                                if (e.target.checked) {
                                    setMoveSiteCheckbox({
                                        moveEverything: !e.target.checked,
                                        separateProposalServices: e.target.checked
                                    })
                                } else {
                                    setMoveSiteCheckbox({
                                        ...moveSiteCheckbox,
                                        separateProposalServices: e.target.checked
                                    })
                                }
                            }}
                        >

                            <OverlayTrigger
                                placement="bottom"
                                overlay={
                                    <Tooltip id="separate_proposal_services_tooltip">
                                        Tick this checkbox if you want Proposals to be split. Proposals with status
                                        Paid, Payment and Invoiced will remain with the current customer and the rest
                                        will be assigned to <b>{data?.new_customer?.name}</b> as new Proposals.
                                    </Tooltip>}
                            >
                                <span>Separate Proposal Services</span>
                            </OverlayTrigger>
                        </Checkbox>
                        <Checkbox
                            checked={moveSiteCheckbox.moveEverything}
                            onChange={e => {
                                if (e.target.checked) {
                                    setMoveSiteCheckbox({
                                        separateProposalServices: !e.target.checked,
                                        moveEverything: e.target.checked
                                    })
                                } else {
                                    setMoveSiteCheckbox({...moveSiteCheckbox, moveEverything: e.target.checked})
                                }
                            }}
                        >

                            <OverlayTrigger
                                placement="bottom"
                                overlay={
                                    <Tooltip id="separate_proposal_services_tooltip">
                                        Tick this checkbox if you want to move whole Proposals. Proposals in all
                                        statuses (including Paid, Payment and Invoiced) will be transferred to new
                                        customer <b>{data?.new_customer?.name}</b>.
                                    </Tooltip>}
                            >
                                <span>Move Everything</span>
                            </OverlayTrigger>
                        </Checkbox>
                    </Col>
                    <Col xs={6}>
                        <Button
                            bsSize="small"
                            bsStyle="success"
                            disabled={loading}
                            type="submit"
                        >
                            {loading ? <div><MDSpinner size={20} className="mr-5"/>Processing...</div> : "Save Site"}
                        </Button>
                    </Col>
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default MovedSiteModal;