import {FETCH_STICKIES,} from "./constants"

import {addAlert} from "../App/actions"
import axios from "axios"

export const fetchStickies = () => async (
    dispatch,
    getState
) => {
    dispatch(
        addAlert({
            message: "This might take a minute or two.",
            mood: "info",
            dismissAfter: 1500,
        })
    );
    const store = getState();

    const config = {
        url: `/api/stickies/activity`,
        headers: {
            Authorization: store.auth.token
        },
    };

    try {
        const response = await axios.request(config);
        console.log(response.data);
        dispatch({type: FETCH_STICKIES, stickies: response.data});
        if (response.data && response.data.length === 0) {
            dispatch(
                addAlert({
                    message: "No activities returned. Please change your query and try again.",
                    mood: "warning",
                    dismissAfter: 3500,
                })
            )
        }
    } catch (error) {
        console.log("activities not found");
        dispatch(
            addAlert({
                message: "Couldn't fetch activities because: " + error.toString(),
                mood: "danger",
                dismissAfter: 5000,
            })
        );
        throw error
    }
};
